import React, {useState, useEffect} from 'react'
import './Modal.style.css'
import {
  ModalMoveContent,
  Heighlight,
  PrimaryText,
  SectionGrey,
  TextStyled,
  SecondaryText,
  WrapperContent,
  Wrapperhalf,
  WrapperReporter,
  ContentReporter,
  ValueWrapper,
  WrapperColumn,
  WrapperButtonAction,
} from './ModalStyle'
import ModalWlb from './ModalWlb'
import {Avatar, TextField, Button, Tooltip} from '@material-ui/core'
import ButtonAttachment from '../../../components/attachment/ButtonAttachment'
import {handleChangeAttachment, getTypeFile} from '../../../utils/helpers'
import {format} from 'date-fns'
import {UPLOAD_URL, TOKEN} from '../../../utils/globals'
import axios from 'axios'
import {
  FILE_TYPE_UPLOAD,
  goalType,
  priority,
  status_update_task,
  visibility,
} from '../goal-components/TabDialog/constant'
import ItemAttachment from '../../../components/attachment/ItemAttachment'
import {useSnackbar} from 'notistack'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

const generateDate = (date, type) => {
  if (date) {
    if (type === 'fulldate') {
      return format(new Date(date), 'd LLLL yyyy p')
    } else {
      return format(new Date(date), 'MMM d, yyyy')
    }
  } else {
    return date
  }
}

const ProgressUpdate = ({
  weightunit,
  setNewAttachment,
  setProgressValue,
  newAttachment,
  progressValue,
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (newAttachment?.attachment?.length ?? 0)

    tempFile.forEach(val => {
      if (fileCount > 10) {
        enqueueSnackbar('The number of attachments must not exceed 10', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
        enqueueSnackbar('There are file types that are not allowed', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (val.size >= 1024 * 1024 * 25) {
        enqueueSnackbar('Some of your attachment size is more than 25MB', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else {
        handleChangeAttachment(
          val,
          setDisabledButton,
          setNewAttachment,
          axios,
          UPLOAD_URL,
          TOKEN
        )
      }
    })
  }

  const handleDeleteAttachButton = i => {
    const newState = [...newAttachment?.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setNewAttachment(prevState => ({...prevState, attachment: newState}))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...newAttachment?.attachment]
    newState[i].name = e.target.value
    setNewAttachment(prevState => ({...prevState, attachment: newState}))
  }

  return (
    <div>
      <Heighlight>Pembaruan Kemajuan</Heighlight>
      <ValueWrapper>
        <TextField
          value={progressValue}
          onChange={e => setProgressValue(parseInt(e.target.value))}
          type="number"
          size="small"
          variant="outlined"
          classes={{
            root: 'text-field-root-wlb-move-task',
          }}
        />
        <TextStyled
          style={{maxWidth: 100}}
          margin="0px 0px 0px 8px"
          fontsize="12px"
        >
          {weightunit}
        </TextStyled>
      </ValueWrapper>
      <WrapperColumn>
        <TextStyled fontsize="12px" color="#a9a8a8" margin="10px 0px 8px 0px">
          Lampiran
        </TextStyled>
        <ButtonAttachment
          multiple
          id={'add-attachment'}
          disabled={newAttachment?.attachment?.length > 10 || disabledButton}
          onUpload={handleChangeFile}
        />
        {newAttachment?.attachment?.map((res, i) => {
          const {name, size, url, isUploading, loaded} = res
          return (
            <ItemAttachment
              res={res}
              key={`${url}-${i}`}
              id={`${url}-${i}`}
              size={size}
              onDelete={() => handleDeleteAttachButton(i)}
              src={url}
              onChange={e => handleChangeNameFileAttBtn(e, i)}
              fileName={name}
              isUploading={isUploading}
              loaded={loaded}
            />
          )
        })}
      </WrapperColumn>
    </div>
  )
}

const SectionContent = ({title1, value1, title2, value2, code = null}) => {
  return (
    <WrapperContent>
      <Wrapperhalf>
        <TextStyled fontsize="12px" color="#a9a8a8" margin="0px 0px 8px 0px">
          {title1}
        </TextStyled>
        <TextStyled color="#000" margin="0px 0px 5px 0px">
          {code && <SecondaryText>{code}</SecondaryText>} {value1}
        </TextStyled>
      </Wrapperhalf>
      <Wrapperhalf>
        <TextStyled fontsize="12px" color="#a9a8a8" margin="0px 0px 8px 0px">
          {title2}
        </TextStyled>
        <TextStyled color="#000" margin="0px 0px 5px 0px">
          {value2}
        </TextStyled>
      </Wrapperhalf>
    </WrapperContent>
  )
}

const SectionContent2 = ({start_date, end_date, target, target_unit}) => {
  const _renderComponent = (title, value) => {
    return (
      <Wrapperhalf>
        <TextStyled fontsize="12px" color="#a9a8a8" margin="0px 0px 8px 0px">
          {title}
        </TextStyled>
        <TextStyled
          style={{marginRight: 11, overflowWrap: 'break-word'}}
          color="#000"
          margin="0px 0px 5px 0px"
        >
          {value}
        </TextStyled>
      </Wrapperhalf>
    )
  }

  return (
    <WrapperContent>
      <Wrapperhalf direction="row">
        {_renderComponent('Tanggal Mulai', start_date)}
        {_renderComponent('Tanggal Berakhir', end_date)}
      </Wrapperhalf>
      <Wrapperhalf direction="row">
        {_renderComponent(
          GLOBAL_GLOSSARY.performance['Weight Unit'],
          target_unit
        )}
        {_renderComponent(`Target ${GLOBAL_GLOSSARY.performance.Task}`, target)}
      </Wrapperhalf>
    </WrapperContent>
  )
}

const People = ({title, isParticipant = false, participant, reporter}) => {
  const _renderComponent = () => {
    if (isParticipant) {
      return (
        <WrapperReporter>
          {participant?.length > 0 &&
            participant.slice(0, 5).map((res, i) => (
              <Tooltip
                style={{marginRight: 16}}
                key={i}
                title={res?.global_user?.name}
                placement="bottom"
              >
                <Avatar src={res?.global_user?.avatar} alt="avatar" />
              </Tooltip>
            ))}
          {participant?.length > 5 && (
            <Tooltip
              style={{marginRight: 16}}
              title={`and ${participant.length - 5} more`}
              placement="bottom"
            >
              <Avatar alt="more">+{participant.length - 5}</Avatar>
            </Tooltip>
          )}
        </WrapperReporter>
      )
    } else {
      return (
        <WrapperReporter>
          <Avatar src={reporter?.avatar} alt="avatar" />
          <ContentReporter>
            <TextStyled
              fontsize="14px"
              fontweight="600"
              color="#000"
              margin="0px 0px 4px 0px"
            >
              {reporter ? reporter.name : 'Pelapor Belum Ditentukan'}
            </TextStyled>
            <TextStyled fontsize="12px" color="#a9a8a8">
              {
                reporter?.people_work_placements?.[0]?.company_job_profile
                  ?.title
              }{' '}
              {reporter && 'di '}
              {
                reporter?.people_work_placements?.[0]?.company_profile
                  ?.brand_name
              }
            </TextStyled>
          </ContentReporter>
        </WrapperReporter>
      )
    }
  }

  return (
    <div style={{marginTop: 16}}>
      <TextStyled fontsize="12px" color="#a9a8a8" margin="0px 0px 8px 0px">
        {title}
      </TextStyled>
      {_renderComponent()}
    </div>
  )
}

export default function ModalMoveTask({
  open,
  onClose,
  target,
  data,
  isCanUpdate,
  onSubmit,
}) {
  const [progressValue, setProgressValue] = useState(data?.progress)
  const [newAttachment, setNewAttachment] = useState([])

  useEffect(() => {
    if (data) {
      setProgressValue(data.progress)
    }
  }, [data])

  const typeGoal =
    data?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate
      ?.count > 0

  const handleClose = () => {
    onClose()
    setNewAttachment([])
  }

  const handleSubmit = (progressValue, newAttachment) => {
    onSubmit(progressValue, newAttachment)
    setNewAttachment([])
  }

  const _renderButtonAction = () => {
    return (
      <WrapperButtonAction>
        <Button
          onClick={handleClose}
          style={{marginLeft: 'auto', marginRight: 16}}
        >
          Batal
        </Button>
        <Button
          onClick={() => handleSubmit(progressValue, newAttachment)}
          disableElevation
          variant="contained"
          color="secondary"
          disabled={isNaN(progressValue)}
        >
          Konfirmasi
        </Button>
      </WrapperButtonAction>
    )
  }

  const generateTarget = target => status_update_task[target] || target

  if (data) {
    return (
      <ModalWlb
        open={open}
        onClose={onClose}
        title={`Pindahkan ${GLOBAL_GLOSSARY.performance.Task}`}
      >
        <ModalMoveContent>
          <Heighlight>
            Pindahkan {GLOBAL_GLOSSARY.performance.Task} ini ke{' '}
            <PrimaryText>{generateTarget(target)}</PrimaryText> ?
          </Heighlight>
          <SectionGrey>
            <TextStyled
              fontsize="12px"
              color="#a9a8a8"
              margin="0px 0px 10px 0px"
            >
              Nama {GLOBAL_GLOSSARY.performance.Goal}
            </TextStyled>
            <TextStyled color="#000" fontweight="600" margin="0px 0px 5px 0px">
              <SecondaryText>{`[PF-${data?.performance_goal?.id}]`}</SecondaryText>{' '}
              {data?.performance_goal?.name}
            </TextStyled>
            <TextStyled fontsize="14px" color="#000">
              Dibuat pada {generateDate(data.date_added, 'fulldate')}
            </TextStyled>
            <SectionContent
              code={`[PF-${data.id}]`}
              title1={`Nama ${GLOBAL_GLOSSARY.performance.Task}`}
              value1={data.name}
              title2="Visibilitas"
              value2={visibility[data.visibility]}
            />
            <SectionContent
              title1={`Tipe ${GLOBAL_GLOSSARY.performance.Task}`}
              value1={`${
                typeGoal
                  ? GLOBAL_GLOSSARY.performance['Company Goal']
                  : GLOBAL_GLOSSARY.performance['Personal Goal']
              } ${data?.method_type ? '-' : ''} ${goalType[data?.method_type] ??
                ''}`}
              title2="Prioritas"
              value2={priority[data.priority]}
            />
            <SectionContent
              title1={`Aspek ${GLOBAL_GLOSSARY.performance.Task}`}
              value1={data?.performance_goal?.kpi_list?.kpi_aspect?.name ?? '-'}
              title2="Metrik"
              value2={data?.metric}
            />
            <SectionContent
              title1="Perspektif"
              value1={data?.performance_goal?.kpi_list?.kpi_perspective?.name}
              title2="Sifat Penilaian"
              value2={
                data?.performance_goal?.kpi_list?.kpi_scoring_nature?.name
              }
            />
            <SectionContent2
              start_date={generateDate(data.startdate)}
              end_date={generateDate(data.enddate)}
              target={`${data.target} ${data?.kpi_weight_unit?.name ||
                data?.performance_goal?.kpi_list?.kpi_weight_unit?.name}`}
              target_unit={
                data?.kpi_weight_unit?.name ||
                data?.performance_goal?.kpi_list?.kpi_weight_unit?.name ||
                '-'
              }
            />
            <People title="Penilai Kinerja" reporter={data.ReporterUser} />
            <People
              title="Peserta"
              isParticipant
              participant={data.performance_task_assignments}
            />
          </SectionGrey>
          {isCanUpdate && (
            <ProgressUpdate
              setProgressValue={setProgressValue}
              setNewAttachment={setNewAttachment}
              progressValue={progressValue}
              newAttachment={newAttachment}
              // attachment={attachment}
              weightunit={
                data?.kpi_weight_unit?.name ||
                data?.performance_goal?.kpi_list?.kpi_weight_unit?.name
              }
            />
          )}

          {_renderButtonAction()}
        </ModalMoveContent>
      </ModalWlb>
    )
  } else {
    return null
  }
}
