import gql from 'graphql-tag'

export const DELETE_GOAL = gql`
  mutation deleteGoal($id: bigint!) {
    update_performance_goals(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`
