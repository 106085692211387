import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import {MessageText} from '../../../../components/typography/TypographyStyles'
import {trimString} from '../../../../utils/helpers'

const WordingTabActicity = ({comment}) => {
  const [more, setMore] = useState(false)
  const messageLenght = comment.length

  const getIndexBeforeReporter = comment.indexOf('Set') + 3
  const getIndexBeforeAddTask = comment.indexOf('added new task')
  const getIndexBeforeDeleteTask = comment.indexOf('deleted task')
  const getIndexBeforeUpdate = comment.indexOf('from')
  const getIndexBeforeAssigned = comment.indexOf('assigned')

  const getIndexAfterReporter = comment.indexOf('as a new reporter')
  const getIndexAfterAddTask = comment.indexOf('to this goal.')
  const getIndexAfterDeleteTask = comment.indexOf('in this goal')
  const getIndexAfterUpdate = comment.indexOf('to')
  const getIndexAfterAssigned = comment.indexOf('to this task')

  const isSetReporter = getIndexAfterReporter >= 0
  const isAddTask = getIndexBeforeAddTask >= 0
  const isDeleteTask = getIndexBeforeDeleteTask >= 0
  const isUpdate = comment.indexOf('Update') >= 0
  const isLabel = comment.indexOf('Label') >= 0
  const isProgress = comment.indexOf('Progress') >= 0
  const isAssigned = getIndexBeforeAssigned >= 0
  const isUpdateDynamic = getIndexBeforeUpdate >= 0
  const isMoveTask = comment.indexOf('Task is moved') >= 0

  const reporterBold = comment.substr(
    getIndexBeforeReporter,
    getIndexAfterReporter - getIndexBeforeReporter
  )

  const assignedBold = comment.substr(
    getIndexBeforeAssigned + 8,
    getIndexAfterAssigned - (getIndexBeforeAssigned + 8)
  )

  const taskBold = comment.substr(
    isAddTask ? getIndexBeforeAddTask + 14 : getIndexBeforeDeleteTask + 12,
    (isAddTask ? getIndexAfterAddTask : getIndexAfterDeleteTask) -
      (isAddTask ? getIndexBeforeAddTask + 14 : getIndexBeforeDeleteTask + 12)
  )

  const beforeFrom = getIndexBeforeUpdate + 5
  const afterFrom = getIndexAfterUpdate + 3

  const beforeUpdate = comment.substr(
    beforeFrom,
    getIndexAfterUpdate - beforeFrom
  )

  const afterUpdate = comment.substr(afterFrom, messageLenght)

  const renderManipulatecomment = () => {
    if (isSetReporter) {
      return (
        <>
          <MessageText>{`${comment.substr(
            0,
            getIndexBeforeReporter
          )}`}</MessageText>
          <strong>{reporterBold}</strong>
          <span>
            {comment.substr(getIndexAfterReporter, messageLenght)}
          </span>{' '}
        </>
      )
    } else if (isAddTask || isDeleteTask) {
      return (
        <>
          <MessageText>{`${comment.substr(
            0,
            (isAddTask ? getIndexBeforeAddTask : getIndexBeforeDeleteTask) +
              (isAddTask ? 14 : 12)
          )} `}</MessageText>
          <strong>{taskBold}</strong>
          <span>
            {comment.substr(
              isAddTask ? getIndexAfterAddTask : getIndexAfterDeleteTask,
              messageLenght
            )}
          </span>
        </>
      )
    } else if (isUpdate || isProgress || isMoveTask) {
      if (!isLabel && isUpdateDynamic) {
        return (
          <>
            <MessageText>{`${comment.substr(0, beforeFrom)}`}</MessageText>
            <strong>{` ${beforeUpdate} `}</strong>
            <span>{comment.substr(getIndexAfterUpdate, 3)}</span>
            <strong>{`${afterUpdate}`}</strong>
          </>
        )
      } else if (isLabel) {
        return (
          <>
            <MessageText>{`${comment.substr(
              0,
              getIndexAfterUpdate
            )}`}</MessageText>
            <strong>{` ${afterUpdate} `}</strong>
          </>
        )
      } else {
        return (
          <>
            {more ? comment : trimString(comment, 650, true)}
            {comment?.length > 650 && (
              <Typography
                style={{
                  color: '#039be5',
                  cursor: 'pointer',
                }}
                onClick={() => setMore(!more)}
                variant="span"
              >
                {more ? ` Lihat lebih sedikit` : ` Lihat semua`}
              </Typography>
            )}
          </>
        )
      }
    } else if (isAssigned) {
      return (
        <>
          <MessageText>{`${comment.substr(
            0,
            getIndexBeforeAssigned + 8
          )}`}</MessageText>
          <strong>{` ${assignedBold} `}</strong>
          <span>{`${comment.substr(
            getIndexAfterAssigned,
            messageLenght
          )}`}</span>
        </>
      )
    } else {
      return (
        <>
          {more ? comment : trimString(comment, 650, true)}
          {comment?.length > 650 && (
            <Typography
              style={{
                color: '#039be5',
                cursor: 'pointer',
              }}
              onClick={() => setMore(!more)}
              variant="span"
            >
              {more ? ` Lihat lebih sedikit` : ` Lihat semua`}
            </Typography>
          )}
        </>
      )
    }
  }

  return <div>{renderManipulatecomment()}</div>
}

export default WordingTabActicity
