import {InputAdornment, makeStyles, TextField} from '@material-ui/core'
import React, {useState} from 'react'
import SearchIcon from '@material-ui/icons/Search'
import EmptyState from '../../../../../components/empty-state/EmptyStateComponent'
import ModalTab from '../../../goal-components/TabDialog/ModalTab'
import InfiniteScroll from 'react-infinite-scroll-component'
import SquadListItem from './SquadListItem'

const useStyles = makeStyles(() => ({
  searchBar: {marginTop: 16, marginBottom: 24},
  wrapperComponent: {width: '99%', margin: 'auto'},
}))

export default function ListMember(props) {
  const {
    search,
    setSearch,
    data,
    loading,
    fetchMoreData,
    onClickInviteMember = null,
    type,
  } = props

  const [detail, setDetail] = useState({
    open: false,
    type: 'goal',
    id: 0,
  })

  const classes = useStyles()
  return (
    <div style={{marginBottom: 120}}>
      <div className={classes.wrapperComponent}>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Cari"
          className={classes.searchBar}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>

      {!loading && data && (
        <InfiniteScroll
          dataLength={data.people_work_placements.length}
          next={fetchMoreData}
          hasMore={
            data.people_work_placements.length < data.total.aggregate.count
          }
          loader={
            <>
              <SquadListItem isLoading />
              <SquadListItem isLoading />
              <SquadListItem isLoading />
            </>
          }
        >
          {data.people_work_placements.map(
            ({id, global_user, company_job_profile}) => {
              const talent_assignments =
                global_user && global_user.talent_assignments[0]
              return (
                <SquadListItem
                  key={id}
                  user={global_user.id}
                  name={global_user?.name}
                  avatar={global_user?.avatar}
                  badge={talent_assignments?.talent_box?.badge}
                  badgeName={talent_assignments?.talent_box?.name}
                  versionName={
                    talent_assignments?.talentVersionByTalentVersion?.name
                  }
                  score={talent_assignments?.talent_score?.toFixed(2)}
                  title={company_job_profile?.title}
                  badgeTitle={
                    talent_assignments?.people_work_placement
                      ?.company_job_profile?.title
                  }
                  setDetail={setDetail}
                  detail={detail}
                  type={type}
                />
              )
            }
          )}
        </InfiniteScroll>
      )}

      {loading && (
        <>
          <SquadListItem isLoading />
          <SquadListItem isLoading />
          <SquadListItem isLoading />
        </>
      )}

      {!loading && data && data.people_work_placements.length === 0 && (
        <EmptyState
          message1={'Maaf, Tidak ada Daftar'}
          message2={
            search
              ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
              : 'Saat ini, tidak ada tim kerja yang terdaftar'
          }
          image={require('../../../../../assets/images/nodatalist.png')}
          buttonText={onClickInviteMember && !search && 'Undang Anggota'}
          onClickButton={onClickInviteMember}
          styleMessage2={{
            maxWidth: 300,
            margin: 'auto',
          }}
        />
      )}

      <ModalTab
        open={detail.open}
        handleClose={() => setDetail(prev => ({...prev, open: false}))}
        id={detail.id}
        withoutAdd
        type={detail.type || 'goal'}
        isUserCanUpdate={false}
      />
    </div>
  )
}
