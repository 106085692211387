import {useMutation, useQuery} from '@apollo/react-hooks'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  GET_DETAIL_SUBORDINATE_SQUAD,
  GET_EXPORT_DISABLED_BUTTON_IS_NULL_FILTER,
  GET_LIST_MEMBER_SUBORDINATE_SQUAD,
  GET_LIST_SUGGESTION_SUBORDINATE_SQUAD_MEMBER,
  GET_STATUS_EXPORT_BALOON_IS_NULL_FILTER,
} from '../../../../graphql/queries'
import {
  ADD_SUBORDINATE_SQUAD_MEMBER,
  DELETE_SUBORDINATE_SQUAD_MEMBER,
  UPDATE_SUBORDINATE_SQUAD_INFO,
} from '../../../../graphql/mutations'
import ListMember from '../shared/list-squad-member/ListMember'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import HeaderSquadDetail from '../shared/HeaderSquadDetail'
import ExportBaloon from '../../../shared-component/ExportBaloon'
import {useSnackbar} from 'notistack'
import NotificationPopup from '../../../shared-component/NotificationPopup'
import PopupDetailSquad from '../shared/PopupDetailSquad'
import {DEFAULT_VALUE} from '../shared/constant'
import AddEditSquadInformation from '../shared/AddEditSquadMember'
import EditSquadMember from '../shared/EditSquadMember'
import AddSquadMember from '../shared/AddSquadMember'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import moment from 'moment'
import ChoosePeriodPopup from '../../../shared-component/ChoosePeriodPopup'

import {makeStyles, Paper} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: '24px 18px',
    backgroundColor: '#fff',
  },
}))

const TABLE_NAME = 'performance_goal_team'
const INITIAL_INFO_STATE = {
  open: false,
  title: '',
  message: '',
  onCancel: undefined,
  onConfirm: undefined,
  isDisabledMainButton: false,
}

const INITIAL_NOTIFICATION = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
}
const INITIAL_VALUES = {
  squadName: '',
  squadDescription: '',
}

const getSubordinateInfo = dataDetail => {
  const placement_fields =
    dataDetail?.people_work_placements?.[0]?.placement_fields

  const subordinate_squad_label =
    placement_fields?.subordinate_squad_label ||
    DEFAULT_VALUE.subordinate_squad_label
  const subordinate_squad_description =
    placement_fields?.subordinate_squad_description ||
    DEFAULT_VALUE.subordinate_squad_description

  return {
    subordinate_squad_label,
    subordinate_squad_description,
  }
}

const ListMemberSubordinateSquad = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [notification, setNotification] = useState(INITIAL_NOTIFICATION)
  const [openDetailInfo, setOpenDetailInfo] = useState(false)
  const [infoState, setInfoState] = useState(INITIAL_INFO_STATE)
  const [openExport, setExport] = useState(false)
  const [isAllowOpenPopup, setIsAllowOpenPopup] = useState(true)
  const [open, setOpen] = useState(false)
  const [openAddMember, setOpenAddMember] = useState(false)
  const [openEditMember, setOpenEditMember] = useState(false)
  const [values, setValues] = useState(INITIAL_VALUES)
  const [selectedAddMember, setSelectedAddMember] = useState([])

  const [updateSubordinateSquad] = useMutation(UPDATE_SUBORDINATE_SQUAD_INFO)
  const [addSubordinateSquadMember] = useMutation(ADD_SUBORDINATE_SQUAD_MEMBER)
  const [deleteSubordinateMember] = useMutation(DELETE_SUBORDINATE_SQUAD_MEMBER)

  const {
    dataBaloon: getExportBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_NAME,
    query: {
      getStatusBaloon: GET_STATUS_EXPORT_BALOON_IS_NULL_FILTER,
      getDisabledButton: GET_EXPORT_DISABLED_BUTTON_IS_NULL_FILTER,
    },
  })

  const {data, loading, error, refetch, fetchMore} = useQuery(
    GET_LIST_MEMBER_SUBORDINATE_SQUAD,
    {
      variables: {
        company: COMPANY_ID,
        user: USER_ID,
        search: `%${search}%`,
        limit: 20,
        offset: 0,
      },
    }
  )

  const {data: dataDetail, refetch: refetchDetailInfoSquad} = useQuery(
    GET_DETAIL_SUBORDINATE_SQUAD,
    {
      variables: {
        user: USER_ID,
      },
    }
  )

  const {
    subordinate_squad_label,
    subordinate_squad_description,
  } = getSubordinateInfo(dataDetail)

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_work_placements.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_work_placements: [
            ...prev.people_work_placements,
            ...fetchMoreResult.people_work_placements,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const handleDownload = ({startDate, endDate}) => {
    const format = 'YYYY-MM-DD'
    const successAction = () => {
      setExport(false)
      setInfoState(e => ({
        ...e,
        open: true,
        title: 'Pembuatan data sedang diproses!',
        message:
          'Pembuatan data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai.',
        onConfirm: () => setInfoState(INITIAL_INFO_STATE),
      }))
      enqueueSnackbar('Pembuatan data sedang diproses!', {
        variant: 'success',
        autoHideDuration: 1000,
      })
    }
    const errorAction = () => {
      setExport(false)
      enqueueSnackbar('Generate Data failed, please try again later', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    }

    onDownload({
      variables: {
        table: TABLE_NAME,
        ...(startDate &&
          endDate && {
            filter: {
              start_date: moment(startDate).format(format),
              end_date: moment(endDate).format(format),
            },
          }),
      },
      action: {
        success: () => successAction(),
        error: () => errorAction(),
      },
    })
  }

  const closeBaloonExportMutation = async () => {
    await onCloseBaloon(getExportBaloon?.company_export_data_logs?.[0]?.id)
  }

  const updateSquadInfo = ({squadName, squadDescription}) => {
    updateSubordinateSquad({
      variables: {
        user: USER_ID,
        info: {
          subordinate_squad_label: squadName,
          subordinate_squad_description: squadDescription,
        },
      },
    })
      .then(() => {
        refetchDetailInfoSquad()
        enqueueSnackbar('Update squad information success', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar(
          'Update squad information error, please try again later',
          {
            variant: 'error',
            autoHideDuration: 2000,
          }
        )
      })
  }

  const resetValuesState = () => {
    setValues(prev => ({
      ...prev,
      squadName: subordinate_squad_label,
      squadDescription: subordinate_squad_description,
    }))
  }

  const onConfirmAction = () => {
    setIsAllowOpenPopup(true)
    setOpen(false)
    setNotification(INITIAL_NOTIFICATION)
    setValues(INITIAL_VALUES)

    resetValuesState()
  }

  const handleClose = () => {
    setIsAllowOpenPopup(false)
    setNotification({
      open: true,
      title: 'Buang perubahan?',
      message: 'Apakah Anda yakin akan membuang perubahan yang belum disimpan?',
      onClose: () => {
        setIsAllowOpenPopup(true)
        setNotification(INITIAL_NOTIFICATION)
      },
      onConfirm: () => {
        onConfirmAction()
      },
    })
  }

  const handleSave = values => {
    setIsAllowOpenPopup(false)
    setNotification({
      open: true,
      title: 'Simpan Perubahan?',
      message: 'Apakah anda yakin untuk menyimpan perubahan?',
      onClose: () => {
        setIsAllowOpenPopup(true)
        setNotification(INITIAL_NOTIFICATION)
      },
      onConfirm: () => {
        updateSquadInfo(values)
        onConfirmAction()
      },
    })
  }

  const handleDeleteMember = (data, refetchList) => {
    deleteSubordinateMember({
      variables: {
        placement: data.id,
      },
    })
      .then(() => {
        refetchList()
        enqueueSnackbar('Delete squad member success', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete squad member error, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const addMember = () => {
    const variables = {
      team: selectedAddMember.map(res => ({member_placement: res.id})),
    }
    addSubordinateSquadMember({variables})
      .then(() => {
        refetch()
        enqueueSnackbar('Add squad member success', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Add squad member error, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleCloseAddMember = () => {
    setOpenAddMember(false)
    setNotification({
      open: true,
      title: 'Batal Tambah Anggota Tim Kerja?',
      message:
        'Apakah Anda yakin akan membatalkan penambahan anggota ke Tim Kerja Anda?',
      onClose: () => {
        setNotification(INITIAL_NOTIFICATION)
        setOpenAddMember(true)
      },
      onConfirm: () => {
        setSelectedAddMember([])
        setNotification(INITIAL_NOTIFICATION)
      },
    })
  }

  const handleSaveAddMember = () => {
    setOpenAddMember(false)
    setNotification({
      open: true,
      title: 'Tambah Anggota Tim Kerja?',
      message:
        'Apakah anda yakin ingin menambah anggota tim kerja? Orang yang anda pilih akan mendapatkan notifikasi',
      onClose: () => {
        setNotification(INITIAL_NOTIFICATION)
        setOpenAddMember(true)
      },
      onConfirm: () => {
        addMember()
        setSelectedAddMember([])
        setNotification(INITIAL_NOTIFICATION)
      },
    })
  }

  return (
    <Paper className={classes.root}>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
        showCloseButton={isDisableButton}
      />

      <HeaderSquadDetail
        type="direct-report-squad"
        squadName={subordinate_squad_label}
        isDisabledReport={isDisableButton}
        isActiveSquad={true}
        onClickGenerateReport={() => setExport(true)}
        onClickShowDetail={() => setOpenDetailInfo(true)}
        onClickSquadInformation={() => {
          setValues({
            squadName: subordinate_squad_label,
            squadDescription: subordinate_squad_description,
          })
          setOpen(true)
        }}
        onClickSquadMember={() => setOpenEditMember(true)}
      />

      <ListMember
        type="direct-report-squad"
        setSearch={setSearch}
        search={search}
        data={data}
        loading={loading}
        error={error}
        refetch={refetch}
        fetchMoreData={fetchMoreData}
        {...props}
      />

      <EditSquadMember
        open={openEditMember}
        onClose={() => {
          refetch()
          setOpenEditMember(false)
        }}
        query={GET_LIST_MEMBER_SUBORDINATE_SQUAD}
        variablesQuery={{
          company: COMPANY_ID,
          user: USER_ID,
        }}
        onDelete={handleDeleteMember}
        onInviteMember={() => {
          setOpenEditMember(false)
          setOpenAddMember(true)
        }}
      />
      <AddEditSquadInformation
        open={open && isAllowOpenPopup}
        values={values}
        setValues={setValues}
        onClose={handleClose}
        onSave={handleSave}
        title="Ubah Informasi Tim Kerja"
        type="subordinate-squad"
      />

      <AddSquadMember
        open={openAddMember}
        onClose={handleCloseAddMember}
        onSave={handleSaveAddMember}
        selectedAddMember={selectedAddMember}
        setSelectedAddMember={setSelectedAddMember}
        query={GET_LIST_SUGGESTION_SUBORDINATE_SQUAD_MEMBER}
      />

      <PopupDetailSquad
        name={subordinate_squad_label}
        description={subordinate_squad_description}
        status={'Aktif'}
        open={openDetailInfo}
        onClose={() => setOpenDetailInfo(false)}
      />

      <NotificationPopup
        open={notification.open}
        title={notification.title}
        message={notification.message}
        onConfirm={notification.onConfirm}
        onClose={notification.onClose}
        onCancel={notification.onClose}
      />

      <NotificationPopup
        open={infoState.open}
        title={infoState.title}
        message={infoState.message}
        onClose={infoState.onClose}
        onConfirm={infoState.onConfirm}
        onCancel={infoState.onCancel}
        isDisabledMainButton={infoState.isDisabledMainButton}
      />

      <ChoosePeriodPopup
        open={openExport}
        onClose={() => setExport(false)}
        onConfirm={handleDownload}
        enableFuture
        custom
      />
    </Paper>
  )
}

export default withRouter(ListMemberSubordinateSquad)
