import React from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Paper,
  Icon,
  makeStyles,
} from '@material-ui/core'
// import {capitalize} from '../../utils/helpers'
import {BoldTypography} from '../../components/typography/TypographyStyles'

// import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  wrapper: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  expansion: {
    padding: '0 14px 0 38px',
    border: 'none',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    marginRight: 45,
  },
  marginBottom: {
    marginBottom: 16,
  },
  paper: {
    padding: 24,
    marginLeft: 12,
    marginBottom: 30,
  },
  paperDetail: {
    padding: 24,
  },
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    padding: '8px 16px',
  },
  textFilter: {
    marginLeft: 16,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0px',
    padding: '8px 0px',
  },
  bodyDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0px',
    padding: '8px 0px',
    cursor: 'pointer',
  },
  time: {
    marginLeft: 'auto',
    color: '#a9a8a8',
  },
  seeMore: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#039be5',
    cursor: 'pointer',
  },
  large: {
    width: 80,
    height: 80,
  },
  largeTalent: {
    width: 100,
    height: 100,
  },
  infoDetail: {
    flexDirection: 'coloumn',
    marginBottom: '1rem',
  },
  info1: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    color: '#014a62',
    marginRight: 17,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  role: {
    color: '#a9a8a8',
  },
  info2: {
    color: '#a9a8a8',
  },
  profil: {
    height: 34,
    width: 'auto',
  },
  score: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#a9a8a8',
  },
  classification: {
    padding: '0px 10px',
    borderRight: '1px solid rgba(0, 0, 0, 0.16)',
  },
  score2: {
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'row',
  },
  score3: {
    marginLeft: 7,
  },
  dialog: {
    width: 1101,
  },
  title: {
    color: '#a9a8a8',
    fontWeight: 600,
    marginBottom: 10,
  },
  talentDetailHeader: {
    backgroundColor: '#eff2f4',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px',
  },
  talentDetailTitle: {
    fontWeight: '600',
  },
  talentSubtittle: {
    fontWeight: '600',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#a9a8a8',
  },
  iconClose: {
    cursor: 'pointer',
    padding: '16px 24px',
    marginLeft: 'auto',
    fontSize: '32px !important',
  },
  talentDetaiInfo: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    marginLeft: '1rem',
    padding: 24,
  },
  globalInfoTalentDetail: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: '20px 40px',
  },
  talentDetailAvatar: {
    width: 128,
    height: 128,
  },
  talentDetailName: {
    fontSize: 22,
    fontWeight: 600,
  },
  talentDetailNumber: {
    color: '#a9a8a8',
    marginBottom: 10,
  },
  talentDetailRole: {
    fontSize: 20,
    fontWeight: 600,
  },
  talentDetailDate: {
    color: '#a9a8a8',
  },
  talentDetailBadge: {
    height: 79,
    width: 'auto',
  },
  badge: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  talentDetailPosition: {
    fontWeight: '600',
    fontSize: 16,
  },
  talentDetailScore: {
    textAlign: 'center',
  },
  talentDetailTalentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
  },
  paperBadge: {
    width: '30%',
    borderRadius: '5px',
    marginRight: '2rem',
    paddingBottom: 20,
  },
  paperPostBadge: {
    width: '70%',
    borderRadius: '5px',
    paddingBottom: 20,
  },
  talentInfoPaper: {
    display: 'flex',
    flexGrow: '1',
    textAlign: 'center',
    flexDirection: 'column',
    margin: '20px 0px 0px 0px',
    padding: 25,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginRight: 30,
    '&:nth-child(4)': {
      marginRight: 0,
    },
  },
  talentDetailInfo: {
    fontSize: 15,
    marginBottom: 10,
  },
  padding: {
    padding: 40,
    width: '100%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginBottom: '1rem',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentInfoScore1: {
    width: 150,
    textAlign: 'right',
    marginLeft: 'auto',
  },
  talentInfoScore2: {
    width: 150,
    textAlign: 'right',
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    '&:nth-child(n+1)': {
      fontSize: 20,
      fontWeight: '600',
    },
  },
  edit: {color: '#039be5', cursor: 'pointer'},
  titleBadge: {
    display: 'flex',
    padding: 20,
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  font: {
    fontWeight: 500,
    fontSize: 17,
  },
  titleDetail: {
    fontSize: 18,
    margin: '30px 0px 15px 0px',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailTitleName: {
    fontSize: 20,
    fontWeight: '600',
    display: 'inline-block',
    marginLeft: 20,
  },
  paperRating: {
    padding: 40,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'row',
  },
  indicator1: {
    width: '100%',
    marginRight: 45,
  },
  indicator2: {
    width: '100%',
  },
  titleIndicator: {
    marginBottom: 14,
    fontWeight: '600',
    fontSize: 18,
    textTransform: 'capitalize',
  },
  valueIndicator: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemIndicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: 500,
    marginBottom: 3,
  },
  textNote: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 26,
  },
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 15,
  },
  lastUpdate: {
    margin: '30px 0px 15px 0px',
  },
  aspiration: {
    display: 'flex',
    flexDirection: 'row',
    padding: 30,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    flexWrap: 'wrap',
  },
  listContainer: {minHeight: '33.5rem'},
}))

export default function ModalDetail({onClose, open, data, xArr, yArr}) {
  const classes = useStyles()
  // const colorInformation = status => {
  //   if (status === 'HIGH') {
  //     return {
  //       fontWeght: 600,
  //       color: '#ef4d5e',
  //     }
  //   } else if (status === 'LOW') {
  //     return {
  //       fontWeght: 600,
  //       color: '#4caf50',
  //     }
  //   } else {
  //     return {
  //       fontWeght: 600,
  //       color: '#ffa000',
  //     }
  //   }
  // }

  // const placement_fields =
  //   data?.people_work_placement?.placement_fields?.talent_information

  const resTalent = data?.talentVersionByTalentVersion

  return (
    <Dialog scroll="body" fullWidth maxWidth="lg" onClose={onClose} open={open}>
      <div className={classes.talentDetailHeader}>
        <BoldTypography variant="h6">{resTalent?.name}</BoldTypography>
        <Icon className={classes.iconClose} onClick={onClose}>
          close
        </Icon>
      </div>
      <DialogContent>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Paper className={classes.globalInfoTalentDetail}>
            <div style={{marginRight: 25}}>
              <BoldTypography variant="body1" paragraph>
                Description
              </BoldTypography>
              <img
                src={data?.talent_box?.badge}
                alt={data?.talent_box?.name}
                style={{width: 135}}
              />
            </div>
            <div style={{marginTop: '2rem'}}>
              <BoldTypography variant="h5" paragraph>
                {data?.talent_box?.name}
              </BoldTypography>
              <Typography variant="body2" className={classes.title}>
                {data?.people_work_placement?.company_job_profile?.title}
              </Typography>
              <Typography variant="body1">
                {data?.talent_box?.description}
              </Typography>
            </div>
          </Paper>
          <Paper className={classes.talentDetaiInfo}>
            <BoldTypography variant="body1">Overall</BoldTypography>
            <Typography
              variant="h2"
              style={{
                fontWeight: 'bold',
                marginLeft: '1rem',
                textAlign: 'center',
                marginTop: '2rem',
              }}
            >
              {data?.talent_score?.toFixed(2)}
              <Icon
                style={{fontSize: 50, fontWeight: 'bold', color: '#4caf50'}}
              >
                arrow_upward
              </Icon>
            </Typography>
            <BoldTypography
              variant="body1"
              style={{textAlign: 'center', marginRight: '2rem'}}
            >
              Points
            </BoldTypography>
          </Paper>
        </div>
        {/* <div className={classes.talentDetailTalentInfo}>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Risk of Loss
            </Typography>
            <Typography
              variant="body1"
              style={colorInformation(placement_fields?.risk_of_loss)}
            >
              {data && capitalize(placement_fields?.risk_of_loss)}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Impact of Loss
            </Typography>
            <Typography
              variant="body1"
              style={colorInformation(placement_fields?.impact_of_loss)}
            >
              {data && capitalize(placement_fields?.impact_of_loss)}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Talent Mobility
            </Typography>
            <Typography
              variant="body1"
              style={{fontWeight: '600', color: '#a9a8a8'}}
            >
              {placement_fields?.reason_for_leaving}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Future Leader
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                color: placement_fields?.future_leader ? '#4caf50' : '#ef4d5e',
              }}
            >
              {placement_fields?.future_leader ? 'Yes' : 'No'}
            </Typography>
          </Paper>
        </div> */}

        <div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
          <Paper className={classes.padding} style={{marginRight: '1rem'}}>
            <div className={classes.displayRow}>
              <BoldTypography variant="body1" gutterBottom>
                {resTalent?.flexy_components?.x.name}
              </BoldTypography>
              {/* <Typography variant="caption" paragraph>
                Last Update on{' '}
                {data?.date_modified
                  ? moment(data.date_modified).format('DD/MM/YYYY')
                  : '-'}
              </Typography> */}
            </div>
            <Typography variant="body1" align="right" gutterBottom>
              Achieved
            </Typography>
            {xArr?.length > 0 &&
              xArr.map((axis, i) => (
                <div className={classes.displayRow} key={i}>
                  <Typography variant="body1">
                    {axis.param}
                    {/* ({axis.index}) */}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                  >
                    {axis.value}
                  </Typography>
                </div>
              ))}
          </Paper>

          <Paper className={classes.padding}>
            <div className={classes.displayRow}>
              <BoldTypography variant="body1">
                {resTalent?.flexy_components?.y.name}
              </BoldTypography>
              {/* <Typography variant="caption" paragraph>
                Last Update on{' '}
                {data?.date_modified
                  ? moment(data.date_modified).format('DD/MM/YYYY')
                  : '-'}
              </Typography> */}
            </div>
            <Typography variant="body1" align="right" gutterBottom>
              Achieved
            </Typography>
            {yArr?.length > 0 &&
              yArr.map((axis, i) => (
                <div className={classes.displayRow} key={i}>
                  <Typography variant="body1">
                    {axis.param}
                    {/* ({axis.index}) */}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                  >
                    {axis.value}
                  </Typography>
                </div>
              ))}
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  )
}
