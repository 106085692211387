import gql from 'graphql-tag'

export const GET_LIST_FEED_BACK_REQUEST = gql`
  query listRequest(
    $offset: Int
    $limit: Int
    $user: uuid!
    $search: String
    $dateFrom: timestamptz
    $dateTo: timestamptz
    $orderBy: [ongoing_feedback_requests_order_by!]
  ) {
    total: ongoing_feedback_requests_aggregate (
      where: {
        _not: {ongoing_feedback_lists: {id: {_gte: 0}}}
        _or: [{user: {_eq: $user}}, {request_to: {_eq: $user}}]
        _and: {
          _or: [
            {global_user_from: {name: {_ilike: $search}}}
            {global_user_to: {name: {_ilike: $search}}}
            {topic: {_ilike: $search}}
            {description: {_ilike: $search}}
          ]
        }
        date_created: {_gte: $dateFrom, _lte: $dateTo}
      }
    ) {
      aggregate {
        count
      }
    }
    ongoing_feedback_requests(
      where: {
        _not: {ongoing_feedback_lists: {id: {_gte: 0}}}
        _or: [{user: {_eq: $user}}, {request_to: {_eq: $user}}]
        _and: {
          _or: [
            {global_user_from: {name: {_ilike: $search}}}
            {global_user_to: {name: {_ilike: $search}}}
            {topic: {_ilike: $search}}
            {description: {_ilike: $search}}
          ]
        }
        date_created: {_gte: $dateFrom, _lte: $dateTo}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      topic
      description
      type_from
      type_to
      date_created
      global_user_from {
        id
        name
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
      global_user_to {
        id
        name
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
    }
  }
`
