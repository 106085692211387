/* eslint-disable */
import React, {useState, Fragment, useEffect, useContext} from 'react'
import {
  Button,
  MenuItem,
  Icon,
  InputLabel,
  InputAdornment,
  TextField,
  DialogActions,
  Typography,
  Radio,
  Paper,
  Avatar,
  Card,
  IconButton,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import Select, {components} from 'react-select'
import {
  PsmGrey,
  PmdGrey,
  GreyTypography,
} from '../../../components/typography/TypographyStyles'

import {ChromePicker} from 'react-color'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import {
  ADD_NEW_GOALS,
  ADD_NEW_TASKS,
  UPDATE_GOALS,
  UPDATE_TASKS,
  ADD_NEW_GROUP,
  UPDATE_GROUP_TASK,
  ADD_NEW_GOAL_GROUP,
  DELETE_TASK_GROUP,
  DELETE_GOAL_GROUP,
} from '../../../graphql/mutations'
import {
  GET_LIST_GROUP,
  GET_LIST_EMPLOYEE,
  // GET_COUNT_TARGET_GOAL,
  GET_DROPDOWN_WEIGHT,
  GET_DROPDOWN_ASPECT,
  GET_LIST_GOAL_GROUP,
  CHECK_GROUP_TASK,
  CHECK_GROUP_GOAL,
} from '../../../graphql/queries'
import {DataContext} from '../../../contexts/ContextConfig'
import {TOKEN, UPLOAD_URL, USER_ID, COMPANY_ID} from '../../../utils/globals'
import axios from 'axios'
import {ModalContent} from '../detail-modal/ModalPerformanceStyles'
import {
  TextHelper,
  GenerateTooltip,
  FlexBetween,
  LineClamp,
  FlexCenter,
} from '../PerformanceStyles'
import {
  goalType,
  goalTypes,
  FILE_TYPE_UPLOAD,
  visibilitys,
  priorities,
} from '../goal-components/TabDialog/constant'
import {
  handleChangeAttachment,
  getTypeFile,
  IntersectionOfDate,
  selectProps,
  trimString,
} from '../../../utils/helpers'
import ButtonAttachment from '../../../components/attachment/ButtonAttachment'
import ItemAttachment from '../../../components/attachment/ItemAttachment'
import TooltipAttachmentGoal from '../../shared-component/attachment/TooltipAttachmentGoal'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {useSnackbar} from 'notistack'
import moment from 'moment'
import _ from 'lodash'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import ModalDelete from './ModalDelete'
import ConfirmationPopup from '../../shared-component/ConfirmationPopup'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorDate: {
    margin: '8px 14px 0px',
    color: '#f44336',
    fontSize: 12,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  upload: {
    width: '150px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#055469',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#fff',
  },
  save: {
    width: '150px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#007fb2',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#fff',
  },
  formChild: {marginBottom: '1rem'},
  disabled: {background: '#eff2f4'},
  outlinedInput: {
    padding: '10px 14px',
  },
  radioRoot: {padding: 0},
  icon: {
    borderRadius: 5,
    padding: 10,
  },
  checkedIcon: {
    borderRadius: 5,
    color: '#fff',
  },
  list: {display: 'flex', margin: '10px 0'},
  textGrey: {color: '#a9a8a8'},
  spacer: {marginRight: 10},
  selectedEmp: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardEmp: {
    width: '28.5%',
    margin: 10,
    padding: 9,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #a9a8a8',
  },
  flexAbsolute: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
  },
  helperMarginLeft0: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
  marginTextHelper: {
    marginLeft: '0px !important',
    marginTop: '5px !important',
  },
  weightUnitSyle: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
}))

const customStyle = {
  flexExpansion: {
    display: 'flex',
  },
  flexExpansion2: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 350,
  },
  PR: {
    paddingRight: '3rem',
  },
  pR: {
    paddingRight: '1.5rem',
  },
  buttonSpacer: {
    marginRight: 12,
  },
  empContainer: {
    paddingTop: 16,
  },
}
export default function ModalPersonal(props) {
  const {type, dataEdit, dataDateGoal = null} = props
  const client = useApolloClient()
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const {setDataContext} = useContext(DataContext)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [label, setLabel] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [idLabel, setIdLabel] = useState('')
  const [selectedGroup, setSelectedGroup] = useState([])
  const [disabledButton, setDisabledButton] = useState(false)
  const [values, setValues] = useState({
    name: '',
    description: '',
    target: '',
    // target_stretch: '',
    progress: 0,
    weight: null,
    aspect: null,
    priority: '',
    visibility: '',
    startdate: null,
    enddate: null,
    status: '',
    metric: '',
    method_type: null,
    attachment: [],
  })
  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])
  const [color, setColor] = useState('#055469')
  const [isError, setIsError] = useState(false)
  const [labelName, setLabelName] = useState('')
  const [colorPicker, setColorPicker] = useState(false)
  const [selectedRep, setSelectedRep] = useState([])
  const INITIAL_ALERT_LABEL = {
    canDelete: true,
    open: false,
    idLabel: null,
  }
  const [alertLabel, setAlertLabel] = useState(INITIAL_ALERT_LABEL)
  const [searchLabel, setSearchLabel] = useState('')
  const [searchDropdown, setSearchDropdown] = useState({
    recepient: '',
    weight: '',
    aspect: '',
  })

  const {data: dataGroup, refetch: refetchGroup} = useQuery(GET_LIST_GROUP, {
    variables: {
      search: searchLabel
        ? {name: {_ilike: `%${searchLabel}%`}}
        : {
            _or: [{user: {_eq: USER_ID}}],
          },
    },
  })
  const {data: dataGoalGroup, refetch: refetchGoalGroup} = useQuery(
    GET_LIST_GOAL_GROUP,
    {
      variables: {
        search: searchLabel
          ? {name: {_ilike: `%${searchLabel}%`}}
          : {
              _or: [{added_by: {_eq: USER_ID}}],
            },
      },
    }
  )

  const {data: dataEmp, loading} = useQuery(GET_LIST_EMPLOYEE, {
    variables: {
      company: COMPANY_ID,
      limit: 20,
      search: `%${searchDropdown.recepient}%`,
    },
  })

  const {
    data: dataWeight,
    error: errorWeight,
    loading: loadingWeight,
  } = useQuery(GET_DROPDOWN_WEIGHT, {
    variables: {
      companyId: COMPANY_ID,
      search: `%${searchDropdown.weight}%`,
      limit: 20,
    },
  })

  const {
    data: dataAspect,
    error: errorAspect,
    loading: loadingAspect,
  } = useQuery(GET_DROPDOWN_ASPECT, {
    variables: {
      search: `%${searchDropdown.weight}%`,
      limit: 20,
    },
  })

  const options = [
    {
      idGroup: null,
      value: 'Tidak Ada',
      label: 'Tidak Ada',
      color: '#A9A8A8',
      user: null,
    },
  ]
  dataGroup?.performance_groups?.map(res => {
    const option = {
      idGroup: res.id,
      value: res.name,
      label: res.name,
      color: res.color,
      user: res.user,
    }
    options.push(option)
  })

  const labelOptionsGoal = [
    {
      idGroup: null,
      value: 'Tidak Ada',
      label: 'Tidak Ada',
      color: '#A9A8A8',
      user: null,
    },
  ]
  dataGoalGroup?.performance_goal_groups?.map(res => {
    const option = {
      idGroup: res.id,
      value: res.name,
      label: res.name,
      color: res.color,
      user: res.added_by,
    }
    labelOptionsGoal.push(option)
  })

  const listEmp = dataEmp?.people_work_placements?.map(res => {
    return {
      label: res.global_user.id,
      value: res.global_user.name,
      avatar: res.global_user.avatar,
      organization: res.company_organization && res.company_organization.name,
      pos: res.company_job_profile && res.company_job_profile.title,
    }
  })

  const dataKpi =
    dataEdit && type === 'edit-goal'
      ? dataEdit.kpi_list
      : dataEdit && type === 'edit-task'
      ? dataEdit.performance_goal.kpi_list
      : props.dataKpi
  const [weightDropdown, setWeightDropdown] = useState(
    dataEdit
      ? {
          value:
            dataEdit?.kpi_weight_unit?.id ||
            dataKpi?.kpi_weight_unit?.id ||
            '-',
          label:
            dataEdit?.kpi_weight_unit?.name ||
            dataKpi?.kpi_weight_unit?.name ||
            '-',
        }
      : null
  )
  const [aspectDropdown, setAspectDropdown] = useState(
    dataEdit
      ? {
          value: dataEdit?.kpi_aspect?.id || dataKpi?.kpi_aspect?.id || '-',
          label: dataEdit?.kpi_aspect?.name || dataKpi?.kpi_aspect?.name || '-',
        }
      : type === 'add-goal'
      ? {
          value: dataKpi?.kpi_aspect?.id,
          label: dataKpi?.kpi_aspect?.name,
        }
      : null
  )

  const idTask = dataEdit && type === 'edit-task' ? dataEdit.id : null
  const isTask = type === 'add-task' || type === 'edit-task'
  const minDate = isTask
    ? dataDateGoal?.startDate || dataEdit?.performance_goal?.startdate
    : undefined
  const maxDate = isTask
    ? dataDateGoal?.endDate || dataEdit?.performance_goal?.enddate
    : undefined
  const isEditedDate =
    dataEdit && dataDateGoal?.startdate !== dataEdit.startdate
  const isOffsideDate = IntersectionOfDate(
    minDate,
    dataDateGoal?.startdate,
    maxDate
  )
  const labelOptions = isTask ? options : labelOptionsGoal

  useEffect(() => {
    if (dataEdit) {
      if (type === 'edit-goal') {
        setValues(v => {
          return {
            ...v,
            kpi: dataKpi?.id,
            method_type: dataKpi?.type,
            metric: dataKpi?.formula,
          }
        })
        if (dataEdit?.performance_goal_group) {
          setSelectedGroup([
            {
              idGroup: dataEdit.performance_goal_group.id,
              value: dataEdit.performance_goal_group.name,
              label: dataEdit.performance_goal_group.name,
              color: dataEdit.performance_goal_group.color,
            },
          ])
        }
      } else if (type === 'edit-task') {
        dataEdit?.performance_task_assignments?.forEach(element => {
          const dataAssign = {
            avatar: element.global_user.avatar,
            idx: idTask,
            label: element.global_user.id,
            organization:
              element.global_user.people_work_placements[0].company_organization
                .name,
            pos:
              element.global_user.people_work_placements[0].company_job_profile
                .title,
            value: element.global_user.name,
          }
          setSelectedRep(selectedRep => [...selectedRep, dataAssign])
        })
        setSelectedGroup([
          {
            idGroup: dataEdit?.performance_group?.id,
            value: dataEdit?.performance_group?.name,
            label: dataEdit?.performance_group?.name,
            color: dataEdit?.performance_group?.color,
          },
        ])
        setValues(v => {
          return {
            ...v,
            target_unit:
              dataEdit?.kpi_weight_unit?.id ||
              dataKpi.kpi_weight_unit.id ||
              null,
            aspect: dataEdit?.kpi_aspect?.id || dataKpi.kpi_aspect.id || null,
            method_type: dataEdit?.method_type ?? 2,
            metric: dataEdit?.metric || dataKpi?.formula || '-',
          }
        })
      } else {
        setValues(v => {
          return {
            ...v,
            goal: dataEdit && dataEdit.performance_goal.id,
          }
        })
      }
      setValues(v => {
        return {
          ...v,
          name: dataEdit.name,
          description: dataEdit.description,
          target: dataEdit.target,
          target_stretch: dataEdit.target_stretch,
          weight: dataEdit.weight,
          progress: dataEdit.progress,
          priority: dataEdit.priority,
          visibility: dataEdit.visibility,
          startdate: dataEdit.startdate,
          enddate: dataEdit.enddate,
          status: dataEdit.status,
          attachment: dataEdit.attachment,
          cascading_kpi:
            dataKpi.kpi_cascading_lists &&
            dataKpi.kpi_cascading_lists[0] &&
            dataKpi.kpi_cascading_lists[0].id,
        }
      })
    } else {
      if (type === 'add-goal') {
        setValues(v => {
          return {
            ...v,
            kpi: dataKpi?.id,
            status: 'OPEN',
            method_type: dataKpi?.type ?? '-',
            metric: dataKpi?.formula ?? '-',
          }
        })
      } else {
        setValues(v => {
          return {
            ...v,
            goal: props.idGoals,
            status: 'TODO',
            performance_task_assignments: {
              data: [{user: USER_ID}],
            },
            reporter: USER_ID,
            metric: dataKpi?.formula || '',
          }
        })
      }
    }
  }, [])

  if (errorWeight || errorAspect) {
    console.error(errorWeight || errorAspect)
  }

  const _dataWeight = dataWeight?.kpi_weight_units?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const _dataApsect = dataAspect?.kpi_aspects?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  const [addGoals] = useMutation(ADD_NEW_GOALS)
  const [addTasks] = useMutation(ADD_NEW_TASKS)
  const [updateGoals] = useMutation(UPDATE_GOALS(dataEdit && dataEdit.id))
  const [updateTasks] = useMutation(UPDATE_TASKS)
  const [addGroup] = useMutation(ADD_NEW_GROUP)
  const [addGoalGroup] = useMutation(ADD_NEW_GOAL_GROUP)
  const [deleteTaskGroup] = useMutation(DELETE_TASK_GROUP)
  const [deleteGoalGroup] = useMutation(DELETE_GOAL_GROUP)
  const [updateGroup] = useMutation(UPDATE_GROUP_TASK)

  const handleChangeGroup = dataGroups => {
    const multipleValue = {...dataGroups}
    if (isTask && type === 'edit-goal') {
      updateGroup({
        variables: {idGroup: multipleValue.idGroup, idTask: idTask},
      })
        .then(() => {
          enqueueSnackbar('Tersimpan', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          setSelectedGroup(oldArray => {
            const arr = [...oldArray, multipleValue]
            arr.reverse()
            const revArr = arr
            const newValue = _.uniqBy(revArr, 'idParent')

            return newValue
          })
        })
        .catch(() => {
          enqueueSnackbar('Kesalahan perbarui data, harap coba lagi', {
            variant: 'error',
            autoHideDuration: 1000,
          })
        })
    } else {
      setValues({...values, group: multipleValue.idGroup})
      setSelectedGroup(oldArray => {
        const arr = [...oldArray, multipleValue]
        arr.reverse()
        const revArr = arr
        const newValue = _.uniqBy(revArr, 'idParent')

        return newValue
      })
    }
  }

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (values?.attachment?.length ?? 0)

    tempFile.forEach(val => {
      if (fileCount > 10) {
        enqueueSnackbar('Jumlah lampiran tidak boleh lebih dari 10', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
        enqueueSnackbar('Ada jenis file yang tidak diperbolehkan', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (val.size >= 1024 * 1024 * 25) {
        enqueueSnackbar('Beberapa ukuran lampiran Anda lebih dari 25 MB', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else {
        handleChangeAttachment(
          val,
          setDisabledButton,
          setValues,
          axios,
          UPLOAD_URL,
          TOKEN
        )
      }
    })
  }

  const handleDeleteAttachButton = i => {
    const newState = [...values.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues(prevState => ({...prevState, attachment: newState}))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...values.attachment]
    newState[i].name = e.target.value
    setValues(prevState => ({...prevState, attachment: newState}))
  }

  const dateChecker = () => {
    const startdateSplit = moment(values.startdate).format('MM/DD/YYYY')
    const enddateSplit = moment(values.enddate).format('MM/DD/YYYY')

    if (moment(enddateSplit).isSame(startdateSplit)) {
      return false
    } else {
      return moment(enddateSplit).isBefore(startdateSplit)
    }
  }
  const handleAddLabel = () => {
    setLabel(true)
    setIdLabel(idTask)
  }

  const onMutation = () => {
    const rawDataGoal = {
      name: values.name,
      description: values.description,
      target: values.target,
      progress: values.progress,
      priority: values.priority,
      visibility: values.visibility,
      startdate: values.startdate,
      enddate: values.enddate,
      status: values.status,
      attachment: values.attachment,
      kpi: values.kpi,
      group: values.group,
    }
    if (type === 'add-goal') {
      const data = rawDataGoal
      if (
        !values.name ||
        values.name.length > 255 ||
        // !values.visibility ||
        !values.description ||
        !values.startdate ||
        !values.enddate ||
        // !values.weight ||
        !values.target ||
        // !values.target_stretch ||
        !values.priority
        // || checkScoring()
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else if (dateChecker()) {
        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 3000)
      } else {
        setDisabled(true)
        addGoals({variables: {data}})
          .then(() => {
            enqueueSnackbar('Berhasil Membuat Data', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setTimeout(() => {
              setDataContext(true)
              props.onClose()
            }, 1000)
          })
          .catch(() => {
            enqueueSnackbar('Kesalahan penambahan data, harap coba lagi', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      }
    } else if (type === 'add-task') {
      const data = values

      if (
        !values.name ||
        values.name.length > 255 ||
        !values.metric ||
        values.metric.length > 250 ||
        !values.target_unit ||
        !values.aspect ||
        !values.method_type ||
        !values.visibility ||
        !values.description ||
        !values.target ||
        // targetTaskValidation() ||
        values.progress === '' ||
        !values.startdate ||
        !values.enddate ||
        // !values.target_stretch ||
        !values.priority
        // || checkScoring()
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else if (dateChecker()) {
        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 3000)
      } else {
        setDisabled(true)
        addTasks({variables: {data}})
          .then(() => {
            enqueueSnackbar('Berhasil Membuat Data', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setTimeout(() => {
              setDataContext(true)
              props.onBack()
            }, 1000)
          })
          .catch(() => {
            enqueueSnackbar('Kesalahan penambahan data, harap coba lagi', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      }
    } else if (type === 'edit-goal') {
      const data = {
        ...rawDataGoal,
        target_stretch: values?.target_stretch,
        cascading_kpi: values?.cascading_kpi,
      }
      if (
        !values.name ||
        values.name.length > 255 ||
        // !values.visibility ||
        !values.description ||
        // !values.weight ||
        !values.target ||
        !values.startdate ||
        !values.enddate ||
        // !values.target_stretch ||
        !values.priority
        // || checkScoring()
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else if (dateChecker()) {
        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 3000)
      } else {
        setDisabled(true)
        updateGoals({variables: {data}})
          .then(() => {
            enqueueSnackbar('Berhasil Mengubah Data', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setTimeout(() => {
              setDataContext(true)
              props.onBack()
            }, 1000)
          })
          .catch(() => {
            enqueueSnackbar('Kesalahan perbarui data, harap coba lagi', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      }
    } else {
      if (
        !values.name ||
        values.name.length > 255 ||
        !values.metric ||
        values.metric.length > 250 ||
        !values.target_unit ||
        !values.method_type ||
        !values.visibility ||
        !values.description ||
        values.target === '' ||
        // targetTaskValidation() ||
        values.progress === '' ||
        !values.startdate ||
        !values.enddate ||
        // !values.target_stretch ||
        !values.priority
        // || checkScoring()
      ) {
        const ids = document.getElementById('top')
        ids.scrollIntoView({behavior: 'smooth'})
        setIsError(true)
        setTimeout(() => setIsError(false), 5000)
      } else if (dateChecker()) {
        setIsErrorDate(true)
        setTimeout(() => {
          setIsErrorDate(false)
        }, 3000)
      } else {
        const dataAssign = selectedRep.map(rep => {
          const a = {task: rep.idx, user: rep.label}
          return a
        })
        const dataUpdate =
          selectedGroup[0].idGroup !== null
            ? {
                name: values.name,
                description: values.description,
                target: values.target,
                target_stretch: values.target_stretch,
                weight: values.weight,
                progress: values.progress,
                priority: values.priority,
                visibility: values.visibility,
                startdate: values.startdate,
                enddate: values.enddate,
                attachment: values.attachment,
                group: selectedGroup[0].idGroup,
                id: idTask,
                assignment: dataAssign,
                changeRecepient:
                  (selectedRep && selectedRep.length) !==
                  (dataEdit &&
                    dataEdit.performance_task_assignments &&
                    dataEdit.performance_task_assignments.length),
              }
            : {
                name: values.name,
                description: values.description,
                target: values.target,
                target_stretch: values.target_stretch,
                weight: values.weight,
                progress: values.progress,
                priority: values.priority,
                visibility: values.visibility,
                startdate: values.startdate,
                enddate: values.enddate,
                attachment: values.attachment,
                id: idTask,
                assignment: dataAssign,
                changeRecepient:
                  (selectedRep && selectedRep.length) !==
                  (dataEdit &&
                    dataEdit.performance_task_assignments &&
                    dataEdit.performance_task_assignments.length),
              }
        setDisabled(true)
        updateTasks({variables: dataUpdate})
          .then(() => {
            enqueueSnackbar('Berhasil Mengubah Data', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setTimeout(() => {
              setDataContext(true)
              props.onBack()
            }, 1000)
          })
          .catch(() => {
            enqueueSnackbar('Kesalahan perbarui data, harap coba lagi', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      }
    }
  }

  const handleChooseColor = event => {
    setColor(event.target.value)
  }
  const handlePicker = () => {
    setColorPicker(true)
  }
  const handleChangePicker = color => {
    setColor(color.hex)
  }
  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    })
  }
  const onSaveLabel = () => {
    const data = {name: labelName, color: color}
    const queryMutation = isTask ? addGroup : addGoalGroup
    const variable = isTask ? {data} : data
    queryMutation({variables: variable})
      .then(() => {
        enqueueSnackbar('Sukses menambah label baru', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setLabelName('')
        setLabel(false)
        refetchGroup()
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Kesalahan penambahan data, harap coba lagi', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }
  const handleLabelName = event => {
    setLabelName(event.target.value)
  }
  const handleChangeRep = (val, i) => {
    const dataRep = {idx: i, ...val}
    const isDuplicate = _.some(selectedRep, dataRep)
    if (!isDuplicate) {
      setSelectedRep(oldArray => [...oldArray, dataRep])
    }
  }
  const handleDelRep = (idTask, name) => {
    const aryName = selectedRep

    const filter = {
      idx: idTask,
      value: name,
    }
    const wbDel = aryName.filter(function(item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] !== filter[key]) return false
      }
      return true
    })
    const arrAfterDel = aryName.filter(e => e !== wbDel[0])
    setSelectedRep(arrAfterDel)
  }

  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      outline: 'none',
      padding: 10,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#055469',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
  }

  const onDeleteLabel = () => {
    const queryDeleteLabel = isTask ? deleteTaskGroup : deleteGoalGroup
    queryDeleteLabel({variables: {id: alertLabel.idLabel}})
      .then(() => {
        enqueueSnackbar('Berhasil menghapus label', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setAlertLabel(INITIAL_ALERT_LABEL)
        refetchGroup()
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Kesalahan hapus data, silakan coba lagi', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleDeleteLabel = async idLabel => {
    const query = isTask ? CHECK_GROUP_TASK : CHECK_GROUP_GOAL

    const {data, error} = await client.query({
      query: query,
      variables: {group: idLabel},
    })

    if (error) {
      return console.error({error})
    }

    const lengthLabel = isTask
      ? data?.performance_tasks?.length
      : data?.performance_goals?.length
    const isExsist = lengthLabel > 0

    setValues({...values, group: null})
    setSelectedGroup([])
    setAlertLabel({
      canDelete: !isExsist,
      idLabel: idLabel,
      open: true,
    })
  }

  const groupMenu = option => {
    const canDelete = option.user === USER_ID
    return (
      <FlexBetween>
        <div style={{width: '80%', display: 'flex'}}>
          <div
            style={{
              background: option.color,
              width: 0,
              borderRadius: 5,
              padding: 10,
              marginRight: 12,
            }}
          />
          <LineClamp
            lineClamp={1}
            style={{
              alignSelf: 'center',
              fontSize: 12,
              color: '#a9a8a8',
            }}
          >
            {option.label}
          </LineClamp>
        </div>
        {canDelete && (
          <IconButton
            style={{padding: 0}}
            onClick={() => handleDeleteLabel(option.idGroup)}
          >
            <DeleteOutlineIcon style={{color: '#EF4D5E'}} />
          </IconButton>
        )}
      </FlexBetween>
    )
  }

  const renderValue = option => {
    return (
      <FlexCenter>
        <div
          style={{
            background: option.color || '#A9A8A8',
            width: 0,
            borderRadius: 5,
            padding: 10,
            marginRight: 12,
          }}
        />
        <PsmGrey>{trimString(option.label || 'Tidak Ada', 50)}</PsmGrey>
      </FlexCenter>
    )
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon>search</Icon>
      </components.DropdownIndicator>
    )
  }
  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={classes.spacer}>
          <Avatar src={option.avatar} alt={option.value}></Avatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body2">{option.value}</Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.organization}
          </Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.pos}
          </Typography>
        </div>
        <Icon className={classes.textGrey}>add</Icon>
      </div>
    )
  }

  const nameID = value => {
    switch (value) {
      case 'add-task':
      case 'edit-task':
        return (
          <InputLabel htmlFor="text-field-goal-name">
            Nama {GLOBAL_GLOSSARY.performance.Task}*
          </InputLabel>
        )

      default:
        return (
          <InputLabel htmlFor="text-field-goal-name">
            Nama {GLOBAL_GLOSSARY.performance.Goal}*
          </InputLabel>
        )
    }
  }

  const stylesSelect = {
    control: provided => ({
      ...provided,
      borderColor:
        dataEdit || type === 'add-goal' ? '#b1b3b5' : provided.borderColor,
      backgroundColor: dataEdit ? '#eff2f4' : provided.backgroundColor,

      '&:hover': {borderColor: '#000'},
      marginTop: 16,
      minHeight: 55,
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#bfbfbf',
      }
    },
  }

  const renderType = () => (
    <div className={classes.formChild} style={{marginTop: 25}}>
      <InputLabel htmlFor="select-goal-type">{`${
        isTask
          ? `Tipe ${GLOBAL_GLOSSARY.performance.Task}`
          : `Tipe ${GLOBAL_GLOSSARY.performance.Goal_short}`
      }`}</InputLabel>
      <TextField
        id="select-goal-type"
        select
        style={{width: '60%'}}
        value={values.method_type ?? '-'}
        onChange={handleChange('method_type')}
        margin="normal"
        variant="outlined"
        disabled={type !== 'add-task'}
        error={!values.method_type && isError}
        helperText={
          !values.method_type && isError ? 'Bagian ini diperlukan' : ''
        }
        InputProps={{classes: {disabled: classes.disabled}}}
        SelectProps={selectProps(values.method_type)}
      >
        <MenuItem value="" disabled>
          Pilih{' '}
          {isTask
            ? `Tipe ${GLOBAL_GLOSSARY.performance.Task}`
            : `Tipe ${GLOBAL_GLOSSARY.performance.Goal_short}`}
        </MenuItem>
        {type === 'add-task' ? (
          goalTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            value={isTask ? dataEdit?.method_type ?? '-' : dataKpi?.type ?? '-'}
          >
            {goalType[isTask ? dataEdit?.method_type : dataKpi?.type] ?? '-'}
          </MenuItem>
        )}
      </TextField>
    </div>
  )

  return (
    <Fragment>
      <ModalContent style={{padding: type === 'add-task' && 0}}>
        <form action="">
          <div className={classes.formChild} id="top">
            <InputLabel htmlFor="text-field-goal-name">
              {isTask
                ? `Nama ${GLOBAL_GLOSSARY.performance.Task}*`
                : `Nama ${GLOBAL_GLOSSARY.performance.Goal_short}*`}
            </InputLabel>
            <TextField
              id="text-field-goal-name"
              multiline
              placeholder={
                isTask
                  ? `Tambah Nama ${GLOBAL_GLOSSARY.performance.Task}`
                  : 'Tambah Nama Hasil Kerja'
              }
              margin="normal"
              variant="outlined"
              style={{width: '100%'}}
              value={values.name}
              // onChange={handleChange('name')}
              onChange={e => {
                if (values.name.length >= 255 || e.target.value.length >= 255) {
                  setValues({...values, name: e.target.value.slice(0, 255)})
                } else setValues({...values, name: e.target.value})
              }}
              error={
                (!values.name && isError) ||
                (values.name.length > 255 && isError)
              }
              helperText={
                !values.name && isError
                  ? 'Bagian ini diperlukan'
                  : values.name.length > 255 && isError
                  ? "Name can't be more than 255 characters"
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <GreyTypography variant="body2">
                      {values.name.length >= 255 ? 0 : 255 - values.name.length}
                    </GreyTypography>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {isTask && (
            <>
              <div className={classes.formChild}>
                <InputLabel htmlFor="select-visibility">
                  Visibilitas*
                </InputLabel>
                <TextField
                  id="select-visibility"
                  select
                  style={{width: '30%'}}
                  value={values.visibility}
                  onChange={handleChange('visibility')}
                  margin="normal"
                  variant="outlined"
                  error={!values.visibility && isError}
                  helperText={
                    !values.visibility && isError ? 'Bagian ini diperlukan' : ''
                  }
                >
                  <MenuItem value="" disabled>
                    Pilih Visibilitas
                  </MenuItem>
                  {visibilitys.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </>
          )}

          <div style={customStyle.flexExpansion}>
            <div style={{width: '50%'}}>
              <PmdGrey style={{marginBottom: 8}}>Label</PmdGrey>
              <Select
                value={selectedGroup}
                options={labelOptions}
                onChange={value => handleChangeGroup(value)}
                styles={customSelect}
                placeholder="Label RHK / Rencana Aksi"
                getOptionLabel={groupMenu}
                onInputChange={dataSearchDropdown =>
                  setSearchLabel(dataSearchDropdown)
                }
                components={{
                  SingleValue: ({data}) => renderValue(data),
                  Menu: props => (
                    <Fragment>
                      <components.Menu {...props}>
                        {props.children}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 16,
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              width: '100%',
                            }}
                            onClick={handleAddLabel}
                          >
                            Buat Label Terbaru
                          </Button>
                        </div>
                      </components.Menu>
                    </Fragment>
                  ),
                }}
              />
              {renderType()}
            </div>
            {label && idLabel === idTask && (
              <div style={{margin: 25}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            style={{
                              padding: 10,
                              background: color,
                              borderRadius: 5,
                            }}
                          ></div>
                        </InputAdornment>
                      ),
                    }}
                    value={labelName}
                    onChange={handleLabelName}
                  />
                  <Button
                    style={{marginLeft: 10}}
                    variant="contained"
                    color="primary"
                    disabled={labelName === ''}
                    onClick={onSaveLabel}
                  >
                    Simpan
                  </Button>
                </div>
                <div style={{marginTop: 16}}>
                  <PsmGrey>Pilih Warna</PsmGrey>
                  <div style={customStyle.flexExpansion2}>
                    {colorOptions.map((col, i) => {
                      return (
                        <Radio
                          key={i}
                          onChange={handleChooseColor}
                          value={col.color}
                          checked={color === col.color}
                          icon={
                            <span
                              className={classes.icon}
                              style={{background: col.color}}
                            />
                          }
                          checkedIcon={
                            <Icon
                              className={classes.checkedIcon}
                              style={{
                                background: col.color,
                                fontSize: 20,
                              }}
                            >
                              check
                            </Icon>
                          }
                        ></Radio>
                      )
                    })}
                    <Icon
                      onClick={handlePicker}
                      style={{
                        border: '1px #a9a8a8 solid',
                        color: '#a9a8a8',
                        borderRadius: 5,
                        cursor: 'pointer',
                        fontSize: 19,
                        margin: 9,
                      }}
                    >
                      add
                    </Icon>
                  </div>
                  <div className={classes.flexAbsolute}>
                    {colorPicker && (
                      <Paper style={{padding: 14, marginBottom: 10}}>
                        <Typography variant="body2" gutterBottom>
                          Pilih Warna
                        </Typography>
                        <ChromePicker
                          color={color || false}
                          value={color}
                          onChangeComplete={handleChangePicker}
                        />
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{width: '80%'}}
                            onClick={() => {
                              setColorOptions(prev => [...prev, {color}])
                              setColorPicker(false)
                            }}
                          >
                            Tutup
                          </Button>
                        </div>
                      </Paper>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.formChild}>
            <InputLabel htmlFor="goal-aspect">
              Aspek {GLOBAL_GLOSSARY.performance.Goal_short}
            </InputLabel>
            <Select
              id="goal-aspect"
              options={_dataApsect}
              value={aspectDropdown}
              isLoading={loadingAspect}
              isDisabled={type !== 'add-task'}
              placeholder=""
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  aspect: dataSearchDropdown,
                })
              }
              onChange={event => {
                setValues({
                  ...values,
                  aspect: event.value,
                })
                setAspectDropdown(event)
              }}
              styles={stylesSelect}
            />
            <TextHelper className={classes.marginTextHelper}>
              {!values.aspect && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="select-category">Perspektif</InputLabel>
            <TextField
              id="select-category"
              style={{width: '100%'}}
              value={
                dataKpi &&
                dataKpi.kpi_perspective &&
                dataKpi.kpi_perspective.name
              }
              margin="normal"
              variant="outlined"
              disabled
              InputProps={{classes: {disabled: classes.disabled}}}
            ></TextField>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="text-area-description">Keterangan*</InputLabel>
            <TextField
              id="text-area-description"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              placeholder="Tambah Keterangan"
              value={values.description}
              onChange={handleChange('description')}
              style={{width: '100%'}}
              error={!values.description && isError}
              helperText={
                !values.description && isError ? 'Bagian ini diperlukan' : ''
              }
              inputProps={{ maxLength: 1500 }}
            />
          </div>
          <div style={{marginTop: '2%', marginBottom: '1rem'}}>
            <InputLabel htmlFor="text-field-metric">Metrik</InputLabel>
            <TextField
              id="text-field-metric"
              multiline
              margin="normal"
              variant="outlined"
              value={values.metric}
              placeholder="Penerapan Sistem Kartu Skor"
              disabled={type !== 'add-task'}
              InputProps={{classes: {disabled: classes.disabled}}}
              onChange={handleChange('metric')}
              style={{width: '50%'}}
              error={
                (!values.metric && isError) ||
                (values.metric.length > 250 && isError)
              }
              helperText={
                !values.metric && isError
                  ? 'Bagian ini diperlukan'
                  : values.metric.length > 250 && isError
                  ? "Metric can't be more than 250 characters"
                  : ''
              }
            />
          </div>
          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <div style={{width: '25%', paddingRight: '1rem'}}>
              <InputLabel htmlFor="text-field-scoring-nature">
                Parameter Penilaian
              </InputLabel>
              <TextField
                id="text-field-scoring-nature"
                placeholder="Lebih tinggi lebih baik"
                value={
                  dataKpi &&
                  dataKpi.kpi_scoring_nature &&
                  dataKpi.kpi_scoring_nature.name
                }
                disabled
                InputProps={{classes: {disabled: classes.disabled}}}
                margin="normal"
                variant="outlined"
              />
            </div>
            {isTask && (
              <div style={{width: '25%', paddingRight: '1rem'}}>
                <InputLabel htmlFor="text-field-scoring-nature">
                  Kemajuan Saat Ini*
                </InputLabel>
                <TextField
                  id="text-field-target-current"
                  type="number"
                  margin="normal"
                  variant="outlined"
                  className={classes.helperMarginLeft0}
                  value={values.progress}
                  onChange={handleChange('progress')}
                  fullWidth
                  error={!values.progress && isError}
                  helperText={
                    !values.progress && isError ? 'Bagian ini diperlukan' : ''
                  }
                />
              </div>
            )}
          </div>
          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <div style={{width: '25%', paddingRight: '1rem'}}>
              <InputLabel htmlFor="text-field-target-base">
                {isTask
                  ? `Target ${GLOBAL_GLOSSARY.performance.Task}`
                  : GLOBAL_GLOSSARY.performance['Target Base']}{' '}
                *
              </InputLabel>
              <TextField
                id="text-field-target-base"
                type="number"
                margin="normal"
                variant="outlined"
                placeholder={
                  isTask
                    ? `Tambah Kontribusi pada ${GLOBAL_GLOSSARY.performance.Goal}`
                    : 'Tambah Basis Sasaran'
                }
                className={classes.helperMarginLeft0}
                value={values.target}
                onChange={handleChange('target')}
                fullWidth
                error={
                  !values.target && isError
                  // || targetTaskValidation()
                }
                helperText={
                  !values.target && isError && 'Bagian ini diperlukan'
                  // : targetTaskValidation()
                  // ? 'Target Task must not exceed the Target Goal'
                  // : ''
                }
              />
            </div>

            {isTask ? (
              <>
                <div style={{width: '25%'}} className={classes.weightUnitSyle}>
                  <InputLabel htmlFor="select-weight">Satuan Target</InputLabel>
                  <Select
                    id="select-weight"
                    options={_dataWeight}
                    value={weightDropdown}
                    isLoading={loadingWeight}
                    isDisabled={dataEdit}
                    placeholder=""
                    onInputChange={dataSearchDropdown =>
                      setSearchDropdown({
                        ...searchDropdown,
                        weight: dataSearchDropdown,
                      })
                    }
                    onChange={event => {
                      setValues({
                        ...values,
                        target_unit: event.value,
                      })
                      setWeightDropdown(event)
                    }}
                    styles={stylesSelect}
                  />
                  {!values.target_unit && isError && (
                    <TextHelper className={classes.marginTextHelper}>
                      Bagian ini diperlukan
                    </TextHelper>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingRight: '1rem',
                }}
              >
                <Typography variant="body1" style={{color: '#a9a8a8'}}>
                  {dataKpi?.kpi_weight_unit?.name}
                </Typography>
              </div>
            )}
          </div>

          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{flex: '0.5'}}>
                <InputLabel htmlFor="date-picker-start-date">
                  Tanggal Mulai *
                </InputLabel>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  minDate={minDate}
                  maxDate={maxDate}
                  id="date-picker-start-date"
                  inputVariant="outlined"
                  value={values.startdate}
                  onChange={date =>
                    setValues({
                      ...values,
                      startdate: date,
                      enddate: isTask ? maxDate : values.enddate,
                    })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={!values.startdate && isError}
                />
                {!values.startdate && isError ? (
                  <Typography className={classes.errorDate}>
                    Bagian ini diperlukan
                  </Typography>
                ) : null}
              </div>
              <div>
                <InputLabel htmlFor="date-picker-end-date">
                  Tanggal Akhir *
                </InputLabel>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  minDate={
                    isEditedDate || isOffsideDate || values.startdate
                      ? values.startdate
                      : minDate
                  }
                  maxDate={maxDate}
                  id="date-picker-end-date"
                  inputVariant="outlined"
                  value={values.enddate}
                  onChange={date =>
                    setValues({
                      ...values,
                      enddate: date,
                    })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={isErrorDate || (!values.enddate && isError)}
                />
                {isErrorDate || (!values.enddate && isError) ? (
                  <Typography className={classes.errorDate}>
                    {isErrorDate
                      ? 'end date cannot be before start date'
                      : 'Bagian ini diperlukan'}
                  </Typography>
                ) : null}
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="text-edit-priority">Prioritas *</InputLabel>
            <TextField
              id="text-edit-priority"
              select
              style={{width: '30%'}}
              value={values.priority}
              onChange={handleChange('priority')}
              margin="normal"
              variant="outlined"
              error={!values.priority && isError}
              helperText={
                !values.priority && isError ? 'Bagian ini diperlukan' : ''
              }
              SelectProps={selectProps(values.priority)}
            >
              <MenuItem value="" disabled>
                Pilih Prioritas
              </MenuItem>
              {priorities.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formChild}>
            {type === 'edit-task' ? (
              <>
                <PmdGrey
                  style={{
                    marginBottom: 8,
                    marginTop: 16,
                  }}
                >
                  Penerima
                </PmdGrey>
                <div style={{width: '50%'}}>
                  <Select
                    placeholder="Search..."
                    components={{
                      DropdownIndicator,
                      SingleValue: () => {
                        return 'Search...'
                      },
                    }}
                    value={selectedRep}
                    getOptionLabel={empMenu}
                    options={listEmp}
                    onChange={value => handleChangeRep(value, idTask)}
                    styles={customSelect}
                    isLoading={loading}
                    onInputChange={dataSearchDropdown =>
                      setSearchDropdown({
                        ...searchDropdown,
                        recepient: dataSearchDropdown,
                      })
                    }
                  />
                </div>
                {selectedRep && (
                  <PmdGrey
                    style={{
                      marginBottom: 8,
                      marginTop: 16,
                    }}
                  >
                    Recipient
                  </PmdGrey>
                )}

                <div className={classes.selectedEmp}>
                  {selectedRep?.map((option, i) => {
                    return (
                      <Card key={i} className={classes.cardEmp} elevation={0}>
                        <div className={classes.spacer}>
                          <Avatar
                            src={option.avatar}
                            alt={option.value}
                          ></Avatar>
                        </div>
                        <div>
                          <Typography variant="body2">
                            {option.value}
                          </Typography>

                          <Typography
                            variant="body2"
                            className={classes.textGrey}
                          >
                            {option.pos}
                          </Typography>
                        </div>
                        <Icon
                          className={classes.textGrey}
                          style={{cursor: 'pointer'}}
                          onClick={() => handleDelRep(option.idx, option.value)}
                        >
                          close
                        </Icon>
                      </Card>
                    )
                  })}
                </div>
              </>
            ) : null}
            <div className={classes.formChild} style={{width: '50%'}}>
              <InputLabel
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#a9a8a8',
                }}
              >
                Lampiran (opsional){' '}
                <GenerateTooltip
                  title={<TooltipAttachmentGoal />}
                  placement="right"
                >
                  <InfoOutlinedIcon
                    style={{marginLeft: 7, width: 16}}
                    color="primary"
                  />
                </GenerateTooltip>
              </InputLabel>
              <ButtonAttachment
                multiple
                id={'add-attachment'}
                disabled={values?.attachment?.length > 10 || disabledButton}
                onUpload={handleChangeFile}
              />
              {values?.attachment?.map((res, i) => {
                const {name, size, url, link, isUploading, loaded} = res
                const file_url = url || link
                return (
                  <ItemAttachment
                    res={res}
                    key={`${file_url}-${i}`}
                    id={`${file_url}-${i}`}
                    size={size}
                    onDelete={() => handleDeleteAttachButton(i)}
                    src={file_url}
                    onChange={e => handleChangeNameFileAttBtn(e, i)}
                    fileName={name}
                    isUploading={isUploading}
                    loaded={loaded}
                  />
                )
              })}
            </div>
          </div>

          <DialogActions style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 12}}>
              <Button
                size="large"
                onClick={() => {
                  props.onBack()
                  props.resetId && props.resetId()
                }}
              >
                Batal
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={onMutation}
                size="large"
                disabled={disabled}
              >
                Simpan
              </Button>
            </div>
          </DialogActions>
        </form>
        <ModalDelete
          open={alertLabel.open && alertLabel.canDelete}
          handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
          name="label ini"
          isHighlight={false}
          description="All goal / task that using this label will be unassigned to any label once you delete this label"
          mutation={onDeleteLabel}
          canClose={false}
        />
        <ConfirmationPopup
          open={alertLabel.open && !alertLabel.canDelete}
          handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
          handleCloseForm={() => setAlertLabel(INITIAL_ALERT_LABEL)}
          feature="Tidak dapat menghapus Label"
          message="Tidak dapat menghapus karena label ini masih digunakan pada RHK atau Rencana Aksi"
          type="Konfirmasi"
          isCancel={false}
        />
      </ModalContent>
    </Fragment>
  )
}
