import {
  Avatar,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Tab as TabMui,
  Tabs,
  Typography,
} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import React, {useState} from 'react'
import noBadge from '../../../../../assets/images/icon_badges_not_found.svg'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {clamp, trimString} from '../../../../../utils/helpers'

import {GoalTab, SummaryTab, TaskTab} from '../detail-squad-member'

const useStyles = makeStyles(() => ({
  avatar: {width: 56, height: 56, marginRight: 24},
  userName: {fontSize: 14, fontWeight: 600, color: '#014a62'},
  title: {fontSize: 12, color: '#a9a8a8', marginTop: 8},
  badge: {width: 48, height: 'auto', margin: 'auto', marginBottom: 4},
  titleBadge: {
    fontSize: 12,
    fontWeight: '600',
    color: '#014a62',
    textAlign: 'center',
  },
  companyBadge: {
    ...clamp(1),
    fontSize: 12,
    fontWeight: '600',
    color: '#a9a8a8',
    textAlign: 'center',
  },
  score: {
    fontSize: 12,
    color: '#039be5',
    fontWeight: '600',
    textAlign: 'center',
  },
  greyBold: {
    fontSize: 12,
    color: '#a9a8a8',
    textAlign: 'center',
    fontWeight: 600,
    marginTop: 4,
  },
  rootSummary: {
    padding: '12px 14px 12px 32px',
    minHeight: 165,
    boxSizing: 'border-box',
  },
  contentSummary: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    borderRadius: '5px',
    width: '99%',
    margin: 'auto auto 26px !important',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '&::before': {
      content: 'none',
    },
  },
  expanDetail: {padding: 0, display: 'block'},
  tabsRoot: {
    background: '#f7f8f9',
    width: '100%',
  },
  tabsIndicator: {height: 3},
  tabRoot: {fontSize: 12, color: '#a9a8a8', minWidth: 120},
  tabSelected: {
    fontWeight: '600',
    color: '#333333',
  },
  badgeWrap: {width: 167, textOverflow: 'ellipsis'},
}))

export default function SquadListItem({
  detail,
  setDetail,
  user,
  name,
  avatar,
  badge,
  title,
  badgeTitle,
  badgeName,
  versionName,
  score,
  isLoading = false,
  type,
}) {
  const classes = useStyles()
  const isDireactReportSquad = type === 'direct-report-squad'
  const [tabsValue, setTabsValue] = useState(
    isDireactReportSquad ? 'goal' : 'summary'
  )
  const [expan, setExpan] = useState(false)
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }

  const handleChangeExpansion = (e, isExpanded) => {
    e.preventDefault()
    setExpan(isExpanded)
  }

  return (
    <Accordion
      expanded={expan}
      onChange={handleChangeExpansion}
      classes={{root: classes.root}}
    >
      <AccordionSummary
        classes={{
          root: classes.rootSummary,
          content: classes.contentSummary,
        }}
      >
        <Box display="flex" alignItems="center">
          {isLoading ? (
            <Skeleton height={56} width={56} variant="circle" animation="wave">
              <Avatar className={classes.avatar} />
            </Skeleton>
          ) : (
            <Avatar src={avatar} className={classes.avatar} />
          )}
          {isLoading ? (
            <div style={{marginLeft: 32}}>
              <Skeleton width={312} height={38} animation="wave" />
              <Skeleton width={473} animation="wave" />
            </div>
          ) : (
            <div>
              <Typography className={classes.userName} component="h3">
                {name}
              </Typography>
              <Typography className={classes.title} component="p">
                {title}
              </Typography>
            </div>
          )}
        </Box>
        {isLoading ? (
          <div>
            <Skeleton
              className={classes.badge}
              height={48}
              width={48}
              variant="circle"
              animation="wave"
            />
            <Skeleton width={120} height={38} animation="wave" />
            <Skeleton width={120} animation="wave" />
          </div>
        ) : (
          <div className={classes.badgeWrap}>
            <Avatar
              variant="square"
              src={badge || noBadge}
              className={classes.badge}
            />
            <Typography className={classes.score} component="p">
              {score}
            </Typography>
            <Typography className={classes.titleBadge} component="p">
              {badgeName || 'Tidak Ada Lencana'}
            </Typography>
            <Typography className={classes.companyBadge} component="p">
              {versionName}
            </Typography>
            <Typography className={classes.greyBold} component="p">
              {expan ? badgeTitle : trimString(badgeTitle, 20)}
            </Typography>
          </div>
        )}
      </AccordionSummary>
      {!isLoading && (
        <AccordionDetails classes={{root: classes.expanDetail}}>
          <Tabs
            value={tabsValue}
            onChange={handleChangeTabs}
            indicatorColor="secondary"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            {isDireactReportSquad && (
              <Tab value="goal" label={GLOBAL_GLOSSARY.performance.Goal} />
            )}
            {isDireactReportSquad && (
              <Tab value="task" label={GLOBAL_GLOSSARY.performance.Task} />
            )}
            <Tab value="summary" label="Ringkasan" />
          </Tabs>
          {isDireactReportSquad && tabsValue === 'goal' && (
            <GoalTab
              user={user}
              isExpanded={expan}
              detail={detail}
              setDetail={setDetail}
            />
          )}
          {isDireactReportSquad && tabsValue === 'task' && (
            <TaskTab
              user={user}
              isExpanded={expan}
              detail={detail}
              setDetail={setDetail}
            />
          )}
          {tabsValue === 'summary' && (
            <SummaryTab
              user={user}
              isExpanded={expan}
              detail={detail}
              setDetail={setDetail}
            />
          )}
        </AccordionDetails>
      )}
    </Accordion>
  )
}

const Tab = props => {
  const classes = useStyles()

  return (
    <TabMui
      {...props}
      variant=""
      classes={{
        selected: classes.tabSelected,
        root: classes.tabRoot,
      }}
    />
  )
}
