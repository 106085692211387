import gql from 'graphql-tag'

export const GET_LIST_TASK_FOR_ME = gql`
  query($user: uuid!, $company: uuid!, $goal: bigint, $progressTo: float8) {
    performance_tasks(
      where: {
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
        goal: {_eq: $goal}
        progress: {_gte: $progressTo, _lte: $progressTo}
        status: {_in: ["COMPLETED", "ARCHIVED"]}
      }
      order_by: [{last_modified: desc}]
    ) {
      id
      name
      performance_group {
        id
        name
        color
      }
      performance_task_assignments {
        global_user {
          id
          name
          avatar
        }
      }
      enddate
      progress
      progress_percentage
      status
      last_modified
      weight
      priority
      target
      performance_goal {
        id
        kpi_list {
          id
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
export const GET_LIST_FILTER_GOAL = gql`
  query listMyGoal(
    $id: uuid
    $limit: Int
    $offset: Int
    $search: String
    $latest: order_by
  ) {
    performance_goals(
      where: {
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $id}}
            }
          }
          {created_by: {_eq: $id}}
        ]
        name: {_ilike: $search}
      }
      limit: $limit
      offset: $offset
      order_by: [{date_added: $latest}]
    ) {
      id
      name
      status
      priority
      weight
      startdate
      enddate
      target
      performance_tasks {
        id
        weight
        target
        progress
        performance_task_assignments(limit: 5) {
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`
