import gql from 'graphql-tag'

export const GET_DROPDOWN_WEIGHT_UNIT = gql`
  query($company: uuid, $search: String) {
    kpi_weight_units(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
      limit: 10
    ) {
      id
      name
    }
  }
`

export const GET_DROPDOWN_PERSPECTIVE = gql`
  query($company: uuid, $search: String) {
    kpi_perspectives(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
      limit: 10
    ) {
      id
      name
    }
  }
`

export const GET_DROPDOWN_SCORING_NATURE = gql`
  query($company: uuid, $search: String) {
    kpi_scoring_natures(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
      limit: 10
    ) {
      id
      name
    }
  }
`

export const GET_DROPDOWN_GOAL_ASPECT = gql`
  query($company: uuid, $search: String) {
    kpi_aspects(
      where: {company: {_eq: $company}, name: {_ilike: $search}}
      limit: 10
    ) {
      id
      name
    }
  }
`

export const GET_DROPDOWN_GOAL_ASPECTS = gql`
  query($company: uuid!, $search: String, $offset: Int, $limit: Int) {
    total: kpi_aspects_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }

    kpi_aspects(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
  }
`
