import React from 'react'
import {WrapperBtn} from './Attachment.style'
import AddIcon from '@material-ui/icons/Add'

export default function ButtonAttachment({disabled, id, multiple, onUpload}) {
  return (
    <>
      <label htmlFor={id}>
        <WrapperBtn disabled={disabled}>
          <AddIcon style={{fontSize: 44}} color="secondary" />
        </WrapperBtn>
      </label>
      <input
        disabled={disabled}
        onChange={onUpload}
        style={{visibility: 'hidden', display: 'none'}}
        type="file"
        id={id}
        name="updateFile"
        multiple={multiple}
      />
    </>
  )
}
