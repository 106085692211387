import React, {useMemo, useState} from 'react'
import {
  TableRow,
  TableCell,
  Typography,
  Button,
  Snackbar,
} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {
  GET_HIRARCY_MULTIRATER,
  GET_LIST_MULTIRATER_SURVEY,
} from '../../../graphql/queries'

import useStyles, {
  StatusRow,
  Thcustom,
  Tdcustom,
  TableRowHover,
} from './SurveyStyles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Skeleton from '@material-ui/lab/Skeleton'

import {USER_ID} from '../../../utils/globals'
import {SeeMore} from '../PerformanceStyles'

import {getDate, isDateBefore, isDateExpire} from '../../../utils/helpers'

function ListMultirater(props) {
  const {filter, job_profile} = props
  const classes = useStyles()
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [hierarchyVars, setHierarchyVars] = useState(null)
  const [offset, setOffset] = useState(0)
  const [openSnackbar, setOpenSnackbar] = useState({
    open: false,
    message: '',
  })

  useQuery(GET_HIRARCY_MULTIRATER, {
    skip: !job_profile,
    variables: {jobProfile: job_profile},
    fetchPolicy: 'network-only',
    onCompleted: data => setHierarchyVars(getHierarchyIds(data)),
  })

  const {data, error, loading, fetchMore} = useQuery(
    GET_LIST_MULTIRATER_SURVEY,
    {
      fetchPolicy: 'network-only',
      skip: !job_profile || !hierarchyVars,
      variables: {
        user: USER_ID,
        offset: offset,
        limit: 10,
        search: `%${filter.search_filter}%`,
        from: getVarsDate(filter, 'dateFrom'),
        to: getVarsDate(filter, 'dateTo'),
        orderBy: [{date_created: filter.orderBy}],
        ...hierarchyVars,
      },
    }
  )

  useMemo(() => setOffset(0), [filter])

  let typelist = ''

  const dataLength = data?.multirater_surveys?.length
  const total = data?.total?.aggregate?.count
  const loadingData =
    loading || loadingFetchMore || !job_profile || !hierarchyVars

  if (error) console.error(error)

  if (!loading && total === 0) {
    return (
      <TableRow>
        <TableCell colSpan="4">Tidak ada data pada survei multirater</TableCell>
      </TableRow>
    )
  }

  const handleSeeMore = async () => {
    setLoadingFetchMore(true)
    await fetchMore({
      variables: {offset: dataLength},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          multirater_surveys: [
            ...prev.multirater_surveys,
            ...fetchMoreResult.multirater_surveys,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
    setLoadingFetchMore(false)
  }

  const handleRowClicked = (row, status) => {
    if (status === 'Queued') {
      setOpenSnackbar({
        open: true,
        message: 'Survey is still in the queue',
      })
    } else {
      props.history.push({
        pathname: '/multirater/' + row.id,
        state: {status},
      })
    }
  }

  return (
    <React.Fragment>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{color: 'black'}}>
            <Thcustom>Judul</Thcustom>
            <Thcustom>Tipe</Thcustom>
            <Thcustom>Periode</Thcustom>
            <Thcustom>Status</Thcustom>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data?.multirater_surveys?.map((row, key) => {
              let status
              if (row.status === 'COMPLETED') {
                status = 'Tutup'
              } else if (!isDateBefore(row.startdate, new Date())) {
                status = 'Queued'
              } else if (
                row.answered.aggregate.count >=
                  row.count_respondents.aggregate.count &&
                row.answered.aggregate.count !== 0
              ) {
                status = 'Selesai'
              } else if (
                isDateExpire(row.enddate, new Date()) ||
                row.status === 'COMPLETED'
              ) {
                status = 'Tutup'
              } else {
                status = 'Buka'
              }

              if (
                row.is_multirater.aggregate.count > 0 &&
                row.is_nps.aggregate.count > 0
              ) {
                typelist = 'Multirater & NPS'
              } else if (row.is_multirater.aggregate.count > 0) {
                typelist = 'Multirater'
              } else if (row.is_nps.aggregate.count > 0) {
                typelist = 'NPS'
              }
              return (
                <TableRowHover
                  key={key}
                  onClick={() => handleRowClicked(row, status)}
                >
                  <Tdcustom>
                    <Typography>{row.title}</Typography>
                  </Tdcustom>
                  <Tdcustom>{typelist}</Tdcustom>
                  <Tdcustom>
                    {getDate(row.startdate)} - {getDate(row.enddate)}
                  </Tdcustom>
                  <Tdcustom>
                    <StatusRow
                      style={{
                        backgroundColor: STATUS_COLOR[status] || '#039bE5',
                      }}
                    >
                      {status}
                    </StatusRow>
                  </Tdcustom>
                </TableRowHover>
              )
            })}
        </TableBody>
      </Table>

      {loadingData &&
        Array.from({length: 10}).map((val, i) => (
          <Skeleton
            key={`${val}-${i}`}
            variant="rect"
            height={47}
            style={{margin: 16}}
          />
        ))}
      {!loadingData && dataLength < total && (
        <SeeMore borderTop="none">
          <Button
            style={{
              textTransform: 'none',
            }}
            color="secondary"
            onClick={handleSeeMore}
          >
            Lihat semua
          </Button>
        </SeeMore>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnackbar.open}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar({open: false, message: ''})}
        message={openSnackbar.message}
      />
    </React.Fragment>
  )
}

export default withRouter(ListMultirater)

const getHierarchyIds = data => {
  const hierarchies = [
    'second_supervisor',
    'supervisor',
    'peer',
    'subordinate',
    'second_subordinate',
  ]

  let obj = {}
  hierarchies.forEach(hierarchy => {
    obj = {...obj, [hierarchy]: data[hierarchy].map(({id}) => id)}
  })

  return obj
}

const getVarsDate = (f, t) => (f[t] !== 'Invalid date' && f[t]) || null

const STATUS_COLOR = {
  Queued: '#ffa000',
  Selesai: '#4caf50',
  Tutup: '#EF4D5E',
}
