import React from 'react'
import {Button, Typography} from '@material-ui/core'
import {GreyTypography} from '../typography/TypographyStyles'

const EmptyState = ({
  message1,
  image,
  message2,
  styleMessage2 = {},
  buttonText,
  onClickButton,
}) => {
  return (
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        height: '50vh',
        alignItems: 'center',
      }}
    >
      <div>
        <img
          src={image}
          alt="no-data"
          height="200px"
          width="auto"
          style={{marginBottom: 24}}
        ></img>
        <Typography variant="h6">{message1}</Typography>
        <GreyTypography style={styleMessage2} variant="body1">{message2}</GreyTypography>
        {buttonText && (
          <Button
            disableElevation
            color="primary"
            variant="contained"
            onClick={onClickButton}
            style={{marginTop: 16, padding: '10px 42px'}}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default EmptyState
