export const customSelect = {
  control: (provided) => ({
    ...provided,
    width: "100%",
    outline: "none",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "#055469",
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: "#fff",
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: "#ffff",
    "&:hover": { color: "#ffff", backgroundColor: "rgba(0,0,0,0)" },
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: 'muli'
  }),
  noOptionsMessage: (base) => ({
    ...base,
    fontFamily: 'muli'
  }),
  loadingMessage: (base) => ({
    ...base,
    fontFamily: 'muli'
  })
};

