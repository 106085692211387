import React from 'react'

import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0px',
    boxSizing: 'border-box',
    width: 236,
  },
  icon: {
    width: 55,
    height: 55,
    marginRight: 18,
  },
  title: {
    fontSize: 20,
    color: '#055469',
    margin: '0 0 8px',
    fontWeight: '500',
  },
  desc: {
    fontSize: 14,
    color: '#a9a8a8',
    margin: 0,
    maxWidth: 140,
  },
  iconWrapper: {
    borderRadius: '50%',
    border: '1px solid #055469',
    width: 55,
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 18,
  },
})

export default function IconItem({value, src, description}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <img src={src} alt={description} />
      </div>
      <div>
        <h5 className={classes.title}>{value}</h5>
        <p className={classes.desc}>{description}</p>
      </div>
    </div>
  )
}
