import gql from "graphql-tag";

export const UPDATE_SUBORDINATE_SQUAD_INFO = gql`
  mutation($user: uuid!, $info: jsonb!) {
    update_people_work_placements(
      where: { user: { _eq: $user }, status: { _eq: "ACTIVE" } }
      _append: { placement_fields: $info }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_SUBORDINATE_SQUAD_MEMBER = gql`
  mutation($placement: Int!) {
    delete_performance_teams(
      where: {
        group: { _is_null: true }
        member_placement: { _eq: $placement }
      }
    ) {
      affected_rows
    }
  }
`;

export const ADD_SUBORDINATE_SQUAD_MEMBER = gql`
  mutation addMyTeamPerformance($team: [performance_teams_insert_input!]!) {
    insert_performance_teams(objects: $team) {
      affected_rows
    }
  }
`;
