import gql from 'graphql-tag'

export const GET_EMOJI_FOR_ME = id => {
  return gql`
    {
      satisfied_all: ongoing_feedback_lists_aggregate(
        where: {
          ongoing_to: {_eq: "${id}"} #still hardcoed
          summary: {_eq: "SATISFIED"}
        }
      ) {
        aggregate {
          count
        }
      }
      ordinary_all: ongoing_feedback_lists_aggregate(
        where: {
          ongoing_to: {_eq: "${id}"} #still hardcoed
          summary: {_eq: "ORDINARY"}
        }
      ) {
        aggregate {
          count
        }
      }
      disappointed_all: ongoing_feedback_lists_aggregate(
        where: {
          ongoing_to: {_eq: "${id}"} #still hardcoed
          summary: {_eq: "DISAPPOINTED"}
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `
}
