import React, {Fragment, useState} from 'react'

import {Divider, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import IconItem from './IconItem'
import SummaryContainer from './SummaryContainer'
import Doughnut from '../../../../../../components/chart/Doughnut'

import avg from '../../../../../../assets/images/icons/summary-icon/avg-score.svg'
import total from '../../../../../../assets/images/icons/summary-icon/total.svg'
import totalComplete from '../../../../../../assets/images/icons/summary-icon/total-complete.svg'
import totalDone from '../../../../../../assets/images/icons/summary-icon/total-done.svg'
import totalInprogress from '../../../../../../assets/images/icons/summary-icon/total-inprogress.svg'
import totalTodo from '../../../../../../assets/images/icons/summary-icon/total-todo.svg'

import {GET_TOTAL_TASK} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {dateFormat} from '../../../../../../utils/helpers'
import {getAggregate} from '../../../squadHelper'
import {GLOBAL_GLOSSARY} from '../../../../../../utils/constant'

const useStyles = makeStyles({
  container: {padding: 22},
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '24px 12px',
    flexWrap: 'wrap',
    marginBottom: 40,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dividerVertical: {minHeight: 378},
  titleChart: {fontSize: 16, margin: '0 0 24px'},
  chartRoot: {width: '45%'},
  chartWrap: {display: 'flex', flexDirection: 'column'},
  tableLegendRoot: {marginTop: 20},
  tableLegend: {verticalAlign: 'top'},
  tableDot: {
    borderRadius: '50%',
    height: 12,
    width: 12,
    margin: '2px 0px 0px 0px',
    boxSizing: 'border-box',
    '-moz-box-sizing': 'border-box',
    '-webkit-box-sizing': 'border-box',
  },
  tableLabel: {
    fontSize: 14,
    margin: '0 0 14px 0',
  },
  tableValue: {
    fontSize: 14,
    color: '#a9a8a8',
    margin: '0 0 14px 0',
    textAlign: 'right',
  },
  avg: {fontSize: 14, margin: '24px 0 0'},
  avgScore: {color: '#014a62', fontWeight: '600', marginRight: 8},
})

export default function TaskSummary({user}) {
  const classes = useStyles()
  const [date, setDate] = useState({
    start: null,
    end: null,
  })

  const {data, error} = useQuery(GET_TOTAL_TASK, {
    skip: !user,
    variables: {
      user,
      company: COMPANY_ID,
      filterDate: {
        _gte: dateFormat({
          date: date?.start,
          format: 'YYYY-MM-DD',
          nullHandler: null,
        }),
        _lte: dateFormat({
          date: date?.end,
          format: 'YYYY-MM-DD',
          nullHandler: null,
        }),
      },
    },
  })

  if (error) {
    console.error(error)
  }

  const handleChangeDate = (date, type) => {
    setDate(prev => ({...prev, [type]: date}))
  }

  const Icons = [
    {
      value: getAggregate(data, 'all_task').avg + '%',
      description: `Rata-rata Skor ${GLOBAL_GLOSSARY.performance.Task}`,
      src: avg,
    },
    {
      value: getAggregate(data, 'all_task').total,
      description: `Total ${GLOBAL_GLOSSARY.performance.Task}`,
      src: total,
    },
    {
      value: getAggregate(data, 'all_task_completed').total,
      description: `Total ${GLOBAL_GLOSSARY.performance.Task} Sudah Terlaksana`,
      src: totalComplete,
    },
    {
      value: getAggregate(data, 'all_task_todo').total,
      description: `Total ${GLOBAL_GLOSSARY.performance.Task} Harus dikerjakan`,
      src: totalTodo,
    },
    {
      value: getAggregate(data, 'all_task_inprogress').total,
      description: `Total ${GLOBAL_GLOSSARY.performance.Task} Sedang dikerjakan`,
      src: totalInprogress,
    },
    {
      value: getAggregate(data, 'all_task_done').total,
      description: `Total ${GLOBAL_GLOSSARY.performance.Task} Selesai dikerjakan`,
      src: totalDone,
    },
  ]

  return (
    <SummaryContainer
      date={date}
      onChangeDate={handleChangeDate}
      title={GLOBAL_GLOSSARY.performance.Task}
    >
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          {Icons?.map((icon, i) => (
            <IconItem key={i} {...icon} />
          ))}
        </div>
        <div className={classes.chartContainer}>
          <Chart
            title={`${GLOBAL_GLOSSARY.performance.Task} Pegawai`}
            type="personal"
            data={data}
            legendTitle={`${GLOBAL_GLOSSARY.performance.Task}`}
            avgLabel={`Skor rata-rata ${GLOBAL_GLOSSARY.performance.Task}:`}
          />
          <Divider className={classes.dividerVertical} orientation="vertical" />
          <Chart
            title={`${GLOBAL_GLOSSARY.performance.Task} Instansi`}
            type="company"
            data={data}
            legendTitle={`${GLOBAL_GLOSSARY.performance.Task}`}
            avgLabel={`Skor rata-rata ${GLOBAL_GLOSSARY.performance.Task}:`}
          />
        </div>
      </div>
    </SummaryContainer>
  )
}

const Chart = ({title, data, legendTitle, type, avgLabel}) => {
  const classes = useStyles()

  const generatedChartData = {
    labels: [
      'Akan Dikerjakan',
      'Sudah Terlaksana',
      'Selesai',
      'Dalam Pengerjaan',
    ],
    datasets: [
      {
        data: [
          getAggregate(data, `${type}_task_todo`).total,
          getAggregate(data, `${type}_task_done`).total,
          getAggregate(data, `${type}_task_completed`).total,
          getAggregate(data, `${type}_task_inprogress`).total,
        ],
        backgroundColor: color,
        hoverBackgroundColor: color,
        hoverOffset: 50,
        borderWidth: 0,
      },
    ],
  }

  const generatedLegendData = [
    {
      label: 'Akan Dikerjakan',
      value: getAggregate(data, `${type}_task_todo`).total,
      isdot: true,
      color: color[0],
    },
    {
      label: 'Dalam Pengerjaan',
      value: getAggregate(data, `${type}_task_inprogress`).total,
      isdot: true,
      color: color[3],
    },
    {
      label: 'Skor rata-rata dalam pengerjaan',
      value: getAggregate(data, `${type}_task_inprogress`).avg + '%',
      isdot: false,
    },
    {
      label: 'Sudah Terlaksana',
      value: getAggregate(data, `${type}_task_done`).total,
      isdot: true,
      color: color[1],
    },
    {
      label: 'Skor rata-rata sudah terlaksana',
      value: getAggregate(data, `${type}_task_done`).avg + '%',
      isdot: false,
    },
    {
      label: 'Selesai',
      value: getAggregate(data, `${type}_task_completed`).total,
      isdot: true,
      color: color[2],
    },
    {
      label: 'Skor rata-rata selesai',
      value: getAggregate(data, `${type}_task_completed`).avg + '%',
      isdot: false,
    },
  ]

  const avg_task = getAggregate(data, `${type}_task`).avg + '%'
  const total_task = getAggregate(data, `${type}_task`).total

  return (
    <div className={classes.chartRoot}>
      <h3 className={classes.titleChart}>{title}</h3>
      <div className={classes.chartWrap}>
        <Doughnut
          data={generatedChartData}
          total={total_task}
          title={legendTitle}
        />
        <Legend data={generatedLegendData} />
      </div>
      <p className={classes.avg}>
        {avgLabel} <span className={classes.avgScore}>{avg_task}</span>
      </p>
    </div>
  )
}

const Legend = ({data}) => {
  const classes = useStyles()
  return (
    <table className={classes.tableLegendRoot}>
      <tbody>
        {data?.map((row, i) => (
          <Fragment key={i}>
            <tr>
              <td className={classes.tableLegend}>
                <div
                  style={{
                    border: `3px solid ${row.color || '#fff'}`,
                  }}
                  className={classes.tableDot}
                />
              </td>
              <td>
                <p className={classes.tableLabel}>{row.label}</p>
              </td>
              <td>
                <p className={classes.tableValue}>{row.value}</p>
              </td>
            </tr>
            {row.description && (
              <tr>
                <td>
                  <p className={classes.tableLabel}>{row.description}</p>
                </td>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

const color = ['#1f78b4', '#a6cee3', '#b2df8a', '#33a02c']
