import gql from 'graphql-tag'

export const GET_TASK_CONDITION = condition => {
  return gql`
    subscription {
      performance_tasks ${condition} {
        id
        name
        description
        attachment
        visibility
        startdate
        enddate
        date_added
        status
        priority
        weight
        target
        target_stretch
        progress
        progress_percentage
        last_modified
        created_by_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_employee_position {
              id
              name
            }
            company_profile {
              id
              brand_name
            }
            company_job_profile {
              id
              title
            }
          }
        }
        ReporterUser{
          id
          name
          avatar
          people_work_placements(where:{status:{_eq:"ACTIVE"}}){
            id
            regno
            company_job_profile{
              id
              title
            }
            company_profile {
              id
              brand_name
            }
          }
        }
        performance_group {
          id
          name
          color
        }
        performance_goal {
          id
          name
          description
          kpi_list {
            id
            name
            formula
            description
            kpi_scoring_nature {
              id
              name
            }
            kpi_weight_unit {
              id
              name
            }
            kpi_aspect {
              id
              name
            }
            kpi_level {
              id
              name
            }
            kpi_version {
              id
              name
            }
            kpi_method {
              id
              name
            }
            kpi_monitoring_frequency {
              id
              name
            }
            kpi_perspective {
              id
              name
            }
          }
        }
        performance_task_assignments {
          id
          global_user {
            id
            name
            avatar
            people_work_placements {
              id
              company_employee_position {
                id
                name
              }
              company_profile {
                id
                brand_name
              }
              company_organization{
                id
                name
              }
            }
          }
        }
        performance_task_activities(where:{attachment:{_is_null:false}} limit:5, order_by:[{id:desc}]){
          id
          attachment
        }
        performance_group{
          id
          name
        }
      }
    }
  `
}

export const GET_LITE_ASSIGN_TASK = gql`
  query(
    $company: uuid
    $user: uuid
    $goal: bigint_comparison_exp
    $search: String
    $priority: enum_mpp_priority_comparison_exp
  ) {
    performance_tasks(
      where: {
        company: {_eq: $company}
        goal: $goal
        priority: $priority
        status: {_neq: "ARCHIVED"}
        name: {_ilike: $search}
        _or: [
          {performance_task_assignments: {user: {_eq: $user}}}
          {
            performance_goal: {
              global_user: {
                people_work_placements: {
                  company_job_profile: {
                    company_organization: {
                      company_job_profiles: {
                        people_work_placements: {user: {_eq: $user}}
                      }
                    }
                  }
                }
              }
              kpi_list: {
                _or: [
                  {
                    kpi_cascading_lists: {
                      cascading_main: {status: {_eq: "ACTIVE"}}
                    }
                  }
                  {_not: {kpi_cascading_lists: {}}}
                ]
              }
            }
          }
        ]
      }
      limit: 30
    ) {
      id
      name
      priority
      status
    }
  }
`

export const GET_LIST_TAB_TASK = gql`
  query getLazyTaskByGoal(
    $id: bigint
    $offset: Int
    $limit: Int
    $search: String
  ) {
    performance_tasks(
      where: {goal: {_eq: $id}, name: {_ilike: $search}}
      order_by: [{name: asc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      priority
      progress
      progress_percentage
      status
      kpi_weight_unit {
        id
        name
      }
    }
    performance_tasks_aggregate(
      where: {goal: {_eq: $id}}
      order_by: [{name: asc_nulls_last}]
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DETAIL_TAB_TASK = gql`
  query getDetailDetailTask($id: bigint) {
    performance_tasks(where: {id: {_eq: $id}}) {
      id
      name
      description
      attachment
      visibility
      startdate
      enddate
      date_added
      status
      priority
      method_type
      metric
      weight
      aspect
      target
      target_stretch
      progress
      progress_percentage
      last_modified
      kpi_aspect {
        id
        code
        name
      }
      kpi_weight_unit {
        id
        name
      }
      created_by_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_profile {
            id
            brand_name
          }
          company_job_profile {
            id
            title
          }
        }
      }
      ReporterUser {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
          }
        }
      }
      performance_goal {
        id
        name
        status
        description
        target
        priority
        visibility
        startdate
        enddate
        attachment
        kpi_list {
          id
          name
          formula
          description
          kpi_scoring_nature {
            id
            name
          }
          kpi_weight_unit {
            id
            name
          }
          kpi_aspect {
            id
            name
          }
          kpi_level {
            id
            name
          }
          kpi_version {
            id
            name
          }
          kpi_method {
            id
            name
          }
          kpi_monitoring_frequency {
            id
            name
          }
          kpi_perspective {
            id
            name
          }
          kpi_cascading_lists {
            id
          }
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      performance_task_assignments {
        id
        global_user {
          id
          name
          avatar
          people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
            id
            company_profile {
              id
              brand_name
            }
            company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`

export const GET_LIST_ASSIGN_TASK = gql`
  query getDetailDetailTask($id: [bigint!]) {
    performance_tasks(where: {id: {_in: $id}}) {
      id
      name
      priority
      performance_task_assignments {
        id
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_employee_position {
              id
              name
            }
            company_profile {
              id
              brand_name
            }
            company_organization {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_LIST_CONTAINER_TASK = gql`
  query lazyLoadTask($id: bigint!) {
    performance_tasks_by_pk(id: $id) {
      id
      name
      visibility
      status
      priority
      progress
      metric
      progress_percentage
      created_by_user {
        id
        name
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_profile {
            id
            brand_name
          }
        }
      }
      performance_group {
        id
        name
        color
      }
      performance_task_assignments {
        id
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_employee_position {
              id
              name
            }
            company_profile {
              id
              brand_name
            }
            company_organization {
              id
              name
            }
          }
        }
      }
      performance_goal {
        id
        kpi_list {
          id
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const GET_DETAIL_CONTAINER_TASK = gql`
  query lazyLoadTask($id: bigint!) {
    performance_tasks_by_pk(id: $id) {
      id
      name
      description
      attachment
      visibility
      startdate
      enddate
      date_added
      status
      priority
      method_type
      metric
      weight
      aspect
      target
      target_stretch
      progress
      progress_percentage
      last_modified
      kpi_aspect {
        id
        code
        name
      }
      kpi_weight_unit {
        id
        name
      }
      created_by_user {
        id
        name
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_profile {
            id
            brand_name
          }
        }
      }
      ReporterUser {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
          }
        }
      }
      performance_group {
        id
        name
        color
      }
      performance_goal {
        id
        name
        description
        startdate
        enddate
        kpi_list {
          id
          name
          formula
          description
          kpi_scoring_nature {
            id
            name
          }
          kpi_weight_unit {
            id
            name
          }
          kpi_aspect {
            id
            name
          }
          kpi_level {
            id
            name
          }
          kpi_version {
            id
            name
          }
          kpi_method {
            id
            name
          }
          kpi_monitoring_frequency {
            id
            name
          }
          kpi_perspective {
            id
            name
          }
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      performance_task_assignments {
        id
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_job_profile {
              id
              code
              title
            }
            company_profile {
              id
              brand_name
            }
            company_organization {
              id
              name
            }
          }
        }
      }
      performance_task_activities(
        where: {attachment: {_is_null: false}}
        limit: 5
        order_by: [{id: desc}]
      ) {
        id
        attachment
      }
      performance_group {
        id
        name
      }
    }
  }
`

export const GET_LIST_ALL_PERSONAL_TASK = gql`
  query getListTasks(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
    $goal: bigint
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        aspect: {_in: $aspect}
        performance_goal: {
          id: {_eq: $goal}
          kpi_list: {
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        aspect: {_in: $aspect}
        performance_goal: {
          id: {_eq: $goal}
          kpi_list: {
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      status
    }
  }
`

export const GET_LIST_ALL_DEPARTMENT_TASK = gql`
  query getListTasks(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
    $goal: bigint
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        aspect: {_in: $aspect}
        performance_goal: {
          id: {_eq: $goal}
          kpi_list: {
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company: {_eq: $company}
              company_job_profile: {
                company_organization: {
                  company_job_profiles: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $user}
                    }
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        aspect: {_in: $aspect}
        performance_goal: {
          id: {_eq: $goal}
          kpi_list: {
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company: {_eq: $company}
              company_job_profile: {
                company_organization: {
                  company_job_profiles: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $user}
                    }
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      status
    }
  }
`

export const GET_MY_TASK_BY_GOAL = gql`
  query getListMyTasksGroupByGoal(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      distinct_on: [goal]
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{goal: $order}]
      offset: $offset
      limit: $limit
      distinct_on: [goal]
    ) {
      id
      performance_goal {
        id
        name
        performance_tasks(
          where: {
            status: {_in: $status}
            company: {_eq: $company}
            performance_goal: {
              kpi_list: {
                aspect: {_in: $aspect}
                prespective: {_in: $perspective}
                scoring: {_in: $scoringNature}
                _or: [
                  {
                    kpi_cascading_lists: {
                      cascading_main: {
                        _and: [
                          {status: {_eq: "ACTIVE"}}
                          {company: {_eq: $company}}
                          {_not: {id: {_is_null: $isCompany}}}
                        ]
                      }
                    }
                  }
                  {
                    _and: [
                      {_not: {kpi_cascading_lists: {}}}
                      {_not: {id: {_is_null: $isPersonal}}}
                    ]
                  }
                ]
              }
            }
            name: {_ilike: $search}
            performance_task_assignments: {user: {_eq: $user}}
            created_by: {_in: $creator}
            reporter: {_in: $reporter}
            method_type: {_in: $type}
            priority: {_in: $priority}
            progress_percentage: {
              _gte: $progressPersenFrom
              _lte: $progressPersenTo
            }
            startdate: {_gte: $dateFrom, _lte: $dateTo}
            target_unit: {_in: $weightUnit}
            group: {_in: $label}
          }
        ) {
          id
        }
      }
    }
  }
`

export const GET_DEPARTEMEN_TASK_BY_GOAL = gql`
  query getListDepartemenTaskGroupByGoal(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company: {_eq: $company}
              company_job_profile: {
                company_organization: {
                  company_job_profiles: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $user}
                    }
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      distinct_on: [goal]
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
          global_user: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              company: {_eq: $company}
              company_job_profile: {
                company_organization: {
                  company_job_profiles: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $user}
                    }
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{goal: $order}]
      offset: $offset
      limit: $limit
      distinct_on: [goal]
    ) {
      id
      performance_goal {
        id
        name
        performance_tasks(
          where: {
            status: {_in: $status}
            company: {_eq: $company}
            performance_goal: {
              kpi_list: {
                aspect: {_in: $aspect}
                prespective: {_in: $perspective}
                scoring: {_in: $scoringNature}
                _or: [
                  {
                    kpi_cascading_lists: {
                      cascading_main: {
                        _and: [
                          {status: {_eq: "ACTIVE"}}
                          {company: {_eq: $company}}
                          {_not: {id: {_is_null: $isCompany}}}
                        ]
                      }
                    }
                  }
                  {
                    _and: [
                      {_not: {kpi_cascading_lists: {}}}
                      {_not: {id: {_is_null: $isPersonal}}}
                    ]
                  }
                ]
              }
              global_user: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  company: {_eq: $company}
                  company_job_profile: {
                    company_organization: {
                      company_job_profiles: {
                        people_work_placements: {
                          status: {_eq: "ACTIVE"}
                          user: {_eq: $user}
                        }
                      }
                    }
                  }
                }
              }
            }
            name: {_ilike: $search}
            created_by: {_in: $creator}
            reporter: {_in: $reporter}
            method_type: {_in: $type}
            priority: {_in: $priority}
            progress_percentage: {
              _gte: $progressPersenFrom
              _lte: $progressPersenTo
            }
            startdate: {_gte: $dateFrom, _lte: $dateTo}
            target_unit: {_in: $weightUnit}
            group: {_in: $label}
          }
        ) {
          id
        }
      }
    }
  }
`

export const GET_CASCADE_TASK = gql`
  query listCascadingByTask($id: Int!, $offset: Int!, $limit: Int!) {
    total: performance_cascadings_aggregate(
      where: {
        task: {_eq: $id}
        performance_goal: {deleted_at: {_is_null: true}}
      }
      distinct_on: [goal]
    ) {
      aggregate {
        count
      }
    }
    performance_cascadings(
      where: {
        task: {_eq: $id}
        performance_goal: {deleted_at: {_is_null: true}}
      }
      distinct_on: [goal]
      offset: $offset
      limit: $limit
    ) {
      id
      performance_goal {
        id
        name
      }
    }
  }
`
