import React from 'react'
import {HeaderContainer2, OptionsContainer} from './HeaderStyles'
import {Typography} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {GreyTypography} from '../../pages/performance-tabs/PerformanceStyles'
import {GLOBAL_GLOSSARY} from '../../utils/constant'

const HeaderGenerate = props => {
  //   const {status} = props.location.state
  return (
    <HeaderContainer2
      style={{
        padding: '1.5rem 0',
        alignItems: 'end',
        boxShadow: 'none',
      }}
    >
      <img
        alt="smartasn-logo"
        src={require('../../assets/slicing/logo-smartasn.svg')}
        style={{
          width: 125,
          marginLeft: '1.5rem',
          cursor: 'pointer',
          alignSelf: 'start',
          marginTop: 5,
        }}
        onClick={props.history.goBack}
      />

      <OptionsContainer
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'center',
          marginRight: '1.5rem',
        }}
      >
        <Typography variant="h5" color="primary" style={{fontWeight: 'bold'}}>
          {props.title || GLOBAL_GLOSSARY.performance.OGF}
        </Typography>
        <span>{props.taskCode}</span>
        <GreyTypography variant="body2" style={{marginTop: 8}}>
          {`${props.from} - ${props.to}`}
        </GreyTypography>
      </OptionsContainer>
    </HeaderContainer2>
  )
}

export default withRouter(HeaderGenerate)
