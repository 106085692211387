import React from 'react'
import {Icon, IconButton, Typography} from '@material-ui/core'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
  DetailAchievementContainer,
  CardAssignedReq,
  CardAssignedContainerReq,
  AvatarAssignedReq,
  DetailMainContainerReq,
  CardDetailFBContainer,
} from './ModalPerformanceStyles'
import {FlexBetween, IconFeedback} from '../PerformanceStyles'
import {
  TextXl,
  Pmd,
  P18Bold,
} from '../../../components/typography/TypographyStyles'
import {getDate} from '../../../utils/helpers'
// import {ButtonContained} from '../multirater-components/SurveyStyles'

export default function ModalDetailFeedbackSurvey(props) {
  const {open, onClose, params, type} = props
  if (params.length === 0) {
    return ''
  }
  const data = params
  let paramter = 'global_user_from'
  if (type === 'Sent') {
    paramter = 'global_user_to'
  }
  let nama = data[paramter] && data[paramter].name
  let avatar = data[paramter] && data[paramter].avatar
  const createdName = data.global_user_from && data.global_user_from.name
  if (type !== 'Sent') {
    if (data.is_anonymous) {
      nama = 'Anonymous'
      avatar =
        'https://cdn4.iconfinder.com/data/icons/gray-toolbar-8/512/xxx046-512.png'
    }
  }

  const emosional = type => {
    if (type === 'DISAPPOINTED') {
      return (
        <div style={{color: '#ef4d5e', display: 'flex', flexDirection: 'row'}}>
          <IconFeedback>sentiment_very_dissatisfied</IconFeedback>
          <Typography
            style={{
              paddingTop: '6px',
              paddingLeft: '6px',
              fontFamily: 'Muli',
              fontSize: '16px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.31',
              letterSpacing: 'normal',
              textAlign: 'center',
            }}
          >
            Merasa Tidak Puas
          </Typography>
        </div>
      )
    } else if (type === 'SATISFIED') {
      return (
        <div style={{color: '#4caf50', display: 'flex', flexDirection: 'row'}}>
          <IconFeedback>sentiment_satisfied_alt</IconFeedback>
          <Typography
            style={{
              paddingTop: '6px',
              paddingLeft: '6px',
              fontFamily: 'Muli',
              fontSize: '16px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.31',
              letterSpacing: 'normal',
              textAlign: 'center',
            }}
          >
            Merasa Puas
          </Typography>
        </div>
      )
    } else if (type === 'ORDINARY') {
      return (
        <div style={{color: '#ffa000', display: 'flex', flexDirection: 'row'}}>
          <IconFeedback>sentiment_neutral</IconFeedback>
          <Typography
            style={{
              paddingTop: '6px',
              paddingLeft: '6px',
              fontFamily: 'Muli',
              fontSize: '16px',
              fontWeight: 'bold',
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: '1.31',
              letterSpacing: 'normal',
              textAlign: 'center',
            }}
          >
            Merasa Netral
          </Typography>
        </div>
      )
    }
  }

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      // open={this.state.openModal}
      open={open}
      fullWidth
      maxWidth={window.innerWidth < 1920 ? 'md' : 'lg'}
    >
      <ModalHeader>
        <FlexBetween>
          {type !== 'req' ? (
            <TextXl>Umpan Balik {nama && nama.split(' ')[0]}</TextXl>
          ) : (
            <TextXl>{nama}</TextXl>
          )}
          <IconButton aria-label="close" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
        {type !== 'req' && (
          <Pmd>
            Dibuat oleh <span style={{color: '#039be5'}}>{createdName} </span>
            {getDate(data.date_added)}
          </Pmd>
        )}
        {type === 'req' && <Pmd>{`Send on ${getDate(data.date_created)}`}</Pmd>}
      </ModalHeader>
      <ModalContent>
        <FlexBetween>
          <Pmd>{type !== 'req' ? 'Rincian Hasil' : 'Detail Sent Request'}</Pmd>
          {/* <ButtonContained variant="contained" color="primary">
            Generate Report
          </ButtonContained> */}
        </FlexBetween>
        <DetailAchievementContainer>
          <CardAssignedContainerReq>
            <CardAssignedReq>
              <Pmd>{type !== 'req' ? 'Responden' : 'Subject'}</Pmd>
              <AvatarAssignedReq
                src={avatar}
                alt="assigned-to"
              ></AvatarAssignedReq>
              <P18Bold>{nama}</P18Bold>
              {!data.is_anonymous && (
                <>
                  <Pmd style={{color: '#a9a8a8'}}>
                    {data[paramter] &&
                      data[paramter].people_work_placements[0]
                        .company_employee_position.name}{' '}
                    at{' '}
                  </Pmd>
                  <Pmd style={{color: '#a9a8a8'}}>
                    {data[paramter] &&
                      data[paramter].people_work_placements[0].company_address
                        .office_name}
                  </Pmd>
                </>
              )}
            </CardAssignedReq>
          </CardAssignedContainerReq>

          <DetailMainContainerReq>
            <CardDetailFBContainer style={{minHeight: '272px !important'}}>
              <div>{type !== 'req' ? emosional(data.summary) : ''}</div>
              <Typography
                style={{
                  paddingTop: '25px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.75',
                  letterSpacing: 'normal',
                  color: '#000000',
                }}
              >
                {type !== 'req' ? 'Respon' : 'Topic'}
              </Typography>
              {type === 'req' && (
                <Typography
                  style={{
                    paddingTop: '10px',
                    fontFamily: 'Muli',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.5',
                    letterSpacing: 'normal',
                    textAlign: ' left',
                  }}
                >
                  {data.topic}
                </Typography>
              )}
              {type === 'req' && (
                <Typography
                  style={{
                    paddingTop: '25px',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: '1.75',
                    letterSpacing: 'normal',
                    color: '#000000',
                  }}
                >
                  Description
                </Typography>
              )}
              <div
                style={{
                  paddingTop: '15px',
                  fontFamily: 'Muli',
                  fontSize: '14px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: '1.5',
                  letterSpacing: 'normal',
                  textAlign: ' left',
                  color: 'rgba(0, 0, 0, 0.65)',
                }}
              >
                {data.description}
              </div>
            </CardDetailFBContainer>
          </DetailMainContainerReq>
        </DetailAchievementContainer>
      </ModalContent>
    </StyledDialog>
  )
}
