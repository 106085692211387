import React from 'react'
import useStyles from '../SurveyStyles'

import {CheckBoxLabelImpres} from '../../archive-components/ArchiveStyles'

import {FlexBetween, Center, IconFeedback} from '../../PerformanceStyles'
import {Psm} from '../../../../components/typography/TypographyStyles'

// material
import Grid from '@material-ui/core/Grid'

import FormControl from '@material-ui/core/FormControl'

// icon
import {Checkbox} from '@material-ui/core'

function TabsImpression(props) {
  const {
    handleResetImpression,
    handleCheckedImpression,
    handleCheckAllImpression,
    dataImpression,
  } = props
  // style
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item sm={12}>
        <FormControl variant="outlined" className={classes.searchPerspective}>
          {/* <Grid container>
            <Grid item sm={10}>
              <TextFieldSearch
                placeholder="Search"
                fullWidth
                name="searchPerspective"
                // value={searchCheckbox.searchPerspective}
                // onChange={handleChangeSearchCheckbox}
              />
            </Grid>
            <Grid item sm={2}>
              <IconButton
                type="submit"
                className={classes.iconSearch}
                aria-label="search"
              >
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid> */}
        </FormControl>
      </Grid>
      <FlexBetween style={{paddingTop: '10px', paddingBottom: '12px'}}>
        <span style={{cursor: 'pointer'}} onClick={handleCheckAllImpression}>
          Pilih Semua
        </span>
        <Psm style={{cursor: 'pointer'}} onClick={handleResetImpression}>
          Atur Ulang
        </Psm>
      </FlexBetween>
      <div
        style={{
          overflowY: 'auto',
          height: '127px',
          width: '238px',
        }}
      >
        {dataImpression.map((res, key) => (
          <Grid key={key} item sm={12}>
            <CheckBoxLabelImpres
              control={
                <Checkbox
                  checked={res.check}
                  onChange={e => handleCheckedImpression(e)}
                  value={res.value}
                />
              }
              label={
                <Center
                  style={{
                    color: res.color,
                    padding: '6px 3px 0px 9px',
                  }}
                >
                  <IconFeedback>{res.icon}</IconFeedback>
                </Center>
              }
            />
          </Grid>
        ))}
      </div>
    </Grid>
  )
}

export default TabsImpression
