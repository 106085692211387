import React from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core'
import {WhiteModalHeader} from '../performance-tabs/PerformanceStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnPopup: {
    backgroundColor: '#014a62',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
    textTransform: 'capitalize',
  },
  btnCancel: {
    color: '#a9a8a8',
    backgroundColor: '#ffffff',
    boxShadow: 'initial',
  },
  word: {wordBreak: 'break-all'},
  nameStyle: {
    color: '#014a62',
    fontWeight: '600',
  },
}))

export default function Result(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    feature,
    message,
    type,
    name,
    fMessage,
    lMessage,
    mutation,
    handleCloseForm,
    optional = null,
    disabled = false,
    isCancel = true,
  } = props

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography style={{color: '#014a62', fontWeight: 700}}>
          {feature}
        </Typography>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        {name ? (
          <>
            <Typography variant="body2" display="inline">
              {fMessage}
            </Typography>
            <Typography
              variant="body2"
              color="primary"
              display="inline"
              className={classes.nameStyle}
            >
              {name}
            </Typography>
            <Typography variant="body2" display="inline">
              {lMessage}
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" display="inline">
              {message}
            </Typography>
          </>
        )}
        {optional}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {isCancel && (
          <Button
            className={classes.btnCancel}
            variant="contained"
            onClick={() => handleClose()}
          >
            {type === 'Cancel' ? 'Tidak Sekarang' : 'Batal'}
          </Button>
        )}
        <Button
          className={classes.btnPopup}
          variant="contained"
          disabled={disabled}
          onClick={() => {
            if (mutation) {
              mutation()
            } else {
              handleCloseForm()
            }
          }}
        >
          {type}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
