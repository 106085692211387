import gql from 'graphql-tag'

export const ADD_ACTIVITIES_GOALS = gql`
  mutation addActivities($data: [performance_goal_activities_insert_input!]!) {
    insert_performance_goal_activities(objects: $data) {
      affected_rows
    }
  }
`
export const ADD_COMMENT_ACTIVITIES_GOALS = gql`
  mutation addComment($data: [performance_goal_comments_insert_input!]!) {
    insert_performance_goal_comments(objects: $data) {
      affected_rows
    }
  }
`
export const UPDATE_COMMENT_ACTIVITIES_GOALS = gql`
  mutation updateCommentGoal(
    $comment: String
    $attachment: jsonb
    $id: bigint
  ) {
    update_performance_goal_comments(
      _set: {comment: $comment, attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_ACTIVITY_GOAL = gql`
  mutation updateGoalActivities($attachment: jsonb, $id: bigint) {
    update_performance_goal_activities(
      _set: {attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_ACTIVITY_GOALS = gql`
  mutation updateGoalActivities(
    $comment: String
    $attachment: jsonb
    $id: bigint
  ) {
    update_performance_goal_activities(
      _set: {comment: $comment, attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_ACTIVITY_GOAL = gql`
  mutation updateGoalActivities($id: bigint) {
    update_performance_goal_activities(
      _set: {deletedat: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_COMMENT_ACTIVITY_GOAL = gql`
  mutation updateComment($id: bigint) {
    update_performance_goal_comments(
      _set: {deletedat: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
