import gql from 'graphql-tag'

export const GET_CURRENT_BADGE_SQUAD = gql`
  query currentPerformanceBadge($user: uuid, $limit: Int) {
    talent_assignments(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          classification_type: {_eq: "PERFORMANCE"}
          visibility: {_eq: "PUBLISH"}
        }
        badge_classification: {_is_null: false}
      }
      order_by: [{id: desc}]
      limit: $limit
    ) {
      id
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
      }
      talent_box {
        id
        name
        badge
      }
      talent_score
      people_work_placement {
        id
        company_job_profile {
          id
          title
        }
      }
      indicator_perf_multirater
      indicator_perf_nps
      indicator_perf_ogf
      indicator_perf_goal
    }
  }
`

export const GET_ALL_BADGE_SQUAD = gql`
  query allBadge($user: uuid, $offset: Int, $limit: Int) {
    total: talent_assignments_aggregate(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          classification_type: {_eq: "PERFORMANCE"}
          visibility: {_eq: "PUBLISH"}
        }
        badge_classification: {_is_null: false}
      }
    ) {
      aggregate {
        count
      }
    }
    talent_assignments(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          classification_type: {_eq: "PERFORMANCE"}
          visibility: {_eq: "PUBLISH"}
        }
        badge_classification: {_is_null: false}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
      }
      talent_box {
        id
        name
        badge
      }
      talent_score
      people_work_placement {
        id
        company_job_profile {
          id
          title
        }
      }
      indicator_perf_multirater
      indicator_perf_nps
      indicator_perf_ogf
      indicator_perf_goal
    }
  }
`

export const GET_TOTAL_GOAL = gql`
  query($user: String!, $startdate: String, $enddate: String) {
    getPerformanceIndividualSummaryGoal(
      user: $user
      startdate: $startdate
      enddate: $enddate
    ) {
      total_all_goal
      total_complete_goal
      total_todo_goal
      total_all_company_goal
      total_complete_company_goal
      total_todo_company_goal
      total_all_personal_goal
      total_complete_personal_goal
      total_todo_personal_goal
      avg_score_all_goal
      avg_score_company_goal
      avg_score_personal_goal
    }
  }
`

export const GET_TOTAL_TASK = gql`
  query dashboardPerformanceIndividualTask(
    $user: uuid!
    $filterDate: date_comparison_exp
    $company: uuid!
  ) {
    all_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task: count
        avg {
          avg_all_task: progress_percentage
        }
      }
    }
    all_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_todo: count
        avg {
          avg_all_task_todo: progress_percentage
        }
      }
    }
    all_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_inprogress: count
        avg {
          avg_all_task_inprogress: progress_percentage
        }
      }
    }
    all_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_done: count
        avg {
          avg_all_task_done: progress_percentage
        }
      }
    }
    all_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            _or: [
              {_not: {kpi_cascading_lists: {}}}
              {
                kpi_cascading_lists: {
                  deleted_at: {_is_null: true}
                  cascading_main: {
                    company: {_eq: $company}
                    deleted_at: {_is_null: true}
                    status: {_eq: "ACTIVE"}
                  }
                }
              }
            ]
          }
        }
      }
    ) {
      aggregate {
        total_all_task_completed: count
        avg {
          avg_all_task_completed: progress_percentage
        }
      }
    }
    personal_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task: count
        avg {
          avg_personal_task: progress_percentage
        }
      }
    }
    personal_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_todo: count
        avg {
          avg_personal_task_todo: progress_percentage
        }
      }
    }
    personal_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_inprogress: count
        avg {
          avg_personal_task_inprogress: progress_percentage
        }
      }
    }
    personal_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_done: count
        avg {
          avg_personal_task_done: progress_percentage
        }
      }
    }
    personal_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {kpi_list: {_not: {kpi_cascading_lists: {}}}}
      }
    ) {
      aggregate {
        total_personal_task_completed: count
        avg {
          avg_personal_task_completed: progress_percentage
        }
      }
    }
    company_task: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task: count
        avg {
          avg_company_task: progress_percentage
        }
      }
    }
    company_task_todo: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "TODO"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_todo: count
        avg {
          avg_company_task_todo: progress_percentage
        }
      }
    }
    company_task_inprogress: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "INPROGRESS"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_inprogress: count
        avg {
          avg_company_task_inprogress: progress_percentage
        }
      }
    }
    company_task_done: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_eq: "DONE"}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_done: count
        avg {
          avg_company_task_done: progress_percentage
        }
      }
    }
    company_task_completed: performance_tasks_aggregate(
      where: {
        startdate: $filterDate
        status: {_in: ["COMPLETED", "ARCHIVED"]}
        performance_task_assignments: {user: {_eq: $user}}
        performance_goal: {
          kpi_list: {
            kpi_cascading_lists: {
              deleted_at: {_is_null: true}
              cascading_main: {
                company: {_eq: $company}
                deleted_at: {_is_null: true}
                status: {_eq: "ACTIVE"}
              }
            }
          }
        }
      }
    ) {
      aggregate {
        total_company_task_completed: count
        avg {
          avg_company_task_completed: progress_percentage
        }
      }
    }
  }
`
