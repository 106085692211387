import React from 'react'
import {AttachmentHoverWrap} from '../../performance-tabs/PerformanceStyles'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faFileWord,
  faFilePdf,
  faFile,
  faFilePowerpoint,
} from '@fortawesome/free-solid-svg-icons'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'

const getIcon = url => {
  let type = null
  let icon = faFile
  if (url) {
    const fileType = url.split('.').pop()
    switch (fileType) {
      case 'pdf':
        type = 'pdf'
        icon = faFilePdf
        break
      case 'xls':
      case 'xlsx':
        type = 'xls'
        icon = faFileExcel
        break
      case 'ppt':
        type = 'ppt'
        icon = faFilePowerpoint
        break
      case 'doc':
      case 'docx':
        type = 'doc'
        icon = faFileWord
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'tiff':
      case 'pjp':
      case 'pjpeg':
        type = 'img'
        icon = null
        break
      default:
        type = null
        icon = faFile
        break
    }
  }
  return [type, icon]
}

export const AttachmentItemHover = ({
  iconAction,
  onPreview,
  onDelete,
  fileName,
  url,
}) => {
  const [type, icon] = getIcon(url)

  return (
    <AttachmentHoverWrap>
      <div className="hover">
        {iconAction || (
          <>
            <VisibilityOutlinedIcon onClick={onPreview} className="icon mr" />
            <DeleteOutlineOutlinedIcon onClick={onDelete} className="icon" />
          </>
        )}
      </div>
      {icon ? (
        <FontAwesomeIcon className={`icon ${type}`} icon={icon} />
      ) : (
        <img alt="icon" className={`icon ${type}`} src={url} />
      )}
      <div className="file-name">
        <p>{fileName}</p>
      </div>
    </AttachmentHoverWrap>
  )
}

export const WrapAttachment = ({flexDirection = 'row', ...children}) => {
  return (
    <div
      style={{display: 'flex', flexDirection, flexWrap: 'wrap'}}
      {...children}
    />
  )
}
