import React from 'react'

import {LabelSearchDate} from '../../archive-components/ArchiveStyles'

import {FlexBetween} from '../../PerformanceStyles'
import {Psm} from '../../../../components/typography/TypographyStyles'

// material
import Grid from '@material-ui/core/Grid'

// picker
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

function TabsDate(props) {
  const {handleResetDate, dateFrom, ChangeDFrom, dateTo, ChangeDTo} = props
  return (
    <Grid container>
      <Grid item sm={12}>
        <FlexBetween>
          Pilih Tanggal
          <Psm style={{cursor: 'pointer'}} onClick={handleResetDate}>
            Atur Ulang
          </Psm>
        </FlexBetween>
      </Grid>
      <Grid item sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <LabelSearchDate>Dari</LabelSearchDate>
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            format="yyyy/MM/dd"
            value={dateFrom}
            onChange={ChangeDFrom}
            name="dateFrom"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item sm={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <LabelSearchDate>Sampai</LabelSearchDate>
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            format="yyyy/MM/dd"
            value={dateTo}
            onChange={ChangeDTo}
            name="dateTo"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  )
}

export default TabsDate
