import gql from 'graphql-tag'

export const GET_LIST_EMPLOYEE = gql`
  query getEmployee($company:uuid, $search: String, $limit: Int, $offset: Int) {
    people_work_placements(
      where: {
        company:{_eq:$company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      distinct_on:[user]
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_organization {
        id
        name
      }
      company_job_profile{
        id
        code
        title
      }
    }
  }
`
