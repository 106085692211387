import {useQuery, useLazyQuery} from '@apollo/react-hooks'
import {Skeleton} from '@material-ui/lab'
import React from 'react'
import {withRouter} from 'react-router-dom'
import {
  GET_SUBORDINATE_SQUAD_LIST,
  GET_TOTAL_SUBORDINATE_SQUAD,
} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {DEFAULT_VALUE} from '../shared/constant'
import SquadItem from '../shared/SquadItem'
import {ListSquadWrapper} from '../SquadStyle'
import EmptyState from '../../../../components/empty-state/EmptyStateComponent'

const SquadData = ({placement_fields, onClick}) => {
  const {
    subordinate_squad_label,
    subordinate_squad_description,
  } = placement_fields

  return (
    <SquadItem
      title={subordinate_squad_label || DEFAULT_VALUE.subordinate_squad_label}
      description={
        subordinate_squad_description ||
        DEFAULT_VALUE.subordinate_squad_description
      }
      status="Aktif"
      onClick={onClick}
    />
  )
}

const ListSubordinateSquad = props => {
  const [getListSubordinate, {data, loading, error}] = useLazyQuery(
    GET_SUBORDINATE_SQUAD_LIST,
    {
      variables: {
        user: USER_ID,
      },
    }
  )

  const {loading: loadingTotal} = useQuery(GET_TOTAL_SUBORDINATE_SQUAD, {
    fetchPolicy: 'no-cache',
    variables: {
      user: USER_ID,
    },
    onCompleted: ({people_work_placements_aggregate}) => {
      if (people_work_placements_aggregate.aggregate.count > 0) {
        getListSubordinate()
      }
    },
  })

  const handleClickDetail = () => {
    props.history.push({
      pathname: `/squad/direct-report-squad/detail`,
    })
  }

  if (error) return JSON.stringify(error, 0, 4)

  return (
    <ListSquadWrapper>
      {!loading &&
        !loadingTotal &&
        data?.people_work_placements?.[0]?.placement_fields && (
          <SquadData
            placement_fields={
              data?.people_work_placements?.[0]?.placement_fields
            }
            onClick={handleClickDetail}
          />
        )}
      {(loading || loadingTotal) && (
        <Skeleton animation="wave" height={149} width={178} />
      )}
      {!loading && !loadingTotal && !data && (
        <EmptyState
          message1="Maaf, Tidak ada Daftar"
          message2="Saat ini, tidak ada tim kerja dalam unit kerja"
          image={require('../../../../assets/images/nodatalist.png')}
          styleMessage2={{
            maxWidth: 300,
            margin: 'auto',
          }}
        />
      )}
    </ListSquadWrapper>
  )
}

export default withRouter(ListSubordinateSquad)
