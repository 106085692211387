import {
  GET_FILTER_ASPECT,
  GET_FILTER_GOAL_LABEL,
  GET_FILTER_LABEL,
  GET_FILTER_PERSPECTIVE,
  GET_FILTER_PLACEMENT,
  GET_FILTER_SCORING_NATURE,
  GET_FILTER_WEIGHT_UNIT,
} from '../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../utils/globals'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const LIMIT_TRESHOLD = 6
const DEFAULT_OPTIONS = {
  context: {
    headers: {
      'X-Hasura-Role': 'user',
    },
  },
}

export const listFilterGoalSquad = [
  {
    name: 'Goal Reporter',
    fieldName: 'reporter',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Creator',
    fieldName: 'creator',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Goal Type',
    fieldName: 'methodType',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'Personal - 2',
          label: 'Personal - Quantitative',
        },
        {
          value: 'Personal - 1',
          label: 'Personal - Qualitative',
        },
        {
          value: 'Company - 2',
          label: 'Company - Quantitative',
        },
        {value: 'Company - 1', label: 'Company - Qualitative'},
      ],
    },
  },
  {
    name: 'Priority',
    fieldName: 'priority',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'HIGH',
          label: 'High',
        },
        {
          value: 'MEDIUM',
          label: 'Medium',
        },
        {
          value: 'LOW',
          label: 'Low',
        },
      ],
    },
  },
  {
    name: 'Label',
    fieldName: 'label',
    type: 'checkbox',
    options: {
      checkboxType: 'label',
      fetch: {
        query: GET_FILTER_GOAL_LABEL,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.performance_goal_groups) {
            const _data = data.performance_goal_groups.map(
              ({id, name, color}) => {
                return {
                  value: id,
                  label: {
                    color,
                    name,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Status',
    fieldName: 'filterStatus',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'todo',
          label: 'To Do',
        },
        {
          value: 'complete',
          label: 'Complete',
        },
      ],
    },
  },
  {
    name: 'Period',
    fieldName: 'period',
    type: 'date',
  },
  {
    name: 'Goal Aspect',
    fieldName: 'aspect',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_ASPECT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_aspects) {
            const _data = data.kpi_aspects.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Perspective',
    fieldName: 'perspective',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_PERSPECTIVE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_perspectives) {
            const _data = data.kpi_perspectives.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Scoring Nature',
    fieldName: 'scoring',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_SCORING_NATURE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_scoring_natures) {
            const _data = data.kpi_scoring_natures.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Weight Unit',
    fieldName: 'weightUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_WEIGHT_UNIT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_weight_units) {
            const _data = data.kpi_weight_units.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
]

export const listFilterTaskSquad = [
  {
    name: 'Task Reporter',
    fieldName: 'reporter',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Creator',
    fieldName: 'creator',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Task Type',
    fieldName: 'methodType',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'Personal - 2',
          label: 'Personal - Quantitative',
        },
        {
          value: 'Personal - 1',
          label: 'Personal - Qualitative',
        },
        {
          value: 'Company - 2',
          label: 'Company - Quantitative',
        },
        {value: 'Company - 1', label: 'Company - Qualitative'},
      ],
    },
  },
  {
    name: 'Priority',
    fieldName: 'priority',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'HIGH',
          label: 'High',
        },
        {
          value: 'MEDIUM',
          label: 'Medium',
        },
        {
          value: 'LOW',
          label: 'Low',
        },
      ],
    },
  },
  {
    name: 'Percentage',
    fieldName: 'progress',
    type: 'checkbox',
    options: {
      list: [
        {
          value: '100.1-9999',
          label: 'Exceed',
        },
        {
          value: '100-100',
          label: 'Meet',
        },
        {
          value: '0-99.9',
          label: 'Below',
        },
      ],
    },
  },
  {
    name: 'Label',
    fieldName: 'label',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_LABEL,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.performance_groups) {
            const _data = data.performance_groups.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'TODO',
          label: 'To Do',
        },
        {
          value: 'INPROGRESS',
          label: 'In Progress',
        },
        {
          value: 'DONE',
          label: 'Done',
        },
        {
          value: 'COMPLETED',
          label: 'Complete',
        },
      ],
    },
  },
  {
    name: 'Period',
    fieldName: 'period',
    type: 'date',
  },
  {
    name: 'Goal Aspect',
    fieldName: 'aspect',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_ASPECT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_aspects) {
            const _data = data.kpi_aspects.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Perspective',
    fieldName: 'perspective',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_PERSPECTIVE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_perspectives) {
            const _data = data.kpi_perspectives.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Scoring Nature',
    fieldName: 'scoring',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_SCORING_NATURE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_scoring_natures) {
            const _data = data.kpi_scoring_natures.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Weight Unit',
    fieldName: 'weightUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_WEIGHT_UNIT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_weight_units) {
            const _data = data.kpi_weight_units.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
]
