import gql from 'graphql-tag'

export const GET_LIST_QUESION_PER_USER = gql`
  query($user: uuid, $respondent: uuid, $survey: bigint) {
    people_work_placements(where: {global_user: {id: {_eq: $respondent}}}) {
      id
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
    multirater_survey_questions(
      where: {multirater_survey_group: {survey: {_eq: $survey}}}
      order_by: [{multirater_survey_group: {number: asc}}, {number: asc}]
    ) {
      id
      number
      question_self
      question_others
      multirater_survey_group {
        id
        number
        name
        isnps
        multirater_survey {
          id
          multirater_scale {
            id
            name
          }
        }
      }
      multirater_responses(
        where: {respond_from: {_eq: $user}, respond_to: {_eq: $respondent}}
      ) {
        id
        answer
        answer_text
      }
    }
  }
`

export const GET_LIST_HIRARCY_EMPLOYEE_MULTIRATER = gql`
  query($user: uuid, $survey: bigint) {
    multirater_surveys(where: {id: {_eq: $survey}}) {
      id
      title
      enddate
      status
      startdate
    }
    total_question: multirater_survey_questions_aggregate(
      where: {
        multirater_survey_group: {multirater_survey: {id: {_eq: $survey}}}
      }
    ) {
      aggregate {
        count
      }
    }
    multirater_survey_groups(
      where: {survey: {_eq: $survey}}
      order_by: [{number: asc}]
    ) {
      id
      isnps
    }
    second_spv: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          job_profile_subordinate: {
            job_profile_subordinate: {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                user: {_eq: $user}
              }
            }
          }
        }
        global_user: {
          multirater_respondents: {
            survey: {_eq: $survey}
            multirater_survey: {
              weight_settings: {_has_key: "second_subordinate"}
            }
          }
        }
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
    spv: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          job_profile_subordinate: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              user: {_eq: $user}
            }
          }
        }
        global_user: {
          multirater_respondents: {
            survey: {_eq: $survey}
            multirater_survey: {weight_settings: {_has_key: "subordinate"}}
          }
        }
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
    peer: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          _or: [
            {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                user: {_eq: $user}
              }
            }
            {
              job_profile_supervisor: {
                job_profile_subordinate: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                  }
                }
              }
            }
          ]
        }
        global_user: {
          multirater_respondents: {
            survey: {_eq: $survey}
            multirater_survey: {weight_settings: {_has_key: "peer"}}
          }
        }
        user: {_neq: $user}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
    subordinate: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          job_profile_supervisor: {
            people_work_placements: {
              status: {_eq: "ACTIVE"}
              user: {_eq: $user}
            }
          }
        }
        global_user: {
          multirater_respondents: {
            survey: {_eq: $survey}
            multirater_survey: {weight_settings: {_has_key: "supervisor"}}
          }
        }
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
    second_sub: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          job_profile_supervisor: {
            job_profile_supervisor: {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                user: {_eq: $user}
              }
            }
          }
        }
        global_user: {
          multirater_respondents: {
            survey: {_eq: $survey}
            multirater_survey: {
              weight_settings: {_has_key: "second_supervisor"}
            }
          }
        }
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
    self: people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        user: {_eq: $user}
        global_user: {multirater_respondents: {survey: {_eq: $survey}}}
      }
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        multirater_responses_to_aggregate(
          where: {
            respond_from: {_eq: $user}
            multirater_survey: {id: {_eq: $survey}}
          }
        ) {
          aggregate {
            count
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
