import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  buttonFile: {
    margin: '8px 0px 12px 0px',
    width: '8vw',
    height: '8vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    cursor: 'pointer',
  },
  icon: {
    width: '50%',
    height: '50%',
  },
  iconInfo: {
    marginLeft: 10,
    width: 18,
    height: 18,
  },
  textTooltip: {
    fontSize: 12,
    color: '#000',
    display: 'block',
    margin: '2px 0px',
  },
  textBlue: {
    color: '#014a62',
    fontSize: 12,
    display: 'block',
    margin: '2px 0px',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '5px',
  },
  fileUploadded: {
    backgroundColor: '#eff2f4',
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
    borderRadius: 5,
  },
  top: {
    display: 'flex',
    flexDirection: 'row ',
  },
  fileName: {
    backgroundColor: '#fff',
    padding: '10px 16px',
    width: '100%',
    margin: '0px 10px',
  },
  bottom: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
    marginTop: 12,
    marginRight: '3vw',
  },
  size: {
    color: '#000',
    fontSize: 12,
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#a9a8a8',
    borderRadius: '50%',
    margin: '0px 10px',
  },
  preview: {
    color: '#039be5',
    fontSize: 12,
    cursor: 'pointer',
  },
  link: {
    textDecoration: 'none',
  },
  textError: {
    color: '#f44336',
    margin: '8px 14px 0',
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}))

const TooltipAttachmentGoal = () => {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.textTooltip}>
        Appropriate file extension:
      </Typography>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>
          • Document {'( '}
        </Typography>
        <Typography className={classes.textBlue}>
          .doc, .docx, .xls, .pdf, .xlsx, .ppt
        </Typography>
        <Typography className={classes.textTooltip}>{' )'}</Typography>
      </div>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>• Image {'( '}</Typography>
        <Typography className={classes.textBlue}>
          .jpg, .jpeg, .png, .gif, .tiff, .pjp, .pjpeg, .jfif, .tif
        </Typography>
        <Typography className={classes.textTooltip}>{' )'}</Typography>
      </div>
      <div className={classes.tooltipWrapper}>
        <Typography className={classes.textTooltip}>{`Max size: `}</Typography>
        <Typography className={classes.textBlue}>25MB</Typography>
      </div>
    </>
  )
}

export default TooltipAttachmentGoal
