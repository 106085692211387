import gql from 'graphql-tag'

export const GET_LIST_ARCHIVEMENT = gql`
  query getArchived(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      priority
      performance_group {
        id
        name
        color
      }
      performance_task_assignments {
        global_user {
          id
          name
          avatar
        }
      }
      weight
      startdate
      enddate
      last_modified
      progress
      progress_percentage
      performance_goal {
        id
        kpi_list {
          id
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      complete_at: performance_task_activities(
        where: {comment: {_ilike: "Task is reviewed and complete%"}}
        order_by: [{id: desc}]
        limit: 1
      ) {
        id
        date_added
      }
    }
  }
`
