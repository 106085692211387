import React from 'react'

// material
import {CircularProgress, Button} from '@material-ui/core'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_REPORT_SUMMARY} from '../../../graphql/queries/summary/getReportSummary.query'

// material
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

// Global
import {USER_ID} from '../../../utils/globals'
import moment from 'moment'
import {
  TitleContainer,
  TitleCreate,
  TitleContent,
  FlexBetween,
} from '../PerformanceStyles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

function ListReportSummary({limit, offset, full, handle}) {
  const {data, error} = useQuery(GET_REPORT_SUMMARY, {
    variables: {
      user: `${USER_ID}`,
      offset: offset,
      limit: limit,
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  // console.log(data)

  return (
    <div style={{paddingTop: '15px'}}>
      <TitleContainer>
        <TitleCreate style={{cursor: 'pointer'}}>
          {full && (
            <FlexBetween onClick={handle}>
              <ArrowBackIcon />
              <TitleContent style={{margin: '0px', marginLeft: 12}}>
                Reports
              </TitleContent>
            </FlexBetween>
          )}
        </TitleCreate>
        {!full && (
          <Button
            style={{
              textTransform: 'none',
            }}
            color="secondary"
            onClick={handle}
          >
            View All Reports
          </Button>
        )}
      </TitleContainer>
      <TableContainer style={{paddingTop: '35px'}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Version</TableCell>
              <TableCell align="center">Goal</TableCell>
              <TableCell align="center">Goal Weight</TableCell>
              <TableCell align="center">Multirater</TableCell>
              <TableCell align="center">Multirater Weight</TableCell>
              <TableCell align="center">NPS</TableCell>
              <TableCell align="center">NPS Weight</TableCell>
              <TableCell align="center">OGF</TableCell>
              <TableCell align="center">OGF Weight</TableCell>
              <TableCell align="center">Index Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.performance_individuals.map(row => {
              let date = '-'
              if (row.startdate === null) {
                date = '-'
              } else {
                date =
                  moment(new Date(row.startdate)).format('YYYY') ===
                  moment(new Date(row.enddate)).format('YYYY')
                    ? moment(new Date(row.startdate)).format('YYYY')
                    : moment(new Date(row.startdate)).format('YYYY') +
                      '/' +
                      moment(new Date(row.enddate)).format('YYYY')
              }
              return (
                <TableRow key={row.name}>
                  <TableCell align="right">{date}</TableCell>
                  <TableCell align="right">
                    {row.kpi_score ? row.kpi_score : 0}
                  </TableCell>
                  <TableCell align="right">
                    {row.kpi_weight ? row.kpi_weight : 0}%
                  </TableCell>
                  <TableCell align="right">
                    {row.multirater_score ? row.multirater_score : 0}
                  </TableCell>
                  <TableCell align="right">
                    {row.multirater_weight ? row.multirater_weight : 0}%
                  </TableCell>
                  <TableCell align="right">
                    {row.nps_score ? row.nps_score : 0}
                  </TableCell>
                  <TableCell align="right">
                    {row.nps_weight ? row.nps_weight : 0}%
                  </TableCell>
                  <TableCell align="right">
                    {row.ogf_score ? row.ogf_score : 0}
                  </TableCell>
                  <TableCell align="right">
                    {row.ogf_weight ? row.ogf_weight : 0}%
                  </TableCell>
                  <TableCell align="right">
                    {row.index_score ? row.index_score : 0}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default ListReportSummary
