import React, {useMemo, useState} from 'react'
import useStyles, {Thcustom, Tdcustom, TableRowHover} from './SurveyStyles'
import {Center, IconFeedback, SeeMore} from '../PerformanceStyles'
import ModalDetailFeedbackSurvey from '../detail-modal/ModalDetailFeedbackSurvey'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'
import {useQuery} from '@apollo/react-hooks'

import {GET_LIST_FEED_BACK_RECIVED} from '../../../graphql/queries/multirater/getListFeedBackRecived'
import {GET_LIST_FEED_BACK_SENT} from '../../../graphql/queries/multirater/getListFeedBackSent'

import {getDate} from '../../../utils/helpers'
import {USER_ID} from '../../../utils/globals'

function ListFeedBack(props) {
  const {type, filter, filterDate} = props
  const [open, setOpen] = useState(false)
  const [offset, setOffset] = useState(0)
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [params, setParams] = useState([])
  let query = GET_LIST_FEED_BACK_RECIVED
  let title = 'Pengirim'
  let paramter = 'global_user_from'
  if (type === 'Sent') {
    query = GET_LIST_FEED_BACK_SENT
    title = 'Penerima'
    paramter = 'global_user_to'
  }

  const {data, error, loading, fetchMore} = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      id: `${USER_ID}`,
      search: `%${filter.search_filter || ''}%`,
      dateFrom: filterDate(filter.dateFrom, 'from'),
      dateTo: filterDate(filter.dateTo, 'to'),
      summary: filter.summary.length >= 1 ? filter.summary : null,
      // typeFrom: null,
      orderBy: [{date_added: 'desc'}],
      offset: offset,
      limit: 10,
    },
  })

  useMemo(() => setOffset(0), [filter])

  // style
  const classes = useStyles()

  // logc
  const emotional = {
    DISAPPOINTED: {color: '#ef4d5e', icon: 'sentiment_very_dissatisfied'},
    SATISFIED: {color: '#4caf50', icon: 'sentiment_satisfied_alt'},
    ORDINARY: {color: '#ffa000', icon: 'sentiment_neutral'},
  }

  const dataLength = data?.ongoing_feedback_lists.length
  const total = data?.total?.aggregate?.count
  const loadingData = loading || loadingFetchMore

  if (error) console.error(error)

  if (!loading && total === 0) {
    return (
      <TableRow>
        <TableCell colSpan="4">
          Tidak ada data pada umpan balik yang diterima
        </TableCell>
      </TableRow>
    )
  }

  const handleSeeMore = async () => {
    setLoadingFetchMore(true)
    await fetchMore({
      variables: {offset: dataLength},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          ongoing_feedback_lists: [
            ...prev.ongoing_feedback_lists,
            ...fetchMoreResult.ongoing_feedback_lists,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
    setLoadingFetchMore(false)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <Thcustom>{title}</Thcustom>
            <Thcustom>Topik</Thcustom>
            <Thcustom>Tanggal ditambahkan</Thcustom>
            <Thcustom>Keseluruhan</Thcustom>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data?.ongoing_feedback_lists?.map((row, key) => {
              return (
                <TableRowHover
                  key={key}
                  onClick={() => {
                    setParams(row)
                    setOpen(true)
                  }}
                >
                  <Tdcustom>
                    {row.is_anonymous ? 'Anonymous' : row[paramter].name}
                  </Tdcustom>
                  <Tdcustom>{row.topic}</Tdcustom>
                  <Tdcustom>{getDate(row.date_added)}</Tdcustom>
                  <Tdcustom>
                    <Center style={{color: emotional?.[row.summary].color}}>
                      <IconFeedback>
                        {emotional?.[row.summary].icon}
                      </IconFeedback>
                    </Center>
                  </Tdcustom>
                </TableRowHover>
              )
            })}
        </TableBody>
      </Table>

      {loadingData &&
        Array.from({length: 10}).map((val, i) => (
          <Skeleton
            key={`${val}-${i}`}
            variant="rect"
            height={47}
            style={{margin: 16}}
          />
        ))}

      {!loadingData && dataLength < total && (
        <SeeMore borderTop="none">
          <Button
            style={{
              textTransform: 'none',
            }}
            color="secondary"
            onClick={handleSeeMore}
          >
            Lihat semua
          </Button>
        </SeeMore>
      )}
      <ModalDetailFeedbackSurvey
        open={open}
        onClose={onClose}
        params={params}
        type={type}
      />
    </>
  )
}

export default ListFeedBack
