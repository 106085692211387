import {TASK_STATUS} from '../../pages/performance-tabs/goal-components/constant'
import {
  priority,
  progress_status,
} from '../../pages/performance-tabs/goal-components/TabDialog/constant'

export const SwitchValue = (value, type) => {
  if (type === 'colorProgress') {
    if (value > 75) {
      return '#4caf50'
    } else if (value <= 75) {
      return '#039be5'
    }
  } else if (type === 'colorResult') {
    if (value > 75) {
      return '#4caf50'
    } else if (value > 35 && value <= 75) {
      return '#ffa000'
    } else {
      return '#ef4d5e'
    }
  } else if (type === 'progress_percentage') {
    if (value >= 100) {
      return '#4caf50'
    } else {
      return '#014a62'
    }
  } else if (type === 'status') {
    if (value > 75) {
      return TASK_STATUS.COMPLETED
    } else if (value <= 75) {
      return TASK_STATUS.ONPROGRESS
    }
  } else if (type === 'priority') {
    if (value === 'High' || value === 'HIGH') {
      return '#ef4d5e'
    } else if (value === 'Medium' || value === 'MEDIUM') {
      return '#ffa000'
    } else {
      return '#4caf50'
    }
  } else if (type === 'priority2') {
    if (value === 'High' || value === 'HIGH') {
      return '#ef4d5e'
    } else if (value === 'Medium' || value === 'MEDIUM') {
      return '#ffa000'
    } else {
      return '#039be5'
    }
  } else if (type === 'priorityWord') {
    return priority[value]
  } else if (type === 'colorStatusTask') {
    if (value === 'ARCHIVED') {
      return '#ef4d5e'
    } else if (value === 'OPEN') {
      return '#ffa000'
    } else {
      return '#4caf50'
    }
  } else if (type === 'progress_status') {
    if (value > 100) {
      return progress_status.Exceed
    } else if (value === 100) {
      return progress_status.Meet
    } else {
      return progress_status.Below
    }
  } else {
    if (value > 75) {
      return progress_status.Outstanding
    } else if (value > 35 && value <= 75) {
      return progress_status.Almost
    } else {
      return progress_status.Poor
    }
  }
}
