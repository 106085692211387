import gql from 'graphql-tag'

export const ADD_ACTIVITIES_TASK = gql`
  mutation addActivities($data: [performance_task_activities_insert_input!]!) {
    insert_performance_task_activities(objects: $data) {
      affected_rows
    }
  }
`
export const ADD_COMMENT_ACTIVITIES_TASK = gql`
  mutation addComment($data: [performance_task_comments_insert_input!]!) {
    insert_performance_task_comments(objects: $data) {
      affected_rows
    }
  }
`
export const UPDATE_COMMENT_ACTIVITIES_TASK = gql`
  mutation updateCommentTask(
    $comment: String
    $attachment: jsonb
    $id: bigint
  ) {
    update_performance_task_comments(
      _set: {comment: $comment, attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_ACTIVITY_TASK = gql`
  mutation updateTaskActivities($attachment: jsonb, $id: bigint) {
    update_performance_task_activities(
      _set: {attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_ACTIVITY_TASKS = gql`
  mutation updateTaskActivities(
    $comment: String
    $attachment: jsonb
    $id: bigint
  ) {
    update_performance_task_activities(
      _set: {comment: $comment, attachment: $attachment}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_ACTIVITY_TASK = gql`
  mutation updateTaskActivities($id: bigint) {
    update_performance_task_activities(
      _set: {deleted_at: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const DELETE_COMMENT_ACTIVITY_TASK = gql`
  mutation updateCommentTaskActivity($id: bigint) {
    update_performance_task_comments(
      _set: {deletedat: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
