import React, {Fragment} from 'react'

import {getDate} from '../../../utils/helpers'

import {Icon, IconButton} from '@material-ui/core'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
  DetailNSPContainer,
  CardResultFeedback,
  CardAssigned,
  AvatarAssigned,
  CardAssignedContainer,
  CardNSPContainer,
} from './ModalPerformanceStyles'
import {FlexBetween, FlexColumn, IconFeedback} from '../PerformanceStyles'
import {
  Pmd,
  TextXl,
  P18Bold,
  P14Bold,
} from '../../../components/typography/TypographyStyles'

function ModalResultDetailFeedback(props) {
  const {open, onClose, user, id} = props

  // console.log(user)
  const feedbackList = user && user.ongoing_feedback_lists[id]
  const user_from = feedbackList && feedbackList.global_user_from

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      // open={this.state.openModal}
      open={open}
      fullWidth
      maxWidth={window.innerWidth < 1920 ? 'md' : 'lg'}
    >
      {/* {user &&
        user.ongoing_feedback_lists.map((res, key) => {
          const from = feedbackList.global_user_from
          // console.log(user.ongoing_feedback_lists[id])
          return ( */}
      <Fragment>
        <ModalHeader>
          <FlexBetween>
            <TextXl>{user_from.name}</TextXl>
            <IconButton aria-label="close" onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
          <Pmd>
            Dibuat oleh{' '}
            <span style={{color: '#039be5'}}>{user_from.name} </span>{' '}
            {`pada ${getDate(feedbackList.date_added)}`}
          </Pmd>
        </ModalHeader>
        <ModalContent>
          <FlexBetween>
            <Pmd>Rincian Hasil</Pmd>
            {/* <Button variant="contained" color="primary" size="large">
              Generate Report
            </Button> */}
          </FlexBetween>
          <DetailNSPContainer>
            <CardAssignedContainer>
              {user_from &&
                user_from.people_work_placements.map(
                  (resPosition, keyPosition) => {
                    return (
                      <CardAssigned key={keyPosition}>
                        <Pmd>Responden</Pmd>
                        <AvatarAssigned
                          src={user_from.avatar}
                          alt="assigned-to"
                        ></AvatarAssigned>
                        <P18Bold>{user_from.name}</P18Bold>

                        <Pmd style={{color: '#a9a8a8'}}>
                          {resPosition.company_employee_position.name} -{' '}
                        </Pmd>
                        <Pmd style={{color: '#a9a8a8'}}>
                          {resPosition.company_address.office_name}
                        </Pmd>
                      </CardAssigned>
                    )
                  }
                )}
            </CardAssignedContainer>
            <CardNSPContainer>
              <CardResultFeedback>
                <FlexBetween>
                  <FlexBetween style={{width: '15%'}}>
                    <IconFeedback
                      style={(() => {
                        switch (feedbackList.summary) {
                          case 'DISAPPOINTED':
                            return {color: '#ef4d5e', margin: '10px 35px'}
                          case 'ORDINARY':
                            return {color: '#ffa000', margin: '10px 35px'}
                          case 'SATISFIED':
                            return {color: '#4caf50', margin: '10px 35px'}
                          default:
                            return {color: '#ffffff', margin: '10px 35px'}
                        }
                      })()}
                    >
                      {(() => {
                        switch (feedbackList.summary) {
                          case 'DISAPPOINTED':
                            return 'sentiment_very_dissatisfied'
                          case 'ORDINARY':
                            return 'sentiment_neutral'
                          case 'SATISFIED':
                            return 'sentiment_satisfied_alt'
                          default:
                            return ''
                        }
                      })()}
                    </IconFeedback>
                  </FlexBetween>
                  <FlexBetween style={{width: '100%'}}>
                    <P18Bold
                      style={(() => {
                        switch (feedbackList.summary) {
                          case 'DISAPPOINTED':
                            return {color: '#ef4d5e', margin: '10px 35px'}
                          case 'ORDINARY':
                            return {color: '#ffa000', margin: '10px 35px'}
                          case 'SATISFIED':
                            return {color: '#4caf50', margin: '10px 35px'}
                          default:
                            return {color: '#ffffff', margin: '10px 35px'}
                        }
                      })()}
                    >
                      {(() => {
                        switch (feedbackList.summary) {
                          case 'DISAPPOINTED':
                            return 'Merasa Tidak Puas'
                          case 'ORDINARY':
                            return 'Merasa Netral'
                          case 'SATISFIED':
                            return 'Merasa Puas'
                          default:
                            return ''
                        }
                      })()}
                    </P18Bold>
                  </FlexBetween>
                </FlexBetween>
                <FlexColumn>
                  <P14Bold>Respon</P14Bold>
                  <Pmd>{feedbackList.description}</Pmd>
                </FlexColumn>
              </CardResultFeedback>
            </CardNSPContainer>
          </DetailNSPContainer>
          <Pmd
            onClick={onClose}
            style={{
              color: '#039be5',
              marginTop: '30px',
              cursor: 'pointer',
            }}
          >
            Kembali ke Daftar
          </Pmd>
        </ModalContent>
      </Fragment>
    </StyledDialog>
  )
}

export default ModalResultDetailFeedback
