import React, {useState} from 'react'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
} from '../../detail-modal/ModalPerformanceStyles'
import {
  FlexBetween,
  FormElement,
  FilterAssignWrapper,
  StyledExpansionSummaryAssign,
  FormChildButton,
  StyledBorderedExpansion,
} from '../../PerformanceStyles'
import {
  TextXl,
  Pmd,
  PmdGrey,
  PsmGrey,
} from '../../../../components/typography/TypographyStyles'
import {StyledTextField} from '../../../../components/input/InputsStyles'
import {StyledSmallButton} from '../../../../components/button/ButtonStyles'
import Select from 'react-select'

import {
  Icon,
  IconButton,
  InputAdornment,
  ExpansionPanelDetails,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {SwitchValue} from '../../../../components/color-switch/ColorSwitcher'
import ModalAssignTaskFinal from './ModalAssignTaskFinal'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_GOAL_ASSIGN_TASK,
  GET_DETAIL_TAB_TASK,
  GET_LITE_ASSIGN_TASK,
} from '../../../../graphql/queries'
import Detail from '../TabDialog/Detail'
import LoadingComponent from '../../../../components/loading/LoadingComponent'
import NoDataListComponent from '../../../shared-component/NoDataListComponent'
import {USER_ID, COMPANY_ID} from '../../../../utils/globals'
import {priorities} from '../TabDialog/constant'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

function ModalAssignTask(props) {
  const customStyle = {
    flex: {
      display: 'flex',
    },
    PR: {
      paddingRight: '3rem',
    },
    pR: {
      paddingRight: '1.5rem',
    },
    buttonSpacer: {
      marginRight: 12,
    },
  }

  const [next, setNext] = useState(false)
  const [selectedPriority, setSelectedPriority] = useState([])
  const [expanded, setExpanded] = useState(false)
  const [goalDropdown, setGoalDropdown] = useState([])
  const [searchDropdown, setSearchDropdown] = useState('')
  // const [checkedItem, setCheckedItem] = useState([])
  const [selectedTask, setSelectedTask] = useState([])
  const [values, setValues] = useState({
    // goal: 'all',
    name: '',
    // priority: 'all',
  })

  const undefinedChecker = arr => arr.every(v => !v)

  const {data: dataGoal, error: errorGoal, loading: loadingGoal} = useQuery(
    GET_LIST_GOAL_ASSIGN_TASK,
    {
      variables: {
        company: COMPANY_ID,
        user: USER_ID,
        search: `%${searchDropdown}%`,
      },
      skip: !props.open,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    }
  )

  const {data: dataDetailTask, loading: loadingDetailTask} = useQuery(
    GET_DETAIL_TAB_TASK,
    {
      variables: {id: expanded && parseInt(expanded)},
      skip: !expanded,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    }
  )

  const _dataGoal = [
    {label: `Semua ${GLOBAL_GLOSSARY.performance.Goal_short}`, value: null},
  ]
  dataGoal &&
    dataGoal.performance_goals.map(options => {
      const goalOptions = {
        value: options.id,
        label: options.name,
      }

      _dataGoal.push(goalOptions)
    })

  const valueFilterPriority =
    selectedPriority && selectedPriority.map(res => res.value)

  const {data, loading} = useQuery(GET_LITE_ASSIGN_TASK, {
    variables: {
      company: COMPANY_ID,
      user: USER_ID,
      search: `%${values.name}%`,
      priority:
        valueFilterPriority && valueFilterPriority.length > 0
          ? {
              _in: valueFilterPriority,
            }
          : {},
      goal: {
        _eq: values.goal,
      },
    },
    skip: !props.open,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  if (errorGoal) {
    console.error(errorGoal)
    return 'Something went wrong.'
  }

  const taskChecked = selectedTask.map(res => res.checked)

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    })
  }

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    event.preventDefault()
  }

  const handleChangePriority = values => {
    setSelectedPriority(values)
  }

  const handleClickNext = () => {
    setNext(true)
  }

  const handleClickBack = () => {
    setNext(false)
  }

  const handleChecked = async event => {
    event.stopPropagation()
    const prevChecked = data.performance_tasks
    const isChecked = event.target.checked
    const id = event.target.value
    const nextChecked = prevChecked.filter(position => {
      const pos = position
      if (pos.id.toString() === id) {
        pos.checked = isChecked
      }
      return pos
    })
    const newSelected = isChecked
      ? [
          ...selectedTask,
          ...nextChecked.filter(
            el => el.checked && !selectedTask.some(s => s.id === el.id)
          ),
        ]
      : nextChecked.filter(el => el.checked)

    setSelectedTask(newSelected)
  }

  const customSelect = {
    control: provided => ({
      ...provided,
      '&:hover': {borderColor: '#000'},
      marginTop: '1rem',
      minHeight: 56,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: (base, value) => ({
      ...base,
      backgroundColor: SwitchValue(value.children, 'priority2'),
      borderRadius: 6,
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
      margin: '8px 9px',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      margin: '8px 9px',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
  }

  return (
    <StyledDialog
      onClose={() => {
        props.handleClose()
        handleClickBack()
        setValues({name: ''})
        setSelectedPriority([])
        setSelectedTask([])
        setGoalDropdown([])
      }}
      open={props.open}
      maxWidth="md"
      fullWidth
    >
      <ModalHeader>
        <FlexBetween>
          <TextXl>Menetapkan {GLOBAL_GLOSSARY.performance.Task}</TextXl>
          <IconButton
            aria-label="close"
            onClick={() => {
              props.handleClose()
              handleClickBack()
              setValues({name: ''})
              setSelectedPriority([])
              setSelectedTask([])
              setGoalDropdown([])
            }}
          >
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      {next ? (
        <ModalAssignTaskFinal
          item={selectedTask}
          resetSelectedTask={setSelectedTask}
          handleClickBack={handleClickBack}
          handleClose={() => {
            props.handleClose()
            setValues({name: ''})
            setSelectedPriority([])
            setSelectedTask([])
            setGoalDropdown([])
          }}
        />
      ) : (
        <>
          <ModalContent
            style={{paddingBottom: 10, maxHeight: '60vh', overflow: 'auto'}}
          >
            <div>
              <PmdGrey>{GLOBAL_GLOSSARY.performance.Task}</PmdGrey>
              <StyledTextField
                id="outlined-simple-start-adornment"
                variant="outlined"
                width="100%"
                label="Cari"
                value={values.name}
                onChange={handleChange('name')}
                style={{marginTop: 12}}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FilterAssignWrapper>
              <FormElement style={{paddingRight: 12}}>
                <PmdGrey>Nama {GLOBAL_GLOSSARY.performance.Goal}</PmdGrey>
                <Select
                  id="select-goal"
                  options={_dataGoal}
                  value={goalDropdown}
                  placeholder={`Semua ${GLOBAL_GLOSSARY.performance.Goal_short}`}
                  isLoading={loadingGoal}
                  onInputChange={dataSearchDropdown =>
                    setSearchDropdown(dataSearchDropdown)
                  }
                  onChange={event => {
                    setValues({
                      ...values,
                      goal: event.value,
                    })
                    setGoalDropdown(event)
                  }}
                  styles={customSelect}
                />
              </FormElement>
              <FormElement>
                <PmdGrey>Prioritas</PmdGrey>
                <Select
                  placeholder="Prioritas"
                  value={selectedPriority}
                  options={priorities}
                  onChange={handleChangePriority}
                  isMulti
                  styles={customSelect}
                />
              </FormElement>
            </FilterAssignWrapper>

            {loading ? (
              <LoadingComponent />
            ) : data?.performance_tasks?.length === 0 ? (
              <NoDataListComponent
                search={
                  values.name ||
                  (valueFilterPriority && valueFilterPriority.length > 0) ||
                  values.goal
                }
              />
            ) : (
              data?.performance_tasks.map(res => {
                return (
                  <StyledBorderedExpansion
                    expanded={expanded === res.id}
                    onChange={handleExpand(res.id)}
                    key={res.id}
                    elevation={0}
                  >
                    <StyledExpansionSummaryAssign>
                      <Checkbox
                        color="primary"
                        name={res.name}
                        onClick={handleChecked}
                        checked={
                          selectedTask.find(i => i.id === res.id)?.checked
                        }
                        value={res.id}
                      ></Checkbox>
                      <div style={{width: '80%'}}>
                        <Pmd>{res.name}</Pmd>
                        <PsmGrey>#{res.id}</PsmGrey>
                      </div>
                      <StyledSmallButton
                        background={SwitchValue(res.priority, 'priority2')}
                      >
                        {SwitchValue(res.priority, 'priorityWord')}
                      </StyledSmallButton>
                    </StyledExpansionSummaryAssign>
                    {expanded === res.id && !loadingDetailTask ? (
                      dataDetailTask &&
                      dataDetailTask.performance_tasks.map((res, i) => (
                        <ExpansionPanelDetails key={i} style={customStyle.flex}>
                          <Detail
                            data={{performance_tasks: [res]}}
                            type="taskDetail"
                          />
                        </ExpansionPanelDetails>
                      ))
                    ) : expanded === res.id && loadingDetailTask ? (
                      <CircularProgress />
                    ) : null}
                  </StyledBorderedExpansion>
                )
              })
            )}
          </ModalContent>
          <FormChildButton style={{paddingBottom: 0, background: '#eff2f4'}}>
            <Button
              size="large"
              style={customStyle.buttonSpacer}
              onClick={() => {
                props.handleClose()
                setValues({name: ''})
                setSelectedPriority([])
                setSelectedTask([])
                setGoalDropdown([])
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClickNext}
              disabled={undefinedChecker(taskChecked)}
            >
              Selanjutnya
            </Button>
          </FormChildButton>
        </>
      )}
    </StyledDialog>
  )
}

export default ModalAssignTask
