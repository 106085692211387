import {GlobalSidebar} from '@smartasn/wlb-utils-components'
import React from 'react'

export const AccessToAnotherModule = () => {
  return (
    <GlobalSidebar
      language="indonesian"
      env={{
        SOSMED_URL: process.env.REACT_APP_HC_SOSMED,
        USER_URL: process.env.REACT_APP_HC_TIME,
        PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
        LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
        TALENT_URL: process.env.REACT_APP_HC_TALENT,
        VENDOR_URL: process.env.REACT_APP_HC_VENDOR,
      }}
    />
  )
}
