import React, {useState} from 'react'

// style
import useStyles from './SurveyStyles'

// material
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

// filter
import Filter from './Filter/Filter'

// konsum
import ListFeedBack from './ListFeedBack'
import ListFeedBackReq from './ListFeedBackReq'

import moment from 'moment'

// tabs
function TabsFeedBack() {
  // filter

  const [filter, setFilter] = useState({
    search_filter: '',
    dateFrom: 'Invalid date',
    dateTo: 'Invalid date',
    summary: [],
  })

  const [targerFilter, setTargetFilter] = useState(['Kesan', 'Tanggal'])

  // style
  const classes = useStyles()

  // logi
  const [typeList, setTypeList] = useState('Received')

  const SwitchButton = type => {
    if (typeList === type) {
      return 'contained'
    } else {
      return 'outlained'
    }
  }
  const handleClickType = type => {
    // setOpen(type)

    if (type === 'Received' || type === 'Sent') {
      setTargetFilter(['Kesan', 'Tanggal'])
    } else {
      setTargetFilter(['Tanggal'])
    }

    setTypeList(type)
    // console.log(type)
  }

  const filterDate = (tanggal, type) => {
    if (tanggal !== 'Invalid date' && tanggal !== null) {
      const hours = type === 'from' ? '00:00:01' : '23:59:59'
      const day = new Date(`${tanggal} ${hours}`)
      // const newDateTo = filter.dateTo.split('-')
      // const newDay = parseInt(newDateTo[2]) + 1
      // return `${newDateTo[0]}-${newDateTo[1]}-${newDay}`
      return moment(day, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }

  return (
    <Grid container style={{marginTop: '22.8px'}}>
      <Grid item xs={12} sm={5} style={{marginTop: '7px'}}>
        <Button
          style={{textTransform: 'none'}}
          className={classes.buttonFeed}
          onClick={() => handleClickType('Received')}
          value="Received"
          variant={SwitchButton('Received')}
          color="primary"
        >
          Terima
        </Button>
        &nbsp;
        <Button
          style={{textTransform: 'none'}}
          className={classes.buttonFeed}
          onClick={() => handleClickType('Sent')}
          value={'Sent'}
          variant={SwitchButton('Sent')}
          color="primary"
        >
          Terkirim
        </Button>
        &nbsp;
        <Button
          style={{textTransform: 'none'}}
          className={classes.buttonFeed}
          onClick={() => handleClickType('Request')}
          value="Request"
          variant={SwitchButton('Request')}
          color="primary"
        >
          Permintaan
        </Button>
      </Grid>
      <Filter filter={filter} setFilter={setFilter} target={targerFilter} />

      <Grid item sm={12} style={{marginTop: '21.8px'}}>
        {(typeList === 'Received' || typeList === 'Sent') && (
          <ListFeedBack
            filter={filter}
            type={typeList}
            filterDate={filterDate}
          />
        )}
        {typeList === 'Request' && (
          <ListFeedBackReq filter={filter} filterDate={filterDate} />
        )}
      </Grid>
    </Grid>
  )
}

export default TabsFeedBack
