import gql from 'graphql-tag'
import {getCookie} from '../../../utils/helpers'

const userData = getCookie('userData') && JSON.parse(getCookie('userData'))

/* Dikasih pengkondisian menggunakan hardcode user id, sementara untuk menampilkan data survey multirater yang sudah ada. 
fleksibel user id nya menggunakan userData.id */

export const GET_LIST_SEND_TO = search => {
  return gql`
  {
    subordinate: people_work_placements(
      where: {
        company_job_profile:{
          job_profile_supervisor:{
            people_work_placements:{
              user:{_eq:"${String(userData.id)}"},
              status:{_eq:"ACTIVE"}
            }
          }
        },
        status:{_eq:"ACTIVE"}
        global_user: {
          name: {
            _ilike: "%${String(search)}%"
          }
        }
      }, distinct_on: user) {
      user: user
      global_user {
        name
        avatar
        people_work_placements {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
    
    insight: people_work_placements(where: {
      company_job_profile:{
        job_profile_subordinate:{
          people_work_placements:{
            user:{_eq:"${String(userData.id)}"},
            status:{_eq:"ACTIVE"}
          }
        }
      }
      global_user: {
        name: {
          _ilike: "%${String(search)}%"
        }
      },
      status:{_eq:"ACTIVE"}
    }) {
      user: supervisor_id
      global_user: global_user_supervisor {
        name
        avatar
        people_work_placements {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }

    feedback: people_work_placements(where: {
      company_job_profile:{
        job_profile_supervisor:{
          job_profile_subordinate:{
            people_work_placements:{
              user:{_eq:"${String(userData.id)}"},
              status:{_eq:"ACTIVE"}
            }
          }
        }
      },
      user:{_neq:"${String(userData.id)}"},
      status:{_eq:"ACTIVE"},
      global_user: {
            name: {
              _ilike: "%${String(search)}%"
            }
          }
    }) {
      user
      global_user {
        name
        avatar
        people_work_placements {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
  `
}

export const GET_LIST_RESPONDENT = gql`
  query ListSendToOGF($user: uuid, $company: uuid, $search: String) {
    people_work_placements(
      where: {
        company: {_eq: $company}
        user: {_neq: $user}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        status: {_eq: "ACTIVE"}
      }
      limit: 10
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        code
        title
        job_profile_supervisor {
          people_work_placements_aggregate(
            where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
          ) {
            aggregate {
              count #-----------> ketika > 0 type_from = "INSIGHT", type_to = "SUBORDINATE"
            }
          }
        }

        job_profile_subordinate_aggregate(
          where: {
            people_work_placements: {
              user: {_eq: $user}
              status: {_eq: "ACTIVE"}
            }
          }
        ) {
          aggregate {
            count #-----------> ketika > 0 type_from = "SUBORDINATE", type_to = "INSIGHT"
          }
        }
      }
    }
  }
`
