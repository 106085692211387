import gql from 'graphql-tag'

export const EDIT_BADGES = gql`
  mutation($id: Int!, $talentBoxId: Int!) {
    update_talent_assignments(
      where: {id: {_eq: $id}}
      _set: {badge_classification: $talentBoxId}
    ) {
      affected_rows
    }
  }
`
