import gql from 'graphql-tag'

export const ASSIGN_TASK = gql`
  mutation addTasks($data: [performance_task_assignments_insert_input!]!) {
    insert_performance_task_assignments(objects: $data) {
      affected_rows
    }
  }
`
export const UPDATE_REPORTER = gql`
  mutation($id: bigint!, $reporter: uuid!) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {reporter: $reporter}
    ) {
      affected_rows
    }
  }
`

export const LEAVE_TASK = gql`
  mutation deleteAssignmentTask($user: uuid!, $task: bigint!) {
    delete_performance_task_assignments(
      where: {user: {_eq: $user}, task: {_eq: $task}}
    ) {
      affected_rows
    }
  }
`
