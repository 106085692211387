import gql from 'graphql-tag'

/* Dikasih pengkondisian menggunakan hardcode user id, sementara untuk menampilkan data survey multirater yang sudah ada. 
fleksibel user id nya menggunakan userData.id */

export const GET_LIST_FEED_BACK_SENT = gql`
  query getLISTOGFSend(
    $id: uuid!
    $search: String
    $dateFrom: timestamptz
    $dateTo: timestamptz
    $summary: [enum_ongoing_feedback_summary!]
    $typeTo: [enum_ongoing_feedback_type!]
    $offset: Int
    $limit: Int
    $orderBy: [ongoing_feedback_lists_order_by!]
  ) {
    total: ongoing_feedback_lists_aggregate (
      where: {
        ongoing_from: {_eq: $id}
        _or: [
          {global_user_to: {name: {_ilike: $search}}}
          {topic: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        type_to: {_in: $typeTo}
        summary: {_in: $summary}
      }
    ) {
      aggregate {
        count
      }
    }
    ongoing_feedback_lists(
      where: {
        ongoing_from: {_eq: $id}
        _or: [
          {global_user_to: {name: {_ilike: $search}}}
          {topic: {_ilike: $search}}
          {description: {_ilike: $search}}
        ]
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        type_to: {_in: $typeTo}
        summary: {_in: $summary}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id # id feedback
      topic # topic
      description
      global_user_to {
        id # id pengirim
        name # nama pengirim
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
      global_user_from {
        id # id pengirim
        name # nama pengirim
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
      summary # summary emoticon
      type_from # tipe dari
      date_added # data ditambahkan
    }
  }
`
