import DateFnsUtils from '@date-io/date-fns'
import {
  Button,
  Divider,
  Icon,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import React from 'react'
import {
  ModalContent,
  ModalHeader,
  StyledDialog,
} from '../performance-tabs/detail-modal/ModalPerformanceStyles'
import {
  FlexBetween,
  FlexCenter,
  FormChildButton,
  GreyTypography,
} from '../performance-tabs/PerformanceStyles'
import moment from 'moment'

const useStyles = makeStyles({
  flex: {display: 'flex'},
  spacerh4: {marginLeft: 8},
  spacerTanggel: {margin: '35px 16px 0'},
  pickerContainer: {
    display: 'flex',
    marginTop: 18,
  },
  textReset: {textAlign: 'right', cursor: 'pointer'},
  buttonSpacer: {
    marginRight: 12,
  },
})

const DateRange = ({
  open,
  handleClose,
  startDate,
  endDate,
  handleChange,
  handleGenerate,
}) => {
  const classes = useStyles()
  const isDateAfter = moment(startDate).isAfter(moment(endDate))
  const datesPicked = (type, date) => (
    <div>
      <GreyTypography variant="body1">{type}</GreyTypography>
      <FlexCenter>
        <Typography variant="h4" color="primary">
          {moment(date).format('D')}
        </Typography>
        <div className={classes.spacerh4}>
          <Typography variant="body1" color="primary">
            {moment(date).format('MMMM')} {moment(date).format('YYYY')}
          </Typography>
          <GreyTypography variant="body2" gutterBottom>
            {moment(date).format('dddd')}
          </GreyTypography>
        </div>
      </FlexCenter>
    </div>
  )
  return (
    <StyledDialog
      onClose={handleClose}
      open={open}
      maxWidth="md"
      //   fullWidth
    >
      <ModalHeader>
        <FlexBetween>
          <div className={classes.flex}>
            {datesPicked('Dari', startDate)}
            <Typography className={classes.spacerTanggel}>-</Typography>
            {datesPicked('Sampai', endDate)}
          </div>
          <IconButton aria-label="close" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <ModalContent style={{paddingBottom: 0}}>
        <Typography
          variant="body1"
          className={classes.textReset}
          color="secondary"
          onClick={() => handleChange('reset')}
        >
          Atur Ulang
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className={classes.pickerContainer}>
            <DatePicker
              disableToolbar
              autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              value={startDate}
              onChange={date => handleChange('start', date)}
            />
            <Divider orientation="vertical" style={{height: '34vh'}} />
            <DatePicker
              disableToolbar
              autoOk
              orientation="landscape"
              variant="static"
              openTo="date"
              value={endDate}
              onChange={date => handleChange('end', date)}
              minDate={startDate}
            />
          </div>
        </MuiPickersUtilsProvider>
        {isDateAfter && (
          <Typography variant="body2" color="error">
            Tanggal mulai tidak boleh lebih dari tanggal akhir
          </Typography>
        )}
      </ModalContent>
      <FormChildButton style={{paddingBottom: 0}}>
        <Button
          size="large"
          className={classes.buttonSpacer}
          onClick={handleClose}
        >
          Batal
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleGenerate}
          disabled={!startDate || !endDate || isDateAfter}
        >
          Buat laporan
        </Button>
      </FormChildButton>
    </StyledDialog>
  )
}

export default DateRange
