import React from 'react'
import {FormControlLabel, Checkbox} from '@material-ui/core'
const FormFilter = ({filterData, handleChange, state}) => {
  let render = null
  switch (filterData.name) {
    case 'job_profile':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.job_profile.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'badge':
      render =
        filterData &&
        filterData.data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((res, i) => {
            return (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    onChange={handleChange(filterData.name)}
                    name={res.name}
                    color="primary"
                    value={res.id}
                    checked={state.badge.includes(res.id)}
                  />
                }
                label={res.name}
              />
            )
          })
      break
    case 'organization':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.organization.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'position':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.position.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'office':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.office.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'aspiration':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.aspiration.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    default:
      break
  }
  return render
}
export default FormFilter
