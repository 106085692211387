import React, {Fragment, useState} from 'react'
import {Icon, IconButton, Button, Typography, Paper} from '@material-ui/core'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
  CardAssigned,
  CardAssignedContainerReq,
  DetailMainContainer,
  AvatarAssigned,
} from './ModalPerformanceStyles'
import {
  PsmGrey,
  Pmd,
  P18Bold,
  P14Bold,
  PmdG,
} from '../../../components/typography/TypographyStyles'

import {
  FlexBetween,
  StyledLinear,
  GoalsListBtn,
  GoalTypeBtn,
  SpanMyGoals,
  SpanGoalType,
} from '../PerformanceStyles'
import ParticipantPopup from '../../shared-component/ParticipantPopup'
import MonthAchievement from './MonthAchievement'
import moment from 'moment'
import {goalType, priority} from '../goal-components/TabDialog/constant'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_DETAIL_ACHIEVEMENT} from '../../../graphql/queries/index'
import {withRouter} from 'react-router-dom'
import LoadingComponent from '../../../components/loading/LoadingComponent'
import {TYPE} from '../goal-components/constant'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

const customStyle = {
  flexValue: {
    margin: ' 1rem auto',
    justifyContent: 'center',
  },
  flexValue2: {
    margin: ' 0rem auto',
  },
  wrapperGenerate: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    alignItems: 'flex-end',
  },
  divider: {
    height: '70px',
  },
}

export default withRouter(function ModalDetailAchievement(props) {
  const {open, onClose, id} = props
  const {data, error, loading} = useQuery(GET_DETAIL_ACHIEVEMENT(id), {
    skip: id === 0,
    pollInterval: 500,
  })
  const [openDetailParticipant, setOpenDetailParticipant] = useState(false)
  const [dataParticipant, setDataParticipant] = useState([])

  if (error) {
    console.error({error})
  }

  const handleReport = id => {
    props.history.push({
      pathname: `/generate-report-task/${id}`,
    })
  }

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      // open={this.state.openModal}
      open={open}
      fullWidth
      maxWidth={window.innerWidth < 1920 ? 'md' : 'lg'}
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        data?.performance_tasks?.map(res => {
          const formattedDateAdded = `${moment(res.date_added).format(
            'LL'
          )} &bull; ${moment(res.date_added).format('LT')}`
          const resWeight =
            res?.performance_goal?.kpi_list?.kpi_weight_unit?.name
          const typeGoal =
            res?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
              ?.aggregate?.count > 0
          return (
            <Fragment key={res.id}>
              <ModalHeader style={{padding: '0.8rem 2rem'}}>
                <FlexBetween>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        style={{
                          color: '#039be5',
                          marginRight: '0.5rem',
                          fontSize: '18px',
                        }}
                      >
                        [PF-{res.id}]
                      </Typography>
                      <P18Bold style={{margin: 0}}>{res.name}</P18Bold>
                    </div>
                    <Pmd
                      style={{marginTop: 6}}
                      dangerouslySetInnerHTML={{
                        __html: `Dibuat oleh <span style="color: #039be5">${
                          res.created_by_user.name
                        }</span> ${
                          res.date_added
                            ? `pada <span style="font-family: muli">${formattedDateAdded}</span>`
                            : ''
                        }`,
                      }}
                    />
                  </div>
                  <IconButton
                    aria-label="close"
                    onClick={onClose}
                    style={{padding: '5px'}}
                  >
                    <Icon style={{fontSize: 35, color: '#a9a8a8'}}>close</Icon>
                  </IconButton>
                </FlexBetween>
                <div style={{marginTop: '10px'}}>
                  <GoalsListBtn
                    style={(() => {
                      switch (res.priority) {
                        case 'HIGH':
                          return {
                            backgroundColor: '#ef4d5e',
                          }
                        case 'MEDIUM':
                          return {
                            backgroundColor: '#ffa000',
                          }
                        case 'LOW':
                          return {
                            backgroundColor: '#039be5',
                          }
                        default:
                          return '#FFFFFF'
                      }
                    })()}
                  >
                    <SpanMyGoals>{priority[res?.priority]}</SpanMyGoals>
                  </GoalsListBtn>
                  <GoalTypeBtn
                    style={{backgroundColor: typeGoal ? '#a35a31' : '#874caf'}}
                  >
                    <SpanGoalType>
                      {`${typeGoal ? TYPE.COMPANY_GOAL : TYPE.PERSONAL_GOAL} ${
                        res?.method_type ? '-' : ''
                      } ${goalType[res?.method_type] ?? ''}`}
                    </SpanGoalType>
                  </GoalTypeBtn>
                </div>
              </ModalHeader>
              <ModalContent style={{padding: '0.8rem 2rem'}}>
                <div style={customStyle.wrapperGenerate}>
                  <P14Bold style={{alignSelf: 'center'}}>
                    Rincian Kinerja
                  </P14Bold>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => handleReport(id)}
                  >
                    Buat Laporan
                  </Button>
                </div>
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <CardAssignedContainerReq>
                    <CardAssigned
                      style={{textAlign: 'center', padding: 0, height: '100%'}}
                    >
                      <div style={{padding: '1.2rem'}}>
                        <div>
                          <Pmd style={{color: '#252525', fontSize: 14}}>
                            Pemberi Tugas
                          </Pmd>
                          <AvatarAssigned
                            style={{margin: '15px auto', height: 80, width: 80}}
                            src={
                              res.ReporterUser
                                ? res.ReporterUser.avatar
                                : res.created_by_user &&
                                  res.created_by_user.avatar
                            }
                            alt="assigned-to"
                          />
                          <div>
                            <P14Bold style={{margin: 0}}>
                              {res.ReporterUser
                                ? res.ReporterUser.name
                                : res.created_by_user &&
                                  res.created_by_user.name}
                            </P14Bold>
                            <PsmGrey>
                              {res.ReporterUser
                                ? res.ReporterUser.people_work_placements[0]
                                    .company_job_profile.title
                                : res.created_by_user &&
                                  res.created_by_user.people_work_placements[0]
                                    .company_job_profile.title}{' '}
                              at{' '}
                              {res.ReporterUser
                                ? res.ReporterUser.people_work_placements[0]
                                    .company_profile.brand_name
                                : res.created_by_user &&
                                  res.created_by_user.people_work_placements[0]
                                    .company_profile.brand_name}
                            </PsmGrey>
                          </div>
                        </div>
                        <P14Bold style={{marginTop: 20}}>Peserta</P14Bold>
                        <div
                          style={{
                            marginTop: '1rem',
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginLeft: 10,
                          }}
                        >
                          {res.performance_task_assignments
                            .slice(0, 7)
                            .map((ava, i) => {
                              return (
                                <AvatarAssigned
                                  src={ava.global_user.avatar}
                                  alt="assigned-to"
                                  key={i}
                                />
                              )
                            })}
                          {res.performance_task_assignments.length > 7 ? (
                            <div
                              style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: '#a9a8a8',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#ffff',
                              }}
                              onClick={() => {
                                setOpenDetailParticipant(!openDetailParticipant)
                                setDataParticipant(
                                  res.performance_task_assignments
                                )
                              }}
                            >
                              <Typography variant="body1">
                                +
                                {parseInt(
                                  res.performance_task_assignments.length
                                ) - 7}
                              </Typography>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </CardAssigned>
                  </CardAssignedContainerReq>
                  <DetailMainContainer style={{marginLeft: '0.8rem'}}>
                    <FlexBetween>
                      <CardAssigned
                        style={{
                          width: '45%',
                          alignSelf: 'start',
                          marginRight: '0.8rem',
                          minHeight: 90,
                        }}
                      >
                        <FlexBetween>
                          <P14Bold style={{margin: 0}}>
                            Kontribusi pada {GLOBAL_GLOSSARY.performance.Goal}
                          </P14Bold>
                          <P14Bold style={{margin: 0}}>
                            {res.target ? res.target.toFixed(2) : '0'}
                          </P14Bold>
                        </FlexBetween>
                        <FlexBetween style={{marginTop: 5}}>
                          <PmdG>
                            {`(${res?.performance_goal?.kpi_list
                              ?.kpi_scoring_nature?.name ?? '-'})`}
                          </PmdG>
                          <PmdG>{res?.kpi_weight_unit?.name ?? 'N/A'}</PmdG>
                        </FlexBetween>
                        <FlexBetween style={{marginTop: 10}}>
                          <P14Bold style={{margin: 0}}>Tercapai</P14Bold>
                          <P14Bold style={{margin: 0}}>{`${res.progress} (${
                            res.progress_percentage
                              ? res.progress_percentage.toFixed(2)
                              : 0
                          }%)`}</P14Bold>
                        </FlexBetween>
                        <FlexBetween style={{marginTop: 5}}>
                          <PmdG>
                            {`(${res?.performance_goal?.kpi_list
                              ?.kpi_scoring_nature?.name ?? '-'})`}
                          </PmdG>
                          <PmdG>{res?.kpi_weight_unit?.name ?? 'N/A'}</PmdG>
                        </FlexBetween>
                      </CardAssigned>
                      <MonthAchievement
                        id={id}
                        kpi={
                          res?.performance_goal?.kpi_list?.kpi_scoring_nature
                            ?.name
                        }
                        target_unit={res?.kpi_weight_unit?.name ?? 'N/A'}
                      />
                    </FlexBetween>
                    <CardAssigned
                      style={{marginTop: '0.8rem', padding: '0.8rem 1.2rem'}}
                    >
                      <FlexBetween>
                        <P14Bold
                          style={{fontWeight: '0', margin: 0, color: '#333333'}}
                        >
                          Batas Waktu
                        </P14Bold>
                        <P14Bold style={{margin: 0}}>
                          {moment(res.enddate).format('MMMM DD, YYYY')}
                        </P14Bold>
                      </FlexBetween>
                      <FlexBetween style={{marginTop: 10}}>
                        <P14Bold
                          style={{fontWeight: '0', margin: 0, color: '#333333'}}
                        >
                          Tanggal Penyelesaian
                        </P14Bold>
                        <P14Bold style={{margin: 0}}>
                          {moment(res?.complete_at[0]?.date_added).format(
                            'MMMM DD, YYYY'
                          )}
                        </P14Bold>
                      </FlexBetween>
                    </CardAssigned>
                    <Paper
                      style={{
                        padding: '0.8rem 1.2rem',
                        marginTop: '0.8rem',
                      }}
                    >
                      <PmdG>{GLOBAL_GLOSSARY.performance.Goal}</PmdG>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              style={{
                                color: '#039be5',
                                marginRight: '0.5rem',
                                fontSize: 14,
                              }}
                            >
                              [PF-{res?.performance_goal?.id}]
                            </Typography>

                            <Typography
                              variant="h6"
                              style={{margin: 0, fontWeight: 600, fontSize: 14}}
                            >
                              {res?.performance_goal?.name || '-'}
                            </Typography>
                          </div>
                          <Typography
                            style={{
                              color: typeGoal ? '#a35a31' : '#874caf',
                              fontSize: 12,
                              margin: '4px 0px',
                            }}
                          >
                            {`${
                              typeGoal
                                ? GLOBAL_GLOSSARY.performance['Company Goal']
                                : GLOBAL_GLOSSARY.performance['Personal Goal']
                            } ${
                              res?.performance_goal?.kpi_list?.type ? '-' : ''
                            } ${goalType[
                              res?.performance_goal?.kpi_list?.type
                            ] ?? ''}`}
                          </Typography>
                          <Typography
                            style={{margin: 0, color: '#a9a8a8', fontSize: 12}}
                          >
                            {moment(res?.performance_goal?.startdate).format(
                              'MMM DD, YYYY'
                            )}{' '}
                            -{' '}
                            {moment(res?.performance_goal?.enddate).format(
                              'MMM DD, YYYY'
                            )}
                          </Typography>
                        </div>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          style={(() => {
                            switch (res?.performance_goal?.priority) {
                              case 'HIGH':
                                return {
                                  backgroundColor: '#ef4d5e',
                                }
                              case 'MEDIUM':
                                return {
                                  backgroundColor: '#ffa000',
                                }
                              case 'LOW':
                                return {
                                  backgroundColor: '#039be5',
                                }
                              default:
                                return '#FFFFFF'
                            }
                          })()}
                        >
                          {res?.performance_goal?.priority &&
                            priority[res?.performance_goal?.priority]}
                        </Button>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <StyledLinear
                          variant="determinate"
                          color="secondary"
                          value={
                            res?.performance_goal?.progress_percentage <= 100
                              ? res?.performance_goal?.progress_percentage
                              : 100
                          }
                        />
                        <Typography
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                            width: '25%',
                            textAlign: 'right',
                          }}
                        >
                          {`${res?.performance_goal?.performance_tasks_aggregate
                            ?.aggregate?.sum.progress ?? '0'} ${resWeight} (
                          ${
                            res?.performance_goal?.progress_percentage
                              ? res?.performance_goal?.progress_percentage?.toFixed(
                                  2
                                )
                              : 0
                          }%)`}
                        </Typography>
                      </div>
                    </Paper>
                  </DetailMainContainer>
                </div>
              </ModalContent>
            </Fragment>
          )
        })
      )}
      <ParticipantPopup
        open={openDetailParticipant}
        handleClose={() => setOpenDetailParticipant(!openDetailParticipant)}
        participant={dataParticipant}
      />
    </StyledDialog>
    //     )
    //   }}
    // </Query>
  )
})
