import {Divider} from '@material-ui/core'
import React from 'react'
import {SquadItemStyle} from '../SquadStyle'

export default function SquadItem({title, description, status, date, onClick}) {
  const class_status = status === 'Aktif' ? 'active' : 'inactive'
  return (
    <SquadItemStyle onClick={onClick}>
      <div className="info">
        <h3 className="title">{title}</h3>
        <Divider className="divider" />
        <p className="description">{description}</p>
      </div>
      <div className="status-info">
        <p className={'status ' + class_status}>{status}</p>
        {date && <p className="date">{date}</p>}
      </div>
    </SquadItemStyle>
  )
}
