import React from 'react'
import {capitalize, MathDetailBoxes} from '../../../../utils/helpers'
import {LineClamp} from '../../PerformanceStyles'
import {HoverBadgeContainer} from './CommitteeTaskStyles'

const HoverBadges = props => {
  const {dataBoxes, resultBox} = props
  const minValues = {
    box_width: dataBoxes.talent_versions[0].box_width,
    box_height: dataBoxes.talent_versions[0].box_height,
    indicator_perf_nps: false,
    indicator_perf_ogf: false,
    indicator_perf_goal: false,
    indicator_perf_multirater: false,
    indicator_perf_nps_fields: {
      weight: 0,
    },
    indicator_perf_ogf_fields: {
      weight: 0,
    },
    indicator_perf_multirater_fields: {
      weight: 0,
    },
    indicator_perf_goal_fields: {
      weight: 0,
    },
    talent_boxes: {data: dataBoxes.talent_versions[0].talent_boxes},
  }

  const flexy = dataBoxes?.talent_versions?.[0]?.flexy_components

  const switchFlexyComponents = data => {
    let indicatorState
    switch (data.parameter) {
      case 'task':
        indicatorState = 'indicator_perf_goal'
        minValues.indicator_perf_goal_fields.weight = data.weight
        break
      case 'ogf':
        indicatorState = 'indicator_perf_ogf'
        minValues.indicator_perf_ogf_fields.weight = data.weight
        break
      case 'multirater':
        indicatorState = 'indicator_perf_multirater'
        minValues.indicator_perf_multirater_fields.weight = data.weight
        break
      case 'nps':
        indicatorState = 'indicator_perf_nps'
        minValues.indicator_perf_nps_fields.weight = data.weight
        break
    }

    minValues[indicatorState] = true
  }

  const xArrs = []
  const yArrs = []

  if (flexy) {
    flexy.x.parameters.forEach(axis => switchFlexyComponents(axis))
    flexy.y.parameters.forEach(axis => switchFlexyComponents(axis))
  }

  const checkIndicator = MathDetailBoxes(minValues, flexy)
  const index =
    resultBox &&
    checkIndicator.findIndex(
      x => x.index_x === resultBox.index_x && x.index_y === resultBox.index_y
    )

  const resI = checkIndicator[index]
  const mappingDataBoxes = (data, axis, xArr, yArr) => {
    const newAxisValue = {
      name:
        data.parameter === 'ogf'
          ? 'Ongoing Feedback'
          : data.parameter === 'socmed_post'
          ? 'Social Media Post'
          : data.parameter === 'nps'
          ? 'NPS'
          : capitalize(data.parameter),
      value: resI?.[data.parameter]?.toFixed(2),
    }

    if (axis === 'x') {
      xArr.push(newAxisValue)
    } else {
      yArr.push(newAxisValue)
    }
  }

  if (flexy) {
    flexy.x.parameters.forEach(axis =>
      mappingDataBoxes(axis, 'x', xArrs, yArrs)
    )
    flexy.y.parameters.forEach(axis =>
      mappingDataBoxes(axis, 'y', xArrs, yArrs)
    )
  }

  const selectedDataBadge =
    dataBoxes?.talent_versions?.[0]?.talent_boxes?.[index]

  return (
    <HoverBadgeContainer>
      <div className="title-badge-section">
        <img
          src={selectedDataBadge?.badge}
          alt={selectedDataBadge?.name}
          className="badge-image"
        />
        <p className="name-badge">{selectedDataBadge?.name}</p>
      </div>
      <div className="description-section">
        <LineClamp className="description-badge" lineClamp={4}>
          {selectedDataBadge?.description}
        </LineClamp>
      </div>
      <div className="axis-section">
        <div className="x-axis-section">
          <p className="p-title-axis">
            {`${dataBoxes?.talent_versions?.[0]?.flexy_components?.x?.name} (X Axis)`}
          </p>
          {xArrs?.map((data, key) => {
            return (
              <>
                <div className="hover-between" key={key}>
                  <div className="name-section">
                    <p className="p-value-axis">{data.name}</p>
                  </div>
                  <div className="value-section">
                    <p className="p-value-axis">{data.value}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
        <div className="y-axis-section">
          <p className="p-title-axis">
            {`${dataBoxes?.talent_versions?.[0]?.flexy_components?.y?.name} (Y Axis)`}
          </p>
          {yArrs?.map((data, key) => {
            return (
              <>
                <div className="hover-between" key={key}>
                  <div className="name-section">
                    <p className="p-value-axis">{data.name}</p>
                  </div>
                  <div className="value-section">
                    <p className="p-value-axis">{data.value}</p>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </div>
    </HoverBadgeContainer>
  )
}

export default HoverBadges
