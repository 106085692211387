import React, {useEffect, useState} from 'react'

import {
  TitlePerforment,
  StyledTabs,
  StyledTab,
  TitleContainer,
} from './PerformanceStyles'

import {
  ButtonOutLine,
  ButtonContained,
} from './multirater-components/SurveyStyles'

import useGetProfile from '../../hooks/useGetProfile'
import TabsMultirater from './multirater-components/TabsMultirater'
import TabsFeedBack from './multirater-components/TabsFeedBack'

import ModalMutationFeedBack from './detail-modal/ModalMutationFeedBack'
import {withRouter} from 'react-router-dom'
import {GLOBAL_GLOSSARY} from '../../utils/constant'

function Survey(props) {
  const customStyle = {
    tabsPerformance: {
      background: '#f6f8f9',
      height: '50px',
      margin: '35px -24px 0px -24px',
    },
    paperPerformance: {
      padding: 24,
      marginLeft: 12,
    },
  }

  const [openModal, setOpenModal] = useState(false)
  const [type, setType] = useState('')
  const [value, setValue] = useState(1)

  const {job_profile} = useGetProfile()

  useEffect(() => {
    if (props.location.state) {
      setValue(props.location.state.activeTab)
    }
  }, [])
  const handleModalOpen = title => {
    setType(title)
    setOpenModal(true)
  }
  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
  }

  return (
    <>
      <TitleContainer style={{minHeight: 45}}>
        <TitlePerforment variant="h6">Umpan Balik </TitlePerforment>
        {value === 1 && (
          <div>
            <ButtonOutLine
              variant="outlined"
              color="primary"
              onClick={() => handleModalOpen('Meminta Umpan Balik')}
            >
              Meminta Umpan Balik
            </ButtonOutLine>
            &nbsp;
            <ButtonContained
              variant="contained"
              color="primary"
              onClick={() => handleModalOpen('Memberi Umpan Balik')}
            >
              Memberi Umpan Balik
            </ButtonContained>
          </div>
        )}
      </TitleContainer>
      <StyledTabs
        value={value}
        onChange={handleChange}
        style={customStyle.tabsPerformance}
      >
        <StyledTab label="Multirater & NPS" />
        <StyledTab label={GLOBAL_GLOSSARY.performance.OGF} />
      </StyledTabs>

      {value === 0 && <TabsMultirater job_profile={job_profile} />}
      {value === 1 && <TabsFeedBack />}
      <ModalMutationFeedBack
        open={openModal}
        handleClose={handleModalClose}
        type={type}
      />
    </>
  )
}

export default withRouter(Survey)
