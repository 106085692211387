import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {GET_DETAIL_MULTIRATER} from '../../../graphql/queries/index'

import {
  Icon,
  IconButton,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
  DetailMultiraterContainer,
  CardAssigned,
  CardAssignedContainer,
  DetailMainContainer,
  CardRadarContainer,
  AvatarAssigned,
  CardStrength,
  CardArea,
  Pm9,
} from './ModalPerformanceStyles'
import {Radar} from 'react-chartjs-2'
import {FlexBetween, SummaryScore} from '../PerformanceStyles'
import {
  TextXl,
  Pmd,
  P18Bold,
  TextBig,
} from '../../../components/typography/TypographyStyles'
import {USER_ID} from '../../../utils/globals'
import {getDate} from '../../../utils/helpers'
import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'

function ModalDetailMultirater(props) {
  const {open, onClose, id} = props

  const {data, error} = useQuery(GET_DETAIL_MULTIRATER, {
    variables: {
      id: USER_ID,
      survey: id,
    },
  })

  if (data === undefined && error === undefined) {
    return ''
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const user = data && data.detail_user[0]
  const survey = data && data.multirater_surveys[0]
  const company = user && user.people_work_placements[0]
  const avg =
    survey && survey.multirater_responses_aggregate.aggregate.avg.answer
  const result = Math.round((avg / 5) * 100)

  const label = []
  const dataSurvey = []
  const spv = []
  const peer = []
  const subordinate = []
  const self = []

  survey &&
    survey.multirater_survey_groups.map(res => {
      label.push(res.name)
      dataSurvey.push({
        name: res.name,
        value: res.avg_all_score.aggregate.avg.answer,
      })
      spv.push(res.avg_spv_score.aggregate.avg.answer)
      peer.push(res.avg_peer_score.aggregate.avg.answer)
      subordinate.push(res.avg_subordinate_score.aggregate.avg.answer)
      self.push(res.avg_self.aggregate.avg.answer)
    })

  const surveyGroup = {
    labels: label,
    datasets: [
      {
        label: 'Atasan Langsung',
        //   backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: '#014a62',
        pointBackgroundColor: '#014a62',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#014a62',
        pointHoverBorderColor: '#fff',
        data: spv,
        pointHitRadius: 7,
        pointRadius: 7,
      },
      {
        label: 'Sesama Pegawai',
        //   backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: '#039be5',
        pointBackgroundColor: '#039be5',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#039be5',
        pointHoverBorderColor: '#fff',
        data: peer,
        pointHitRadius: 7,
        pointRadius: 7,
      },
      {
        label: 'Bawahan',
        //   backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: '#ffa000',
        pointBackgroundColor: '#ffa000',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#ffa000',
        pointHoverBorderColor: '#fff',
        data: subordinate,
        pointHitRadius: 7,
        pointRadius: 7,
      },
      {
        label: 'Diri Sendiri',
        //   backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: '#ef4d5e',
        pointBackgroundColor: '#ef4d5e',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#ef4d5e',
        pointHoverBorderColor: '#fff',
        data: self,
        pointHitRadius: 7,
        pointRadius: 7,
      },
    ],
  }

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="lg"
    >
      <ModalHeader>
        <FlexBetween>
          <TextXl>{survey && survey.title}</TextXl>
          <IconButton aria-label="close" onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
        {/* <Pmd>Created by Habib Akbar Aziiz at Sep 4 2019</Pmd> */}
        <Pmd>
          {survey && `Diterbitkan pada ${getDate(survey.date_created)}`}{' '}
        </Pmd>
        {/* <Button
          variant="contained"
          color="primary"
          style={{backgroundColor: '#3b86ff', marginTop: '1rem'}}
        >
          25%
        </Button> */}
      </ModalHeader>
      <ModalContent>
        <Pmd>Rincian Hasil</Pmd>
        <DetailMultiraterContainer>
          <CardAssignedContainer>
            <CardAssigned>
              <Pmd>Tugaskan Kepada</Pmd>
              <AvatarAssigned
                src={user && user.avatar}
                alt={user && user.name}
              ></AvatarAssigned>
              <P18Bold>{user && user.name}</P18Bold>
              <Pmd style={{color: '#a9a8a8'}}>{`${company &&
                company.company_employee_position.name} -`}</Pmd>
              <Pmd style={{color: '#a9a8a8'}}>
                {company && company.company_address.office_name}
              </Pmd>
              <h2 style={{color: '#707070', marginTop: '72%'}}>
                Indeks Keseluruhan
              </h2>
              <SummaryScore>
                <TextBig style={{color: SwitchValue(result, 'colorResult')}}>
                  {`${result}%`}
                </TextBig>
                <h2 style={{color: '#4caf50'}}> </h2>
              </SummaryScore>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: '#4caf50',
                  marginBottom: '1rem',
                  textTransform: 'none',
                }}
                size="small"
              >
                Selesai
              </Button>
            </CardAssigned>
          </CardAssignedContainer>
          <DetailMainContainer>
            <CardRadarContainer>
              <Radar
                data={surveyGroup}
                options={{
                  scale: {
                    // Hides the scale
                    ticks: {
                      beginAtZero: true,
                      max: 5,
                      min: 0,
                      stepSize: 1,
                    },
                  },
                }}
              />
            </CardRadarContainer>
            <FlexBetween>
              <CardStrength>
                <Pmd style={{paddingBottom: '1rem', textAlign: 'center'}}>
                  Titik Kekuatan
                </Pmd>
                <FormGroup>
                  {dataSurvey.map((res, key) => {
                    if (res.value >= 3) {
                      // console.log(res.value)
                      return (
                        <FlexBetween key={key}>
                          <FormControlLabel
                            control={
                              // <Checkbox color="primary" checked={true} />
                              <Checkbox color="primary" />
                            }
                            label={res.name}
                          />
                          <Pmd style={{color: '#4caf50'}}>
                            {res.value === null && res.value === undefined
                              ? 0
                              : res.value.toFixed(2)}
                          </Pmd>
                        </FlexBetween>
                      )
                    }
                  })}
                </FormGroup>
              </CardStrength>
              <CardArea>
                <Pmd style={{paddingBottom: '1rem', textAlign: 'center'}}>
                  Bidang Perbaikan
                </Pmd>
                {dataSurvey.map((res, key) => {
                  if (res.value < 3) {
                    return (
                      <FlexBetween key={key}>
                        <Pm9>{res.name}</Pm9>
                        <Pmd style={{color: '#ef4d5e'}}>
                          {(() => {
                            if (res.value === null && res.value === undefined) {
                              return 0
                            } else {
                              return res.value.toFixed(2)
                            }
                          })()}
                        </Pmd>
                      </FlexBetween>
                    )
                  }
                })}
              </CardArea>
            </FlexBetween>
          </DetailMainContainer>
        </DetailMultiraterContainer>
      </ModalContent>
    </StyledDialog>
  )
}

export default ModalDetailMultirater
