import React from 'react'
import Alert from '@material-ui/lab/Alert'
import moment from 'moment'

const ExportBaloon = ({
  data,
  closeBaloonMutation,
  retry,
  isReport,
  isLastGenerateStatus = false,
  showCloseButton = false,
}) => {
  const lastGenetate = ` (Terakhir dibuat pada ${moment(
    data &&
      data.company_export_data_logs &&
      data.company_export_data_logs.length > 0 &&
      data.company_export_data_logs[0].date_added
  ).format('ll')}) `

  return (
    <>
      {data &&
      data.company_export_data_logs &&
      data.company_export_data_logs.length > 0 &&
      data &&
      data.company_export_data_logs &&
      data.company_export_data_logs[0].status !== 'WAITING' &&
      !data.company_export_data_logs[0].viewed ? (
        <Alert
          color={
            data.company_export_data_logs[0].status === 'FAILED'
              ? 'error'
              : 'success'
          }
          severity={
            data.company_export_data_logs[0].status === 'FAILED'
              ? 'error'
              : 'success'
          }
          style={{marginBottom: 8}}
          onClose={
            !showCloseButton &&
            data.company_export_data_logs[0].status !== 'WAITING' &&
            closeBaloonMutation
          }
        >
          {(function() {
            if (data.company_export_data_logs[0].status === 'WAITING') {
              return (
                isReport ? 'Download data' : 'Pembuatan data',
                ' sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai.'
              )
            } else if (data.company_export_data_logs[0].status === 'FAILED') {
              return (
                <>
                  <span style={{color: '#ef4d5e'}}>
                    Generate data has failed!{' '}
                  </span>
                  <a
                    onClick={retry}
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#ef4d5e',
                      fontWeight: 'bold',
                    }}
                  >
                    Retry
                  </a>
                </>
              )
            } else {
              return (
                <>
                  <span style={{color: '#4caf50'}}>
                    {`${
                      isReport ? 'Download' : 'Pembuatan'
                    }  data sudah selesai! ${
                      !isLastGenerateStatus ? lastGenetate : ''
                    } `}
                  </span>
                  <a
                    href={
                      data &&
                      data.company_export_data_logs &&
                      data.company_export_data_logs[0].data_url
                    }
                    rel="noreferrer"
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: '#4caf50',
                      fontWeight: 'bold',
                    }}
                  >
                    Unduh
                  </a>
                  {isLastGenerateStatus && (
                    <span style={{color: '#4caf50'}}>{lastGenetate}</span>
                  )}
                </>
              )
            }
          })()}
        </Alert>
      ) : data &&
        data.company_export_data_logs &&
        data.company_export_data_logs.length > 0 &&
        data.company_export_data_logs[0].status === 'WAITING' ? (
        <Alert color={'info'} severity={'info'} style={{marginBottom: 8}}>
          {isReport ? 'Download data' : 'Pembuatan data'} sedang diproses! Kami
          akan mengirimkan email kepada Anda jika sudah selesai.
        </Alert>
      ) : (
        true
      )}
    </>
  )
}

export default ExportBaloon
