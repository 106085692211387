import {GLOBAL_GLOSSARY} from '../../../utils/constant'

export const QUICK_FILTER_PERSONAL = 'personal'
export const QUICK_FILTER_DEPARTMENT = 'department'

export const COMPANY_GOAL_TYPE = 'COMPANY_GOAL'
export const PERSONAL_GOAL_TYPE = 'PERSONAL_GOAL'

export const GROUP_BY_GOAL = 'goal'

export const TASK_STATUS = {
  TODO: 'Harus dikerjakan',
  INPROGRESS: 'Sedang dikerjakan',
  ONPROGRESS: 'Sedang dikerjakan',
  ONGOING: 'Sedang berlangsung',
  DONE: 'Selesai dikerjakan',
  COMPLETED: 'Selesai',
  ARCHIVED: 'Diarsipkan',
}

export const STATUS_OPTIONS = [
  {
    value: 'TODO',
    label: 'To Do',
  },
  {
    value: 'INPROGRESS',
    label: 'In Progress',
  },
  {
    value: 'DONE',
    label: 'Done',
  },
  {
    value: 'COMPLETED',
    label: 'Complete',
  },
]

export const PRIORITY_STYLE = {
  HIGH: '#ef4d5e',
  MEDIUM: '#ffa000',
  LOW: '#039be5',
}

export const PRIORITY = {
  HIGH: 'Tinggi',
  MEDIUM: 'Sedang',
  LOW: 'Rendah',
}

export const TYPE_STYLE = {
  [COMPANY_GOAL_TYPE]: '#a35a31',
  [PERSONAL_GOAL_TYPE]: '#874caf',
}

export const TYPE = {
  [COMPANY_GOAL_TYPE]: GLOBAL_GLOSSARY.performance['Company Goal'],
  [PERSONAL_GOAL_TYPE]: GLOBAL_GLOSSARY.performance['Personal Goal'],
}

export const DONE_STATUS_ASSIGNMENT = ['DONE', 'COMPLETED']

export const COLUMNS_FAKE = {
  'column-1': {
    id: 'column-1',
    title: 'To Do',
    taskIds: [1, 2],
  },
  'column-2': {
    id: 'column-2',
    title: 'To Do',
    taskIds: [1, 2],
  },
  'column-3': {
    id: 'column-3',
    title: 'To Do',
    taskIds: [1, 2],
  },
}
