import gql from 'graphql-tag'

export const GET_LIST_MULTIRATER = gql`
  query listSurvey(
    $user: uuid!
    $search: String
    $from: date
    $to: date
    $orderBy: [multirater_surveys_order_by!]
    $offset: Int
    $limit: Int
  ) {
    multirater_surveys(
      where: {
        multirater_responses: {respond_to: {_eq: $user}}
        title: {_ilike: $search}
        startdate: {_gte: $from, _lte: $to}
        enddate: {_lte: $to}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      title
      type
      startdate
      enddate
      date_created
      status
      count_responded: multirater_responses_aggregate(
        where: {respond_from: {_eq: null}}
        distinct_on: respond_to
      ) {
        aggregate {
          count
        }
      }
      count_respondents: multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_responses_aggregate(
        where: {
          respond_to: {_eq: null}
          multirater_survey_group: {isnps: {_eq: false}}
        }
      ) {
        aggregate {
          avg {
            answer
          }
        }
      }
    }
  }
`
