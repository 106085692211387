import gql from 'graphql-tag'

export const GET_OVERALL_SUMMARY = gql`
  query getSummary($from: String, $to: String) {
    getPerformanceIndividualSummary(from: $from, to: $to) {
      kpi_score
      kpi_total
      multirater_score
      multirater_total
      nps_score
      nps_total
      ogf_score
      ogf_total
    }
  }
`
