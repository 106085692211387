import gql from 'graphql-tag'

export const GET_REPORT_OGF = gql`
  query($user: uuid, $from: timestamptz, $to: timestamptz) {
    user: people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
    disappointed: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $user}
        summary: {_eq: "DISAPPOINTED"}
        date_added: {_gte: $from, _lte: $to}
      }
    ) {
      aggregate {
        count
      }
    }
    ordinary: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $user}
        summary: {_eq: "ORDINARY"}
        date_added: {_gte: $from, _lte: $to}
      }
    ) {
      aggregate {
        count
      }
    }
    satisfied: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $user}
        summary: {_eq: "SATISFIED"}
        date_added: {_gte: $from, _lte: $to}
      }
    ) {
      aggregate {
        count
      }
    }
    manager: ongoing_feedback_lists(
      where: {
        ongoing_to: {_eq: $user}
        date_added: {_gte: $from, _lte: $to}
        type_from: {_eq: "INSIGHT"}
      }
    ) {
      id
      global_user_from {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      topic
      description
      summary
      date_added
    }
    feedback: ongoing_feedback_lists(
      where: {
        ongoing_to: {_eq: $user}
        date_added: {_gte: $from, _lte: $to}
        type_from: {_eq: "FEEDBACK"}
      }
    ) {
      id
      global_user_from {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      topic
      description
      summary
      date_added
    }
    subordinate: ongoing_feedback_lists(
      where: {
        ongoing_to: {_eq: $user}
        date_added: {_gte: $from, _lte: $to}
        type_from: {_eq: "SUBORDINATE"}
      }
    ) {
      id
      global_user_from {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
          id
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      topic
      description
      summary
      date_added
    }
  }
`
