import {parameters_versions} from '../../../../utils/constant'

export const DEFAULT_VALUE = {
  subordinate_squad_label: 'Pelaporan Langsung Tim Kerja',
  subordinate_squad_description:
    'Anggota tim kerja disini adalah pegawai di bawah Anda dan Anda dapat melihat Rencana hasil Kerja yang ditetapkan ke pegawai di bawah Anda',
}

export const PARAMETERS = {
  task: parameters_versions.task.EN,
  ogf: parameters_versions.ogf.EN,
  nps: parameters_versions.nps.EN,
  multirater: parameters_versions.multirater.EN,
}
