import styled from 'styled-components'

export const LinearProgressbar = styled.div`
  background-color: #e9ecee !important;
  -webkit-print-color-adjust: exact;
  height: 16px;
  position: relative;
  &:before {
    content: ' ';
    height: 16px;
    width: ${props => props.value};
    left: 0;
    top: 0;
    position: absolute;
    background-color: #014a62 !important;
    -webkit-print-color-adjust: exact;
    color: #014a62;
  }
`
export const TableStyled = styled.div`
  .row {
    padding: 14.8px 49px;
    display: flex;
    flex-direction: row;
    font-weight: inherit;

    p {
      margin: 0px;
      font-size: 8px;
      font-weight: inherit;
      margin-right: 22px;
      display: flex;
      align-items: center;
    }
  }

  .header {
    border-top: solid 1px #e5e5e5;
    border-bottom: solid 1px #e5e5e5;
    font-weight: 600;
  }

  .body {
    border-bottom: solid 1px #e5e5e5;
    background-color: #fff;
  }
  .body:nth-child(even) {
    background-color: #f7f8f9 !important;
    -webkit-print-color-adjust: exact;
  }

  .title {
    margin-right: auto !important;
    min-width: 100px;
    max-width: 180px;
  }
  .date {
    width: 70px;
  }
  .score {
    justify-content: flex-end;
    width: 44px;
    text-align: right;
  }
`
