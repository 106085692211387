import {
  Avatar,
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Icon,
  Paper,
  Typography,
} from '@material-ui/core'
import React from 'react'
import {FlexBetween, FlexCenter, GreyTypography} from '../PerformanceStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

export default function DetailMultiraterSidebar({
  classes,
  sidebarData,
  data,
  selected,
  handleExpand,
  handleSelect,
  isComplete,
  onSubmitSurvey,
}) {
  return (
    <Paper elevation={3} className={classes.sidebar}>
      <Typography variant="h6" color="primary" className={classes.sidebarTitle}>
        Pegawai
      </Typography>
      <Divider />
      {sidebarData.map((res, i) => {
        // console.log(res.data)
        let completes = 0
        for (var x = 0; x < res.data.length; x++) {
          if (
            res.data[x].global_user.multirater_responses_to_aggregate.aggregate
              .count >= data.total_question.aggregate.count
          ) {
            completes += 1
          }
        }
        return (
          <ExpansionPanel
            elevation={0}
            key={i}
            expanded={selected.type === res.id}
            onChange={handleExpand(res.id)}
          >
            <ExpansionPanelSummary
              classes={{expandIcon: classes.expandIcon}}
              expandIcon={<ExpandMoreIcon />}
            >
              <div>
                <Typography variant="body1" className={classes.bold}>
                  {res.title}
                </Typography>
                <Typography variant="caption" component="p" color="secondary">
                  {!completes ? 0 : completes} dari {res.length} Selesai
                </Typography>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{width: '100%'}}>
                {res.data.map((resData, i) => {
                  const isSelected =
                    selected.type === res.id &&
                    selected.id === resData.global_user.id

                  return (
                    <FlexCenter
                      style={{
                        padding: 12,
                        paddingLeft: 16,
                        margin: '0 -16px',
                        cursor: 'pointer',
                        background: isSelected ? '#f7f8f9' : '#fff',
                      }}
                      key={i}
                      onClick={() =>
                        handleSelect(res.id, resData.global_user.id)
                      }
                    >
                      <Avatar src={resData.global_user.avatar} alt="ava" />
                      <FlexBetween>
                        <div className={classes.jobprogile}>
                          <Typography
                            variant="body2"
                            noWrap
                            style={{maxWidth: '12vw'}}
                          >
                            {resData.global_user.name}
                          </Typography>
                          <GreyTypography
                            variant="caption"
                            component="p"
                            noWrap
                            style={{maxWidth: '12vw'}}
                          >
                            {resData.company_job_profile &&
                              resData.company_job_profile.title}
                          </GreyTypography>
                        </div>
                        {resData.global_user.multirater_responses_to_aggregate
                          .aggregate.count >=
                          data.total_question.aggregate.count && (
                          <Icon color="secondary">check</Icon>
                        )}
                      </FlexBetween>
                    </FlexCenter>
                  )
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
      <Divider />
      <div className={classes.wrapBtnSubmit}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={isComplete}
          onClick={onSubmitSurvey}
        >
          Submit
        </Button>
      </div>
    </Paper>
  )
}
