import gql from 'graphql-tag'

export const GET_LIST_GENERAL_SQUAD = gql`
  query listGroupGeneral(
    $company: uuid
    $user: uuid
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: performance_squad_groups_aggregate(
      where: {
        company: {_eq: $company}
        added_by: {_eq: $user}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_squad_groups(
      where: {
        company: {_eq: $company}
        added_by: {_eq: $user}
        name: {_ilike: $search}
      }
      offset: $offset
      limit: $limit
      order_by: [{id: desc}]
    ) {
      id
      name
      description
      start_date
      end_date
    }
  }
`

export const GET_LIST_SUGGESTION_GENERAL_SQUAD_MEMBER = gql`
  query(
    $company: uuid
    $user: uuid
    $group: Int
    $search: String
    $offset: Int
    $limit: Int
    $nin: [Int!]
  ) {
    people_work_placements(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        user: {_neq: $user}
        _or: [
          {
            _and: [
              {_not: {performance_teams: {}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
            ]
          }
          {
            _and: [
              {
                _not: {
                  performance_teams: {
                    added_by: {_eq: $user}
                    group: {_eq: $group}
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
            ]
          }
        ]
      }
      distinct_on: [user]
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_DETAIL_GENERAL_SQUAD = gql`
  query listGroupGeneral($company: uuid, $user: uuid, $id: Int, $limit: Int) {
    performance_squad_groups(
      where: {id: {_eq: $id}, company: {_eq: $company}, added_by: {_eq: $user}}
      limit: $limit
    ) {
      id
      name
      start_date
      description
      end_date
    }
  }
`

export const GET_LIST_MEMBER_GENERAL_SQUAD = gql`
  query listMySquadGeneral(
    $group: Int
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        performance_teams: {group: {_eq: $group}}
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        performance_teams: {group: {_eq: $group}}
        _or: [
          {global_user: {name: {_ilike: $search}}}
          {regno: {_ilike: $search}}
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        talent_assignments(
          where: {
            talentVersionByTalentVersion: {
              status: {_eq: "COMPLETED"}
              visibility: {_eq: "PUBLISH"}
              classification_type: {_eq: "PERFORMANCE"}
            }
            badge_classification: {_is_null: false}
          }
          order_by: [{id: desc}]
          limit: 1
        ) {
          id
          talent_box {
            id
            name
            badge
          }
          talent_score
          talentVersionByTalentVersion {
            id
            name
            start_date
            end_date
          }
          people_work_placement {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
  }
`
