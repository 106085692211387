import gql from 'graphql-tag'

export const GET_KPI_LIST = gql`
  query getListKpi($search: String) {
    kpi_perspectives(
      where: {
        kpi_list: {
          kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        }
        _or: [
          {name: {_ilike: $search}}
          {kpi_list: {name: {_ilike: $search}}}
          {
            kpi_list: {
              kpi_cascading_lists: {cascading_main: {name: {_ilike: $search}}}
            }
          }
        ]
      }
    ) {
      id
      name
      kpi_list(
        where: {
          kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
          deleted_at: {_is_null: true}
        }
      ) {
        id
        name
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists(
          where: {cascading_main: {status: {_eq: "ACTIVE"}}}
          order_by: [{id: desc}]
        ) {
          id
          cascading_main {
            id
            name
            date_start
            date_end
          }
        }
      }
    }
  }
`

export const GET_KPI_LIST_FILTER = gql`
  query(
    $company: uuid
    $user: uuid
    $perspective: Int
    $is_suggest: Boolean
    $search: String
  ) {
    kpi_lists(
      where: {
        company: {_eq: $company}
        kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        prespective: {_eq: $perspective}
        _or: [
          {
            _and: [
              {
                company_job_profiles: {
                  company_job_profile: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      user: {_eq: $user}
                    }
                  }
                }
              }
              {_not: {id: {_is_null: $is_suggest}}}
              {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
            ]
          }
          {
            _and: [
              {id: {_is_null: $is_suggest}}
              {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
            ]
          }
        ]
      }
      limit: 30
    ) {
      id
      code
      name
      type
      formula
      kpi_scoring_nature {
        id
        name
      }
      kpi_perspective {
        id
        name
      }
      kpi_aspect {
        id
        name
      }
      kpi_weight_unit {
        id
        name
      }
      kpi_cascading_lists(
        where: {cascading_main: {status: {_eq: "ACTIVE"}}}
        order_by: [{id: desc}]
        limit: 1
      ) {
        id
        cascading_main {
          id
          name
          date_start
          date_end
        }
      }
    }
  }
`

export const GET_LIST_PERSPECTIVE_FILTER = gql`
  query($company: uuid, $search: String) {
    kpi_perspectives(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        name: {_ilike: $search}
        kpi_list: {
          kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}
        }
      }
      limit: 10
    ) {
      id
      name
    }
  }
`
