import React from 'react'

import {LineClamp} from '../../../PerformanceStyles'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'

const TitleRow = ({isCompanyGoal, name}) => {
  return (
    <>
      <p
        style={{
          marginBottom: 4,
          color: isCompanyGoal ? '#a35a31' : '#874caf',
        }}
      >
        {isCompanyGoal
          ? GLOBAL_GLOSSARY.performance['Company Goal']
          : GLOBAL_GLOSSARY.performance['Personal Goal']}
      </p>
      <LineClamp>{name}</LineClamp>
    </>
  )
}

export default TitleRow
