import React, {useEffect, useState} from 'react'
import useStyles, {InfoStyled} from './CommitteeTaskStyles'
import {Paper, Typography, IconButton, Avatar, Divider} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
// import Aspiration from '../shared-component/Aspiration'
// import DevelopmentPlan from '../talent-profile/DevelopmentPlant'
import Moment from 'moment'
import EditBadge from './ChangeBoxes'
import {TALENT_LIST_HOME} from '../../../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
import {switchFlexyComponents, trimString} from '../../../../utils/helpers'
import IconSvg from '../../../../assets/images/icon_badges_not_found.svg'
import LoadingComponent from '../../../../components/loading/LoadingComponent'
import {
  BoldTypography,
  IconArrowBlue,
  IconArrowDisabled,
} from '../../../../components/typography/TypographyStyles'
import {GreyTypography} from '../../PerformanceStyles'
import Carousel from 'nuka-carousel'

const TalentDetail = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const idAssignment = props.location.state.idAssignment
  const condition = {
    user: props.match.params.id_emp,
    version: props.match.params.id_version,
  }
  const {data: dataList, loading, error} = useQuery(TALENT_LIST_HOME, {
    variables: condition,
  })

  const dataPwp = dataList?.people_work_placements[0]

  useEffect(() => {
    if (dataList) {
      const ids = document.getElementById('top')
      if (ids) {
        ids.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [dataList])

  if (loading || !dataPwp) {
    return <LoadingComponent />
  }
  if (error) {
    return JSON.stringify(error)
  }

  const dataAssignment = dataPwp.talent_assignments[0]
  const dataTalentByTalent = dataAssignment.talentVersionByTalentVersion

  const handleback = () => {
    props.history.goBack()
  }

  const Items = ({data}) => {
    return (
      <Paper className={classes.containerChildBadge} elevation={0}>
        <img
          src={data.talent_box?.badge ? data.talent_box.badge : IconSvg}
          alt="badge"
          style={{width: '85px'}}
        />
        <div className={classes.childBadgeContent}>
          <Typography gutterBottom variant="body1">
            {data?.talent_box?.name ?? '-'}
          </Typography>
          <GreyTypography gutterBottom variant="body2">
            {trimString(
              data?.people_work_placement?.company_job_profile?.title ?? '-',
              20
            )}
          </GreyTypography>
          <Typography gutterBottom variant="body2" className={classes.position}>
            {trimString(data?.talentVersionByTalentVersion?.name ?? '-', 20)}
          </Typography>
          <BoldTypography gutterBottom variant="h6">
            {data?.talent_score?.toFixed(2)}
          </BoldTypography>
        </div>
      </Paper>
    )
  }
  const ItemsNull = () => {
    return (
      <Paper className={classes.containerChildBadge} elevation={0}>
        <img src={IconSvg} alt="badge" style={{width: '85px'}} />
        <Typography variant="h6">No Badge</Typography>
      </Paper>
    )
  }
  const xArr = []
  const yArr = []

  if (dataTalentByTalent?.flexy_components) {
    dataTalentByTalent.flexy_components.x.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'x', dataAssignment, xArr, yArr)
    )
    dataTalentByTalent.flexy_components.y.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'y', dataAssignment, xArr, yArr)
    )
  }

  return (
    <>
      <div id="top" className={classes.headerRow}>
        <IconButton onClick={handleback} aria-label="delete">
          <ArrowBackIcon />
        </IconButton>
        <Typography className={classes.detailTitleName}>
          {`Rincian Kinerja ${dataPwp.global_user.name}`}
        </Typography>
      </div>
      <Divider style={{margin: '22px -24px 28px'}} />
      <Paper className={classes.paperDetail}>
        <div className={classes.bodyDetail}>
          <Avatar
            alt={dataPwp.global_user.name}
            className={classes.largeTalent}
            src={dataPwp.global_user.avatar}
          />
          <InfoStyled>
            <div className={classes.infoDetail}>
              <Typography variant="h6" style={{fontWeight: 600}}>
                {dataPwp.global_user.name}
              </Typography>
              <Typography variant="body2" className={classes.role}>
                {dataPwp.regno}
                {/* id number */}
              </Typography>
            </div>
            <Typography variant="body1">
              {dataPwp.current_position?.title}
            </Typography>
            <Typography variant="body2" className={classes.role}>
              Sejak {Moment(dataPwp.company_date).format('LL')}
            </Typography>
          </InfoStyled>
        </div>
      </Paper>

      <Typography className={classes.titleDetail}>Lencana Kinerja</Typography>
      <div className={classes.badge}>
        <Paper className={classes.paperBadge}>
          <div className={classes.titleBadge}>
            <BoldTypography variant="body1">Lencana Saat Ini</BoldTypography>
            <BoldTypography
              variant="body1"
              className={classes.edit}
              onClick={() => setOpen(true)}
            >
              Ubah
            </BoldTypography>
          </div>

          <center>
            <Items data={dataAssignment} type="current" />
          </center>
        </Paper>
        <Paper className={classes.paperPostBadge}>
          <div className={classes.titleBadge}>
            <BoldTypography variant="body1">Peringkat Kinerja</BoldTypography>
          </div>
          <div className={classes.indicator1}>
            <Typography
              className={classes.titleIndicator}
              variant="body1"
              color="primary"
            >
              {dataTalentByTalent?.flexy_components?.x.name}
            </Typography>
            <div className={classes.valueIndicator}>
              {xArr.length > 0 &&
                xArr.map((res, i) => (
                  <div className={classes.itemIndicator} key={i}>
                    <Typography className={classes.text}>
                      {res.param}
                      {/* ({res.index}) */}
                    </Typography>
                    <Typography className={classes.text}>
                      {res.value}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
          <div className={classes.indicator2}>
            <Typography
              className={classes.titleIndicator}
              variant="body1"
              color="primary"
            >
              {dataTalentByTalent?.flexy_components?.y.name}
            </Typography>
            <div className={classes.valueIndicator}>
              {yArr.length > 0 &&
                yArr.map((res, i) => (
                  <div className={classes.itemIndicator} key={i}>
                    <Typography className={classes.text}>
                      {res.param}
                      {/* ({res.index}) */}
                    </Typography>
                    <Typography className={classes.text}>
                      {res.value}
                    </Typography>
                  </div>
                ))}
            </div>
          </div>
        </Paper>
      </div>
      <Paper className={classes.paperRating}>
        <div className={classes.titleBadge}>
          <BoldTypography variant="body1">Lencana Lampau</BoldTypography>
        </div>

        {dataPwp.global_user.past_badge.length > 0 ? (
          <Carousel
            style={{outline: 'none'}}
            dragging={false}
            slidesToShow="4"
            renderCenterRightControls={({
              currentSlide,
              slideCount,
              nextSlide,
            }) => {
              return slideCount - currentSlide === 4 ? (
                <IconArrowDisabled
                  style={{
                    right: 0,
                    marginRight: '1rem',
                    display:
                      dataPwp.global_user.past_badge.length === 0
                        ? 'none'
                        : 'block',
                  }}
                >
                  chevron_right
                </IconArrowDisabled>
              ) : (
                <IconArrowBlue
                  onClick={nextSlide}
                  style={{
                    right: 0,
                    marginRight: '1rem',
                    display:
                      dataPwp.global_user.past_badge.length === 0
                        ? 'none'
                        : 'block',
                  }}
                >
                  chevron_right
                </IconArrowBlue>
              )
            }}
            renderTopRightControls={null}
            renderCenterLeftControls={({previousSlide, currentSlide}) => {
              return currentSlide === 0 ? (
                <IconArrowDisabled
                  style={{
                    marginLeft: '1rem',
                    display:
                      dataPwp.global_user.past_badge.length === 0
                        ? 'none'
                        : 'block',
                  }}
                >
                  chevron_left
                </IconArrowDisabled>
              ) : (
                <IconArrowBlue
                  style={{
                    marginLeft: '1rem',
                    display:
                      dataPwp.global_user.past_badge.length === 0
                        ? 'none'
                        : 'block',
                  }}
                  onClick={previousSlide}
                >
                  chevron_left
                </IconArrowBlue>
              )
            }}
            renderBottomCenterControls={null}
          >
            {dataPwp.global_user.past_badge.map((res, i) => {
              return <Items data={res} type="past" key={i} />
            })}
          </Carousel>
        ) : (
          <ItemsNull />
        )}
      </Paper>

      <EditBadge
        newHeight={props.location.state.newHeight}
        newWidth={props.location.state.newWidth}
        data={props.location.state.dataBoxes}
        dataCurrent={dataAssignment}
        open={open}
        onClose={() => setOpen(false)}
        back={() => props.history.goBack()}
        nameUser={dataPwp.global_user.name}
        idAssignment={idAssignment}
      />
    </>
  )
}

export default TalentDetail
