import React, {Fragment, useState} from 'react'

import {Divider, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import SummaryContainer from './SummaryContainer'
import IconItem from './IconItem'
import Doughnut from '../../../../../../components/chart/Doughnut'

import avg from '../../../../../../assets/images/icons/summary-icon/avg-score.svg'
import total from '../../../../../../assets/images/icons/summary-icon/total.svg'
import totalComplete from '../../../../../../assets/images/icons/summary-icon/total-complete.svg'

import {GET_TOTAL_GOAL} from '../../../../../../graphql/queries'
import {dateFormat} from '../../../../../../utils/helpers'
import {GLOBAL_GLOSSARY} from '../../../../../../utils/constant'

const useStyles = makeStyles({
  container: {padding: 22},
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '24px 12px',
    flexWrap: 'wrap',
    marginBottom: 40,
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dividerVertical: {minHeight: 200},
  titleChart: {fontSize: 16, margin: '0 0 24px'},
  chartRoot: {width: '45%'},
  chartWrap: {display: 'flex'},
  tableLegendRoot: {marginLeft: 42},
  tableLegend: {verticalAlign: 'top'},
  tableDot: {
    borderRadius: '50%',
    height: 18,
    width: 18,
    margin: '2px 18px 0px 0px',
    boxSizing: 'border-box',
    '-moz-box-sizing': 'border-box',
    '-webkit-box-sizing': 'border-box',
  },
  tableLabel: {
    fontSize: 14,
    marginBottom: 7,
  },
  tableValue: {
    paddingBottom: 23,
    fontSize: 20,
    color: '#a9a8a8',
  },
  avg: {fontSize: 14, margin: '24px 0 0'},
  avgScore: {color: '#014a62', fontWeight: '600', marginRight: 8},
})

export default function GoalSummary({user}) {
  const classes = useStyles()
  const [date, setDate] = useState({
    start: null,
    end: null,
  })

  const {data, error} = useQuery(GET_TOTAL_GOAL, {
    skip: !user,
    variables: {
      user,
      startdate: dateFormat({
        date: date?.start,
        format: 'YYYY-MM-DD',
        nullHandler: '',
      }),
      enddate: dateFormat({
        date: date?.end,
        format: 'YYYY-MM-DD',
        nullHandler: '',
      }),
    },
  })

  if (error) {
    console.error(error)
  }

  const {avg_score_all_goal, total_all_goal, total_complete_goal} =
    data?.getPerformanceIndividualSummaryGoal || {}

  const handleChangeDate = (date, type) => {
    setDate(prev => ({...prev, [type]: date}))
  }

  const Icons = [
    {
      value: avg_score_all_goal?.toFixed(2) || 0 + '%',
      description: `Rata-rata skor ${GLOBAL_GLOSSARY.performance.Goal}`,
      src: avg,
    },
    {
      value: total_all_goal || 0,
      description: `Total ${GLOBAL_GLOSSARY.performance.Goal}`,
      src: total,
    },
    {
      value: total_complete_goal || 0,
      description: `Total ${GLOBAL_GLOSSARY.performance.Goal} Selesai`,
      src: totalComplete,
    },
  ]

  return (
    <SummaryContainer
      date={date}
      onChangeDate={handleChangeDate}
      title="Sasaran Kinerja"
    >
      <div className={classes.container}>
        <div className={classes.iconContainer}>
          {Icons?.map((icon, i) => (
            <IconItem key={i} {...icon} />
          ))}
        </div>
        <div className={classes.chartContainer}>
          <Chart
            title={GLOBAL_GLOSSARY.performance.Goal}
            type="personal"
            data={data}
            legendTitle={GLOBAL_GLOSSARY.performance.Goal}
            avgLabel={`Rata-rata skor ${GLOBAL_GLOSSARY.performance.Goal}:`}
          />
          <Divider className={classes.dividerVertical} orientation="vertical" />
          <Chart
            title="Sasaran Kinerja Instansi"
            type="company"
            data={data}
            legendTitle="Sasaran Kinerja Instansi"
            avgLabel="Rata-rata skor Sasaran Kinerja Instansi:"
          />
        </div>
      </div>
    </SummaryContainer>
  )
}

const Chart = ({title, data, type, legendTitle, avgLabel}) => {
  const classes = useStyles()

  const _data = data?.getPerformanceIndividualSummaryGoal

  const generatedChartData = {
    labels: ['Selesai', 'Akan Dikerjakan'],
    datasets: [
      {
        data: [
          _data?.[`total_complete_${type}_goal`] || 0,
          _data?.[`total_todo_${type}_goal`] || 0,
        ],
        backgroundColor: color,
        hoverBackgroundColor: color,
        hoverOffset: 50,
        borderWidth: 0,
      },
    ],
  }

  const generatedLegendData = [
    {
      label: 'Selesai',
      value: _data?.[`total_complete_${type}_goal`] || 0,
      color: color[0],
    },
    {
      label: 'Akan Dikerjakan',
      value: _data?.[`total_todo_${type}_goal`] || 0,
      color: color[1],
    },
  ]

  return (
    <div className={classes.chartRoot}>
      <h3 className={classes.titleChart}>{title}</h3>
      <div className={classes.chartWrap}>
        <Doughnut
          data={generatedChartData}
          total={_data?.[`total_all_${type}_goal`] || 0}
          title={legendTitle}
        />
        <Legend data={generatedLegendData} />
      </div>
      <p className={classes.avg}>
        {avgLabel}{' '}
        <span className={classes.avgScore}>
          {_data?.[`avg_score_${type}_goal`]?.toFixed(2) || 0}%
        </span>
      </p>
    </div>
  )
}

const Legend = ({data}) => {
  const classes = useStyles()
  return (
    <table className={classes.tableLegendRoot}>
      <tbody>
        {data?.map((row, i) => (
          <Fragment key={i}>
            <tr>
              <td className={classes.tableLegend} rowSpan={2}>
                <div
                  style={{border: `3px solid ${row.color}`}}
                  className={classes.tableDot}
                />
              </td>
              <td className={classes.tableLabel}>{row.label}</td>
            </tr>
            <tr>
              <td className={classes.tableValue}>{row.value}</td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </table>
  )
}

const color = ['#1f78b4', '#a6cee3']
