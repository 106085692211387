import {useQuery} from '@apollo/react-hooks'
import {Button, makeStyles, Paper} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {withRouter, Route} from 'react-router-dom'
import {GET_LIST_GENERAL_SQUAD} from '../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {StyledTab, StyledTabs} from './PerformanceStyles'
import AddEditGeneralSquad from './squad-component/general-squad/AddEditGeneralSquad'
import ListGeneralSquad from './squad-component/general-squad/ListGeneralSquad'
import ListSubordinateSquad from './squad-component/subordinate-squad/ListSubordinateSquad'

const getActiveTab = location => {
  if (location.pathname === '/squad') return '/squad/direct-report-squad'
  else return location.pathname
}

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: 24,
    backgroundColor: '#fff',
  },
  wrapp: {marginBottom: 120},
  wrapperComponent: {
    width: '99%',
    margin: 'auto',
    minHeight: '45px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabStyle: {margin: '24px -24px 0px'},
  title: {fontSize: 16, fontWeight: '600', color: '#014a62', margin: 0},
  buttonAdd: {
    fontSize: 12,
    fontWeight: '600',
    color: '#fff',
    padding: '12px 40px',
  },
}))

const Squad = props => {
  const {location, history} = props
  const [openAdd, setOpenAdd] = useState(false)

  const [search, setSearch] = useState('')

  const {data, loading, error, fetchMore, refetch} = useQuery(
    GET_LIST_GENERAL_SQUAD,
    {
      skip: location.pathname === '/squad/direct-report-squad' || false,
      variables: {
        company: COMPANY_ID,
        user: USER_ID,
        search: `%${search}%`,
        limit: 20,
        offset: 0,
      },
    }
  )

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.performance_squad_groups.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          performance_squad_groups: [
            ...prev.performance_squad_groups,
            ...fetchMoreResult.performance_squad_groups,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const activeTab = getActiveTab(location)

  useEffect(() => {
    if (location.pathname === '/squad') {
      history.push({
        pathname: '/squad/direct-report-squad',
      })
    }
  }, [location, history])

  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    history.push({
      pathname: newValue,
    })
  }

  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <div className={classes.wrapp}>
        <div className={classes.wrapperComponent}>
          <h2 className={classes.title}>Tim Kerja</h2>
          {location.pathname === '/squad/collaboration-squad' && (
            <Button
              className={classes.buttonAdd}
              variant="contained"
              color="primary"
              onClick={() => setOpenAdd(true)}
              disableElevation
            >
              Menambahkan Tim Kerja
            </Button>
          )}
        </div>
        <StyledTabs
          value={activeTab}
          onChange={handleChangeTabs}
          indicatorColor="secondary"
          className={classes.tabStyle}
        >
          <StyledTab
            label="Tim Kerja dalam Unit Kerja"
            value="/squad/direct-report-squad"
          />
          <StyledTab
            label="Tim Kerja Lintas Unit Kerja"
            value="/squad/collaboration-squad"
          />
        </StyledTabs>

        <AddEditGeneralSquad
          refetch={refetch}
          open={openAdd}
          onClose={() => setOpenAdd(false)}
        />

        <Route
          component={ListSubordinateSquad}
          path="/squad/direct-report-squad"
          exact
        />
        <Route
          render={() => (
            <ListGeneralSquad
              setSearch={setSearch}
              fetchMoreData={fetchMoreData}
              search={search}
              data={data}
              loading={loading}
              error={error}
            />
          )}
          path="/squad/collaboration-squad"
          exact
        />
      </div>
    </Paper>
  )
}

export default withRouter(Squad)
