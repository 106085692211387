import styled from 'styled-components'
import {Icon, Typography} from '@material-ui/core'
import {Link} from 'react-router-dom'

export const Title = styled.h1`
  font-weight: bold;
  color: white;
`
export const SubTitle = styled.h2`
  font-weight: bolder;
`

export const P = styled.p`
  margin: 8px 0;
  font-size: 14px;
`

export const P0 = styled.p`
  margin: 0;
`

export const Pmd = styled.p`
  margin: 0;
  font-size: 12px;

  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const PmdG = styled.p`
  margin: 0;
  font-size: 12px;
  color: #a9a8a8;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const PmdP = styled.p`
  margin: 0;
  font-size: 12px;
  color: #039be5;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const Psm = styled.p`
  margin: 0;
  font-size: 12px;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`
export const Pdate = styled.p`
  margin: 0;
  font-size: 11px;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 14px;
  }
`

export const Pnm = styled.p`
  margin: 0;
  font-size: 22px !important;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 15px;
    font-size: 22px !important;
  }
`
export const Pnm2 = styled.h1`
  font-weight: 500;
  font-size: 2rem;
  margin: 0;
`
export const TextBig = styled.h1`
  font-weight: 500;
  font-size: 2.5rem;
  margin: 0;
`

export const TextPercent = styled.h1`
  font-weight: 500;
  font-size: 1.5rem;
  margin: 0;
`

export const TextXl = styled.h2`
  margin: 0;
`

export const P18Bold = styled.p`
  font-size: 18px;
  font-weight: 600;
`

export const P14Bold = styled.p`
  font-size: 14px;
  font-weight: 600;
`

export const P18 = styled.p`
  font-size: 14px;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 18px;
  }
`

export const P018 = styled.p`
  font-size: 14px;
  margin: 0;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 18px;
  }
`
export const IconArrowDisabled = styled(Icon)`
  border: 1px #a9a8a8 solid;
  color: #a9a8a8;
  border-radius: 5px;
`

export const IconArrowBlue = styled(Icon)`
  border: 1px #039be5 solid;
  background-color: #039be5;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`

export const IconBack = styled(Icon)`
  font-size: 24px;
  margin-right: 1rem;

  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 28px;
  }
`
export const PsmGrey = styled.p`
  margin: 0;
  font-size: 12px;
  color: #a9a8a8;
  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 15px;
  }
`
export const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
`

export const PmdGrey = styled.p`
  margin: 0;
  font-size: 14px;
  color: #a9a8a8;

  @media only screen and (min-width: 1367px) {
    /* For desktop XL: */
    font-size: 18px;
  }
`

export const GreyTypography = styled(Typography)`
  color: #a9a8a8;
`

export const BlueTypography = styled.div`
  color: #039be5;
  font-family: Muli;
  font-size: 18px;
`

export const BlackTypography = styled.div`
  color: Black;
  font-family: Muli;
  font-size: 18px;
`

export const BoldTypography = styled(Typography)`
  font-weight: 700;
`

export const MessageText = styled.p`
  display: inline-block;
  margin: 0;
  &::first-letter {
    text-transform: capitalize;
  }
`

export const TypographyStyleds = styled(Typography)`
  font-family: Muli;
  font-size: ${props => props.size} !important;
  font-weight: ${props => props.weight} !important;
  color: ${props => (props.color ? props.color : '#000000')};
  width: ${props => props.width}px !important;
  cursor: ${props => props.pointer && 'pointer'};
`
