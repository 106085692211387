import React from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core'
import {WhiteModalHeader} from '../performance-tabs/PerformanceStyles'
import {makeStyles} from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  contentTxt: {fontSize: 12},
  btnPopup: {
    backgroundColor: '#014a62',
    fontSize: 12,
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
    textTransform: 'capitalize',
  },
  btnCancel: {
    color: '#a9a8a8',
    backgroundColor: '#ffffff',
    boxShadow: 'initial',
  },
  word: {wordBreak: 'break-all'},
  nameStyle: {
    color: '#014a62',
    fontWeight: '600',
  },
  title: {color: '#014a62', fontSize: 14, fontWeight: '600'},
}))

export default function NotificationPopup({
  open,
  onClose,
  title,
  message,
  onConfirm,
  onCancel = undefined,
  mainButtonText = 'Konfirmasi',
  isDisabledMainButton = false,
  secondaryButtonText = 'Batal',
  maxWidth = 'xs',
}) {
  const classes = useStyles()

  const handleClose = () => {
    onClose && onClose()
  }
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth={maxWidth}>
      <WhiteModalHeader>
        <Typography className={classes.title}>{title}</Typography>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        <Typography className={classes.contentTxt} display="inline">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        {onCancel && <Button onClick={onCancel}>{secondaryButtonText}</Button>}
        <Button
          className={classes.btnPopup}
          variant="contained"
          onClick={onConfirm}
          disabled={isDisabledMainButton}
        >
          {mainButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
