import {Avatar, Checkbox, Divider, Icon, makeStyles} from '@material-ui/core'
import React, {useRef, useEffect} from 'react'
import PopupWrapper from '../../../shared-component/PopupWrapper'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {customSelect} from '../customSelect'
import {useApolloClient, useLazyQuery} from '@apollo/react-hooks'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import {Skeleton} from '@material-ui/lab'
import EmptyState from '../../../../components/empty-state/EmptyStateComponent'

const useStyles = makeStyles(() => ({
  searchBar: {marginTop: 16, marginBottom: 24},
  option: {display: 'flex', alignItems: 'center', margin: '12px 0px'},
  optionWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarOption: {width: 56, height: 56},
  infoWrappper: {marginLeft: 19},
  nameOption: {
    fontFamily: 'muli',
    fontSize: 14,
    margin: 0,
    fontWeight: '600',
  },
  regnoOption: {
    fontFamily: 'muli',
    fontSize: 12,
    color: '#a9a8a8',
    margin: 0,
    fontWeight: '500',
  },
  titleOption: {
    fontFamily: 'muli',
    fontSize: 12,
    margin: 0,
    fontWeight: '500',
  },
  divider: {margin: 21},
  bodyTop: {
    padding: '24px 22px',
    position: 'sticky',
    top: 0,
    zIndex: 9,
    background: '#fff',
  },
  body: {padding: '0px 22px 24px'},
}))

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon>search</Icon>
    </components.DropdownIndicator>
  )
}

const ItemMember = ({
  onClick,
  avatar,
  name,
  title,
  checked = false,
  onChange,
  isLoading = false,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.optionWrap}>
      <div onClick={onClick} className={classes.option}>
        {isLoading ? (
          <Skeleton height={56} width={56} variant="circle" animation="wave">
            <Avatar className={classes.avatarOption} />
          </Skeleton>
        ) : (
          <Avatar src={avatar} className={classes.avatarOption} />
        )}
        <div className={classes.infoWrappper}>
          {isLoading ? (
            <>
              <Skeleton width={250} animation="wave" />
              <Skeleton width={319} animation="wave" />
            </>
          ) : (
            <>
              <h4 className={classes.nameOption}>{name}</h4>
              <p className={classes.titleOption}>{title}</p>
            </>
          )}
        </div>
      </div>
      <div>
        {isLoading ? (
          <Skeleton height={32} width={32} variant="circle" animation="wave" />
        ) : (
          <Checkbox color="primary" checked={checked} onChange={onChange} />
        )}
      </div>
    </div>
  )
}

const DEFAULT_VARIABLES = {
  company: COMPANY_ID,
  user: USER_ID,
  limit: 20,
  offset: 0,
}

export default function AddSquadMember({
  open,
  onClose,
  onSave,
  selectedAddMember,
  setSelectedAddMember,
  query,
  variablesQuery = {},
}) {
  const client = useApolloClient()
  const classes = useStyles()
  const selectInputRef = useRef()

  const [getListSugestion, {data, loading, error}] = useLazyQuery(query, {
    variables: {
      ...DEFAULT_VARIABLES,
      ...variablesQuery,
    },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    selectInputRef &&
      selectInputRef.current &&
      selectInputRef.current.select &&
      selectInputRef.current.select.select &&
      selectInputRef.current.select.select.clearValue()
  }, [selectedAddMember, selectInputRef])

  useEffect(() => {
    if (open) {
      getListSugestion({
        variables: {
          nin: selectedAddMember.map(res => res.id),
        },
      })
    }
  }, [open, selectedAddMember])

  const promiseOptions = async search => {
    const {data} = await client.query({
      query: query,
      fetchPolicy: 'no-cache',
      variables: {
        ...DEFAULT_VARIABLES,
        ...variablesQuery,
        search: `%${search}%`,
        nin: selectedAddMember.map(res => res.id),
      },
    })

    return data.people_work_placements
  }

  const handleClickMember = option => {
    setSelectedAddMember(e => [...e, {...option, checked: true}])
  }

  const handleClose = () => {
    onClose()
  }

  const handleRemoveSelected = clicked => {
    setSelectedAddMember(prev => {
      return [...prev].filter(({id}) => id !== clicked.id)
    })
  }

  if (error) JSON.stringify(error)

  return (
    <PopupWrapper
      title="Menambahkan Anggota Tim Kerja"
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={onSave}
      isDisabledMainButton={selectedAddMember?.length === 0}
      contentPadding={0}
    >
      <div className={classes.bodyTop}>
        {/* SEARCH BAR */}
        <AsyncSelect
          placeholder="Cari"
          ref={selectInputRef}
          loadOptions={promiseOptions}
          styles={customSelect}
          components={{DropdownIndicator}}
          getOptionLabel={option => {
            return (
              <div
                onClick={() => handleClickMember(option)}
                className={classes.option}
              >
                <Avatar
                  src={option.global_user && option.global_user.avatar}
                  className={classes.avatarOption}
                />
                <div className={classes.infoWrappper}>
                  <h4 className={classes.nameOption}>
                    {option.global_user && option.global_user.name}
                  </h4>
                  <p className={classes.regnoOption}>{option.regno}</p>
                  <p className={classes.titleOption}>
                    {option.company_job_profile &&
                      option.company_job_profile.title}
                  </p>
                </div>
              </div>
            )
          }}
        />
      </div>
      <div className={classes.body}>
        {selectedAddMember.map((res, i) => (
          <ItemMember
            key={`${i}`}
            avatar={res.global_user && res.global_user.avatar}
            name={res.global_user && res.global_user.name}
            title={res.company_job_profile && res.company_job_profile.title}
            checked={res.checked}
            onChange={() => handleRemoveSelected(res)}
          />
        ))}
        {selectedAddMember[0] && <Divider className={classes.divider} />}
        {!loading &&
          data &&
          data.people_work_placements.map((res, i) => (
            <ItemMember
              key={`${i}`}
              avatar={res.global_user && res.global_user.avatar}
              name={res.global_user && res.global_user.name}
              title={res.company_job_profile && res.company_job_profile.title}
              onChange={() =>
                setSelectedAddMember(e => [...e, {...res, checked: true}])
              }
            />
          ))}

        {loading && (
          <>
            <ItemMember isLoading />
            <ItemMember isLoading />
            <ItemMember isLoading />
            <ItemMember isLoading />
            <ItemMember isLoading />
          </>
        )}
        {/* EMPTY STATE */}
        {!loading && data && data.people_work_placements.length === 0 && (
          <EmptyState
            message1={'Maaf, Tidak ada Daftar'}
            message2={'Saat ini, tidak ada anggota'}
            image={require('../../../../assets/images/nodatalist.png')}
          />
        )}
      </div>
    </PopupWrapper>
  )
}
