import React, {Fragment} from 'react'

import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'

import {
  Icon,
  IconButton,
  Grid,
  Avatar,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {
  ModalHeader,
  StyledDialog,
  ModalContent,
  DetailNSPContainer,
  CardAssigned,
  CardNSPContainer,
  TitleRespondents,
  ValueRespondents,
} from './ModalPerformanceStyles'
import {Doughnut} from 'react-chartjs-2'
import {FlexBetween, SummaryScore} from '../PerformanceStyles'
import {
  TextXl,
  Pmd,
  P18Bold,
  TextBig,
} from '../../../components/typography/TypographyStyles'
import {withRouter} from 'react-router-dom'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import {useQuery} from '@apollo/react-hooks'
import {GET_COUNT_RESPONDEN, GET_DETAIL_NPS} from '../../../graphql/queries'
import {USER_ID} from '../../../utils/globals'

const customStyle = {
  rightCard: {
    textAlign: 'left',
    marginLeft: '20px',
  },
  loadingWrapper: {
    width: '100%',
    margin: '120px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
}

function ModalDetailNPS(props) {
  const {open, onClose, result, id_data, dataUser} = props

  const {data: detailNps, loading: loadingDataNps} = useQuery(GET_DETAIL_NPS, {
    skip: !id_data,
    variables: {
      user: USER_ID,
      survey_id: id_data,
    },
  })

  const {data: countResponden, loading: loadingTotalResponden} = useQuery(
    GET_COUNT_RESPONDEN,
    {
      skip: !id_data,
      variables: {user: USER_ID, survey: id_data},
    }
  )

  const dataNPS = detailNps && detailNps.multirater_surveys[0]
  const question =
    dataNPS &&
    dataNPS.multirater_survey_groups[0].multirater_survey_questions[0]

  const dectators = question && question.dectators.aggregate.count
  const passives = question && question.passives.aggregate.count
  const promotors = question && question.promotors.aggregate.count

  const totalResponse =
    countResponden?.people_work_placements_aggregate.aggregate.count
  const finishedResponse = dectators + passives + promotors
  const waiting = totalResponse - finishedResponse

  const dectatorsPercent = Math.round(
    (dectators / (dectators + passives + promotors)) * 100
  )
  const passivesPercent = Math.round(
    (passives / (dectators + passives + promotors)) * 100
  )
  const promotorsPercent = Math.round(
    (promotors / (dectators + passives + promotors)) * 100
  )

  const work = dataUser?.people_work_placements[0]
  const position = work && work.company_employee_position.name
  const company = work && work.company_address.office_name
  const resultNPS = result

  const data = {
    labels: [
      'Promotor ' + promotorsPercent + '%',
      'Pasif ' + passivesPercent + '%',
      'Detractor ' + dectatorsPercent + '%',
    ],
    datasets: [
      {
        label: [promotorsPercent, passivesPercent, dectatorsPercent],
        data: [promotorsPercent, passivesPercent, dectatorsPercent],
        backgroundColor: ['#003d51', '#ffa000', '#ef4d5e'],
        hoverBackgroundColor: ['#003d51', '#ffa000', '#ef4d5e'],
      },
    ],
  }

  const options = {
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        boxWidth: 20,
      },
    },
    tooltips: {
      callbacks: {
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index]
        },
        label: function() {
          return ''
        },
      },
      backgroundColor: '#FFF',
      titleFontSize: 16,
      titleFontColor: '#0066ff',
      bodyFontColor: '#000',
      bodyFontSize: 14,
      displayColors: false,
    },
  }

  const handleReport = id => {
    props.history.push({
      pathname: `/generate-report-nps/${id}`,
    })
  }

  const isLoading = loadingDataNps || loadingTotalResponden

  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth={window.innerWidth < 1920 ? 'md' : 'lg'}
    >
      <Fragment>
        <ModalHeader>
          <FlexBetween>
            <FlexBetween style={{width: '100%'}}>
              <TextXl>{`${dataUser.name}'s NPS`}</TextXl>
            </FlexBetween>
            <IconButton aria-label="close" onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
          <Pmd style={{marginTop: '10px'}}>
            {`Diterbitkan pada ${dataNPS?.startdate}`}
          </Pmd>
        </ModalHeader>
        <ModalContent>
          {isLoading ? (
            <div style={customStyle.loadingWrapper}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <FlexBetween>
                <Pmd>Rincian {GLOBAL_GLOSSARY.performance.NPS}</Pmd>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{textTransform: 'initial'}}
                  onClick={() => handleReport(id_data)}
                >
                  Buat Laporan
                </Button>
              </FlexBetween>
              <DetailNSPContainer>
                <div style={{width: '30%', marginRight: '1rem'}}>
                  <CardAssigned
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Pmd>Ditugaskan kepada</Pmd>
                    <Avatar
                      src={dataUser.avatar}
                      alt={dataUser.name}
                      style={{height: '100px', width: '100px'}}
                    />
                    <P18Bold>{dataUser.name}</P18Bold>
                    <Pmd style={{color: '#a9a8a8'}}>{`${position} -`}</Pmd>
                    <Pmd style={{color: '#a9a8a8'}}>{company}</Pmd>
                    <h3 style={{color: '#707070', marginTop: '100px'}}>
                      Indeks Keseluruhan
                    </h3>
                    <SummaryScore
                      style={{color: SwitchValue(resultNPS, 'colorResult')}}
                    >
                      <TextBig>
                        {(() => {
                          if (isNaN(resultNPS)) {
                            return '0%'
                          } else {
                            return `${resultNPS}%`
                          }
                        })()}
                      </TextBig>
                    </SummaryScore>
                  </CardAssigned>
                </div>
                <CardNSPContainer>
                  <CardAssigned style={{marginRight: '0px'}}>
                    <Pmd style={customStyle.rightCard}>Respon</Pmd>
                    <Doughnut
                      data={data}
                      options={options}
                      width={400}
                      height={150}
                    />
                  </CardAssigned>
                  <CardAssigned
                    style={{marginTop: '1.6rem', marginRight: '0px'}}
                  >
                    <Pmd style={customStyle.rightCard}>Responden</Pmd>
                    <Grid container>
                      <Grid item md={4}>
                        <TitleRespondents>{totalResponse}</TitleRespondents>
                        <ValueRespondents>Total Responden</ValueRespondents>
                      </Grid>
                      <Grid item md={4}>
                        <TitleRespondents>
                          {(() => {
                            if (isNaN(finishedResponse)) {
                              return '0'
                            } else {
                              return `${finishedResponse}`
                            }
                          })()}
                        </TitleRespondents>
                        <ValueRespondents>Respon yang selesai</ValueRespondents>
                      </Grid>
                      <Grid item md={4}>
                        <TitleRespondents>
                          {(() => {
                            if (isNaN(waiting)) {
                              return '0'
                            } else {
                              return `${waiting}`
                            }
                          })()}
                        </TitleRespondents>
                        <ValueRespondents>Menunggu</ValueRespondents>
                      </Grid>
                    </Grid>
                  </CardAssigned>
                </CardNSPContainer>
              </DetailNSPContainer>
            </>
          )}
        </ModalContent>
      </Fragment>
    </StyledDialog>
  )
}

export default withRouter(ModalDetailNPS)
