import React from 'react'
import {Route} from 'react-router-dom'
import {getCookie} from '../../utils/helpers'
import {SSO_URL} from '../../utils/globals'

function redirectToSso() {
  if (process.env.NODE_ENV !== 'development') {
    window.location = SSO_URL
  }
}

const PrivateRoute = ({component: Component, ...props}) => (
  <Route
    {...props}
    render={props =>
      getCookie('userData') ? <Component {...props} /> : redirectToSso()
    }
  />
)

export default PrivateRoute
