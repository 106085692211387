import React from 'react'
import {CircularProgress, Typography} from '@material-ui/core'

export default function LoadingComponent() {
  return (
    <div
      style={{
        width: '100%',
        padding: '120px 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <CircularProgress />
      <Typography style={{marginTop: 30}}>Loading Data...</Typography>
    </div>
  )
}
