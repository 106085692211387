import React, {useState} from 'react'

import {
  CardSummaryPlain,
  SeeMore,
  GoalsListBtn,
  SpanMyGoals,
  SummaryScore,
} from '../PerformanceStyles'

import useStyles from './ArchiveStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {ErrorOutline} from '@material-ui/icons'
import {Psm, Pnm2} from '../../../components/typography/TypographyStyles'
import {trimString} from '../../../utils/helpers'
import moment from 'moment'
import ModalTab from '../goal-components/TabDialog/ModalTab'
import {IconButton} from '@material-ui/core'
import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'
import FieldInformationTooltip from '../../shared-component/FieldInformationTooltip'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

const customStyle = {
  goalContainer: {
    backgroundColor: '#f7f8f9',
    margin: 0,
  },
  cardItem: {
    cursor: 'pointer',
    padding: 12,
    margin: 5,
    fontSize: 13,
    height: 'auto',
    paddingBottom: '20px',
  },
  textSkyBlue: {
    color: '#039be5',
  },
  textDate: {
    color: '#a9a8a8',
    marginBottom: 0,
  },
  btnGreen: {backgroundColor: '#4caf50', textTransform: 'none'},
  btnSkyBlue: {backgroundColor: '#039be5', textTransform: 'none'},
}

export default function ListArchivment({data, limit, setLimit}) {
  const classes = useStyles()
  const [statusModal, setStatModal] = useState(false)
  const [selectedId, setSelectedId] = useState(0)

  const dataLength = data?.total?.aggregate?.count

  const handleSeeMore = () => {
    if (limit < dataLength) {
      setLimit(limit + 12)
    } else {
      setLimit(12)
    }
  }

  const handleModalOpen = id => {
    setStatModal(true)
    setSelectedId(id)
  }
  const handleModalClose = () => {
    setStatModal(false)
  }

  return (
    <>
      <Grid container spacing={1}>
        {data.performance_tasks.map((res, key) => {
          const typeGoal =
            res?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
              ?.aggregate?.count > 0
          return (
            <CardSummaryPlain
              style={customStyle.cardItem}
              onClick={() => handleModalOpen(res.id)}
              key={key}
            >
              <GoalsListBtn
                style={{
                  marginBottom: '5px',
                  width: '85px',
                  backgroundColor: typeGoal ? '#a35a31' : '#874caf',
                }}
              >
                <SpanMyGoals style={{fontSize: '10px'}}>
                  {typeGoal
                    ? GLOBAL_GLOSSARY.performance['Company Goal']
                    : GLOBAL_GLOSSARY.performance['Personal Goal']}
                </SpanMyGoals>
              </GoalsListBtn>
              <div style={{marginTop: 10}} className={classes.titleCard}>
                {res && trimString(res.name, 55)}
              </div>
              <div style={{color: '#055469', margin: '10px 0px'}}>
                {`Diarsipkan pada tanggal ${moment(res.last_modified).format(
                  'MMM DD, YYYY'
                )}`}
                <FieldInformationTooltip
                  title={`Tugas ini jatuh tempo sebelum ${moment(
                    res.enddate
                  ).format('MMM DD, YYYY')} dan selesai pada ${moment(
                    res?.complete_at?.[0]?.date_added
                  ).format('MMM DD, YYYY')}`}
                >
                  <IconButton size="small" style={{marginLeft: '8px'}}>
                    <ErrorOutline color="primary" style={{fontSize: '15px'}} />
                  </IconButton>
                </FieldInformationTooltip>
              </div>
              <SummaryScore
                style={{
                  color: SwitchValue(
                    res && res.progress_percentage,
                    'progress_percentage'
                  ),
                  marginTop: '25px',
                }}
              >
                <Pnm2>
                  {res && res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : 0}
                  %
                </Pnm2>

                <Psm>
                  {SwitchValue(
                    res && res.progress_percentage,
                    'progress_status'
                  )}
                </Psm>
              </SummaryScore>
            </CardSummaryPlain>
          )
        })}
      </Grid>
      {dataLength >= 12 ? (
        <SeeMore>
          <Button
            style={{
              textTransform: 'none',
            }}
            color="secondary"
            onClick={handleSeeMore}
          >
            {limit < dataLength ? 'Lihat lebih banyak' : 'Lihat lebih sedikit'}
          </Button>
        </SeeMore>
      ) : (
        ''
      )}
      <ModalTab
        open={statusModal}
        handleClose={handleModalClose}
        id={selectedId}
        type="task"
        condition="archive"
        withoutAdd={true}
      />
    </>
  )
}
