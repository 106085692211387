import React, {useEffect, useState, useContext} from 'react'

import {CircularProgress, makeStyles} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  GET_DETAIL_CONTAINER_GOAL,
  GET_LIST_ALL_DEPARTMENT_GOAL,
  GET_LIST_ALL_MY_GOAL,
} from '../../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../../utils/globals'
import {
  COMPANY_GOAL_TYPE,
  PERSONAL_GOAL_TYPE,
  QUICK_FILTER_PERSONAL,
} from '../../constant'
import ModalTab from '../../TabDialog/ModalTab'

import SeeAll from '../SeeAll'
import GoalContainer from './GoalContainer'
import Loader from '../Loader'
import ModalDelete from '../../../Modal/ModalDelete'
import ModalEdit from '../../../Modal/ModalEdit'
import {DELETE_GOAL} from '../../../../../graphql/mutations'
import {CreateGoalButton} from '../../sections/GoalsComponent'
import ModalKpi from '../../../Modal/ModalKpi'
import ModalOwnGoal from '../../../Modal/ModalOwnGoal'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {DataContext} from '../../../../../contexts/ContextConfig'

const useStyles = makeStyles(theme => ({
  container: {
    padding: 4,
    width: '100%',
    display: 'grid',
    gap: '30px 42px',
    boxSizing: 'border-box',
    gridTemplateColumns: 'repeat(auto-fit, 241px)',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    overflowY: 'initial !important',
  },
}))

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const SeeAllGoal = props => {
  const {type} = props.match.params
  const {dataContext, setDataContext} = useContext(DataContext)

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()
  const classes = useStyles()

  const [modalStyle] = useState(getModalStyle)
  const [sort, setSort] = useState('desc')
  const [search, setSearch] = useState('')
  const [filterData, setFilterData] = useState({})
  const [dataModal, setDataModal] = useState(null)
  const [open, setOpen] = useState({
    detail: false,
    edit: false,
    delete: false,
    kpi: false,
    ownGoal: false,
  })

  useEffect(() => {
    if (dataContext) {
      setTimeout(() => {
        setDataContext(null)
      }, 1000)
    }
  }, [dataContext])
  const [deleteGoal] = useMutation(DELETE_GOAL)

  const query =
    type === QUICK_FILTER_PERSONAL
      ? GET_LIST_ALL_MY_GOAL
      : GET_LIST_ALL_DEPARTMENT_GOAL

  const {data, loading, fetchMore, refetch} = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      user: USER_ID,
      company: COMPANY_ID,
      search: `%${search}%`,
      offset: 0,
      limit: 6,
      order: sort,
      isCompany: true,
      isPersonal: true,
      ...filterData.include,
    },
  })

  const collectedData = data?.performance_goals?.length || 0
  const totalData = data?.total?.aggregate.count || 0

  const fetchMoreData = () => {
    fetchMore({
      variables: {offset: collectedData},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          performance_goals: [
            ...prev.performance_goals,
            ...fetchMoreResult.performance_goals,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const handleOpenSnackbar = (message, variant) => {
    enqueueSnackbar(message, {variant, autoHideDuration: 3000})
  }

  const onDeleteGoal = () => {
    deleteGoal({
      variables: {
        id: dataModal?.id,
      },
    })
      .then(() => {
        handleOpenSnackbar('Data deleted', 'success')
        refetch()
      })
      .catch(() => {
        handleOpenSnackbar('Delete data error, please try again', 'error')
      })
  }

  const handleToggleModal = type => {
    setOpen(prev => ({...prev, [type]: !prev[type]}))
  }

  const handleElipsis = async (action, data) => {
    switch (action) {
      case 'edit':
        {
          const {data: _data} = await client.query({
            query: GET_DETAIL_CONTAINER_GOAL,
            variables: {id: data.id},
          })
          setDataModal(_data.performance_goals_by_pk)
          handleToggleModal(action)
        }

        break

      default:
        setDataModal(data)
        handleToggleModal(action)
        break
    }
  }

  const handleClickMenu = type => {
    switch (type) {
      case COMPANY_GOAL_TYPE:
        handleToggleModal('kpi')
        break

      case PERSONAL_GOAL_TYPE:
        handleToggleModal('ownGoal')
        break

      default:
        break
    }
  }

  const handleCloseModal = type => {
    handleToggleModal(type)
    refetch()
  }

  const typeGoal =
    type === 'personal'
      ? '- Hasil Kerja Saya'
      : `- ${GLOBAL_GLOSSARY.performance['Company Goal']}`

  return (
    <>
      <SeeAll
        title={`Semua ${GLOBAL_GLOSSARY.performance.Goal} ${typeGoal}`}
        type={type}
        setSort={setSort}
        search={search}
        setSearch={setSearch}
        filterData={filterData}
        setFilterData={setFilterData}
        onBack={() => props.history.goBack()}
        buttonConfig={props => (
          <CreateGoalButton onClickMenu={handleClickMenu} {...props} />
        )}
      >
        {!loading && data && (
          <InfiniteScroll
            dataLength={collectedData}
            hasMore={collectedData < totalData}
            next={fetchMoreData}
            loader={
              <div style={{textAlign: 'center', margin: '34px auto'}}>
                <CircularProgress size={15} style={{marginRight: '10px'}} />
                <span>Loading</span>
              </div>
            }
          >
            <div className={classes.container}>
              {data?.performance_goals?.map(({id}) => (
                <GoalContainer key={id} id={id} handleElipsis={handleElipsis} />
              ))}
            </div>
          </InfiniteScroll>
        )}
        {loading && (
          <div className={classes.container}>
            {Array.from(Array(6).keys()).map(id => (
              <Loader key={id} />
            ))}
          </div>
        )}
        {!loading && totalData === 0 && (
          <EmptyState
            message1="Maaf, Tidak ada daftar"
            message2={`Tidak ada ${GLOBAL_GLOSSARY.performance.Goal} yang dibuat`}
            width="313"
          />
        )}
      </SeeAll>

      <ModalTab
        open={open.detail}
        handleClose={() => handleToggleModal('detail')}
        id={dataModal?.id || 0}
        type="goal"
      />

      <ModalEdit
        open={open.edit}
        onBack={() => handleToggleModal('edit')}
        dataEdit={dataModal}
        type="edit-goal"
      />

      <ModalDelete
        open={open.delete}
        handleClose={() => handleToggleModal('delete')}
        name={dataModal?.name}
        mutation={onDeleteGoal}
      />

      <ModalKpi
        open={open.kpi}
        onClose={() => handleCloseModal('kpi')}
        styleModal={modalStyle}
        classNameModal={classes.paper}
        onBack={() => handleCloseModal('kpi')}
      />

      <ModalOwnGoal
        open={open.ownGoal}
        onClose={() => handleCloseModal('ownGoal')}
      />
    </>
  )
}

export default withRouter(SeeAllGoal)
