import Moment from 'moment'
import { parameters_versions } from './constant'
import { APP_COOKIE, SSO_URL } from './globals'
// import html2PDF from 'jspdf-html2canvas'

export const GLOBAL_PAGINATION_RANGE = [10, 25, 50]

export function convertToRupiah(angka) {
  let rupiah = ''
  const angkarev = angka
    .toString()
    .split('')
    .reverse()
    .join('')
  for (let i = 0; i < angkarev.length; i++)
    if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.'

  return (
    'Rp' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('') +
    ',00'
  )
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}

export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export function getDate(date) {
  const initDate = new Date(date)
  return Moment(initDate).format('DD MMMM YYYY')
  // let dd = initDate.getDate()
  // let mm = initDate.getMonth() + 1
  // let yyyy = initDate.getFullYear()
  // if (dd < 10) {
  //   dd = `0${dd}`
  // }

  // if (mm < 10) {
  //   mm = `0${mm}`
  // }

  // const dateFull = `${dd}/${mm}/${yyyy}`
  // return dateFull
}

export const IntersectionOfDate = (minDate, date, maxDate) => {
  const convertminDate = new Date(minDate)
  const convertDate = new Date(date)
  const convertMaxDate = new Date(maxDate)

  return convertDate <= convertMaxDate && convertDate >= convertminDate
}

export function dateFormat({ date, format, nullHandler = '-' }) {
  if (date) return Moment(date).format(format)
  else return nullHandler
}

export const isDateBefore = (start_date, end_date) => {
  if (start_date && end_date) {
    return Moment(start_date).isBefore(end_date)
  } else {
    return false
  }
}

export const isDateExpire = (start_date, end_date) => {
  if (start_date && end_date) {
    if (!Moment(start_date).isSame(end_date, 'day')) {
      return Moment(start_date).isBefore(end_date)
    } else {
      return false
    }
  } else {
    return false
  }
}
export const isDateAfterFormNow = date => {
  if (date) {
    return Moment(date).isAfter(new Date())
  } else {
    return false
  }
}
export const isDateBetween = (date, startdate, enddate) => {
  if (date) {
    return Moment(dateFormat({ date, format: 'YYYY-MM-DD' })).isBetween(
      startdate,
      enddate,
      undefined,
      '[]'
    )
  } else {
    return false
  }
}
export const isNowAfterFrom = date => {
  if (date) {
    return Moment(new Date()).isAfter(date)
  } else {
    return false
  }
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + APP_COOKIE
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function getWithin(start_date) {
  if (start_date !== '') {
    // var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // var firstDate = new Date(start_date);
    // var secondDate = new Date();
    // var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));

    // return diffDays
    // var date2 = new Date(start_date);
    // var date1 = new Date();
    // var diff = Math.floor(date1.getTime() - date2.getTime());
    // var day = 1000 * 60 * 60 * 24;

    // var days = Math.floor(diff / day);
    // // var weeks = Math.floor(diff / 7);
    // var months = Math.floor(days / 31);
    // var years = Math.floor(months / 12);

    // var message = '';
    // message += " was "
    // message += days + " days "
    // // message += weeks + " weeks "
    // message += months + " months "
    // message += years + " years ago \n"

    // return message
    // Calculate time between two dates:
    const date1 = new Date(start_date) // the date you already commented/ posted
    const date2 = new Date() // today

    let message = ''

    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor((diffInSeconds / 60 / 60) % 24)
    const minutes = Math.floor((diffInSeconds / 60) % 60)

    const months = Math.floor(days / 31)
    const years = Math.floor(months / 12)

    // the below object is just optional
    // if you want to return an object instead of a message

    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in days if no years / months
      if (days > 0) {
        if (days === 1) {
          message = days + ' day'
        } else {
          message = days + ' days'
        }
      } else if (hours > 0) {
        if (hours === 1) {
          message = hours + ' hour'
        } else {
          message = hours + ' hours'
        }
      } else {
        // show in minutes if no years / months / days
        if (minutes === 1) {
          message = minutes + ' minute'
        } else {
          message = minutes + ' minutes'
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = months + ' month'
      } else {
        message = months + ' months'
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = years + ' year'
      } else {
        message = years + ' years'
      }
    }

    return 'Within ' + message
  }
}

export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

export const formatBytes = (a, b) => {
  if (a === 0) {
    return '0 Bytes'
  }
  const c = 1024
  const d = b || 2
  const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const f = Math.floor(Math.log(a) / Math.log(c))
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + ' ' + e[f]
}

// export const generatePdf = (id, filename) => {
// html2canvas(id, {
//   letterRendering: 1,
//   allowTaint: true,
//   useCORS: true,
// }).then(canvas => {
//   const imgData = canvas.toDataURL('image/png')
//   const imgWidth = 210
//   const pageHeight = 295
//   const imgHeight = (canvas.height * imgWidth) / canvas.width
//   let heightLeft = imgHeight
//   const JSPDF = jsPDF
//   const doc = new JSPDF('p', 'mm')
//   let position = 0
//   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
//   heightLeft -= pageHeight
//   while (heightLeft >= 0) {
//     position = heightLeft - imgHeight
//     doc.addPage()
//     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
//     heightLeft -= pageHeight
//   }
//   doc.save(filename)
// })

//   html2PDF(id, {
//     jsPDF: {
//       format: 'a4',
//     },
//     imageType: 'image/jpeg',
//     success: pdf => {
//       pdf.save(filename)
//     },
//   })
// }

export const switchFlexyComponents = (data, axis, detail, xArr, yArr) => {
  let indicatorState
  let index
  switch (data.parameter) {
    case 'competency':
      indicatorState = 'indicator_pot_competence'
      index = 'Index'
      break
    case 'attendance':
      indicatorState = 'indicator_pot_attendance'
      index = 'Attendances'
      break
    case 'course':
      indicatorState = 'indicator_pot_total_course'
      index = 'Courses'
      break
    case 'socmed_post':
      indicatorState = 'indicator_pot_socmed_post'
      index = 'Posts'
      break
    case 'task':
      indicatorState = 'indicator_perf_goal'
      index = 'Index'
      break
    case 'ogf':
      indicatorState = 'indicator_perf_ogf'
      index = 'Total'
      break
    case 'multirater':
      indicatorState = 'indicator_perf_multirater'
      index = 'Total'
      break
    case 'nps':
      indicatorState = 'indicator_perf_nps'
      index = 'Total'
      break
  }

  const newAxisValue = {
    param:
      parameters_versions?.[data.parameter]?.ID || capitalize(data.parameter),
    value: detail[indicatorState]?.toFixed(2),
    index,
  }

  if (axis === 'x') {
    xArr.push(newAxisValue)
  } else {
    yArr.push(newAxisValue)
  }
}

export const percentProgressMethod = percentProgres => {
  const scoringNature =
    percentProgres &&
    percentProgres.performance_goal &&
    percentProgres.performance_goal.kpi_list &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature
      .global_scoring_nature &&
    percentProgres.performance_goal.kpi_list.kpi_scoring_nature
      .global_scoring_nature.name
  let kpiScore = 0
  if (scoringNature === 'Higher is Better') {
    kpiScore += (percentProgres.progress / percentProgres.target) * 100
    if (percentProgres.progress === percentProgres.target) {
      kpiScore = 100
    }
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Lower is Better') {
    let low = 0
    let delta = 100
    if (percentProgres.target !== 0) {
      delta = Math.abs(percentProgres.target)
    }
    if (percentProgres.progress === percentProgres.target) {
      low += 100
    } else if (percentProgres.progress > percentProgres.target) {
      low +=
        100 - (percentProgres.progress - percentProgres.target) / (delta / 100)
    } else if (percentProgres.progress < percentProgres.target) {
      low +=
        100 + (percentProgres.target - percentProgres.progress) / (delta / 100)
    }
    kpiScore += low
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Precise is Better') {
    let precise = 0
    let delta = 100
    if (percentProgres.target !== 0) {
      delta = Math.abs(percentProgres.target)
    }
    if (percentProgres.progress === percentProgres.target) {
      precise += 100
    } else if (percentProgres.progress > percentProgres.target) {
      precise +=
        100 - (percentProgres.progress - percentProgres.target) / (delta / 100)
    } else if (percentProgres.progress < percentProgres.target) {
      precise +=
        100 - (percentProgres.target - percentProgres.progress) / (delta / 100)
    }
    kpiScore += precise
    if (!kpiScore) {
      kpiScore = 0
    }
  } else if (scoringNature === 'Special Scoring') {
    let special = 0
    if (percentProgres.progress !== percentProgres.target) {
      special += 100
    }
    kpiScore += special
  } else {
    kpiScore += (percentProgres.progress / percentProgres.target) * 100
    if (percentProgres.progress === percentProgres.target) {
      kpiScore = 100
    }
    if (!kpiScore) {
      kpiScore = 0
    }
  }
  return kpiScore.toFixed(2)
}

export const selectProps = val => {
  return {
    displayEmpty: true,
    style: {
      color: !val ? '#bcbcbc' : 'unset',
    },
  }
}

export const disbaledButtonExport = dateAdded => {
  const present_date = new Date()
  const download_date = new Date(dateAdded)

  present_date.setHours(0, 0, 0, 0)
  download_date.setHours(0, 0, 0, 0)

  const oneHours = 1000 * 60 * 60
  const timeCalculate = present_date.getTime() - download_date.getTime()
  const limitDisiabled = timeCalculate / oneHours
  return limitDisiabled < 24
}

export const trimString = (data, length, seeMore = false) => {
  return (
    data &&
    `${data.substring(0, length)} ${data.length > length && !seeMore ? '...' : ''
    }`
  )
}

export const timeStamp = commentDate => {
  const present_date = new Date()
  const comment_date = new Date(commentDate)

  present_date.setHours(0, 0, 0, 0)
  comment_date.setHours(0, 0, 0, 0)

  const oneHours = 1000 * 60 * 60
  const timeCalculate = present_date.getTime() - comment_date.getTime()
  const limitDisiabled = timeCalculate / oneHours

  return limitDisiabled < 168
    ? Moment(commentDate).fromNow()
    : Moment(commentDate).format('MMMM DD, YYYY')
}

export const getTypeFile = (href = '') => {
  return href
    .split('.')
    .slice(-1)
    .pop()
    .toLocaleLowerCase()
}

export const fileChecker = name => {
  const nameSplited = name?.split('.')
  const idx = nameSplited?.length - 1
  const res = nameSplited?.[idx]

  if (res === 'doc' || res === 'docx') {
    return 'document'
  } else if (res === 'xls' || res === 'xlsx' || res === 'csv') {
    return 'excel'
  } else if (res === 'pdf') {
    return 'pdf'
  } else if (res === 'ppt' || res === 'pptx') {
    return 'powerpoint'
  } else if (
    res === 'jpg' ||
    res === 'jpeg' ||
    res === 'png' ||
    res === 'gif' ||
    res === 'tiff' ||
    res === 'pjp' ||
    res === 'pjpeg' ||
    res === 'jfif' ||
    res === 'tif'
  ) {
    return 'image'
  } else if (res === 'JPG' || res === 'JPEG' || res === 'PNG') {
    return 'image'
  } else {
    return false
  }
}

export const hasModule = (data, moduleName) =>
  data?.company_module_settings?.length === 0 ||
  data?.company_module_settings?.some(o => o.global_module.name === moduleName)

export const downloadFile = (fileUrl, fileName, fileExtension) => {
  const nameaHasExtension = fileName?.indexOf('.') > 0
  const addExtension = !nameaHasExtension ? `.${fileExtension}` : ''
  fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `${fileName}${addExtension}`
      link.click()
    })
}

export const handleChangeAttachment = (
  valueAttachment,
  setDisableButton,
  setValues,
  axios,
  uploadUrl,
  token
) => {
  const formData = new FormData()
  formData.append('file', valueAttachment)
  const handleResponse = {
    id: valueAttachment.name + valueAttachment.size,
    name: valueAttachment.name,
    size: valueAttachment.size,
    url: '',
    loaded: 0,
    isUploading: true,
  }
  setDisableButton(true)
  setValues(prevState => {
    return {
      ...prevState,
      attachment: [...(prevState.attachment || []), handleResponse],
    }
  })
  return axios
    .post(uploadUrl, formData, {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      onUploadProgress: progressEvent => {
        handleResponse.loaded = progressEvent.loaded
        setDisableButton(true)
        setValues(prevState => {
          const items = prevState.attachment
          const foundIndex = items.findIndex(
            x => x.id === handleResponse.id && x.isUploading
          )
          items[foundIndex] = handleResponse
          return { ...prevState, attachment: items }
        })
      },
    })
    .then(response => {
      handleResponse.url = response.data.url
      handleResponse.loaded = valueAttachment.size
      handleResponse.isUploading = false
      setDisableButton(false)
      setValues(prevState => {
        const items = prevState.attachment
        const foundIndex = items.findIndex(
          x => x.id === handleResponse.id && x.isUploading
        )
        items[foundIndex] = handleResponse
        return { ...prevState, attachment: items }
      })
    })
    .catch(error => {
      JSON.stringify(error)
    })
}

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

export const clamp = (amount = 1) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': amount,
})

export const MathDetailBoxes = (dataBoxes, flexy) => {
  const setBoxes = []
  const res = dataBoxes

  const boxRangeX =
    (parseInt(flexy.x.max) - parseInt(flexy.x.min)) / parseInt(res.box_width)
  const boxRangeY =
    (parseInt(flexy.y.max) - parseInt(flexy.y.min)) / parseInt(res.box_height)
  const findValue = parameter =>
    flexy.x.parameters.some(f => f.parameter === parameter)

  res.talent_boxes.data.forEach(resd => {
    const minBoxX = parseInt(flexy.x.min) + (resd.index_x - 1) * boxRangeX
    const minBoxY = parseInt(flexy.y.min) + (resd.index_y - 1) * boxRangeY

    const nps =
      res.indicator_perf_nps &&
      (parseInt(res.indicator_perf_nps_fields.weight) *
        (findValue('nps') ? minBoxX : minBoxY)) /
      100
    const ogf =
      res.indicator_perf_ogf &&
      (parseInt(res.indicator_perf_ogf_fields.weight) *
        (findValue('ogf') ? minBoxX : minBoxY)) /
      100
    const task =
      res.indicator_perf_goal &&
      (parseInt(res.indicator_perf_goal_fields.weight) *
        (findValue('task') ? minBoxX : minBoxY)) /
      100
    const multirater =
      res.indicator_perf_multirater &&
      (parseInt(res.indicator_perf_multirater_fields.weight) *
        (findValue('multirater') ? minBoxX : minBoxY)) /
      100
    const competency =
      res.indicator_pot_competence &&
      (parseInt(res.indicator_pot_competence_fields.weight) *
        (findValue('competency') ? minBoxX : minBoxY)) /
      100
    const attendance =
      res.indicator_pot_attendance &&
      (parseInt(res.indicator_pot_attendance_fields.weight) *
        (findValue('attendance') ? minBoxX : minBoxY)) /
      100
    const course =
      res.indicator_pot_total_course &&
      (parseInt(res.indicator_pot_total_course_fields.weight) *
        (findValue('course') ? minBoxX : minBoxY)) /
      100
    const socmed_post =
      res.indicator_pot_socmed_post &&
      (parseInt(res.indicator_pot_socmed_post_fields.weight) *
        (findValue('socmed_post') ? minBoxX : minBoxY)) /
      100
    const data = {
      nps,
      ogf,
      task,
      multirater,
      competency,
      attendance,
      course,
      socmed_post,
      index_x: resd.index_x,
      index_y: resd.index_y,
    }
    setBoxes.push(data)
  })

  return setBoxes
}
