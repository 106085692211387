import gql from 'graphql-tag'

export const GET_DETAIL_MULTIRATER = gql`
  query getListMultirater($id: uuid, $survey: bigint) {
    detail_user: global_users(where: {id: {_eq: $id}}) {
      id
      name
      avatar
      people_work_placements {
        id
        company_employee_position {
          id
          name
        }
        company_address {
          id
          office_name
        }
      }
    }

    multirater_surveys(
      where: {
        multirater_responses: {respond_to: {_eq: $id}}
        id: {_eq: $survey}
      }
    ) {
      id
      title
      type
      startdate
      enddate
      date_created
      status
      multirater_survey_groups(where: {isnps: {_eq: false}}) {
        id
        name
        avg_all_score: multirater_survey_responses_aggregate {
          aggregate {
            avg {
              answer
            }
          }
        }

        avg_self: multirater_survey_responses_aggregate(
          where: {respond_to: {_eq: $id}, respond_from: {_eq: $id}}
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }

        avg_spv_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {
                global_user: {
                  people_work_placement_supervisor: {user: {_eq: $id}}
                }
              }
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
        avg_peer_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {
                global_user_supervisor: {
                  people_work_placement_supervisor: {user: {_eq: $id}}
                }
              }
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
        avg_subordinate_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {global_user_supervisor: {id: {_eq: $id}}}
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
      }

      count_responded: multirater_responses_aggregate(
        where: {respond_from: {_eq: $id}}
        distinct_on: respond_to
      ) {
        aggregate {
          count
        }
      }
      count_respondents: multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_responses_aggregate(where: {respond_to: {_eq: $id}}) {
        aggregate {
          avg {
            answer
          }
        }
      }
    }
  }
`
