import gql from 'graphql-tag'

export const GET_LIST_MULTIRATER_EVALUATE = gql`
  query($user: uuid!, $company: uuid!, $survey: bigint!) {
    multirater_surveys(where: {id: {_eq: $survey}, company: {_eq: $company}}) {
      title
      buttons
      scale
      nps_include
      startdate
      enddate
      multirater_scale {
        id
        name
      }
      multirater_respondents {
        global_user {
          id
          name
          avatar
          email
          people_work_placements(
            where: {company: {_eq: $company}, status: {_eq: "ACTIVE"}}
          ) {
            id
            regno
            company_organization {
              id
              name
            }
            company_employee_position {
              id
              name
            }
            company_employee_rank {
              id
              name
            }
            company_address {
              id
              office_name
            }
          }

          multirater_responses_to(where: {respond_from: {_eq: $user}}) {
            id
            group
            question
            answer
            answer_text
          }
        }
      }
      multirater_survey_groups(order_by: {number: asc}) {
        id
        name
        isnps
        number
        multirater_survey_questions(order_by: {number: asc}) {
          id
          question_self
          question_others
          number
        }
      }
    }
  }
`
