import React from 'react'
import ButtonAttachment from './ButtonAttachment'
import ItemAttachment from './ItemAttachment'
import Button from '@material-ui/core/Button'

const AddAtachment = props => {
  const {
    attachment,
    idAttachment,
    multiple,
    onUpload,
    clearAttach,
    mutationAttach,
    handleDeleteAttachement,
    handleChangeNameFile,
    hideOptions,
    disabled,
  } = props
  return (
    <div>
      <ButtonAttachment
        id={idAttachment}
        multiple={multiple}
        onUpload={onUpload}
        disabled={disabled}
      />
      {attachment?.length > 0 && !hideOptions && (
        <div>
          {attachment?.map((res, i) => {
            const {name, size, url, isUploading, loaded} = res
            return (
              <ItemAttachment
                res={res}
                key={`${url}-${i}`}
                id={`${url}-${i}`}
                size={size}
                onDelete={() => handleDeleteAttachement(i)}
                src={url}
                onChange={e => handleChangeNameFile(e, i)}
                fileName={name}
                isUploading={isUploading}
                loaded={loaded}
              />
            )
          })}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              float: 'right',
              marginTop: '1rem',
            }}
          >
            <div style={{marginRight: 12}}>
              <Button size="small" onClick={clearAttach}>
                Cancel
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={mutationAttach}
                size="small"
                disabled={attachment?.length === 0 || disabled}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddAtachment
