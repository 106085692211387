import gql from 'graphql-tag'

export const GET_REPORT_SUMMARY = gql`
  query getDetailIndividualPerformance($offset: Int, $limit: Int, $user: uuid) {
    total: performance_individuals_aggregate {
      aggregate {
        count
      }
    }

    performance_individuals(
      where: {user: {_eq: $user}}
      offset: $offset
      limit: $limit
      order_by: [{date_added: desc}]
    ) {
      id
      user
      startdate
      enddate
      kpi_score
      kpi_weight
      multirater_score
      multirater_weight
      nps_score
      nps_weight
      ogf_score
      ogf_weight
      index_score
    }
  }
`
