import {makeStyles} from '@material-ui/core'
import React from 'react'

import {Doughnut as DoughnutChart} from 'react-chartjs-2'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    width: '44%',
  },
  title: {
    fontSize: 24,
    color: '#414141',
    margin: 0,
    textAlign: 'center',
  },
  description: {
    fontSize: 14,
    color: '#414141',
    margin: 0,
    textAlign: 'center',
    maxWidth: 100,
  },
  legend: {
    position: 'absolute',
    zIndex: '-1',
  },
})

const options = {
  cutoutPercentage: 80,
  maintainAspectRatio: true,
  responsive: true,
  legend: {display: false},
  tooltips: {
    callbacks: {
      title: (tooltipItem, data) => {
        return data.labels[tooltipItem[0].index]
      },
      label: (tooltipItem, data) => {
        return data?.datasets?.[0]?.data?.[tooltipItem?.index || 0]
      },
    },
    backgroundColor: '#FFF',
    titleFontSize: 12,
    titleFontColor: '#000',
    bodyFontColor: '#039be5',
    bodyFontSize: 12,
    displayColors: false,
  },
}

const Doughnut = props => {
  const classes = useStyles()
  const {data, total, title} = props

  return (
    <div className={classes.container}>
      <div>
        <DoughnutChart
          className={classes.chart}
          type="doughnut"
          data={data}
          options={options}
        />
      </div>
      <div className={classes.legend}>
        <p className={classes.title}>{total}</p>
        <p className={classes.description}>{title}</p>
      </div>
    </div>
  )
}

export default Doughnut
