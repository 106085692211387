import React from 'react'
import styled from 'styled-components'
import {
  Card,
  Tabs,
  Tab,
  Icon,
  withStyles,
  LinearProgress,
  Avatar,
  Button,
  Grid,
  ExpansionPanelSummary,
  Menu,
  MenuItem,
  ExpansionPanel,
  Typography,
  Tooltip,
} from '@material-ui/core'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${props => props.theme.font};
  justify-content: space-between;
`

export const TitleCreate = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  font-size: 18px;
`
export const TaskCard = styled.div`
  width: 100%;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FlexColumn = styled.div`
  margin: 10px 35px;
  flex-direction: column;
  width: 90%;
`

export const Center = styled.div`
  text-align: center;
`

export const SummaryContainer = styled.div``

export const ListSummaryContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.template || 'repeat(3, 32%)'};
  gap: 16px;
`

export const CardSummary = styled(Card)`
  padding: 18px;
  cursor: pointer;
`
export const OverallContainer = styled.div`
  margin: 1rem 0;
  color: #039be5;
`
export const SummaryScore = styled.div`
  color: #039be5;
`
export const Rate = styled.div`
  color: #039be5;
`

export const DateContainer = styled.div`
  color: #707070;
  display: grid;
`
export const DateContainer2 = styled.div`
  color: #707070;
  height: 2rem;
  display: grid;
  font-size: 12px !important;
  @media only screen and (max-width: 1308px) {
    font-size: 10px;
  }
`
export const SummaryListContainer = styled.div``

export const AchievementContainer = styled.div`
  padding-top: 2rem;
`

export const MultiraterContainer = styled.div`
  padding-top: 2rem;
`

export const NPSContainer = styled.div`
  padding-top: 2rem;
`

export const FeedbackContainer = styled.div`
  padding-top: 2rem;
`

export const SelectContainer = styled.div``

export const SeeMore = styled.div`
  font-size: 14px;
  border-top: ${props => props.borderTop || '3px solid #f7f8f9'};
  margin-top: 1.5rem;
  padding-top: 1rem;
  text-align: center;
  color: #039be5;
  text-transform: none !important;
  width: 100%;
`

export const CardFeedback = styled(Card)`
  padding: 15px;
  width: 15%;
  display: flex;
  justify-content: center;
`

export const IconFeedback = styled(Icon)`
  font-size: 2.3rem;
`

export const CardFeedbackTabs = styled(Card)`
  padding: 8px 18px;
  margin-bottom: 10px;
  cursor: pointer;
`
export const FeedbackTabsContainer = styled(Card)`
  width: 100%;
  margin-top: 20px;
`
export const FeedbackTabsContent = styled.div`
  padding: 20px;
  padding-top: 0;
  background-color: #f7f8f9;
`
export const FeedbackTabs = styled(Tabs)`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
`
export const FeedbackTab = styled(Tab)`
  text-transform: none;
`
export const StyledTabs = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'rgb(246, 248, 249)',
  },
  indicator: {
    backgroundColor: '#039be5',
    height: '3px',
  },
})(Tabs)

export const TabsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
  position: sticky;
  top: 62px;
`

export const StyledTabsSurvey = withStyles({
  indicator: {backgroundColor: '#f6f8f9', height: '3px'},
})(Tabs)

export const StyledTab = withStyles({
  root: {
    boxShadow: 'none',
    textTtransform: 'capitalize',
    padding: '8px 20px',
    minWidth: '100px',
    fontSize: '13px',
    fontWeight: '500',
  },
})(Tab)

// export const StyledTab = styled(Tab)`
//   text-transform: capitalize;
//   font-family: ${props => props.theme.font};
//   padding: 5px 8px;
//   width: 30px;
// `

export const ArchiveContainer = styled.div`
  min-height: 75vh;
`
export const TitlePerforment = styled.div`
  width: auto;
  height: 20px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #055469;
`

export const ListArchiveContainer = styled.div`
  margin: 0 1.5rem;
  justify-content: space-between;
  padding-top: 0.5rem;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
`
export const DetailArchiveContainer = styled.div``

export const TitleArchive = styled.div``

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const InformationContainer = styled.div`
  padding-bottom: 24px;
`

// export const StyledLinear = styled(LinearProgress)`
//   border-radius: 22px;
//   margin: 5px 1rem 10px 0;
//   background: white;
//   border: 1px solid lightgrey;
//   height: 10px;
//   width: 80%;
// `
export const StyledLinear = withStyles({
  root: {
    borderRadius: 22,
    margin: '5px 1rem 10px 0',
    background: 'white',
    border: '1px solid lightgrey',
    height: 10,
    width: '80%',
  },
  // bar1Determinate: {
  //   background: '#4caf50',
  // },
})(LinearProgress)

export const TabContent = styled.div`
  padding-top: 2rem;
`
export const DescriptionTabContainer = styled.div``

export const DescriptionWrapper = styled.div``

export const AttachmentWrapper = styled.div`
  padding-top: 2rem;
`

export const AttachmentImg = styled.img`
  width: 30%;
`
export const ActivityTabContainer = styled.div``

export const CommentAvatar = styled(Avatar)`
  width: 77px;
  height: 77px;
`
export const CommentWrapper = styled.div`
  display: flex;
  padding-bottom: 1rem;
  align-items: center;
`

export const CommentWrapperAct = styled.div`
  display: flex;
  width: 100%;
  padding-right: 2rem;
`

export const CommentDetailWrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
`
export const CommentChildrenAvatar = styled(Avatar)`
  width: 35px;
  height: 35px;
`
export const BtnAttachment = styled(Button)`
  background: #3b86ff;
  text-transform: capitalize;
  margin: 1rem 1rem 0 0;
`
export const MainMultiraterContainer = styled.div``

export const ListMultiraterContainer = styled.div`
  margin-top: 1rem;
`
export const GoalContainer = styled(Grid)`
  background-color: #f7f8f9;
  margin: 0;
  max-height: 42rem;
  overflow-y: auto;
`
export const FormElement = styled.div`
  width: 50%;
`

export const FilterAssignWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
`

export const StyledExpansionSummaryAssign = styled(ExpansionPanelSummary).attrs(
  {
    classes: {content: 'content'},
  }
)`
  .content {
    align-items: center;
    justify-content: space-between;
  }
`
export const FormChildButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
  padding-right: 1.6rem;
  padding-bottom: 1rem;
`
export const FeedbackListContainer = styled.div`
  height: 260px;
  overflow-y: auto;
  padding-top: 10px;
`
export const CardSummaryPlain = styled(Card)`
  padding: 18px;
  width: 29%;
  margin-top: 1rem;
`

export const TitleHoverable = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  width: max-content;
  cursor: pointer;
  padding: 8px 0 16px;
`

export const IconTitle = styled(Icon)`
  font-size: 2.3rem;
  padding-right: 12px;
`

export const BlueMenuItem = styled(MenuItem)`
  color: #014a62;
`

export const StyledBorderedExpansion = styled(ExpansionPanel)`
  margin-top: 12px;
  border: 1px solid #a9a8a8;
  border-radius: 5px;
`

export const QuickFilter = styled.p`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  margin: 0 27px 0 0;
`
export const MyGoalsBtn = styled(Button)`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: #014a62;
  margin-right: 20px;
`
export const SpanMyGoals = styled.span`
  width: 100px;
  height: 15px;

  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`
export const SpanGoalType = styled.span`
  min-width: 100px;
  min-height: 15px;

  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`

export const DepartementGoalsBtn = styled(Button)`
  width: 150px;
  height: 35px;
  text-transform: initial;
  border-radius: 5px;
  background-color: #ffffff;
`

export const SpanDepartementGoals = styled.span`
  width: 150px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #014a62;
`
export const TitleContent = styled.p`
  width: auto;
  margin-left: 15px;
  height: 15px;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #014a62;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const TitleCardSummary = styled.p`
  width: 100%;
  height: 15px;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
`

export const TitleCardSummaryContent = styled.p`
  width: 100%;
  max-height: 40px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #055469;
`

export const GoalsListBtn = styled.div`
  padding: 4px 16px;
  font-size: 12px;
  display: inline;
  border-radius: 5px;
  line-height: 15px;
  font-family: 'Muli';
  color: #fff;
`

export const GoalTypeBtn = styled(Button)`
  padding: 4px 12px;
  margin-left: 5px;
  min-width: 85px;
  border-radius: 5px;
`

export const GoalProgressIndicator = styled.p`
  margin: 0px;
  text-align: right;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #707070;
`
export const CarouselCard = styled('div')`
  display: flex;
  overflow-x: auto;
`
export const GreyTypography = styled(Typography)`
  color: #a9a8a8;
`

export const TypeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 13px;
  padding-left: 16px;
  margin-right: 1rem;
`

export const RenderBadges = styled.div`
  font-family: ${props => props.theme.font};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  background-color: #ffffff;
  @media (max-width: 850px) {
    display: none;
  }
  margin-bottom: 30px;
  padding-bottom: 24px;
`
export const TextHelper = styled.p`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 25px;
`

export const LineClamp = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.lineClamp || '2'};
  margin: ${props => props.margin || '0px'};
`

export const WhiteModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

export const StyledMenus = styled(({...other}) => (
  <Menu classes={{list: 'list'}} {...other} />
))`
  & .list {
    min-width: 80px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  justify-content: left;
  color: #039be5;
`

export const BorderAddActivity = styled.div`
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 1rem;
`

export const ChipInformation = styled.div`
  background-color: ${props => props.bgColor || '#a9a8a8'};
  border: 1px solid ${props => props.bgColor || '#a9a8a8'};
  color: ${props => props.color || '#ffffff'};
  border-radius: 5px;
  padding: 5px;
  width: max-content;
`

export const ChipCascade = styled.div`
  background-color: #ffffff;
  border: 1px solid #a9a8a8;
  border-radius: 90px;
  padding: 8px 16px;
  max-width: max-content;
`

export const TextBlackBold = styled(Typography)`
  font-size: 13px;
  font-family: ${props => props.theme.font};
  font-weight: 600;
  color: #000000;
`

export const AttachmentHoverWrap = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0px 24px 12px 0px;
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  .icon {
    font-size: 33px;
  }
  .pdf {
    color: #ef4d5e;
  }
  .xls {
    color: #2c5a1a;
  }
  .ppt {
    color: #ef4d5e;
  }
  .doc {
    color: #039be5;
  }
  .img {
    width: 40px;
    max-width: 70px;
  }
  .file-name {
    margin-top: 11px;
    width: 80px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    p {
      margin: 0px;
      font-size: 12px;
    }
  }
  .hover {
    border-radius: 5px;
    height: 100%;
    width: 100%;
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.3);
    }
    .icon {
      color: #fff;
      cursor: pointer;
      font-size: 26px;
    }
    .mr {
      margin-right: 10px;
    }
  }
`

export const GenerateTooltip = withStyles(() => ({
  tooltip: {
    padding: '12px 14px',
    backgroundColor: props => props.bgColor || '#eff2f4',
    color: '#000',
    fontSize: 12,
    borderRadius: 5,
    boxShadow: props => props.boxShadow || '',
    maxWidth: 600,
  },
  arrow: {
    color: props => props.bgColor || '#eff2f4',
    backgroundColor: props => props.bgColor || '#eff2f4',
  },
}))(Tooltip)

export const WrapperPopupFieldItm = styled.div`
  margin-bottom: 23px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-family: Muli;
    font-size: 12px;
    color: #a9a8a8;
    margin-bottom: 15px;
  }

  .date {
    max-width: 170px;
    margin-right: 25px;
  }

  .MuiOutlinedInput-input {
    font-size: 12px;
  }
`

export const WrapperChoosePeriode = styled.div`
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    background-color: #f7f8f9;
    padding: 12px 30px;
    justify-content: space-between;
    align-items: center;
    .title {
      color: #333333;
      font-weight: 600;
      font-size: 20px;
      font-family: Muli;
    }
    .wrap-close {
      .icon-close {
        color: #333333;
        font-weight: 600;
      }
    }
  }

  .body {
    margin: 25px 30px;

    .title-period {
      font-size: 1rem;
      margin-bottom: 12px;
    }

    .radio-custom {
      margin-bottom: 14px;
    }

    .wrap-date-picker {
      margin-top: 10px;
      width: 100%;
    }

    .text-alert-notes {
      margin-left: 2px;
      color: #ffa000;
      font-family: Muli;
    }
  }

  .action-wrapper-modal-confirmation {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 30px 20px;
  }
`
