import gql from 'graphql-tag'

export const GET_STATUS_EXPORT_BALOON = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        filter: {_eq: $filter}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      status
      data_url
      date_added
    }
  }
`

export const GET_EXPORT_DISABLED_BUTTON = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        filter: {_eq: $filter}
        status: {_neq: "FAILED"}
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      date_added
    }
  }
`

export const GET_STATUS_EXPORT_BALOON_IS_NULL_FILTER = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        _not: {filter: {_has_key: "id", _is_null: false}}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      status
      data_url
      date_added
      filter
    }
  }
`

export const GET_EXPORT_DISABLED_BUTTON_IS_NULL_FILTER = gql`
  query($company: uuid, $table: String) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        status: {_neq: "FAILED"}
        filter: {_is_null: true}
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      date_added
    }
  }
`

export const GET_STATUS_EXPORT_BALOON_CONTAINS_FILTER = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        filter: {_contains: $filter}
        _or: [{viewed: {_eq: false}}, {status: {_eq: "WAITING"}}]
      }
      limit: 1
      order_by: [{id: desc}]
    ) {
      id
      status
      data_url
      date_added
    }
  }
`
export const GET_EXPORT_DISABLED_BUTTON_CONTAINS_FILTER = gql`
  query($company: uuid, $table: String, $filter: jsonb) {
    company_export_data_logs(
      where: {
        company: {_eq: $company}
        table_name: {_eq: $table}
        status: {_neq: "FAILED"}
        filter: {_contains: $filter}
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      date_added
    }
  }
`
