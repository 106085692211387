import React, {useState, useEffect} from 'react'
import NotificationPopup from '../../../shared-component/NotificationPopup'
import {useMutation} from '@apollo/react-hooks'
import {
  ADD_GENERAL_SQUAD,
  UPDATE_GENERAL_SQUAD_INFORMATION,
} from '../../../../graphql/mutations'
import AddEditSquadInformation from '../shared/AddEditSquadMember'
import {dateFormat} from '../../../../utils/helpers'
import {useSnackbar} from 'notistack'

const INITIAL_NOTIFICATION = {
  open: false,
  title: '',
  message: '',
  onClose: undefined,
  onConfirm: undefined,
}
const INITIAL_VALUES = {
  squadName: '',
  squadDescription: '',
  startDate: new Date(),
  endDate: new Date(),
}

const AddEditGeneralSquad = ({
  open,
  onClose,
  refetch,
  actionType = 'Add',
  squadId,
  squadName,
  squadDescription,
  startDate,
  endDate,
}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [isAllowOpenPopup, setIsAllowOpenPopup] = useState(true)
  const [notification, setNotification] = useState(INITIAL_NOTIFICATION)
  const [values, setValues] = useState(INITIAL_VALUES)

  useEffect(() => {
    if (open && actionType === 'Edit') {
      setValues({
        squadName,
        squadDescription,
        startDate,
        endDate,
      })
    }
  }, [open, actionType])

  const [addGeneralSquadMutation] = useMutation(ADD_GENERAL_SQUAD)
  const [editGeneralSquadMutation] = useMutation(
    UPDATE_GENERAL_SQUAD_INFORMATION
  )

  const addGeneralSquad = values => {
    const variables = {
      name: values.squadName,
      description: values.squadDescription,
      startdate: dateFormat({date: values.startDate, format: 'YYYY-MM-DD'}),
      enddate: dateFormat({date: values.endDate, format: 'YYYY-MM-DD'}),
    }

    addGeneralSquadMutation({variables})
      .then(() => {
        refetch()
        enqueueSnackbar('Add general squad success!', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Add general squad failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }
  const editGeneralSquad = values => {
    const variables = {
      id: parseInt(squadId),
      name: values.squadName,
      description: values.squadDescription,
      startdate: dateFormat({date: values.startDate, format: 'YYYY-MM-DD'}),
      enddate: dateFormat({date: values.endDate, format: 'YYYY-MM-DD'}),
    }
    editGeneralSquadMutation({variables})
      .then(() => {
        refetch()
        enqueueSnackbar('Edit general squad success!', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Edit general squad failed, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleClose = () => {
    setIsAllowOpenPopup(false)
    setNotification({
      open: true,
      title: 'Buang perubahan?',
      message: 'Apakah Anda yakin akan membuang perubahan yang belum disimpan?',
      onClose: () => {
        setIsAllowOpenPopup(true)
        setNotification(INITIAL_NOTIFICATION)
      },
      onConfirm: () => {
        setIsAllowOpenPopup(true)
        onClose()
        setNotification(INITIAL_NOTIFICATION)
        setValues(INITIAL_VALUES)
      },
    })
  }

  const handleSave = values => {
    setIsAllowOpenPopup(false)
    setNotification({
      open: true,
      title: 'Simpan Perubahan?',
      message: 'Apakah anda yakin untuk menyimpan perubahan?',
      onClose: () => {
        setIsAllowOpenPopup(true)
        setNotification(INITIAL_NOTIFICATION)
      },
      onConfirm: () => {
        setIsAllowOpenPopup(true)
        onClose()
        setNotification(INITIAL_NOTIFICATION)
        setValues(INITIAL_VALUES)
        if (actionType === 'Edit') {
          editGeneralSquad(values)
        } else {
          addGeneralSquad(values)
        }
      },
    })
  }

  const title = {
    Add: 'Menambahkan Tim Kerja',
    Edit: 'Ubah Informasi Tim Kerja',
  }

  return (
    <>
      <AddEditSquadInformation
        open={open && isAllowOpenPopup}
        values={values}
        setValues={setValues}
        onClose={handleClose}
        onSave={handleSave}
        title={title[actionType]}
        type="collaboration-squad"
      />
      <NotificationPopup
        open={notification.open}
        title={notification.title}
        message={notification.message}
        onConfirm={notification.onConfirm}
        onClose={notification.onClose}
        onCancel={notification.onClose}
      />
    </>
  )
}

export default AddEditGeneralSquad
