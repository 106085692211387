import {makeStyles, Tab, Tabs} from '@material-ui/core'
import React, {useState} from 'react'
import MyCompany from './committee-task-component/MyCompanyComponent'
// import OtherCompanyComponent from './committee-task-component/OtherCompanyComponent'
import {
  IconTitle,
  TitleContainer,
  TitleHoverable,
  TitlePerforment,
} from './PerformanceStyles'

const useStyles = makeStyles(() => ({
  tabsRoot: {background: '#eff2f4', margin: '20px -24px', marginBottom: 26},
  tabsIndicator: {height: 4},
  tabRoot: {fontSize: 14},
}))

const CommitteeTask = () => {
  const classes = useStyles()
  const [tabsValue, setTabsValue] = useState(0)
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }
  return (
    <>
      <TitleContainer>
        <TitleHoverable style={{cursor: 'default'}}>
          <IconTitle>assignment_ind</IconTitle>
          <TitlePerforment style={{width: 'auto'}} variant="h6">
            Evaluasi Kinerja
          </TitlePerforment>
        </TitleHoverable>
      </TitleContainer>
      <Tabs
        value={tabsValue}
        onChange={handleChangeTabs}
        indicatorColor="secondary"
        classes={{
          root: classes.tabsRoot,
          indicator: classes.tabsIndicator,
        }}
      >
        <Tab label="Instansi saya" className={classes.tabRoot} />
        {/* <Tab label="Other Company" className={classes.tabRoot} /> */}
      </Tabs>

      {tabsValue === 0 && <MyCompany />}
    </>
  )
}

export default CommitteeTask
