import React from 'react'
import {InputAdornment, makeStyles, Paper, TextField} from '@material-ui/core'
import Search from '@material-ui/icons/Search'

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 30px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  rootHeader: {
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleHeader: {display: 'flex'},
  rootButton: {padding: '12px 32px'},
  rootSearch: {marginBottom: 30},
  labelButton: {fontWeight: '600'},
  inputSearch: {fontSize: 12},
  iconWrapper: {marginRight: 12, display: 'flex', alignItems: 'center'},
  title: {
    margin: 0,
    fontSize: 24,
    fontWeight: '600',
  },
}))

export default function PaperSearcher(props) {
  const {...child} = props
  const classes = useStyles()

  return (
    <Paper classes={{root: classes.root}}>
      <Header {...props} />
      <SearchBar {...props} />
      <div {...child} />
    </Paper>
  )
}

const Header = props => {
  const {title, icon, buttonConfig} = props
  const classes = useStyles()

  const defaultProps = {
    classes: {root: classes.rootButton, label: classes.labelButton},
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  }

  return (
    <div className={classes.rootHeader}>
      <div className={classes.titleHeader}>
        {icon && <div className={classes.iconWrapper}>{icon}</div>}
        <h2 className={classes.title}>{title}</h2>
      </div>
      <div>{buttonConfig && buttonConfig(defaultProps)}</div>
    </div>
  )
}

const SearchBar = ({search, setSearch}) => {
  const classes = useStyles()

  return (
    <TextField
      value={search}
      fullWidth
      size="small"
      variant="outlined"
      className={classes.rootSearch}
      placeholder="Cari..."
      onChange={event => setSearch(event.target.value)}
      InputProps={{
        classes: {input: classes.inputSearch},
        endAdornment: (
          <InputAdornment className="ml-0" position="end">
            <Search style={{color: '#014a62', width: 16, height: 16}} />
          </InputAdornment>
        ),
      }}
    />
  )
}
