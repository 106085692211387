import {
  GET_FILTER_ASPECT,
  GET_FILTER_LABEL,
  GET_FILTER_PERSPECTIVE,
  GET_FILTER_PLACEMENT,
  GET_FILTER_SCORING_NATURE,
  GET_FILTER_WEIGHT_UNIT,
} from '../../../graphql/queries'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import {COMPANY_ID} from '../../../utils/globals'
import {progress_status} from '../goal-components/TabDialog/constant'
import {PRIORITY} from '../goal-components/constant'

export const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}
export const transformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const LIMIT_TRESHOLD = 6
const DEFAULT_OPTIONS = {
  context: {
    headers: {
      'X-Hasura-Role': 'user',
    },
  },
}

export const listFilter = [
  {
    name: 'Tugas Pelapor',
    fieldName: 'reporter',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Tugas Pembuat',
    fieldName: 'creator',
    type: 'checkbox',
    options: {
      checkboxType: 'placement',
      fetch: {
        query: GET_FILTER_PLACEMENT,
        options: DEFAULT_OPTIONS,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.people_work_placements) {
            const _data = data.people_work_placements.map(
              ({
                global_user: {id, avatar, name},
                company_job_profile: {title},
              }) => {
                return {
                  value: id,
                  label: {
                    avatar,
                    name,
                    title,
                  },
                }
              }
            )
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: `Tipe ${GLOBAL_GLOSSARY.performance.Task}`,
    fieldName: 'type',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'Personal - 2',
          label: `Pribadi - ${GLOBAL_GLOSSARY.performance.Quantitative}`,
        },
        {
          value: 'Personal - 1',
          label: `Pribadi - ${GLOBAL_GLOSSARY.performance.Qualitative}`,
        },
        {
          value: 'Company - 2',
          label: `Unit - ${GLOBAL_GLOSSARY.performance.Quantitative}`,
        },
        {
          value: 'Company - 1',
          label: `Unit - ${GLOBAL_GLOSSARY.performance.Qualitative}`,
        },
      ],
    },
  },
  {
    name: 'Prioritas',
    fieldName: 'priority',
    type: 'checkbox',
    options: {
      list: [
        {
          value: 'HIGH',
          label: PRIORITY.HIGH,
        },
        {
          value: 'MEDIUM',
          label: PRIORITY.MEDIUM,
        },
        {
          value: 'LOW',
          label: PRIORITY.LOW,
        },
      ],
    },
  },
  {
    name: 'Persentase',
    fieldName: 'percentage',
    type: 'checkbox',
    options: {
      list: [
        {
          value: '100.1-9999',
          label: progress_status.Exceed,
        },
        {
          value: '100-100',
          label: progress_status.Meet,
        },
        {
          value: '0-99.9',
          label: progress_status.Below,
        },
      ],
    },
  },
  {
    name: 'Periode',
    fieldName: 'period',
    type: 'date',
  },
  {
    name: `Aspek ${GLOBAL_GLOSSARY.performance.Goal_short}`,
    fieldName: 'aspect',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_ASPECT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_aspects) {
            const _data = data.kpi_aspects.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Perspektif',
    fieldName: 'perspective',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_PERSPECTIVE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_perspectives) {
            const _data = data.kpi_perspectives.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Sifat Penilaian',
    fieldName: 'scoringNature',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_SCORING_NATURE,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_scoring_natures) {
            const _data = data.kpi_scoring_natures.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: GLOBAL_GLOSSARY.performance['Weight Unit'],
    fieldName: 'weightUnit',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_WEIGHT_UNIT,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.kpi_weight_units) {
            const _data = data.kpi_weight_units.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
  {
    name: 'Label',
    fieldName: 'label',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_FILTER_LABEL,
        getVariables: ({search, _nin}) => {
          return {
            limit: LIMIT_TRESHOLD,
            company: COMPANY_ID,
            search: search,
            nin: _nin,
          }
        },
        setData: data => {
          if (data?.performance_groups) {
            const _data = data.performance_groups.map(({id, name}) => {
              return {
                value: id,
                label: name,
              }
            })
            return [_data, data.total.aggregate.count]
          }
        },
      },
    },
  },
]

export const getDataVariableGoals = filterData => {
  const variables = {
    filterOrg: {
      _in:
        filterData && filterData.include && filterData.include.organizationUnit,
      _nin:
        filterData && filterData.exclude && filterData.exclude.organizationUnit,
    },
    filterPosition: {
      _in: filterData && filterData.include && filterData.include.position,
      _nin: filterData && filterData.exclude && filterData.exclude.position,
    },
  }
  return variables
}
