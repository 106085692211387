import React, {useState} from 'react'

// style
import {
  ArchiveContainer,
  FlexBetween,
  FlexEnd,
  TitlePerforment,
} from './PerformanceStyles'

// styles Archive
import useStyles from './archive-components/ArchiveStyles'

// material
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ListArchivment from './archive-components/ListArchivment'
import {
  MenuItem,
  InputAdornment,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import {GET_LIST_ARCHIVEMENT} from '../../graphql/queries/archive/getListArchivement.query'
import {useQuery} from '@apollo/react-hooks'
import {Filter} from '@smartasn/wlb-utils-components'
import {
  anchorOrigin,
  listFilter,
  transformOrigin,
} from './archive-components/ArchiveFilterData'
import moment from 'moment'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import NoDataListComponent from '../shared-component/NoDataListComponent'

export default function Archive() {
  const classes = useStyles()

  const [filterData, setFilterData] = useState({})
  const [limit, setLimit] = useState(12)

  const {data, error} = useQuery(GET_LIST_ARCHIVEMENT, {
    variables: {
      user: USER_ID,
      company: COMPANY_ID,
      order: filterData.order || 'desc',
      search: `%${filterData.search || ''}%`,
      status: 'ARCHIVED',
      isCompany: true,
      isPersonal: true,
      limit: limit,
      ...filterData.include,
    },
  })

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'type') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'percentage') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include.progressPersenFrom = Math.min(...tempData)
            include.progressPersenTo = Math.max(...tempData)
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include.dateFrom = moment(value.from).format(format)
        include.dateTo = moment(value.to).format(format)
      }

      if (key !== 'percentage' && key !== 'period') {
        include[key] = value
      }
    }
    setFilterData({
      ...filterData,
      include,
    })
  }

  const handleChangeFilter = event => {
    setFilterData({
      ...filterData,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <ArchiveContainer>
      <TitlePerforment variant="h6">Arsip</TitlePerforment>
      <FlexBetween>
        <TextField
          onChange={handleChangeFilter}
          value={filterData.order_filter}
          select
          label="Urutkan"
          variant="outlined"
          className={classes.dropdownArchive}
          inputProps={{
            name: 'order',
          }}
        >
          <MenuItem value="desc">Terbaru</MenuItem>
          <MenuItem value="asc">Terlama</MenuItem>
        </TextField>

        <TextField
          placeholder="Cari"
          className={classes.textSearch}
          onChange={handleChangeFilter}
          value={filterData.search_filter}
          name="search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FlexBetween>
      <FlexEnd>
        <Filter
          includeExclude={false}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id="filter-sumamry-achievement"
          onApply={handleApply}
          listFilter={listFilter}
          language="indonesian"
        >
          <Typography className={classes.textFilterNew}>Filter</Typography>
        </Filter>
      </FlexEnd>
      {data?.total.aggregate.count > 0 ? (
        <ListArchivment data={data} setLimit={setLimit} limit={limit} />
      ) : data === undefined && error === undefined ? (
        <CircularProgress />
      ) : (
        <NoDataListComponent search={filterData} />
      )}
    </ArchiveContainer>
  )
}
