import gql from 'graphql-tag'

export const GET_TOTAL_SUBORDINATE_SQUAD = gql`
  query($user: uuid!) {
    people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          job_profile_supervisor: {
            people_work_placements: {
              user: {_eq: $user}
              status: {_eq: "ACTIVE"}
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_SUBORDINATE_SQUAD_LIST = gql`
  query($user: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      placement_fields
    }
  }
`

export const GET_DETAIL_SUBORDINATE_SQUAD = gql`
  query($user: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      placement_fields
    }
  }
`

export const GET_LIST_SUGGESTION_SUBORDINATE_SQUAD_MEMBER = gql`
  query(
    $company: uuid
    $user: uuid
    $search: String
    $offset: Int
    $limit: Int
    $nin: [Int!]
  ) {
    people_work_placements(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        user: {_neq: $user}
        _or: [
          {
            _and: [
              {_not: {performance_teams: {}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
              {
                company_job_profile: {
                  _or: [
                    {
                      job_profile_supervisor: {
                        _or: [
                          {
                            _not: {
                              people_work_placements: {
                                status: {_eq: "ACTIVE"}
                                user: {_eq: $user}
                              }
                            }
                          }
                          {_not: {people_work_placements: {}}}
                        ]
                      }
                    }
                    {_not: {job_profile_supervisor: {}}}
                  ]
                }
              }
            ]
          }
          {
            _and: [
              {
                _not: {
                  performance_teams: {
                    added_by: {_eq: $user}
                    group: {_is_null: true}
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
              {
                company_job_profile: {
                  _or: [
                    {
                      job_profile_supervisor: {
                        _or: [
                          {
                            _not: {
                              people_work_placements: {
                                status: {_eq: "ACTIVE"}
                                user: {_eq: $user}
                              }
                            }
                          }
                          {_not: {people_work_placements: {}}}
                        ]
                      }
                    }
                    {_not: {job_profile_supervisor: {}}}
                  ]
                }
              }
            ]
          }
        ]
      }
      distinct_on: [user]
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_MEMBER_SUBORDINATE_SQUAD = gql`
  query listMySquadSubOrdinate(
    $company: uuid
    $user: uuid
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {
            _and: [
              {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
          {
            _and: [
              {
                performance_teams: {
                  added_by: {_eq: $user}
                  group: {_is_null: true}
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {
            _and: [
              {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
          {
            _and: [
              {
                performance_teams: {
                  added_by: {_eq: $user}
                  group: {_is_null: true}
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        talent_assignments(
          where: {
            talentVersionByTalentVersion: {
              status: {_eq: "COMPLETED"}
              visibility: {_eq: "PUBLISH"}
              classification_type: {_eq: "PERFORMANCE"}
            }
            badge_classification: {_is_null: false}
          }
          order_by: [{id: desc}]
          limit: 1
        ) {
          id
          talent_box {
            id
            name
            badge
          }
          talent_score
          talentVersionByTalentVersion {
            id
            name
            start_date
            end_date
          }
          people_work_placement {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
      company_job_profile {
        id
        title
        job_profile_supervisor {
          people_work_placements_aggregate(
            where: {status: {_eq: "ACTIVE"}, user: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
