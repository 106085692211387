import gql from 'graphql-tag'

export const ADD_NEW_GOALS = gql`
  mutation addGoals($data: [performance_goals_insert_input!]!) {
    insert_performance_goals(objects: $data) {
      affected_rows
    }
  }
`

export const CREATE_OWN_GOAL = isCreate => gql`
  mutation(
    $name: String
    $type: Int
    $group: uuid
    $description: String
    $target: float8
    #$target_stretch: float8
    $weight: float8
    $startdate: date
    $enddate: date
    $priority: enum_mpp_priority!
    $visibility: String
    $status: String
    $attachment: jsonb
    $metrix: String
    $weightUnit: Int
    $perspective: Int
    $aspect: Int
    $scoringNature: Int
    ${isCreate ? '$cascading: [performance_cascadings_insert_input!]!' : ''}
  ) {
    insert_performance_goals(
      objects: [
        {
          name: $name
          group: $group
          description: $description
          target: $target
          #target_stretch: $target_stretch
          weight: $weight
          startdate: $startdate
          enddate: $enddate
          priority: $priority
          visibility: $visibility
          status: $status
          attachment: $attachment
          target_unit: $weightUnit
          kpi_list: {
            data: {
              code: $name
              name: $name
              type: $type
              formula: $metrix
              description: $name
              weight: $weightUnit
              prespective: $perspective
              aspect: $aspect
              scoring: $scoringNature
            }
          }
          ${isCreate ? 'performance_cascadings: {data: $cascading}' : ''}
        }
      ]
    ) {
      affected_rows
    }
  }
`

export const ADD_CASCADE = gql`
  mutation addcascading($data: [performance_cascadings_insert_input!]!) {
    insert_performance_cascadings(objects: $data) {
      affected_rows
    }
  }
`
