import React, {useState, useEffect, useRef} from 'react'
import {
  ModalHeaderWhite,
  StyledDialog,
  ModalContent,
  FromButton,
} from './ModalPerformanceStyles'

import {FlexBetween, Center, IconFeedback} from '../PerformanceStyles'

import {PmdGrey} from '../../../components/typography/TypographyStyles'
import {GET_LIST_RESPONDENT} from '../../../graphql/queries/task/getListSendTo.query'
import {ADD_GIVE_OGV} from '../../../graphql/mutations/multirater/addGiveOGF.query'
import {ADD_REQ_OGV} from '../../../graphql/mutations/multirater/addRequestOGF.query'
import {withRouter} from 'react-router-dom'
import {useMutation, useApolloClient} from '@apollo/react-hooks'

import {
  Card,
  Icon,
  IconButton,
  makeStyles,
  Typography,
  Avatar,
  Button,
  TextField,
  Snackbar,
} from '@material-ui/core'

import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import {USER_ID, COMPANY_ID} from '../../../utils/globals'

function ModalMutationFeedBack(props) {
  const client = useApolloClient()
  const selectInputRef = useRef()
  const useStyles = makeStyles(() => ({
    list: {display: 'flex', margin: '10px 0', cursor: 'pointer'},
    textGrey: {color: '#a9a8a8'},
    spacer: {marginRight: 10},
    selectedEmp: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cardEmp: {
      width: '100%',
      margin: '10px 0',
      padding: 9,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #a9a8a8',
    },
    cardIconFeed: {
      width: '54px',
      height: '54px',
      margin: 10,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      border: 'solid 1px #a9a8a8',
    },
  }))

  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      outline: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#055469',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
  }

  const classes = useStyles()
  const {open, handleClose, type, params, dataActivity, refetch = null} = props
  const isDataActEmpty = Object.keys(dataActivity ?? {}).length === 0
  const [selectedRep, setSelectedRep] = useState([])
  const [impression, setImpression] = useState('')
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')

  const [states, setStates] = React.useState({
    openSB: false,
    message: '',
  })

  const {message, openSB} = states
  let Query = ADD_GIVE_OGV
  if (type !== 'Memberi Umpan Balik') {
    Query = ADD_REQ_OGV
  }
  const [mutationFB] = useMutation(Query)

  useEffect(() => {
    if (!isDataActEmpty) {
      setSelectedRep(dataActivity?.receiver)
      setTopic(dataActivity?.topic)
    }
  }, [isDataActEmpty, dataActivity])

  const resetRespondenValue = () => {
    selectInputRef &&
      selectInputRef.current &&
      selectInputRef.current.select &&
      selectInputRef.current.select.select &&
      selectInputRef.current.select.select.clearValue()
  }

  useEffect(() => resetRespondenValue(), [selectedRep, selectInputRef])

  const [dataImpression] = useState([
    {
      value: 'DISAPPOINTED',
      icon: 'sentiment_very_dissatisfied',
      color: '#ef4d5e',
      checked: false,
    },
    {
      value: 'ORDINARY',
      icon: 'sentiment_neutral',
      color: '#ffa000',
      checked: false,
    },
    {
      value: 'SATISFIED',
      icon: 'sentiment_satisfied_alt',
      color: '#4caf50',
      checked: false,
    },
  ])

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon>search</Icon>
      </components.DropdownIndicator>
    )
  }
  const [checked, setChecked] = React.useState(false)

  // const handleChange = event => {
  //   setChecked(event.target.checked)
  // }

  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={classes.spacer}>
          <Avatar src={option.avatar} alt={option.value}></Avatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body2">{option.value}</Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {/* {option.organization} */}
          </Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.pos}
          </Typography>
        </div>
        {type === 'Meminta Umpan Balik' && (
          <Icon className={classes.textGrey}>add</Icon>
        )}
      </div>
    )
  }

  const handleChangeRep = (val, i) => {
    const dataRep = {idx: i, ...val}
    const isDuplicate = _.some(selectedRep, dataRep)
    const newData =
      type === 'Meminta Umpan Balik' ? [...selectedRep, dataRep] : [dataRep]
    if (!isDuplicate) {
      // only add data that has `label`
      setSelectedRep(newData.filter(({label}) => label))
    }
  }
  const handleDel = (idTask, name) => {
    const aryName = selectedRep

    const filter = {
      idx: idTask,
      value: name,
    }
    const wbDel = aryName.filter(function(item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] !== filter[key]) return false
      }
      return true
    })
    const arrAfterDel = aryName.filter(e => e !== wbDel[0])
    setSelectedRep(arrAfterDel)
  }

  const handleImpres = value => {
    const timpres = dataImpression
    timpres.find(val => val.value === value).checked = true
    timpres
      .filter(val => val.value !== value)
      .map(row => {
        row.checked = false
      })

    setImpression(value)
  }
  const handleChangeTopic = event => {
    setTopic(event.target.value)
  }
  const handleChangeDesc = event => {
    setDescription(event.target.value)
  }
  const handleReset = () => {
    setDescription('')
    setTopic('')
    setSelectedRep([])
    setChecked(false)

    const timpres = dataImpression
    timpres
      .filter(val => val.checked === true)
      .map(row => {
        row.checked = false
      })
  }

  const handleCloseModal = () => {
    handleReset()
    handleClose()
  }
  const handleSave = () => {
    const object = []
    let dataSave = selectedRep
    if (params) {
      dataSave = [params]
    }
    if (type === 'Memberi Umpan Balik') {
      dataSave.map(res => {
        return object.push({
          topic: topic,
          ongoing_to: res.label,
          description: description,
          summary: impression,
          type_from: res.type_from,
          type_to: res.type_to,
          request_id: res.request_id,
          is_anonymous: checked,
        })
      })
    } else {
      dataSave.map(res => {
        return object.push({
          topic: topic,
          request_to: res.label,
          description: description,
          type_from: res.type_from,
          type_to: res.type_to,
        })
      })
    }
    if (
      object.length === 0 ||
      description === '' ||
      topic === '' ||
      (type === 'Memberi Umpan Balik' && impression === '')
    ) {
      setStates({openSB: true, message: 'required fields are empty'})
    } else {
      const message =
        type === 'Memberi Umpan Balik'
          ? 'Berhasil memberi umpan balik'
          : 'Berhasil mengirim permintaan umpan balik'

      mutationFB({variables: {object}})
        .then(() => {
          if (refetch) {
            refetch()
          }
          setStates({openSB: true, message})
          setTimeout(() => handleCloseModal(), 1000)
          !isDataActEmpty &&
            props.history.push({
              pathname: '/survey',
            })
        })
        .catch(() => {
          setStates({
            openSB: true,
            message: 'Update data error, please try again',
          })
        })
    }

    setTimeout(() => setStates({openSB: false}), 3000)
  }

  function handleCloseSnackbar() {
    setStates({...states, open: false})
  }

  const loadOptions = async search => {
    const {data} = await client.query({
      query: GET_LIST_RESPONDENT,
      fetchPolicy: 'no-cache',
      variables: {
        user: USER_ID,
        company: COMPANY_ID,
        search: `%${search}%`,
      },
    })

    return data.people_work_placements.map(res => {
      let obj = {
        type_from: 'FEEDBACK',
        type_to: 'FEEDBACK',
      }
      if (
        res?.company_job_profile?.job_profile_supervisor
          ?.people_work_placements_aggregate.aggregate.count > 0
      ) {
        obj = {
          type_from: 'INSIGHT',
          type_to: 'SUBORDINATE',
        }
      } else if (
        res?.company_job_profile?.job_profile_subordinate_aggregate?.aggregate
          .count > 0
      ) {
        obj = {
          type_from: 'SUBORDINATE',
          type_to: 'INSIGHT',
        }
      }

      return {
        ...obj,
        label: res.global_user?.id,
        value: res.global_user?.name,
        request_id: null,
        avatar: res.global_user?.avatar,
        pos: res.company_job_profile?.title,
      }
    })
  }
  return (
    <StyledDialog
      onClose={handleCloseModal}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="md"
      scroll="body"
    >
      <ModalHeaderWhite>
        <FlexBetween>
          <textTitle>{type}</textTitle>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeaderWhite>
      <ModalContent>
        <div style={{width: '573px'}}>
          {params ? null : (
            <>
              <PmdGrey>
                {type === 'Meminta Umpan Balik' ? 'Responden' : 'Penerima'}
              </PmdGrey>
              <AsyncSelect
                placeholder="Pilih..."
                components={{
                  DropdownIndicator,
                  SingleValue: () => 'Pilih...',
                }}
                noOptionsMessage={() => 'Tidak ada pilihan'}
                getOptionLabel={empMenu}
                defaultOptions
                loadOptions={loadOptions}
                onChange={value => handleChangeRep(value, 0)}
                isDisabled={!isDataActEmpty}
                styles={customSelect}
                ref={selectInputRef}
              />
            </>
          )}
          <div className={classes.selectedEmp}>
            {selectedRep
              ?.filter(v => v.idx === 0)
              .map((option, i) => {
                return (
                  <Card key={i} className={classes.cardEmp} elevation={0}>
                    <div className={classes.spacer}>
                      <Avatar src={option.avatar} alt={option.value}></Avatar>
                    </div>
                    <FlexBetween>
                      {' '}
                      <div>
                        <Typography variant="body2">{option.value}</Typography>
                        <Typography
                          variant="body2"
                          className={classes.textGrey}
                        >
                          {option.pos}
                        </Typography>
                      </div>
                      <Icon
                        className={classes.textGrey}
                        style={{cursor: 'pointer'}}
                        onClick={() => handleDel(option.idx, option.value)}
                      >
                        close
                      </Icon>
                    </FlexBetween>
                  </Card>
                )
              })}
          </div>

          <div style={{marginTop: '20px'}}>
            <PmdGrey>Topik</PmdGrey>
            <TextField
              id="standard-full-width"
              variant="outlined"
              value={topic}
              onChange={handleChangeTopic}
              style={{
                margin: '0 8 0 8',
                borderRadius: '5px',
                backgroundColor: '#ffffff',
              }}
              disabled={params}
              // placeholder="Placeholder"
              fullWidth
            />
          </div>

          <div style={{marginTop: '20px'}}>
            <PmdGrey>Komentar</PmdGrey>
            <TextField
              id="standard-full-width"
              variant="outlined"
              style={{
                margin: '0 8 0 8',
                borderRadius: '5px',
                border: 'solid 1px #a9a8a8',
                backgroundColor: '#ffffff',
              }}
              value={description}
              onChange={handleChangeDesc}
              // placeholder="Placeholder"
              fullWidth
              multiline={true}
              rows={2}
              rowsMax={4}
            />
          </div>

          {type === 'Memberi Umpan Balik' && (
            <div style={{marginTop: '20px'}}>
              <PmdGrey>Kesan</PmdGrey>
              <div className={classes.selectedEmp}>
                {dataImpression.map((row, key) => (
                  <Card
                    key={key}
                    className={classes.cardIconFeed}
                    elevation={0}
                    onClick={() => handleImpres(row.value)}
                    style={{margin: 5, cursor: 'pointer'}}
                  >
                    <Center
                      style={{
                        padding: '6px 3px 0px 9px',
                        color: `${row.checked ? row.color : ''}`,
                      }}
                    >
                      <IconFeedback>{row.icon}</IconFeedback>
                    </Center>
                  </Card>
                ))}
              </div>
            </div>
          )}

          {/* {type === 'Memberi Umpan Balik' && (
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    value="checkedB"
                    color="primary"
                  />
                }
                label="Send Feedback as anonymous"
              />
            </div>
          )} */}
          <FromButton
            style={{
              paddingRight: '0px !important',
              paddingBottom: '0px !important',
            }}
          >
            <Button
              size="sm"
              style={{textTransform: 'none'}}
              onClick={handleCloseModal}
            >
              Batal
            </Button>
            <Button
              style={{
                textTransform: 'none',
                backgroundColor: '#014a62',
              }}
              variant="contained"
              color="secondary"
              size="sm"
              onClick={handleSave}
              // disabled={undefinedChecker(taskChecked)}
            >
              Konfirmasi
            </Button>
          </FromButton>
        </div>
      </ModalContent>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={1500}
        open={openSB}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
      />
    </StyledDialog>
  )
}
export default withRouter(ModalMutationFeedBack)
