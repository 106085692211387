import React, {Fragment, useState} from 'react'
import {ModalContent} from '../../detail-modal/ModalPerformanceStyles'
import {
  StyledExpansionSummaryAssign,
  FormChildButton,
  FlexCenter,
  StyledBorderedExpansion,
} from '../../PerformanceStyles'
import {
  Pmd,
  PsmGrey,
  PmdGrey,
} from '../../../../components/typography/TypographyStyles'
import {StyledSmallButton} from '../../../../components/button/ButtonStyles'

import {
  Icon,
  ExpansionPanelDetails,
  Button,
  TextField,
  makeStyles,
  Radio,
  Typography,
  Avatar,
  Card,
  Snackbar,
  Paper,
  InputAdornment,
} from '@material-ui/core'
import {SwitchValue} from '../../../../components/color-switch/ColorSwitcher'

import Select, {components} from 'react-select'
import {ChromePicker} from 'react-color'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  GET_LIST_GROUP,
  GET_LIST_EMPLOYEE,
  GET_LIST_ASSIGN_TASK,
} from '../../../../graphql/queries'
import {
  ASSIGN_TASK,
  ADD_NEW_GROUP,
  UPDATE_GROUP_TASK,
} from '../../../../graphql/mutations'
import _ from 'lodash'
import {COMPANY_ID} from '../../../../utils/globals'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import LoadingComponent from '../../../../components/loading/LoadingComponent'

function ModalAssignTaskFinal(props) {
  const customStyle = {
    flexExpansion: {
      display: 'flex',
    },
    flexExpansion2: {
      display: 'flex',
      flexWrap: 'wrap',
      width: 350,
    },
    PR: {
      paddingRight: '3rem',
    },
    pR: {
      paddingRight: '1.5rem',
    },
    buttonSpacer: {
      marginRight: 12,
    },
    empContainer: {
      paddingTop: 16,
    },
  }

  const useStyles = makeStyles(() => ({
    outlinedInput: {
      padding: '10px 14px',
    },
    radioRoot: {padding: 0},
    icon: {
      borderRadius: 5,
      padding: 10,
    },
    checkedIcon: {
      borderRadius: 5,
      color: '#fff',
    },
    list: {display: 'flex', margin: '10px 0'},
    textGrey: {color: '#a9a8a8'},
    spacer: {marginRight: 10},
    selectedEmp: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    cardEmp: {
      width: '28.5%',
      margin: 10,
      padding: 9,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #a9a8a8',
    },
    flexAbsolute: {
      display: 'flex',
      position: 'absolute',
      zIndex: 1,
    },
  }))

  const classes = useStyles()

  const {item, resetSelectedTask, handleClose, handleClickBack} = props
  const idObjectAssignTask = item && item.map(data => data.id)
  const {
    data: dataListAssign,
    loading: loadingListAssign,
    error: errorListAssign,
  } = useQuery(GET_LIST_ASSIGN_TASK, {
    variables: {
      id: idObjectAssignTask,
    },
    skip: !item,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  const [label, setLabel] = useState(false)
  const [idLabel, setIdLabel] = useState('')
  const [selectedRep, setSelectedRep] = useState([])
  const [selectedGroup, setSelectedGroup] = useState([])
  const [color, setColor] = useState('#055469')
  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])

  const [labelName, setLabelName] = useState('')
  const [colorPicker, setColorPicker] = useState(false)
  const [states, setStates] = useState({
    open: false,
    message: '',
  })

  const [searchEmp, setSearchEmp] = useState('')

  const {open, message} = states

  const handleAddLabel = i => {
    setLabel(true)
    setIdLabel(i)
  }

  const handleChangeRep = (val, i) => {
    const dataRep = {idx: i, ...val}
    const isDuplicate = _.some(selectedRep, dataRep)
    if (!isDuplicate) {
      setSelectedRep(oldArray => [...oldArray, dataRep])
    }
  }

  const handleChangeGroup = (values, i) => {
    const multipleValue = {idParent: i, ...values}

    updateGroup({variables: {idGroup: multipleValue.idGroup, idTask: i}})
      .then(() => {
        setStates({open: true, message: 'Saved'})
        setSelectedGroup(oldArray => {
          const arr = [...oldArray, multipleValue]
          arr.reverse()
          const revArr = arr
          const newValue = _.uniqBy(revArr, 'idParent')

          return newValue
        })
      })
      .catch(() => {
        // console.log(e)
        setStates({
          open: true,
          message: 'Update data error, please try again',
        })
      })
  }

  const handleChooseColor = event => {
    setColor(event.target.value)
  }

  const handleLabelName = event => {
    setLabelName(event.target.value)
  }

  const handlePicker = () => {
    setColorPicker(true)
  }

  const handleChangePicker = color => {
    setColor(color.hex)
  }
  const customSelect = {
    control: provided => ({
      ...provided,
      width: '100%',
      outline: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    multiValue: base => ({
      ...base,
      backgroundColor: '#055469',
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
    menu: base => ({
      ...base,
      maxWidth: '100%',
    }),
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon>search</Icon>
      </components.DropdownIndicator>
    )
  }

  const groupMenu = option => {
    return (
      <FlexCenter>
        <div
          style={{
            background: option.color,
            width: 0,
            borderRadius: 5,
            padding: 10,
            marginRight: 5,
          }}
        ></div>
        <PsmGrey>{option.label}</PsmGrey>
      </FlexCenter>
    )
  }

  const empMenu = option => {
    return (
      <div className={classes.list}>
        <div className={classes.spacer}>
          <Avatar src={option.avatar} alt={option.value}></Avatar>
        </div>
        <div style={{width: '100%'}}>
          <Typography variant="body2">{option.value}</Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.organization}
          </Typography>
          <Typography variant="body2" className={classes.textGrey}>
            {option.pos}
          </Typography>
        </div>
        <Icon className={classes.textGrey}>add</Icon>
      </div>
    )
  }

  const {
    data: dataGroup,
    loading: loadingGroup,
    error: errorGroup,
    refetch: refetchGroup,
  } = useQuery(GET_LIST_GROUP)
  const {data: dataEmp, error: errorEmp, loading: loadingEmp} = useQuery(
    GET_LIST_EMPLOYEE,
    {
      variables: {
        search: `%${searchEmp}%`,
        limit: 30,
        company: COMPANY_ID,
      },
    }
  )

  const [assignTask] = useMutation(ASSIGN_TASK)
  const [addGroup] = useMutation(ADD_NEW_GROUP)
  const [updateGroup] = useMutation(UPDATE_GROUP_TASK)

  if (loadingGroup || loadingListAssign) {
    return <LoadingComponent />
  }

  if (errorGroup || errorEmp || errorListAssign) {
    console.error({
      errorGroup,
      errorEmp,
      errorListAssign,
    })
    return 'Something went wrong.'
  }

  const _item = dataListAssign.performance_tasks

  const options = dataGroup.performance_groups.map(res => {
    return {idGroup: res.id, value: res.name, label: res.name, color: res.color}
  })

  const listEmp = dataEmp?.people_work_placements.map(res => {
    return {
      label: res.global_user.id,
      value: res.global_user.name,
      avatar: res.global_user.avatar,
      organization: res.company_organization && res.company_organization.name,
      pos: res.company_job_profile && res.company_job_profile.title,
    }
  })

  const handleDel = (idTask, name) => {
    const aryName = selectedRep

    const filter = {
      idx: idTask,
      value: name,
    }
    const wbDel = aryName.filter(function(item) {
      for (var key in filter) {
        if (item[key] === undefined || item[key] !== filter[key]) return false
      }
      return true
    })
    const arrAfterDel = aryName.filter(e => e !== wbDel[0])
    setSelectedRep(arrAfterDel)
  }

  const onSaveLabel = () => {
    const data = {name: labelName, color}
    addGroup({variables: {data}})
      .then(() => {
        setStates({open: true, message: 'Success add new label'})
        setLabelName('')
        setLabel(false)
        refetchGroup()
      })
      .catch(e => {
        console.error(e)
        setStates({
          open: true,
          message: 'Update data error, please try again',
        })
      })
  }

  const handleAssignTask = () => {
    const data = selectedRep.map(rep => {
      const a = {task: rep.idx, user: rep.label}
      return a
    })

    assignTask({variables: {data}})
      .then(() => {
        setStates({
          open: true,
          message: `${GLOBAL_GLOSSARY.performance.Task} ditetapkan`,
        })
        setTimeout(() => {
          handleClose()
          resetSelectedTask([])
          handleClickBack()
        }, 1500)
      })
      .catch(() => {
        setStates({
          open: true,
          message: 'Update data error, please try again later',
        })
      })
  }

  return (
    <>
      <ModalContent>
        {_item.map(res => {
          return (
            <StyledBorderedExpansion key={res.id} elevation={0}>
              <StyledExpansionSummaryAssign
              // expandIcon={<Icon>expand_more</Icon>}
              >
                <div style={{width: '80%'}}>
                  <Pmd>{res.name}</Pmd>

                  <PsmGrey>#{res.id}</PsmGrey>
                </div>
                <StyledSmallButton
                  background={SwitchValue(res.priority, 'priority2')}
                >
                  {SwitchValue(res.priority, 'priorityWord')}
                </StyledSmallButton>
              </StyledExpansionSummaryAssign>
              <ExpansionPanelDetails style={{paddingTop: 0, display: 'block'}}>
                <div style={customStyle.flexExpansion}>
                  <div style={{width: '50%'}}>
                    <PmdGrey style={{marginBottom: 8}}>Label</PmdGrey>
                    <Select
                      value={selectedGroup.filter(v => v.idParent === res.id)}
                      options={options}
                      onChange={value => handleChangeGroup(value, res.id)}
                      styles={customSelect}
                      placeholder="Tambah ke Tim Kerja"
                      getOptionLabel={groupMenu}
                      components={{
                        Menu: props => (
                          <Fragment>
                            <components.Menu {...props}>
                              {props.children}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 16,
                                }}
                              >
                                <Button
                                  variant="contained"
                                  style={{
                                    textTransform: 'capitalize',
                                    width: '100%',
                                  }}
                                  onClick={() => handleAddLabel(res.id)}
                                >
                                  Buat Label Baru
                                </Button>
                              </div>
                            </components.Menu>
                          </Fragment>
                        ),
                      }}
                    />
                    <PmdGrey
                      style={{
                        marginBottom: 8,
                        marginTop: 16,
                      }}
                    >
                      Penerima
                    </PmdGrey>
                    <Select
                      placeholder="Cari..."
                      components={{
                        DropdownIndicator,
                        SingleValue: () => {
                          return 'Cari...'
                        },
                      }}
                      value={selectedRep}
                      getOptionLabel={empMenu}
                      options={listEmp?.filter(
                        el =>
                          !res.performance_task_assignments.some(
                            s =>
                              el.label === s.global_user.id &&
                              el.value === s.global_user.name
                          )
                      )}
                      onChange={value => handleChangeRep(value, res.id)}
                      // isMulti
                      // isClearable={false}
                      styles={customSelect}
                      isLoading={loadingEmp}
                      onInputChange={d => setSearchEmp(d)}
                    />
                  </div>
                  {label && idLabel === res.id && (
                    <div style={{margin: 25}}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <TextField
                          variant="outlined"
                          InputProps={{
                            classes: {
                              input: classes.outlinedInput,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <div
                                  style={{
                                    padding: 10,
                                    background: color,
                                    borderRadius: 5,
                                  }}
                                ></div>
                              </InputAdornment>
                            ),
                          }}
                          value={labelName}
                          onChange={handleLabelName}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={labelName === ''}
                          onClick={onSaveLabel}
                        >
                          Simpan
                        </Button>
                      </div>
                      <div style={{marginTop: 16}}>
                        <PsmGrey>Pilih Warna</PsmGrey>
                        <div style={customStyle.flexExpansion2}>
                          {colorOptions.map((col, i) => {
                            return (
                              <Radio
                                key={i}
                                onChange={handleChooseColor}
                                value={col.color}
                                checked={color === col.color}
                                icon={
                                  <span
                                    className={classes.icon}
                                    style={{background: col.color}}
                                  />
                                }
                                checkedIcon={
                                  <Icon
                                    className={classes.checkedIcon}
                                    style={{
                                      background: col.color,
                                      fontSize: 20,
                                    }}
                                    // fontSize="small"
                                  >
                                    check
                                  </Icon>
                                }
                              ></Radio>
                            )
                          })}
                          <Icon
                            onClick={handlePicker}
                            style={{
                              border: '1px #a9a8a8 solid',
                              color: '#a9a8a8',
                              borderRadius: 5,
                              // marginLeft: 9,
                              // marginRight: 16,
                              cursor: 'pointer',
                              fontSize: 19,
                              margin: 9,
                            }}
                          >
                            add
                          </Icon>
                        </div>
                        <div className={classes.flexAbsolute}>
                          {colorPicker && (
                            <Paper style={{padding: 14, marginBottom: 10}}>
                              <Typography variant="body2" gutterBottom>
                                Pick Color
                              </Typography>
                              <ChromePicker
                                color={color || false}
                                value={color}
                                onChangeComplete={handleChangePicker}
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  marginTop: 10,
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{width: '80%'}}
                                  // onClick={handleClosePicker}
                                  onClick={() => {
                                    setColorOptions(prev => [...prev, {color}])
                                    setColorPicker(false)
                                  }}
                                >
                                  Close
                                </Button>
                              </div>
                            </Paper>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className={classes.selectedEmp}>
                  {selectedRep &&
                    selectedRep
                      .filter(v => v.idx === res.id)
                      .map((option, i) => {
                        return (
                          <Card
                            key={i}
                            className={classes.cardEmp}
                            elevation={0}
                          >
                            <div className={classes.spacer}>
                              <Avatar
                                src={option.avatar}
                                alt={option.value}
                              ></Avatar>
                            </div>
                            <div>
                              <Typography variant="body2">
                                {option.value}
                              </Typography>
                              {/* <Typography
                                    variant="body2"
                                    className={classes.textGrey}
                                  >
                                    {option.organization}
                                  </Typography> */}
                              <Typography
                                variant="body2"
                                className={classes.textGrey}
                              >
                                {option.pos}
                              </Typography>
                            </div>
                            <Icon
                              className={classes.textGrey}
                              style={{cursor: 'pointer', marginLeft: 'auto'}}
                              onClick={() =>
                                handleDel(option.idx, option.value)
                              }
                            >
                              close
                            </Icon>
                          </Card>
                        )
                      })}
                </div>
              </ExpansionPanelDetails>
            </StyledBorderedExpansion>
          )
        })}
      </ModalContent>
      <FormChildButton>
        <Button
          size="large"
          style={customStyle.buttonSpacer}
          onClick={handleClickBack}
        >
          Kembali
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={handleAssignTask}
          disabled={selectedRep.length === 0}
        >
          Simpan
        </Button>
      </FormChildButton>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={1500}
        open={open}
        onClose={() => setStates({...states, open: false})}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{message}</span>}
      />
    </>
  )
}

export default ModalAssignTaskFinal
