import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import {WrapperItemAtch} from './Attachment.style'
import {fileChecker} from '../../utils/helpers'
import documents from '../../assets/images/icons/document.svg'
import pdfIcon from '../../assets/images/icons/file-pdf.svg'
import excelIcon from '../../assets/images/icons/file-excel.svg'
import pptIcon from '../../assets/images/icons/file-powerpoint.svg'
import fileIcon from '../../assets/images/icons/file.svg'
import {LinearProgress} from '@material-ui/core'

export default function ItemAttachment({
  size,
  onDelete,
  src,
  onChange,
  fileName = '',
  isUploading,
  loaded,
  id,
}) {
  const percentCompleted = Math.round((loaded * 100) / size)
  const ex = fileChecker(src)
  const isIconable =
    ex === 'document' || ex === 'pdf' || ex === 'excel' || ex === 'powerpoint'
  return (
    <WrapperItemAtch key={id}>
      <img
        style={{
          ...(!isIconable && {width: 36, height: 36}),
          ...((isIconable || isUploading) && {width: 25, height: 34}),
        }}
        src={
          !isUploading
            ? ex === 'document'
              ? documents
              : ex === 'pdf'
              ? pdfIcon
              : ex === 'excel'
              ? excelIcon
              : ex === 'powerpoint'
              ? pptIcon
              : src
            : fileIcon
        }
        alt="logo"
        className="img"
      />
      {isUploading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontFamily: 'muli',
            }}
          >
            <p
              style={{fontWeight: '600', fontSize: 12, margin: 0}}
            >{`Uploading... (${percentCompleted || 0}%)`}</p>
            <p style={{color: '#a9a8a8', fontSize: 10, margin: 0}}>
              {loaded >= size ? size : loaded}/{size}
            </p>
          </div>
          <LinearProgress variant="determinate" value={percentCompleted} />
        </div>
      ) : (
        <div className="wrapper-field">
          <div className="wrapper-action">
            <input
              style={{fontSize: 12}}
              value={fileName}
              onChange={onChange}
              className="text-field"
              size="large"
            />
            <ClearIcon onClick={onDelete} style={{cursor: 'pointer'}} />
          </div>
          <div className="preview-wrapper">
            <div className="size">
              {size ? (size / 1024 / 1024).toFixed(2) : ''} MB
            </div>
            <div className="dot" />
            <a
              href={src}
              rel="noopener noreferrer"
              target="_blank"
              className="preview"
            >
              Preview
            </a>
          </div>
        </div>
      )}
    </WrapperItemAtch>
  )
}
