import {
  Avatar,
  CircularProgress,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import PopupWrapper from '../../../shared-component/PopupWrapper'
import SearchIcon from '@material-ui/icons/Search'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import {ItemEditMember} from '../SquadStyle'
import CloseIcon from '@material-ui/icons/Close'
import {useQuery} from '@apollo/react-hooks'
import {Skeleton} from '@material-ui/lab'
import EmptyState from '../../../../components/empty-state/EmptyStateComponent'
import DeletePopup from '../../../shared-component/DeletePopUp'
import InfiniteScroll from 'react-infinite-scroll-component'

const useStyles = makeStyles(() => ({
  searchBar: {
    padding: '24px 0px',
    position: 'sticky',
    top: '0',
    backgroundColor: '#fff',
    zIndex: 2,
  },
  wrapperComponent: {
    position: 'relative',
    padding: '0px 24px 22px',
    height: 450,
    overflow: 'auto',
  },
  loaderWrapper: {
    textAlign: 'center',
  },
  loader: {
    fontFamily: 'Muli',
    marginLeft: 10,
  },
}))

const ListUser = ({
  loading = false,
  avatar,
  name,
  possition,
  onDelete,
  onClickUser,
}) => {
  return (
    <ItemEditMember>
      <div
        className={'user-info ' + (onClickUser ? 'cursor-pointer' : '')}
        onClick={onClickUser}
      >
        {loading ? (
          <Skeleton variant="circle" width={40} height={40} />
        ) : (
          <Avatar src={avatar} className="avatar" alt="user-avatar">
            {onClickUser && <PersonAddIcon className="add-icon" />}
          </Avatar>
        )}
        <div className="user-detail">
          {loading ? (
            <Skeleton className="name" variant="rect" width={200} height={17} />
          ) : (
            <h3 className="name">{name}</h3>
          )}
          {loading ? (
            <Skeleton variant="rect" width={250} height={14} />
          ) : (
            <p className="possition">{possition}</p>
          )}
        </div>
      </div>
      {loading ? (
        <Skeleton variant="circle" width={24} height={24} />
      ) : (
        onDelete && (
          <IconButton onClick={onDelete}>
            <CloseIcon className="close-icon" />
          </IconButton>
        )
      )}
    </ItemEditMember>
  )
}

export default function EditSquadMember({
  open,
  onClose,
  onDelete,
  onInviteMember,
  squadType = 'SUBORDINATE', // SUBORDINATE and GENERAL
  query,
  variablesQuery = {},
}) {
  const classes = useStyles()
  const [search, setSearch] = useState('')
  const [deletePopup, setDeletePopup] = useState({
    open: false,
    message: '',
    data: {},
  })
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 7,
    hasMore: true,
  })

  const {data, loading, error, refetch} = useQuery(query, {
    skip: !open,
    fetchPolicy: 'network-only',
    variables: {
      ...variablesQuery,
      search: `%${search}%`,
      offset: infiniteLoad.offset,
      limit: infiniteLoad.limit,
    },
  })

  const countDataPeople = data?.total?.aggregate?.count

  const fetchMoreData = () => {
    if (infiniteLoad.limit >= countDataPeople) {
      setInfiniteLoad({
        ...infiniteLoad,
        hasMore: false,
      })
    } else {
      setTimeout(() => {
        setInfiniteLoad({
          ...infiniteLoad,
          limit: infiniteLoad.limit + 20,
        })
      }, 1000)
    }
  }

  const handleClose = () => {
    onClose()
    setSearch('')
  }

  const handleDeleteUser = async data => {
    await onDelete(data, refetch)
  }

  const handleOpenDeleteConfirmation = data => {
    setDeletePopup({
      open: true,
      data,
      message: (
        <>
          <Typography variant="caption" display="inline">
            Apakah kamu yakin untuk mengahapus
          </Typography>
          <Typography color="error" variant="caption" display="inline">
            {' '}
            {data?.global_user?.name}{' '}
          </Typography>
          <Typography variant="caption" display="inline">
            dari tim kerja ini?
          </Typography>
        </>
      ),
    })
  }

  const handleCloseDeleteConfirmation = () => {
    setDeletePopup({
      open: false,
      message: '',
      data: {},
    })
  }

  return (
    <>
      <PopupWrapper
        title="Ubah Anggota Tim Kerja"
        maxWidth="sm"
        open={open && !deletePopup.open}
        withAction={false}
        onClose={handleClose}
        withCloseicon
        contentPadding="0px"
      >
        <div className={classes.wrapperComponent} id="scrollableDiv">
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Cari"
            className={classes.searchBar}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {error && JSON.stringify(error, 0, 4)}

          <ListUser onClickUser={onInviteMember} name="Undang Anggota" />

          <InfiniteScroll
            dataLength={infiniteLoad.limit}
            next={fetchMoreData}
            hasMore={countDataPeople > 6 ? infiniteLoad.hasMore : false}
            loader={
              <div className={classes.loaderWrapper}>
                <CircularProgress size={15} />
                <span className={classes.loader}>Loading</span>
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            {!loading &&
              data?.people_work_placements?.map((res, i) => {
                const SUPERVISOR =
                  res?.company_job_profile?.job_profile_supervisor
                const COUNT =
                  SUPERVISOR?.people_work_placements_aggregate?.aggregate?.count
                return (
                  <ListUser
                    key={i}
                    avatar={res?.global_user?.avatar}
                    name={res?.global_user?.name}
                    possition={res?.company_job_profile?.title}
                    onDelete={
                      squadType === 'GENERAL' ||
                      (squadType === 'SUBORDINATE' &&
                        (COUNT === 0 || SUPERVISOR === null))
                        ? () => handleOpenDeleteConfirmation(res)
                        : null
                    }
                  />
                )
              })}
          </InfiniteScroll>

          {loading && (
            <>
              <ListUser loading />
              <ListUser loading />
              <ListUser loading />
              <ListUser loading />
              <ListUser loading />
              <ListUser loading />
              <ListUser loading />
            </>
          )}

          {!loading && data && data.people_work_placements.length === 0 && (
            <EmptyState
              message1={'Maaf, Tidak ada Daftar'}
              message2={
                search
                  ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
                  : 'Saat ini, tidak ada anggota tim kerja yang terdaftar'
              }
              image={require('../../../../assets/images/nodatalist.png')}
              styleMessage2={{
                maxWidth: 300,
                margin: 'auto',
              }}
            />
          )}
        </div>
      </PopupWrapper>
      <DeletePopup
        open={deletePopup.open}
        handleClose={handleCloseDeleteConfirmation}
        title="Hapus Anggota?"
        message={deletePopup.message}
        checkboxMessage="Saya memahami bahwa mengahapus anggota ini bersifat permanen dan tidak dapat diurungkan."
        mutation={() => handleDeleteUser(deletePopup.data)}
      />
    </>
  )
}
