import gql from 'graphql-tag'

export const GET_LIST_TASK_SQUAD_MEMBER = gql`
  query getTaskTeam(
    $user: uuid!
    $company: uuid!
    $search: String
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $reporter: uuid_comparison_exp
    $creator: uuid_comparison_exp
    $methodType: Int_comparison_exp
    $priority: enum_mpp_priority_comparison_exp
    $label: bigint_comparison_exp
    $status: String_comparison_exp
    $period: date_comparison_exp
    $aspect: Int_comparison_exp
    $perspective: Int_comparison_exp
    $scoring: Int_comparison_exp
    $weightUnit: Int_comparison_exp
    $progress: float8_comparison_exp
    $offset: Int!
    $limit: Int!
    $order: order_by
  ) {
    total: performance_tasks_aggregate(
      where: {
        company: {_eq: $company}
        deletedat: {_is_null: true}
        performance_task_assignments: {
          deleted_at: {_is_null: true}
          user: {_eq: $user}
        }
        performance_goal: {
          deleted_at: {_is_null: true}
          kpi_list: {
            scoring: $scoring
            prespective: $perspective
            deleted_at: {_is_null: true}
            _or: [
              {
                _and: [
                  {
                    kpi_cascading_lists: {
                      cascading_main: {status: {_eq: "ACTIVE"}}
                    }
                  }
                  {_not: {id: {_is_null: $isCompany}}}
                ]
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        reporter: $reporter
        created_by: $creator
        method_type: $methodType
        priority: $priority
        group: $label
        status: $status
        startdate: $period
        aspect: $aspect
        target_unit: $weightUnit
        progress_percentage: $progress
      }
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        company: {_eq: $company}
        deletedat: {_is_null: true}
        performance_task_assignments: {
          deleted_at: {_is_null: true}
          user: {_eq: $user}
        }
        performance_goal: {
          deleted_at: {_is_null: true}
          kpi_list: {
            scoring: $scoring
            prespective: $perspective
            deleted_at: {_is_null: true}
            _or: [
              {
                _and: [
                  {
                    kpi_cascading_lists: {
                      cascading_main: {status: {_eq: "ACTIVE"}}
                    }
                  }
                  {_not: {id: {_is_null: $isCompany}}}
                ]
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        reporter: $reporter
        created_by: $creator
        method_type: $methodType
        priority: $priority
        group: $label
        status: $status
        startdate: $period
        aspect: $aspect
        target_unit: $weightUnit
        progress_percentage: $progress
      }
      offset: $offset
      limit: $limit
      order_by: [{id: $order}]
    ) {
      id
      name
      progress_percentage
      startdate
      enddate
      method_type
      kpi_weight_unit {
        id
        name
      }
      performance_goal {
        id
        name
        kpi_list {
          id
          formula
          type
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      created_by_user {
        id
        name
      }
    }
  }
`
