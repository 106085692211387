import gql from 'graphql-tag'

export const GET_LIST_TASK = condition => {
  return gql`
    subscription getListTask {
      performance_tasks ${condition} {
        id
        name
        performance_group {
          id
          name
          color
        }
        performance_task_assignments {
          global_user {
            id
            name
            avatar
          }
        }
        startdate
        enddate
        last_modified
        weight
        progress
        status
        priority
        performance_goal_category {
          id
          name
        }
        performance_goal {
          id
          name
        }
        visibility
        metric
        kpi_weight_unit{
          id
          name
        }
        target
        target_stretch  
      }
    }
  `
}

export const GET_COUNT_TARGET_GOAL = condition => {
  if (condition === 'add-task') {
    return gql`
      query($idGoal: bigint, $task: bigint) {
        performance_goals(
          where: {
            id: {_eq: $idGoal}
            kpi_list: {
              kpi_scoring_nature: {
                global_scoring_nature: {name: {_eq: "Higher is Better"}}
              }
            }
          }
        ) {
          target
          performance_tasks_aggregate(where: {id: {_neq: $task}}) {
            aggregate {
              sum {
                target
              }
            }
          }
        }
      }
    `
  } else {
    return gql`
      query($idTask: bigint) {
        performance_tasks(where: {id: {_eq: $idTask}}) {
          performance_goal {
            id
            target
            performance_tasks_aggregate(where: {id: {_neq: $idTask}}) {
              aggregate {
                sum {
                  target
                }
              }
            }
          }
        }
      }
    `
  }
}

export const GET_DROPDOWN_WEIGHT = gql`
  query($companyId: uuid!, $search: String, $offset: Int, $limit: Int) {
    kpi_weight_units(
      where: {
        _and: [
          {company: {_eq: $companyId}, status: {_eq: "ACTIVE"}}
          {_or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]}
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      code
      name
    }
  }
`

export const GET_DROPDOWN_ASPECT = gql`
  query listAspect($limit: Int, $offset: Int, $search: String) {
    kpi_aspects(
      where: {
        _or: [{name: {_ilike: $search}}, {description: {_ilike: $search}}]
        status: {_eq: "ACTIVE"}
      }
      limit: $limit
      offset: $offset
      order_by: {id: desc}
    ) {
      id
      name
      description
      status
    }
  }
`
