import React, {useState} from 'react'
import {
  Button,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import {Link, withRouter} from 'react-router-dom'
import ListTask from '../list/task/ListTask'
import ModalAssign from '../modal-assign/ModalAssignTask'
import PaperSearcher from '../../../shared-component/paper/PaperSearcher'
import {TitleWrapper} from '../GoalStyled'
import {GROUP_BY_GOAL, QUICK_FILTER_PERSONAL} from '../constant'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {FlexBetween, StyledMenuItem, StyledMenus} from '../../PerformanceStyles'
import ListTaskByGoal from '../list/task/ListTaskByGoal'
import {selectProps} from '../../../../utils/helpers'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import Shorting from '@material-ui/icons/KeyboardArrowDown'
import moment from 'moment'
import {Filter} from '@smartasn/wlb-utils-components'
import {
  anchorOrigin,
  listFilterCoreTask,
  transformOrigin,
} from '../see-all/AllFIlterData'

const useStyles = makeStyles(() => ({
  titleSection: {
    margin: '0px 16px 0 0',
    fontSize: 16,
    fontWeight: '600',
    color: '#014a62',
  },
  seeAll: {
    margin: 0,
    fontSize: 16,
    fontWeight: '600',
    color: '#039be5',
    padding: 0,
    textTransform: 'initial',
  },
  groupBy: {
    width: '19.2vw',
    marginBottom: 14,
  },
  sectionRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 22,
    height: 20,
    margin: '32px 3px',
  },
  textFilter: {
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    margin: '35px 0px',
    cursor: 'pointer',
  },
  menu: {marginTop: 40},
}))

const TasksComponent = props => {
  const classes = useStyles()
  const {active, refetchListTask, match} = props
  const [search, setSearch] = useState('')
  const [groupBy, setGroupBy] = useState('')
  const [filterData, setFilterData] = useState({})
  const [openAssign, setOpenAssign] = useState(false)
  const [sorting, setSorting] = useState({
    label: 'Urutkan',
    value: null,
  })

  const type = match.params.type || QUICK_FILTER_PERSONAL

  const handleChange = event => {
    setGroupBy(event.target.value)
  }

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'type') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'percentage') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include.progressPersenFrom = Math.min(...tempData)
            include.progressPersenTo = Math.max(...tempData)
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include.dateFrom = moment(value.from).format(format)
        include.dateTo = moment(value.to).format(format)
      }

      if (key !== 'percentage' && key !== 'period') {
        include[key] = value
      }
    }
    setFilterData({...filterData, include})
  }

  return (
    <>
      <PaperSearcher
        title={GLOBAL_GLOSSARY.performance.Task}
        search={search}
        setSearch={setSearch}
        icon={
          <img
            src={require('../../../../assets/images/icons/icon-task.svg')}
            alt="tasks-icon"
          />
        }
        buttonConfig={props => (
          <Button {...props} onClick={() => setOpenAssign(true)}>
            Menetapkan {GLOBAL_GLOSSARY.performance.Task}
          </Button>
        )}
      >
        <FlexBetween>
          <TitleWrapper style={{marginBottom: 14, alignItems: 'center'}}>
            <Typography component="h3" className={classes.titleSection}>
              Daftar {GLOBAL_GLOSSARY.performance.Task}
            </Typography>
            <Button
              variant="text"
              color="secondary"
              className={classes.seeAll}
              component={Link}
              to={{pathname: `/goals/${type}/all-task`}}
            >
              Lihat Semua
            </Button>
          </TitleWrapper>
          <TextField
            id="filterBy"
            select
            value={groupBy}
            onChange={handleChange}
            displayEmpty
            variant="outlined"
            margin="dense"
            className={classes.groupBy}
            SelectProps={selectProps(groupBy)}
          >
            <MenuItem value="" disabled>
              Kelompokkan Berdasarkan
            </MenuItem>
            <MenuItem value="none">Tidak Ada</MenuItem>
            <MenuItem value="goal">
              Nama {GLOBAL_GLOSSARY.performance.Goal}
            </MenuItem>
          </TextField>
        </FlexBetween>

        <div className={classes.sectionRoot}>
          <PopupState variant="popover" popupId="menu-sorting">
            {popupState => (
              <>
                <div
                  {...bindTrigger(popupState)}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <BoldTypography
                    component="span"
                    style={{verticalAlign: 'top', fontSize: 14}}
                  >
                    {sorting.label}
                  </BoldTypography>
                  <Shorting fontSize="small" style={{color: '#014A62'}} />
                </div>

                <StyledMenus {...bindMenu(popupState)} className={classes.menu}>
                  <StyledMenuItem
                    style={{color: 'black', padding: 16}}
                    onClick={() => {
                      popupState.close()
                      setSorting({
                        ...sorting,
                        label: 'Terbaru',
                        value: 'desc',
                      })
                    }}
                  >
                    Terbaru
                  </StyledMenuItem>
                  <StyledMenuItem
                    style={{color: 'black', padding: 16}}
                    onClick={() => {
                      popupState.close()
                      setSorting({
                        ...sorting,
                        label: 'Terlama',
                        value: 'asc',
                      })
                    }}
                  >
                    Terlama
                  </StyledMenuItem>
                </StyledMenus>
              </>
            )}
          </PopupState>

          <Filter
            includeExclude={false}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id="filter-squad-goal"
            language="indonesian"
            onApply={handleApply}
            listFilter={listFilterCoreTask}
          >
            <Typography className={classes.textFilter}>Filter</Typography>
          </Filter>
        </div>

        {groupBy === GROUP_BY_GOAL ? (
          <ListTaskByGoal
            sorting={sorting}
            filterData={filterData}
            filterValue={active}
            search={search}
          />
        ) : (
          <ListTask
            sorting={sorting}
            filterData={filterData}
            filterValue={active}
            search={search}
            refetchListTaskFromGoal={refetchListTask}
          />
        )}
      </PaperSearcher>

      <ModalAssign open={openAssign} handleClose={() => setOpenAssign(false)} />
    </>
  )
}

export default withRouter(TasksComponent)
