import React, { useState } from 'react'
import {
  ActivityTabContainer,
  CommentWrapper,
  CommentDetailWrapper,
  CommentChildrenAvatar,
  CarouselCard,
  CommentWrapperAct,
  FlexBetween,
} from '../../PerformanceStyles'
import { Icon, Menu, MenuItem, Snackbar } from '@material-ui/core'
import {
  Psm,
  PmdG,
  TypographyStyleds,
} from '../../../../components/typography/TypographyStyles'
import Moment from 'moment'
import ActivityComment from './ActivityComment'
import WordingTabActicity from './WordingTabActicity'
// import {makeStyles} from '@material-ui/core/styles'
import { UPLOAD_URL, TOKEN, USER_ID } from '../../../../utils/globals'
import axios from 'axios'
import DialogDelete from '../../../shared-component/DeletePopUp'
import { AttachmentItemHover } from '../../../shared-component/attachment/AttachmentItemHover'
import { timeStamp, getTypeFile, downloadFile } from '../../../../utils/helpers'
import { useSnackbar } from 'notistack'
import FieldInformationTooltip from '../../../shared-component/FieldInformationTooltip'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import FileDownloadIcon from '@material-ui/icons/GetApp'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import PostingField from '../../../../components/post/PostingField'
import AddAtachment from '../../../../components/attachment/AddAtachment'
import ModalMutationFeedBack from '../../detail-modal/ModalMutationFeedBack'
import { FILE_TYPE_UPLOAD } from './constant'

export default function Activity({
  condition,
  res,
  Attachment,
  type,
  updateActivity,
  updateMutationParComment,
  deleteActivity,
  updateMutationChildComment,
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [openComment, setOpenComment] = useState(false)
  const [updateComment, setUpdateComment] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDeleteAttachment, setOpenDeleteAttachment] = useState(false)
  const [idAttachment, setIdAttachment] = useState(null)
  const [openFeedback, setOpenFeedback] = useState({
    open: false,
    data: {},
  })
  const [disabledButton, setDisabledButton] = useState(false)
  const [comment, setComment] = useState({
    comment: '',
    attachment: [],
  })
  const [updateACT, setUpdateActivity] = useState({
    comment: '',
    attachment: [],
  })

  const [anchorEl, setAnchorEl] = useState({ data: null, open: null })
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })

  const snackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={3000}
        open={states.open}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{states.message}</span>}
      />
    )
  }

  function handleCloseSnackbar() {
    setStates({ ...states, open: false })
  }

  const handleClick = (event, res) => {
    setAnchorEl({ data: res, open: event.currentTarget })
  }

  const handleClose = () => {
    setAnchorEl({ ...anchorEl, open: null })
  }

  const handleChangeUpdate = event => {
    setUpdateActivity({ ...updateACT, comment: event.target.value })
  }

  const handleChangeFile = name => e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) +
      (name === 'attachmentComment'
        ? comment?.attachment?.length ?? 0
        : updateACT?.attachment?.length ?? 0)
    tempFile.forEach(val => {
      if (fileCount > 10) {
        setStates({
          open: true,
          message: 'The number of attachments must not exceed 10',
        })
      } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
        setStates({
          open: true,
          message: 'There are file types that are not allowed',
        })
      } else if (val.size >= 1024 * 1024 * 25) {
        setStates({
          open: true,
          message: 'Some of your attachment size is more than 25MB',
        })
      } else {
        const formData = new FormData()
        formData.append('file', val)
        const _initialUpload = {
          id: val.name + val.size,
          name: val.name,
          size: val.size,
          url: '',
          loaded: 0,
          isUploading: true,
        }
        setDisabledButton(true)
        if (name === 'attachmentComment') {
          setComment(prevState => {
            return {
              ...prevState,
              attachment: [...(prevState.attachment || []), _initialUpload],
            }
          })
        } else {
          setUpdateActivity(prevState => {
            return {
              ...prevState,
              attachment: [...(prevState.attachment || []), _initialUpload],
            }
          })
        }
        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            onUploadProgress: progressEvent => {
              setDisabledButton(true)
              const item = {
                id: val.name + val.size,
                name: val.name,
                size: val.size,
                url: '',
                loaded: progressEvent.loaded,
                isUploading: true,
              }
              if (name === 'attachmentComment') {
                setComment(prevState => {
                  const items = prevState.attachment
                  const foundIndex = items.findIndex(
                    x => x.id === item.id && x.isUploading
                  )
                  items[foundIndex] = item
                  return { ...prevState, attachment: items }
                })
              } else {
                setUpdateActivity(prevState => {
                  const items = prevState.attachment
                  const foundIndex = items.findIndex(
                    x => x.id === item.id && x.isUploading
                  )
                  items[foundIndex] = item
                  return { ...prevState, attachment: items }
                })
              }
            },
          })
          .then(response => {
            const item = {
              id: val.name + val.size,
              name: val.name,
              size: val.size,
              url: response.data.url,
              loaded: val.size,
              isUploading: false,
            }
            setDisabledButton(false)
            if (name === 'attachmentComment') {
              setComment(prevState => {
                const items = prevState.attachment
                const foundIndex = items.findIndex(
                  x => x.id === item.id && x.isUploading
                )
                items[foundIndex] = item
                return { ...prevState, attachment: items }
              })
            } else {
              setUpdateActivity(prevState => {
                const items = prevState.attachment
                const foundIndex = items.findIndex(
                  x => x.id === item.id && x.isUploading
                )
                items[foundIndex] = item
                return { ...prevState, attachment: items }
              })
            }
          })
          .catch(error => {
            JSON.stringify(error)
          })
      }
    })
  }

  const clearAtch = () => {
    setComment({ ...comment, attachment: [] })
  }

  const updateAtc = id => {
    updateActivity(id, comment.attachment)
    setComment({ ...comment, attachment: [] })
  }

  const handleUpdateComment = () => {
    setUpdateActivity({
      comment: anchorEl.data.comment,
      attachment: anchorEl.data.attachment,
    })
    setUpdateComment(true)
  }

  const handleKeyPress = () => e => {
    if (e.key === 'Enter') {
      if (updateACT.comment === '') {
        enqueueSnackbar('Fill the comment first', {
          autoHideDuration: 2500,
          variant: 'error',
        })
        setUpdateComment(false)
      } else if (!disabledButton) {
        updateMutationParComment(
          updateACT.comment,
          updateACT.attachment,
          anchorEl.data.id
        )
        setUpdateActivity({ comment: '', attachment: [] })
        setUpdateComment(false)
        setOpenComment(false)
      }
    }
  }
  const handleSent = () => {
    if (updateACT.comment === '') {
      enqueueSnackbar('Fill the comment first', {
        autoHideDuration: 2500,
        variant: 'error',
      })
    } else if (!disabledButton) {
      updateMutationParComment(
        updateACT.comment,
        updateACT.attachment,
        anchorEl.data.id
      )
      setUpdateActivity({ comment: '', attachment: [] })
      setUpdateComment(false)
      setOpenComment(false)
    }
  }

  const dateActivity = type === 'task' ? res.date_added : res.date_created
  const commentCount = res?.total_comment?.aggregate?.count
  const dynamicCommentCount = commentCount > 1 ? commentCount - 2 : 0

  const handleDeleteAttachement = i => {
    const newState = [...updateACT.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setUpdateActivity(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...updateACT.attachment]
    newState[i].name = e.target.value
    setUpdateActivity(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleDeleteAttachButton = i => {
    const newState = [...comment.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setComment(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleDeleteHover = i => {
    const newState = [...Attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    updateActivity(res.id, newState)
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...comment.attachment]
    newState[i].name = e.target.value
    setComment(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleCancelPostField = () => {
    setUpdateActivity({ ...updateACT, comment: '', attachment: [] })
    setUpdateComment(false)
  }

  const comment_id = `menu-${res.id}`

  return (
    <ActivityTabContainer>
      <CommentWrapper style={{ paddingLeft: '2rem' }}>
        <CommentWrapperAct>
          <CommentChildrenAvatar src={res.global_user?.avatar} />
          <CommentDetailWrapper>
            <FlexBetween>
              <TypographyStyleds size="14px" weight="600">
                {res.global_user?.name}
              </TypographyStyleds>

              <Icon
                onClick={e => handleClick(e, res)}
                style={{ cursor: 'pointer' }}
                aria-describedby={comment_id}
              >
                more_horiz
              </Icon>
              <Menu
                id={comment_id}
                anchorEl={anchorEl.open}
                keepMounted
                open={Boolean(anchorEl.open)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {res.global_user?.id === USER_ID && condition !== 'archive' ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleUpdateComment()
                        handleClose()
                      }}
                    >
                      Ubah
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpen(true)
                        handleClose()
                      }}
                    >
                      Hapus
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        setOpenFeedback({
                          ...openFeedback,
                          open: true,
                          data: {
                            receiver: [
                              {
                                idx: 0,
                                type_from: 'FEEDBACK',
                                type_to: 'FEEDBACK',
                                label: res?.global_user?.id,
                                value: res?.global_user?.name,
                                request_id: null,
                                avatar: res?.global_user?.avatar,
                                pos:
                                  res?.global_user?.people_work_placements?.[0]
                                    ?.company_job_profile?.title,
                              },
                            ],
                            topic: res?.comment,
                          },
                        })
                        handleClose()
                      }}
                    >
                      Berikan Tanggapan
                    </MenuItem>
                  </>
                )}
              </Menu>
            </FlexBetween>
            <FlexBetween style={{ marginBottom: 15 }}>
              <TypographyStyleds size="12px" color="#a9a8a8">
                {
                  res.global_user?.people_work_placements[0]
                    ?.company_job_profile?.title
                }
              </TypographyStyleds>
              <FieldInformationTooltip
                placement="bottom"
                title={`${Moment(dateActivity).format('LL')} • ${Moment(
                  dateActivity
                ).format('LT')}`}
              >
                <TypographyStyleds size="12px" color="#a9a8a8">
                  {timeStamp(dateActivity)}
                </TypographyStyleds>
              </FieldInformationTooltip>
            </FlexBetween>
            {!updateComment && (
              <>
                <Psm style={{ marginTop: 5, wordBreak: 'break-all' }}>
                  <WordingTabActicity comment={res?.comment ?? ''} />
                  {type === 'task' &&
                    res.is_progress === true &&
                    `(${res?.progress_percentage ?? 0}%)`}
                </Psm>
                {res.last_modified && (
                  <PmdG style={{ marginTop: 10, fontSize: 14 }}>(Diedit)</PmdG>
                )}
              </>
            )}
          </CommentDetailWrapper>
        </CommentWrapperAct>
      </CommentWrapper>
      {updateComment && condition !== 'archive' ? (
        <div style={{ padding: '0px 1.8rem 0rem 5.3rem', marginBottom: 40 }}>
          <PostingField
            handleKeyPress={handleKeyPress()}
            handleTextField={handleChangeUpdate}
            handleChangeFile={handleChangeFile()}
            handleCancelPostField={handleCancelPostField}
            handleDeleteAttachement={handleDeleteAttachement}
            handleChangeNameFile={handleChangeNameFile}
            handleSave={handleSent}
            textValue={updateACT.comment}
            placeholder="Deskripsikan aktivitas anda"
            attachmentValue={updateACT.attachment}
            variant="save"
            disabled={updateACT?.attachment?.length > 10 || disabledButton}
          />
        </div>
      ) : (
        <>
          {Attachment.length === 0 && res.global_user?.id === USER_ID ? (
            <div style={{ padding: '0px 1.8rem 0rem 5.3rem', marginBottom: 40 }}>
              <AddAtachment
                attachment={comment.attachment}
                idAttachment={res.id}
                multiple
                onUpload={handleChangeFile('attachmentComment')}
                clearAttach={clearAtch}
                mutationAttach={() => updateAtc(res.id)}
                handleDeleteAttachement={handleDeleteAttachButton}
                handleChangeNameFile={handleChangeNameFileAttBtn}
                hideOptions={openComment}
                disabled={comment?.attachment?.length > 10 || disabledButton}
              />
            </div>
          ) : (
            <div style={{ marginLeft: '5.3rem', marginBottom: '1rem' }}>
              <CarouselCard>
                {Attachment.map((attachment, i) => {
                  const fileUrl = attachment.link || attachment.url
                  const getFileName = fileUrl
                  const getFileExtension = getFileName.split('.')
                  const extensionFile =
                    getFileExtension[getFileExtension.length - 1]
                  return (
                    <AttachmentItemHover
                      key={`${i}-itm`}
                      iconAction={
                        <>
                          <VisibilityOutlinedIcon
                            onClick={() => window.open(fileUrl)}
                            className="icon"
                          />
                          <FileDownloadIcon
                            style={{
                              ...(res.global_user.id !== USER_ID
                                ? { marginLeft: 10 }
                                : { marginLeft: 5 }),
                            }}
                            className="icon"
                            onClick={() =>
                              downloadFile(
                                fileUrl,
                                attachment.name,
                                extensionFile
                              )
                            }
                          />
                          {res.global_user.id === USER_ID && (
                            <DeleteOutlineIcon
                              style={{ marginLeft: 5 }}
                              onClick={() => {
                                setOpenDeleteAttachment(true)
                                setIdAttachment(i)
                              }}
                              className="icon"
                            />
                          )}
                        </>
                      }
                      fileName={attachment.name}
                      url={fileUrl}
                    />
                  )
                })}
              </CarouselCard>
            </div>
          )}
        </>
      )}

      <ActivityTabContainer>
        <ActivityComment
          type={type}
          condition={condition}
          idActivity={res?.id}
          updateMutationChildComment={updateMutationChildComment}
          idx={USER_ID}
          commentCount={dynamicCommentCount}
          updateComment={updateComment}
        />
      </ActivityTabContainer>
      <DialogDelete
        open={open}
        handleClose={() => setOpen(false)}
        title="Delete this activity?"
        message="Are you sure you want to delete this activity permanently?"
        checkboxMessage="I understand that deleting this activity is permanent and cannot be undone. This action will also delete comments in this activity"
        isClose={false}
        mutation={() => {
          deleteActivity(anchorEl.data.id)
          setAnchorEl({ data: null, open: null })
        }}
      />
      <DialogDelete
        open={openDeleteAttachment}
        handleClose={() => setOpenDeleteAttachment(false)}
        title="Delete this attachment?"
        message="Are you sure you want to delete this attachment permanently?"
        checkboxMessage="I understand that deleting this attachment is permanent and cannot be undone."
        isClose={false}
        mutation={() => {
          handleDeleteHover(idAttachment)
        }}
      />
      <ModalMutationFeedBack
        open={openFeedback.open}
        dataActivity={openFeedback.data}
        handleClose={() => setOpenFeedback({ ...openFeedback, open: false })}
        type="Memberi Umpan Balik"
      />
      {snackBar()}
    </ActivityTabContainer>
  )
}
