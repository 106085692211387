import {useMutation, useQuery} from '@apollo/react-hooks'
import {useState} from 'react'
import {EXPORT_MUTATION, CLOSE_BALOON_EXPORT} from '../graphql/mutations'

import {
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../graphql/queries'
import {COMPANY_ID} from '../utils/globals'
import {disbaledButtonExport} from '../utils/helpers'

const DEFAULT_QUERY = {
  getStatusBaloon: GET_STATUS_EXPORT_BALOON,
  getDisabledButton: GET_EXPORT_DISABLED_BUTTON,
}

export default ({table, filter = undefined, query = DEFAULT_QUERY}) => {
  const [dataButton, setDataButton] = useState(undefined)
  const [isDisableButton, setIsDisableButton] = useState(true)
  const [skip, setSkip] = useState({
    baloon: false,
    button: false,
  })

  const [downloadData] = useMutation(EXPORT_MUTATION)

  const [closeBaloon] = useMutation(CLOSE_BALOON_EXPORT)

  const {data: dataBaloon} = useQuery(query.getStatusBaloon, {
    variables: {
      company: COMPANY_ID,
      table,
      filter,
    },
    pollInterval: 1000,
    nextFetchPolicy: 'no-cache',
    fetchPolicy: 'no-cache',
  })

  useQuery(query.getDisabledButton, {
    variables: {
      company: COMPANY_ID,
      table,
      filter,
    },
    skip: skip.button,
    onCompleted: data => {
      if (data && data.company_export_data_logs[0]) {
        const {date_added} = data.company_export_data_logs[0]
        setDataButton(data)

        if (disbaledButtonExport(date_added)) {
          setIsDisableButton(true)
          setSkip(e => ({...e, button: true}))
        } else {
          setIsDisableButton(false)
        }
      } else {
        setIsDisableButton(false)
      }
    },
  })

  const onDownload = async params => {
    await downloadData({
      variables: params.variables,
    })
      .then(res => {
        setSkip(e => ({...e, baloon: false, button: true}))
        setIsDisableButton(true)
        params?.action?.success && params.action.success(res)
      })
      .catch(err => {
        params?.action?.error && params.action.error(err)
      })
  }

  const onCloseBaloon = async baloonId => {
    await closeBaloon({
      variables: {
        id: baloonId,
      },
    }).then(() => {
      setIsDisableButton(false)
    })
  }

  return {
    dataBaloon,
    dataButton,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  }
}
