import gql from 'graphql-tag'
// import {USER_ID} from '../../../utils/globals'

// export const UPDATE_GROUP_TASK = (idGroup, idTask) => {
//   return gql`
//     mutation {
//       update_performance_tasks(
//         _set: {group: ${idGroup}}
//         where: {
//           id: {_eq: ${idTask}}
//           performance_goal: {
//             created_by: {_eq: ${USER_ID}}
//           }
//         }
//       ) {
//         affected_rows
//       }
//     }
//   `
// }

export const UPDATE_GROUP_TASK = gql`
    mutation UpdateGroupTasks($idGroup:bigint! , $idTask:bigint!){
      update_performance_tasks(
        _set: {group: $idGroup}
        where: {
          id: {_eq: $idTask}
        }
      ) {
        affected_rows
      }
    }
  `
