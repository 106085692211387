import gql from 'graphql-tag'
export const GET_DETAIL_GOAL = gql`
  query($id: bigint) {
    performance_goals(where: {id: {_eq: $id}}) {
      id
      name
      description
      reporter
      performance_goal_group {
        id
        name
        color
      }
      global_user {
        id
        name
        avatar
      }
      kpi_list {
        id
        name
        type
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
      target
      target_stretch
      weight
      startdate
      enddate
      priority
      visibility
      attachment
      status
      date_added
      progress
      progress_percentage
      performance_tasks_aggregate {
        aggregate {
          sum {
            progress
          }
        }
      }
      total_task: performance_tasks_aggregate {
        aggregate {
          count
        }
      }
      complete_task: performance_tasks_aggregate(
        where: {status: {_in: ["COMPLETED", "ARCHIVED"]}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_ASSIGN_GOAL = gql`
  query($goal: bigint!, $offset: Int, $limit: Int) {
    total: global_users_aggregate(
      where: {
        performance_task_assignments: {performance_task: {goal: {_eq: $goal}}}
      }
    ) {
      aggregate {
        count
      }
    }
    global_users(
      where: {
        performance_task_assignments: {performance_task: {goal: {_eq: $goal}}}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      avatar
    }
  }
`

export const GET_CASCADE_GOAL = gql`
  query listCascadingByGoal($id: Int!, $offset: Int!, $limit: Int!) {
    total: performance_cascadings_aggregate(
      where: {goal: {_eq: $id}, performance_task: {deletedat: {_is_null: true}}}
      distinct_on: [task]
    ) {
      aggregate {
        count
      }
    }
    performance_cascadings(
      where: {goal: {_eq: $id}, performance_task: {deletedat: {_is_null: true}}}
      distinct_on: [task]
      offset: $offset
      limit: $limit
    ) {
      id
      performance_task {
        id
        name
      }
    }
  }
`

export const LIST_CASCADE_BY_GOAL = gql`
  query listTask(
    $where: performance_tasks_bool_exp
    $offset: Int
    $limit: Int
  ) {
    total: performance_tasks_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    performance_tasks(where: $where, offset: $offset, limit: $limit) {
      id
      name
    }
  }
`

export const SELECTED_CASCADE_BY_GOAL = gql`
  query listTask($company: uuid!, $include: [bigint!]) {
    total: performance_tasks_aggregate(
      where: {id: {_in: $include}, company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(where: {id: {_in: $include}, company: {_eq: $company}}) {
      id
      name
    }
  }
`

export const LIST_CASCADE_BY_TASK = gql`
  query listGoal(
    $where: performance_goals_bool_exp
    $offset: Int
    $limit: Int
  ) {
    total: performance_goals_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    performance_goals(where: $where, offset: $offset, limit: $limit) {
      id
      name
    }
  }
`

export const SELECTED_CASCADE_BY_TASK = gql`
  query listGoal($company: uuid!, $include: [bigint!]) {
    total: performance_goals_aggregate(
      where: {id: {_in: $include}, company: {_eq: $company}}
    ) {
      aggregate {
        count
      }
    }
    performance_goals(where: {id: {_in: $include}, company: {_eq: $company}}) {
      id
      name
    }
  }
`
