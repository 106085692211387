import React from 'react'

import {Avatar, Divider, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import {
  GET_ALL_BADGE_SQUAD,
  GET_CURRENT_BADGE_SQUAD,
} from '../../../../../../graphql/queries'
import {PARAMETERS} from '../../constant'
import {clamp} from '../../../../../../utils/helpers'
import noBadge from '../../../../../../assets/images/icon_badges_not_found.svg'

import SummaryContainer from './SummaryContainer'

const useStyles = makeStyles({
  wrapperContainer: {
    padding: '24px 20px',
  },
  titleContainer: {
    fontSize: 16,
    fontWeight: '600',
    margin: '0 0 24px',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px 0',
  },
  badgeItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 305,
  },
  badgeInfo: {maxWidth: 181},
  badge: {
    width: 100,
    height: 'auto',
    margin: 'auto',
    marginRight: 24,
    padding: 12,
    boxSizing: 'border-box',
  },
  badgeTitle: {
    fontSize: 16,
    color: '#000',
    fontWeight: '600',
    margin: '0 0 4px',
    ...clamp(1),
  },
  badgePosition: {
    fontSize: 12,
    color: '#a9a8a8',
    margin: '0 0 8px',
    ...clamp(2),
  },
  badgeClasification: {
    color: '#014a62',
    fontSize: 12,
    margin: '0 0 8px',
    ...clamp(1),
  },
  badgeScore: {
    fontSize: 24,
    fontWeight: '600',
    color: '#039be5',
  },
  dividerVertical: {
    margin: '0px 20px',
    height: 116,
  },
  badgeDetail: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  tableAxisName: {
    fontSize: 14,
    fontWeight: '600',
    margin: '0 0 16px',
  },
  tableAxis: {
    fontSize: 14,
  },
  tableAxisLabel: {
    textTransform: 'capitalize',
    minWidth: 142,
    paddingBottom: 8,
  },
  tableAxisValue: {
    color: '#014a62',
    verticalAlign: 'top',
  },
  seeMore: {
    fontSize: 16,
    color: '#039be5',
    fontWeight: '600',
    cursor: 'pointer',
    margin: '24px 0 0',
  },
})

export default function BadgesSummary({user}) {
  return (
    <SummaryContainer title="Lencana">
      <BadgeWrapper
        query={GET_CURRENT_BADGE_SQUAD}
        user={user}
        title="Lencana Terbaru"
        limit={1}
      />
      <Divider />
      <BadgeWrapper
        query={GET_ALL_BADGE_SQUAD}
        user={user}
        title="Semua Lencana"
        limit={5}
      />
    </SummaryContainer>
  )
}

const BadgeWrapper = ({title, user, query, limit}) => {
  const classes = useStyles()

  const {data, fetchMore} = useQuery(query, {
    skip: !user,
    variables: {user, limit},
  })

  const totalData = data?.total?.aggregate?.count || 0
  const totalFetch = data?.talent_assignments?.length || 0

  const fetchMoreData = (isResetResult = false) => {
    fetchMore({
      variables: {offset: isResetResult ? 0 : totalFetch},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (isResetResult) {
          return Object.assign({}, prev, {
            talent_assignments: [...prev.talent_assignments.slice(0, 5)],
          })
        } else if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          talent_assignments: [
            ...prev.talent_assignments,
            ...fetchMoreResult.talent_assignments,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const isHaveMoreData = totalData > limit && totalData > totalFetch

  return (
    <div className={classes.wrapperContainer}>
      <h3 className={classes.titleContainer}>{title}</h3>
      <div className={classes.itemContainer}>
        {data?.talent_assignments?.map(talent_assignment => {
          return (
            <BadgeItem key={talent_assignment.id} data={talent_assignment} />
          )
        })}
      </div>
      {totalData > limit && (
        <p
          className={classes.seeMore}
          onClick={() => fetchMoreData(!isHaveMoreData)}
        >
          {isHaveMoreData ? 'Lihat semua' : 'Lihat lebih sedikit'}
        </p>
      )}
    </div>
  )
}

const BadgeItem = ({data}) => {
  const classes = useStyles()

  const {
    talent_box,
    people_work_placement,
    talentVersionByTalentVersion,
    talent_score,
  } = data
  const {flexy_components} = talentVersionByTalentVersion

  const DATAX = getAxisValue(flexy_components, 'x')
  const DATAY = getAxisValue(flexy_components, 'y')

  return (
    <div className={classes.badgeItemContainer}>
      <div className={classes.badgeContainer}>
        <Avatar
          variant="square"
          src={talent_box?.badge || noBadge}
          className={classes.badge}
        />
        <div className={classes.badgeInfo}>
          <h3 className={classes.badgeTitle}>{talent_box?.name || '-'}</h3>
          <p className={classes.badgePosition}>
            {people_work_placement?.company_job_profile?.title || '-'}
          </p>
          <p className={classes.badgeClasification}>
            {talentVersionByTalentVersion?.name}
          </p>
          <span className={classes.badgeScore}>
            {talent_score.toFixed(2) || 0.0}
          </span>
        </div>
      </div>
      <Divider className={classes.dividerVertical} orientation="vertical" />
      <div className={classes.badgeDetail}>
        <AxisBadge title="X Axis" data={DATAX} />
        <AxisBadge title="Y Axis" data={DATAY} />
      </div>
    </div>
  )
}

const AxisBadge = ({title, data}) => {
  const classes = useStyles()

  return (
    <div>
      <h4 className={classes.tableAxisName}>{title}</h4>
      <table className={classes.tableAxis}>
        <tbody>
          {data?.map((row, i) => (
            <tr key={i}>
              <td className={classes.tableAxisLabel}>{row?.label}</td>
              <td className={classes.tableAxisValue}>{row?.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const getAxisValue = (flexy_components, axis) => {
  return flexy_components?.[axis]?.parameters?.map(({parameter, weight}) => {
    return {
      label: PARAMETERS[parameter] || parameter,
      value: weight,
    }
  })
}
