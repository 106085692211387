import gql from 'graphql-tag'

export const ADD_MULTIRATER_SURVEY_RESPONSE_NEW = gql`
  mutation(
    $respond_from: uuid
    $respond_to: uuid
    $survey: Int
    $question: Int
    $answer: Int
    $group: Int
    $answer_text: String
    $structure: Int
  ) {
    delete_multirater_survey_responses(
      where: {
        respond_from: {_eq: $respond_from}
        respond_to: {_eq: $respond_to}
        survey: {_eq: $survey}
        question: {_eq: $question}
      }
    ) {
      affected_rows
    }

    insert_multirater_survey_responses(
      objects: [
        {
          respond_to: $respond_to
          survey: $survey
          question: $question
          group: $group
          answer: $answer
          answer_text: $answer_text
          structure: $structure
        }
      ]
    ) {
      affected_rows
    }
  }
`
