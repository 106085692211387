import React from 'react'
import {makeStyles, Avatar} from '@material-ui/core'
import {P14Bold, PmdG} from '../../components/typography/TypographyStyles'
import PopupWrapper from './PopupWrapper'

const useStyles = makeStyles(theme => ({
  rootAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > .MuiAvatar-root': {
      width: '45px',
      height: '45px',
    },
  },
  textWraper: {
    marginLeft: '5px',
    width: '85%',
  },
  paddingContent: {
    padding: '16px 24px',
  },
  fontName: {
    fontFamily: 'muli',
    margin: '0px 0px 5px 0px',
  },
  fontPosition: {
    fontFamily: 'muli',
  },
  marginTitle: {
    margin: '24px 0px',
    fontFamily: 'muli',
  },
  marginTitleFirst: {
    marginBottom: 24,
    fontFamily: 'muli',
  },
}))

const CommitteeTalentPopup = props => {
  const {open, handleClose, pic, committee} = props
  const classes = useStyles()

  const renderAvatar = dataAvatar => {
    return dataAvatar?.map((res, i) => (
      <>
        <div className={classes.rootAvatar} key={i}>
          <Avatar alt="ok" src={res?.global_user?.avatar ?? null} />
          <div className={classes.textWraper}>
            <P14Bold className={classes.fontName}>
              {res?.global_user?.name ?? '-'}
            </P14Bold>
            <PmdG className={classes.fontPosition}>
              {res?.global_user?.people_work_placements?.[0]
                ?.company_job_profile?.title ?? '-'}
            </PmdG>
          </div>
        </div>
      </>
    ))
  }

  return (
    <>
      <PopupWrapper
        title="PIC & Committee"
        titleBackground="#ffffff"
        maxWidth="sm"
        open={open}
        withAction={false}
        onClose={handleClose}
        withCloseicon
        contentPadding="0px"
      >
        <div className={classes.paddingContent}>
          <P14Bold className={classes.marginTitleFirst}>PIC</P14Bold>
          {renderAvatar(pic)}
          <P14Bold className={classes.marginTitle}>Committee</P14Bold>
          {renderAvatar(committee)}
        </div>
      </PopupWrapper>
    </>
  )
}

export default CommitteeTalentPopup
