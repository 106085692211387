import styled from 'styled-components'

export const ListSquadWrapper = styled.div`
  margin: 24px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .list-data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .searchbar {
    margin: 0px 0 24px;
  }
`

export const SquadItemStyle = styled.div`
  width: 194px;
  min-height: 178px;
  border-radius: 5px;
  border: solid 1px #eff2f4;
  padding: 24px 14px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 13px 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .info {
    .title {
      font-family: Muli;
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      color: #014a62;
      margin: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }

    .divider {
      margin: 13px 0 11px 0;
    }

    .description {
      font-size: 12px;
      text-align: left;
      color: #333;
      margin-bottom: 10px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
    }
  }

  .status-info {
    .status {
      font-size: 12px;
      text-align: left;
    }

    .active {
      color: #4caf50;
    }
    .inactive {
      color: #ef4d5e;
    }

    .date {
      font-size: 12px;
      text-align: left;
      color: #a9a8a8;
      margin-top: 10px;
    }
  }
`

export const TitleSquad = styled.div`
  display: flex;

  .title-area {
    display: flex;
    align-items: center;

    .title-info {
      margin-left: 18px;
      width: auto;
      .title {
        font-family: Muli;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        margin: 0;
      }
      .date {
        font-family: Muli;
        font-size: 14px;
        color: #a9a8a8;
        margin: 0;
        margin-top: 4px;

        .ml {
          margin-left: 12px;
        }
        .active {
          color: #4caf50;
        }
        .inactive {
          color: #ef4d5e;
        }
      }
    }
  }

  .action {
    margin: 0;
    color: #039be5;
    font-family: Muli;
    font-size: 14px;
    text-align: left;
    min-width: 100px;
    cursor: pointer;
    padding-top: 4px;
    margin-left: 18px;
  }
`

export const DetailSquadArea = styled.div`
  .list-item {
    margin-bottom: 12px;
    display: flex;

    p {
      margin: 0;
    }

    .label {
      font-family: Muli;
      font-size: 12px;
      font-weight: 600;
      color: #a9a8a8;
      min-width: 139px;
    }

    .description {
      font-family: Muli;
      font-size: 12px;
      font-weight: 600;
      color: #000;
    }

    .active {
      color: #4caf50;
    }
    .inactive {
      color: #ef4d5e;
    }
  }
`

export const WrapperField = styled.div`
  margin-bottom: 23px;

  .row {
    display: flex;
    flex-direction: row;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-family: Muli;
    font-size: 12px;
    color: #a9a8a8;
    margin-bottom: 15px;
  }

  .date {
    max-width: 170px;
    margin-right: 25px;
  }

  .MuiOutlinedInput-input {
    font-size: 12px;
  }
`

export const ItemEditMember = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .cursor-pointer {
    cursor: pointer;
  }

  .user-info {
    display: flex;
    align-items: center;

    .avatar {
      background-color: #fafafa;
      .add-icon {
        color: #014a62;
      }
    }

    .user-detail {
      margin-left: 20px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        font-family: Muli;
        font-size: 14px;
        font-weight: 600;
        color: #000;
        margin: 0;
        margin-bottom: 2px;
      }
      .possition {
        margin: 0;
        font-family: Muli;
        font-size: 12px;
        color: #a9a8a8;
      }
    }
  }

  .close-icon {
    color: #ef4d5e;
  }
`
