import {useLazyQuery, useMutation, useQuery} from '@apollo/react-hooks'
import {
  // Icon,
  makeStyles,
} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState, useMemo} from 'react'
import {withRouter} from 'react-router-dom'
import {ADD_MULTIRATER_SURVEY_RESPONSE_NEW} from '../../../graphql/mutations'
// import NotFoundComponent from '../../../../shared-components/NotFoundComponent'
import {
  GET_LIST_HIRARCY_EMPLOYEE_MULTIRATER,
  GET_LIST_QUESION_PER_USER,
} from '../../../graphql/queries'
import {USER_ID} from '../../../utils/globals'
import {isDateBefore, isDateExpire} from '../../../utils/helpers'
import {
  getIncomingQuestion,
  getUnanswered,
  getIncomingAssessor,
  isHiddenBtn,
} from './detailMultirarterHelper'
import DetailMultiraterContent from './DetailMultiraterContent'
import DetailMultiraterHeader from './DetailMultiraterHeader'
import DetailMultiraterSidebar from './DetailMultiraterSidebar'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    height: 81,
    boxShadow: '0px 3px 6px #0000001A',
    justifyContent: 'space-between',
    padding: '0 98px',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: 1,
    position: 'sticky',
    top: 0,
    background: '#fff',
  },
  pointer: {cursor: 'pointer'},
  body: {
    padding: '22px 86px',
    background: '#F7F8F9 0% 0% no-repeat padding-box',
    display: 'flex',
    minHeight: 'calc(100vh - 81px - 44px)',
  },
  sidebar: {width: '24vw', marginRight: '1vw'},
  sidebarTitle: {padding: '14px 20px', fontWeight: 700},
  wrapBtnSubmit: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {width: '75vw', padding: '20px 24px'},
  jobprogile: {
    marginLeft: 12,
  },
  questionDropdown: {
    cursor: 'pointer',
    marginLeft: 12,
    width: 200,
  },
  loading: {
    backgroundColor: '#fff',
    marginLeft: 10,
    marginTop: '25vh',
    borderRadius: '5px',
    width: '100%',
    fontFamily: 'Muli',
    height: '100vh',
  },
  questionContainer: {
    margin: '37px 0 60px',
    textAlign: 'center',
    minHeight: '30rem',
  },
  avatarQuestion: {
    margin: 'auto',
    marginBottom: 8,
    width: '125px !important',
    height: '125px !important',
  },
  scoreStep: {
    display: 'flex',
    flexDirection: 'row',
    color: 'grey',
    margin: '20px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  select: {padding: '8px 0', paddingRight: 39},
  bold: {fontWeight: 700},
  expandIcon: {padding: 0},
  btnNext: {marginLeft: 'auto'},
  btnPrev: {marginRight: 'auto'},
}))

const DetailMultirater = props => {
  const survey = props.match.params.id
  const {enqueueSnackbar} = useSnackbar()
  const classes = useStyles()
  const [selected, setSelected] = useState({
    type: '',
    id: '',
  })
  const [questionId, setQuestionId] = useState('')
  const [answer, setAnswer] = useState(null)
  const [answerText, setAnswerText] = useState(null)
  const [sidebarData, setSidebarData] = useState([])
  const [group, setGroup] = useState(null)
  const [empChanged, setEmpChanged] = useState(false)
  const [btnDisabled, setDisabled] = useState(false)
  // state for managing if user choose previous from first question
  const [isPrev, setIsPrev] = useState(false)

  const {data, refetch} = useQuery(GET_LIST_HIRARCY_EMPLOYEE_MULTIRATER, {
    variables: {survey, user: USER_ID},
    fetchPolicy: 'cache-and-network',
  })

  const back = () =>
    props.history.push({
      pathname: '/survey',
      state: {activeTab: 0},
    })

  const [getData, {data: dataContent, loading: loadingContent}] = useLazyQuery(
    GET_LIST_QUESION_PER_USER,
    {
      variables: {
        survey,
        user: USER_ID,
        respondent: selected.id,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: res => {
        const _questionData = res.multirater_survey_questions
        if (_questionData.length === 0) {
          enqueueSnackbar('This feedback has no questions', {
            variant: 'error',
            autoHideDuration: 2500,
            onClose: back,
          })
        } else {
          if (!questionId || empChanged) {
            if (isPrev) {
              setQuestionId(_questionData[0]?.id)
              handleTextNumberAnswer(_questionData[0])
              setIsPrev(false)
            } else {
              const {question, questionIndex} = getUnanswered({
                multirater_survey_questions: _questionData,
                startFrom: 0,
                next: true,
              })
              const index = question ? questionIndex : 0
              setQuestionId(_questionData[index]?.id)
              handleTextNumberAnswer(_questionData[index])
            }
            setEmpChanged(false)
          }
        }
      },
    }
  )

  const [mutationAddSurveyResponse] = useMutation(
    ADD_MULTIRATER_SURVEY_RESPONSE_NEW
  )

  useEffect(() => {
    if (data) {
      const forsidebar = [
        {
          id: 6,
          title: 'Atasan Tingkat Kedua',
          length: data.second_spv.length,
          data: data.second_spv,
        },
        {
          id: 5,
          title: 'Atasan Tingkat Pertama',
          length: data.spv.length,
          data: data.spv,
        },
        {
          id: 2,
          title: 'Sesama Pegawai',
          length: data.peer.length,
          data: data.peer,
        },
        {
          id: 3,
          title: 'Bawahan Tingkat Pertama',
          length: data.subordinate.length,
          data: data.subordinate,
        },
        {
          id: 4,
          title: 'Bawahan Tingkat Kedua',
          length: data.second_sub.length,
          data: data.second_sub,
        },
        {
          id: 1,
          title: 'Diri Sendiri',
          length: data.self.length,
          data: data.self,
        },
      ]
      setSidebarData(forsidebar)
      if (!selected.id) {
        const firstData = forsidebar.filter(f => f.length > 0)
        setSelected({
          id: firstData[0].data[0].global_user.id,
          type: firstData[0].id,
        })
        getData()
      }
    }
  }, [data, selected])

  const handleTextNumberAnswer = res => {
    setAnswer(res?.multirater_responses[0]?.answer ?? '')
    setAnswerText(res?.multirater_responses[0]?.answer_text ?? '')
    setGroup(res?.multirater_survey_group?.id)
  }

  // eslint-disable-next-line
  const handleExpand = panel => (event, isExpanded) => {
    setSelected({
      ...selected,
      type: isExpanded ? panel : '',
    })
  }

  const currentQuestion =
    dataContent &&
    dataContent.multirater_survey_questions.find(
      element => element.id === questionId
    )

  const currentQuestionIndex =
    dataContent &&
    dataContent.multirater_survey_questions.findIndex(i => i.id === questionId)

  const isComplete =
    data?.multirater_surveys[0].status === 'COMPLETED' ||
    isDateExpire(data?.multirater_surveys[0].enddate, new Date()) ||
    !isDateBefore(data?.multirater_surveys[0].startdate, new Date())

  const currentLayerIndex = sidebarData.findIndex(i => i.id === selected.type)
  const currentEmpIndex =
    currentLayerIndex !== -1 &&
    sidebarData[currentLayerIndex].data.findIndex(
      i => i.global_user.id === selected.id
    )

  const noAnswerChanges =
    answer === currentQuestion?.multirater_responses[0]?.answer &&
    answerText === currentQuestion?.multirater_responses[0]?.answer_text

  const addResponse = (succcess, allowEmpty = false) => {
    const onSaveResponses = () => {
      mutationAddSurveyResponse({
        variables: {
          respond_from: USER_ID,
          respond_to: selected.id,
          survey,
          group,
          answer,
          question: questionId,
          answer_text: answerText,
          structure: selected.type, // (1=self, 2 = peer, 3 = sub, 4=sc sub, 5=spv, 6=sc spv)
        },
      })
        .then(async () => {
          await refetch()
          await getData()
          setDisabled(false)
          if (succcess) {
            succcess()
          }
        })
        .catch(() => {
          enqueueSnackbar('Please choose answer', {
            variant: 'error',
            autoHideDuration: 2500,
          })
          setDisabled(false)
        })
    }

    setDisabled(true)

    if (allowEmpty) {
      if (answer === '' && !answerText) {
        succcess()
        setDisabled(false)
      } else {
        onSaveResponses()
      }
    } else {
      onSaveResponses()
    }
  }

  const isMutationNeeded = (nextFn, allowEmpty = false) => {
    const noMutationNeeded = isComplete || noAnswerChanges
    if (noMutationNeeded) {
      nextFn()
    } else {
      addResponse(() => {
        nextFn()
      }, allowEmpty)
    }
  }

  const handleSelect = (type, id) => {
    if (selected.id !== id || loadingContent) {
      isMutationNeeded(() => {
        setSelected({type, id})
        setQuestionId('')
      }, true)
    }
  }

  const handleNextPrev = next => {
    const isPrevFirstQuestion = currentQuestionIndex === 0 && !next
    const msq = dataContent?.multirater_survey_questions
    const nextEmpIndex = next ? currentEmpIndex + 1 : currentEmpIndex - 1

    const {question: incoming} = getIncomingQuestion({
      currentQuestionIndex,
      multirater_survey_questions: msq,
      next,
    })

    if (!incoming) {
      const {question} = getUnanswered({
        multirater_survey_questions: msq,
        skipIndex: currentQuestionIndex,
        startFrom: 0,
        next,
      })

      const isLastOrFirstAssessor =
        nextEmpIndex === sidebarData[currentLayerIndex].length ||
        nextEmpIndex === -1

      if (isLastOrFirstAssessor && !question) {
        const {assessor, layer} = getIncomingAssessor({
          sidebarData,
          totalQuestion: msq.length,
          currentLayerIndex,
          currentAssessorId: selected.id,
          next,
        })

        isMutationNeeded(() => {
          setSelected({
            id: assessor?.global_user.id,
            type: layer?.id,
          })
          setEmpChanged(true)
        })
      } else {
        isMutationNeeded(() => {
          if (next && question) {
            setQuestionId(question.id)
            handleTextNumberAnswer(question)
          } else {
            setSelected({
              id:
                sidebarData[currentLayerIndex].data[nextEmpIndex].global_user
                  .id,
              type: sidebarData[currentLayerIndex].id,
            })
            setEmpChanged(true)
            if (isPrevFirstQuestion) {
              setIsPrev(true)
            }
          }
        })
      }
    } else {
      isMutationNeeded(() => {
        if (isPrevFirstQuestion) {
          setSelected({
            id:
              sidebarData[currentLayerIndex].data[nextEmpIndex].global_user.id,
            type: sidebarData[currentLayerIndex].id,
          })
          setEmpChanged(true)
          setIsPrev(true)
        } else {
          // ordinary next and prev
          setQuestionId(incoming.id)
          handleTextNumberAnswer(incoming)
        }
      })
    }
  }

  const onSubmitSurvey = () => {
    isMutationNeeded(() => {
      enqueueSnackbar('Data Saved', {
        variant: 'success',
        autoHideDuration: 2500,
      })
      back()
    })
  }

  let isNpsExist = false
  let isMultiraterExist = false
  if (data) {
    data.multirater_survey_groups.some(s => {
      if (s.isnps) {
        isNpsExist = true
      } else {
        isMultiraterExist = true
      }
    })
  }

  const {next, prev} = useMemo(
    () =>
      isHiddenBtn({
        selected,
        questionId,
        sidebarData,
        questions: dataContent?.multirater_survey_questions,
      }),
    [selected, questionId, sidebarData, dataContent]
  )

  return (
    <>
      <DetailMultiraterHeader
        classes={classes}
        back={back}
        data={data}
        isNpsExist={isNpsExist}
        isMultiraterExist={isMultiraterExist}
      />
      <div className={classes.body}>
        <DetailMultiraterSidebar
          classes={classes}
          sidebarData={sidebarData}
          data={data}
          selected={selected}
          handleExpand={handleExpand}
          handleSelect={handleSelect}
          isComplete={isComplete}
          onSubmitSurvey={onSubmitSurvey}
        />
        <DetailMultiraterContent
          classes={classes}
          dataContent={dataContent}
          currentQuestionIndex={currentQuestionIndex}
          questionId={questionId}
          isComplete={isComplete}
          setQuestionId={setQuestionId}
          handleTextNumberAnswer={handleTextNumberAnswer}
          currentQuestion={currentQuestion}
          answer={answer}
          setAnswer={setAnswer}
          answerText={answerText}
          setAnswerText={setAnswerText}
          handleNextPrev={handleNextPrev}
          btnDisabled={btnDisabled || empChanged || loadingContent}
          isMutationNeeded={isMutationNeeded}
          loading={loadingContent}
          isHiddenPrev={prev}
          isHiddenNext={next}
        />
      </div>
    </>
  )
}

export default withRouter(DetailMultirater)
