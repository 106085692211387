import React from 'react'

import {Box, Divider, makeStyles, Paper} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'

const useStyles = makeStyles({
  root: {
    marginBottom: 30,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    maxWidth: 786,
  },
  rootHeader: {
    padding: '12px 20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64,
    boxSizing: 'border-box',
  },
  dateContainer: {
    display: 'flex',
  },
  date: {
    margin: 0,
    maxWidth: 183,
    '& .MuiInputBase-root': {
      height: 40,
    },
    '& fieldset': {
      borderColor: '#e5e5e5',
    },
    '& .MuiIconButton-root': {
      color: '#014a62',
    },
  },
  inputDate: {
    fontSize: 12,
  },
  dash: {
    margin: '0px 16px',
    color: '#a9a8a8',
  },
  title: {
    fontSize: 16,
    fontWeight: '600',
    color: '#055469',
    margin: 0,
  },
})

export default function SummaryContainer(props) {
  const {children} = props
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Header {...props} />
      <Divider />
      {children}
    </Paper>
  )
}

const Header = props => {
  const {title, onChangeDate, date} = props
  const classes = useStyles()

  return (
    <div className={classes.rootHeader}>
      <h2 className={classes.title}>{title}</h2>
      {onChangeDate && (
        <Box display="flex" alignItems="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputProps={{className: classes.inputDate}}
              className={classes.date}
              onChange={date => onChangeDate(date, 'start')}
              value={date?.start}
              placeholder="Tanggal Mulai"
              inputVariant="outlined"
              margin="normal"
              format="MMM dd, yyyy"
              size="small"
              InputProps={{readOnly: true}}
            />
            <div className={classes.dash}>-</div>
            <KeyboardDatePicker
              inputProps={{className: classes.inputDate}}
              className={classes.date}
              onChange={date => onChangeDate(date, 'end')}
              value={date?.end}
              placeholder="Tanggal Berakhir"
              inputVariant="outlined"
              margin="normal"
              format="MMM dd, yyyy"
              size="small"
              InputProps={{readOnly: true}}
            />
          </MuiPickersUtilsProvider>
        </Box>
      )}
    </div>
  )
}
