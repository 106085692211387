import styled from 'styled-components'

export const WrapperBtn = styled.div`
  width: 70px;
  height: 70px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 22px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`
export const WrapperItemSquare = styled.div`
  margin-left: 6px;
  padding: 16px;
  width: 100px;
  height: 100px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  .icon {
    width: 60px;
    height: auto;
    max-height: 55px;
    margin-bottom: 10px;
  }
  .text-wrapper {
    .name {
      margin: 0px;
      font-size: 12px;
      text-align: center;
      line-height: 12px;
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 76px;
    }
  }
`
export const TooltipInfo = styled.div`
  border-radius: 5px;
  background-color: #eff2f4;
  padding: 8px;
  p {
    color: #000;
    margin: 0;
    font-size: 12px;
  }
  ul {
    color: #000;
    margin: 0;
    font-size: 12px;
    margin: 12px 0px;
    padding-left: 16px;
  }
  span {
    color: #014a62;
  }
`

export const WrapperItemAtch = styled.div`
  // max-width: 501px;
  border-radius: 5px;
  display: flex;
  padding: 15px;
  margin-top: 24px;
  flex-direction: row;
  background-color: #e5e5e5;
  .img {
    width: 30px;
    height: auto;
    margin-right: 16px;
  }
  .wrapper-field {
    width: 100%;
    .wrapper-action {
      display: flex;
      .text-field {
        margin-right: 11px;
        width: 100%;
      }
    }
    .preview-wrapper {
      display: flex;
      justify-content: flex-end;
      font-size: 10px;
      padding: 0px 37px;
      margin-top: 10px;
      font-family: muli;
      align-items: center;
      .size {
        color: #333;
      }
      .dot {
        background-color: #a9a8a8;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin: 0px 7px;
      }
      .preview {
        color: #039be5;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
`
