import {TextField} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import React, {useState} from 'react'
import PopupWrapper from '../../../shared-component/PopupWrapper'
import {WrapperPopupFieldItm} from '../../PerformanceStyles'

const TextComponent = ({label, ...props}) => {
  return (
    <WrapperPopupFieldItm>
      <div className="column">
        <span className="label">{label}</span>
        <TextField size="small" variant="outlined" {...props} />
      </div>
    </WrapperPopupFieldItm>
  )
}
const DateComponent = ({label, ...props}) => {
  return (
    <div className="date">
      <span className="label">{label}</span>
      <KeyboardDatePicker
        inputVariant="outlined"
        margin="normal"
        format="MMM dd, yyyy"
        size="small"
        {...props}
      />
    </div>
  )
}

const ERROR_VALUE = [null, '']

const isNullValue = value => ERROR_VALUE.includes(value)

export default function AddEditSquadInformation({
  open,
  onSave,
  title,
  onClose,
  values,
  setValues,
  type,
  ...props
}) {
  const [isError, setIsError] = useState(false)
  const handleClose = () => onClose()

  const handleChange = (value, target) => {
    setValues(prev => ({...prev, [target]: value}))
  }

  const handleSave = () => {
    const arr = Object.keys(values).map(key => values[key])
    const isNullValue = ERROR_VALUE.some(r => arr.indexOf(r) >= 0)
    if (isNullValue || values.squadName.length > 150) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 2000)
    } else {
      onSave(values)
    }
  }

  return (
    <PopupWrapper
      title={title}
      open={open}
      onClose={handleClose}
      onCancel={handleClose}
      onSave={handleSave}
      {...props}
    >
      <TextComponent
        label="Nama Tim Kerja*"
        value={values.squadName}
        placeholder="Tambah Nama Tim Kerja"
        id="squadName"
        error={
          (isError && isNullValue(values.squadName)) ||
          (isError && values.squadName.length > 150)
        }
        helperText={
          (isError &&
            isNullValue(values.squadName) &&
            'Bagian ini diperlukan ') ||
          (isError &&
            values.squadName.length > 150 &&
            "Name can't be more than 150 characters ")
        }
        onChange={e => handleChange(e.target.value, 'squadName')}
      />
      <TextComponent
        value={values.squadDescription}
        placeholder="Tambah Keterangan"
        multiline
        minRows={4}
        label="Keterangan*"
        id="squadDescription"
        error={isError && isNullValue(values.squadDescription)}
        helperText={
          isError &&
          isNullValue(values.squadDescription) &&
          'Bagian ini diperlukan'
        }
        inputProps={{ maxLength: 1500 }}
        onChange={e => handleChange(e.target.value, 'squadDescription')}
      />

      {type === 'collaboration-squad' && (
        <WrapperPopupFieldItm>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="row">
              <DateComponent
                value={values.startDate}
                label="Tanggal Mulai*"
                id="startDate"
                error={isError && isNullValue(values.startDate)}
                helperText={
                  isError &&
                  isNullValue(values.startDate) &&
                  'Bagian ini diperlukan'
                }
                onChange={date => {
                  setValues(prev => ({...prev, endDate: null}))
                  handleChange(date, 'startDate')
                }}
              />
              <DateComponent
                value={values.endDate}
                label="Tanggal Berakhir*"
                id="endDate"
                minDate={values.startDate}
                error={isError && isNullValue(values.endDate)}
                helperText={
                  isError &&
                  isNullValue(values.endDate) &&
                  'Bagian ini diperlukan'
                }
                onChange={date => handleChange(date, 'endDate')}
              />
            </div>
          </MuiPickersUtilsProvider>
        </WrapperPopupFieldItm>
      )}
    </PopupWrapper>
  )
}
