import gql from 'graphql-tag'

export const GET_LIST_ACHIEVEMENT = gql`
  query(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $label: [bigint!]
  ) {
    total: performance_tasks_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
    ) {
      aggregate {
        count
      }
    }
    performance_tasks(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        performance_goal: {
          kpi_list: {
            aspect: {_in: $aspect}
            prespective: {_in: $perspective}
            scoring: {_in: $scoringNature}
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    _and: [
                      {status: {_eq: "ACTIVE"}}
                      {company: {_eq: $company}}
                      {_not: {id: {_is_null: $isCompany}}}
                    ]
                  }
                }
              }
              {
                _and: [
                  {_not: {kpi_cascading_lists: {}}}
                  {_not: {id: {_is_null: $isPersonal}}}
                ]
              }
            ]
          }
        }
        name: {_ilike: $search}
        performance_task_assignments: {user: {_eq: $user}}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        method_type: {_in: $type}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      performance_group {
        id
        name
        color
      }
      enddate
      progress
      progress_percentage
      status
      last_modified
      weight
      priority
      target
      method_type
      performance_goal {
        id
        kpi_list {
          id
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`

export const GET_FILTER_PLACEMENT = gql`
  query listCreator(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [uuid!]
  ) {
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        global_user: {id: {_nin: $nin}}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        code
        title
      }
    }
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {regno: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
          {company_job_profile: {title: {_ilike: $search}}}
        ]
        global_user: {id: {_nin: $nin}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_ASPECT = gql`
  query listAspect(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [Int!]
  ) {
    kpi_aspects(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
    total: kpi_aspects_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_PERSPECTIVE = gql`
  query listPerspective(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [Int!]
  ) {
    kpi_perspectives(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
    total: kpi_perspectives_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_SCORING_NATURE = gql`
  query listScoringNature(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [Int!]
  ) {
    kpi_scoring_natures(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
    total: kpi_scoring_natures_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_WEIGHT_UNIT = gql`
  query listWeightUnit(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [Int!]
  ) {
    kpi_weight_units(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
    }
    total: kpi_weight_units_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [{code: {_ilike: $search}}, {name: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_LABEL = gql`
  query listLabel(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [bigint!]
  ) {
    performance_groups(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      color
    }
    total: performance_groups_aggregate(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_FILTER_GOAL_LABEL = gql`
  query listLabel(
    $company: uuid!
    $search: String
    $offset: Int
    $limit: Int!
    $nin: [uuid!]
  ) {
    performance_goal_groups(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
      color
    }
    total: performance_goal_groups_aggregate(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        name: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
