import React from 'react'
import PopupWrapper from '../../../shared-component/PopupWrapper'
import {DetailSquadArea} from '../SquadStyle'

const ListItem = ({label, description, status = ''}) => {
  const class_status = {Aktif: 'active', 'Tidak Aktif': 'inactive'}
  return (
    <div className="list-item">
      <p className="label">{label}</p>
      <p className={'description ' + class_status[status]}>{description}</p>
    </div>
  )
}

export default function PopupDetailSquad(props) {
  const {name, period, description, status} = props
  return (
    <PopupWrapper
      title="Informasi Tim Kerja"
      maxWidth="sm"
      withAction={false}
      withCloseicon
      {...props}
    >
      <DetailSquadArea>
        <ListItem label="Nama" description={name} />
        {period && <ListItem label="Periode" description={period} />}
        <ListItem label="Keterangan" description={description} />
        <ListItem label="Status" status={status} description={status} />
      </DetailSquadArea>
    </PopupWrapper>
  )
}
