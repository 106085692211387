/**
 *
 * @param {Array} multirater_survey_questions | Get from db
 * @param {Number} startFrom | Initial checker index
 * @param {Boolean} next | true if using next action, false if using previous action
 * @returns Object
 */
export const getUnanswered = ({
  multirater_survey_questions,
  startFrom = 0,
  skipIndex,
  next = true,
}) => {
  const questionSelected = multirater_survey_questions[startFrom]
  const questions = next
    ? multirater_survey_questions
    : [...multirater_survey_questions].reverse()

  const idx = next ? startFrom : questions.indexOf(questionSelected)

  const unanswered = questions.find((question, index) => {
    const isGoTo = idx <= index
    const isSkipped = skipIndex !== index
    const isUnaswered = question.multirater_responses.length === 0
    return isGoTo && isUnaswered && isSkipped
  })

  const index = questions.indexOf(unanswered)

  return {question: unanswered, questionIndex: index}
}

/**
 *
 * @param {Array} multirater_survey_questions | Get from db
 * @param {Number} currentQuestionIndex | Active question index
 * @param {Boolean} next | true if using next action, false if using previous action
 * @returns Object
 */
export const getIncomingQuestion = ({
  multirater_survey_questions,
  currentQuestionIndex,
  next,
}) => {
  const isAnswered =
    multirater_survey_questions?.[currentQuestionIndex].multirater_responses
      .length > 0
  const index = currentQuestionIndex + (next ? 1 : -1)

  const firstUnasweredQuestion = multirater_survey_questions.find(
    question => question.multirater_responses.length === 0
  )
  const isFirstUnaswered =
    multirater_survey_questions.indexOf(firstUnasweredQuestion) ===
    currentQuestionIndex

  // force jump to the previous question
  // if (click `previous` and it is the first unanswered question)
  // OR the question has already been answered
  if ((!next && isFirstUnaswered) || isAnswered) {
    return {question: multirater_survey_questions[index], questionIndex: index}
  }

  return getUnanswered({
    multirater_survey_questions,
    startFrom: index,
    next,
  })
}

/**
 * get rater list that has assessor
 * @param {array} sidebarData
 * @returns array
 */
const getAvailableRater = sidebarData =>
  sidebarData.filter(rater => rater.data.length > 0)

/**
 * get assesor in layer who uncompleted question
 * @param {object} layer
 * @param {number} totalQuestion
 * @param {uuid} currentAssessorId
 * @returns object
 */
const getUncompletedAssessor = ({
  layer,
  currentLayer,
  totalQuestion,
  currentAssessorId,
}) => {
  return layer.data.find(({global_user}) => {
    const answeredQuestion =
      global_user.multirater_responses_to_aggregate.aggregate.count
    const isSameLayer = layer.id === currentLayer.id
    const isSameUserId = currentAssessorId === global_user.id

    // `totalQuestion` decreases by 1 if the raters are the same and on the same layer
    // because the 'layer' has not been updated to the latest conditions after the mutation
    const _totalQuestion =
      isSameUserId && isSameLayer ? totalQuestion - 1 : totalQuestion

    return answeredQuestion < _totalQuestion
  })
}

const getNextRater = ({sidebarData, currentLayerIndex, next}) => {
  const currentLayer = sidebarData[currentLayerIndex]
  const availableRater = getAvailableRater(sidebarData)
  const currentLayerIndexAvail = availableRater.indexOf(currentLayer)
  return availableRater[currentLayerIndexAvail + (next ? 1 : -1)]
}

const getUncompletedQuestion = ({
  sidebarData,
  currentLayerIndex,
  currentAssessorId,
  totalQuestion,
}) => {
  let assessor, layer

  const currentLayer = sidebarData[currentLayerIndex]
  const uncompletedCurrent = getUncompletedAssessor({
    layer: currentLayer,
    currentLayer,
    totalQuestion,
    currentAssessorId,
  })

  if (uncompletedCurrent) {
    assessor = uncompletedCurrent
    layer = currentLayer
  } else {
    const availableRater = getAvailableRater(sidebarData)
    // check uncompleted assessor from first rater list
    for (const checklayer of availableRater) {
      assessor = getUncompletedAssessor({
        layer: checklayer,
        currentLayer,
        totalQuestion,
        currentAssessorId,
      })
      layer = checklayer
      if (assessor) break
    }
  }

  return {assessor, layer}
}

export const getIncomingAssessor = ({
  sidebarData,
  totalQuestion,
  currentLayerIndex,
  currentAssessorId,
  next,
}) => {
  const {assessor: a, layer: l} = getUncompletedQuestion({
    sidebarData,
    currentLayerIndex,
    currentAssessorId,
    totalQuestion,
  })
  const incomingLayer = getNextRater({sidebarData, currentLayerIndex, next})

  // if all in current feedback questions have been answered
  // assessor and layer are assigned to the next rater list
  let assessor = a || incomingLayer?.data?.[0]
  let layer = a ? l : incomingLayer

  // force to the first assessor in the previous rater list
  // if click `previous` on the first question of the first assessor
  if (!next) {
    assessor = incomingLayer?.data?.[0]
    layer = incomingLayer
  }

  return {assessor, layer}
}

export const isHiddenBtn = ({
  selected,
  sidebarData,
  questions = [],
  questionId,
}) => {
  const selectedRaterListReverse =
    [...sidebarData].reverse().find(rater => rater.data.length > 0)?.data || []
  const selectedRaterList =
    sidebarData.find(rater => rater.data.length > 0)?.data || []

  const isLastAssessor =
    selected.id ===
    selectedRaterListReverse[selectedRaterListReverse.length - 1]?.global_user
      ?.id
  const isLastQuestion = questionId === questions[questions.length - 1]?.id

  const isFirstAssessor = selected.id === selectedRaterList[0]?.global_user?.id
  const isFirstQuestion = questionId === questions[0]?.id

  return {
    next: isLastAssessor && isLastQuestion,
    prev: isFirstAssessor && isFirstQuestion,
  }
}
