import React, {useState} from 'react'

import useStyles, {
  TextFieldSearch,
  FormControlSearch,
  ContainerFilter,
  ContentFilter,
  ContainerFilterBottom,
  FlexRow,
  TextFilter,
  ApplyDiv,
  ResetDiv,
  CancelDiv,
} from '../SurveyStyles'

import {TabLabel, LabelTabs} from '../../archive-components/ArchiveStyles'

import {TitleHoverable, FlexBetween} from '../../PerformanceStyles'

import PropTypes from 'prop-types'

import SearchIcon from '@material-ui/icons/Search'
import FilterListIcon from '@material-ui/icons/FilterList'
import Moment from 'moment'

import TabsImpression from './TabsImpression'
import TabsDate from './TabsDate'
import {
  InputAdornment,
  FormControl,
  Typography,
  IconButton,
  Menu,
  MenuList,
  Box,
  Tabs,
  Grid,
} from '@material-ui/core'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function Filter(props) {
  const {filter, setFilter, target} = props
  const [tampungImpression, setTampungImpression] = useState([])

  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }

  const [dataImpression] = useState([
    {
      value: 'DISAPPOINTED',
      icon: 'sentiment_very_dissatisfied',
      color: '#ef4d5e',
      check: false,
    },
    {
      value: 'ORDINARY',
      icon: 'sentiment_neutral',
      color: '#ffa000',
      check: false,
    },
    {
      value: 'SATISFIED',
      icon: 'sentiment_satisfied_alt',
      color: '#4caf50',
      check: false,
    },
  ])

  const handleCheckedImpression = event => {
    const tImpres = []
    const perspectives = dataImpression
    const dataImpressions = perspectives.find(
      val => val.value === event.target.value
    )
    dataImpressions.check = event.target.checked

    dataImpression.map(res => {
      if (res.check) {
        tImpres.push(res.value)
      }
    })
    setTampungImpression(tImpres)
  }

  const handleResetImpression = () => {
    setTampungImpression([])
    dataImpression
      .filter(val => val.check === true)
      .map(row => {
        row.check = false
      })
  }

  const handleCheckAllImpression = () => {
    const tImpres = []
    dataImpression.map(row => {
      row.check = true
    })
    dataImpression.map(res => {
      if (res.check) {
        tImpres.push(res.value)
      }
    })
    setTampungImpression(tImpres)
  }
  const handleResetDate = () => {
    setDateFrom(null)
    setDateTo(null)
  }

  const handleResetAll = () => {
    handleResetDate()
    handleResetImpression()
  }

  const handleApply = () => {
    setFilter({
      ...filter,
      dateTo: Moment(dateTo).format('YYYY-MM-D'),
      dateFrom: Moment(dateFrom).format('YYYY-MM-D'),
      summary: tampungImpression,
    })
  }

  const classes = useStyles()

  const [openFilter, setopenFilter] = useState(null)

  const handleOpenFilter = event => {
    setopenFilter(event.currentTarget)
  }

  const handleCloseFilter = () => {
    setopenFilter(null)
  }

  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const ChangeDFrom = date => {
    setDateFrom(date)
  }
  const ChangeDTo = date => {
    setDateTo(date)
  }

  const [tabsFilter, setTabsFilter] = useState(0)

  function TabsIndex(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    }
  }

  const handleChangeFilterTab = (event, newValue) => {
    event.preventDefault()
    setTabsFilter(newValue)
  }

  const FilterTabs = tabs => {
    switch (tabs) {
      case 'Impression':
      case 'Kesan':
        return (
          <TabsImpression
            handleResetImpression={handleResetImpression}
            handleCheckedImpression={handleCheckedImpression}
            handleCheckAllImpression={handleCheckAllImpression}
            dataImpression={dataImpression}
          />
        )
      case 'Date':
      case 'Tanggal':
        return (
          <TabsDate
            handleResetDate={handleResetDate}
            dateFrom={dateFrom}
            ChangeDFrom={ChangeDFrom}
            dateTo={dateTo}
            ChangeDTo={ChangeDTo}
          />
        )
      default:
        return <h1>Tidak Ada</h1>
    }
  }

  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormControlSearch variant="outlined">
          <TextFieldSearch
            style={{backgroundColor: '#f7f8f9'}}
            placeholder="Cari"
            onChange={handleChangeFilter}
            value={filter.search_filter}
            name="search_filter"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControlSearch>
      </Grid>

      <Grid item xs={12} sm={3}>
        <FormControl
          style={{
            height: 56,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          variant="outlined"
          className={classes.filter}
        >
          <TitleHoverable
            style={{padding: 0, justifyContent: 'space-evenly', width: 120}}
            onClick={handleOpenFilter}
          >
            <FilterListIcon
              style={{padding: 0}}
              className={classes.iconFilter}
            />
            <TextFilter variant="h5">Filter</TextFilter>
          </TitleHoverable>
          <Menu
            id="simple-menu"
            anchorEl={openFilter}
            keepMounted
            open={Boolean(openFilter)}
            onClose={handleCloseFilter}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList>
              <ContainerFilter>
                <ContentFilter>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabsFilter}
                    style={{float: 'left'}}
                    onChange={handleChangeFilterTab}
                    aria-label="Vertical tabs example"
                    inkBarStyle={{background: '#014a62'}}
                  >
                    {target.map((row, key) => {
                      return (
                        <TabLabel
                          key={key}
                          label={
                            <FlexBetween style={{padding: '5px'}}>
                              <LabelTabs>{row}</LabelTabs>
                              {/* <Badge color="primary" badgeContent={1}></Badge> */}
                            </FlexBetween>
                          }
                          {...TabsIndex(key)}
                        />
                      )
                    })}
                  </Tabs>
                  <div style={{width: '270px'}}>
                    {target.map((row, key) => {
                      return (
                        <TabPanel
                          key={key}
                          className={classes.tabPanel}
                          value={tabsFilter}
                          index={key}
                        >
                          {FilterTabs(row)}
                        </TabPanel>
                      )
                    })}
                  </div>
                </ContentFilter>
                <ContainerFilterBottom>
                  <div
                    style={{
                      width: '262px',
                      height: '0',
                      border: 'solid 1px #eff2f4',
                    }}
                  ></div>
                  <FlexRow>
                    <ResetDiv onClick={handleResetAll}>
                      Atur Ulang Semua
                    </ResetDiv>
                    <CancelDiv onClick={handleCloseFilter}>Batalkan</CancelDiv>
                    <ApplyDiv onClick={handleApply}>Terapkan</ApplyDiv>
                  </FlexRow>
                </ContainerFilterBottom>
              </ContainerFilter>
            </MenuList>
          </Menu>
        </FormControl>
      </Grid>
    </>
  )
}

export default Filter
