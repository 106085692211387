import React, { useState, useEffect } from 'react'
import {
  ActivityTabContainer,
  StyledMenus,
  StyledMenuItem,
} from '../../PerformanceStyles'
import { Snackbar, Button } from '@material-ui/core'
import {
  PmdP,
  BoldTypography,
  P14Bold,
} from '../../../../components/typography/TypographyStyles'
import PostingField from '../../../../components/post/PostingField'
import { FILE_TYPE_UPLOAD } from './constant'

import { UPLOAD_URL, TOKEN } from '../../../../utils/globals'
import axios from 'axios'
import AddIcon from '@material-ui/icons/Add'
import Shorting from '@material-ui/icons/KeyboardArrowDown'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useSnackbar } from 'notistack'
import { getTypeFile } from '../../../../utils/helpers'

import Activity from './Activity'
import NoDataListComponent from '../../../shared-component/NoDataListComponent'

export default function ActivityComponent({
  loading,
  condition,
  dataACT,
  type,
  total,
  mutation,
  updateActivity,
  deleteActivity,
  updateMutationChildComment,
  updateMutationParComment,
  setShortingActivity,
  setLimitActivity,
  limitActivity,
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [more, setMore] = useState(0)
  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    const countMore = total - 2
    setMore(countMore)
  }, [total])

  const [comment, setComment] = useState({
    parComment: '',
  })
  const [valueAttachment, setValueAttachment] = useState({
    attachmentVal: [],
  })

  const [shorting, setShorting] = useState('Terbaru')
  const [showAddActivity, setShowAddActivity] = useState(false)
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })

  const snackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={3000}
        open={states.open}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{states.message}</span>}
      />
    )
  }
  function handleCloseSnackbar() {
    setStates({ ...states, open: false })
  }
  const seeMore = () => {
    if (more === 0) {
      setMore(total - 2)
      setLimitActivity(2)
    } else {
      let countMore = 0
      if (more < 10) {
        const currentMore = more
        countMore = more - currentMore
        setMore(countMore)
        setLimitActivity(total)
      } else {
        countMore = more - 10
        setMore(countMore)
        setLimitActivity(limitActivity + 10)
      }
    }
  }

  const handleChangeFile = name => e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (valueAttachment?.attachmentVal?.length ?? 0)
    if (name === 'attachmentVal') {
      tempFile.forEach(val => {
        if (fileCount > 10) {
          setStates({
            open: true,
            message: 'The number of attachments must not exceed 10',
          })
        } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
          setStates({
            open: true,
            message: 'There are file types that are not allowed',
          })
        } else if (val.size >= 1024 * 1024 * 25) {
          setStates({
            open: true,
            message: 'Some of your attachment size is more than 25MB',
          })
        } else {
          const formData = new FormData()
          formData.append('file', val)
          const _initialUpload = {
            id: val.name + val.size,
            name: val.name,
            size: val.size,
            url: '',
            loaded: 0,
            isUploading: true,
          }
          setDisabledButton(true)
          setValueAttachment(prevState => {
            return {
              ...prevState,
              attachmentVal: [
                ...(prevState.attachmentVal || []),
                _initialUpload,
              ],
            }
          })
          return axios
            .post(UPLOAD_URL, formData, {
              headers: {
                Authorization: 'Bearer ' + TOKEN,
                'Content-Type': 'multipart/form-data',
              },
              withCredentials: true,
              onUploadProgress: progressEvent => {
                setDisabledButton(true)
                setValueAttachment(prevState => {
                  const item = {
                    id: val.name + val.size,
                    name: val.name,
                    size: val.size,
                    url: '',
                    loaded: progressEvent.loaded,
                    isUploading: true,
                  }
                  const items = prevState.attachmentVal
                  const foundIndex = items.findIndex(
                    x => x.id === item.id && x.isUploading
                  )
                  items[foundIndex] = item
                  return { ...prevState, attachmentVal: items }
                })
              },
            })
            .then(response => {
              setValueAttachment(prevState => {
                const item = {
                  id: val.name + val.size,
                  name: val.name,
                  size: val.size,
                  url: response.data.url,
                  loaded: val.size,
                  isUploading: false,
                }
                setDisabledButton(false)
                const items = prevState.attachmentVal
                const foundIndex = items.findIndex(
                  x => x.id === item.id && x.isUploading
                )
                items[foundIndex] = item
                return { ...prevState, attachmentVal: items }
              })
            })
            .catch(error => {
              JSON.stringify(error)
            })
        }
      })
    }
  }

  const handleComment = event => {
    setComment({ ...comment, parComment: event.target.value })
  }
  const handleKeyPress = () => e => {
    if (e.key === 'Enter') {
      if (comment.parComment === '') {
        enqueueSnackbar('Fill the Activity first', {
          autoHideDuration: 2500,
          variant: 'error',
        })
        setShowAddActivity(false)
      } else if (!disabledButton) {
        mutation(comment.parComment, valueAttachment.attachmentVal)
        setComment({ ...comment, parComment: '' })
        setValueAttachment(() => {
          return {
            attachmentVal: [],
            attachmentComment: valueAttachment.attachmentComment,
            fileUpload: valueAttachment.fileUpload,
          }
        })
      }
    }
  }
  const handleSent = () => {
    if (comment.parComment === '') {
      enqueueSnackbar('Screen reader support enabled.', {
        autoHideDuration: 2500,
        variant: 'error',
      })
    } else if (!disabledButton) {
      const _attachmentVal = valueAttachment.attachmentVal.map(
        ({ url, name, size }) => ({
          url,
          name,
          size,
        })
      )
      mutation(comment.parComment, _attachmentVal)
      setComment({ ...comment, parComment: '' })
      setShowAddActivity(false)
      setValueAttachment(() => {
        return {
          attachmentVal: [],
          attachmentComment: valueAttachment.attachmentComment,
          fileUpload: valueAttachment.fileUpload,
        }
      })
    }
  }

  const handleDeleteAttachement = i => {
    const newState = [...valueAttachment.attachmentVal]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValueAttachment(prevState => ({ ...prevState, attachmentVal: newState }))
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...valueAttachment.attachmentVal]
    newState[i].name = e.target.value
    setValueAttachment(prevState => ({ ...prevState, attachmentVal: newState }))
  }

  const handleCancelPostField = () => {
    setComment({ ...comment, parComment: '' })
    setValueAttachment({
      attachmentVal: [],
    })
    setShowAddActivity(false)
  }

  return (
    <ActivityTabContainer style={{ padding: '24px 16px' }}>
      {showAddActivity ? (
        <>
          <div style={{ paddingLeft: '2rem', marginBottom: 40 }}>
            <P14Bold>Tambah Aktivitas Baru</P14Bold>
            <PostingField
              handleKeyPress={handleKeyPress()}
              handleTextField={handleComment}
              handleChangeFile={handleChangeFile('attachmentVal')}
              handleCancelPostField={handleCancelPostField}
              handleDeleteAttachement={handleDeleteAttachement}
              handleChangeNameFile={handleChangeNameFile}
              handleSave={handleSent}
              textValue={comment.parComment}
              placeholder="Deskripsikan aktivitas anda"
              attachmentValue={valueAttachment.attachmentVal}
              variant="add"
              disabled={
                valueAttachment?.attachmentVal?.length > 10 || disabledButton
              }
            />
          </div>
        </>
      ) : (
        condition !== 'archive' && (
          <Button
            style={{
              width: '100%',
              height: 64,
              marginBottom: 40,
            }}
            variant="contained"
            color="primary"
            onClick={() => setShowAddActivity(true)}
            startIcon={<AddIcon />}
          >
            Tambah Aktivitas
          </Button>
        )
      )}

      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <>
            <div
              {...bindTrigger(popupState)}
              style={{
                cursor: 'pointer',
                marginBottom: 20,
                paddingLeft: '2rem',
                width: '15%',
              }}
            >
              <BoldTypography
                component="span"
                style={{ verticalAlign: 'top', fontSize: 14 }}
              >
                {shorting}
              </BoldTypography>
              <Shorting fontSize="small" />
            </div>

            <StyledMenus {...bindMenu(popupState)}>
              <StyledMenuItem
                style={{ color: 'black' }}
                onClick={() => {
                  popupState.close()
                  setShorting('Terbaru')
                  setShortingActivity('desc')
                }}
              >
                Terbaru
              </StyledMenuItem>
              <StyledMenuItem
                style={{ color: 'black' }}
                onClick={() => {
                  popupState.close()
                  setShorting('Terlama')
                  setShortingActivity('asc')
                }}
              >
                Terlama
              </StyledMenuItem>
            </StyledMenus>
          </>
        )}
      </PopupState>

      {dataACT?.length > 0 ? (
        dataACT.map((res, i) => {
          const Attachment =
            res.attachment === null || res.attachment === '[]'
              ? []
              : res.attachment

          return (
            <div key={i}>
              <Activity
                condition={condition}
                res={res}
                Attachment={Attachment}
                type={type}
                updateActivity={updateActivity}
                updateMutationParComment={updateMutationParComment}
                deleteActivity={deleteActivity}
                updateMutationChildComment={updateMutationChildComment}
              />
            </div>
          )
        })
      ) : (
        <center>
          <NoDataListComponent />
        </center>
      )}
      {total > 2 && (
        <PmdP
          style={{
            paddingLeft: '2rem',
            marginBottom: '2rem',
            cursor: 'pointer',
          }}
          onClick={seeMore}
        >
          {loading
            ? 'Loading...'
            : more === 0
              ? 'Lihat lebih sedikit'
              : `Lihat semua (${more})`}
        </PmdP>
      )}
      {snackBar()}
    </ActivityTabContainer>
  )
}
