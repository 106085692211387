import React, {useState} from 'react'

import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  List,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core'

import {capitalize} from '../../../utils/helpers'
import {PRIORITY_STYLE, TYPE, TYPE_STYLE, PRIORITY} from './constant'
import {StyledMenuItem} from './GoalStyled'

const useStyles = makeStyles(theme => ({
  actionArea: {borderRadius: 5},
  root: {
    height: '100%',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardHeader: {
    paddingBottom: 0,
  },
  progress: {
    fontWeight: '600',
    fontSize: 20,
    color: theme.palette.secondary.main,
  },
  chipsRoot: {
    fontSize: 12,
    color: '#fff',
    padding: '4px 16px',
    borderRadius: 5,
    lineHeight: '15px',
  },
  avatar: {
    fontSize: 12,
    width: 30,
    height: 30,
  },
  rootList: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  rootListItem: {
    padding: '3.5px 24px',
  },
  content: {
    paddingTop: 0,
  },
}))

export default function CardItem(props) {
  const classes = useStyles()
  const {
    id,
    title,
    subheader,
    avatar,
    percentage,
    priority,
    type,
    maxAvatar = 4,
    onClick,
    listAction = [],
    style,
    disableElevation = false,
    onMouseLeave,
    typeCard,
    percentageColor,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickAction = e => {
    e.stopPropagation()
    setAnchorEl(e.currentTarget)
  }

  const handleClosePopup = e => {
    e.stopPropagation()
    setAnchorEl(null)
  }

  const handleClickMenu = (e, onClick) => {
    handleClosePopup(e)
    onClick()
  }

  const _avatar = [...(avatar || [])].filter(
    (v, i, a) => a.findIndex(t => t.id === v.id) === i
  )
  const open = Boolean(anchorEl)
  const idmore = open ? `more-${id}` : undefined
  const isGoal = typeCard === 'goal'

  return (
    <>
      <CardActionArea
        onMouseLeave={onMouseLeave}
        style={style}
        onClick={onClick}
        className={classes.actionArea}
      >
        <Card
          style={{
            boxShadow: disableElevation
              ? 'none'
              : '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
          }}
          classes={{root: classes.root}}
        >
          <CardHeader
            classes={{root: classes.cardHeader}}
            title={title}
            subheader={subheader}
            action={
              listAction?.[0] && (
                <IconButton size="small" onClick={handleClickAction}>
                  <Icon>more_horiz</Icon>
                </IconButton>
              )
            }
          />
          <CardContent className={classes.content}>
            <Box
              display="flex"
              gridGap="6px"
              marginBottom={isGoal ? '16px' : '18px'}
              flexWrap="wrap"
            >
              {_avatar?.slice(0, maxAvatar)?.map(({global_user}, i) => {
                return (
                  <Avatar
                    key={i}
                    className={classes.avatar}
                    src={global_user.avatar}
                    alt={global_user.name}
                  />
                )
              })}
              {avatar.length > maxAvatar && (
                <Avatar className={classes.avatar}>
                  +{avatar.length - maxAvatar}
                </Avatar>
              )}
            </Box>
            <Typography
              style={{
                color: percentageColor || '#039be5',
                marginBottom: isGoal ? 16 : 12,
              }}
              className={classes.progress}
            >
              {percentage ? percentage.toFixed(2) : 0}%
            </Typography>
            <Box display="flex" gridGap="6px" flexWrap="wrap">
              <Chips
                color={PRIORITY_STYLE[priority] || '#FFFFFF'}
                label={PRIORITY[priority] || capitalize(priority || 'LOW')}
              />
              <Chips color={TYPE_STYLE[type]} label={TYPE[type]} />
            </Box>
          </CardContent>
        </Card>
      </CardActionArea>

      <Popover
        id={idmore}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        classes={{paper: classes.rootList}}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {listAction
            ?.filter(({isShow}) => isShow)
            ?.map(({label, onClick}) => (
              <StyledMenuItem
                key={label}
                className={classes.rootListItem}
                onClick={e => handleClickMenu(e, onClick)}
              >
                {label}
              </StyledMenuItem>
            ))}
        </List>
      </Popover>
    </>
  )
}

const Chips = ({color, label}) => {
  const classes = useStyles()

  return (
    <div style={{backgroundColor: color}} className={classes.chipsRoot}>
      {label}
    </div>
  )
}
