import React from 'react'
import {StyledDialog, ModalHeader} from '../detail-modal/ModalPerformanceStyles'
import {FlexBetween} from '../PerformanceStyles'
import {TextXl} from '../../../components/typography/TypographyStyles'
import {IconButton, Icon} from '@material-ui/core'
import ModalPersonal from './Modalpersonal'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

export default function ModalEdit(props) {
  const {open, onBack, dataEdit, type} = props
  return (
    <StyledDialog
      onClose={onBack}
      open={open}
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <ModalHeader>
        <FlexBetween>
          <TextXl>{`Ubah ${
            type === 'edit-goal'
              ? GLOBAL_GLOSSARY.performance.Goal_short
              : GLOBAL_GLOSSARY.performance.Task
          } "${dataEdit?.name}"`}</TextXl>
          <IconButton aria-label="close" onClick={onBack}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <ModalPersonal onBack={onBack} type={type} dataEdit={dataEdit} />
    </StyledDialog>
  )
}
