import gql from 'graphql-tag'

export const GET_LIST_NPS = gql`
  query getNPS($user: uuid, $limit: Int) {
    user: global_users(where: {id: {_eq: $user}}) {
      id
      name
      avatar
      people_work_placements {
        id
        company_employee_position {
          id
          name
        }
        company_address {
          id
          office_name
        }
      }
    }
    multirater_surveys(
      where: {
        multirater_survey_groups: {isnps: {_eq: true}}
        multirater_respondents: {user: {_eq: $user}}
      }
      limit: $limit
    ) {
      id
      title
      startdate
      enddate
      status
      multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_survey_groups(where: {isnps: {_eq: true}}) {
        id
        name
        multirater_survey_questions {
          dectators: multirater_responses_aggregate(
            where: {answer: {_gte: 0, _lte: 6}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
          passives: multirater_responses_aggregate(
            where: {answer: {_gte: 7, _lte: 8}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
          promotors: multirater_responses_aggregate(
            where: {answer: {_gte: 9, _lte: 10}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }

    total: multirater_surveys_aggregate(
      where: {
        multirater_survey_groups: {isnps: {_eq: true}}
        multirater_respondents: {user: {_eq: $user}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_COUNT_RESPONDEN = gql`
  query($user: uuid!, $survey: bigint!) {
    people_work_placements_aggregate(
      where: {
        status: {_eq: "ACTIVE"}
        _or: [
          {
            company_job_profile: {
              job_profile_subordinate: {
                job_profile_subordinate: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                    global_user: {
                      multirater_respondents: {
                        survey: {_eq: $survey}
                        multirater_survey: {
                          weight_settings: {_has_key: "second_supervisor"}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          {
            company_job_profile: {
              job_profile_subordinate: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $user}
                  global_user: {
                    multirater_respondents: {
                      survey: {_eq: $survey}
                      multirater_survey: {
                        weight_settings: {_has_key: "supervisor"}
                      }
                    }
                  }
                }
              }
            }
          }
          {
            company_job_profile: {
              _or: [
                {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                    global_user: {
                      multirater_respondents: {
                        survey: {_eq: $survey}
                        multirater_survey: {weight_settings: {_has_key: "peer"}}
                      }
                    }
                  }
                }
                {
                  job_profile_supervisor: {
                    job_profile_subordinate: {
                      people_work_placements: {
                        status: {_eq: "ACTIVE"}
                        user: {_eq: $user}
                        global_user: {
                          multirater_respondents: {
                            survey: {_eq: $survey}
                            multirater_survey: {
                              weight_settings: {_has_key: "peer"}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              ]
            }
            user: {_neq: $user}
          }
          {
            company_job_profile: {
              job_profile_supervisor: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $user}
                  global_user: {
                    multirater_respondents: {
                      survey: {_eq: $survey}
                      multirater_survey: {
                        weight_settings: {_has_key: "subordinate"}
                      }
                    }
                  }
                }
              }
            }
          }
          {
            company_job_profile: {
              job_profile_supervisor: {
                job_profile_supervisor: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                    global_user: {
                      multirater_respondents: {
                        survey: {_eq: $survey}
                        multirater_survey: {
                          weight_settings: {_has_key: "second_subordinate"}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          {user: {_eq: $user}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DETAIL_NPS = gql`
  query getNPS($user: uuid, $survey_id: bigint!) {
    multirater_surveys(
      where: {
        id: {_eq: $survey_id}
        multirater_survey_groups: {isnps: {_eq: true}}
        multirater_respondents: {user: {_eq: $user}}
      }
    ) {
      id
      title
      startdate
      enddate
      status
      multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_survey_groups(where: {isnps: {_eq: true}}) {
        id
        name
        multirater_survey_questions {
          dectators: multirater_responses_aggregate(
            where: {answer: {_gte: 0, _lte: 6}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
          passives: multirater_responses_aggregate(
            where: {answer: {_gte: 7, _lte: 8}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
          promotors: multirater_responses_aggregate(
            where: {answer: {_gte: 9, _lte: 10}, respond_to: {_eq: $user}}
          ) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`
