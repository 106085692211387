import {
  Button,
  DialogActions,
  Divider,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {TypographyStyleds} from '../../components/typography/TypographyStyles'
import {
  ModalContent,
  ModalHeader,
  StyledDialog,
} from '../performance-tabs/detail-modal/ModalPerformanceStyles'
import {ChipCascade, FlexBetween} from '../performance-tabs/PerformanceStyles'
import {trimString} from '../../utils/helpers'
import Search from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import {
  LIST_CASCADE_BY_GOAL,
  LIST_CASCADE_BY_TASK,
  SELECTED_CASCADE_BY_GOAL,
  SELECTED_CASCADE_BY_TASK,
} from '../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {ADD_CASCADE} from '../../graphql/mutations'
import {useSnackbar} from 'notistack'

const customStyle = {
  closeCascade: {
    width: '15px',
    fontSize: 15,
    color: '#014A62',
    cursor: 'pointer',
    alignSelf: 'center',
  },
  chipCascade: {display: 'flex', marginRight: 5},
  search: {color: '#014a62', width: 20, height: 20},
  divider: {margin: '20px 0px'},
  listCascade: {display: 'flex', cursor: 'pointer', marginBottom: 20},
  modalContent: {padding: '1.5rem'},
  selected: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  action: {margin: '0px 20px 24px 0px'},
  buttonCancel: {
    width: 125,
    height: 45,
    backgroundColor: '#ffffff',
    boxShadow: 'initial',
  },
  buttonConfirm: {
    width: 125,
    height: 45,
  },
}

const CascadePopup = props => {
  const {
    open,
    handleClose,
    isGoal,
    id,
    refetchCascade,
    tempCascade = [],
  } = props
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])

  useEffect(() => {
    if (tempCascade) {
      setSelected(tempCascade)
    }
  }, [tempCascade])

  const intSearch = parseInt(search)

  const whereList = isGoal
    ? {
        where: {
          performance_goal: {
            ...(!search
              ? {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: 'ACTIVE'},
                      company_job_profile: {
                        company_organization: {
                          company_job_profiles: {
                            people_work_placements: {
                              status: {_eq: 'ACTIVE'},
                              user: {_eq: USER_ID},
                            },
                          },
                        },
                      },
                    },
                  },
                }
              : ''),
            kpi_list: {
              _or: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {
                      status: {_eq: 'ACTIVE'},
                    },
                  },
                },
                {
                  _not: {
                    kpi_cascading_lists: {},
                  },
                },
              ],
            },
          },
          id: {_nin: selected},
          company: {_eq: COMPANY_ID},
          _or: Number.isNaN(intSearch)
            ? [{name: {_ilike: search ? `%${search}%` : undefined}}]
            : [
                {id: {_eq: intSearch}},
                {name: {_ilike: search ? `%${search}%` : undefined}},
              ],
        },
      }
    : {
        where: {
          ...(!search
            ? {
                global_user: {
                  people_work_placements: {
                    status: {_eq: 'ACTIVE'},
                    company_job_profile: {
                      company_organization: {
                        company_job_profiles: {
                          people_work_placements: {
                            status: {_eq: 'ACTIVE'},
                            user: {_eq: USER_ID},
                          },
                        },
                      },
                    },
                  },
                },
              }
            : ''),
          kpi_list: {
            _or: [
              {
                kpi_cascading_lists: {
                  cascading_main: {
                    status: {_eq: 'ACTIVE'},
                  },
                },
              },
              {
                _not: {
                  kpi_cascading_lists: {},
                },
              },
            ],
          },
          id: {_nin: selected},
          company: {_eq: COMPANY_ID},
          _or: Number.isNaN(intSearch)
            ? [{name: {_ilike: search ? `%${search}%` : undefined}}]
            : [
                {id: {_eq: intSearch}},
                {name: {_ilike: search ? `%${search}%` : undefined}},
              ],
        },
      }

  const querylistCascade = isGoal ? LIST_CASCADE_BY_GOAL : LIST_CASCADE_BY_TASK
  const querySelectedCascade = isGoal
    ? SELECTED_CASCADE_BY_GOAL
    : SELECTED_CASCADE_BY_TASK

  const {data: dataOptions} = useQuery(querylistCascade, {
    variables: {
      where: whereList.where,
      offset: 0,
      limit: 5,
    },
    skip: !open,
  })

  const {data: dataSelected} = useQuery(querySelectedCascade, {
    variables: {
      company: COMPANY_ID,
      include: selected,
    },
    skip: !open,
  })

  const [addCascade] = useMutation(ADD_CASCADE)

  const dataOptionCascade = isGoal
    ? dataOptions?.performance_tasks
    : dataOptions?.performance_goals

  const dataSelectedCascade = isGoal
    ? dataSelected?.performance_tasks
    : dataSelected?.performance_goals

  const showingData = dataOptionCascade?.length
  const dataCount = dataOptions?.total?.aggregate?.count

  const selectedCount = dataSelectedCascade?.length

  const handleDelete = id => {
    const newState = [...selected]
    const index = selected.indexOf(id)

    if (index > -1) {
      newState.splice(index, 1)
    }
    setSelected(newState)
  }

  const handleCloseModal = () => {
    setSearch('')
    setSelected([])
    handleClose()
  }

  const handleConfirm = () => {
    const dataAddCascade = selected.filter(
      item => tempCascade.indexOf(item) < 0
    )
    const data = dataAddCascade.map(data => ({
      goal: isGoal ? id : data,
      task: isGoal ? data : id,
    }))

    addCascade({variables: {data: data}})
      .then(() => {
        enqueueSnackbar('Success cascade link', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        refetchCascade()
      })
      .catch(() => {
        enqueueSnackbar('Add cascade link error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
    handleCloseModal()
  }

  return (
    <StyledDialog
      open={open}
      onClose={handleCloseModal}
      maxWidth="sm"
      fullWidth
      scroll="body"
    >
      <ModalHeader>
        <FlexBetween>
          <TypographyStyleds weight="600" size="18px" color="#333333">
            Add Cascade Link
          </TypographyStyleds>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <Icon>close</Icon>
          </IconButton>
        </FlexBetween>
      </ModalHeader>
      <ModalContent style={customStyle.modalContent}>
        <TextField
          margin="normal"
          variant="outlined"
          fullWidth
          style={{marginTop: 0}}
          placeholder="Search"
          onChange={event => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment className="ml-0" position="end">
                <Search style={customStyle.search} />
              </InputAdornment>
            ),
          }}
        />

        {selectedCount > 0 && (
          <>
            <div id="selected" style={customStyle.selected}>
              {dataSelectedCascade?.map(dataSelected => (
                <ChipCascade
                  style={{margin: '10px 5px 0px 0px'}}
                  key={`selected-${dataSelected.id}`}
                >
                  <FlexBetween>
                    <div style={customStyle.chipCascade}>
                      <TypographyStyleds
                        component="span"
                        size="12px"
                        color="#039be5"
                        style={{marginRight: '5px'}}
                      >
                        {`[PF-${dataSelected.id}]`}
                      </TypographyStyleds>
                      <TypographyStyleds
                        component="span"
                        size="12px"
                        color="#333333"
                      >
                        {trimString(dataSelected.name, 45)}
                      </TypographyStyleds>
                    </div>
                    <CloseIcon
                      style={customStyle.closeCascade}
                      onClick={() => handleDelete(dataSelected.id)}
                    />
                  </FlexBetween>
                </ChipCascade>
              ))}
            </div>
            <Divider style={customStyle.divider} />
          </>
        )}

        <div id="options">
          <TypographyStyleds
            size="14px"
            weight="600"
            color="#333333"
            style={{marginBottom: 20, marginTop: selectedCount === 0 ? 8 : 0}}
          >
            {`Showing ${showingData} of ${dataCount} issues`}
          </TypographyStyleds>
          {dataOptionCascade?.map(dataOption => (
            <div
              style={customStyle.listCascade}
              key={`option-${dataOption.id}`}
              onClick={() => setSelected([...selected, dataOption.id])}
            >
              <TypographyStyleds
                component="span"
                size="14px"
                color="#039be5"
                style={{marginRight: '5px'}}
              >
                {`[PF-${dataOption.id}]`}
              </TypographyStyleds>
              <TypographyStyleds component="span" size="14px" color="#333333">
                {trimString(dataOption.name, 65)}
              </TypographyStyleds>
            </div>
          ))}
        </div>
      </ModalContent>
      <DialogActions style={customStyle.action}>
        <Button
          variant="contained"
          onClick={handleCloseModal}
          style={customStyle.buttonCancel}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleConfirm}
          style={customStyle.buttonConfirm}
          disabled={selectedCount === 0}
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default CascadePopup
