import React, {useState} from 'react'

import {makeStyles, Typography} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import {
  LineClamp,
  StyledMenuItem,
  StyledMenus,
} from '../../../../PerformanceStyles'
import {
  dateFormat,
  GLOBAL_PAGINATION_RANGE,
} from '../../../../../../utils/helpers'

import TableDetail from '../TableDetail'
import TitleRow from '../TitleRow'

import {GET_LIST_TASK_SQUAD_MEMBER} from '../../../../../../graphql/queries'
import {COMPANY_ID} from '../../../../../../utils/globals'
import {GLOBAL_GLOSSARY} from '../../../../../../utils/constant'

import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {BoldTypography} from '../../../../../../components/typography/TypographyStyles'
import Shorting from '@material-ui/icons/KeyboardArrowDown'
import {
  anchorOrigin,
  listFilterTaskSquad,
  transformOrigin,
} from '../SquadFilterData'
import {Filter} from '@smartasn/wlb-utils-components'
import moment from 'moment'

const useStyles = makeStyles({
  titleRow: {width: 141},
  detail: {color: '#039be5', fontSize: 12, cursor: 'pointer'},
  rowId: {width: 54},
  date: {minWidth: 88},
  progress: {textAlign: 'right'},
  sectionRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 22,
    height: 20,
    margin: '35px 30px',
  },
  textFilter: {
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    margin: '35px 0px',
    cursor: 'pointer',
  },
})

export default function TaskTab({setDetail, user}) {
  const classes = useStyles()

  const [page, setPage] = useState(0)
  const [sorting, setSorting] = useState({
    label: 'Sorting',
    value: null,
  })
  const [filterData, setFilterData] = useState({})
  const [rowsPerPage, setRowsPerPage] = useState(GLOBAL_PAGINATION_RANGE[0])

  const {data, loading, error} = useQuery(GET_LIST_TASK_SQUAD_MEMBER, {
    skip: !user,
    fetchPolicy: 'network-only',
    variables: {
      user,
      company: COMPANY_ID,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      order: sorting.value,
      isCompany: true,
      isPersonal: true,
      ...filterData.include,
    },
  })

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'methodType') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'progress') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include[key] = {
              _gte: Math.min(...tempData),
              _lte: Math.max(...tempData),
            }
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include[key] = {
          _gte: moment(value.from).format(format),
          _lte: moment(value.to).format(format),
        }
      }

      if (key !== 'progress' && key !== 'period') {
        if (key === 'filterStatus') {
          if (value.includes('todo') && value.includes('complete')) {
            include.isComplete = true
            include.isTodo = true
          } else if (value.includes('complete')) {
            include.isComplete = true
            include.isTodo = false
          } else {
            include.isComplete = false
            include.isTodo = true
          }
        } else {
          include[key] = {_in: value}
        }
      }
    }

    setFilterData({
      ...filterData,
      include,
    })
  }

  if (error) {
    console.error(error)
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const columns = [
    {
      label: `ID ${GLOBAL_GLOSSARY.performance.Task}`,
      value: row => row.id,
      className: classes.rowId,
    },
    {
      label: 'Judul',
      value: row => {
        const isCompanyGoal =
          row?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
            ?.aggregate.count > 0

        return <TitleRow name={row.name} isCompanyGoal={isCompanyGoal} />
      },
      className: classes.titleRow,
    },
    {
      label: 'Tanggal Mulai',
      value: row => dateFormat({date: row.startdate, format: 'll'}),
      className: classes.date,
    },
    {
      label: 'Tanggal Berakhir',
      value: row => dateFormat({date: row.enddate, format: 'll'}),
      className: classes.date,
    },
    {
      label: 'Kreator',
      value: row => <LineClamp>{row?.created_by_user?.name || '-'}</LineClamp>,
    },
    {
      label: 'Progres',
      value: row => row?.progress_percentage?.toFixed(2) + '%',
      className: classes.progress,
    },
    {
      label: 'Aksi',
      value: row => (
        <span
          onClick={() =>
            setDetail(e => ({...e, open: true, id: row.id, type: 'task'}))
          }
          className={classes.detail}
        >
          Rincian
        </span>
      ),
    },
  ]

  return (
    <>
      <div className={classes.sectionRoot}>
        <PopupState variant="popover" popupId="menu-sorting">
          {popupState => (
            <>
              <div
                {...bindTrigger(popupState)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <BoldTypography
                  component="span"
                  style={{verticalAlign: 'top', fontSize: 14}}
                >
                  {sorting.label}
                </BoldTypography>
                <Shorting fontSize="small" />
              </div>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  style={{color: 'black'}}
                  onClick={() => {
                    popupState.close()
                    setSorting({
                      ...sorting,
                      label: 'Latest',
                      value: 'desc',
                    })
                  }}
                >
                  Latest
                </StyledMenuItem>
                <StyledMenuItem
                  style={{color: 'black'}}
                  onClick={() => {
                    popupState.close()
                    setSorting({
                      ...sorting,
                      label: 'Oldest',
                      value: 'asc',
                    })
                  }}
                >
                  Oldest
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>

        <Filter
          includeExclude={false}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id="filter-squad-goal"
          onApply={handleApply}
          listFilter={listFilterTaskSquad}
        >
          <Typography className={classes.textFilter}>Filter</Typography>
        </Filter>
      </div>
      <TableDetail
        columns={columns}
        loading={loading}
        data={data?.performance_tasks || []}
        count={data?.total?.aggregate?.count || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        config={{
          emptyStateProps: {
            message1: 'Maaf, Tidak ada Daftar',
            message2: `Saat ini, tidak ada ${GLOBAL_GLOSSARY.performance.Task} yang terdaftar pada anggota ini`,
            width: '300',
          },
        }}
      />
    </>
  )
}
