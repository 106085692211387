import gql from 'graphql-tag'

export const GET_FEEDBACK_FOR_ME = gql`
  query getListOGFSummary(
    $id: uuid
    $search: String
    $type_from: enum_ongoing_feedback_type
  ) {
    ongoing_feedback_lists(
      where: {
        ongoing_to: {_eq: $id}
        type_from: {_eq: $type_from}
        _or: [{global_user_from: {name: {_ilike: $search}}}]
      }
      distinct_on: [ongoing_from]
    ) {
      global_user_from {
        id
        name
        avatar
        ordinary: ongoing_feedback_from_aggregate(
          where: {ongoing_to: {_eq: $id}, summary: {_eq: "ORDINARY"}}
        ) {
          aggregate {
            count
          }
        }
        satisfied: ongoing_feedback_from_aggregate(
          where: {ongoing_to: {_eq: $id}, summary: {_eq: "SATISFIED"}}
        ) {
          aggregate {
            count
          }
        }
        disappointed: ongoing_feedback_from_aggregate(
          where: {ongoing_to: {_eq: $id}, summary: {_eq: "DISAPPOINTED"}}
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`
