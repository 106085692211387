import React from 'react'
// Style
import {
  TitleCardSummary,
  ListSummaryContainer,
  CardSummary,
  OverallContainer,
  SummaryListContainer,
  // SeeMore,
} from '../PerformanceStyles'

import {Psm, TextBig} from '../../../components/typography/TypographyStyles'

// material
import {
  CircularProgress,
  // Button
} from '@material-ui/core'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_OVERALL_SUMMARY} from '../../../graphql/queries/index'
import {GLOBAL_GLOSSARY, parameters_versions} from '../../../utils/constant'

// component
// import ListReportSummary from './ListReportSummary'

function ListSummary({
  from,
  to,
  // handle
}) {
  // const [open, setOpen] = useState(false)
  const {data, error} = useQuery(GET_OVERALL_SUMMARY, {
    variables: {
      from: from,
      to: to,
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const row = data.getPerformanceIndividualSummary
  // const handleView = () => {
  //   if (open) {
  //     setOpen(false)
  //   } else {
  //     setOpen(true)
  //   }
  // }

  return (
    <SummaryListContainer>
      <ListSummaryContainer template="repeat(4, 23%)">
        {/* Goal Overall */}
        <CardSummary>
          <TitleCardSummary>
            Keseluruhan {GLOBAL_GLOSSARY.performance.Goal_short}
          </TitleCardSummary>
          <OverallContainer>
            <TextBig>{Math.round(row && row.kpi_score)}</TextBig>
            <Psm>Keseluruhan</Psm>
          </OverallContainer>
        </CardSummary>

        {/* Multilater Overall */}
        <CardSummary>
          <TitleCardSummary>
            Keseluruhan {parameters_versions.multirater.ID}
          </TitleCardSummary>
          <OverallContainer>
            <TextBig>{Math.round(row && row.multirater_score)}</TextBig>
            <Psm>Keseluruhan</Psm>
          </OverallContainer>
        </CardSummary>

        {/* Ongoing Feedback Overall */}
        <CardSummary>
          <TitleCardSummary>
            Keseluruhan {parameters_versions.ogf.ID}
          </TitleCardSummary>
          <OverallContainer>
            <TextBig>{Math.round(row && row.ogf_score)}</TextBig>
            <Psm>Keseluruhan</Psm>
          </OverallContainer>
        </CardSummary>

        {/* NPS Overall */}
        <CardSummary>
          <TitleCardSummary>
            Keseluruhan {parameters_versions.nps.ID}
          </TitleCardSummary>
          <OverallContainer>
            <TextBig>{Math.round(row && row.nps_score)}</TextBig>
            <Psm>Keseluruhan</Psm>
          </OverallContainer>
        </CardSummary>
      </ListSummaryContainer>
      {/* {open && <ListReportSummary limit={3} offset={0} handle={handle} />} */}
      {/* <SeeMore>
        <Button
          style={{
            textTransform: 'none',
          }}
          color="secondary"
          onClick={handleView}
        >
          {open ? 'Hide' : 'View Details'}
        </Button>
      </SeeMore> */}
    </SummaryListContainer>
  )
}

export default ListSummary
