import {makeStyles, Popover, Box, Button, IconButton} from '@material-ui/core'
import React from 'react'
import {withRouter} from 'react-router'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {TitleSquad} from '../SquadStyle'
import {GenerateTooltip} from '../../PerformanceStyles'
import {trimString} from '../../../../utils/helpers'

const useStyles = makeStyles(() => ({
  wrap: {marginTop: 16},
  helperMarginLeft0: {
    '& .MuiFormHelperText-contained': {
      marginLeft: '0px',
    },
  },
  buttonWrapper: {
    minWidth: 306,
    alignSelf: 'start',
    marginLeft: 22,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rootPopover: {
    display: 'flex',
    flexDirection: 'column',
  },
  paperPopover: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  buttonAction: {
    color: '#039be5',
    padding: '10px 22px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
}))

const HeaderSquadDetail = props => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const {
    history,
    squadName,
    squadDate = '',
    onClickShowDetail,
    isActiveSquad,
    onClickGenerateReport,
    onClickSquadInformation,
    onClickSquadMember,
    isDisabledReport,
    type,
  } = props
  const classes = useStyles()

  const handleBack = () => history.goBack()

  const handleOpenEditPopover = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseEditPopover = () => {
    setAnchorEl(null)
  }

  const handleClickSquadInformation = () => {
    handleCloseEditPopover()
    onClickSquadInformation()
  }

  const handleClickSquadMember = () => {
    handleCloseEditPopover()
    onClickSquadMember()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box
      className={classes.wrap}
      alignItems="center"
      justifyContent="space-between"
      display="flex"
    >
      <TitleSquad>
        <div className="title-area">
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIcon />
          </IconButton>
          <div className="title-info">
            <h2 className="title">{trimString(squadName, 40)}</h2>
            <p className="date">
              {squadDate}
              <span
                className={
                  'status ' +
                  (isActiveSquad ? 'active' : 'inactive') +
                  (squadDate ? ' ml' : '')
                }
              >
                ({isActiveSquad ? 'Aktif' : 'Tidak Aktif'})
              </span>
            </p>
          </div>
        </div>
        <p className="action" onClick={onClickShowDetail}>
          Tampilkan Rincian
        </p>
      </TitleSquad>

      <div className={classes.buttonWrapper}>
        {type === 'direct-report-squad' && (
          <GenerateTooltip
            placement="left"
            title={
              isDisabledReport
                ? `Anda hanya dapat menghasilkan data sekali sehari. Untuk mengunduh data gunakan link yang sudah dibuat.`
                : ''
            }
          >
            <span>
              <Button
                variant={isDisabledReport ? 'contained' : 'outlined'}
                style={{marginRight: 12}}
                color="primary"
                onClick={onClickGenerateReport}
                size="large"
                disabled={isDisabledReport}
              >
                Buat Laporan
              </Button>
            </span>
          </GenerateTooltip>
        )}

        <Button
          aria-describedby={id}
          onClick={handleOpenEditPopover}
          variant="contained"
          color="primary"
          size="large"
        >
          Ubah Tim Kerja
        </Button>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseEditPopover}
          classes={{
            root: classes.rootPopover,
            paper: classes.paperPopover,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Button
            className={classes.buttonAction}
            onClick={handleClickSquadInformation}
          >
            Ubah Informasi Tim Kerja
          </Button>
          <Button
            className={classes.buttonAction}
            onClick={handleClickSquadMember}
          >
            Ubah Anggota Tim Kerja
          </Button>
        </Popover>
      </div>
    </Box>
  )
}

export default withRouter(HeaderSquadDetail)
