import gql from 'graphql-tag'

export const GET_LIST_GROUP = gql`
  query getLabelTask($search: performance_groups_bool_exp) {
    performance_groups(where: $search) {
      id
      name
      color
      user
    }
  }
`

export const GET_LIST_GOAL_GROUP = gql`
  query($search: performance_goal_groups_bool_exp) {
    performance_goal_groups(where: $search) {
      id
      name
      color
      added_by
    }
  }
`

export const CHECK_GROUP_TASK = gql`
  query($group: bigint!) {
    performance_tasks(where: {group: {_eq: $group}}) {
      id
      name
    }
  }
`

export const CHECK_GROUP_GOAL = gql`
  query($group: uuid!) {
    performance_goals(where: {group: {_eq: $group}}) {
      id
      name
    }
  }
`
