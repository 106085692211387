import gql from 'graphql-tag'

export const GET_LIST_MULTIRATER_SURVEY = gql`
  query listSurvey(
    $user: uuid
    $search: String
    $from: date
    $to: date
    $second_supervisor: [Int!]
    $supervisor: [Int!]
    $peer: [Int!]
    $subordinate: [Int!]
    $second_subordinate: [Int!]
    $orderBy: [multirater_surveys_order_by!]
    $offset: Int
    $limit: Int
  ) {
    total: multirater_surveys_aggregate(
      where: {
        _or: [
          {multirater_respondents: {user: {_eq: $user}}}
          {
            _and: [
              {weight_settings: {_has_key: "second_supervisor"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $second_subordinate}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "supervisor"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $subordinate}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "peer"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $peer}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "subordinate"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $supervisor}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "second_subordinate"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $second_supervisor}
                    }
                  }
                }
              }
            ]
          }
        ]
        title: {_ilike: $search}
        startdate: {_gte: $from, _lte: $to}
        enddate: {_lte: $to}
        status: {_neq: "DRAFT"}
      }
    ) {
      aggregate {
        count
      }
    }
    multirater_surveys(
      where: {
        _or: [
          {multirater_respondents: {user: {_eq: $user}}}
          {
            _and: [
              {weight_settings: {_has_key: "second_supervisor"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $second_subordinate}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "supervisor"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $subordinate}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "peer"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $peer}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "subordinate"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $supervisor}
                    }
                  }
                }
              }
            ]
          }
          {
            _and: [
              {weight_settings: {_has_key: "second_subordinate"}}
              {
                multirater_respondents: {
                  global_user: {
                    people_work_placements: {
                      status: {_eq: "ACTIVE"}
                      job_profile: {_in: $second_supervisor}
                    }
                  }
                }
              }
            ]
          }
        ]
        title: {_ilike: $search}
        startdate: {_gte: $from, _lte: $to}
        enddate: {_lte: $to}
        status: {_neq: "DRAFT"}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      title
      type
      startdate
      enddate
      date_created
      status
      count_responded: multirater_responses_aggregate(
        where: {respond_from: {_eq: null}}
        distinct_on: respond_to
      ) {
        aggregate {
          count
        }
      }
      count_respondents: multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_responses_aggregate(
        where: {
          respond_to: {_eq: null}
          multirater_survey_group: {isnps: {_eq: false}}
        }
      ) {
        aggregate {
          avg {
            answer
          }
        }
      }
      answered: multirater_responses_aggregate(
        where: {respond_from: {_eq: $user}}
        distinct_on: [respond_to]
      ) {
        aggregate {
          count
        }
      }
      is_nps: multirater_survey_groups_aggregate(where: {isnps: {_eq: true}}) {
        aggregate {
          count
        }
      }
      is_multirater: multirater_survey_groups_aggregate(
        where: {isnps: {_eq: false}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_HIRARCY_MULTIRATER = gql`
  query($jobProfile: Int!) {
    second_supervisor: company_job_profiles(
      where: {
        job_profile_subordinate: {
          job_profile_subordinate: {id: {_eq: $jobProfile}}
        }
      }
    ) {
      id
    }

    supervisor: company_job_profiles(
      where: {job_profile_subordinate: {id: {_eq: $jobProfile}}}
    ) {
      id
    }

    peer: company_job_profiles(
      where: {
        job_profile_supervisor: {
          job_profile_subordinate: {id: {_eq: $jobProfile}}
        }
      }
    ) {
      id
    }

    subordinate: company_job_profiles(where: {parent: {_eq: $jobProfile}}) {
      id
    }

    second_subordinate: company_job_profiles(
      where: {job_profile_supervisor: {parent: {_eq: $jobProfile}}}
    ) {
      id
    }
  }
`
