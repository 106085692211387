/**
 * Function that return amount or average of task or goal
 * @param {Object} data - returned value from the database
 * @param {String} bridge - configuration logic
 * @returns {Object} - return total and average data
 */
export const getAggregate = (data, bridge) => {
  const total_bridge = `total_${bridge}`
  const avg_bridge = `avg_${bridge}`

  const total = data?.[bridge]?.aggregate?.[total_bridge] || 0
  const avg = data?.[bridge]?.aggregate?.avg?.[avg_bridge]?.toFixed(2) || 0

  return {total, avg}
}
