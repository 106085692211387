import React, {useState, useEffect} from 'react'
import FilterListIcon from '@material-ui/icons/FilterList'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import BackIcons from '@material-ui/icons/ArrowBackTwoTone'
import SearchIcon from '@material-ui/icons/Search'
import {
  Typography,
  Button,
  Avatar,
  TextField,
  MenuItem,
  InputAdornment,
  TablePagination,
  Divider,
  IconButton,
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import {
  GET_TALENT_BOXES,
  GET_LIST_TALENT_LIST,
  GET_LESS_COMMITTEE_GROUP,
} from '../../../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
import moment from 'moment'
import 'moment/locale/id'
import useStyles, {
  TitleWrapStyled,
  TitleStyled,
  InfoStyled,
} from './CommitteeTaskStyles'
import {Link} from 'react-router-dom'
import Filter from './Filter'
import Loading from '../../../../components/loading/LoadingComponent'
import {GreyTypography} from '../../../../components/typography/TypographyStyles'
import {capitalize} from '../../../../utils/helpers'
import NineBoxes from '../../../shared-component/NineBoxesComponent'
import EmptyState from '../../../../components/empty-state/EmptyStateComponent'
import IconSvg from '../../../../assets/images/icon_badges_not_found.svg'
import {parameters_versions} from '../../../../utils/constant'
import CommitteeTalentPopup from '../../../shared-component/CommitteeTalentPopup'
import HoverBadges from './HoverBadges'

const HumanCapitalTalentVersion = props => {
  const [boxes, setBoxes] = useState(null)
  const [employee, setEmployee] = useState(null)
  const [detailCommittee, setDetailCommittee] = useState(false)
  const [resultBox, setResultBox] = useState(null)
  const [filter, setFilter] = useState({
    name: '',
    data: [],
  })
  const [state, setState] = useState({
    // job_profile: [],
    badge: [],
    organization: [],
    position: [],
    office: [],
    // aspiration: [],
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [sorting, setSorting] = useState('')
  const [text, setText] = useState('')

  const classes = useStyles()
  moment.locale('id')
  const {
    match: {params},
  } = props
  const [condList, setCondList] = useState({
    job_profile: params.id_job,
    version: params.id,
    badge: null,
    organization: null,
    position: null,
    office: null,
    aspiration: null,
    filter: false,
    orderName: null,
    orderScore: null,
    searchName: null,
    orderLatest: null,
  })
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [page, setPage] = React.useState(0)
  const condBoxes = {
    id: params.id,
    organization: condList.organization,
    position: condList.position,
    office: condList.office,
    aspiration: condList.aspiration,
    filter: condList.filter,
    search: condList.searchName,
    job_profile: condList.job_profile,
  }

  const {data: dataCommittee, error: errorCommittee} = useQuery(
    GET_LESS_COMMITTEE_GROUP,
    {
      variables: {
        group: params.id_group,
      },
    }
  )
  const {data: dataList, refetch, error: errList} = useQuery(
    GET_LIST_TALENT_LIST,
    {
      variables: condList,
    }
  )
  const {data: dataBoxes, refetch: refetchBoxes, error: errBoxes} = useQuery(
    GET_TALENT_BOXES,
    {
      variables: condBoxes,
    }
  )

  useEffect(() => {
    const condList = () => {
      refetch()
      refetchBoxes()
      setBoxes(dataBoxes)
      setEmployee(dataList)
    }
    condList()
  }, [dataList, dataBoxes])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSorting = event => {
    setSorting(event.target.value)
    switch (event.target.value) {
      case 'latest':
        setCondList({
          ...condList,
          orderLatest: 'desc',
          orderName: null,
          orderScore: null,
        })

        break
      case 'oldest':
        setCondList({
          ...condList,
          orderLatest: 'asc',
          orderName: null,
          orderScore: null,
        })

        break
      case 'name(a-z)':
        setCondList({
          ...condList,
          orderName: 'asc',
          orderLatest: null,
          orderScore: null,
        })

        break
      case 'scoreAsc':
        setCondList({
          ...condList,
          orderScore: 'asc',
          orderLatest: null,
          orderName: null,
        })

        break
      case 'scoreDesc':
        setCondList({
          ...condList,
          orderScore: 'desc',
          orderLatest: null,
          orderName: null,
        })

        break
      default:
        break
    }
  }
  const handleClickFilter = name => {
    let data = []
    switch (name) {
      // case 'job_profile':
      //   dataBoxes &&
      //     dataBoxes.job_profile.forEach(element => {
      //       data.push({id: element.id, name: element.title})
      //     })
      //   setFilter({name: name, data: data})
      //   data = []
      //   break
      case 'badge':
        dataBoxes &&
          dataBoxes.talent_versions[0].talent_boxes.forEach(element => {
            data.push({
              id: element.id,
              name: element.name,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      case 'organization':
        dataBoxes &&
          dataBoxes.organization.forEach(element => {
            data.push({
              id: element.company_organization.id,
              name: element.company_organization.name,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      case 'position':
        dataBoxes &&
          dataBoxes.position.forEach(element => {
            data.push({
              id: element.company_employee_position.id,
              name: element.company_employee_position.name,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      case 'office':
        dataBoxes &&
          dataBoxes.office.forEach(element => {
            data.push({
              id: element.company_address.id,
              name: element.company_address.office_name,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      // case 'aspiration':
      //   dataBoxes &&
      //     dataBoxes.aspiration.forEach(element => {
      //       data.push({
      //         id: element.company_job_profile.id,
      //         name: element.company_job_profile.title,
      //       })
      //     })
      //   setFilter({name: name, data: data})
      //   data = []
      //   break
      default:
        break
    }
  }
  const handleChange = name => event => {
    const a = event.target.value
    const b = parseInt(a)
    switch (name) {
      // case 'job_profile':
      //   var job_profile = state.job_profile
      //   if (!job_profile.includes(b)) {
      //     setState(prevState => {
      //       return {
      //         ...state,
      //         job_profile: [...prevState.job_profile, b],
      //       }
      //     })
      //   } else {
      //     job_profile = job_profile.filter(e => e !== b)
      //     setState({...state, [name]: job_profile})
      //   }
      //   break
      case 'organization':
        var organization = state.organization
        if (!organization.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              organization: [...prevState.organization, b],
            }
          })
        } else {
          organization = organization.filter(e => e !== b)
          setState({...state, [name]: organization})
        }
        break
      case 'badge':
        var badge = state.badge
        if (!badge.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              badge: [...prevState.badge, b],
            }
          })
        } else {
          badge = badge.filter(e => e !== b)
          setState({...state, [name]: badge})
        }
        break
      case 'position':
        var position = state.position
        if (!position.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              position: [...prevState.position, b],
            }
          })
        } else {
          position = position.filter(e => e !== b)
          setState({...state, [name]: position})
        }
        break
      case 'office':
        var office = state.office
        if (!office.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              office: [...prevState.office, b],
            }
          })
        } else {
          office = office.filter(e => e !== b)
          setState({...state, [name]: office})
        }
        break
      // case 'aspiration':
      //   var aspiration = state.aspiration
      //   if (!aspiration.includes(b)) {
      //     setState(prevState => {
      //       return {
      //         ...state,
      //         aspiration: [...prevState.aspiration, b],
      //       }
      //     })
      //   } else {
      //     aspiration = aspiration.filter(e => e !== b)
      //     setState({...state, [name]: aspiration})
      //   }
      //   break
      default:
        break
    }
  }
  const resetFilter = name => {
    setState({...state, [name]: []})
  }
  const resetAll = () => {
    setState({
      // job_profile: [],
      badge: [],
      organization: [],
      position: [],
      office: [],
      // aspiration: [],
    })
  }
  const selectAll = name => {
    if (filter.name === name) {
      const loop = []
      filter.data.forEach(resx => {
        loop.push(resx.id)
      })
      setState({...state, [name]: loop})
    }
  }
  const handleApply = () => {
    setCondList({
      ...condList,
      // job_profile: state.job_profile.length !== 0 ? state.job_profile : null,
      version: params.id,
      badge: state.badge.length !== 0 ? state.badge : null,
      organization: state.organization.length !== 0 ? state.organization : null,
      position: state.position.length !== 0 ? state.position : null,
      office: state.office.length !== 0 ? state.office : null,
      // aspiration: state.aspiration.length !== 0 ? state.aspiration : null,
      filter:
        // state.aspiration.length !== 0 &&
        // state.job_profile.length !== 0 &&
        state.badge.length !== 0 &&
        state.organization.length !== 0 &&
        state.position.length !== 0 &&
        state.office.length !== 0,
    })
    setAnchorEl(null)
  }
  const handleSearch = event => {
    setText(event.target.value)
    setCondList({
      ...condList,
      searchName: `%${event.target.value}%`,
    })
  }

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeBage = id => {
    let tmp = condList.badge || []
    if (!tmp.includes(id)) {
      setCondList({...condList, badge: [id]})
    } else {
      tmp = tmp.filter(e => e !== id)
      setCondList({...condList, badge: null})
    }
  }

  if (
    (dataList === undefined && errList === undefined) ||
    (dataBoxes === undefined && errBoxes === undefined) ||
    (dataCommittee === undefined && errorCommittee === undefined)
  ) {
    return <Loading />
  } else if (
    errList !== undefined ||
    errBoxes !== undefined ||
    errorCommittee !== undefined
  ) {
    console.error(errList || errBoxes || errorCommittee)
  }

  const countCommittees = dataCommittee?.total_committee?.aggregate?.count
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton>
            <BackIcons
              style={{fontSize: 30, color: '#014A62'}}
              onClick={() => props.history.goBack()}
            />
          </IconButton>

          <div style={{marginLeft: '1rem'}}>
            <Typography
              variant="body1"
              style={{color: '#014A62', fontWeight: 600}}
            >
              {dataBoxes?.talent_versions?.[0]?.name}
            </Typography>
            <Typography
              className={classes.picStyle}
              onClick={() => setDetailCommittee(true)}
            >
              {`${
                dataCommittee?.pic?.[0]?.global_user?.name
              } & ${countCommittees} ${
                countCommittees > 1 ? 'Committees' : 'Committee'
              }`}
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: '140px',
            height: '40px',
            borderRadius: 5,
            backgroundColor:
              dataBoxes && dataBoxes.talent_versions[0].status === 'COMPLETED'
                ? '#4caf50'
                : dataBoxes &&
                  dataBoxes.talent_versions[0].status === 'INEVALUATION'
                ? '#039be5'
                : '#000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="body1"
            style={{color: '#fff', textAlign: 'center'}}
          >
            {dataBoxes && dataBoxes.talent_versions[0].status === 'INEVALUATION'
              ? 'Dalam Evaluasi'
              : capitalize(dataBoxes && dataBoxes.talent_versions[0].status)}
          </Typography>
        </div>
      </div>
      <Divider style={{margin: '22px -24px 28px'}} />

      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <AssignmentIndIcon />
          <TitleStyled>Klasifikasi Kinerja</TitleStyled>
        </div>
        <Button
          variant="outlined"
          className={classes.filter}
          onClick={handleClick}
        >
          <FilterListIcon />
          <Typography className={classes.textFilter}>Filter</Typography>
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '50px 0px 0px 0px',
        }}
      >
        <div
          style={{
            width: 200,
            height: 'auto',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'right',
            boxSizing: 'border-box',
            paddingRight: 10,
          }}
        >
          <GreyTypography>
            {dataBoxes &&
              dataBoxes.talent_versions[0].flexy_components &&
              dataBoxes.talent_versions[0].flexy_components.y.name}{' '}
            (
            {dataBoxes.talent_versions[0].flexy_components.y.parameters
              .map(res => parameters_versions?.[res.parameter]?.ID)
              .join(', ')}
            )
          </GreyTypography>
        </div>
        <NineBoxes
          newHeight={dataBoxes && dataBoxes.talent_versions[0].box_height}
          newWidth={dataBoxes && dataBoxes.talent_versions[0].box_width}
          data={boxes?.talent_versions[0].talent_boxes}
          badge={condList.badge}
          handleChangeBage={handleChangeBage}
          onMouseOver={setResultBox}
          elementHover={
            <HoverBadges dataBoxes={dataBoxes} resultBox={resultBox} />
          }
        />
        <div style={{width: 200, height: 'auto'}}></div>
      </div>
      <GreyTypography style={{textAlign: 'center'}}>
        {dataBoxes &&
          dataBoxes.talent_versions[0].flexy_components &&
          dataBoxes.talent_versions[0].flexy_components.x.name}{' '}
        (
        {dataBoxes?.talent_versions?.[0]?.flexy_components?.x.parameters
          ?.map(res => parameters_versions?.[res.parameter]?.ID)
          .join(', ')}
        )
      </GreyTypography>
      <br />
      <div className={classes.header}>
        <TitleWrapStyled>
          <FormatListBulletedIcon />
          <TitleStyled>Daftar Pegawai</TitleStyled>
        </TitleWrapStyled>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '2rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <TextField
            select
            variant="outlined"
            label="Urutkan Berdasarkan"
            style={{width: '50%'}}
            onChange={handleSorting}
            value={sorting}
          >
            <MenuItem value="latest" style={{color: '#a9a8a8'}}>
              Terbaru
            </MenuItem>
            <MenuItem value="oldest" style={{color: '#a9a8a8'}}>
              Terlama
            </MenuItem>
            <MenuItem value="name(a-z)" style={{color: '#a9a8a8'}}>
              Nama (A-Z)
            </MenuItem>
            <MenuItem value="scoreDesc" style={{color: '#a9a8a8'}}>
              Skor Tertinggi
            </MenuItem>
            <MenuItem value="scoreAsc" style={{color: '#a9a8a8'}}>
              Skor Terendah
            </MenuItem>
          </TextField>
        </div>
        <TextField
          className={classes.search}
          variant="outlined"
          placeholder="Cari Pegawai"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          value={text}
        />
      </div>
      <div className={classes.listContainer}>
        {employee?.talent_assignments.length === 0 ? (
          <EmptyState
            message1={'Maaf, Tidak ada Daftar'}
            message2={'Saat ini, tidak ada talenta yang terdaftar'}
            image={require('../../../../assets/images/nodatalist.png')}
          />
        ) : (
          employee?.talent_assignments
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((res, i) => {
              return (
                <div key={i} className={classes.body}>
                  <Avatar
                    alt={res.people_work_placement.global_user.name}
                    style={{width: 80, height: 80}}
                    src={res.people_work_placement.global_user.avatar}
                  />
                  <InfoStyled>
                    <div className={classes.info1}>
                      <Typography
                        className={classes.name}
                        component={Link}
                        to={{
                          pathname: `/committee/version/${params.id}/${params.id_job}/${params.id_group}/${res.people_work_placement.global_user.id}`,
                          state: {
                            newHeight:
                              dataBoxes &&
                              dataBoxes.talent_versions[0].box_height,
                            newWidth:
                              dataBoxes &&
                              dataBoxes.talent_versions[0].box_width,
                            dataBoxes,
                            idAssignment:
                              res.people_work_placement.talent_assignments[0]
                                .id,
                          },
                        }}
                        style={{cursor: 'pointer'}}
                      >
                        {res.people_work_placement.global_user.name}
                      </Typography>
                      {/* <Typography className={classes.role}>
                        {
                          res.people_work_placement.current_position
                            .company_employee_position.name
                        }{' '}
                        at{' '}
                        {
                          res.people_work_placement.current_position
                            .company_address.office_name
                        }
                      </Typography> */}
                    </div>
                    <Typography className={classes.info2} gutterBottom>
                      {/* Wants to be{' '}
                      {res.people_work_placement.talent_aspirations.length !== 0
                        ? res.people_work_placement.talent_aspirations &&
                          res.people_work_placement.talent_aspirations[0]
                            .company_job_profile.company_employee_position.name
                        : '-'} */}
                      {res.people_work_placement?.current_position?.title}
                    </Typography>
                    <div className={classes.score}>
                      <img
                        alt="profil"
                        src={
                          res.people_work_placement.talent_assignments[0]
                            .talent_box?.badge
                            ? res.people_work_placement.talent_assignments[0]
                                .talent_box.badge
                            : IconSvg
                        }
                        className={classes.profil}
                      />
                      <Typography className={classes.classification}>
                        {
                          res.people_work_placement.talent_assignments[0]
                            .talent_box?.name
                        }
                      </Typography>
                      <div className={classes.score2}>
                        <StarIcon style={{color: 'green'}} />
                        <Typography className={classes.score3}>
                          {res.people_work_placement.talent_assignments[0].talent_score.toFixed(
                            2
                          )}
                        </Typography>
                      </div>
                    </div>
                  </InfoStyled>
                  <Typography className={classes.time}>
                    {moment(
                      res.people_work_placement.talent_assignments[0]
                        .date_modified
                    ).fromNow()}
                  </Typography>
                </div>
              )
            })
        )}
      </div>
      <Divider />
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={employee && employee.talent_assignments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Baris per halaman"
        labelDisplayedRows={({from, to, count}) =>
          `${from}-${to} dari ${count}`
        }
      />

      <Filter
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleClickFilter={handleClickFilter}
        filterData={filter}
        handleChange={handleChange}
        state={state}
        handleApply={handleApply}
        resetFilter={resetFilter}
        resetAll={resetAll}
        selectAll={selectAll}
        setFilter={setFilter}
      />
      <CommitteeTalentPopup
        open={detailCommittee}
        handleClose={() => setDetailCommittee(!detailCommittee)}
        pic={dataCommittee?.pic}
        committee={dataCommittee?.committee}
      />
    </div>
  )
}

export default HumanCapitalTalentVersion
