import React, {useEffect, useState, useContext} from 'react'

import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {Button, makeStyles, CircularProgress} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import InfiniteScroll from 'react-infinite-scroll-component'
import {EmptyState} from '@smartasn/wlb-utils-components'

import {
  GET_DETAIL_CONTAINER_TASK,
  GET_LIST_ALL_DEPARTMENT_TASK,
  GET_LIST_ALL_PERSONAL_TASK,
} from '../../../../../graphql/queries'
import {DELETE_TASK, UPDATE_TASK} from '../../../../../graphql/mutations'
import {COMPANY_ID, USER_ID} from '../../../../../utils/globals'
import {QUICK_FILTER_PERSONAL} from '../../constant'

import ConfirmationPopup from '../../../../shared-component/ConfirmationPopup'
import ModalEdit from '../../../Modal/ModalEdit'
import ModalDelete from '../../../Modal/ModalDelete'
import ModalAssign from '../../modal-assign/ModalAssignTask'
import ModalTab from '../../TabDialog/ModalTab'
import SeeAll from '../SeeAll'
import Loader from '../Loader'
import TaskContainer from './TaskContainer'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'
import {DataContext} from '../../../../../contexts/ContextConfig'

const useStyles = makeStyles(() => ({
  container: {
    padding: 4,
    width: '100%',
    display: 'grid',
    gap: '30px 42px',
    boxSizing: 'border-box',
    gridTemplateColumns: 'repeat(auto-fit, 241px)',
  },
}))

const SeeAllTask = props => {
  const {type} = props.match.params
  const {dataContext, setDataContext} = useContext(DataContext)

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()
  const classes = useStyles()

  const [sort, setSort] = useState('desc')
  const [search, setSearch] = useState('')
  const [filterData, setFilterData] = useState({})
  const [dataModal, setDataModal] = useState(null)
  const [open, setOpen] = useState({
    detail: false,
    edit: false,
    delete: false,
    archive: false,
    assign: false,
  })

  useEffect(() => {
    if (dataContext) {
      setTimeout(() => {
        setDataContext(null)
      }, 1000)
    }
  }, [dataContext])

  const [deleteTask] = useMutation(DELETE_TASK)
  const [updateTask] = useMutation(UPDATE_TASK)

  const query =
    type === QUICK_FILTER_PERSONAL
      ? GET_LIST_ALL_PERSONAL_TASK
      : GET_LIST_ALL_DEPARTMENT_TASK

  const {data, loading, fetchMore, refetch} = useQuery(query, {
    fetchPolicy: 'network-only',
    variables: {
      offset: 0,
      limit: 6,
      search: `%${search}%`,
      company: COMPANY_ID,
      user: USER_ID,
      order: sort,
      status: ['TODO', 'INPROGRESS', 'DONE', 'COMPLETED'],
      isCompany: true,
      isPersonal: true,
      ...filterData.include,
    },
  })

  const handlToggleModal = type => {
    setOpen(prev => ({...prev, [type]: !prev[type]}))
  }

  const handleOpenSnackbar = (message, variant) => {
    enqueueSnackbar(message, {variant, autoHideDuration: 3000})
  }

  const onDeleteTask = () => {
    deleteTask({
      variables: {id: dataModal?.id},
    })
      .then(() => {
        refetch()
        handleOpenSnackbar('Delete Task Success', 'success')
      })
      .catch(err => {
        handleOpenSnackbar('Delete Task Error, Please try again later', 'error')
        console.error(err)
      })
  }

  const onMoveToArchive = () => {
    updateTask({
      variables: {
        id: dataModal.id,
        status: 'ARCHIVED',
        progress: dataModal.progress,
      },
    })
      .then(() => {
        refetch()
        handlToggleModal('archive')
        handleOpenSnackbar('Task updated', 'success')
      })
      .catch(err => {
        handleOpenSnackbar('Update data error, please try again', 'error')
        console.error(err)
      })
  }

  const collectedData = data?.performance_tasks?.length || 0
  const totalData = data?.total?.aggregate.count || 0

  const fetchMoreData = () => {
    fetchMore({
      variables: {offset: collectedData},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          performance_tasks: [
            ...prev.performance_tasks,
            ...fetchMoreResult.performance_tasks,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const handleElipsis = async (action, data) => {
    switch (action) {
      case 'edit':
        {
          const {data: _data} = await client.query({
            query: GET_DETAIL_CONTAINER_TASK,
            variables: {id: data.id},
          })
          setDataModal(_data.performance_tasks_by_pk)
          setOpen(prev => ({...prev, edit: true}))
        }
        break
      default:
        handlToggleModal(action)
        setDataModal(data)
        break
    }
  }

  const typeTask =
    type === 'personal'
      ? `- ${GLOBAL_GLOSSARY.performance.Task} Saya`
      : `- ${GLOBAL_GLOSSARY.performance.Task} Unit`

  return (
    <>
      <SeeAll
        title={`Semua ${GLOBAL_GLOSSARY.performance.Task} ${typeTask}`}
        type={type}
        setSort={setSort}
        search={search}
        setSearch={setSearch}
        filterData={filterData}
        setFilterData={setFilterData}
        onBack={() => props.history.goBack()}
        buttonConfig={props => (
          <Button onClick={() => handlToggleModal('assign')} {...props}>
            Menetapkan {GLOBAL_GLOSSARY.performance.Task}
          </Button>
        )}
      >
        {!loading && data && (
          <InfiniteScroll
            dataLength={collectedData}
            hasMore={collectedData < totalData}
            next={fetchMoreData}
            loader={
              <div style={{textAlign: 'center', margin: '34px auto'}}>
                <CircularProgress size={15} style={{marginRight: '10px'}} />
                <span>Loading</span>
              </div>
            }
          >
            <div className={classes.container}>
              {data?.performance_tasks?.map(({id}) => (
                <TaskContainer key={id} id={id} handleElipsis={handleElipsis} />
              ))}
            </div>
          </InfiniteScroll>
        )}
        {loading && (
          <div className={classes.container}>
            {Array.from(Array(6).keys()).map(id => (
              <Loader key={id} />
            ))}
          </div>
        )}
        {!loading && totalData === 0 && (
          <EmptyState
            message1="Maaf, Tidak ada daftar"
            message2={`Tidak ada ${GLOBAL_GLOSSARY.performance.Task} yang dibuat`}
            width="313"
          />
        )}
      </SeeAll>

      <ModalAssign
        open={open.assign}
        handleClose={() => handlToggleModal('assign')}
      />

      <ModalEdit
        open={open.edit}
        onBack={() => handlToggleModal('edit')}
        dataEdit={dataModal}
        type="edit-task"
      />

      <ModalDelete
        open={open.delete}
        handleClose={() => handlToggleModal('delete')}
        name={dataModal?.name}
        mutation={onDeleteTask}
      />

      <ConfirmationPopup
        open={open.archive}
        handleClose={() => handlToggleModal('archive')}
        name={dataModal?.name}
        fMessage="Apakah anda yakin pindah "
        lMessage=" ke Arsip?"
        feature={`Pindah ${dataModal?.name} ke Arsip?`}
        type="Konfirmasi"
        mutation={onMoveToArchive}
      />

      <ModalTab
        open={open.detail}
        handleClose={() => handlToggleModal('detail')}
        id={dataModal?.id || 0}
        type="task"
      />
    </>
  )
}

export default withRouter(SeeAllTask)
