import React, {useState, Fragment, useEffect, useContext} from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import {
  Tabs,
  Tab,
  IconButton,
  Icon,
  Typography,
  Snackbar,
  Button,
  TextField,
  makeStyles,
  Avatar,
  Tooltip,
  MenuItem,
} from '@material-ui/core'
import Detail from './Detail'
import Description from './Description'
import Task from './Task'
import Activity from './ActivityComponent'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_DETAIL_GOAL,
  GET_LIST_ACTIVITY_GOAL,
  GET_LIST_REVIEW_GOAL,
  GET_LIST_ACTIVITY_TASK,
  GET_LIST_REVIEW_TASK,
  GET_DETAIL_TAB_TASK,
  GET_STATUS_REVIEW,
  GET_ASSIGN_GOAL,
} from '../../../../graphql/queries/index'
import moment from 'moment'
import {
  StyledDialog,
  ModalHeader,
  ModalHeaderBlue,
} from '../../detail-modal/ModalPerformanceStyles'
import {
  TextXl,
  Pmd,
  BlueTypography,
  BlackTypography,
} from '../../../../components/typography/TypographyStyles'
import {FlexBetween, FlexCenter, StyledLinear} from '../../PerformanceStyles'
import {USER_ID} from '../../../../utils/globals'
import Review from './Review'
import EditIcon from '@material-ui/icons/Edit'
import ModalOwnGoal from '../../Modal/ModalOwnGoal'

import {
  ADD_ACTIVITIES_GOALS,
  UPDATE_PROGRESS_TASK,
  ADD_ACTIVITIES_TASK,
  UPDATE_ACTIVITY_GOAL,
  UPDATE_ACTIVITY_TASK,
  UPDATE_COMMENT_ACTIVITIES_GOALS,
  UPDATE_COMMENT_ACTIVITIES_TASK,
  DELETE_ACTIVITY_GOAL,
  DELETE_ACTIVITY_TASK,
  UPDATE_ACTIVITY_GOALS,
  UPDATE_ACTIVITY_TASKS,
  UPDATE_REPORTER,
  UPDATE_REPORTER_GOAL,
  LEAVE_TASK,
} from '../../../../graphql/mutations/index'
import {useSnackbar} from 'notistack'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import LoadingComponent from '../../../../components/loading/LoadingComponent'
import {progress_status} from './constant'
import {DataContext} from '../../../../contexts/ContextConfig'
import ConfirmationPopup from '../../../shared-component/ConfirmationPopup'

const ModalTab = props => {
  const {enqueueSnackbar} = useSnackbar()
  const {dataContext, setDataContext} = useContext(DataContext)
  const {isUserCanUpdate = true} = props
  const useStyles = makeStyles(() => ({
    outlinedInput: {
      padding: '9px 14px',
      background: '#fff',
    },
    progressContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '50%',
    },
    buttonSpacer: {
      marginLeft: 12,
      cursor: 'pointer',
    },
    openleaveTask: {
      cursor: 'pointer',
      margin: '8px 0px',
    },
    w55: {
      width: '50%',
    },
    weight: {marginLeft: 6, marginRight: 26},
    flexProgress: {minHeight: 38},
    avatarSpacer: {
      marginRight: 12,
      marginBottom: 8,
      cursor: 'pointer',
      width: 46,
      height: 46,
    },
    avatarSpacerReporter: {
      marginRight: 12,
      cursor: 'pointer',
      width: 46,
      height: 46,
    },
    titleReporter: {
      fontSize: 14,
      color: '#a9a8a8',
    },
    dividers: {
      padding: '24px 32px',
    },
    wrapperReporter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '12px 0',
    },
    nameReporter: {
      fontSize: 14,
    },
    rootReporterSelect: {
      minWidth: 241,
      backgroundColor: '#fff',
      marginRight: 12,
    },
    dropdownWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    avatarDropdown: {
      width: 30,
      height: 30,
      marginRight: 12,
    },
    nameDropdown: {
      fontSize: 12,
      color: '#000',
    },
    positionDropdown: {
      fontSize: 12,
      color: '#a9a8a8',
    },
    buttonSetTask: {
      margin: '24px 0px',
    },
    pfGroupWrap: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '12px 0px',
      width: '30%',
    },
    pfGroup: {
      width: '75%',
      fontSize: 14,
      margin: 0,
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      '-webkit-line-clamp': 2,
    },
    box: {
      width: 30,
      height: 30,
      borderRadius: 5,
      marginRight: 15,
    },
  }))

  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [isUpdate, setIsUpdate] = useState(false)
  const [openReporter, setOpenReporter] = useState(false)
  const [reporter, setReporter] = useState('')
  const [progs, setProgs] = useState(0)
  const [idTask, setIdTask] = useState(0)
  const [dataSetGoal, setDataSetGoal] = useState({
    open: false,
    data: null,
  })
  const [openleaveTask, setOpenLeaveTask] = useState(false)
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })
  const [more, setmore] = useState(5)
  const [shortingActivity, setShortingActivity] = useState('desc')
  const [limitActivity, setLimitActivity] = useState(2)
  const [mutationActivityGoal] = useMutation(ADD_ACTIVITIES_GOALS)
  const [updateCommentGoal] = useMutation(UPDATE_COMMENT_ACTIVITIES_GOALS)
  const [updateActivityGoal] = useMutation(UPDATE_ACTIVITY_GOAL)
  const [updateParCommentGoal] = useMutation(UPDATE_ACTIVITY_GOALS)
  const [updateActivityTask] = useMutation(UPDATE_ACTIVITY_TASK)
  const [updateParCommentTask] = useMutation(UPDATE_ACTIVITY_TASKS)
  const [mutationActivityTask] = useMutation(ADD_ACTIVITIES_TASK)
  const [deleteActivityGoal] = useMutation(DELETE_ACTIVITY_GOAL)
  const [deleteActivityTask] = useMutation(DELETE_ACTIVITY_TASK)
  const [updateCommentTask] = useMutation(UPDATE_COMMENT_ACTIVITIES_TASK)
  const [leaveTask] = useMutation(LEAVE_TASK)
  const [updateReporter] = useMutation(
    props.type === 'task' ? UPDATE_REPORTER : UPDATE_REPORTER_GOAL
  )

  const {open, message} = states

  const handleUpdate = () => {
    setIsUpdate(true)
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    setValue(newValue)
    refetch()
  }

  const skipFetchingGoals = !props.open || props.type === 'task'
  const skipFetchingTasks = !props.open || props.type !== 'task'

  const {data, error, refetch, loading} = useQuery(GET_DETAIL_GOAL, {
    variables: {id: props.id},
    skip: skipFetchingGoals,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  })

  const {
    data: dataAssignGoal,
    error: errorAssignGoal,
    refetch: refetchAssignGoal,
  } = useQuery(GET_ASSIGN_GOAL, {
    variables: {goal: props.id},
    skip: skipFetchingGoals,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  })

  const {
    data: dataR,
    loading: loadingR,
    error: errorR,
    refetch: refetchR,
  } = useQuery(GET_DETAIL_TAB_TASK, {
    variables: {id: props.id},
    skip: skipFetchingTasks,
    errorPolicy: 'ignore',
  })

  const {
    data: dataACT,
    error: errorACT,
    refetch: refetchACT,
    loading: loadingACT,
  } = useQuery(GET_LIST_ACTIVITY_GOAL, {
    variables: {
      goal: props.id,
      order: shortingActivity,
      offset: 0,
      limit: limitActivity,
    },
    skip: skipFetchingGoals || value !== 3,
    errorPolicy: 'ignore',
  })

  const {
    data: dataTACT,
    error: errorTACT,
    refetch: refetchTACT,
    loading: loadingTACT,
  } = useQuery(GET_LIST_ACTIVITY_TASK, {
    variables: {
      task: props.id,
      order: shortingActivity,
      offset: 0,
      limit: limitActivity,
    },
    skip: skipFetchingTasks || value !== 2,
    errorPolicy: 'ignore',
  })

  const {
    data: dataReview,
    error: errorReview,
    refetch: refetchReview,
  } = useQuery(GET_LIST_REVIEW_GOAL, {
    variables: {id: props.id},
    skip: skipFetchingGoals || value !== 4,
    errorPolicy: 'ignore',
  })

  const {
    data: dataTReview,
    error: errorTReview,
    refetch: refetchTReview,
  } = useQuery(GET_LIST_REVIEW_TASK, {
    variables: {id: props.id},
    skip: skipFetchingTasks || value !== 3,
    errorPolicy: 'ignore',
  })

  const {data: dataStatusReview, refetch: refetchStatusReview} = useQuery(
    GET_STATUS_REVIEW,
    {
      variables: {id: props.id},
      skip: skipFetchingTasks || value !== 3,
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    }
  )
  useEffect(() => {
    if (dataR && dataR.performance_tasks && dataR.performance_tasks[0]) {
      setProgs(dataR.performance_tasks[0].progress)
      setIdTask(dataR.performance_tasks[0].id)
    }
  }, [dataR])

  const handleCancelUpdate = () => {
    setIsUpdate(false)
    setProgs(dataR && dataR.performance_tasks[0].progress)
  }
  const [updateProgress] = useMutation(UPDATE_PROGRESS_TASK)

  const refetchAll = () => {
    refetch()
    refetchR()
    refetchACT()
    refetchTACT()
    refetchReview()
    refetchTReview()
    refetchStatusReview()
    refetchAssignGoal()
  }

  useEffect(() => {
    if (dataContext) {
      refetchAll()
    }
  }, [dataContext])

  if (
    error ||
    errorR ||
    errorACT ||
    errorTACT ||
    errorReview ||
    errorTReview ||
    errorAssignGoal
  ) {
    console.error({
      error,
      errorR,
      errorACT,
      errorTACT,
      errorReview,
      errorTReview,
      errorAssignGoal,
    })
    return 'Something went wrong.'
  }

  const dataGoals = data?.performance_goals[0]
  const dataDateGoals = {
    startDate: dataGoals?.startdate,
    endDate: dataGoals?.enddate,
  }

  const onChangeProgress = e => {
    const val = e.target.value
    // if (val < 0) {
    //   setProgs(0)
    // } else {
    setProgs(val)
    // }
  }

  const handleUpdateMutation = () => {
    const id = idTask
    const progress = progs
    updateProgress({variables: {id, progress}})
      .then(() => {
        setIsUpdate(false)
        setStates({open: true, message: 'Progress Updated'})
        setTimeout(() => {
          refetchR()
        }, 1000)
        
      })
      .catch(() => {
        setStates({open: true, message: 'Please input number(s) only'})
      })
  }
  const UpdateActivityGoal = (id, attachment) => {
    updateActivityGoal({variables: {id, attachment}})
    refetchACT()
  }
  const DeleteActivityGoal = id => {
    deleteActivityGoal({variables: {id}})
    refetchACT()
  }
  const DeleteActivityTask = id => {
    deleteActivityTask({variables: {id}})
    refetchTACT()
  }
  const UpdateActivityTask = (id, attachment) => {
    updateActivityTask({variables: {id, attachment}})
  }
  const addActivityGoals = (ParComment, ValAttachment) => {
    const data = {
      goal: props.id,
      comment: ParComment,
      attachment: ValAttachment,
    }
    mutationActivityGoal({variables: {data}})
    refetchACT()
  }
  const updateParCommentGoals = (ParComment, ValAttachment, idx) => {
    updateParCommentGoal({
      variables: {comment: ParComment, attachment: ValAttachment, id: idx},
    })
    refetchACT()
  }
  const updateParCommentTasks = (ParComment, ValAttachment, idx) => {
    updateParCommentTask({
      variables: {comment: ParComment, attachment: ValAttachment, id: idx},
    })
  }

  const updateCommentGoals = (ParComment, ValAttachment, id) => {
    updateCommentGoal({
      variables: {id: id, comment: ParComment, attachment: ValAttachment},
    })
    refetchACT()
  }
  const addActivityTask = (ParComment, ValAttachment) => {
    const data = {
      task: props.id,
      comment: ParComment,
      attachment: ValAttachment,
      is_progress: false,
      progress_percentage: 40,
    }
    mutationActivityTask({variables: {data}})
  }

  const updateCommentTasks = (ChildComment, ValAttachment, id) => {
    updateCommentTask({
      variables: {id: id, comment: ChildComment, attachment: ValAttachment},
    })
  }

  const handleMore = value => {
    if (more === value.length) {
      setmore(5)
    } else {
      if (value.length - more <= 5) {
        setmore(more + (value.length - more))
      } else {
        setmore(more + 5)
      }
    }
  }

  const handleSelectReporter = () => {
    if (!reporter) {
      enqueueSnackbar(
        `Please set an employee as ${
          props.type === 'task' ? 'task' : 'goal'
        } repoter`,
        {
          variant: 'error',
          autoHideDuration: 2500,
        }
      )
    } else {
      updateReporter({
        variables: {
          id:
            props.type === 'task'
              ? dataR.performance_tasks[0].id
              : data.performance_goals[0].id,
          reporter,
        },
      })
        .then(() => {
          setOpenReporter(false)
          setReporter('')
          enqueueSnackbar('Reporter updated', {autoHideDuration: 2500})
          if (!props.type) {
            refetch()
          }
        })
        .catch(e => {
          enqueueSnackbar(JSON.stringify(e))
        })
    }
  }

  const handleLeaveTask = () => {
    leaveTask({
      variables: {
        user: USER_ID,
        task: props.id,
      },
    })
      .then(() => {
        enqueueSnackbar('Success leave task', {
          variant: 'success',
          autoHideDuration: 2500,
        })
        setDataContext(true)
        setTimeout(() => {
          setOpenLeaveTask(false)
          handleClose()
        }, 1000)
      })
      .catch(() => {
        enqueueSnackbar('Error leave task, please try again', {
          variant: 'error',
          autoHideDuration: 2500,
        })
      })
  }

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") 
        return;
    setOpenReporter(false)
    setValue(0)
    setLimitActivity(2)
    setDataContext(null)
    props.handleClose()
  }

  const fieldReporter = (res, canEdit, isUserCanUpdate = true) => (
    <>
      <span className={classes.titleReporter}>Pelapor</span>
      <div className={classes.wrapperReporter}>
        {openReporter ? (
          <>
            <TextField
              size="small"
              select
              variant="outlined"
              classes={{
                root: classes.rootReporterSelect,
              }}
              onChange={e => setReporter(e.target.value)}
              value={reporter}
            >
              {res.performance_task_assignments.length > 0 &&
                res.performance_task_assignments.map((assign, idx) => {
                  return (
                    <MenuItem value={assign.global_user.id} key={idx}>
                      <div className={classes.dropdownWrapper}>
                        <Avatar
                          className={classes.avatarDropdown}
                          src={assign.global_user.avatar}
                        />
                        <div>
                          <div className={classes.nameDropdown}>
                            {assign.global_user.name}
                          </div>
                          <div className={classes.positionDropdown}>
                            {`${assign?.global_user?.people_work_placements?.[0]?.company_job_profile?.title} at ${assign?.global_user?.people_work_placements?.[0]?.company_profile?.brand_name}`}
                          </div>
                        </div>
                      </div>
                    </MenuItem>
                  )
                })}
            </TextField>
            <Button onClick={() => setOpenReporter(false)}>Cancel</Button>
            <Button
              onClick={handleSelectReporter}
              variant="contained"
              color="secondary"
              className={classes.buttonSpacer}
            >
              Confirm
            </Button>
          </>
        ) : (
          <>
            <Avatar
              src={
                res.ReporterUser
                  ? res.ReporterUser.avatar
                  : res.created_by_user
                  ? res.created_by_user.avatar
                  : null
              }
              className={classes.avatarSpacerReporter}
            />
            <div>
              <span className={classes.nameReporter}>
                {res.ReporterUser
                  ? res.ReporterUser.name
                  : res.created_by_user
                  ? res.created_by_user.name
                  : 'Pelapor Belum Ditentukan'}{' '}
                {isUserCanUpdate && props.condition !== 'archive' && canEdit && (
                  <EditIcon
                    onClick={() => setOpenReporter(true)}
                    style={{
                      fontSize: 12,
                      color: '#039be5',
                      cursor: 'pointer',
                      marginLeft: 6,
                    }}
                  />
                )}
              </span>
              <br></br>
              <span className={classes.titleReporter}>
                {res?.ReporterUser?.people_work_placements?.[0]
                  ?.company_job_profile?.title ||
                  res?.created_by_user?.people_work_placements?.[0]
                    ?.company_job_profile?.title ||
                  ''}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  )

  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      {props.condition === 'archive' && (
        <ModalHeaderBlue>
          <FlexBetween>
            <center style={{width: '100%'}}>
              <TextXl style={{color: 'white', fontSize: '16px'}}>
                {GLOBAL_GLOSSARY.performance.Task} ini diarsipkan
              </TextXl>
            </center>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              style={{color: 'white'}}
            >
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
        </ModalHeaderBlue>
      )}

      {loading || loadingR ? (
        <LoadingComponent />
      ) : (
        (!skipFetchingGoals || !skipFetchingTasks) && (
          <>
            <ModalHeader style={{paddingBottom: 0}}>
              {props.type === 'task'
                ? dataR.performance_tasks.map((res, i) => {
                    const formattedDateAdded = moment(res.date_added).format(
                      'lll'
                    )

                    const percentProgress = res.progress_percentage
                      ? res.progress_percentage
                      : 0
                    const resWeight = res?.kpi_weight_unit?.name
                    const canEdit =
                      res &&
                      res.performance_task_assignments &&
                      res.performance_task_assignments.find(
                        data => data.global_user.id === USER_ID
                      )

                    const createdTask = res?.created_by_user?.id === USER_ID
                    return (
                      <Fragment key={i}>
                        <FlexBetween>
                          <Pmd style={{display: 'flex'}}>
                            <BlueTypography>[{res.id}]</BlueTypography>
                            <BlackTypography>
                              {res.name ? res.name : '-'}
                            </BlackTypography>
                          </Pmd>
                          {props.condition !== 'archive' && (
                            <IconButton
                              aria-label="close"
                              onClick={handleClose}
                            >
                              <Icon>close</Icon>
                            </IconButton>
                          )}
                        </FlexBetween>
                        <Pmd style={{marginBottom: 12}}>
                          {/* {res.created_by_user.name} */}
                          Dibuat pada{' '}
                          {res.date_added ? ('at ', formattedDateAdded) : '-'}
                        </Pmd>

                        {fieldReporter(res, canEdit, isUserCanUpdate)}
                        <FlexCenter className={classes.flexProgress}>
                          <StyledLinear
                            variant="determinate"
                            color="secondary"
                            value={
                              percentProgress <= 100 ? percentProgress : 100
                            }
                            className={classes.w55}
                          />
                          {isUpdate ? (
                            <div className={classes.progressContainer}>
                              <TextField
                                type="number"
                                variant="outlined"
                                InputProps={{
                                  classes: {
                                    input: classes.outlinedInput,
                                  },
                                }}
                                value={progs}
                                style={{width: 125}}
                                onChange={onChangeProgress}
                              />
                              <Typography style={{marginLeft: 6}}>
                                {resWeight}
                              </Typography>
                              <Typography
                                style={{
                                  marginLeft: 10,
                                  marginRight: 26,
                                  color:
                                    percentProgress < 100
                                      ? '#014a62'
                                      : '#4caf50',
                                }}
                              >
                                (
                                {percentProgress < 100
                                  ? progress_status.Below
                                  : percentProgress === 100
                                  ? progress_status.Meet
                                  : percentProgress > 100
                                  ? progress_status.Exceed
                                  : '-'}
                                )
                              </Typography>
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={handleUpdateMutation}
                              >
                                Ubah
                              </Button>
                              <Button size="small" onClick={handleCancelUpdate}>
                                Batalkan
                              </Button>
                            </div>
                          ) : (
                            <Fragment>
                              <Typography
                                variant="body1"
                                style={{marginRight: 10}}
                              >
                                {res.progress} {resWeight} (
                                {percentProgress.toFixed(2)}%)
                              </Typography>
                              <Typography
                                style={{
                                  color:
                                    percentProgress < 100
                                      ? '#014a62'
                                      : '#4caf50',
                                }}
                              >
                                (
                                {percentProgress < 100
                                  ? progress_status.Below
                                  : percentProgress === 100
                                  ? progress_status.Meet
                                  : percentProgress > 100
                                  ? progress_status.Exceed
                                  : '-'}
                                )
                              </Typography>
                              {props.condition !== 'archive' &&
                                canEdit &&
                                isUserCanUpdate && (
                                  <Typography
                                    variant="body2"
                                    color="secondary"
                                    className={classes.buttonSpacer}
                                    onClick={handleUpdate}
                                  >
                                    Ubah
                                  </Typography>
                                )}
                            </Fragment>
                          )}
                        </FlexCenter>
                        <FlexCenter style={{flexWrap: 'wrap'}}>
                          {res.performance_task_assignments
                            .slice(0, more)
                            .map((assignee, index) => {
                              return (
                                <Tooltip
                                  key={index}
                                  title={
                                    assignee.global_user &&
                                    assignee.global_user.name
                                  }
                                >
                                  <Avatar
                                    key={index}
                                    alt={
                                      assignee.global_user &&
                                      assignee.global_user.name
                                    }
                                    src={
                                      assignee.global_user &&
                                      assignee.global_user.avatar
                                        ? assignee.global_user.avatar
                                        : require('../../../../assets/slicing/default-avatar.png')
                                    }
                                    className={classes.avatarSpacer}
                                  ></Avatar>
                                </Tooltip>
                              )
                            })}
                          {res.performance_task_assignments.length > 5 ? (
                            <div
                              style={{
                                width: '45px',
                                height: '45px',
                                borderRadius: '50%',
                                backgroundColor: '#a9a8a8',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#ffff',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleMore(res.performance_task_assignments)
                              }
                            >
                              <Typography variant="body1">
                                +
                                {res.performance_task_assignments.length - more}
                              </Typography>
                            </div>
                          ) : null}
                        </FlexCenter>
                        {canEdit && !createdTask && (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.buttonSetTask}
                            onClick={() => {
                              setDataSetGoal({
                                ...dataSetGoal,
                                data: res,
                                open: true,
                              })
                            }}
                          >
                            Tetapkan {GLOBAL_GLOSSARY.performance.Task} sebagai{' '}
                            {GLOBAL_GLOSSARY.performance.Goal} baru
                          </Button>
                        )}
                        {canEdit && (
                          <Typography
                            variant="body2"
                            color="secondary"
                            className={classes.openleaveTask}
                            onClick={() => setOpenLeaveTask(true)}
                          >
                            Keluar dari Rencana Aksi
                          </Typography>
                        )}
                      </Fragment>
                    )
                  })
                : data.performance_goals.map((res, i) => {
                    const formattedDateAdded = moment(res.date_added).format(
                      'll'
                    )
                    const participantId = data?.assignment?.map(
                      assign => assign?.global_user?.id
                    )
                    const canEdit = participantId?.includes(USER_ID)
                    return (
                      <Fragment key={i}>
                        <FlexBetween>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <BlueTypography>[PF-{res.id}]</BlueTypography>
                            <BlackTypography>
                              {res.name ? res.name : '-'}
                            </BlackTypography>
                          </div>
                          <IconButton aria-label="close" onClick={handleClose}>
                            <Icon>close</Icon>
                          </IconButton>
                        </FlexBetween>
                        <Pmd style={{marginBottom: 5}}>
                          Dibuat Oleh {res.global_user.name}{' '}
                          {res.date_added ? ('at ', formattedDateAdded) : '-'}
                        </Pmd>
                        <FlexBetween>
                          <div style={{width: '70%'}}>
                            {fieldReporter(
                              {
                                performance_task_assignments:
                                  dataAssignGoal?.global_users,
                                ReporterUser:
                                  dataAssignGoal?.global_users?.length > 0 &&
                                  dataAssignGoal.global_users.filter(
                                    i =>
                                      i.id ===
                                      data.performance_goals[0].reporter
                                  )[0]?.global_user,
                              },
                              canEdit,
                              isUserCanUpdate
                            )}
                          </div>
                          <div className={classes.pfGroupWrap}>
                            <div
                              className={classes.box}
                              style={{
                                backgroundColor:
                                  res?.performance_goal_group?.color ||
                                  '#e5e5e5',
                              }}
                            />
                            <Typography
                              className={classes.pfGroup}
                              gutterBottom
                              style={{
                                color: res?.performance_goal_group
                                  ? '#333333'
                                  : '#a9a8a8',
                                fontStyle: res?.performance_goal_group
                                  ? 'initial'
                                  : 'italic',
                              }}
                            >
                              {res?.performance_goal_group?.name || 'Tidak Ada'}
                            </Typography>
                          </div>
                        </FlexBetween>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <StyledLinear
                            variant="determinate"
                            color="secondary"
                            value={
                              res.progress_percentage
                                ? res.progress_percentage
                                : 0
                            }
                            className={classes.w55}
                          />
                          {`${
                            res.progress_percentage
                              ? res.progress_percentage.toFixed(2)
                              : 0
                          }%`}
                          {/* Hide Progress Goal */}
                          {/* {`${
                        res.performance_tasks_aggregate.aggregate.sum.progress
                          ? res.performance_tasks_aggregate.aggregate.sum
                              .progress
                          : '0'
                      } ${
                        res.kpi_list
                          ? res.kpi_list.kpi_weight_unit
                            ? res.kpi_list.kpi_weight_unit.name
                            : ''
                          : ''
                      } (${
                        res.progress_percentage
                          ? res.progress_percentage.toFixed(2)
                          : 0
                      }%)`} */}
                        </div>
                        <FlexCenter style={{flexWrap: 'wrap'}}>
                          {dataAssignGoal?.global_users
                            ?.slice(0, more)
                            .map((assignee, index) => {
                              return (
                                <Tooltip key={index} title={assignee?.name}>
                                  <Avatar
                                    alt={assignee?.name}
                                    src={
                                      assignee?.avatar
                                        ? assignee?.avatar
                                        : require('../../../../assets/slicing/default-avatar.png')
                                    }
                                    className={classes.avatarSpacer}
                                  ></Avatar>
                                </Tooltip>
                              )
                            })}
                          {dataAssignGoal?.global_users?.length > 5 ? (
                            <div
                              style={{
                                width: '45px',
                                height: '45px',
                                borderRadius: '50%',
                                backgroundColor: '#a9a8a8',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: '#ffff',
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleMore(dataAssignGoal?.global_users)
                              }
                            >
                              <Typography variant="body1">
                                +{dataAssignGoal?.global_users?.length - more}
                              </Typography>
                            </div>
                          ) : null}
                        </FlexCenter>
                      </Fragment>
                    )
                  })}
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
              >
                <Tab label="Rincian" />
                <Tab
                  label={
                    props.type === 'task'
                      ? 'Keterangan'
                      : GLOBAL_GLOSSARY.performance.Description_goal
                  }
                />
                {props.type === 'task' ? (
                  ''
                ) : (
                  <Tab label={GLOBAL_GLOSSARY.performance.Task} />
                )}
                <Tab label="Aktivitas" />
                <Tab label="Tinjauan" />
              </Tabs>
            </ModalHeader>
            <DialogContent dividers classes={{dividers: classes.dividers}}>
              {props.type === 'task' ? (
                <Fragment>
                  {value === 0 && <Detail data={dataR} type={'task'} />}
                  {value === 1 && <Description data={dataR} type={'task'} />}
                  {value === 2 && (
                    <Activity
                      loading={loadingTACT}
                      condition={props.condition}
                      dataACT={dataTACT?.performance_task_activities}
                      total={dataTACT?.total.aggregate.count}
                      type={'task'}
                      mutation={addActivityTask}
                      updateActivity={UpdateActivityTask}
                      deleteActivity={DeleteActivityTask}
                      updateMutationChildComment={updateCommentTasks}
                      updateMutationParComment={updateParCommentTasks}
                      setShortingActivity={setShortingActivity}
                      setLimitActivity={setLimitActivity}
                      limitActivity={limitActivity}
                    />
                  )}
                  {value === 3 && (
                    <Review
                      dataReview={dataTReview?.performance_task_activities}
                      type={'task'}
                      dataStatus={dataStatusReview}
                      refetchStatusReview={refetchStatusReview}
                      idx={props.id}
                      reporter={dataR.performance_tasks[0]}
                      weightTask={
                        dataR?.performance_tasks?.[0]?.kpi_weight_unit?.name ||
                        dataR?.performance_tasks?.[0]?.performance_goal
                          ?.kpi_list?.kpi_weight_unit?.name
                      }
                    />
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {value === 0 && <Detail data={data} type={'goal'} />}
                  {value === 1 && <Description data={data} type={'goal'} />}
                  {value === 2 && dataGoals ? (
                    <Task
                      withoutAdd={props.withoutAdd}
                      idGoals={dataGoals && dataGoals.id}
                      dataKpi={dataGoals && dataGoals.kpi_list}
                      dataDateGoal={dataDateGoals}
                    />
                  ) : null}
                  {value === 3 && (
                    <Activity
                      loading={loadingACT}
                      condition={props.condition}
                      dataACT={dataACT?.performance_goal_activities}
                      total={dataACT?.total.aggregate.count}
                      type={'goal'}
                      mutation={addActivityGoals}
                      updateActivity={UpdateActivityGoal}
                      deleteActivity={DeleteActivityGoal}
                      updateMutationChildComment={updateCommentGoals}
                      updateMutationParComment={updateParCommentGoals}
                      setShortingActivity={setShortingActivity}
                      setLimitActivity={setLimitActivity}
                      limitActivity={limitActivity}
                    />
                  )}
                  {value === 4 && (
                    <Review
                      dataReview={dataReview?.performance_tasks}
                      dataReporter={data.performance_goals[0].reporter}
                      type={'goal'}
                      isUserCanUpdate={isUserCanUpdate}
                    />
                  )}
                </Fragment>
              )}
            </DialogContent>
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              autoHideDuration={1500}
              open={open}
              onClose={() => setStates({...states, open: false})}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{message}</span>}
            />
            <ModalOwnGoal
              onClose={() => setDataSetGoal({open: false, data: null})}
              open={dataSetGoal.open}
              dataGoal={dataSetGoal?.data}
              typeGoal="company"
              handleClose={handleClose}
            />
            <ConfirmationPopup
              open={openleaveTask}
              handleClose={() => setOpenLeaveTask(false)}
              handleCloseForm={() => setOpenLeaveTask(false)}
              feature="Keluar dari Rencana Aksi?"
              message="Apakah Anda yakin akan Keluar dari Rencana Aksi ini?"
              type="Konfirmasi"
              mutation={handleLeaveTask}
            />
          </>
        )
      )}
    </StyledDialog>
  )
}

export default ModalTab
