import React, {useState} from 'react'

import useStyles from './SurveyStyles'
import Filter from './Filter/Filter'
import ListMultirater from './ListMultirater'

import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

function TabsMultirater(props) {
  const classes = useStyles()

  const [filter, setFilter] = useState({
    orderBy: 'desc',
    search_filter: '',
    dateFrom: 'Invalid date',
    dateTo: 'Invalid date',
    summary: null,
  })

  const handleChangeFilter = event => {
    setFilter({...filter, [event.target.name]: event.target.value})
  }
  return (
    <Grid container style={{marginTop: '22.8px'}}>
      <Grid item xs={12} sm={5}>
        <FormControl variant="outlined" className={classes.formControl}>
          <TextField
            onChange={handleChangeFilter}
            value={filter.orderBy}
            select
            label="Urutkan"
            variant="outlined"
            className={classes.dropdownArchive}
            inputProps={{
              name: 'orderBy',
            }}
          >
            <MenuItem value="desc">Terbaru</MenuItem>
            <MenuItem value="asc">Terlama</MenuItem>
          </TextField>
        </FormControl>
      </Grid>
      <Filter filter={filter} setFilter={setFilter} target={['Tanggal']} />
      <Grid item sm={12} style={{marginTop: '21.8px'}}>
        <ListMultirater filter={filter} {...props} />
      </Grid>
    </Grid>
  )
}
export default TabsMultirater
