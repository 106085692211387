import gql from 'graphql-tag'

export const GET_LIST_REVIEW_GOAL = gql`
  query getLazyReviewGoal($id: bigint) {
    performance_tasks(where: {goal: {_eq: $id}}, order_by: [{id: asc}]) {
      id
      name
      weight
      date_added
      last_modified
      performance_task_activities {
        id
        comment
        attachment
      }
      progress
      progress_percentage
      status
      performance_goal {
        id
        kpi_list {
          id
          kpi_weight_unit {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_LIST_REVIEW_TASK = gql`
  query GetTabReview($id: bigint!) {
    performance_task_activities(
      where: {task: {_eq: $id}, is_progress: {_eq: true}}
      order_by: [{date_added: desc_nulls_last}]
    ) {
      id
      comment
      progress_percentage
      progress_number
      attachment
      date_added
    }
  }
`

export const GET_STATUS_REVIEW = gql`
  query GetReview($id: bigint) {
    performance_tasks(where: {id: {_eq: $id}}) {
      id
      status
      review
    }
  }
`
