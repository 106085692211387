import React, {useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {AccessToAnotherModule} from '../../components/access-to-another-module/AccessToAnotherModuleComponent'
import {Header} from '../../components/header/HeaderComponent'
import {
  LeftSideWrapper,
  MainWrapper,
  VacancyListsMainArea,
} from '../../components/job-portal/JobPortalStyles'
import {UserLoggedIn} from '../../components/user-logged-in/UserLoggedInComponent'
import {GLOBAL_GLOSSARY} from '../../utils/constant'
import HomePage from '../homepage/HomepageComponent'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../performance-tabs/PerformanceStyles'
import PerformanceBadge from '../sidebar-badge/SidebarBadgeComponent'
// hide modular setting performance
// import {COMPANY_ID, SOSMED_APP_URL} from './utils/globals'
// import {GetModularSettings} from '@smartasn/wlb-utils-components'
// import {hasModule} from './utils/helpers'

const customStyle = {
  tabsPerformance: {
    background: 'rgb(246, 248, 249)',
  },
  paperPerformance: {
    padding: 24,
    marginLeft: 12,
  },
}

const AppContainer = props => {
  const {location, history} = props
  // hide modular settings performance
  // const {data} = GetModularSettings(COMPANY_ID)

  // if (data) {
  //   if (!hasModule(data, 'performance')) {
  //     window.location = SOSMED_APP_URL
  //   }
  // }

  useEffect(() => {
    if (location.pathname === '/') {
      history.push({pathname: '/goals/personal'})
    }
  }, [location])

  let regexUrl = ''
  if (props.location.pathname) {
    regexUrl = props.location.pathname.match('[a-z0-9-]+')
  }

  // used to determine which tabValue current page should has (only if that page need HomeTabs)
  const activeTab = () => {
    let vals = ''
    if (regexUrl) {
      const regexVal = regexUrl[0]
      switch (regexVal) {
        case 'see-all-goal':
        case 'goals':
          vals = '/'
          break
        default:
          vals = `/${regexVal}`
      }
    } else {
      vals = '/'
    }
    return vals
  }

  const handleChange = (event, newValue) => {
    event.preventDefault()
    props.history.push({
      pathname: newValue,
    })
  }

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={activeTab()}
          onChange={handleChange}
          style={customStyle.tabsPerformance}
          centered
        >
          <StyledTab label={GLOBAL_GLOSSARY.performance.Goal} value="/" />
          <StyledTab label="Tim Kerja" value="/squad" />
          <StyledTab label="Umpan Balik" value="/survey" />
          <StyledTab label="Ringkasan" value="/summary" />
          <StyledTab label="Evaluasi Kinerja" value="/committee" />
          <StyledTab label="Arsip" value="/archive" />
        </StyledTabs>
      </TabsWrapper>
      <MainWrapper>
        <LeftSideWrapper>
          <UserLoggedIn />
          <PerformanceBadge />
          <AccessToAnotherModule />
        </LeftSideWrapper>
        <VacancyListsMainArea>
          <HomePage tabValue={activeTab()} />
        </VacancyListsMainArea>
      </MainWrapper>
    </>
  )
}

export default withRouter(AppContainer)
