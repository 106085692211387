import React, {useContext, useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {Accordion, AccordionDetails, AccordionSummary} from '../../GoalStyled'
import UpArrowIcon from '@material-ui/icons/ExpandLess'
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight'
import {QUICK_FILTER_PERSONAL} from '../../constant'
import {
  GET_DEPARTEMEN_TASK_BY_GOAL,
  GET_MY_TASK_BY_GOAL,
} from '../../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import {CircularProgress} from '@material-ui/core'
import {DataContext} from '../../../../../contexts/ContextConfig'
import InfiniteScroll from 'react-infinite-scroll-component'
import ListTask from './ListTask'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  textText: {
    fontWeight: 600,
    fontSize: 16,
    marginBottom: 14,
    color: '#014A62',
  },
})

const ListTaskByGoal = props => {
  const classes = useStyles()
  const {sorting, filterData, filterValue, search} = props
  const {dataContext} = useContext(DataContext)

  const [expanded, setExpanded] = useState(false)
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 20,
    hasMore: true,
  })

  const query =
    filterValue === QUICK_FILTER_PERSONAL
      ? GET_MY_TASK_BY_GOAL
      : GET_DEPARTEMEN_TASK_BY_GOAL

  const variables = {
    offset: infiniteLoad.offset,
    limit: infiniteLoad.limit,
    company: COMPANY_ID,
    search: `%${search}%`,
    user: USER_ID,
    isCompany: true,
    isPersonal: true,
    order: sorting?.value,
    ...filterData?.include,
  }

  const {
    data: dataByGoal,
    error: errorByGoal,
    refetch: refetchByGoal,
  } = useQuery(query, {variables})

  useEffect(() => {
    if (dataContext) refetchByGoal()
  }, [dataContext])

  if (dataByGoal === undefined && errorByGoal === undefined) {
    return <CircularProgress />
  } else if (errorByGoal !== undefined) {
    console.error({errorByGoal})
  }

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    event.preventDefault()
  }

  const lengthGoal = dataByGoal?.total?.aggregate?.count
  const fetchMoreData = () => {
    if (infiniteLoad.limit >= lengthGoal) {
      setInfiniteLoad(prev => ({
        ...prev,
        hasMore: false,
      }))
    }
    setTimeout(() => {
      setInfiniteLoad(prev => ({
        ...prev,
        limit: prev.limit + 20,
      }))
    }, 1000)
  }

  return (
    <div className={classes.root}>
      <InfiniteScroll
        style={{overflow: 'hidden'}}
        dataLength={infiniteLoad.limit}
        next={fetchMoreData}
        hasMore={infiniteLoad.hasMore}
        loader={
          <div style={{textAlign: 'center', marginTop: 20}}>
            <CircularProgress size={15} style={{marginRight: '10px'}} />
            <span>Loading</span>
          </div>
        }
      >
        {dataByGoal?.performance_tasks?.map((value, index) => (
          <Accordion
            key={index}
            expanded={expanded === value.id}
            onChange={handleExpand(value.id)}
          >
            <AccordionSummary
              expandIcon={
                expanded ? (
                  <UpArrowIcon style={{color: '#014A62'}} />
                ) : (
                  <RightArrowIcon style={{color: '#014A62'}} />
                )
              }
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <Typography
                className={classes.textText}
              >{`[PF-${value?.performance_goal?.id}] ${value?.performance_goal?.name}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {expanded === value.id && (
                <ListTask
                  idGoal={value?.performance_goal?.id}
                  search=""
                  filterValue={filterValue}
                  filterData={filterData}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </InfiniteScroll>
    </div>
  )
}

export default ListTaskByGoal
