import gql from 'graphql-tag'

export const GET_PROGRESS_GOAL = gql`
  query($task: bigint) {
    performance_goals(where: {performance_tasks: {id: {_eq: $task}}}) {
      id
      target
      progress_percentage
      kpi_list {
        id
        kpi_weight_unit {
          id
          name
        }
      }
      performance_tasks_aggregate {
        aggregate {
          sum {
            progress
          }
        }
      }
    }
  }
`
