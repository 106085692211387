import React, {useState} from 'react'

// Style
import {
  SummaryContainer,
  TitleContainer,
  TitleCreate,
  TitleContent,
  AchievementContainer,
  MultiraterContainer,
  NPSContainer,
  FlexBetween,
  FlexEnd,
  // SelectContainer,
  GenerateTooltip,
} from './PerformanceStyles'
import {
  MenuItem,
  Grid,
  TextField,
  Menu,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {Paper} from '../../components/paper/PaperStyles'
import ExportBaloon from '../shared-component/ExportBaloon'

import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_ACHIEVEMENT,
  GET_STATUS_EXPORT_BALOON,
  GET_EXPORT_DISABLED_BUTTON,
} from '../../graphql/queries'
import {EXPORT_MUTATION, CLOSE_BALOON_EXPORT} from '../../graphql/mutations'
import {disbaledButtonExport} from '../../utils/helpers'
import {Filter} from '@smartasn/wlb-utils-components'
import {
  anchorOrigin,
  listFilter,
  transformOrigin,
} from './summary-components/SummaryFilterData'

// Summary Component
import ListAchievement from './summary-components/ListAchievement'
import ListMultirater from './summary-components/ListMultirater'
import ListNPS from './summary-components/ListNPS'
import Feedback from './summary-components/Feedback'
import moment from 'moment'
import ListSummary from './summary-components/ListSummary'
import ListReportSummary from './summary-components/ListReportSummary'
import ChoosePeriodPopup from '../shared-component/ChoosePeriodPopup'
import {useSnackbar} from 'notistack'

// global js
import {USER_ID, COMPANY_ID} from '../../utils/globals'
import NoDataListComponent from '../shared-component/NoDataListComponent'
import {GLOBAL_GLOSSARY} from '../../utils/constant'
import {progress_status} from './goal-components/TabDialog/constant'
import {TASK_STATUS} from './goal-components/constant'

const customStyle = {
  paper: {
    padding: 24,
  },
  paperFeedback: {
    padding: 24,
    marginTop: '2rem',
  },
  titleCardContent: {
    color: '#055469',
    margin: '20px 0px ',
  },
  detail: {fontFamily: 'Muli', color: '#707070', fontWeight: '600'},
  textFilter: {
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    margin: '35px 0px',
    cursor: 'pointer',
  },
}

export default function Summary() {
  const {enqueueSnackbar} = useSnackbar()
  const [from, setFrom] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [to, setTo] = useState(null)
  const [sort, setSort] = useState('all')
  const [openExport, setExport] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [openReport, setOpenReport] = useState(false)
  const [date, setDate] = useState({
    start: new Date(),
    end: new Date(),
  })

  const {data, error} = useQuery(GET_LIST_ACHIEVEMENT, {
    variables: {
      user: USER_ID,
      company: COMPANY_ID,
      isCompany: true,
      isPersonal: true,
      status: ['COMPLETED', 'ARCHIVED'],
      ...filterData.include,
    },
  })

  const {data: getExportBaloon} = useQuery(GET_STATUS_EXPORT_BALOON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'performance_goal_user',
    },
  })

  const {data: getExportDisabled} = useQuery(GET_EXPORT_DISABLED_BUTTON, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 10000,
    variables: {
      company: COMPANY_ID,
      table: 'performance_goal_user',
    },
  })

  const [mutationExport] = useMutation(EXPORT_MUTATION)

  const [closeBaloonExport] = useMutation(CLOSE_BALOON_EXPORT)

  const closeBaloonExportMutation = async () => {
    closeBaloonExport({
      variables: {
        id:
          getExportBaloon.company_export_data_logs.length > 0 &&
          getExportBaloon.company_export_data_logs[0].id,
      },
    })
  }

  const handleDownload = ({startDate, endDate}) => {
    setExport(false)
    const format = 'YYYY-MM-DD'
    const start_date = moment(startDate).format(format)
    const end_date = moment(endDate).format(format)
    mutationExport({
      variables: {
        table: 'performance_goal_user',
        ...(startDate &&
          endDate && {
            filter: {
              start_date,
              end_date,
            },
          }),
      },
    })
      .then(() => {
        enqueueSnackbar('Generate Data is processing!', {
          variant: 'success',
          autoHideDuration: 1000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Generate Data failed, please try again later', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const handleViewReport = () => {
    if (openReport) {
      setOpenReport(false)
    } else {
      setOpenReport(true)
    }
  }

  const SwitchValue = (value, type) => {
    if (type === 'colorProgress') {
      if (value > 75) {
        return '#4caf50'
      } else if (value <= 75) {
        return '#039be5'
      }
    } else if (type === 'colorResult') {
      if (value > 75) {
        return '#4caf50'
      } else if (value > 35 && value <= 75) {
        return '#ffa000'
      } else if (value > 0 && value <= 35) {
        return '#ef4d5e'
      }
    } else if (type === 'status') {
      if (value > 75) {
        return TASK_STATUS.COMPLETED
      } else if (value <= 75) {
        return TASK_STATUS.ONPROGRESS
      }
    } else {
      if (value > 75) {
        return progress_status.Outstanding
      } else if (value > 35 && value <= 75) {
        return progress_status.Almost
      } else if (value > 0 && value <= 35) {
        return progress_status.Poor
      }
    }
  }

  const handleChangeFilter = value => {
    setSort(value)
    if (value === 'all') {
      setFrom(null)
      setTo(null)
      setAnchorEl(null)
    } else if (value === 1) {
      setFrom(
        moment()
          .startOf('month')
          .format('YYYY-MM-DD')
      )
      setTo(moment(new Date()).format('YYYY-MM-DD'))

      setAnchorEl(null)
    } else {
      setFrom(
        moment(new Date())
          .subtract(parseInt(value), 'months')
          .format('YYYY-MM-DD')
      )

      setTo(moment(new Date()).format('YYYY-MM-DD'))
      setAnchorEl(null)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleDate = name => event => {
    setDate({...date, [name]: event.target.value})
  }
  const handleChangeFilterDate = () => {
    setFrom(moment(date.start).format('YYYY-MM-DD'))
    setTo(moment(date.from).format('YYYY-MM-DD'))
    setSort(0)
  }

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'type') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'percentage') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include.progressPersenFrom = Math.min(...tempData)
            include.progressPersenTo = Math.max(...tempData)
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include.dateFrom = moment(value.from).format(format)
        include.dateTo = moment(value.to).format(format)
      }

      if (key !== 'percentage' && key !== 'period') {
        include[key] = value
      }
    }
    setFilterData({...filterData, include})
  }

  return (
    <>
      {!openReport && (
        <SummaryContainer>
          <Paper style={customStyle.paper}>
            <TitleContainer>
              <TitleCreate>
                <TitleContent style={{margin: '0px'}}>Keseluruhan</TitleContent>
              </TitleCreate>
              {/* <Button variant="contained" color="primary" size="large">
                Generate Report
              </Button> */}
            </TitleContainer>

            <Grid container spacing={0} style={{paddingTop: '30px'}}>
              <Grid item xs={12} sm={5}>
                <TextField
                  variant="outlined"
                  label="periode"
                  fullWidth
                  style={{width: '214.2px', zIndex: 0}}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  value={
                    sort === 1
                      ? 'Bulan ini'
                      : sort === 3
                      ? '3 Bulan Terakhir'
                      : sort === 6
                      ? '6 Bulan Terakhir'
                      : sort === 12
                      ? '1 Tahun Terakhir'
                      : sort === 'all'
                      ? 'Semua'
                      : `${date.start} - ${date.end}`
                  }
                  disabled
                />

                <Menu
                  id="lock-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem
                    onClick={() => handleChangeFilter('all')}
                    value="all"
                  >
                    Semua
                  </MenuItem>
                  <MenuItem onClick={() => handleChangeFilter(1)} value="1">
                    1 Bulan Terakhir
                  </MenuItem>
                  <MenuItem onClick={() => handleChangeFilter(3)} value="3">
                    3 Bulan Terakhir
                  </MenuItem>
                  <MenuItem onClick={() => handleChangeFilter(6)} value="6">
                    6 Bulan Terakhir
                  </MenuItem>
                  <MenuItem onClick={() => handleChangeFilter(12)} value="12">
                    1 Tahun Terakhir
                  </MenuItem>
                  <MenuItem
                    value="s-e"
                    onClick={() => handleChangeFilterDate()}
                  >
                    <div>
                      <Typography>
                        Pilih Tanggal Mulai - Tanggal Berakhir
                      </Typography>
                      <div style={{display: 'flex'}}>
                        <TextField
                          fullWidth
                          type="date"
                          onChange={handleDate('start')}
                          value={date.start}
                          variant="outlined"
                        />
                        <Typography
                          variant="body1"
                          style={{marginLeft: '1rem', marginRight: '1rem'}}
                        >
                          -
                        </Typography>
                        <TextField
                          fullWidth
                          type="date"
                          onChange={handleDate('end')}
                          value={date.end}
                          variant="outlined"
                        />
                      </div>
                    </div>
                  </MenuItem>
                </Menu>

                {/* </TextField> */}
              </Grid>
            </Grid>
            <ListSummary from={from} to={to} handle={handleViewReport} />
          </Paper>
          <AchievementContainer>
            <p style={customStyle.detail}>Rincian</p>
            <Paper style={customStyle.paper}>
              <ExportBaloon
                data={getExportBaloon}
                closeBaloonMutation={closeBaloonExportMutation}
                retry={handleDownload}
                showCloseButton={disbaledButtonExport(
                  getExportDisabled?.company_export_data_logs?.[0]?.date_added
                )}
              />
              <FlexBetween>
                <TitleContainer>
                  <TitleCreate>
                    <TitleContent style={customStyle.titleCardContent}>
                      Pencapaian {GLOBAL_GLOSSARY.performance.Task}
                    </TitleContent>
                  </TitleCreate>
                </TitleContainer>
                <GenerateTooltip
                  title={
                    getExportBaloon?.company_export_data_logs?.[0]?.status ===
                      'FAILED' ||
                    disbaledButtonExport(
                      getExportDisabled?.company_export_data_logs?.[0]
                        ?.date_added
                    )
                      ? `Anda hanya dapat membuat laporan sekali sehari. Untuk mengunduh data gunakan tautan yang sudah dibuat`
                      : ``
                  }
                  placement="left"
                >
                  <span>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => setExport(true)}
                      disabled={
                        getExportBaloon?.company_export_data_logs?.[0]
                          ?.status === 'FAILED' ||
                        disbaledButtonExport(
                          getExportDisabled?.company_export_data_logs?.[0]
                            ?.date_added
                        )
                      }
                    >
                      Membuat laporan
                    </Button> */}
                  </span>
                </GenerateTooltip>
              </FlexBetween>
              <FlexEnd>
                <Filter
                  includeExclude={false}
                  anchorOrigin={anchorOrigin}
                  transformOrigin={transformOrigin}
                  id="filter-sumamry-achievement"
                  onApply={handleApply}
                  listFilter={listFilter}
                  language="indonesian"
                >
                  <Typography style={customStyle.textFilter}>Filter</Typography>
                </Filter>
              </FlexEnd>
              {data?.total.aggregate.count > 0 ? (
                <ListAchievement SwitchValue={SwitchValue} data={data} />
              ) : data === undefined && error === undefined ? (
                <CircularProgress />
              ) : (
                <NoDataListComponent search={filterData} />
              )}
            </Paper>
          </AchievementContainer>
          <MultiraterContainer>
            <Paper style={customStyle.paper}>
              <TitleContainer>
                <TitleCreate>
                  <TitleContent style={customStyle.titleCardContent}>
                    Multirater
                  </TitleContent>
                </TitleCreate>
              </TitleContainer>
              <ListMultirater />
            </Paper>
          </MultiraterContainer>
          <NPSContainer>
            <Paper style={customStyle.paper}>
              <TitleContainer>
                <TitleCreate>
                  <TitleContent style={customStyle.titleCardContent}>
                    NPS
                  </TitleContent>
                </TitleCreate>
              </TitleContainer>
              <ListNPS />
            </Paper>
          </NPSContainer>
          <Paper style={customStyle.paperFeedback}>
            <Feedback />
          </Paper>
          <ChoosePeriodPopup
            open={openExport}
            onClose={() => setExport(false)}
            onConfirm={handleDownload}
            enableFuture
            custom
          />
        </SummaryContainer>
      )}
      {openReport && (
        <SummaryContainer>
          <Paper style={customStyle.paper}>
            <ListReportSummary
              limit={null}
              offset={null}
              full={true}
              handle={handleViewReport}
            />
          </Paper>
        </SummaryContainer>
      )}
    </>
  )
}
