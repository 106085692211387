import React from 'react'
import {CardFeedback, Center, IconFeedback} from '../../PerformanceStyles'
import {CircularProgress} from '@material-ui/core'
import {Psm} from '../../../../components/typography/TypographyStyles'

import {useQuery} from '@apollo/react-hooks'
import {GET_EMOJI_FOR_ME} from '../../../../graphql/queries/index'
import {USER_ID} from '../../../../utils/globals'

export default function EmojiFeedback() {
  const {data, error} = useQuery(GET_EMOJI_FOR_ME(USER_ID))
  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <CardFeedback>
      <Center style={{color: '#ef4d5e'}}>
        <IconFeedback>sentiment_very_dissatisfied</IconFeedback>
        <Psm>{data.disappointed_all.aggregate.count}</Psm>
      </Center>
      <Center style={{color: '#ffa000'}}>
        <IconFeedback>sentiment_neutral</IconFeedback>
        <Psm>{data.ordinary_all.aggregate.count}</Psm>
      </Center>
      <Center style={{color: '#4caf50'}}>
        <IconFeedback>sentiment_satisfied_alt</IconFeedback>
        <Psm>{data.satisfied_all.aggregate.count}</Psm>
      </Center>
    </CardFeedback>
  )
}
