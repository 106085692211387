import gql from 'graphql-tag'

export const GET_LIST_PERSPECTIVE = gql`
  {
    kpi_perspectives {
      id
      name
    }
  }
`
