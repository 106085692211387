import {makeStyles} from '@material-ui/styles'
import styled from 'styled-components'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import {Typography, TableCell, TableRow} from '@material-ui/core'

export const SelectCustomFilter = styled(Select)`
  width: 162.1px;
  height: 44.2px;
  border-radius: 5px;
  border: solid 1px #a9a8a8;
  background-color: #ffffff;
`
export const TextFieldSearch = styled(TextField)`
  // border: solid #ffffff;
`
export const FormControlSearch = styled(FormControl)`
  // margin: theme.spacing(1);
  // width: 217.3px;
  // height: 44.2px;
  // border-radius: 3px;
  // border: solid 1px #e5e5e5;
  background-color: #ffffff;
`

export const ContainerFilter = styled.div`
  font-family: Muli;
  display: flex;
  font-size: 12px;
  flex-direction: column;
`

export const ContentFilter = styled.div`
  flex-grow: 1;
  display: flex;
  height: 250px;
`

export const ContainerFilterBottom = styled.div`
  z-index: 0;
  width: 264px;
  border-top-color: solid 1px #eff2f4;
  flex-direction: column;
  margin-top: -63px;
  display: flex;
  padding: 0px !important;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const TextFilter = styled(Typography)`
  width: 32px;
  height: 18px;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  lette-spacing: normal;
  text-align: center;
  color: #000000;
`

export const ResetDiv = styled.div`
  cursor: pointer;
  width: 105px;
  height: 15px;
  line-height: 15px;
  text-align: left;
  color: #040404;
  padding: 12px;
`
export const CancelDiv = styled.div`
  cursor: pointer;
  width: 38px;
  height: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  lette-spacing: normal;
  text-align: right;
  color: #a9a8a8;
  padding: 12px;
`
export const ApplyDiv = styled.div`
  cursor: pointer;
  width: 33px;
  height: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  color: #039be5;
  padding: 12px;
`
export const ButtonOutLine = styled(Button)`
  font-family: Muli;
  height: 45px;
  border-radius: 5px;
  text-transform: none;
  font-size: 14px;
  line-height: 18px;
  color: #014a62;
`
export const ButtonContained = styled(Button)`
  font-family: Muli;
  width: auto;
  height: 45px;
  text-transform: none;
  border-radius: 5px;
  background-color: #014a62;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
`

export const StatusRow = styled(Typography)`
  width: 65.7px;
  height: 24.4px;
  border-radius: 5px;
  font-family: Muli;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-top: 6px;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
`
export const ButtonRes = styled(Button)`
  width: 112.2px;
  height: 40px;
  text-transform: none;
  border-radius: 5px;
  background-color: #014a62;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  color: #ffffff;
`

export const TableRowHover = styled(TableRow)`
  background-color: #ffffff;
  &:hover {
    background-color: ##f6f8f9 !important;
    cursor: pointer !important;
  }
`

export const Thcustom = styled(TableCell)`
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 0.8rem;
`
export const Tdcustom = styled(TableCell)`
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
  text-align: left;
  & > div {
    text-align: left;
  }
`

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  dropdownArchive: {
    width: '162.1px',
    height: '44.2px !important',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
  },
  filter: {
    // margin: theme.spacing(1),
    width: '153.1px',
    height: '44.2px',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
    backgroundColor: '#ffffff',
  },
  iconFilter: {
    paddingLeft: '38.6px',
    paddingRight: '15px',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    // width: '270px',
    height: '240px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f7f8f9',
  },
  buttonFeed: {
    width: '93px',
    height: '35px',
    borderRadius: '5px',
    border: 'solid 1px #014a62',
    fontFamily: 'Muli',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'left',
  },
}))

export default useStyles
