import {Typography} from '@material-ui/core'
import React from 'react'
import {COMPANY_LOGO} from '../../../utils/globals'
import {parameters_versions} from '../../../utils/constant'
import {FlexCenter, GreyTypography} from '../PerformanceStyles'

export default function DetailMultiraterHeader({
  classes,
  back,
  data,
  isNpsExist,
  isMultiraterExist,
}) {
  return (
    <div className={classes.header}>
      <img
        src={
          COMPANY_LOGO || require('../../../assets/slicing/logo-smartasn.svg')
        }
        alt="logo"
        width="auto"
        height="54px"
        onClick={back}
        className={classes.pointer}
      />
      <div>
        <Typography variant="body1" color="primary" className={classes.bold}>
          {data && data.multirater_surveys[0].title}
        </Typography>

        <Typography variant="body2">
          {isNpsExist && parameters_versions.nps.ID}
          {isNpsExist && isMultiraterExist && ', '}
          {isMultiraterExist && parameters_versions.multirater.ID}
        </Typography>
      </div>
      <FlexCenter>
        <GreyTypography
          variant="body1"
          color="primary"
          className={classes.pointer}
          onClick={back}
          style={{marginRight: 42}}
        >
          Tutup
        </GreyTypography>
      </FlexCenter>
    </div>
  )
}
