import React from 'react'
import {Card, CardContent, makeStyles} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'

const useStyles = makeStyles(() => ({
  canbanCard: {
    padding: '15px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
}))

export default function Loader() {
  const classes = useStyles()

  return (
    <Card classes={{root: classes.canbanCard}}>
      <CardContent>
        <Skeleton width="100%" height={90} />
        <Skeleton circle width={50} height={50} />
        <Skeleton width="100%" height={60} />
      </CardContent>
    </Card>
  )
}
