import React, {useMemo, useState} from 'react'
import useStyles, {ButtonRes, Thcustom, Tdcustom} from './SurveyStyles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'

import {GET_LIST_FEED_BACK_REQUEST} from '../../../graphql/queries/multirater/getListFeedBackRequest'
import {getDate} from '../../../utils/helpers'

import {USER_ID} from '../../../utils/globals'

import ModalMutationFeedBack from '../detail-modal/ModalMutationFeedBack'

import {useQuery} from '@apollo/react-hooks'
import {SeeMore} from '../PerformanceStyles'
import ModalDetailFeedbackSurvey from '../detail-modal/ModalDetailFeedbackSurvey'

function ListFeedBackReq(props) {
  const {filter, filterDate} = props
  const [openModal, setOpenModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [loadingFetchMore, setLoadingFetchMore] = useState(false)
  const [params, setParams] = useState([])
  const [open, setOpen] = useState(false)

  let name = useState('')
  let tombol = useState(true)

  const {data, error, loading, fetchMore, refetch} = useQuery(
    GET_LIST_FEED_BACK_REQUEST,
    {
      fetchPolicy: 'network-only',
      variables: {
        user: `${USER_ID}`,
        offset: offset,
        limit: 10,
        search: `%${filter.search_filter}%`,
        dateFrom: filterDate(filter.dateFrom, 'from'),
        dateTo: filterDate(filter.dateTo, 'to'),
        orderBy: [{date_created: 'desc'}],
      },
    }
  )

  useMemo(() => setOffset(0), [filter, filterDate])

  const classes = useStyles()
  const handleModalClose = () => {
    setOpenModal(false)
  }

  const dataLength = data?.ongoing_feedback_requests?.length
  const total = data?.total?.aggregate?.count
  const loadingData = loading || loadingFetchMore

  if (error) console.error(error)

  if (!loading && total === 0) {
    return (
      <TableRow>
        <TableCell colSpan="4">
          Tidak ada data pada umpan balik yang diterima
        </TableCell>
      </TableRow>
    )
  }

  const handleSeeMore = async () => {
    setLoadingFetchMore(true)
    await fetchMore({
      variables: {offset: dataLength},
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          ongoing_feedback_requests: [
            ...prev.ongoing_feedback_requests,
            ...fetchMoreResult.ongoing_feedback_requests,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
    setLoadingFetchMore(false)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow style={{color: 'black'}}>
            <Thcustom>Subjek</Thcustom>
            <Thcustom>Topik</Thcustom>
            <Thcustom>Tanggal Ditambahkan</Thcustom>
            <Thcustom>Tipe Permintaan</Thcustom>
            <Thcustom></Thcustom>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            data?.ongoing_feedback_requests.map((row, key) => {
              if (row.global_user_from.id === USER_ID) {
                name = row.global_user_to.name
                tombol = false
              } else {
                name = row.global_user_from.name
                tombol = true
              }

              return (
                <TableRow key={key}>
                  <Tdcustom
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      setParams(row)
                      setOpen(true)
                    }}
                  >
                    {name}
                  </Tdcustom>
                  <Tdcustom>{row.topic}</Tdcustom>
                  <Tdcustom>{getDate(row.date_created)}</Tdcustom>
                  <Tdcustom>{tombol ? 'Terima' : 'Terkirim'}</Tdcustom>
                  <Tdcustom>
                    {tombol && (
                      <ButtonRes
                        onClick={() => {
                          setParams({
                            description: row.description,
                            request_id: row.id,
                            type_from: row.type_to,
                            type_to: row.type_from,
                            label: row.global_user_from.id,
                            value: row.global_user_from.name,
                            avatar: row.global_user_from.avatar,
                            pos:
                              row.global_user_from &&
                              row.global_user_from.people_work_placements[0]
                                .company_employee_position.name,
                            topic: row.topic,
                          })
                          setOpenModal(true)
                        }}
                      >
                        Respon
                      </ButtonRes>
                    )}
                  </Tdcustom>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <ModalMutationFeedBack
        open={openModal}
        handleClose={handleModalClose}
        params={params}
        dataActivity={{topic: params.topic}}
        type="Memberi Umpan Balik"
        refetch={refetch}
      />

      {loadingData &&
        Array.from({length: 10}).map((val, i) => (
          <Skeleton
            key={`${val}-${i}`}
            variant="rect"
            height={47}
            style={{margin: 16}}
          />
        ))}

      {!loadingData && dataLength < total && (
        <SeeMore borderTop="none">
          <Button
            style={{
              textTransform: 'none',
            }}
            color="secondary"
            onClick={handleSeeMore}
          >
            Lihat semua
          </Button>
        </SeeMore>
      )}
      <ModalDetailFeedbackSurvey
        open={open}
        onClose={onClose}
        params={params}
        type={'req'}
      />
    </>
  )
}

export default ListFeedBackReq
