import gql from "graphql-tag";

export const ADD_GENERAL_SQUAD = gql`
  mutation(
    $name: String
    $description: String
    $startdate: date
    $enddate: date
  ) {
    insert_performance_squad_groups(
      objects: [
        {
          name: $name
          description: $description
          start_date: $startdate
          end_date: $enddate
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_GENERAL_SQUAD_INFORMATION = gql`
  mutation(
    $id: Int!
    $name: String
    $description: String
    $startdate: date
    $enddate: date
  ) {
    update_performance_squad_groups(
      _set: {
        name: $name
        description: $description
        start_date: $startdate
        end_date: $enddate
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const ADD_GENERAL_SQUAD_MEMBER = gql`
  mutation addMyTeamPerformance($team: [performance_teams_insert_input!]!) {
    insert_performance_teams(objects: $team) {
      affected_rows
    }
  }
`;

export const DELETE_GENERAL_SQUAD_MEMBER = gql`
  mutation($group: Int!, $placement: Int!) {
    delete_performance_teams(
      where: { group: { _eq: $group }, member_placement: { _eq: $placement } }
    ) {
      affected_rows
    }
  }
`;
