import gql from 'graphql-tag'

export const GET_REPORT_NPS = gql`
  query getNPS($user: uuid, $survey: bigint) {
    user: global_users(where: {id: {_eq: $user}}) {
      id
      name
      avatar
      people_work_placements {
        id
        regno
        company_job_profile {
          id
          title
        }
      }
    }
    multirater_surveys(where: {id: {_eq: $survey}, multirater_survey_groups: {isnps: {_eq: true}}, multirater_respondents: {user: {_eq: $user}}}) {
      id
      title
      startdate
      enddate
      status
      multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
    }
    dectators: multirater_survey_responses_aggregate(where: {answer: {_gte: 0, _lte: 6}, respond_to: {_eq: $user}, multirater_survey_group: {isnps: {_eq: true}, survey: {_eq: $survey}}}) {
      aggregate {
        count
      }
    }
    passives: multirater_survey_responses_aggregate(where: {answer: {_gte: 7, _lte: 8}, respond_to: {_eq: $user}, multirater_survey_group: {isnps: {_eq: true}, survey: {_eq: $survey}}}) {
      aggregate {
        count
      }
    }
    promotors: multirater_survey_responses_aggregate(where: {answer: {_gte: 9, _lte: 10}, respond_to: {_eq: $user}, multirater_survey_group: {isnps: {_eq: true}, survey: {_eq: $survey}}}) {
      aggregate {
        count
      }
    }
    responses: multirater_survey_responses(where: {respond_to: {_eq: $user}, multirater_survey_group: {isnps: {_eq: true}, survey: {_eq: $survey}}}) {
      id
      global_user_respond_from {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}, limit: 1) {
          regno
          company_job_profile {
            id
            title
          }
        }
      }
      answer
      answer_text
    }
  }
`
