import React from 'react'

import Goals from '../performance-tabs/Goals'
import Squad from '../performance-tabs/Squad'
import Summary from '../performance-tabs/Summary'
import Archive from '../performance-tabs/Archive'
// import Multirater from '../performance-tabs/Multirater'

// Style
import {Paper} from '../../components/paper/PaperStyles'

import Survey from '../performance-tabs/Survey'
import {Switch, Route} from 'react-router-dom'
import CommitteeTask from '../performance-tabs/CommitteeTaskComponent'
import HumanCapitalTalentVersion from '../performance-tabs/committee-task-component/detail/HumanCapitalTalentVersion'
import TalentDetail from '../performance-tabs/committee-task-component/detail/TalentDetail'
import ListMemeberGeneralSquad from '../performance-tabs/squad-component/general-squad/ListMemeberGeneralSquad'
import ListMemberSubordinateSquad from '../performance-tabs/squad-component/subordinate-squad/ListMemberSubordinateSquad'
import SeeAllGoal from '../performance-tabs/goal-components/see-all/goal/SeeAllGoal'
import SeeAllTask from '../performance-tabs/goal-components/see-all/task/SeeAllTask'

const customStyle = {
  paperBlankPerformance: {
    background: 'initial',
    padding: 0,
    boxShadow: 'none',
  },
  paperPerformance: {
    padding: 24,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
}

const PAPER_BLANK = ['/', '/summary', '/see-all', '/squad']

const HomePage = ({tabValue}) => {
  return (
    <Paper
      style={
        PAPER_BLANK.includes(tabValue)
          ? customStyle.paperBlankPerformance
          : customStyle.paperPerformance
      }
    >
      <Switch>
        <Route exact path="/goals/:type" component={Goals} />
        <Route
          exact
          path="/squad/direct-report-squad/detail"
          component={ListMemberSubordinateSquad}
        />
        <Route
          exact
          path="/squad/collaboration-squad/detail/:id"
          component={ListMemeberGeneralSquad}
        />
        <Route path="/squad" component={Squad} />
        <Route exact path="/survey" render={() => <Survey />} />
        <Route exact path="/summary" component={Summary} />
        <Route exact path="/archive" component={Archive} />
        <Route exact path="/committee" component={CommitteeTask} />
        <Route
          exact
          path="/committee/version/:id/:id_job/:id_group"
          component={HumanCapitalTalentVersion}
        />
        <Route
          path="/committee/version/:id_version/:id_job/:id_group/:id_emp"
          component={TalentDetail}
        />
        <Route
          exact
          path="/goals/:type/all-goal"
          render={() => <SeeAllGoal />}
        />
        <Route
          exact
          path="/goals/:type/all-task"
          render={() => <SeeAllTask />}
        />
      </Switch>
    </Paper>
  )
}

export default HomePage
