import React, {useState, useEffect} from 'react'
import {
  Menu,
  MenuList,
  TextField,
  Typography,
  makeStyles,
  Badge,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FormFilter from './FormFilter'

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  flex1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
    cursor: 'pointer',
  },
  divroot1: {
    width: '50%',
    padding: '10px',
  },
  divroot2: {
    width: '50%',
    marginLeft: '2rem',
    padding: '10px',
    backgroundColor: '#f7f8f9',
  },
  scroll: {
    height: '170px',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    backgroundColor: '#fff',
  },
  list: {padding: 0},
  actionButton: {fontSize: 12, cursor: 'pointer'},
}))
const Filter = ({
  handleClose,
  anchorEl,
  handleClickFilter,
  filterData,
  handleChange,
  state,
  handleApply,
  resetFilter,
  resetAll,
  selectAll,
}) => {
  const classes = useStyle()
  const [filters, setDataFilter] = useState({name: '', data: []})
  const [text, setText] = useState('')

  const handleFilters = event => {
    const query = event.target.value
    const dataFilter = filterData.data.filter(function(el) {
      return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
    setText(event.target.value)
    setDataFilter({name: filterData.name, data: dataFilter})
  }
  useEffect(() => {
    setDataFilter({name: filterData.name, data: filterData.data})
  }, [filterData])

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: '620px',
        },
      }}
      classes={{list: classes.list}}
    >
      <MenuList disablePadding>
        <div className={classes.root}>
          <div className={classes.divroot1}>
            <div
              className={classes.flex1}
              onClick={() => handleClickFilter('badge')}
            >
              <Typography variant="body1">Lencana</Typography>
              {state.badge.length !== 0 ? (
                <Badge badgeContent={state.badge.length} color="primary" />
              ) : null}
            </div>
            <div
              className={classes.flex1}
              onClick={() => handleClickFilter('office')}
            >
              <Typography variant="body1">Instansi</Typography>
              {state.office.length !== 0 ? (
                <Badge badgeContent={state.office.length} color="primary" />
              ) : null}
            </div>
            <div
              className={classes.flex1}
              onClick={() => handleClickFilter('organization')}
            >
              <Typography variant="body1">Organisasi</Typography>
              {state.organization.length !== 0 ? (
                <Badge
                  badgeContent={state.organization.length}
                  color="primary"
                />
              ) : null}
            </div>
            {/* <div
              className={classes.flex1}
              onClick={() => handleClickFilter('job_profile')}
            >
              <Typography variant="body1">Position</Typography>
              {state.job_profile.length !== 0 ? (
                <Badge
                  badgeContent={state.job_profile.length}
                  color="primary"
                />
              ) : null}
            </div> */}
            <div
              className={classes.flex1}
              onClick={() => handleClickFilter('position')}
            >
              <Typography variant="body1">Posisi</Typography>
              {state.position.length !== 0 ? (
                <Badge badgeContent={state.position.length} color="primary" />
              ) : null}
            </div>
            {/* <div
              className={classes.flex1}
              onClick={() => handleClickFilter('aspiration')}
            >
              <Typography variant="body1">Aspiration</Typography>
              {state.aspiration.length !== 0 ? (
                <Badge badgeContent={state.aspiration.length} color="primary" />
              ) : null}
            </div> */}
            <Divider style={{marginTop: '4rem'}} />
            <div className={classes.flex}>
              <Typography
                variant="body1"
                onClick={resetAll}
                className={classes.actionButton}
              >
                Atur Ulang Semua
              </Typography>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '42%',
                }}
              >
                <Typography
                  variant="body1"
                  onClick={handleClose}
                  className={classes.actionButton}
                >
                  Batalkan
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  onClick={handleApply}
                  className={classes.actionButton}
                >
                  Terapkan
                </Typography>
              </div>
            </div>
          </div>
          <div className={classes.divroot2}>
            <TextField
              className={classes.search}
              variant="outlined"
              fullWidth
              placeholder="Cari"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
              }}
              onChange={handleFilters}
              value={text}
            />
            <div className={classes.flex}>
              <Typography
                variant="body1"
                color="primary"
                onClick={() => selectAll(filterData.name)}
                className={classes.actionButton}
              >
                Pilih Semua
              </Typography>
              <Typography
                variant="body1"
                onClick={() => resetFilter(filterData.name)}
                className={classes.actionButton}
              >
                Atur Ulang
              </Typography>
            </div>
            <div className={classes.scroll}>
              <FormFilter
                filterData={filters}
                handleChange={handleChange}
                state={state}
              />
            </div>
          </div>
        </div>
      </MenuList>
    </Menu>
  )
}
export default Filter
