import React, {Fragment} from 'react'
import {CircularProgress, Icon} from '@material-ui/core'
import {CardDetailsTop} from './ModalPerformanceStyles'
import {PmdG, P14Bold} from '../../../components/typography/TypographyStyles'

import moment from 'moment'

// graphql
import {useQuery} from '@apollo/react-hooks'
import {GET_MONTHLY_ACHIEVEMENT} from '../../../graphql/queries/index'
import Carousel from 'nuka-carousel'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

export default function MonthAchievement(props) {
  const {id, kpi, target_unit} = props

  const {data, error} = useQuery(GET_MONTHLY_ACHIEVEMENT(id), {
    errorPolicy: 'ignore',
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return ''
  }

  return (
    <CardDetailsTop
      style={{
        display: 'flex',
        minHeight: 90,
        width: '45%',
        marginBottom: 0,
      }}
    >
      <Carousel
        slidesToShow={1}
        renderCenterRightControls={null}
        renderCenterLeftControls={null}
        renderCenterCenterControls={({
          previousSlide,
          currentSlide,
          slideCount,
          nextSlide,
        }) => {
          return (
            <div
              style={{
                width: 300,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {currentSlide === 0 ? (
                <Icon style={{marginRight: '1rem', color: '#a9a8a8'}}>
                  chevron_left
                </Icon>
              ) : (
                <Icon
                  style={{marginRight: '1rem', color: '#333333'}}
                  onClick={previousSlide}
                >
                  chevron_left
                </Icon>
              )}
              {slideCount - currentSlide === 1 ? (
                <Icon style={{color: '#a9a8a8'}}>chevron_right</Icon>
              ) : (
                <Icon style={{color: '#333333'}} onClick={nextSlide}>
                  chevron_right
                </Icon>
              )}
            </div>
          )
        }}
        renderBottomCenterControls={null}
      >
        {data.getDetailAchievement.map((res, idx) => {
          const getDate = moment(res.month).format('MMMM YYYY')
          const getMName = moment(res.month).format('MMMM')
          const getMNumber = moment()
            .month(getMName)
            .format('M')
          const lengthWeight = target_unit?.length

          return (
            <Fragment key={idx}>
              <div style={{height: 90, textAlign: 'center', overflowY: 'auto'}}>
                <P14Bold style={{margin: 0, fontSize: 20}}>
                  {res.progress ? res.progress.toFixed(2) : '0'}
                </P14Bold>
                <PmdG style={{margin: '7px 0px'}}>{getDate}</PmdG>
                <P14Bold
                  style={{margin: 0, fontSize: lengthWeight > 15 ? 13 : 14}}
                >{`M${getMNumber} Kontribusi pada ${GLOBAL_GLOSSARY.performance.Goal} (${target_unit})`}</P14Bold>
                <PmdG style={{marginTop: 3}}>{kpi ? `(${kpi})` : '-'}</PmdG>
              </div>
            </Fragment>
          )
        })}
      </Carousel>
    </CardDetailsTop>
  )
}
