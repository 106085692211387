import React, {useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {GET_FEEDBACK_FOR_ME} from '../../../graphql/queries/index'
import {USER_ID} from '../../../utils/globals'

import EmojiFeedback from './emoji-feedback/EmojiFeedback'

import {
  TitleContainer,
  TitleCreate,
  TitleContent,
  FlexBetween,
  FeedbackTabsContainer,
  FeedbackTabs,
  FeedbackTab,
  FeedbackTabsContent,
  CardFeedbackTabs,
  Center,
  IconFeedback,
  FeedbackListContainer,
} from '../PerformanceStyles'
import {
  Icon,
  // MenuItem,
  InputAdornment,
  Avatar,
  CircularProgress,
  Button,
} from '@material-ui/core'
import {Pmd, Psm} from '../../../components/typography/TypographyStyles'
import {StyledTextField} from '../../../components/input/InputsStyles'
import ModalDetailFeedback from '../detail-modal/ModalDetailFeedback'
import DateRange from '../../shared-component/DateRangePopup'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

const customStyle = {
  titleCardContent: {
    color: '#055469',
    margin: '20px 0px ',
  },
}

function Feedback(props) {
  const [tabValue, setTabValue] = useState(0)
  const [openFeedback, setOpenFeedback] = useState(false)
  const [user, setUser] = useState(null)
  const [typeFeedback, setTypeFeedback] = useState('INSIGHT')
  const [filter, setFilter] = useState('')
  const [openReport, setOpenReport] = useState(false)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const handleChange = (event, tabs) => {
    event.preventDefault()
    // this.setState({tabValue})
    setTabValue(tabs)
    feedbackType(tabs)
  }

  const filterHandle = event => {
    setFilter(event.target.value)
    // console.log(filter)
  }

  const FeedbackhandleClickOpen = user => {
    setOpenFeedback(true)
    setUser(user)
  }

  const FeedbackhandleClickClose = () => {
    setOpenFeedback(false)
  }

  const feedbackType = tabs => {
    // console.log(tabValue)
    switch (tabs) {
      case 0:
        return setTypeFeedback('INSIGHT')
      case 1:
        return setTypeFeedback('FEEDBACK')
      case 2:
        return setTypeFeedback('SUBORDINATE')
      default:
        return ''
    }
  }

  const handleOpenReport = () => {
    setOpenReport(true)
  }

  const handleChangeDate = (type, date) => {
    if (type === 'start') {
      setStartDate(new Date(date))
    } else if (type === 'end') {
      setEndDate(new Date(date))
    } else {
      setStartDate(new Date())
      setEndDate(new Date())
    }
  }

  const handleCloseReport = () => {
    setOpenReport(false)
    handleChangeDate('reset')
  }

  const handleGenerate = () => {
    props.history.push({
      pathname: `/generate-report-feedback/${moment(startDate).format(
        'YYYY-MM-DD'
      )}/${moment(endDate).format('YYYY-MM-DD')}`,
    })
  }

  const {data, error} = useQuery(GET_FEEDBACK_FOR_ME, {
    variables: {
      id: USER_ID,
      type_from: typeFeedback,
      search: `%${filter}%`,
    },
  })

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  // console.log(data)
  return (
    <>
      <FlexBetween>
        <TitleContainer>
          <TitleCreate>
            <TitleContent style={customStyle.titleCardContent}>
              {GLOBAL_GLOSSARY.performance.OGF}
            </TitleContent>
          </TitleCreate>
        </TitleContainer>
        <Button
          variant="contained"
          color="primary"
          size="large"
          // style={{textTransform: 'initial'}}
          // component={Link}
          // to="/generate-report-feedback"
          onClick={handleOpenReport}
        >
          Membuat laporan
        </Button>
      </FlexBetween>
      <FlexBetween style={{alignItems: 'flex-start', flexDirection: 'column'}}>
        <EmojiFeedback />
        <FeedbackTabsContainer>
          <FeedbackTabs
            value={tabValue}
            onChange={handleChange}
            // indicatorColor="primary"
            // style={customStyle.tabsPerformance}
          >
            <FeedbackTab label="Pimpinan" />
            <FeedbackTab label="Rekan Kerja" />
            <FeedbackTab label="Pegawai di Bawahnya" />
          </FeedbackTabs>
          <FeedbackTabsContent>
            <FlexBetween>
              <StyledTextField
                id="outlined-simple-start-adornment"
                variant="outlined"
                placeholder="Cari"
                style={{marginTop: 8, backgroundColor: '#fff'}}
                width="48%"
                value={filter}
                onChange={filterHandle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search</Icon>
                    </InputAdornment>
                  ),
                }}
              />

              {/* <StyledTextField
                id="outlined-select-currency"
                select
                label="Criteria"
                margin="normal"
                variant="outlined"
                width="25%"
              >
                <MenuItem value="1">Criteria</MenuItem>
              </StyledTextField> */}
            </FlexBetween>
            <FeedbackListContainer>
              {data.ongoing_feedback_lists.length > 0 ? (
                data.ongoing_feedback_lists.map((res, key) => {
                  const from = res.global_user_from
                  const user = {id: from.id, name: from.name}
                  return (
                    <CardFeedbackTabs
                      key={key}
                      onClick={() => FeedbackhandleClickOpen(user)}
                    >
                      <FlexBetween>
                        <Avatar alt={('from-', from.id)} src={from.avatar} />
                        <Pmd style={{color: '#055469'}}>{from.name}</Pmd>
                        <FlexBetween style={{width: '30%'}}>
                          <Center style={{color: '#ef4d5e'}}>
                            <IconFeedback>
                              sentiment_very_dissatisfied
                            </IconFeedback>
                            <Psm>{from.disappointed.aggregate.count}</Psm>
                          </Center>
                          <Center style={{color: '#ffa000'}}>
                            <IconFeedback>sentiment_neutral</IconFeedback>
                            <Psm>{from.ordinary.aggregate.count}</Psm>
                          </Center>
                          <Center style={{color: '#4caf50'}}>
                            <IconFeedback>sentiment_satisfied_alt</IconFeedback>
                            <Psm>{from.satisfied.aggregate.count}</Psm>
                          </Center>
                        </FlexBetween>
                      </FlexBetween>
                    </CardFeedbackTabs>
                  )
                })
              ) : (
                <Center
                  style={{color: '#ffa000', top: '38%', position: 'relative'}}
                >
                  <IconFeedback>sentiment_neutral</IconFeedback>
                  <Psm>Belum Ada Umpan Balik</Psm>
                </Center>
              )}
            </FeedbackListContainer>
          </FeedbackTabsContent>
          <ModalDetailFeedback
            open={openFeedback}
            onClose={FeedbackhandleClickClose}
            user={user}
          />
          <DateRange
            handleClose={handleCloseReport}
            open={openReport}
            startDate={startDate}
            endDate={endDate}
            handleChange={handleChangeDate}
            handleGenerate={handleGenerate}
          />
        </FeedbackTabsContainer>
      </FlexBetween>
    </>
  )
}

export default withRouter(Feedback)
