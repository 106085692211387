import {
  Avatar,
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import LoadingSurvey from '../../../components/loading/LoadingSurvey'
import {selectProps} from '../../../utils/helpers'
import {FlexBetween, FlexCenter, GreyTypography} from '../PerformanceStyles'

export default function DetailMultiraterContent({
  classes,
  dataContent,
  currentQuestionIndex,
  questionId,
  isComplete,
  setQuestionId,
  handleTextNumberAnswer,
  currentQuestion,
  answer,
  setAnswer,
  answerText,
  setAnswerText,
  handleNextPrev,
  btnDisabled,
  isMutationNeeded,
  loading,
  isHiddenPrev,
  isHiddenNext,
}) {
  const handleChangeMultiraterAnswer = i => {
    setAnswer(i === 5 ? 0 : i + 1)
  }

  return (
    <Paper elevation={3} className={classes.content}>
      {!dataContent ? (
        // <NotFoundComponent message="Please choose employee first" />
        <div className={classes.loading}>
          <LoadingSurvey />
        </div>
      ) : (
        <>
          <FlexBetween>
            <Typography variant="body2">
              {currentQuestionIndex + 1} dari{' '}
              {dataContent.multirater_survey_questions.length} pertanyaan
            </Typography>
            <FlexCenter style={{width: 'auto'}}>
              <GreyTypography variant="body2">lompat ke</GreyTypography>
              <TextField
                variant="outlined"
                select
                className={classes.questionDropdown}
                size="small"
                value={questionId}
                // onChange={e => setQuestionId(e.target.value)}
                SelectProps={{
                  ...selectProps(questionId),
                  classes: {root: classes.select},
                }}
              >
                <MenuItem value="" disabled>
                  Pilih pertanyaan
                </MenuItem>
                {dataContent.multirater_survey_questions.map((res, i) => (
                  <MenuItem key={i} value={res.id} style={{padding: 0}}>
                    <Typography
                      variant="body1"
                      onClick={() => {
                        isMutationNeeded(() => {
                          setQuestionId(res.id)
                          handleTextNumberAnswer(res)
                        }, true)
                      }}
                      noWrap
                      style={{
                        width: '100%',
                        padding: '6px 16px',
                        color:
                          res.multirater_responses.length > 0
                            ? '#014a62'
                            : 'unset',
                      }}
                    >
                      {res.question_others
                        ? res.question_others
                        : res.question_self}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </FlexCenter>
          </FlexBetween>
          <div className={classes.questionContainer}>
            <Avatar
              src={dataContent.people_work_placements[0].global_user.avatar}
              className={classes.avatarQuestion}
            />
            <Typography
              variant="h6"
              color="primary"
              gutterBottom
              className={classes.bold}
            >
              {dataContent.people_work_placements[0].global_user.name}
            </Typography>
            <Typography variant="body1" paragraph className={classes.bold}>
              {dataContent.people_work_placements[0].company_job_profile?.title}
            </Typography>
            <Typography variant="body1" color="primary" gutterBottom>
              {currentQuestion && currentQuestion.question_others
                ? currentQuestion.question_others
                : currentQuestion && currentQuestion.question_self}
            </Typography>
            <div className={classes.scoreStep}>
              {loading ? (
                <div>
                  <Skeleton width={'50vw'} style={{marginTop: 20}} />
                  <Skeleton width={'30vw'} style={{marginTop: 20}} />
                </div>
              ) : currentQuestion?.multirater_survey_group?.isnps ? (
                <>
                  <GreyTypography variant="body1" style={{marginRight: 12}}>
                    Nilai:
                  </GreyTypography>
                  {/* eslint-disable-next-line */}
                  {Array.from({length: 10}).map((val, i) => (
                    <Button
                      key={i}
                      style={{
                        padding: 14,
                        borderStyle: 'solid',
                        borderColor: 'grey',
                        borderWidth: 1,
                        borderRightWidth: i === 9 ? 1 : 0,
                        borderTopLeftRadius: i === 0 ? 5 : 0,
                        borderBottomLeftRadius: i === 0 ? 5 : 0,
                        borderTopRightRadius: i === 9 ? 5 : 0,
                        borderBottomRightRadius: i === 9 ? 5 : 0,
                        color: answer === i + 1 && 'white',
                        backgroundColor: answer === i + 1 && '#014a62',
                        minWidth: 0,
                        lineHeight: 1,
                      }}
                      disabled={isComplete}
                      onClick={() => setAnswer(i + 1)}
                    >
                      {i + 1}
                    </Button>
                  ))}
                </>
              ) : (
                currentQuestion?.multirater_survey_group?.multirater_survey?.multirater_scale?.name.map(
                  (scales, i) => (
                    <div key={i} style={{margin: '0 6px', minWidth: 115}}>
                      <Button
                        key={i}
                        style={{
                          padding: 14,
                          borderStyle: 'solid',
                          borderColor: 'grey',
                          borderRadius: 5,
                          borderWidth: 1,
                          color:
                            (answer === i + 1 || (answer === 0 && i === 5)) &&
                            'white',
                          backgroundColor:
                            (answer === i + 1 || (answer === 0 && i === 5)) &&
                            '#014a62',
                          minWidth: 0,
                          lineHeight: 1,
                          margin: '0 23px 8px 23px',
                        }}
                        onClick={() => handleChangeMultiraterAnswer(i)}
                        disabled={isComplete}
                      >
                        {i + 1}
                      </Button>
                      <Typography component="p" variant="caption">
                        {scales}
                      </Typography>
                    </div>
                  )
                )
              )}
            </div>
            {loading ? (
              <Skeleton width={'40vw'} height="8rem" />
            ) : (
              currentQuestion?.multirater_survey_group?.isnps && (
                <TextField
                  multiline
                  rows="4"
                  variant="outlined"
                  placeholder="Tuliskan alasan kamu disini"
                  fullWidth
                  style={{width: '32rem'}}
                  value={answerText}
                  onChange={e => setAnswerText(e.target.value)}
                  disabled={isComplete}
                />
              )
            )}
          </div>
          <FlexBetween>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => handleNextPrev(false)}
              disabled={btnDisabled}
              className={classes.btnPrev}
              style={{display: isHiddenPrev ? 'none' : 'block'}}
            >
              Sebelumnya
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleNextPrev(true)}
              disabled={btnDisabled}
              className={classes.btnNext}
              style={{display: isHiddenNext ? 'none' : 'block'}}
            >
              Selanjutnya
            </Button>
          </FlexBetween>
        </>
      )}
    </Paper>
  )
}
