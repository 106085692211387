import gql from 'graphql-tag'

export const UPDATE_REPORTER_GOAL = gql`
  mutation updateReporterGoal($id: bigint!, $reporter: uuid!) {
    update_performance_goals(
      where: {id: {_eq: $id}}
      _set: {reporter: $reporter}
    ) {
      affected_rows
    }
  }
`
