import React, {useEffect, useContext} from 'react'
import {UserLoggedInProfileWrapper} from '../job-portal/JobPortalStyles'
import {getCookie} from '../../utils/helpers'
import BoxInformation from './BoxInformation'
import BoxTaskInformation from './BoxTaskInformation'
import {GET_PERFORMANCE} from '../../graphql/queries/goals/getListGoal.query'
import {USER_ID, SOSMED_APP_URL} from '../../utils/globals'
import {useQuery} from '@apollo/react-hooks'
import {DataContext} from '../../contexts/ContextConfig'
// import BoxInformation from './BoxInformation'

const customStyle = {
  usernameLabel: {
    color: '#252525',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 20,
    cursor: 'pointer',
  },

  posisitionLabel: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 8,
    width: 180,
    marginBottom: 40,
    cursor: 'pointer',
  },

  paddingTaskInfo: {
    padding: '0px',
  },
}

export const UserLoggedIn = () => {
  const data = getCookie('userData') && JSON.parse(getCookie('userData'))
  const {dataContext} = useContext(DataContext)

  const {data: dataSideBar, loading, error, refetch} = useQuery(
    GET_PERFORMANCE,
    {
      variables: {
        user: USER_ID,
      },
    }
  )

  useEffect(() => {
    if (dataContext) {
      refetch()
    }
  }, [dataContext])

  const handleClick = () => {
    window.location.replace(`${SOSMED_APP_URL}/profile/${USER_ID}/detail`)
  }

  return (
    <>
      <UserLoggedInProfileWrapper>
        <div className="user-wrapper" onClick={handleClick}>
          <img
            alt="user-logged-icon"
            src={
              data.avatar
                ? data.avatar
                : require('../../assets/slicing/default-avatar.png')
            }
            style={{
              height: '90px',
              width: '90px',
              borderRadius: '50%',
            }}
          />
          <label style={customStyle.usernameLabel}>{data && data.name}</label>
          <label style={customStyle.posisitionLabel}>
            {data && `${data.position_name} di ${data.company_name}`}
          </label>
        </div>
        <BoxInformation data={dataSideBar} loading={loading} error={error} />
      </UserLoggedInProfileWrapper>
      <UserLoggedInProfileWrapper style={customStyle.paddingTaskInfo}>
        <BoxTaskInformation
          data={dataSideBar}
          loading={loading}
          error={error}
        />
      </UserLoggedInProfileWrapper>
    </>
  )
}
