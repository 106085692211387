import gql from 'graphql-tag'

export const UPDATE_PROGRESS_TASK = gql`
  mutation UpdateProgress($id: bigint!, $progress: float8!) {
    update_performance_tasks(
      _set: {progress: $progress}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
export const UPDATE_TASK = gql`
  mutation updateTask($id: bigint!, $status: String!, $progress: float8) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {status: $status, progress: $progress}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_TASK_ATTACHMENTS = gql`
  mutation($task: bigint!, $attachment: jsonb!, $progress: float8!) {
    insert_performance_task_activities(
      objects: [
        {
          task: $task
          comment: "Progress updated"
          attachment: $attachment
          is_progress: true
          progress_percentage: $progress
        }
      ]
    ) {
      affected_rows
    }
  }
`

export const DELETE_CASCADE = gql`
  mutation deleteCascading($id: uuid!) {
    delete_performance_cascadings(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
