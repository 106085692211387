import gql from 'graphql-tag'

export const GET_SCORING_NATURES = gql`
  {
    kpi_scoring_natures {
      id
      name
    }
  }
`
