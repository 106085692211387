import {CircularProgress, Typography} from '@material-ui/core'
import React from 'react'
import {GLOBAL_GLOSSARY} from '../../utils/constant'
import {
  BoxWrapper,
  BoxRow,
  BoxColumn,
  Score,
  TypeScore,
  MyVacancyElementWrapper,
} from '../job-portal/JobPortalStyles'

export default function BoxTaskInformation(props) {
  const {data, loading, error} = props

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }
  return (
    <BoxWrapper>
      <BoxRow>
        <BoxColumn center>
          <Score>
            {!isNaN(data.total_task.aggregate.count)
              ? data.total_task.aggregate.count
              : 0}
          </Score>
          <TypeScore>Total {GLOBAL_GLOSSARY.performance.Task}</TypeScore>
        </BoxColumn>
      </BoxRow>
      <BoxRow>
        <MyVacancyElementWrapper style={{width: '100%'}}>
          <div style={{textAlign: 'left'}}>
            <Typography
              variant="body2"
              style={{color: '#707070', marginBottom: '10px'}}
            >
              {GLOBAL_GLOSSARY.performance.Task} yang harus dikerjakan
            </Typography>
            <Typography
              variant="body2"
              style={{color: '#707070', marginBottom: '10px'}}
            >
              {GLOBAL_GLOSSARY.performance.Task} yang sedang dikerjakan
            </Typography>
            <Typography
              variant="body2"
              style={{color: '#707070', marginBottom: '10px'}}
            >
              {GLOBAL_GLOSSARY.performance.Task} yang selesai dikerjakan
            </Typography>
            <Typography variant="body2" style={{color: '#707070'}}>
              {GLOBAL_GLOSSARY.performance.Task} yang sudah terlaksana
            </Typography>
          </div>
          <div style={{textAlign: 'right'}}>
            <Typography
              variant="body2"
              style={{color: '#000000', fontWeight: 600, marginBottom: '10px'}}
            >
              {!isNaN(data.total_task.aggregate.count)
                ? data.task_todo.aggregate.count
                : 0}
            </Typography>
            <Typography
              variant="body2"
              style={{color: '#000000', fontWeight: 600, marginBottom: '10px'}}
            >
              {!isNaN(data.total_task.aggregate.count)
                ? data.task_inprogress.aggregate.count
                : 0}
            </Typography>
            <Typography
              variant="body2"
              style={{color: '#000000', fontWeight: 600, marginBottom: '10px'}}
            >
              {!isNaN(data.total_task.aggregate.count)
                ? data.task_done.aggregate.count
                : 0}
            </Typography>
            <Typography
              variant="body2"
              style={{color: '#000000', fontWeight: 600}}
            >
              {!isNaN(data.task_done.aggregate.count)
                ? data.task_completed.aggregate.count
                : 0}
            </Typography>
          </div>
        </MyVacancyElementWrapper>
      </BoxRow>
    </BoxWrapper>
  )
}
