import React from 'react'
import {makeStyles, Avatar} from '@material-ui/core'
import {P14Bold, PmdG} from '../../components/typography/TypographyStyles'
import PopupWrapper from './PopupWrapper'

const useStyles = makeStyles(theme => ({
  rootAvatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '& > *': {
      margin: theme.spacing(1),
    },
    '& > .MuiAvatar-root': {
      width: '45px',
      height: '45px',
    },
  },
  textWraper: {
    marginLeft: '5px',
    width: '85%',
  },
  paddingContent: {
    padding: '16px 24px',
  },
  fontName: {
    fontFamily: 'muli',
    margin: '0px 0px 5px 0px',
  },
  fontPosition: {
    fontFamily: 'muli',
  },
}))

const ParticipantPopup = props => {
  const {open, handleClose, participant} = props
  const classes = useStyles()
  return (
    <>
      <PopupWrapper
        title="Participant"
        maxWidth="sm"
        open={open}
        withAction={false}
        onClose={handleClose}
        withCloseicon
        withArrowBack
        contentPadding="0px"
      >
        <div className={classes.paddingContent}>
          {participant?.map((res, i) => {
            return (
              <>
                <div className={classes.rootAvatar} key={i}>
                  <Avatar alt="ok" src={res?.global_user?.avatar ?? null} />
                  <div className={classes.textWraper}>
                    <P14Bold className={classes.fontName}>
                      {res?.global_user?.name ?? '-'}
                    </P14Bold>
                    <PmdG className={classes.fontPosition}>
                      {res?.global_user?.people_work_placements[0]
                        ?.company_employee_position?.name ?? '-'}
                    </PmdG>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </PopupWrapper>
    </>
  )
}

export default ParticipantPopup
