import gql from 'graphql-tag'

export const GET_WEIGHT_UNIT = gql`
  {
    kpi_weight_units {
      id
      name
    }
  }
`
