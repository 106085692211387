import gql from "graphql-tag";

export const ADD_SQUAD_MEMBER = gql`
  mutation addMyTeamPerformance($team: [performance_teams_insert_input!]!) {
    insert_performance_teams(objects: $team) {
      affected_rows
    }
  }
`;

export const EDIT_SQUAD_NAME = gql`
  mutation($user:uuid!,$label:jsonb!){
    update_people_work_placements(
      where:{user:{_eq:$user},status:{_eq:"ACTIVE"}},
      _append:{
        placement_fields:$label
      }
    ){
      affected_rows
    }
  }
`