import React, {useState} from 'react'

import {
  Button,
  IconButton,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import Search from '@material-ui/icons/Search'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {Filter} from '@smartasn/wlb-utils-components'
import {
  anchorOrigin,
  listFilterGoal,
  listFilterTask,
  transformOrigin,
} from './AllFIlterData'
import moment from 'moment'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import Shorting from '@material-ui/icons/KeyboardArrowDown'
import {QuickFilter, StyledMenuItem, StyledMenus} from '../../PerformanceStyles'
import {USER_ID} from '../../../../utils/globals'

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 30px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  sectionRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 22,
  },
  wrapIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .icon-button': {
      marginRight: 12,
      cursor: 'pointer',
    },
    '& .header-name': {
      fontWeight: '600',
      fontSize: 16,
    },
  },
  buttonRoot: {padding: '12px 32px'},
  buttonLabel: {fontWeight: '600'},
  sortingRoot: {width: 162, height: 44},
  searchRoot: {
    width: '100%',
    '& > .MuiOutlinedInput-root': {
      height: '48px !important',
    },
  },
  searchInputRoot: {height: 44},
  searchInput: {fontSize: 12},
  childRoot: {marginTop: 12},
  outline: {borderColor: '#a9a8a8'},
  textFilter: {
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    margin: '35px 0px',
    cursor: 'pointer',
  },
  wrapQuickFilter: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0px 30px',
  },
  labelButtonFilter: {
    fontWeight: '600',
    textTransform: 'none',
  },
}))

export default function SeeAll(props) {
  const {children} = props
  const classes = useStyles()

  return (
    <Paper classes={{root: classes.root}}>
      <Header {...props} />
      <FilterHeader {...props} />
      <div>{children}</div>
    </Paper>
  )
}

const Header = props => {
  const {title, onBack, buttonConfig} = props
  const classes = useStyles()

  const defaultProps = {
    classes: {root: classes.buttonRoot, label: classes.buttonLabel},
    variant: 'contained',
    color: 'primary',
    disableElevation: true,
  }

  return (
    <div className={classes.sectionRoot}>
      <div className={classes.wrapIcon}>
        <IconButton onClick={onBack} size="small" className="icon-button">
          <ArrowBackIcon style={{color: '#014a62'}} />
        </IconButton>
        <Typography className="header-name">{title}</Typography>
      </div>

      <div>{buttonConfig && buttonConfig(defaultProps)}</div>
    </div>
  )
}

const FilterHeader = props => {
  const classes = useStyles()
  const [sorting, setSorting] = useState('Urutkan')
  const [quickFilter, setQuickFilter] = useState(false)
  const {setSort, search, setSearch, filterData, setFilterData, type} = props
  const isGoal = window.location.pathname.split('/').pop() === 'all-goal'
  const isDepartement = type === 'department'

  const handleApply = res => {
    const raw = res[0]
    const include = {}

    for (const key in raw) {
      let value = raw[key]
      if (Array.isArray(value)) {
        const tempData = []
        const taskType = []

        for (let idx = 0, len = value.length; idx < len; idx++) {
          const item = value[idx]
          const val = item.value

          if (key === 'type') {
            const splitVal = val.split('-')
            const typeVariant = splitVal[0].trim()
            const typeMethod = parseInt(splitVal[1].trim())
            if (!taskType.includes(typeVariant)) {
              taskType.push(typeVariant)
            }

            if (!tempData.includes(typeMethod)) {
              tempData.push(typeMethod)
            }

            if (taskType.includes('Personal') && taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = true
            } else if (taskType.includes('Company')) {
              include.isCompany = true
              include.isPersonal = false
            } else {
              include.isCompany = false
              include.isPersonal = true
            }
          } else if (key === 'percentage') {
            const splitVal = val.split('-')
            if (
              !tempData.includes(parseInt(splitVal[0])) &&
              !tempData.includes(parseInt(splitVal[1]))
            ) {
              tempData.push(splitVal[0])
              tempData.push(splitVal[1])
            }
            include.progressPersenFrom = Math.min(...tempData)
            include.progressPersenTo = Math.max(...tempData)
          } else {
            tempData.push(val)
          }
        }
        value = tempData.length ? tempData : null
      } else if (key === 'period') {
        const format = 'YYYY-MM-DD'
        include.dateFrom = moment(value.from).format(format)
        include.dateTo = moment(value.to).format(format)
      }

      if (key !== 'percentage' && key !== 'period') {
        if (key === 'filterStatus') {
          if (value?.length === 1) {
            include[key] = value[0]
          } else {
            include[key] = null
          }
        } else {
          include[key] = value
        }
      }
    }
    setQuickFilter(false)
    setFilterData({
      ...filterData,
      include,
    })
  }

  const handleQuickFilter = () => {
    setQuickFilter(!quickFilter)
    if (!quickFilter) {
      setFilterData({
        include: {
          ...filterData.include,
          reporter: USER_ID,
        },
      })
    } else {
      setFilterData({
        include: {
          ...filterData.include,
          reporter: undefined,
        },
      })
    }
  }

  return (
    <>
      {isDepartement && (
        <div className={classes.wrapQuickFilter}>
          <QuickFilter>Quick Filter:</QuickFilter>
          <Button
            color="primary"
            disableElevation
            variant={quickFilter ? 'contained' : 'text'}
            onClick={handleQuickFilter}
            classes={{
              label: classes.labelButtonFilter,
            }}
          >
            Reported by Me
          </Button>
        </div>
      )}

      <TextField
        value={search}
        size="small"
        variant="outlined"
        placeholder="Cari"
        classes={{root: classes.searchRoot}}
        onChange={e => setSearch(e.target.value)}
        InputProps={{
          classes: {
            input: classes.searchInput,
            root: classes.searchInputRoot,
          },
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <div
        className={classes.sectionRoot}
        style={{height: 20, margin: '35px 5px'}}
      >
        <PopupState variant="popover" popupId="menu-sorting">
          {popupState => (
            <>
              <div
                {...bindTrigger(popupState)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <BoldTypography
                  component="span"
                  style={{verticalAlign: 'top', fontSize: 14}}
                >
                  {sorting}
                </BoldTypography>
                <Shorting fontSize="small" />
              </div>

              <StyledMenus {...bindMenu(popupState)}>
                <StyledMenuItem
                  style={{color: 'black'}}
                  onClick={() => {
                    popupState.close()
                    setSorting('Latest')
                    setSort('desc')
                  }}
                >
                  Terbaru
                </StyledMenuItem>
                <StyledMenuItem
                  style={{color: 'black'}}
                  onClick={() => {
                    popupState.close()
                    setSorting('Oldest')
                    setSort('asc')
                  }}
                >
                  Terlama
                </StyledMenuItem>
              </StyledMenus>
            </>
          )}
        </PopupState>

        <Filter
          includeExclude={false}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id="filter-sumamry-achievement"
          onApply={handleApply}
          listFilter={isGoal ? listFilterGoal : listFilterTask}
        >
          <Typography className={classes.textFilter}>Filter</Typography>
        </Filter>
      </div>
    </>
  )
}
