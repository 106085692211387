import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  Icon,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import HeaderGenerate from '../../../../components/header/HeaderGenerate'
import {
  AVATAR,
  COMPANY_NAME,
  NAME,
  NIP,
  POSITION_NAME,
} from '../../../../utils/globals'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'
import {GET_PROGRESS_GOAL, GET_REPORT_TASK} from '../../../../graphql/queries'
import {
  goalType,
  visibility,
  priority as prior,
} from '../../goal-components/TabDialog/constant'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const FLEX_COLUMN = {display: 'flex', flexDirection: 'column'}
const WLB_BORDER = 'solid 1px #eff2f4'
const PADDING_VALUE = '8px 16.5px'
const FLEX_ROW = {display: 'flex', flexDirection: 'row'}
const TEXT_GREY = {color: '#a9a8a8', fontSize: 8}
const TYPE_GOAL_TASK = (type, format, methodType = false) => {
  let temp_return = null
  if (format === 'text') {
    temp_return = `${
      type
        ? GLOBAL_GLOSSARY.performance['Company Goal']
        : GLOBAL_GLOSSARY.performance['Personal Goal']
    } ${methodType ? '-' : ''} ${goalType[methodType] ?? ''}`
  } else if (format === 'color') {
    temp_return = {
      color: type ? '#a35a31' : '#874caf',
      fontSize: 10,
    }
  }
  return temp_return
}

const useStyles = makeStyles({
  emojiBuble: {
    borderRadius: '40px',
    width: '24%',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    justifyContent: 'space-between',
    border: WLB_BORDER,
  },
  commentContainer: {
    marginTop: '1rem',
    padding: '1rem 1.6rem',
    borderRadius: 5,
    border: WLB_BORDER,
  },
  dividerComment: {margin: '1rem -1.6rem'},
  feedbacktype: {marginBottom: '3rem'},
  dividerHEader: {height: '4px!important', background: '#e5e5e5'},
  dividerFooter: {
    height: '10px!important',
    background: '#014a62',
    margin: '0 -2rem -2rem',
  },
  textComment: {width: '21%', padding: '0 2%'},
  container: {width: 720, margin: 'auto'},
  containerRoot: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  footerWrapper: {
    marginTop: 40,
    paddingBottom: 12,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  textFooter: {fontSize: '0.875rem', color: '#a9a8a8', lineHeight: '15px'},
  lineBottom: {borderBottom: '10px solid #014a62'},
  wrapperBody: {...FLEX_COLUMN, border: WLB_BORDER, borderRadius: 5},
  titleBody: {padding: PADDING_VALUE, borderBottom: WLB_BORDER},
  codeTask: {color: '#039be5'},
  dateTitle: {fontSize: 8, color: '#a9a8a8'},
  valueBody: {
    ...FLEX_ROW,
    padding: '8px 16.5px 17px 16.5px',
    borderBottom: WLB_BORDER,
  },
  valueWrapper: {
    ...FLEX_COLUMN,
    minWidth: 80,
    marginRight: 10,
    minHeight: 72,
    justifyContent: 'space-between',
  },
  wrapperPeople: {
    ...FLEX_ROW,
    marginRight: 23,
    padding: PADDING_VALUE,
    borderBottom: WLB_BORDER,
  },
  avatar: {width: 26, height: 26, marginRight: 8, marginBottom: 8},
  progressRoot: {height: 14, marginTop: 8, backgroundColor: '#e9ecee'},
  progressPrimary: {color: '#014a62', borderRadius: '0px 2px 2px 0px'},
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
})

const Acheivment = ({score, value, style}) => {
  const classes = useStyles()
  return (
    <>
      <div
        style={{
          ...FLEX_ROW,
          ...style,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography style={{...TEXT_GREY}}>Pencapaian</Typography>
        <Typography style={{fontSize: 10, fontWeight: '600'}}>
          {score}
        </Typography>
      </div>
      <LinearProgress
        variant="determinate"
        classes={{
          root: classes.progressRoot,
          barColorPrimary: classes.progressPrimary,
        }}
        value={value}
      />
    </>
  )
}

const TaskCode = ({
  code,
  name,
  date,
  priority,
  forHeader = false,
  typeGoal,
  methodType,
}) => {
  const _priority = type => {
    const generateColor = type => {
      switch (type) {
        case 'LOW':
          return '#039be5'
        case 'MEDIUM':
          return '#ffa000'
        case 'HIGH':
          return '#ef4d5e'
        default:
          return ''
      }
    }

    return (
      <div
        style={{
          display: 'flex',
          backgroundColor: generateColor(type),
          width: '70px',
          height: '22px',
          borderRadius: 5,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="body2"
          style={{color: '#fff', fontSize: 10, textTransform: 'capitalize'}}
        >
          {prior[type]}
        </Typography>
      </div>
    )
  }

  return (
    <div style={{...FLEX_ROW, justifyContent: 'space-between'}}>
      <div style={FLEX_COLUMN}>
        <div
          style={forHeader ? {...FLEX_ROW, marginBottom: '-12px'} : FLEX_ROW}
        >
          <Typography
            style={{
              fontSize: forHeader ? 12 : 10,
              fontWeight: forHeader ? '500' : '600',
              marginRight: 4,
              color: '#039be5',
            }}
          >
            {code}
          </Typography>
          <Typography
            style={{
              fontSize: forHeader ? 12 : 10,
              fontWeight: forHeader ? '500' : '600',
              marginBottom: 3,
            }}
          >
            {name}
          </Typography>
        </div>
        {!forHeader && (
          <Typography style={TYPE_GOAL_TASK(typeGoal, 'color', false)}>
            {TYPE_GOAL_TASK(typeGoal, 'text', methodType)}
          </Typography>
        )}
        {date && <Typography style={TEXT_GREY}>{date}</Typography>}
      </div>
      {priority && _priority(priority)}
    </div>
  )
}

const Profile = () => {
  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 21}}>
      <img
        alt="avatar"
        src={AVATAR}
        style={{
          width: '57px',
          height: '57px',
          borderRadius: '10px',
          marginRight: '1rem',
        }}
      />
      <div>
        <Typography variant="body2" style={{fontWeight: 'bold'}}>
          {NAME}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#a9a8a8',
          }}
        >
          {NIP}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#a9a8a8',
          }}
        >
          {POSITION_NAME} at {COMPANY_NAME}
        </Typography>
      </div>
    </div>
  )
}

const Footer = ({visible, handlePrints}) => {
  const classes = useStyles()

  return (
    <footer>
      <style>{`@media print {footer {position: fixed;bottom: 0;}}`}</style>
      <div className={classes.footerWrapper}>
        <Typography className={classes.textFooter}>
          Dihasilkan pada {moment().format('LL')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          style={{
            padding: '4px 12px',
            display: visible ? 'flex' : 'none',
          }}
          onClick={handlePrints}
        >
          <Icon style={{marginRight: 12}}>print</Icon>Cetak
        </Button>
      </div>
    </footer>
  )
}

const Body = props => {
  const classes = useStyles()
  const res = props.response
  const {dataGoal} = props
  const kpi = res.performance_goal.kpi_list
  const resWeight = kpi?.kpi_weight_unit?.name ?? '-'
  const progressGoal = dataGoal.progress_percentage
  const resWeightGoal =
    dataGoal.kpi_list && dataGoal.kpi_list.kpi_weight_unit
      ? dataGoal.kpi_list.kpi_weight_unit.name
      : ''
  const typeGoal =
    res?.performance_goal?.kpi_list.kpi_cascading_lists_aggregate?.aggregate
      ?.count > 0
  const goalAspect = res?.performance_goal?.kpi_list?.kpi_aspect?.name ?? '-'

  const _dataValue = (name, value) => {
    return (
      <div style={{...FLEX_COLUMN}}>
        <Typography style={TEXT_GREY}>{name}</Typography>
        <Typography
          style={{
            fontSize: 10,
            fontWeight: '600',
            marginBottom: 6,
            maxWidth: 200,
          }}
        >
          {value}
        </Typography>
      </div>
    )
  }

  const _achievementDetail = (date, score, i) => {
    return (
      <div
        style={{
          ...FLEX_ROW,
          justifyContent: 'space-between',
          padding: '6px 0px',
          alignItems: 'center',
          borderTop: WLB_BORDER,
        }}
        key={i}
      >
        <Typography style={TEXT_GREY}>{date}</Typography>
        <Typography style={{fontSize: 10}}>{score}</Typography>
      </div>
    )
  }

  return (
    <div className={classes.wrapperBody}>
      <div className={classes.titleBody}>
        <TaskCode
          code={`[${'PF- ' + res.id}]`}
          name={res.name}
          date={`${moment(res.startdate).format('LL')} - ${moment(
            res.enddate
          ).format('LL')}`}
          priority={res.priority}
          typeGoal={typeGoal}
          methodType={res.method_type}
        />
      </div>
      <div className={classes.valueBody}>
        <div className={classes.valueWrapper}>
          {_dataValue('Perspektif', kpi && kpi.kpi_perspective.name)}
          {_dataValue('Sifat Penilaian', kpi && kpi.kpi_scoring_nature.name)}
        </div>
        <div className={classes.valueWrapper}>
          {_dataValue('Visibilitas', visibility[res.visibility])}
          {_dataValue(`Aspek ${GLOBAL_GLOSSARY.performance.Goal}`, goalAspect)}
        </div>
        <div className={classes.valueWrapper}>
          {_dataValue('Metrik', res?.metric ?? '-')}
          {_dataValue(
            'Target',
            `${res.target} ${res?.kpi_weight_unit?.name || resWeight}`
          )}
        </div>
      </div>
      <div className={classes.wrapperPeople}>
        {/* REPORTER */}
        <div style={{...FLEX_COLUMN, width: '13rem', marginRight: 23}}>
          <Typography style={{...TEXT_GREY, marginBottom: 8}}>
            Pelapor
          </Typography>
          <div style={FLEX_ROW}>
            <Avatar
              classes={{root: classes.avatar}}
              src={res.ReporterUser && res.ReporterUser.avatar}
            />
            <div style={{...FLEX_COLUMN, marginLeft: 2}}>
              <Typography
                style={{fontSize: 10, fontWeight: '600', marginBottom: 2}}
              >
                {res.ReporterUser && res.ReporterUser.name}
              </Typography>
              <Typography style={TEXT_GREY}>
                {res.ReporterUser &&
                  res.ReporterUser.people_work_placements[0].company_job_profile
                    .title}{' '}
                di{' '}
                {res.ReporterUser &&
                  res.ReporterUser.people_work_placements[0].company_profile
                    .brand_name}
              </Typography>
            </div>
          </div>
        </div>
        {/* PARTICIPANT */}
        <div style={FLEX_COLUMN}>
          <Typography style={{...TEXT_GREY, marginBottom: 8}}>
            Peserta
          </Typography>
          <div style={{...FLEX_ROW, flexWrap: 'wrap'}}>
            {res.performance_task_assignments.map((assign, i) => (
              <Avatar
                classes={{root: classes.avatar}}
                key={i}
                src={assign.global_user.avatar}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Achievement */}
      <div
        style={{
          ...FLEX_COLUMN,
          padding: PADDING_VALUE,
          borderBottom: WLB_BORDER,
        }}
      >
        <Acheivment
          score={`${res.progress} ${res?.kpi_weight_unit?.name || resWeight} (${
            res && res.progress_percentage
              ? res.progress_percentage.toFixed(2)
              : 0
          }%)`}
          value={
            res?.progress_percentage <= 100 ? res?.progress_percentage : 100
          }
        />
        <Typography
          style={{
            ...TEXT_GREY,
            fontWeight: '600',
            paddingBottom: 10,
            marginTop: 15,
          }}
        >
          Detail Pencapaian
        </Typography>
        {res.performance_task_activities.map((act, i, prevData) =>
          _achievementDetail(
            moment(act.date_added).format('LL'),
            `${prevData[i - 1]?.progress_percentage ?? 0}% - ${
              act.progress_percentage
            }%`,
            i
          )
        )}
      </div>

      {/* Goal */}
      <div style={{...FLEX_COLUMN, padding: PADDING_VALUE}}>
        <Typography style={{...TEXT_GREY, marginBottom: 6}}>
          {GLOBAL_GLOSSARY.performance.Goal}
        </Typography>
        <TaskCode
          code={`[${'PF- ' + res.performance_goal.id}]`}
          name={res.performance_goal.name}
          date={`${moment(res.performance_goal.startdate).format(
            'LL'
          )} - ${moment(res.performance_goal.enddate).format('LL')}`}
          priority={res.performance_goal.priority}
          typeGoal={typeGoal}
          methodType={res?.performance_goal?.kpi_list?.type}
        />
        <Acheivment
          style={{marginTop: 14}}
          score={`${dataGoal?.performance_tasks_aggregate?.aggregate?.sum
            ?.progress ?? '0'} ${resWeightGoal} (${progressGoal.toFixed(2)}%)`}
          value={progressGoal >= 100 ? 100 : progressGoal}
        />
      </div>
    </div>
  )
}

export default withRouter(function GenerateTask(props) {
  const classes = useStyles()

  const [buttonVisible, setButtonVisible] = useState(true)
  const handlePrints = () => {
    setButtonVisible(false)
    const reportName = `Laporan ${GLOBAL_GLOSSARY.performance.Task}`
    document.title = `${NAME}_${NIP}_${moment().format('LL')}_${reportName}_${
      data.performance_tasks[0].name
    }`
    setTimeout(() => {
      window.print()
      setButtonVisible(true)
      document.title = 'SmartASN - Kinerja'
    }, 500)
  }

  const {data, error} = useQuery(GET_REPORT_TASK, {
    variables: {task: props.match.params.id},
  })

  const {data: data2, error: error2} = useQuery(GET_PROGRESS_GOAL, {
    variables: {task: props.match.params.id},
  })

  if (
    (data === undefined && error === undefined) ||
    (data2 === undefined && error2 === undefined)
  ) {
    return (
      <div className={classes.loadingProgress}>
        <CircularProgress />
        <Typography variant="body1" color="primary" style={{marginTop: 10}}>
          Loading data, please wait
        </Typography>
      </div>
    )
  } else if (error !== undefined || error2 !== undefined) {
    return <div>{error || error2}</div>
  }

  const res = data.performance_tasks[0]

  return (
    <div className={classes.container}>
      <HeaderGenerate
        taskCode={
          <TaskCode forHeader code={`[${'PF- ' + res.id}]`} name={res.name} />
        }
        title={GLOBAL_GLOSSARY.performance.Task}
        from={moment(res.startdate).format('LL')}
        to={moment(res.enddate).format('LL')}
      />
      <Divider className={classes.dividerHEader} />
      <Paper style={{padding: '35px 35px 0rem 35px'}} elevation={0}>
        <Profile />
        <Body response={res} dataGoal={data2.performance_goals[0]} />
        <Footer handlePrints={handlePrints} visible={buttonVisible} />
      </Paper>
    </div>
  )
})
