import styled from 'styled-components'
import {Button as ButtonMui} from '@material-ui/core'

export const Button = styled.div`
  background: tomato;
  border-color: tomato;
  padding: 10px;
  border-style: solid;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: white;
    color: tomato;
    border-color: white;
  }
`
export const StyledSmallButton = styled(ButtonMui)`
  background: ${props => props.background || '#055469'};
  border-radius: 6px;
  border: 0;
  color: white;
  min-height: 24px;
  padding: 0 15px;
  text-transform: capitalize;
  width: 5rem;
  &:hover {
    background: ${props => props.background || '#055469'};
  }
`

export const StyledMediumButton = styled(ButtonMui)`
  background: ${props => props.background || '#055469'};
  border-radius: 3px;
  border: 0;
  color: white;
  height: 4.5%;
  padding: 15px 15px;
  text-transform: capitalize;
  width: 5rem;
  &:hover {
    background: ${props => props.background || '#055469'};
  }
`
