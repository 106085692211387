import gql from 'graphql-tag'

export const GET_REPORT_TASK = gql`
  query getDetail($task: bigint) {
    performance_tasks(where: {id: {_eq: $task}}) {
      id
      name
      target
      target_stretch
      target_unit
      startdate
      enddate
      progress
      progress_percentage
      weight
      date_added
      priority
      method_type
      metric
      visibility
      kpi_weight_unit {
        id
        name
      }
      performance_goal {
        id
        name
        startdate
        enddate
        priority
        target
        performance_tasks {
          id
          progress
        }
        kpi_list {
          id
          type
          name
          formula
          description
          kpi_scoring_nature {
            id
            name
          }
          kpi_weight_unit {
            id
            name
          }
          kpi_aspect {
            id
            name
          }
          kpi_level {
            id
            name
          }
          kpi_version {
            id
            name
          }
          kpi_method {
            id
            name
          }
          kpi_monitoring_frequency {
            id
            name
          }
          kpi_perspective {
            id
            name
          }
          kpi_cascading_lists_aggregate {
            aggregate {
              count
            }
          }
        }
      }
      ReporterUser {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          regno
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
          }
        }
      }
      performance_task_activities(
        where: {is_progress: {_eq: true}, task: {_eq: $task}}
        order_by: [{date_added: asc}]
      ) {
        id
        date_added
        progress_percentage
      }
      performance_task_assignments {
        global_user {
          id
          name
          avatar
          people_work_placements {
            id
            company_employee_position {
              id
              name
            }
            company_profile {
              id
              brand_name
            }
          }
        }
      }
    }
  }
`
