import React, {useEffect} from 'react'
import {Route, Switch} from 'react-router-dom'
import AppContainer from '../pages/container/AppContainer'
import {AppWrapper} from '../components/job-portal/JobPortalStyles'
import NotFoundComponent from '../pages/error/NotFoundComponent'
import NoRoles from '../pages/error/NoRoles'
import UnderDevelopment from '../pages/error/UnderDevelopment'
import DetailMultiraterComponent from '../pages/performance-tabs/detail-multirater/DetailMultiraterComponent'
import GenerateFeedback from '../pages/performance-tabs/summary-components/generate-report/GenerateFeedback'
import GenerateNPS from '../pages/performance-tabs/summary-components/generate-report/GenerateNPS'
import GenerateTask from '../pages/performance-tabs/summary-components/generate-report/GenerateTask'
import {listenCookieChange} from '../utils/helpers'
import PrivateRoute from '../pages/private/PrivateRoute'

const pathWithDefaultLayout = [
  '/',
  '/goals/:type',
  '/goals/:type/all-goal',
  '/goals/:type/all-task',
  '/squad',
  '/squad/:type',
  '/squad/:type/detail',
  '/squad/:type/detail/:id',
  '/survey',
  '/summary',
  '/archive',
  '/committee',
  '/committee/version/:id/:id_job/:id_group',
  '/committee/version/:id_version/:id_job/:id_group/:id_emp',
]

const rootRoutes = [
  {
    exact: true,
    path: '/page-under-development',
    component: UnderDevelopment,
  },
  {
    exact: true,
    path: '/generate-report-task/:id',
    component: GenerateTask,
  },
  {
    exact: true,
    path: '/generate-report-nps/:id',
    component: GenerateNPS,
  },
  {
    exact: true,
    path: '/generate-report-feedback/:from/:to',
    component: GenerateFeedback,
  },
  {
    exact: true,
    path: '/multirater/:id',
    component: DetailMultiraterComponent,
  },
  {
    exact: true,
    path: pathWithDefaultLayout,
    component: AppContainer,
  },
  {
    exact: false,
    path: '/access-denied',
    component: NoRoles,
  },
]

function Routes() {
  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <AppWrapper>
      <Switch>
        {rootRoutes.map((route, i) => (
          <PrivateRoute
            component={route.component}
            exact={route.exact}
            path={route.path}
            key={i}
          />
        ))}
        <Route component={NotFoundComponent} />
      </Switch>
    </AppWrapper>
  )
}

export default Routes
