import {GlobalHeader} from '@smartasn/wlb-utils-components'
import React from 'react'
import {
  ADMIN_APP_URL,
  APP_COOKIE,
  HOLDING_APP_URL,
  SOSMED_APP_URL,
} from '../../utils/globals'

export const Header = () => {
  const env = {
    REACT_APP_SOSMED: SOSMED_APP_URL + '/',
    REACT_APP_HC_ADMIN: ADMIN_APP_URL,
    REACT_APP_HC_HOLDING: HOLDING_APP_URL,
    REACT_APP_DOMAIN: APP_COOKIE,
    HOME_URL: process.env.REACT_APP_HC_PERFORMANCE,
    REACT_APP_CHAT: process.env.REACT_APP_CHAT,
    // notif_path dev = performance, staging,prod = all
    NOTIF_PATH: 'performance',
    SSO_URL: process.env.REACT_APP_SSO + '/',
    REACT_APP_LEARNING: process.env.REACT_APP_HC_LEARNING,
    rest_url: process.env.REACT_APP_API_SOSMED_URL,
    wlb_rest_url: process.env.REACT_APP_API_URL,
    USER_URL: process.env.REACT_APP_HC_TIME,
    PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
    LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
    TALENT_URL: process.env.REACT_APP_HC_TALENT,
    VENDOR_URL: process.env.REACT_APP_HC_VENDOR,
  }

  return <GlobalHeader env={env} language="indonesian" />
}
