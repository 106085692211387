import gql from 'graphql-tag'

export const GET_LIST_ACTIVITY_GOAL = gql`
  query GetTabGoalActivity(
    $goal: bigint!
    $order: order_by
    $offset: Int
    $limit: Int
  ) {
    total: performance_goal_activities_aggregate(where: {goal: {_eq: $goal}}) {
      aggregate {
        count
      }
    }
    performance_goal_activities(
      where: {goal: {_eq: $goal}}
      order_by: [{id: $order}]
      offset: $offset
      limit: $limit
    ) {
      id
      date_created
      global_user {
        id
        name
        avatar
        people_work_placements {
          id
          company_job_profile {
            id
            code
            title
          }
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
        }
      }
      comment
      attachment
      last_modified
      deletedat
      total_comment: performance_goal_comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_LIST_COMMENT_GOAL = gql`
  query($activity: bigint, $offset: Int, $limit: Int) {
    total: performance_goal_comments_aggregate(
      where: {activity: {_eq: $activity}}
    ) {
      aggregate {
        count
      }
    }
    performance_goal_comments(
      where: {activity: {_eq: $activity}}
      order_by: [{id: desc}]
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(order_by: [{id: desc}], limit: 1) {
          id
          regno
          company_job_profile {
            id
            code
            title
          }
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
        }
      }
      comment
      attachment
      date_created
      last_modified
      deletedat
    }
  }
`

export const GET_LIST_ACTIVITY_TASK = gql`
  query GetTabActivity(
    $task: bigint!
    $order: order_by
    $offset: Int
    $limit: Int
  ) {
    total: performance_task_activities_aggregate(where: {task: {_eq: $task}}) {
      aggregate {
        count
      }
    }
    performance_task_activities(
      where: {task: {_eq: $task}}
      order_by: [{id: $order}]
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements {
          id
          company_job_profile {
            id
            code
            title
          }
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
        }
      }
      comment
      is_progress
      progress_percentage
      attachment
      date_added
      last_modified
      deleted_at
      total_comment: performance_task_comments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_LIST_COMMENT_TASK = gql`
  query($activity: bigint, $offset: Int, $limit: Int) {
    total: performance_task_comments_aggregate(
      where: {task: {_eq: $activity}}
    ) {
      aggregate {
        count
      }
    }
    performance_task_comments(
      where: {task: {_eq: $activity}}
      order_by: [{id: desc}]
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(order_by: [{id: desc}], limit: 1) {
          id
          regno
          company_job_profile {
            id
            code
            title
          }
          company_employee_position {
            id
            name
          }
          company_organization {
            id
            name
          }
        }
      }
      comment
      attachment
      date_created
      last_modified
      deletedat
    }
  }
`
