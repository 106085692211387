import React from 'react'

import {makeStyles} from '@material-ui/core'

import GoalSummary from './GoalSummary'
import TaskSummary from './TaskSummary'
import BadgesSummary from './BadgesSummary'

const useStyles = makeStyles({
  root: {padding: '24px 20px'},
})

export default function SummaryTab({user}) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GoalSummary user={user} />
      <TaskSummary user={user} />
      <BadgesSummary user={user} />
    </div>
  )
}
