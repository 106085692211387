import styled from 'styled-components'
import {Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'

export const TitleWrapStyled = styled.div`
  display: flex;
  flex-direction: row;
`

export const TitleStyled = styled(Typography)`
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  color: #014a62;
  margin-left: 11px;
`

export const InfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
`

export const HoverBadgeContainer = styled.div`
  width: 400px;
  height: auto;
  padding: 10px;

  .title-badge-section {
    display: flex;
    flex-direction: row;

    .badge-image {
      max-width: 28px;
      max-height: 28px;
      margin-right: 19px;
    }

    .name-badge {
      color: #333333;
      font-size: 14px;
      font-weight: 600;
      margin: 0px !important;
      align-self: center;
    }
  }

  .description-section {
    margin: 24px 0px;

    .description-badge {
      color: #a9a8a8;
      font-weight: 400;
      font-size: 14px;
      margin: 0px !important;
    }
  }

  .axis-section {
    display: flex;
    flex-direction: row;
    .x-axis-section {
      width: 50%;
    }
    .p-title-axis {
      font-family: Muli;
      font-weight: 600;
      font-size: 12px;
      color: #333333;
      margin-bottom: 12px;
    }
    .y-axis-section {
      margin-left: 24px;
      width: 50%;
    }
    .hover-between {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;
      .name-section {
        width: 80%;
      }
      .value-section {
        width: 20%;
      }
      .p-value-axis {
        font-family: Muli;
        font-size: 12px;
        color: #333333;
        margin-top: 7px;
        margin: 0px !important;
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  wrapper: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  expansion: {
    padding: '0 14px 0 38px',
    border: 'none',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  avatar: {
    marginRight: 45,
  },
  marginBottom: {
    marginBottom: 16,
  },
  paper: {
    padding: 24,
    marginLeft: 12,
    marginBottom: 30,
  },
  paperDetail: {
    padding: 24,
  },
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  filter: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    padding: '8px 16px',
  },
  textFilter: {
    marginLeft: 16,
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0px',
    padding: '8px 0px',
  },
  bodyDetail: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0px',
    padding: '8px 0px',
    cursor: 'pointer',
  },
  time: {
    marginLeft: 'auto',
    color: '#a9a8a8',
  },
  seeMore: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#039be5',
    cursor: 'pointer',
  },
  large: {
    width: 80,
    height: 80,
  },
  largeTalent: {
    width: 100,
    height: 100,
  },
  infoDetail: {
    flexDirection: 'coloumn',
    marginBottom: '1rem',
  },
  info1: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    color: '#014a62',
    marginRight: 17,
    textDecoration: 'none',
    fontWeight: 'bold',
  },
  role: {
    color: '#a9a8a8',
  },
  info2: {
    color: '#a9a8a8',
  },
  profil: {
    height: 34,
    width: 'auto',
  },
  score: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#a9a8a8',
  },
  classification: {
    padding: '0px 10px',
    borderRight: '1px solid rgba(0, 0, 0, 0.16)',
  },
  score2: {
    padding: '0px 10px',
    display: 'flex',
    flexDirection: 'row',
  },
  score3: {
    marginLeft: 7,
  },
  dialog: {
    width: 1101,
  },
  talentDetailHeader: {
    backgroundColor: '#eff2f4',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px',
  },
  talentDetailTitle: {
    fontWeight: '600',
  },
  talentSubtittle: {
    fontWeight: '600',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    color: '#a9a8a8',
  },
  iconClose: {
    cursor: 'pointer',
    padding: '16px 24px',
    marginLeft: 'auto',
    fontSize: '32px !important',
  },
  talentDetaiInfo: {
    display: 'flex',
    width: '30%',
    flexDirection: 'column',
    marginLeft: '1rem',
    padding: 24,
  },
  globalInfoTalentDetail: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: '20px 40px',
  },
  talentDetailAvatar: {
    width: 128,
    height: 128,
  },
  talentDetailName: {
    fontSize: 22,
    fontWeight: 600,
  },
  talentDetailNumber: {
    color: '#a9a8a8',
    marginBottom: 10,
  },
  talentDetailRole: {
    fontSize: 20,
    fontWeight: 600,
  },
  talentDetailDate: {
    color: '#a9a8a8',
  },
  talentDetailBadge: {
    height: 79,
    width: 'auto',
  },
  badge: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  talentDetailPosition: {
    fontWeight: '600',
    fontSize: 16,
  },
  talentDetailScore: {
    textAlign: 'center',
  },
  talentDetailTalentInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'space-between',
  },
  paperBadge: {
    width: '30%',
    borderRadius: '5px',
    marginRight: '2rem',
  },
  paperPostBadge: {
    width: '70%',
    borderRadius: '5px',
    paddingBottom: 20,
  },
  talentInfoPaper: {
    display: 'flex',
    flexGrow: '1',
    textAlign: 'center',
    flexDirection: 'column',
    margin: '20px 0px 0px 0px',
    padding: 25,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginRight: 30,
    '&:nth-child(4)': {
      marginRight: 0,
    },
  },
  talentDetailInfo: {
    fontSize: 15,
    marginBottom: 10,
  },
  padding: {
    padding: 40,
    width: '100%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginBottom: '1rem',
  },
  displayRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentInfoScore1: {
    width: 150,
    textAlign: 'right',
    marginLeft: 'auto',
  },
  talentInfoScore2: {
    width: 150,
    textAlign: 'right',
  },
  desc: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    '&:nth-child(n+1)': {
      fontSize: 20,
      fontWeight: '600',
    },
  },
  edit: {color: '#039be5', cursor: 'pointer'},
  titleBadge: {
    display: 'flex',
    padding: 20,
    justifyContent: 'space-between',
  },
  font: {
    fontWeight: 500,
    fontSize: 17,
  },
  titleDetail: {
    margin: '30px 0px 15px 0px',
    fontWeight: 600,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailTitleName: {
    fontSize: 20,
    fontWeight: '600',
    display: 'inline-block',
    marginLeft: 20,
  },
  paperRating: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginBottom: '1rem',
    marginTop: 24,
  },
  indicator1: {
    padding: '0 20px',
    marginBottom: 15,
  },
  indicator2: {
    padding: '0 20px',
  },
  titleIndicator: {
    marginBottom: 14,
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  valueIndicator: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemIndicator: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontWeight: 500,
    marginBottom: 3,
  },
  textNote: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 26,
  },
  wrapperTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginTop: 15,
  },
  lastUpdate: {
    margin: '30px 0px 15px 0px',
  },
  aspiration: {
    display: 'flex',
    flexDirection: 'row',
    padding: 30,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    flexWrap: 'wrap',
  },
  listContainer: {minHeight: '33.5rem'},
  containerChildBadge: {
    textAlign: 'center',
    padding: '0 20px 20px',
  },
  childBadgeContent: {
    marginTop: 12,
  },
  position: {
    color: '#014a62',
  },
  picStyle: {
    color: '#039BE5',
    fontSize: 12,
    cursor: 'pointer',
    marginTop: 4,
  },
}))

export default useStyles
