import {CircularProgress} from '@material-ui/core'
import React from 'react'
import {GLOBAL_GLOSSARY} from '../../utils/constant'
import {
  BoxWrapper,
  BoxRow,
  BoxColumn,
  Score,
  TypeScore,
} from '../job-portal/JobPortalStyles'

export default function BoxInformation(props) {
  const {data, loading, error} = props

  if (loading) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  let _goalComplete = 0
  for (let i = 0; i < data?.total_goal?.nodes.length; i++) {
    if (
      data?.total_goal?.nodes[i].complete.aggregate.count ===
      data?.total_goal?.nodes[i].total.aggregate.count
    )
      _goalComplete++
  }

  return (
    <BoxWrapper>
      <BoxRow>
        {/* <BoxColumn right>
          <Score>
            {data.index_score.length >= 1
              ? !isNaN(data.index_score[0].index_score)
                ? data.index_score[0].index_score.toFixed(1)
                : 0
              : 0}
          </Score>
          <TypeScore>Individual Performance Score</TypeScore>
        </BoxColumn> */}
        <BoxColumn right>
          <Score>
            {!isNaN(data.total_goal.aggregate.count)
              ? data.total_goal.aggregate.count
              : 0}
          </Score>
          <TypeScore>Total {GLOBAL_GLOSSARY.performance.Goal}</TypeScore>
        </BoxColumn>
        <BoxColumn>
          <Score>{_goalComplete}</Score>
          <TypeScore>{GLOBAL_GLOSSARY.performance.Goal} Selesai</TypeScore>
        </BoxColumn>
      </BoxRow>
      {/* <BoxRow>
        <BoxColumn right>
          <Score>
            {!isNaN(data.total_task.aggregate.count)
              ? data.total_task.aggregate.count
              : 0}
          </Score>
          <TypeScore>Task</TypeScore>
        </BoxColumn>
        <BoxColumn>
          <Score>
            {!isNaN(data.task_done.aggregate.count)
              ? data.task_done.aggregate.count
              : 0}
          </Score>
          <TypeScore>Task Done</TypeScore>
        </BoxColumn>
      </BoxRow> */}
    </BoxWrapper>
  )
}
