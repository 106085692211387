import gql from 'graphql-tag'

export const ADD_NEW_GROUP = gql`
  mutation addGroup($data: [performance_groups_insert_input!]!) {
    insert_performance_groups(objects: $data) {
      affected_rows
    }
  }
`

export const ADD_NEW_GOAL_GROUP = gql`
  mutation insertGoalLabel($name: String!, $color: String!) {
    insert_performance_goal_groups(objects: [{name: $name, color: $color}]) {
      affected_rows
    }
  }
`

export const DELETE_TASK_GROUP = gql`
  mutation deleteTaskLabel($id: bigint!) {
    delete_performance_groups(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`

export const DELETE_GOAL_GROUP = gql`
  mutation deleteGoalLabel($id: uuid!) {
    delete_performance_goal_groups(where: {id: {_eq: $id}}) {
      affected_rows
    }
  }
`
