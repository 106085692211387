import React, {useState, useContext} from 'react'

import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'

import ModalKpi from '../../Modal/ModalKpi'
import ModalOwnGoal from '../../Modal/ModalOwnGoal'
import {StyledMenus, StyledMenuItem} from '../GoalStyled'

import ListGoal from '../list/goal/ListGoal'
import PaperSearcher from '../../../shared-component/paper/PaperSearcher'
import {COMPANY_GOAL_TYPE, PERSONAL_GOAL_TYPE} from '../constant'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {DataContext} from '../../../../contexts/ContextConfig'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    padding: theme.spacing(2, 4, 3),
    overflowY: 'initial !important',
  },
  infiniteWidth100: {
    '& > .infinite-scroll-component__outerdiv': {
      width: '100%',
    },
  },
  height39: {
    '& > .MuiOutlinedInput-root': {
      height: '39px !important',
    },
  },
  wrapQuickFilter: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
  },
}))

function rand() {
  return Math.round(Math.random() * 20) - 10
}

function getModalStyle() {
  const top = 50 + rand()
  const left = 50 + rand()

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

export default function GoalsComponent({active}) {
  const classes = useStyles()
  const {setDataContext} = useContext(DataContext)
  const [modalStyle] = useState(getModalStyle)
  const [openkpi, setOpenKpi] = useState(false)
  const [openOwnGoal, setOpenOwnGoal] = useState(false)
  const [search, setSearch] = useState('')

  const handleClose = () => {
    setDataContext(true)
    setOpenKpi(false)
  }

  const handleOpenKpi = () => {
    setDataContext(null)
    setOpenKpi(true)
  }

  const handleOwnGoalModal = () => {
    setOpenOwnGoal(!openOwnGoal)
    setDataContext(openOwnGoal)
  }

  const handleClickMenu = type => {
    switch (type) {
      case COMPANY_GOAL_TYPE:
        handleOpenKpi()
        break

      case PERSONAL_GOAL_TYPE:
        handleOwnGoalModal()
        break

      default:
        break
    }
  }

  return (
    <>
      <PaperSearcher
        title={GLOBAL_GLOSSARY.performance.Goal}
        search={search}
        setSearch={setSearch}
        icon={
          <img
            src={require('../../../../assets/images/icons/icon-goal.svg')}
            alt="goals-icon"
          />
        }
        buttonConfig={props => {
          return <CreateGoalButton onClickMenu={handleClickMenu} {...props} />
        }}
      >
        <ListGoal filterValue={active} search={search} />
      </PaperSearcher>

      <ModalKpi
        open={openkpi}
        onClose={handleClose}
        styleModal={modalStyle}
        classNameModal={classes.paper}
        onBack={handleOpenKpi}
      />
      <ModalOwnGoal open={openOwnGoal} onClose={handleOwnGoalModal} />
    </>
  )
}

export const CreateGoalButton = props => {
  const {onClickMenu} = props

  const MENUS = [
    {
      label: `Membuat ${GLOBAL_GLOSSARY.performance.Goal_short} Unit`,
      type: COMPANY_GOAL_TYPE,
    },
    {
      label: `Membuat ${GLOBAL_GLOSSARY.performance.Goal_short} Pribadi`,
      type: PERSONAL_GOAL_TYPE,
    },
  ]

  return (
    <PopupState variant="popover" popupId="popup-create-goal">
      {popupState => (
        <>
          <Button {...bindTrigger(popupState)} {...props}>
            Membuat Hasil Kerja
          </Button>
          <StyledMenus {...bindMenu(popupState)}>
            {MENUS?.map(({label, type}) => (
              <StyledMenuItem
                key={type}
                variant="h6"
                onClick={() => {
                  onClickMenu(type)
                  popupState.close()
                }}
              >
                {label}
              </StyledMenuItem>
            ))}
          </StyledMenus>
        </>
      )}
    </PopupState>
  )
}
