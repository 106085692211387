import React, { useState } from 'react'
import {
  CommentWrapper,
  CommentDetailWrapper,
  CommentChildrenAvatar,
  CommentWrapperAct,
  FlexBetween,
  ChipInformation,
  CarouselCard,
} from '../../PerformanceStyles'
import {
  Icon,
  Menu,
  MenuItem,
  Typography,
  Snackbar,
  InputBase,
} from '@material-ui/core'
import {
  PmdP,
  Psm,
  PmdG,
  TypographyStyleds,
} from '../../../../components/typography/TypographyStyles'
import Moment from 'moment'
import DialogDelete from '../../../shared-component/DeletePopUp'
import { AVATAR, UPLOAD_URL, TOKEN, USER_ID } from '../../../../utils/globals'
import {
  trimString,
  timeStamp,
  getTypeFile,
  downloadFile,
} from '../../../../utils/helpers'
import { useSnackbar } from 'notistack'
import FieldInformationTooltip from '../../../shared-component/FieldInformationTooltip'
import BlockIcon from '@material-ui/icons/Block'
import PostingField from '../../../../components/post/PostingField'
import { FILE_TYPE_UPLOAD } from './constant'
import axios from 'axios'
import { AttachmentItemHover } from '../../../shared-component/attachment/AttachmentItemHover'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import FileDownloadIcon from '@material-ui/icons/GetApp'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  GET_LIST_COMMENT_GOAL,
  GET_LIST_COMMENT_TASK,
} from '../../../../graphql/queries'
import {
  ADD_COMMENT_ACTIVITIES_GOALS,
  ADD_COMMENT_ACTIVITIES_TASK,
  DELETE_COMMENT_ACTIVITY_TASK,
  DELETE_COMMENT_ACTIVITY_GOAL,
  UPDATE_COMMENT_ACTIVITIES_GOALS,
  UPDATE_COMMENT_ACTIVITIES_TASK,
} from '../../../../graphql/mutations'
import ItemAttachment from '../../../../components/attachment/ItemAttachment'
import ModalMutationFeedBack from '../../detail-modal/ModalMutationFeedBack'

export default function ActivityComment({
  type,
  idActivity,
  idx,
  condition,
  commentCount,
  updateComment,
}) {
  const { enqueueSnackbar } = useSnackbar()
  const [addComment, setAddComment] = useState(false)
  const [openComment, setOpenComment] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDeleteAttachment, setOpenDeleteAttachment] = useState(false)
  const [idAttachment, setIdAttachment] = useState({
    res: null,
    i: null,
  })
  const [openFeedback, setOpenFeedback] = useState({
    open: false,
    data: {},
  })
  const [anchorEl, setAnchorEl] = useState({
    data: null,
    open: null,
  })
  const [more, setMore] = useState(false)
  const [moreComment, setMoreComment] = useState(commentCount)
  const [comment, setComment] = useState({
    comment: '',
    attachment: [],
  })
  const [commentChild, setCommentChild] = useState({
    comment: '',
    attachment: [],
  })
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })
  const [limitActivityComment, setLimitActivityComment] = useState({
    offset: 0,
    limit: 2,
  })

  const isTaskActivity = type === 'task'

  const queryComment = isTaskActivity
    ? GET_LIST_COMMENT_TASK
    : GET_LIST_COMMENT_GOAL

  const queryAddComment = isTaskActivity
    ? ADD_COMMENT_ACTIVITIES_TASK
    : ADD_COMMENT_ACTIVITIES_GOALS

  const queryDeleteComment = isTaskActivity
    ? DELETE_COMMENT_ACTIVITY_TASK
    : DELETE_COMMENT_ACTIVITY_GOAL

  const queryUpdateComment = isTaskActivity
    ? UPDATE_COMMENT_ACTIVITIES_TASK
    : UPDATE_COMMENT_ACTIVITIES_GOALS

  const [mutationComment] = useMutation(queryAddComment)
  const [deleteComment] = useMutation(queryDeleteComment)
  const [updateMutationChildComment] = useMutation(queryUpdateComment)
  const { data, error, refetch, loading } = useQuery(queryComment, {
    variables: {
      activity: idActivity,
      offset: limitActivityComment.offset,
      limit: limitActivityComment.limit,
    },
    skip: !idActivity,
  })

  if (data === undefined && error === undefined) {
    return ''
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }

  const totalComment = data?.total?.aggregate?.count
  const dataComment = isTaskActivity
    ? data.performance_task_comments
    : data.performance_goal_comments

  const seeMore = () => {
    if (moreComment === 0) {
      setMoreComment(totalComment - 2)
      setLimitActivityComment({
        offset: 0,
        limit: 2,
      })
    } else {
      let countMore = 0
      if (moreComment < 10) {
        const currentMore = moreComment
        countMore = moreComment - currentMore
        setMoreComment(countMore)
        setLimitActivityComment({
          offset: 0,
          limit: totalComment,
        })
      } else {
        countMore = moreComment - 10
        setMoreComment(countMore)
        setLimitActivityComment({
          offset: 0,
          limit: limitActivityComment.limit + 10,
        })
      }
    }
  }

  const handleCloseSnackbar = () => {
    setStates({ ...states, open: false })
  }

  const handleOpenComment = () => {
    setAddComment(!addComment)
  }

  const handleComment = name => event => {
    setCommentChild({ ...commentChild, [name]: event.target.value })
  }

  const snackBar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={3000}
        open={states.open}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{states.message}</span>}
      />
    )
  }

  const handleClick = (event, res) => {
    setAnchorEl({ data: res, open: event.currentTarget })
  }

  const handleClose = () => {
    setAnchorEl({ ...anchorEl, open: null })
  }

  const handleChange = event => {
    setComment({ ...comment, comment: event.target.value })
  }

  const handleOpenEdit = () => {
    setComment({
      comment: anchorEl.data.comment,
      attachment: anchorEl.data.attachment,
    })
  }

  const handleKeyPress = name => e => {
    if (e.key === 'Enter') {
      if (commentChild.comment === '' && comment.comment === '') {
        enqueueSnackbar('Fill the comment first', {
          autoHideDuration: 2500,
          variant: 'error',
        })
      } else {
        if (name === 'add' && !disabledButton) {
          const data = {
            ...(isTaskActivity && { task: idActivity }),
            ...(!isTaskActivity && { activity: idActivity }),
            comment: commentChild.comment,
            attachment: commentChild.attachment,
          }
          mutationComment({ variables: { data } })
            .then(() => {
              setCommentChild({ comment: '', attachment: [] })
              setAddComment(false)
              setMoreComment(moreComment + 1)
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e))
            })
        } else if (!disabledButton) {
          const variable = {
            id: anchorEl.data.id,
            comment: comment.comment,
            attachment: comment.attachment,
          }
          updateMutationChildComment({ variables: variable })
            .then(() => {
              setComment({ comment: '', attachment: [] })
              setOpenComment(false)
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e))
            })
        }
      }
    }
  }

  const handleSent = feature => {
    if (commentChild.comment === '' && comment.comment === '') {
      enqueueSnackbar('Fill the comment first', {
        autoHideDuration: 2500,
        variant: 'error',
      })
    } else {
      if (!disabledButton) {
        if (feature === 'add') {
          const data = {
            ...(isTaskActivity && { task: idActivity }),
            ...(!isTaskActivity && { activity: idActivity }),
            comment: commentChild.comment,
            attachment: commentChild.attachment,
          }
          mutationComment({ variables: { data } })
            .then(() => {
              setCommentChild({ comment: '', attachment: [] })
              setAddComment(false)
              setMoreComment(moreComment + 1)
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e))
            })
        } else {
          const variable = {
            id: anchorEl.data.id,
            comment: comment.comment,
            attachment: comment.attachment,
          }
          updateMutationChildComment({ variables: variable })
            .then(() => {
              setComment({ comment: '', attachment: [] })
              setOpenComment(false)
              refetch()
            })
            .catch(e => {
              enqueueSnackbar(JSON.stringify(e))
            })
        }
      }
    }
  }

  const handleDelete = () => {
    const id = anchorEl.data.id
    deleteComment({ variables: { id } })
      .then(() => {
        setAnchorEl({ data: null, open: null })
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e))
      })
  }

  const handleChangeFile = feature => e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) +
      (feature === 'add'
        ? commentChild?.attachment?.length ?? 0
        : comment?.attachment?.length ?? 0)
    tempFile.forEach(val => {
      if (fileCount > 10) {
        setStates({
          open: true,
          message: 'The number of attachments must not exceed 10',
        })
      } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
        setStates({
          open: true,
          message: 'There are file types that are not allowed',
        })
      } else if (val.size >= 1024 * 1024 * 25) {
        setStates({
          open: true,
          message: 'Some of your attachment size is more than 25MB',
        })
      } else {
        const formData = new FormData()
        formData.append('file', val)
        const _initialUpload = {
          id: val.name + val.size,
          name: val.name,
          size: val.size,
          url: '',
          loaded: 0,
          isUploading: true,
        }
        setDisabledButton(true)
        if (feature === 'add') {
          setCommentChild(prevState => {
            return {
              ...prevState,
              attachment: [...(prevState.attachment || []), _initialUpload],
            }
          })
        } else {
          setComment(prevState => {
            return {
              ...prevState,
              attachment: [...(prevState.attachment || []), _initialUpload],
            }
          })
        }

        return axios
          .post(UPLOAD_URL, formData, {
            headers: {
              Authorization: 'Bearer ' + TOKEN,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true,
            onUploadProgress: progressEvent => {
              const item = {
                id: val.name + val.size,
                name: val.name,
                size: val.size,
                url: '',
                loaded: progressEvent.loaded,
                isUploading: true,
              }
              setDisabledButton(true)
              if (feature === 'add') {
                setCommentChild(prevState => {
                  const items = prevState.attachment
                  const foundIndex = items.findIndex(
                    x => x.id === item.id && x.isUploading
                  )
                  items[foundIndex] = item
                  return { ...prevState, attachment: items }
                })
              } else {
                setComment(prevState => {
                  const items = prevState.attachment
                  const foundIndex = items.findIndex(
                    x => x.id === item.id && x.isUploading
                  )
                  items[foundIndex] = item
                  return { ...prevState, attachment: items }
                })
              }
            },
          })
          .then(response => {
            const item = {
              id: val.name + val.size,
              name: val.name,
              size: val.size,
              url: response.data.url,
              loaded: val.size,
              isUploading: false,
            }
            setDisabledButton(false)
            if (feature === 'add') {
              setCommentChild(prevState => {
                const items = prevState.attachment
                const foundIndex = items.findIndex(
                  x => x.id === item.id && x.isUploading
                )
                items[foundIndex] = item
                return { ...prevState, attachment: items }
              })
            } else {
              setComment(prevState => {
                const items = prevState.attachment
                const foundIndex = items.findIndex(
                  x => x.id === item.id && x.isUploading
                )
                items[foundIndex] = item
                return { ...prevState, attachment: items }
              })
            }
          })
          .catch(error => {
            JSON.stringify(error)
          })
      }
    })
  }

  const handleDeleteAttachement = i => {
    const newState = [...comment.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setComment(prevState => ({
      ...prevState,
      attachment: newState,
    }))
  }

  const handleChangeNameFile = (e, i) => {
    const newState = [...comment.attachment]
    newState[i].name = e.target.value
    setComment(prevState => ({
      ...prevState,
      attachment: newState,
    }))
  }

  const handleCancelPostField = () => {
    setComment({ ...comment, comment: '' })
    setOpenComment(false)
  }

  const handleDeleteAttachButton = i => {
    const newState = [...commentChild.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setCommentChild(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...commentChild.attachment]
    newState[i].name = e.target.value
    setCommentChild(prevState => ({ ...prevState, attachment: newState }))
  }

  const handleDeleteHover = () => {
    const attachment = idAttachment?.res?.attachment
    const i = idAttachment?.i
    const newState = [...attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    const variable = {
      id: idAttachment?.res?.id,
      comment: idAttachment?.res?.comment,
      attachment: newState,
    }
    updateMutationChildComment({ variables: variable })
      .then(() => {
        refetch()
      })
      .catch(e => {
        enqueueSnackbar(JSON.stringify(e))
      })
  }

  const comment_id = `${idActivity}-${idx}`

  return (
    <div>
      {!updateComment && (
        <div
          style={{
            marginLeft: '5.3rem',
            marginBottom: '2rem',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon
            style={{ color: '#a9a8a8', cursor: 'pointer' }}
            onClick={handleOpenComment}
          >
            chat_bubble_outline
          </Icon>
          <TypographyStyleds
            size="12px"
            color="#a9a8a8"
            style={{ alignSelf: 'center', marginLeft: 5 }}
          >
            {totalComment > 0 ? totalComment : ''}
          </TypographyStyleds>
        </div>
      )}
      {totalComment !== 0 &&
        dataComment?.map((res, id) => {
          return (
            <CommentWrapper
              key={id}
              style={
                res?.attachment?.length > 5
                  ? { paddingLeft: '5.3rem', width: '80%' }
                  : { paddingLeft: '5.3rem' }
              }
            >
              <CommentWrapperAct>
                <CommentChildrenAvatar src={res.global_user?.avatar} />
                <CommentDetailWrapper>
                  <FlexBetween>
                    <TypographyStyleds size="14px" weight="600">
                      {res.global_user.name}
                    </TypographyStyleds>
                    {!res.deletedat && (
                      <Icon
                        onClick={e => handleClick(e, res)}
                        style={{ cursor: 'pointer' }}
                      >
                        more_horiz
                      </Icon>
                    )}
                  </FlexBetween>
                  <FlexBetween style={{ marginBottom: 15 }}>
                    <TypographyStyleds size="12px" color="#a9a8a8">
                      {
                        res.global_user?.people_work_placements[0]
                          ?.company_job_profile?.title
                      }
                    </TypographyStyleds>
                    <FieldInformationTooltip
                      placement="bottom"
                      title={`${Moment(res.date_created).format(
                        'LL'
                      )} • ${Moment(res.date_created).format('LT')}`}
                    >
                      <TypographyStyleds size="12px" color="#a9a8a8">
                        {timeStamp(res.date_created)}
                      </TypographyStyleds>
                    </FieldInformationTooltip>
                  </FlexBetween>
                  {!res.deletedat && (
                    <>
                      {openComment &&
                        anchorEl.data.id === res.id &&
                        condition !== 'archive' ? (
                        <div
                          style={{
                            marginBottom: 40,
                          }}
                        >
                          <PostingField
                            handleKeyPress={handleKeyPress()}
                            handleTextField={handleChange}
                            handleChangeFile={handleChangeFile()}
                            handleCancelPostField={handleCancelPostField}
                            handleDeleteAttachement={handleDeleteAttachement}
                            handleChangeNameFile={handleChangeNameFile}
                            handleSave={handleSent}
                            textValue={comment.comment}
                            placeholder="Add comment here"
                            attachmentValue={comment.attachment}
                            variant="save"
                            disabled={
                              comment?.attachment?.length > 10 || disabledButton
                            }
                          />
                        </div>
                      ) : (
                        <>
                          <Psm
                            style={{
                              marginTop: 5,
                              wordBreak: 'break-all',
                            }}
                          >
                            {more
                              ? res?.comment
                              : res && trimString(res.comment, 550, true)}
                            {res?.comment?.length > 550 && (
                              <Typography
                                style={{
                                  color: '#039be5',
                                  cursor: 'pointer',
                                }}
                                onClick={() => setMore(!more)}
                                variant="span"
                              >
                                {more ? ` Lihat lebih sedikit` : ` Lihat semua`}
                              </Typography>
                            )}
                          </Psm>
                          {res.last_modified && !res.deletedat && (
                            <PmdG style={{ marginTop: 10, fontSize: 14 }}>
                              (Diedit)
                            </PmdG>
                          )}

                          {res.attachment && (
                            <CarouselCard
                              style={{
                                margin: '1rem 0rem',
                              }}
                            >
                              {res.attachment.map((attachment, i) => {
                                const fileUrl =
                                  attachment.link || attachment.url
                                const getFileName = fileUrl
                                const getFileExtension = getFileName.split('.')
                                const extensionFile =
                                  getFileExtension[getFileExtension.length - 1]
                                return (
                                  <AttachmentItemHover
                                    key={`${i}-itm`}
                                    iconAction={
                                      <>
                                        <VisibilityOutlinedIcon
                                          onClick={() => window.open(fileUrl)}
                                          className="icon"
                                        />

                                        <FileDownloadIcon
                                          style={{
                                            ...(res.global_user.id !== USER_ID
                                              ? { marginLeft: 10 }
                                              : { marginLeft: 5 }),
                                          }}
                                          className="icon"
                                          onClick={() =>
                                            downloadFile(
                                              fileUrl,
                                              attachment.name,
                                              extensionFile
                                            )
                                          }
                                        />
                                        {res.global_user.id === USER_ID && (
                                          <DeleteOutlineIcon
                                            style={{
                                              marginLeft: 5,
                                            }}
                                            onClick={() => {
                                              setOpenDeleteAttachment(true)
                                              setIdAttachment({ res, i })
                                            }}
                                            className="icon"
                                          />
                                        )}
                                      </>
                                    }
                                    fileName={attachment.name}
                                    url={fileUrl}
                                  />
                                )
                              })}
                            </CarouselCard>
                          )}
                        </>
                      )}
                    </>
                  )}
                  {res.deletedat && (
                    <ChipInformation>
                      <BlockIcon style={{ fontSize: 12, marginRight: 5 }} />
                      <span style={{ fontSize: 12 }}>
                        Comment has been deleted
                      </span>
                    </ChipInformation>
                  )}
                </CommentDetailWrapper>
              </CommentWrapperAct>
            </CommentWrapper>
          )
        })}
      <Menu
        id={comment_id}
        anchorEl={anchorEl.open}
        keepMounted
        open={Boolean(anchorEl.open)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {idx === anchorEl?.data?.global_user?.id &&
          condition !== 'archive' &&
          !anchorEl?.data?.deletedat ? (
          <>
            <MenuItem
              onClick={() => {
                handleOpenEdit()
                setOpenComment(true)
                handleClose()
              }}
            >
              Ubah
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(true)
                handleClose()
              }}
            >
              Hapus
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                setOpenFeedback({
                  ...openFeedback,
                  open: true,
                  data: {
                    receiver: [
                      {
                        idx: 0,
                        type_from: 'FEEDBACK',
                        type_to: 'FEEDBACK',
                        label: anchorEl?.data?.global_user?.id,
                        value: anchorEl?.data?.global_user?.name,
                        request_id: null,
                        avatar: anchorEl?.data?.global_user?.avatar,
                        pos:
                          anchorEl?.data?.global_user
                            ?.people_work_placements?.[0]?.company_job_profile
                            ?.title,
                      },
                    ],
                    topic: anchorEl?.data?.comment,
                  },
                })
                handleClose()
              }}
            >
              Berikan Tanggapan
            </MenuItem>
          </>
        )}
      </Menu>
      {totalComment > 2 && (
        <PmdP
          style={{
            paddingLeft: '5.3rem',
            marginBottom: '2rem',
            cursor: 'pointer',
          }}
          onClick={seeMore}
        >
          {loading
            ? 'Loading...'
            : moreComment === 0
              ? 'See less'
              : `See more (${moreComment === totalComment ? totalComment - 2 : moreComment
              })`}
        </PmdP>
      )}
      {addComment && condition !== 'archive' && (
        <div>
          <CommentWrapper
            style={{
              margin: '0rem 0rem 2rem 5.3rem',
              border: '1px solid #a9a8a8',
              borderRadius: 5,
              padding: 10,
            }}
          >
            <CommentChildrenAvatar src={AVATAR} />
            <input
              style={{ display: 'none' }}
              type="file"
              id={`add-comment-${idActivity}`}
              name="attachmentComment"
              onChange={handleChangeFile('add')}
              multiple
              disabled={commentChild?.attachment?.length > 10 || disabledButton}
            />
            <label
              htmlFor={`add-comment-${idActivity}`}
              style={{ display: 'flex' }}
            >
              <Icon
                style={{
                  color: '#a9a8a8',
                  ...(commentChild?.attachment?.length < 10 && {
                    cursor: 'pointer',
                  }),
                  ...((commentChild?.attachment?.length > 10 ||
                    disabledButton) && {
                    cursor: 'not-allowed',
                  }),
                }}
              >
                attach_file
              </Icon>
              <div
                style={{
                  borderRight: '1px solid #a9a8a8',
                  width: '100%',
                  marginRight: '5px',
                }}
              />
            </label>
            <InputBase
              id="input-with-icon-grid"
              name="childComment"
              fullWidth
              variant="outlined"
              onKeyPress={handleKeyPress('add')}
              onChange={handleComment('comment')}
              value={commentChild.comment}
              placeholder="Tambahkan komentar disini"
            />
            <Icon
              name="childComment"
              style={{ color: '#a9a8a8', cursor: 'pointer' }}
              onClick={() => handleSent('add')}
            >
              send
            </Icon>
          </CommentWrapper>
          <div style={{ padding: '0px 1.8rem 0rem 5.3rem', marginBottom: 40 }}>
            {commentChild?.attachment?.map((res, i) => {
              const { name, size, url, isUploading, loaded } = res
              return (
                <ItemAttachment
                  res={res}
                  key={`${url}-${i}`}
                  id={`${url}-${i}`}
                  size={size}
                  onDelete={() => handleDeleteAttachButton(i)}
                  src={url}
                  onChange={e => handleChangeNameFileAttBtn(e, i)}
                  fileName={name}
                  isUploading={isUploading}
                  loaded={loaded}
                />
              )
            })}
          </div>
        </div>
      )}
      <DialogDelete
        open={openDeleteAttachment}
        handleClose={() => setOpenDeleteAttachment(false)}
        title="Delete this attachment?"
        message="Are you sure you want to delete this attachment permanently?"
        checkboxMessage="I understand that deleting this attachment is permanent and cannot be undone."
        isClose={false}
        mutation={() => {
          handleDeleteHover()
        }}
      />
      <DialogDelete
        open={open}
        handleClose={() => setOpen(false)}
        title="Delete this comment?"
        message="Are you sure you want to delete this comment permanently?"
        checkboxMessage="I understand that deleting this comment is permanent and cannot be undone."
        isClose={false}
        mutation={handleDelete}
      />
      <ModalMutationFeedBack
        open={openFeedback.open}
        dataActivity={openFeedback.data}
        handleClose={() => setOpenFeedback({ ...openFeedback, open: false })}
        type="Memberi Umpan Balik"
      />
      {snackBar()}
    </div>
  )
}
