import gql from 'graphql-tag'

export const UPDATE_GOALS = id => {
  return gql`
      mutation updateGoal($data: performance_goals_set_input) {
        update_performance_goals(where: {id: {_eq: ${id}}}, _set: $data) {
          returning {
            id
          }
        }
      }
    `
}
