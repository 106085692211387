import styled from 'styled-components'
import {
  Dialog,
  Card,
  Avatar,
  Divider,
  Typography,
  Paper,
} from '@material-ui/core'

export const ModalHeader = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalHeaderBlue = styled.div`
  padding: 0.8rem 2rem;
  background-color: #014a62;
`
export const ModalHeaderWhite = styled.div`
  padding: 1.6rem 2rem;
  background-color: #eff2f4;
`
export const ModalContent = styled.div`
  padding: 1.6rem 2rem;
`

export const StyledDialog = styled(Dialog)`
  font-family: ${props => props.theme.font};
`

export const DetailAchievementContainer = styled.div`
  display: flex;
  margin-top: 1.2rem;
  justify-conten: space-between;
  width: 100%;
`

export const DetailMultiraterContainer = styled.div`
  display: flex;
  padding-top: 1.2rem;
`

export const DetailNSPContainer = styled.div`
  display: flex;
  padding-top: 1.2rem;
`

export const CardAssigned = styled(Paper)`
  padding: 1.2rem;
`
export const CardAssignedReq = styled(Paper)`
  display: flex;
  padding: 1.2rem;
  flex-direction: column;
  align-content: center;
  align-items: center;
`
export const CardResultFeedback = styled(Card)`
  padding: 1.2rem 0;
`

export const CardAssignedContainer = styled.div`
  width: 50%;
`

export const CardAssignedContainerReq = styled.div`
  width: 25%;
`

export const CardTargetContainer = styled.div`
  width: 50%;
  margin-left: 0.8rem;
`

export const CardNSPContainer = styled.div`
  width: 75.5%;
  display: grid;
`

export const CardAchieveContainer = styled.div`
  width: 37.5%;
  display: grid;
  margin-left: 0.8rem;
`

export const AvatarAssigned = styled(Avatar)`
  height: 40px;
  width: 40px;
`
export const AvatarAssignedReq = styled(Avatar)`
  height: 90px;
  width: 90px;
  margin-top: 20px;
`

export const CardDetailsTop = styled(Card)`
  padding: 1.2rem;
  display: grid;
  margin-bottom: 1rem;
`

export const CardDetailsBottom = styled(Card)`
  padding: 1.2rem 2rem;
  display: grid;
  margin-top: 1rem;
`
export const ValueContainer = styled.div`
  text-align: center;
`

export const DividerValue = styled(Divider)`
  width: 3px;
  height: 100px;
  margin-top: 1rem;
`
export const CardDateBottom = styled(Paper)`
  display: grid;
  text-align: center;
  padding: 1.2rem 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const CardDateTop = styled(Paper)`
  display: grid;
  text-align: center;
  padding: 0rem 3rem;
  margin-bottom: 1rem;
`

export const DetailMainContainer = styled.div`
  width: 75%;
`
export const DetailMainContainerReq = styled.div`
  margin-left: 16px;
  width: 75%;
`

export const CardRadarContainer = styled(Card)`
  padding: 2rem;
  margin-bottom: 1rem;
`
export const CardDetailFBContainer = styled(Card)`
  padding: 2rem;
  margin-bottom: 1rem;
  min-height: 272px !important;
`
export const CardStrength = styled(Card)`
  padding: 2rem;
  width: 42%;
  min-height: 10rem;
`

export const CardArea = styled(Card)`
  padding: 2rem;
  width: 42%;
  min-height: 10rem;
`
export const Pm9 = styled.p`
  margin: 10px 0;
`

export const TitleRespondents = styled.h1`
  font-family: Muli;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #010101;
`
export const ValueRespondents = styled.h4`
  font-family: Muli;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #010101;
`

export const textTitle = styled(Typography)`
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`

export const FromButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
`
export const BoxDesc = styled.div`
  width: 536px;
  min-height: 36px;
  padding: 13px 17px;
  border-radius: 5px;
  background-color: #eff2f4;
  font-family: Muli;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
`
