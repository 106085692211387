import React from 'react'
import {Dialog, IconButton} from '@material-ui/core'
import './Modal.style.css'
import {ModalMoveWrapper, ModalMoveTitle, TitleModal} from './ModalStyle'
import ClearIcon from '@material-ui/icons/Clear'

export default function ModalWlb({title, onClose, open, ...child}) {
  return (
    <Dialog
      fullWidth
      scroll="body"
      open={open}
      onClose={onClose}
      aria-labelledby="modal-move-task"
      classes={{
        paper: 'modal-move-task-wlb',
      }}
    >
      <ModalMoveWrapper>
        <ModalMoveTitle>
          <TitleModal>{title}</TitleModal>
          <IconButton onClick={onClose}>
            <ClearIcon />
          </IconButton>
        </ModalMoveTitle>
        <div {...child} />
      </ModalMoveWrapper>
    </Dialog>
  )
}
