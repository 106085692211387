import React, {useEffect, useRef, useState, useContext} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faFileExcel,
  faFileWord,
  faFile,
  faFileVideo,
  faFilePdf,
  faDownload,
} from '@fortawesome/free-solid-svg-icons'
import videojs from 'video.js'
import {formatBytes, downloadFile} from '../../../../utils/helpers'
import {
  FlexCenter,
  GreyTypography,
  StyledBorderedExpansion,
  StyledLinear,
} from '../../PerformanceStyles'
import {Button, TextField} from '@material-ui/core'
import {
  UPDATE_PROGRESS_TASK,
  UPDATE_TASK_REVIEW,
} from '../../../../graphql/mutations'
import moment from 'moment'
import {useMutation} from '@apollo/react-hooks'
import {USER_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import {DataContext} from '../../../../contexts/ContextConfig'
import {progress_status} from './constant'
import {EmptyState as Empty} from '@smartasn/wlb-utils-components'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 600,
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '15px',
    fontWeight: 'normal',
    color: '#a9a8a8',
  },

  detailFile: {
    display: 'flex',
    padding: '1rem',
    alignItems: 'center',
  },
  iframeWrapper: {
    width: '100%',
    height: 423,
    borderRadius: '5px',
    backgroundColor: '#ffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  attachmentImg: {
    objectFit: 'cover',
    height: 315,
    width: '100%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  },
  iframeContent: {
    width: '100%',
    height: 315,
  },
  icon: {
    fontSize: '50px !important',
    marginRight: '1rem',
  },
  unknownContainer: {
    height: 315,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#eff2f4',
  },
  displayDate: {
    '& .MuiExpansionPanelSummary-content': {
      display: 'initial',
    },
  },
}))

function FileEx(props) {
  const classes = useStyles()
  const {value, file} = props
  const playerRef = useRef()

  useEffect(() => {
    if (
      file === 'webm' ||
      file === 'mpeg' ||
      file === 'mp4' ||
      file === 'avi' ||
      file === 'mov' ||
      file === 'flv'
    ) {
      const player = videojs(playerRef.current, {controls: true}, () => {
        player.src(value.link)
      })

      return () => {
        player.dispose()
      }
    }
  })
  const renderInformationFile = () => (
    <div>
      <Typography variant="body2">{value.name}</Typography>
      <GreyTypography variant="body2">
        {value.size && formatBytes(value.size)}
      </GreyTypography>
    </div>
  )

  const renderItem = () => {
    if (
      file === 'jpg' ||
      file === 'png' ||
      file === 'jpeg' ||
      file === 'JPG' ||
      file === 'PNG' ||
      file === 'JPEG'
    ) {
      return (
        <>
          <a href={`${value.link}`} target="_blank" rel="noopener noreferrer">
            <img
              src={value.link}
              alt="attachment"
              className={classes.attachmentImg}
            />
          </a>
          <div className={classes.detailFile}>
            <Icon className={classes.iconImage} style={{color: '#EF4d5E'}}>
              image
            </Icon>
            {renderInformationFile()}
          </div>
        </>
      )
    } else if (
      file === 'pdf' ||
      file === 'doc' ||
      file === 'docx' ||
      file === 'xlsx' ||
      file === 'xls' ||
      file === 'csv'
    ) {
      return (
        <>
          <iframe
            title={value.name}
            className={classes.iframeContent}
            frameBorder="0"
            src={`https://docs.google.com/viewer?url=${value.link}&embedded=true`}
          ></iframe>
          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={
                file === 'pdf'
                  ? faFilePdf
                  : file === 'doc' || file === 'docx'
                  ? faFileWord
                  : file === 'xlsx' || file === 'xls' || file === 'csv'
                  ? faFileExcel
                  : faFile
              }
              style={{
                color:
                  file === 'pdf'
                    ? '#E82B03'
                    : file === 'doc' || file === 'docx'
                    ? '#039be5'
                    : file === 'xlsx' || file === 'xls' || file === 'csv'
                    ? '#2C5A1A'
                    : '#eff2f4',
              }}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
    } else if (
      file === 'webm' ||
      file === 'mpeg' ||
      file === 'mp4' ||
      file === 'avi' ||
      file === 'mov' ||
      file === 'flv'
    ) {
      return (
        <>
          <div className={classes.iframeContent}>
            <div data-vjs-player>
              <video
                ref={playerRef}
                className="video-js"
                style={{height: '100%', width: '100%'}}
              />
            </div>
          </div>

          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={faFileVideo}
              style={{color: '#874CAF'}}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
    } else
      return (
        <>
          <div className={classes.unknownContainer}>
            <FontAwesomeIcon icon={faFile} className={classes.iconUnknown} />
          </div>
          <div className={classes.detailFile}>
            <FontAwesomeIcon
              icon={faFile}
              style={{
                color: '#d0d4d4',
              }}
              className={classes.icon}
            />
            {renderInformationFile()}
          </div>
        </>
      )
  }
  return (
    <div className={classes.iframeWrapper}>
      <Button
        // href={value.link}
        onClick={() => {
          DownloadFile(value, file)
        }}
        target="_blank"
        id={value.link}
        transparent={true}
      >
        <FontAwesomeIcon
          icon={faDownload}
          style={{
            color: '#a9a8a8',
            cursor: 'pointer',
            fontSize: '20px',
          }}
        />
      </Button>
      {renderItem()}
    </div>
  )
}
const DownloadFile = (value, extension) => {
  if (
    extension !== 'pdf' ||
    extension !== 'doc' ||
    extension !== 'docx' ||
    extension !== 'xlsx' ||
    extension !== 'xls' ||
    extension !== 'csv' ||
    extension !== 'rar'
  ) {
    downloadFile(value.link, value.name, extension)
  }
}
export default function Review({
  dataReview,
  type,
  dataStatus,
  idx,
  reporter,
  weightTask,
  refetchStatusReview,
  dataReporter,
  isUserCanUpdate = true,
}) {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [comment, setcomment] = useState('')
  const [selected, setSelected] = useState(null)
  let dataAttachment = []
  let file = []
  let nameFile = ''
  const dataStatusReview = dataStatus && dataStatus.performance_tasks[0]
  const checkUpdate = isUserCanUpdate && dataReporter === USER_ID
  const [mutationReview] = useMutation(UPDATE_TASK_REVIEW)
  const [updateProgress] = useMutation(UPDATE_PROGRESS_TASK)
  const {setDataContext} = useContext(DataContext)

  const handleChange = e => {
    setcomment(e.target.value)
  }
  const handleUpdateMutation = () => {
    mutationReview({variables: {id: idx, review: comment}})
      .then(() => {
        refetchStatusReview()
        setDataContext(true)
        setTimeout(() => {
          setDataContext(null)
        }, 1000)
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  const handleEditProgress = val => {
    setSelected(val)
  }

  const handleChangeProgress = e => {
    setSelected({
      ...selected,
      progress: e.target.value,
    })
  }

  const mutationUpdateProgress = () => {
    updateProgress({
      variables: selected,
    })
      .then(() => {
        enqueueSnackbar('Progress Updated', {
          variant: 'success',
          autoHideDuration: 2500,
        })
        handleEditProgress(null)
      })
      .catch(e =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: 'error',
        })
      )
  }

  return type === 'task' ? (
    <div className={classes.root}>
      <Typography variant="body1" style={{color: '#a9a8a8', marginTop: '1rem'}}>
        Status
      </Typography>
      <Typography variant="body1" color="primary">
        {dataStatusReview?.status === 'COMPLETED'
          ? 'Complete'
          : dataStatusReview?.status.toLowerCase()}
      </Typography>
      <Typography variant="body1" style={{color: '#a9a8a8', marginTop: '2rem'}}>
        Komentar
      </Typography>
      <Typography variant="body1" style={{marginBottom: '2rem'}}>
        {dataStatusReview?.review}
      </Typography>
      {dataReview?.length > 0 ? (
        dataReview.map((res, i) => {
          dataAttachment = []
          res.attachment.length > 0 && typeof res.attachment !== 'string'
            ? res.attachment.map(res => {
                nameFile = res.link || res.url
                file = nameFile.split('.')
                const data = {
                  link: res.link || res.url,
                  name: res.name,
                  file: file[file.length - 1],
                }
                return dataAttachment.push(data)
              })
            : (dataAttachment = [])

          return (
            <ExpansionPanel
              style={{
                marginBottom: 20,
                borderRadius: 5,
                border: 'solid 1px #a9a8a8',
                boxShadow: 'none',
              }}
              key={i}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.displayDate}
              >
                <Typography className={classes.heading2}>
                  {res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : 0}
                  % Kemajuan
                </Typography>
                <GreyTypography style={{fontSize: '0.8rem', marginTop: '5px'}}>
                  {moment(res.date_added).format('LLL')}
                </GreyTypography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                      <StyledLinear
                        variant="determinate"
                        color="secondary"
                        value={
                          res.progress_percentage > 100
                            ? 100
                            : res.progress_percentage
                        }
                        style={{width: '100%'}}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          style={{
                            marginRight: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {res.progress_number
                            ? res.progress_number.toFixed(2)
                            : 0}{' '}
                        </Typography>

                        <Typography
                          style={{
                            marginRight: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {weightTask} (
                          {res.progress_percentage
                            ? res.progress_percentage.toFixed(2)
                            : 0}
                          %)
                        </Typography>
                        <Typography
                          style={{
                            color:
                              res.progress_percentage < 100
                                ? '#014a62'
                                : '#4caf50',
                          }}
                        >
                          {res.progress_percentage < 100
                            ? progress_status.Below
                            : res.progress_percentage === 100
                            ? progress_status.Meet
                            : res.progress_percentage > 100
                            ? progress_status.Exceed
                            : '-'}
                        </Typography>
                      </div>
                    </div>
                    <span className={classes.title}>Hasil</span>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {dataAttachment.length > 0 ? (
                        dataAttachment.map((value, index) => {
                          return (
                            <Grid item xs={6} key={index}>
                              <FileEx value={value} file={value.file} />
                            </Grid>
                          )
                        })
                      ) : (
                        <EmptyState />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        })
      ) : (
        <EmptyState />
      )}
      {dataStatusReview?.status === 'DONE' &&
      ((reporter.ReporterUser && reporter.ReporterUser.id === USER_ID) ||
        reporter.created_by_user.id === USER_ID) ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
          }}
        >
          <Typography variant="body1" style={{color: '#a9a8a8'}}>
            Komentar
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows="10"
            onChange={handleChange}
          />
          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '1rem',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button
              variant="contained"
              style={{
                display: 'flex',
                width: '144px',
                height: '45px',
                borderRadius: '5px',
                backgroundColor: '#007fb2',
                color: '#ffff',
              }}
              onClick={handleUpdateMutation}
            >
              Selesai
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <div className={classes.root}>
      {dataReview?.length > 0 ? (
        dataReview.map((res, i) => {
          dataAttachment = []
          res.performance_task_activities.length > 0
            ? res.performance_task_activities.map(value => {
                value.attachment !== null &&
                typeof value.attachment !== 'string'
                  ? value.attachment.map(res => {
                      if (typeof res !== 'string') {
                        nameFile = res.link || res.url
                        if (nameFile) {
                          file = nameFile?.split('.')
                          const data = {
                            link: res.link || res.url,
                            name: res.name,
                            file: file[file?.length - 1],
                          }
                          return dataAttachment.push(data)
                        }
                      } else dataAttachment = []
                    })
                  : (dataAttachment = [])
              })
            : (dataAttachment = [])
          const resWeight =
            res.performance_goal &&
            res.performance_goal.kpi_list &&
            res.performance_goal.kpi_list.kpi_weight_unit &&
            res.performance_goal.kpi_list.kpi_weight_unit.name

          return (
            <StyledBorderedExpansion
              key={i}
              style={{marginBottom: 16}}
              elevation={0}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.displayDate}
              >
                <FlexCenter>
                  <Typography className={classes.heading}>
                    {res.name} - #{res.id}
                  </Typography>
                  {(res.status === 'ARCHIVED' ||
                    res.status === 'COMPLETED') && (
                    <Icon
                      color="secondary"
                      style={{marginLeft: 8, fontSize: '1rem'}}
                    >
                      check_circle_outline
                    </Icon>
                  )}
                </FlexCenter>
                <GreyTypography style={{fontSize: '0.8rem', marginTop: '5px'}}>
                  {moment(res.last_modified || res.date_added).format('LLL')}
                </GreyTypography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div style={{marginBottom: 30}}>
                      <StyledLinear
                        variant="determinate"
                        color="secondary"
                        value={
                          res.progress_percentage > 100
                            ? 100
                            : res.progress_percentage
                        }
                        style={{width: '100%'}}
                      />
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {selected && selected.id === res.id ? (
                          <TextField
                            variant="outlined"
                            value={selected.progress}
                            size="small"
                            style={{
                              marginRight: 10,
                              width: 150,
                            }}
                            onChange={handleChangeProgress}
                            type="number"
                          />
                        ) : (
                          <Typography
                            style={{
                              marginRight: 10,
                              fontWeight: 'bold',
                            }}
                          >
                            {res.progress ? res.progress.toFixed(2) : 0}{' '}
                          </Typography>
                        )}
                        <Typography
                          style={{
                            marginRight: 10,
                            fontWeight: 'bold',
                          }}
                        >
                          {resWeight} (
                          {res.progress_percentage
                            ? res.progress_percentage.toFixed(2)
                            : 0}
                          %)
                        </Typography>
                        <Typography
                          style={{
                            color:
                              res.progress_percentage < 100
                                ? '#014a62'
                                : '#4caf50',
                          }}
                        >
                          {res.progress_percentage < 100
                            ? progress_status.Below
                            : res.progress_percentage === 100
                            ? progress_status.Meet
                            : res.progress_percentage > 100
                            ? progress_status.Exceed
                            : '-'}
                        </Typography>
                        {res.status !== 'ARCHIVED' &&
                          checkUpdate &&
                          (selected && selected.id === res.id ? (
                            <>
                              <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                                style={{
                                  marginLeft: 14,
                                  marginRight: 10,
                                }}
                                disabled={!selected.progress}
                                onClick={mutationUpdateProgress}
                              >
                                Update
                              </Button>
                              <Button
                                size="small"
                                onClick={() => handleEditProgress(null)}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <Typography
                              color="secondary"
                              style={{
                                marginLeft: 14,
                                cursor: 'pointer',
                              }}
                              onClick={() =>
                                handleEditProgress({
                                  id: res.id,
                                  progress: res.progress,
                                })
                              }
                            >
                              Ubah
                            </Typography>
                          ))}
                      </div>
                    </div>
                    <span className={classes.title}>Hasil</span>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {dataAttachment.length > 0 ? (
                        dataAttachment.map((value, index) => {
                          return (
                            <Grid item xs={6} key={index}>
                              <FileEx value={value} file={value.file} />
                            </Grid>
                          )
                        })
                      ) : (
                        <EmptyState />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </StyledBorderedExpansion>
          )
        })
      ) : (
        <EmptyState />
      )}
    </div>
  )
}

const EmptyState = () => {
  return (
    <center>
      <Empty
        message1="Disini Kosong"
        message2="Oops! Sepertinya belum ada apa-apa di folder ini"
        width="313"
      />
    </center>
  )
}
