import gql from 'graphql-tag'

export const GET_GOALS_CATEGORY = gql`
  {
    performance_goal_categories {
      id
      name
    }
  }
`
