import React, {useState, Fragment} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import SearchIcon from '@material-ui/icons/Search'
import {COMPANY_ID, USER_ID} from '../../../utils/globals'
import Select from 'react-select'
import {
  Button,
  IconButton,
  Icon,
  TextField,
  InputAdornment,
  Paper,
  Switch,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_KPI_LIST_FILTER,
  GET_LIST_PERSPECTIVE_FILTER,
} from '../../../graphql/queries'
import {
  StyledDialog,
  ModalHeader,
  ModalContent,
} from '../detail-modal/ModalPerformanceStyles'
import {FormChildButton, FlexBetween, FormElement} from '../PerformanceStyles'
import ModalPersonal from './Modalpersonal'
import {TextXl, PmdGrey} from '../../../components/typography/TypographyStyles'
import NoDataListComponent from '../../shared-component/NoDataListComponent'
import moment from 'moment'
import {goalType} from '../goal-components/TabDialog/constant'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.28,
    color: '#055469',
    wordBreak: 'break-word',
  },
  expanded: {
    marginTop: 0,
  },
  panelDetails: {
    paddingTop: 0,
    display: 'flex',
    paddingLeft: '3rem',
    flexDirection: 'column',
  },
  buttonSpacer: {
    marginRight: 12,
  },
  expRoot: {
    border: 0,
    marginBottom: 12,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
    '&::before': {
      height: 0,
    },
  },
  rootRadio: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: 12,
  },
  wrapperKpiDetail: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  KpiVersion: {
    fontSize: 16,
    color: '#014a62',
    lineHeight: '1.5',
  },
  KpiDate: {
    color: '#a9a8a8',
    fontSize: 16,
    lineHeight: '1.5',
  },
  goalType: {
    color: '#039be5',
    fontSize: 16,
    lineHeight: '1.5',
  },
  KpiName: {
    color: '#000',
    fontSize: 16,
    lineHeight: '1.5',
  },
  padding15: {
    padding: '15px',
  },
  width65P: {
    width: '65%!important',
  },
}))

function ModalKpi(props) {
  const classes = useStyles()
  const [id, setId] = useState('')
  const [idPerspective, setIdPerspective] = useState('')
  const [perspectiveDropdown, setPerspectiveDropdown] = useState([])
  const [searchDropdown, setSearchDropdown] = useState('')
  const [value, setValue] = useState([])
  const [next, setNext] = useState(false)
  const [search, setSearch] = useState('')
  const [suggested, setSuggested] = useState(false)

  const handleChangeRadio = (e, data, id) => {
    setId(e.target.value)
    setValue(data)
    setIdPerspective(id)
  }

  const handleClickNext = () => {
    setNext(true)
  }

  const handleClickBack = () => {
    setNext(false)
  }

  const handleResetId = () => {
    setId('')
  }

  const handleClose = () => {
    props.onClose()
    handleResetId()
    setPerspectiveDropdown([])
    setSearchDropdown('')
    setSearch('')
    setSuggested(false)
    handleClickBack()
  }

  const {data, error} = useQuery(GET_KPI_LIST_FILTER, {
    variables: {
      company: COMPANY_ID,
      user: USER_ID,
      perspective: perspectiveDropdown?.value,
      is_suggest: suggested,
      search: `%${search}%`,
    },
    skip: !props.open,
  })

  const {
    data: dataPerspective,
    error: errorPerspective,
    loading: loadingPerspective,
  } = useQuery(GET_LIST_PERSPECTIVE_FILTER, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown}%`,
    },
    skip: !props.open,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  if (
    (data === undefined && error === undefined) ||
    (dataPerspective === undefined && errorPerspective === undefined)
  ) {
    return null
  } else if (error !== undefined || errorPerspective !== undefined) {
    return <div>{JSON.stringify(error || errorPerspective)}</div>
  }

  const _dataPerspective = dataPerspective?.kpi_perspectives?.map(options => ({
    value: options.id,
    label: options.name,
  }))

  return (
    <div>
      <StyledDialog
        open={props.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <ModalHeader>
          <FlexBetween>
            <TextXl>Membuat {GLOBAL_GLOSSARY.performance.Goal}</TextXl>
            <IconButton aria-label="close" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
        </ModalHeader>
        {next ? (
          <ModalPersonal
            dataKpi={value}
            onBack={handleClickBack}
            idPerspective={idPerspective}
            onClose={handleClose}
            type="add-goal"
            resetId={handleResetId}
          />
        ) : (
          <Fragment>
            <ModalContent style={{maxHeight: '60vh', overflow: 'auto'}}>
              <div style={{flex: '1'}}>
                <Typography
                  style={{color: '#a9a8a8', marginBottom: '1rem'}}
                  variant="h6"
                >
                  Kumpulan {GLOBAL_GLOSSARY.performance.Goal}
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  style={{marginBottom: 18}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  placeholder={`Cari ${GLOBAL_GLOSSARY.performance.Goal}`}
                />
                <FormElement
                  style={{paddingRight: 12, marginBottom: 25, width: '100%'}}
                >
                  <PmdGrey>Perspektif</PmdGrey>
                  <FlexBetween>
                    <Select
                      className={classes.width65P}
                      id="select-perspective"
                      options={_dataPerspective}
                      value={perspectiveDropdown}
                      placeholder="Pilih Perspektif"
                      isLoading={loadingPerspective}
                      onInputChange={dataSearchDropdown =>
                        setSearchDropdown(dataSearchDropdown)
                      }
                      onChange={event => {
                        setPerspectiveDropdown(event)
                      }}
                      styles={{
                        control: provided => ({
                          ...provided,
                          '&:hover': {borderColor: '#000'},
                          marginTop: '1rem',
                          minHeight: 40,
                        }),
                        placeholder: defaultStyles => {
                          return {
                            ...defaultStyles,
                            color: '#bfbfbf',
                          }
                        },
                      }}
                    />
                    <FormControlLabel
                      style={{width: '30%', alignSelf: 'end'}}
                      control={
                        <Switch
                          checked={suggested}
                          onChange={event => {
                            setSuggested(event.target.checked)
                            handleResetId()
                            setIdPerspective('')
                            setValue([])
                          }}
                          name="suggested"
                          color="primary"
                        />
                      }
                      label={`Lihat ${GLOBAL_GLOSSARY.performance.Goal_short} yang disarankan`}
                    />
                  </FlexBetween>
                </FormElement>
              </div>
              {data.kpi_lists.length === 0 ? (
                <NoDataListComponent
                  search={search || perspectiveDropdown?.value}
                />
              ) : (
                data?.kpi_lists?.map((res, key) => {
                  return (
                    <Paper key={key}>
                      <FormControlLabel
                        classes={{
                          root: classes.rootRadio,
                        }}
                        className={classes.padding15}
                        key={res.id}
                        control={
                          <Radio
                            style={{alignSelf: 'center'}}
                            key={res.id}
                            color="primary"
                            checked={id === res.id.toString()}
                            value={res.id.toString()}
                            onChange={e => {
                              handleChangeRadio(
                                e,
                                res,
                                res?.kpi_perspective?.id
                              )
                            }}
                          />
                        }
                        label={
                          <div className={classes.wrapperKpiDetail}>
                            <Typography className={classes.KpiVersion}>
                              {
                                res?.kpi_cascading_lists?.[0]?.cascading_main
                                  ?.name
                              }
                            </Typography>
                            <Typography className={classes.KpiName}>
                              {res.name}
                            </Typography>
                            <Typography className={classes.goalType}>
                              {goalType[res.type] ?? '-'}
                            </Typography>
                            <Typography className={classes.KpiDate}>
                              {`${moment(
                                res?.kpi_cascading_lists[0]?.cascading_main
                                  ?.date_start
                              ).format('MMMM DD, YYYY')} - ${moment(
                                res?.kpi_cascading_lists[0]?.cascading_main
                                  ?.date_end
                              ).format('MMMM DD, YYYY')}`}
                            </Typography>
                          </div>
                        }
                        labelPlacement="end"
                      />
                    </Paper>
                  )
                })
              )}
            </ModalContent>
            <FormChildButton>
              <Button
                size="large"
                className={classes.buttonSpacer}
                onClick={() => {
                  props.onClose()
                  handleResetId()
                  setPerspectiveDropdown([])
                  setSearchDropdown('')
                  setSearch('')
                  setSuggested(false)
                }}
              >
                Batal
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleClickNext}
                disabled={id === ''}
              >
                Selanjutnya
              </Button>
            </FormChildButton>
          </Fragment>
        )}
      </StyledDialog>
    </div>
  )
}

export default ModalKpi
