import styled from 'styled-components'
import {makeStyles} from '@material-ui/styles'
import MenuList from '@material-ui/core/MenuList'
import {
  Typography,
  OutlinedInput,
  Tab,
  FormControlLabel,
  Badge,
} from '@material-ui/core'

export const ProgressContainer = styled.div`
  height: 2rem;
  display: grid;
`

export const MenuDropdown = styled(MenuList)`
  padding: 0px !important;
`

export const LabelSearchDate = styled(Typography)`
  width: 100%;
  height: 15px;
  padding-bottom: 7px;
  padding-top: 13px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`

export const TabLabel = styled(Tab)`
  width: 270px;
  font-family: Muli;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  background-color: #ffffff;
  &:hover {
    background-color: #014a62;
    color: #ffffff;
  }
`
export const CheckBoxLabel = styled(FormControlLabel)`
  // width: 26px;
  height: 15px;
  padding-bottom: 10px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`
export const CheckBoxLabelImpres = styled(FormControlLabel)`
  // width: 26px;
  height: 37px;
  padding-bottom: 10px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`
export const LabelTabs = styled(Typography)`
  width: 64px;
  padding-right: 5px;
  height: 15px;
  font-family: Muli;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
`
export const badeTabs = styled(Badge)`
  background-color: #014a62;
`
export const FormOutLine = styled(OutlinedInput)`
  width: 100%;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #a9a8a8;
  background-color: #ffffff;
`

const useStyles = makeStyles(theme => ({
  dropdownArchive: {
    width: '162.1px',
    height: '44.2px !important',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  gridRoot: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
  },
  gridPaper: {
    height: 140,
    width: 100,
  },
  gridControl: {
    padding: theme.spacing(2),
  },
  searchArchive: {
    margin: theme.spacing(1),
    width: '217.3px',
    height: '44.2px',
    borderRadius: '3px',
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
  },
  searchPerspective: {
    width: '100%',
    height: '44.2px',
    borderRadius: '3px',
    border: 'solid 1px #e5e5e5',
    backgroundColor: '#ffffff',
  },
  searchDate: {
    width: '100%',
    borderRadius: '3px',
    // border: 'solid 1px #a9a8a8',
    backgroundColor: '#ffffff',
  },
  textSearch: {
    border: 'solid #ffffff',
    marginTop: 5,
  },
  iconSearch: {
    width: '19.9px',
    height: '19.9px',
    marginTop: '-8px',
    objectFit: 'contain',
  },
  archiveFilter: {
    margin: theme.spacing(1),
    width: '153.1px',
    height: '44.2px',
    borderRadius: '5px',
    border: 'solid 1px #a9a8a8',
    backgroundColor: '#ffffff',
  },
  iconFilter: {
    paddingLeft: '38.6px',
    paddingRight: '15px',
    width: '24px',
    height: '24px',
    objectFit: 'contain',
  },
  textFilter: {
    width: '32px',
    height: '18px',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    lettespacing: 'normal',
    textAlign: 'center',
    color: '#000000',
  },
  titleCard: {
    width: '100%',
    minHeight: '18px',
    fontFamily: 'Muli',
    fontSize: '14px',
    fontWeight: '600',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.29',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#055469',
  },
  tabPanel: {
    width: '270px',
    height: '240px',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#f7f8f9',
  },
  minHeightFilter: {
    minHeight: 60,
  },
  textFilterNew: {
    fontWeight: 600,
    fontSize: 14,
    color: '#000000',
    margin: '35px 0px',
    cursor: 'pointer',
  },
}))

export default useStyles
