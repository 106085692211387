import React, {useState} from 'react'

import {
  CardSummary,
  DateContainer,
  FlexBetween,
  SummaryScore,
  ListSummaryContainer,
  SeeMore,
  TitleCardSummaryContent,
  GoalsListBtn,
  SpanMyGoals,
} from '../PerformanceStyles'
import {Button} from '@material-ui/core'
import {
  Pmd,
  Psm,
  Pdate,
  TextPercent,
} from '../../../components/typography/TypographyStyles'
import ModalDetailAchievement from '../detail-modal/ModalDetailAchievement'
import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'
import {capitalize, trimString} from '../../../utils/helpers'
import moment from 'moment'
import {PRIORITY, TASK_STATUS} from '../goal-components/constant'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

export default function ListAchievement({data}) {
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState(6)
  const [selected, setSelected] = useState(0)

  const handleClickOpen = item => {
    setSelected(item)
    setOpen(true)
  }

  const handleSeeMore = () => {
    if (item === 6) {
      setItem(dataLength)
    } else {
      setItem(6)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const {data, error} = useQuery(GET_LIST_TASK(condition))
  const dataLength = data && data.performance_tasks.length

  return (
    <>
      <ListSummaryContainer>
        {data.performance_tasks.slice(0, item).map(res => {
          const typeGoal =
            res?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
              ?.aggregate?.count > 0
          return (
            <CardSummary key={res.id} onClick={() => handleClickOpen(res.id)}>
              <GoalsListBtn
                style={(() => {
                  switch (res.priority) {
                    case 'HIGH':
                      return {
                        backgroundColor: '#ef4d5e',
                      }
                    case 'MEDIUM':
                      return {
                        backgroundColor: '#ffa000',
                      }
                    case 'LOW':
                      return {
                        backgroundColor: '#039be5',
                      }
                    default:
                      return '#FFFFFF'
                  }
                })()}
              >
                <SpanMyGoals>
                  {PRIORITY[res.priority] || capitalize(res.priority)}
                </SpanMyGoals>
              </GoalsListBtn>
              <GoalsListBtn
                onClick={() => handleClickOpen(res.id)}
                style={{
                  marginLeft: '5px',
                  width: '85px',
                  backgroundColor: typeGoal ? '#a35a31' : '#874caf',
                }}
              >
                <SpanMyGoals style={{fontSize: '10px'}}>
                  {typeGoal
                    ? GLOBAL_GLOSSARY.performance['Company Goal']
                    : GLOBAL_GLOSSARY.performance['Personal Goal']}
                </SpanMyGoals>
              </GoalsListBtn>
              <TitleCardSummaryContent style={{color: '#055469'}}>
                {res && trimString(res.name, 55)}
              </TitleCardSummaryContent>
              <Pmd
                style={{
                  color: SwitchValue(res.status, 'colorStatusTask'),
                  marginTop: '20px',
                  textTransform: 'capitalize',
                }}
              >
                {TASK_STATUS[res.status] ||
                  (res.status && res.status.toLowerCase())}
              </Pmd>
              <FlexBetween style={{paddingTop: '2rem'}}>
                <DateContainer>
                  <Pdate>{`Batas Waktu ${moment(res.enddate).format(
                    'MMM DD, YYYY'
                  )}`}</Pdate>
                  <Pdate>{`Terakhir diperbarui ${moment(
                    res.last_modified
                  ).format('MMM DD, YYYY')}`}</Pdate>
                </DateContainer>
                <SummaryScore
                  style={{
                    color: SwitchValue(
                      res && res.progress_percentage,
                      'progress_percentage'
                    ),
                  }}
                >
                  <TextPercent>
                    {res && res.progress_percentage
                      ? res.progress_percentage.toFixed(2)
                      : 0}
                    %
                  </TextPercent>
                  <Psm style={{textAlign: 'center'}}>
                    {SwitchValue(
                      res && res.progress_percentage,
                      'progress_status'
                    )}
                  </Psm>
                </SummaryScore>
              </FlexBetween>
            </CardSummary>
          )
        })}
      </ListSummaryContainer>
      {dataLength > 6 ? (
        <SeeMore>
          <Button
            color="secondary"
            onClick={handleSeeMore}
            style={{textTransform: 'initial'}}
          >
            {item === 6
              ? `Lihat lebih banyak Pencapaian ${GLOBAL_GLOSSARY.performance.Task}`
              : 'Lihat lebih sedikit'}
          </Button>
        </SeeMore>
      ) : (
        ''
      )}
      <ModalDetailAchievement open={open} onClose={handleClose} id={selected} />
    </>
  )
}
