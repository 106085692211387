import React, {useState} from 'react'

import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_MULTIRATER_SUMMARY} from '../../../graphql/queries/index'

import {
  TitleCardSummaryContent,
  ListSummaryContainer,
  CardSummary,
  DateContainer,
  FlexBetween,
  SeeMore,
  Rate,
} from '../PerformanceStyles'
import {
  Pmd,
  Psm,
  Pdate,
  TextPercent,
} from '../../../components/typography/TypographyStyles'
import {Button, CircularProgress} from '@material-ui/core'

import ModalDetailMultirater from '../detail-modal/ModalDetailMultirater'
import {USER_ID} from '../../../utils/globals'
import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'
import {getDate, trimString} from '../../../utils/helpers'
import {TASK_STATUS} from '../goal-components/constant'

function ListMultirater() {
  const [openMultirater, setOpenMultirater] = useState(false)
  const [item, setItem] = useState(0)
  const [multiraterId, setMultiraterId] = useState(null)

  const multiraterHandleClickOpen = id => {
    setOpenMultirater(true)
    setMultiraterId(id)
  }

  const multiraterhandleClickClose = () => {
    setOpenMultirater(false)
  }

  const handleSeeMore = () => {
    if (item < dataLength - 6) {
      setItem(item + 6)
    } else {
      setItem(0)
    }
  }

  const {data, error} = useQuery(GET_LIST_MULTIRATER_SUMMARY, {
    variables: {
      id: USER_ID,
    },
  })

  const dataLength = data && data.multirater_surveys.length

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <ListSummaryContainer
      style={(() => {
        if (dataLength < 3) {
          return {justifyContent: 'flex-start'}
        }
      })()}
    >
      {data &&
        data.multirater_surveys.slice(0, 6 + item).map((res, idx) => {
          const avg =
            res && res.multirater_responses_aggregate.aggregate.avg.answer
          const result = Math.round((avg / 5) * 100)
          const id = res && res.id
          return (
            <CardSummary
              key={idx}
              style={(() => {
                if (dataLength < 3) {
                  return {marginRight: '27px'}
                }
              })()}
              onClick={() => multiraterHandleClickOpen(id)}
            >
              {/* <Button
              variant="contained"
              color="primary"
              style={{backgroundColor: '#3b86ff', marginBottom: '1rem'}}
              size="small"
            >
              10%
            </Button> */}

              <TitleCardSummaryContent style={{color: '#055469'}}>
                {res && trimString(res.title, 55)}
              </TitleCardSummaryContent>
              <Pmd
                style={(() => {
                  switch (res.status) {
                    case 'DONE':
                      return {
                        color: '#4caf50',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                    case 'PUBLISH':
                      return {
                        color: '#039be5',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                    default:
                      return {
                        color: '#ffffff',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                  }
                })()}
              >
                {/* {res.status} */}
                {(() => {
                  if (res.status === 'PUBLISH') {
                    return TASK_STATUS.ONGOING
                  } else {
                    return res.status
                  }
                })()}
              </Pmd>
              <FlexBetween style={{paddingTop: '2rem'}}>
                <DateContainer>
                  <Pdate>{`Batas Waktu ${getDate(res.startdate)}`}</Pdate>
                  <Pdate>{`Selesai pada ${getDate(res.enddate)}`}</Pdate>
                </DateContainer>
                <Rate style={{color: SwitchValue(result, 'colorResult')}}>
                  <TextPercent
                    style={{color: SwitchValue(result, 'colorResult')}}
                  >{`${result}%`}</TextPercent>
                  <Psm style={{color: SwitchValue(result, 'colorResult')}}>
                    {SwitchValue(result)}
                  </Psm>
                </Rate>
              </FlexBetween>
            </CardSummary>
          )
        })}
      {dataLength > 6 ? (
        <SeeMore>
          <Button
            color="secondary"
            onClick={handleSeeMore}
            style={{textTransform: 'initial'}}
          >
            {item < dataLength - 6
              ? 'Lihat lebih banyak Multirater'
              : 'Sembunyikan'}
          </Button>
        </SeeMore>
      ) : (
        ''
      )}
      <ModalDetailMultirater
        open={openMultirater}
        onClose={multiraterhandleClickClose}
        id={multiraterId}
      />
    </ListSummaryContainer>
  )
}

export default ListMultirater
