import React, {useState} from 'react'

import {useQuery} from '@apollo/react-hooks'
import {GET_LIST_NPS} from '../../../graphql/queries/index'

import ModalDetailNPS from '../detail-modal/ModalDetailNPS'

import {
  TitleCardSummaryContent,
  ListSummaryContainer,
  CardSummary,
  DateContainer,
  FlexBetween,
  SeeMore,
  Rate,
} from '../PerformanceStyles'
import {Button, CircularProgress} from '@material-ui/core'
import {
  Pmd,
  Pdate,
  Psm,
  TextPercent,
} from '../../../components/typography/TypographyStyles'

import {USER_ID} from '../../../utils/globals'

import {SwitchValue} from '../../../components/color-switch/ColorSwitcher'

import {getDate, trimString} from '../../../utils/helpers'
import {TASK_STATUS} from '../goal-components/constant'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'

function ListNPS() {
  const [openNPS, setOpenNPS] = useState(false)
  const [limit, setLimit] = useState(6)
  const [id, setId] = useState(null)
  const [resultNPS, setResultNPS] = useState('')

  const handleSeeMore = () => {
    setLimit(prev => (prev === 6 ? undefined : 6))
  }

  const NPShandleClickOpen = (result, data_id) => {
    setOpenNPS(true)
    setResultNPS(result)
    setId(data_id)
  }

  const NPShandleClickClose = () => {
    setOpenNPS(false)
  }

  const {data, error} = useQuery(GET_LIST_NPS, {
    variables: {
      user: USER_ID,
      limit,
    },
  })

  const dataLength = data?.total.aggregate.count

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <ListSummaryContainer
      style={dataLength < 3 ? {justifyContent: 'flex-start'} : undefined}
    >
      <ModalDetailNPS
        id_data={id}
        open={openNPS}
        result={resultNPS}
        onClose={NPShandleClickClose}
        dataUser={data?.user?.[0]}
      />
      {data &&
        data.multirater_surveys?.map((res, key) => {
          let dectators = 0
          let passives = 0
          let promotors = 0

          res &&
            res.multirater_survey_groups.forEach(res => {
              let dect = 0
              let passive = 0
              let prom = 0
              res.multirater_survey_questions.length > 0 &&
                res.multirater_survey_questions.forEach(quest => {
                  dect += quest.dectators.aggregate.count
                  passive += quest.passives.aggregate.count
                  prom += quest.promotors.aggregate.count
                })
              dectators += dect
              passives += passive
              promotors += prom
            })

          const sumQuestion = promotors + passives + dectators
          const result = Math.round(
            (promotors / sumQuestion - dectators / sumQuestion) * 100
          )

          return (
            <CardSummary
              style={dataLength < 3 ? {marginRight: 27} : undefined}
              onClick={() => NPShandleClickOpen(result, res.id)}
              key={key}
            >
              <TitleCardSummaryContent style={{color: '#055469'}}>
                {res && trimString(res.title, 55)}
              </TitleCardSummaryContent>
              <Pmd
                style={(() => {
                  switch (res.status) {
                    case 'DONE':
                      return {
                        color: '#4caf50',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                    case 'PUBLISH':
                      return {
                        color: '#039be5',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                    default:
                      return {
                        color: '#ffffff',
                        marginTop: 8,
                        textTransform: 'capitalize',
                      }
                  }
                })()}
              >
                {res.status === 'PUBLISH' ? TASK_STATUS.ONGOING : res.status}
              </Pmd>
              <FlexBetween style={{paddingTop: '2rem'}}>
                <DateContainer>
                  <Pdate>{`Due Date ${getDate(res.startdate)}`}</Pdate>
                  <Pdate>{`Completed at ${getDate(res.enddate)}`}</Pdate>
                </DateContainer>
                <Rate
                  style={(() => {
                    switch (res.status) {
                      case 'DONE':
                        return {
                          color: '#4caf50',
                          marginTop: 8,
                        }
                      case 'PUBLISH':
                        return {
                          color: '#039be5',
                          marginTop: 8,
                        }
                      case 'Not Completed':
                        return {
                          color: '#ef4d5e',
                          marginTop: 8,
                        }
                      default:
                        return {
                          color: '#ffffff',
                          marginTop: 8,
                        }
                    }
                  })()}
                >
                  <TextPercent
                    style={{color: SwitchValue(result, 'colorResult')}}
                  >
                    {(() => {
                      if (isNaN(result)) {
                        return '0%'
                      } else {
                        return `${result}%`
                      }
                    })()}
                  </TextPercent>
                  <Psm style={{color: SwitchValue(result, 'colorResult')}}>
                    {SwitchValue(result)}
                  </Psm>
                </Rate>
              </FlexBetween>
            </CardSummary>
          )
        })}
      {dataLength > 6 ? (
        <SeeMore>
          <Button
            color="secondary"
            onClick={handleSeeMore}
            style={{textTransform: 'initial'}}
          >
            {limit === 6
              ? `Lihat lebih banyak ${GLOBAL_GLOSSARY.performance.NPS}`
              : 'Lihat lebih sedikit'}
          </Button>
        </SeeMore>
      ) : (
        ''
      )}
    </ListSummaryContainer>
  )
}

export default ListNPS
