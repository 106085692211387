import React, {Fragment, useState} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {GET_DETAIL_FEEDBACK} from '../../../graphql/queries/index'

import {Icon, IconButton, Avatar} from '@material-ui/core'
import {ModalHeader, StyledDialog, ModalContent} from './ModalPerformanceStyles'
import {
  FlexBetween,
  FlexRow,
  Center,
  IconFeedback,
  CardFeedbackTabs,
} from '../PerformanceStyles'
import {Pmd, Psm, TextXl} from '../../../components/typography/TypographyStyles'

import ModalResultDetailFeedback from './ModalResultDetailFeedback'

import {USER_ID} from '../../../utils/globals'

import {getDate} from '../../../utils/helpers'

function ModalDetailFeedback(props) {
  const {open, onClose, user} = props
  const [openFeedback, setOpenFeedback] = useState(false)
  const [userPars, setUserPars] = useState('')
  const [idUser, setIdUser] = useState('')

  const FeedbackhandleClickOpen = (user, id) => {
    setOpenFeedback(true)
    setUserPars(user)
    setIdUser(id)
  }

  const FeedbackhandleClickClose = () => {
    setOpenFeedback(false)
  }

  const {data, error} = useQuery(GET_DETAIL_FEEDBACK, {
    variables: {
      from: user && user.id,
      to: USER_ID,
    },
  })

  if (data === undefined && error === undefined) {
    return <div>loading ...</div>
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  // console.log(data)
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      // open={this.state.openModal}
      open={open}
      fullWidth
      maxWidth={window.innerWidth < 1920 ? 'md' : 'lg'}
    >
      <Fragment>
        <ModalHeader>
          <FlexBetween>
            <TextXl>{user && user.name}</TextXl>
            <IconButton aria-label="close" onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
          <FlexRow>
            <Center style={{color: '#ef4d5e'}}>
              <IconFeedback>sentiment_very_dissatisfied</IconFeedback>
              <Psm>{data.disapointed.aggregate.count}</Psm>
            </Center>
            <Center style={{color: '#ffa000'}}>
              <IconFeedback>sentiment_neutral</IconFeedback>
              <Psm>{data.ordinary.aggregate.count}</Psm>
            </Center>
            <Center style={{color: '#4caf50'}}>
              <IconFeedback>sentiment_satisfied_alt</IconFeedback>
              <Psm>{data.satisfied.aggregate.count}</Psm>
            </Center>
          </FlexRow>
        </ModalHeader>
        <ModalContent>
          {data.ongoing_feedback_lists &&
            data.ongoing_feedback_lists.map((res, key) => {
              // console.log(res)
              const from = res.global_user_from
              return (
                <CardFeedbackTabs
                  key={key}
                  onClick={() => FeedbackhandleClickOpen(data, key)}
                >
                  <FlexBetween>
                    <FlexBetween style={{width: '30%'}}>
                      <Avatar alt={from.name} src={from.avatar} />
                      <Pmd style={{color: '#055469'}}>
                        {from.name} <br />
                        {res.date_added ? getDate(res.date_added) : ''}
                      </Pmd>
                    </FlexBetween>
                    {/* <Pmd style={{color: '#055469'}}>20/01/2020</Pmd> */}
                    <FlexBetween style={{width: '5%'}}>
                      <Center
                        style={(() => {
                          switch (res.summary) {
                            case 'DISAPPOINTED':
                              return {color: '#ef4d5e'}
                            case 'ORDINARY':
                              return {color: '#ffa000'}
                            case 'SATISFIED':
                              return {color: '#4caf50'}
                            default:
                              return {color: '#ffffff'}
                          }
                        })()}
                      >
                        <IconFeedback>
                          {(() => {
                            switch (res.summary) {
                              case 'DISAPPOINTED':
                                return 'sentiment_very_dissatisfied'
                              case 'ORDINARY':
                                return 'sentiment_neutral'
                              case 'SATISFIED':
                                return 'sentiment_satisfied_alt'
                              default:
                                return ''
                            }
                          })()}
                        </IconFeedback>
                      </Center>
                    </FlexBetween>
                  </FlexBetween>
                </CardFeedbackTabs>
              )
            })}
        </ModalContent>
      </Fragment>

      <ModalResultDetailFeedback
        open={openFeedback}
        user={userPars}
        id={idUser}
        onClose={FeedbackhandleClickClose}
      />
    </StyledDialog>
  )
}

export default ModalDetailFeedback
