import gql from 'graphql-tag'
export const GET_MONTHLY_ACHIEVEMENT = idx => {
  return gql`
    {
      getDetailAchievement(id: ${idx}) {
        month
        progress
        target
        target_stretch
      }
    }
  `
}
