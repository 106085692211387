import {TASK_STATUS} from '../constant'

export const FILE_TYPE_UPLOAD = [
  'doc',
  'docx',
  'xls',
  'pdf',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'pjp',
  'pjpeg',
  'jfif',
  'tif',
]

export const acceptImage =
  'image/jpg, image/jpeg, image/png, image/gif, image/tiff, image/pjp, image/pjpeg, image/jfif, image/tif'

export const acceptFile = '.doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx'

export const goalType = {
  1: 'Kualitatif',
  2: 'Kuantitatif',
}

export const visibility = {
  PUBLIC: 'Publik',
  PRIVATE: 'Pribadi',
}

export const visibilitys = [
  {
    value: 'PUBLIC',
    label: visibility?.PUBLIC,
  },
  {
    value: 'PRIVATE',
    label: visibility?.PRIVATE,
  },
]

export const goalTypes = [
  {
    value: '1',
    label: 'Kualitatif',
  },
  {
    value: '2',
    label: 'Kuantitatif',
  },
]

export const priority = {
  HIGH: 'Tinggi',
  MEDIUM: 'Sedang',
  LOW: 'Rendah',
}

export const priorities = [
  {value: 'HIGH', label: priority.HIGH},
  {value: 'MEDIUM', label: priority.MEDIUM},
  {value: 'LOW', label: priority.LOW},
]

export const status_update_task = {
  TODO: TASK_STATUS.TODO,
  INPROGRESS: TASK_STATUS.INPROGRESS,
  DONE: TASK_STATUS.DONE,
}

export const progress_status = {
  Below: 'Dibawah',
  Meet: 'Memenuhi',
  Exceed: 'Melebihi',

  Outstanding: 'Luar biasa',
  Almost: 'Hampir',
  Poor: 'Buruk',
}
