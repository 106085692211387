import gql from 'graphql-tag'

export const GET_DETAIL_ACHIEVEMENT = id => {
  return gql`
    query getDetail {
      performance_tasks(where: {id: {_eq: ${id}}}) {
        id
        name
        target
        target_stretch
        target_unit
        startdate
        enddate
        progress
        progress_percentage
        weight
        date_added
        priority
        method_type
        last_modified 
        kpi_weight_unit {
          id
          name
        }
        performance_goal{
          id
          name
          startdate
          enddate
          progress
          progress_percentage
          priority
          kpi_list{
            id
            type
            kpi_weight_unit{
              id
              name
            }
            kpi_scoring_nature{
              id
              name
            }
            kpi_cascading_lists_aggregate{
              aggregate{
                count
              }
            }
          }
          performance_tasks_aggregate {
            aggregate {
              sum {
                progress
              }
            }
          }
        }
        created_by_user {
          id
          name
          avatar
          people_work_placements(where:{status:{_eq:"ACTIVE"}}){
            id
            regno
            company_job_profile{
              id
              title
            }
            company_profile {
              id
              brand_name
            }
          }
        }
        ReporterUser{
          id
          name
          avatar
          people_work_placements(where:{status:{_eq:"ACTIVE"}}){
            id
            regno
            company_job_profile{
              id
              title
            }
            company_profile {
              id
              brand_name
            }
          }
        }
        performance_task_assignments {
          global_user {
            id
            name
            avatar
            people_work_placements {
              id
              company_employee_position {
                id
                name
              }
              company_profile {
                id
                brand_name
              }
            }
          }
        }
        complete_at:performance_task_activities(where:{comment:{_ilike:"Task is reviewed and complete%"}},order_by:[{id:desc}],limit:1){
          id
          date_added
        }
      }
    }
  `
}
