import React, {useState, useEffect, useContext} from 'react'
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Icon,
  Avatar,
  makeStyles,
  Button,
  Popover,
  List,
  IconButton,
} from '@material-ui/core'
import {
  GoalsListBtn,
  GoalProgressIndicator,
  TitleWrapper,
  StyledMenuItem,
} from '../../../PerformanceStyles'
import {
  IconArrowBlue,
  IconArrowDisabled,
} from '../../../../../components/typography/TypographyStyles'
import {Link, withRouter} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

// graphql
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {
  GET_LIST_CONTAINER_GOAL,
  GET_DETAIL_CONTAINER_GOAL,
  GET_LIST_ALL_MY_GOAL,
  GET_LIST_ALL_DEPARTMENT_GOAL,
} from '../../../../../graphql/queries/index'
import ModalTab from '../../TabDialog/ModalTab'

import Carousel from 'nuka-carousel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {EmptyState} from '@smartasn/wlb-utils-components'

// global js
import {COMPANY_ID, USER_ID} from '../../../../../utils/globals'
import ModalEdit from '../../../Modal/ModalEdit'
import ModalDelete from '../../../Modal/ModalDelete'
import {snackBar} from '../../../../../components/snakbar/SnackbarComponent'
import {DELETE_GOAL} from '../../../../../graphql/mutations'
import {DataContext} from '../../../../../contexts/ContextConfig'
import {
  PRIORITY,
  PRIORITY_STYLE,
  QUICK_FILTER_PERSONAL,
  TASK_STATUS,
} from '../../constant'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'

const customStyle = makeStyles(() => ({
  goalContainer: {
    padding: '10px',
    backgroundColor: '#f7f8f9',
    borderRadius: 5,
    height: 285,
  },
  cardItem: {
    padding: 12,
    fontSize: 13,
    cursor: 'pointer',
    margin: '0 .5rem',
  },
  textSkyBlue: {
    color: '#039be5',
  },
  textDate: {
    color: '#a9a8a8',
    marginBottom: 0,
  },
  card: {
    maxWidth: 275,
    margin: '14px',
    cursor: 'pointer',
    borderRadius: 5,
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: 4,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  pos: {
    width: '100%',
    height: '13px',
    fontFamily: 'Muli',
    fontSize: '12px',
    lineHeight: '13px',
    textAlign: 'left',
    color: '#039be5',
    marginBottom: 15,
    marginTop: 12,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    verticalAlign: 'text-top',
  },
  ActionMenu: {
    textAlign: 'center',
    color: '#039be5',
  },
  cardContent: {padding: '16px 16px 0px 16px'},
  btnGreen: {backgroundColor: '#4caf50', textTransform: 'none'},
  btnSkyBlue: {backgroundColor: '#039be5', textTransform: 'none'},
  avatarWrap: {
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
  },
  totalText: {fontSize: 12, color: '#a9a8a8', margin: '0 0 12px'},
  wrapTitle: {display: 'flex', flexDirection: 'column'},
  buttonActionMore: {marginTop: -12, color: '#000'},
  titleSection: {
    margin: '0px 16px 0 0',
    fontSize: 16,
    fontWeight: '600',
    color: '#014a62',
  },
  seeAll: {
    margin: 0,
    fontSize: 16,
    fontWeight: '600',
    color: '#039be5',
    padding: 0,
    textTransform: 'initial',
  },
  emptyStateWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  avatar: {
    fontSize: 12,
    width: 30,
    height: 30,
  },
  rootList: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  rootListItem: {
    padding: '3.5px 24px',
  },
  cardInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 4,
  },
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 7,
  },
  pfGroup: {
    fontSize: 12,
    fontWeight: '600',
    width: '80%',
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
  },
  box: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 4,
  },
}))

function ListGoal(props) {
  const {type} = props.match.params
  const classes = customStyle()
  const client = useApolloClient()
  const {dataContext, setDataContext} = useContext(DataContext)
  const [open, setOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(0)
  const [openEdit, setOpenEdit] = useState(false)
  const [dataEdit, setDataEdit] = useState([])
  const [dataModal, setDataModal] = useState([])
  const [more, setMore] = useState(5)
  const [openDelete, setOpenDelete] = useState(false)
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })
  const {filterValue, search} = props

  const query =
    filterValue === QUICK_FILTER_PERSONAL
      ? GET_LIST_ALL_MY_GOAL
      : GET_LIST_ALL_DEPARTMENT_GOAL

  const {
    data: dataIdGoal,
    error: errorIdGoal,
    refetch: refetchIdGoal,
    loading: loadingIdGoal,
  } = useQuery(query, {
    variables: {
      company: COMPANY_ID,
      user: USER_ID,
      search: `%${search}%`,
      limit: 5,
      offset: 0,
      order: 'desc',
      isCompany: true,
      isPersonal: true,
    },
  })

  useEffect(() => {
    if (dataContext) {
      refetchIdGoal()
    }
  }, [dataContext])

  const renderListData = (idGoal, loadingIdGoal) => {
    return idGoal?.performance_goals?.map((res, key) => (
      <ContainerListGoal
        key={key}
        idGoal={res.id}
        loadingIdGoal={loadingIdGoal}
      />
    ))
  }

  const ContainerListGoal = ({idGoal, key, loadingIdGoal}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {data, error, refetch} = useQuery(GET_LIST_CONTAINER_GOAL, {
      variables: {id: idGoal},
      errorPolicy: 'ignore',
    })

    useEffect(() => {
      if (dataContext) {
        refetch()
      }
    }, [dataContext])

    if (loadingIdGoal || (data === undefined && error === undefined)) {
      return (
        <Card elevation={0} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Skeleton width="100%" height={90} />
            <Skeleton circle width={50} height={50} />
            <Skeleton width="100%" height={60} />
          </CardContent>
        </Card>
      )
    }
    if (error) {
      console.error(error)
    }
    const res = data && data.performance_goals_by_pk
    const typeGoal =
      res?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate?.count > 0
    const status =
      res?.total_task?.aggregate?.count ===
        res?.complete_task?.aggregate?.count &&
      res?.total_task?.aggregate?.count !== 0
        ? TASK_STATUS.COMPLETED
        : TASK_STATUS.TODO

    const temp = []
    const countUsers = []
    const images = []

    res &&
      res.performance_tasks &&
      res.performance_tasks.map(tasks => {
        tasks.performance_task_assignments &&
          tasks.performance_task_assignments.map(assign => {
            if (assign.global_user) {
              if (images.indexOf(assign.global_user.avatar) === -1) {
                images.push({
                  image: assign.global_user.avatar,
                  name: assign.global_user.name,
                  key: key,
                })
              }
            }
          })
      })

    const dataImages = images.reduce((unique, o) => {
      if (
        !unique.some(
          obj =>
            obj.image === o.image && obj.name === o.name && obj.key === o.key
        )
      ) {
        unique.push(o)
      }
      return unique
    }, [])

    if (res) {
      temp.push({
        [res.id]: dataImages.slice(0, 5),
      })
      countUsers.push({
        [res.id]: dataImages,
      })
    }

    const renderAvatar = goalId => {
      if (temp) {
        for (let i = 0; i < temp.length; i++) {
          if (temp[i][goalId]) {
            const html = []
            temp[i][goalId].map((val, index) => {
              html.push(
                <Avatar
                  className={classes.avatar}
                  md={2}
                  alt={val.name}
                  src={val.image}
                  key={index}
                />
              )
            })
            if (countUsers[i][goalId].length > 5) {
              html.push(
                <Avatar className={classes.avatar} key={i + 10}>{`+${countUsers[
                  i
                ][goalId].length - 5}`}</Avatar>
              )
            }
            return html
          }
        }
      }
    }

    if (res === null) {
      return (
        <Card elevation={0} className={classes.card} key={key}>
          <CardContent className={classes.cardContent}>
            <Skeleton width={240} height={90} />
            <Skeleton circle={true} width={50} height={50} />
            <Skeleton width={240} height={60} />
          </CardContent>
        </Card>
      )
    }

    const listMore = [
      {
        label: 'Ubah',
        onClick: e => handleElipsis('edit', res, e),
      },
      {
        label: 'Hapus',
        onClick: e => handleElipsis('delete', res, e),
      },
    ]

    const handleClickMore = e => {
      e.stopPropagation()
      setAnchorEl(e.currentTarget)
    }

    const handleClosePopup = e => {
      e.stopPropagation()
      setAnchorEl(null)
    }

    const colorPrecentage =
      res?.progress_percentage >= 100 ? '#4caf50' : '#039be5'
    const open = Boolean(anchorEl)
    const id = open ? `more-${idGoal}` : undefined

    return (
      <Card
        onClick={() => handleClickOpen(res.id)}
        elevation={0}
        className={classes.card}
        key={key}
      >
        <CardContent className={classes.cardContent}>
          <Grid style={{height: 100}} container>
            <Grid className={classes.headerWrap} item xs={12} sm={12} md={10}>
              <div className={classes.pfGroupWrap}>
                <div
                  className={classes.box}
                  style={{
                    backgroundColor:
                      res?.performance_goal_group?.color || '#e5e5e5',
                  }}
                />
                <Typography
                  className={classes.pfGroup}
                  gutterBottom
                  style={{
                    color: res?.performance_goal_group ? '#014a62' : '#a9a8a8',
                    fontStyle: res?.performance_goal_group
                      ? 'initial'
                      : 'italic',
                  }}
                >
                  {res?.performance_goal_group?.name || 'Tidak Ada'}
                </Typography>
              </div>
              <Typography component="h3" className={classes.title}>
                {res.name}
              </Typography>
              <Typography
                className={classes.pos}
                color="textSecondary"
                component="span"
              >
                {status}
              </Typography>
              {status === TASK_STATUS.COMPLETED && (
                <CheckCircle
                  color="secondary"
                  style={{
                    fontSize: 15,
                    marginLeft: '5px',
                    verticalAlign: 'text-top',
                  }}
                />
              )}
            </Grid>
            {res && res.created_by === USER_ID && (
              <Grid item xs={12} sm={12} md={2}>
                <IconButton
                  className={classes.buttonActionMore}
                  onClick={handleClickMore}
                >
                  <Icon>more_horiz</Icon>
                </IconButton>
              </Grid>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopup}
              classes={{paper: classes.rootList}}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <List>
                {listMore?.map(({label, onClick}) => (
                  <StyledMenuItem
                    className={classes.rootListItem}
                    key={label}
                    onClick={onClick}
                  >
                    {label}
                  </StyledMenuItem>
                ))}
              </List>
            </Popover>
          </Grid>
        </CardContent>
        <CardActions style={{padding: '12px 16px 16px'}}>
          <Grid container>
            <Grid
              container
              md={12}
              className={classes.avatarWrap}
              style={{minHeight: 30}}
            >
              {renderAvatar(res.id)}
            </Grid>
            <Grid item md={12} className={classes.avatarWrap}>
              <GoalProgressIndicator
                style={{textAlign: 'left', color: colorPrecentage}}
              >
                {`${
                  res && res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : 0
                }%`}
              </GoalProgressIndicator>
            </Grid>
            <Grid className={classes.cardInfo} item md={12}>
              <GoalsListBtn
                onClick={() => handleClickOpen(res.id)}
                style={{
                  backgroundColor: PRIORITY_STYLE[res.priority] || '#FFFFFF',
                }}
              >
                {PRIORITY[res.priority]}
              </GoalsListBtn>
              <GoalsListBtn
                onClick={() => handleClickOpen(res.id)}
                style={{
                  backgroundColor: typeGoal ? '#a35a31' : '#874caf',
                }}
              >
                {typeGoal
                  ? GLOBAL_GLOSSARY.performance['Company Goal']
                  : GLOBAL_GLOSSARY.performance['Personal Goal']}
              </GoalsListBtn>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    )
  }

  const handleClickOpen = id => {
    setOpen(true)
    setSelectedId(id)
  }

  const handleClose = () => {
    setDataContext(null)
    setOpen(false)
    setMore(5)
  }

  const handleElipsis = async (action, data, e) => {
    e.stopPropagation()
    setDataEdit(data)
    if (action === 'edit') {
      const {
        data: _data,
        error: _error,
        loading: _loading,
      } = await client.query({
        query: GET_DETAIL_CONTAINER_GOAL,
        variables: {id: data.id},
      })

      if (_loading) {
        return <div>Loading....</div>
      } else if (_error !== undefined) {
        return <div>{JSON.stringify(_error)}</div>
      }
      setDataModal(_data.performance_goals_by_pk)
      return setOpenEdit(true)
    } else if (action === 'delete') {
      setOpenDelete(true)
    }
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setTimeout(() => {
      setDataContext(null)
    }, 5000)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setTimeout(() => {
      setDataContext(null)
    }, 1000)
  }

  const [deleteGoal] = useMutation(DELETE_GOAL)

  const onDeleteGoal = () => {
    deleteGoal({
      variables: {
        id: dataEdit.id,
      },
    })
      .then(() => {
        setStates({open: true, message: 'Data deleted'})
        refetchIdGoal()
        setDataContext(true)
      })
      .catch(() => {
        setStates({
          open: true,
          message: 'Update data error, please try again',
        })
      })
  }

  function handleCloseSnackbar() {
    setStates({...states, open: false})
  }

  if (dataIdGoal === undefined && errorIdGoal === undefined) {
    return <div>loading ...</div>
  } else if (errorIdGoal !== undefined) {
    return <div>{JSON.stringify(errorIdGoal)}</div>
  }

  const SHOWING = dataIdGoal?.performance_goals_aggregate?.aggregate?.count || 0
  const TOTAL_DATA = dataIdGoal?.total?.aggregate?.count || 0

  return (
    <Grid container>
      <div className={classes.wrapTitle}>
        <TitleWrapper style={{marginBottom: 14, alignItems: 'center'}}>
          <Typography component="h3" className={classes.titleSection}>
            Daftar {GLOBAL_GLOSSARY.performance.Goal}
          </Typography>
          <Button
            variant="text"
            color="secondary"
            className={classes.seeAll}
            component={Link}
            to={{pathname: `/goals/${type}/all-goal`}}
          >
            Lihat Semua
          </Button>
        </TitleWrapper>
        <Typography component="p" className={classes.totalText}>
          Menampilkan {SHOWING} dari {TOTAL_DATA}{' '}
          {GLOBAL_GLOSSARY.performance.Goal}
        </Typography>
      </div>
      <Grid container className={classes.goalContainer}>
        <Grid container>
          {SHOWING === 0 ? (
            <div className={classes.emptyStateWrap}>
              <EmptyState
                message1="Maaf, tidak ada daftar"
                message2={`Tidak ada ${GLOBAL_GLOSSARY.performance.Goal} yang dibuat`}
                width="250"
              />
            </div>
          ) : (
            <Carousel
              slidesToShow={3}
              slidesToScroll={3}
              renderCenterRightControls={null}
              renderCenterLeftControls={null}
              renderTopRightControls={({
                previousSlide,
                currentSlide,
                slideCount,
                nextSlide,
              }) => {
                return (
                  <div style={{marginTop: '-4.5rem', padding: '5px 15px'}}>
                    {currentSlide === 0 ? (
                      <IconArrowDisabled
                        style={{
                          marginRight: '1rem',
                          border: 'none',
                          fontSize: 20,
                        }}
                      >
                        chevron_left
                      </IconArrowDisabled>
                    ) : (
                      <IconArrowBlue
                        style={{
                          marginRight: '1rem',
                          border: 'none',
                          backgroundColor: 'initial',
                          color: 'black',
                          fontSize: 20,
                        }}
                        onClick={previousSlide}
                      >
                        chevron_left
                      </IconArrowBlue>
                    )}
                    {slideCount - currentSlide === 3 ? (
                      <IconArrowDisabled style={{border: 'none', fontSize: 20}}>
                        chevron_right
                      </IconArrowDisabled>
                    ) : (
                      <IconArrowBlue
                        style={{
                          border: 'none',
                          backgroundColor: 'initial',
                          color: 'black',
                          fontSize: 20,
                        }}
                        onClick={nextSlide}
                      >
                        chevron_right
                      </IconArrowBlue>
                    )}
                  </div>
                )
              }}
              renderBottomCenterControls={null}
            >
              {renderListData(dataIdGoal, loadingIdGoal)}
            </Carousel>
          )}
        </Grid>
        <ModalTab
          more={more}
          setMore={setMore}
          open={open}
          handleClose={handleClose}
          id={selectedId}
        />
        <ModalEdit
          open={openEdit}
          onBack={handleCloseEdit}
          dataEdit={dataModal}
          type="edit-goal"
        />
        <ModalDelete
          open={openDelete}
          handleClose={handleCloseDelete}
          name={dataEdit.name}
          mutation={() => onDeleteGoal()}
        />
        {snackBar(handleCloseSnackbar, states.open, states.message)}
      </Grid>
    </Grid>
  )
}

export default withRouter(ListGoal)
