import gql from 'graphql-tag'

export const UPDATE_TASK_REVIEW = gql`
  mutation($id: bigint, $review: String) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {status: "COMPLETED", review: $review}
    ) {
      affected_rows
    }
  }
`
