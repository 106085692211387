import styled from 'styled-components'

export const ModalMoveWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const ModalMoveTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 12px 4px 26px;
  background-color: #eff2f4;
`
export const ModalMoveContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 26px;
  background-color: #fff;
`
export const TitleModal = styled.h2`
  font-size: 16px;
  font-weight: 600;
  font-family: Muli;
`
export const Heighlight = styled.h3`
  font-size: 16px;
  font-weight: 600;
  font-family: Muli;
  margin: 0px 0px 10px 0px;
`
export const PrimaryText = styled.span`
  color: #014a62;
  font-family: Muli;
`
export const SecondaryText = styled.span`
  color: #039be5;
  font-family: Muli;
`
export const TextStyled = styled.span`
  color: ${props => props.color};
  margin: ${props => props.margin};
  font-weight: ${props => props.fontweight};
  font-size: ${props => props.fontsize};
  font-family: Muli;
`
export const SectionGrey = styled.section`
  background-color: #eff2f4;
  padding: 32px 28px 24px 28px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  border-radius: 5px;
  font-family: Muli;
  margin-bottom: 16px;
`
export const WrapperContent = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
`
export const WrapperReporter = styled.section`
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  align-items: center;
`
export const ContentReporter = styled.span`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  align-content: center;
`
export const Wrapperhalf = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'column')};
  width: 47%;
`
export const WrapperSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const WrapperButtonAction = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
`
