import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import HeaderGenerate from '../../../../components/header/HeaderGenerate'
import {NIP, USER_ID} from '../../../../utils/globals'
import moment from 'moment'
import {Doughnut} from 'react-chartjs-2'
import {LinearProgressbar, TableStyled} from './ReportStyled'
import {useQuery} from '@apollo/react-hooks'
import {GET_COUNT_RESPONDEN, GET_REPORT_NPS} from '../../../../graphql/queries'

const FLEX_COLUMN = {display: 'flex', flexDirection: 'column'}
const WLB_BORDER = 'solid 1px #eff2f4'
const FLEX_ROW = {display: 'flex', flexDirection: 'row'}
const TEXT_GREY = {color: '#a9a8a8', fontSize: 8}

const useStyles = makeStyles({
  dividerHEader: {height: '4px!important', background: '#eff2f4'},
  container: {
    width: 720,
    margin: 'auto',
    border: WLB_BORDER,
  },
  footerWrapper: {
    marginTop: 40,
    paddingBottom: 12,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  textFooter: {fontSize: '0.875rem', color: '#a9a8a8', lineHeight: '15px'},
  wrapperBody: {...FLEX_COLUMN},
  // progressRoot: {height: 16, backgroundColor: '#e9ecee'},
  // progressPrimary: {color: '#014a62', borderRadius: '0px 2px 2px 0px'},
  titleSum: {fontSize: 8, fontWeight: '600', marginBottom: 2},
  scoreSum: {
    fontSize: 25,
    fontWeight: '600',
    color: '#039be5',
    lineHeight: '36px',
  },
  footerSum: {fontSize: 5, fontWeight: '600', color: '#039be5'},
  boxWrapper: {
    height: 'auto',
    padding: '8px 10px',
    border: WLB_BORDER,
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  wrapperBar: {
    padding: '8px 20px',
    border: WLB_BORDER,
    borderRadius: 5,
    width: '100%',
    marginLeft: 9,
    marginBottom: 6,
  },
  listWrapper: {
    ...FLEX_COLUMN,
    border: WLB_BORDER,
    borderRadius: 5,
    marginTop: 10,
  },
  namePeople: {
    fontSize: 8,
    color: '#000',
    fontWeight: '600',
    marginBottom: 4,
  },
  textBlue: {fontSize: 8, marginBottom: 5, color: '#039be5'},
  textReason: {fontSize: 7},
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
})

const Profile = ({data}) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', marginBottom: 21}}>
      <img
        alt="avatar"
        src={data.avatar}
        style={{
          width: '57px',
          height: '57px',
          borderRadius: '10px',
          marginRight: '1rem',
        }}
      />
      <div>
        <Typography variant="body2" style={{fontWeight: 'bold'}}>
          {data.name}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#a9a8a8',
          }}
        >
          {(data.people_work_placements[0] &&
            data.people_work_placements[0].regno) ||
            NIP}
        </Typography>
        <Typography
          variant="body2"
          style={{
            color: '#a9a8a8',
          }}
        >
          {data.people_work_placements[0] &&
            data.people_work_placements[0].company_job_profile &&
            data.people_work_placements[0].company_job_profile.title}
        </Typography>
      </div>
    </div>
  )
}

const Footer = ({visible, handlePrints, hiddenprint = false}) => {
  const classes = useStyles()

  return (
    <footer>
      <style>{`@media print {footer {position: fixed;bottom: 0;}}`}</style>
      <div className={classes.footerWrapper}>
        <Typography className={classes.textFooter}>
          Dihasilkan pada {moment().format('LL')}
        </Typography>
        {!hiddenprint && (
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: '4px 12px',
              display: visible ? 'flex' : 'none',
            }}
            onClick={handlePrints}
          >
            <Icon style={{marginRight: 12}}>print</Icon>Cetak
          </Button>
        )}
      </div>
    </footer>
  )
}

const Summary = ({data}) => {
  const classes = useStyles()
  const idSurvey = data.multirater_surveys[0].id

  const {data: countResponden, loading} = useQuery(GET_COUNT_RESPONDEN, {
    variables: {user: USER_ID, survey: idSurvey},
  })

  const totalRespondent =
    countResponden?.people_work_placements_aggregate.aggregate.count

  const dectators = data && data.dectators.aggregate.count
  const passives = data && data.passives.aggregate.count
  const promotors = data && data.promotors.aggregate.count

  const sumQuestion = promotors + passives + dectators
  const result = Math.round(
    (promotors / sumQuestion - dectators / sumQuestion) * 100
  )

  const dectatorsPercent = Math.round(
    (dectators / (dectators + passives + promotors)) * 100
  )
  const passivesPercent = Math.round(
    (passives / (dectators + passives + promotors)) * 100
  )
  const promotorsPercent = Math.round(
    (promotors / (dectators + passives + promotors)) * 100
  )

  const getPercent = result => {
    if (isNaN(result)) {
      return '0%'
    } else {
      return `${result}%`
    }
  }

  const Box = ({title, score, footer, withMargin = false}) => {
    return (
      <div
        style={{marginBottom: withMargin ? 9 : 0}}
        className={classes.boxWrapper}
      >
        <Typography className={classes.titleSum}>{title}</Typography>
        <Typography className={classes.scoreSum}>{score}</Typography>
        <Typography className={classes.footerSum}>{footer}</Typography>
      </div>
    )
  }

  const Respond = ({promotorsPercent, passivesPercent, dectatorsPercent}) => {
    const data = {
      labels: [
        'Promoter ' + promotorsPercent + '%',
        'Passive ' + passivesPercent + '%',
        'Detractor ' + dectatorsPercent + '%',
      ],
      datasets: [
        {
          label: [promotorsPercent, passivesPercent, dectatorsPercent],
          data: [promotorsPercent, passivesPercent, dectatorsPercent],
          backgroundColor: ['#003d51', '#ffa000', '#ef4d5e'],
          hoverBackgroundColor: ['#003d51', '#ffa000', '#ef4d5e'],
        },
      ],
    }

    const options = {
      maintainAspectRatio: true,
      responsive: true,
      legend: {
        position: 'right',
        labels: {
          boxWidth: 20,
        },
      },
      tooltips: {
        callbacks: {
          title: function(tooltipItem, data) {
            return data.labels[tooltipItem[0].index]
          },
          label: function() {
            return ''
          },
          // afterLabel: function(tooltipItem, data) {
          //   console.log("data grafk : ", data)
          //   var dataset = data.datasets[0]
          //   var percent = Math.round(
          //     (dataset.data[tooltipItem.index] / dataset._meta[0].total) * 100
          //   )
          //   return '(' + percent + '%)'
          // },
        },
        backgroundColor: '#FFF',
        titleFontSize: 16,
        titleFontColor: '#0066ff',
        bodyFontColor: '#000',
        bodyFontSize: 14,
        displayColors: false,
        animation: {
          animateRotate: false,
        },
      },
    }

    return (
      <div className={classes.wrapperBar}>
        <Typography className={classes.titleSum} style={{marginBottom: 17}}>
          Respon
        </Typography>
        <Doughnut
          data={data}
          style={{marginLeft: '-140px'}}
          options={options}
          width={200}
          height={40}
        />
      </div>
    )
  }

  return (
    <div style={{...FLEX_ROW}}>
      <div style={{...FLEX_COLUMN, width: '30%'}}>
        <Box
          title="Indeks Keseluruhan"
          score={getPercent(result)}
          footer={'Keseluruhan'}
          withMargin
        />
        <Box
          title="Responden"
          score={
            loading ? <CircularProgress /> : `${sumQuestion}/${totalRespondent}`
          }
          footer={'People'}
        />
      </div>
      <Respond
        dectatorsPercent={dectatorsPercent}
        passivesPercent={passivesPercent}
        promotorsPercent={promotorsPercent}
      />
    </div>
  )
}

const ListPeople = ({avatar, name, regno, title, rating, reason, style}) => {
  const classes = useStyles()
  return (
    <div style={style} className={classes.listWrapper}>
      <div style={{...FLEX_ROW, borderBottom: WLB_BORDER}}>
        <div
          style={{
            ...FLEX_ROW,
            padding: '8px 10.5px 9px 17.5px',
            borderRight: WLB_BORDER,
            width: 211,
            boxSizing: 'border-box',
          }}
        >
          <Avatar src={avatar} style={{height: 24, width: 24}} />
          <div style={{marginLeft: 10}}>
            <Typography className={classes.namePeople}>{name}</Typography>
            <Typography style={{...TEXT_GREY}}>{regno}</Typography>
            <Typography style={{...TEXT_GREY}}>{title}</Typography>
          </div>
        </div>
        <div
          style={{
            ...FLEX_COLUMN,
            padding: '8px 0px 16px 0px',
            width: '460px',
          }}
        >
          <Typography
            style={{marginLeft: '9.5px'}}
            className={classes.namePeople}
          >
            Tanggapan Penilaian
          </Typography>
          <Box display="flex" mt="8px" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgressbar
                // classes={{
                //   root: classes.progressRoot,
                //   barColorPrimary: classes.progressPrimary,
                // }}
                // variant="determinate"
                value={rating * 10 + '%'}
              />
            </Box>
            <Box ml="18px" minWidth={35}>
              <Typography variant="body2" color="textPrimary">
                {rating}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
      <div style={{padding: '8px 17.5px'}}>
        <Typography className={classes.textBlue}>Alasan</Typography>
        <Typography className={classes.textReason}>{reason}</Typography>
      </div>
    </div>
  )
}

const Body = ({data, setlayout}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperBody}>
      <Summary data={data} />
      {data &&
        data.responses.map((res, i) => {
          return (
            <ListPeople
              key={`${i}-${res.id}`}
              style={
                (i === 3 || i === 10) && setlayout ? {marginBottom: 90} : null
              }
              avatar={
                res &&
                res.global_user_respond_from &&
                res.global_user_respond_from.avatar
              }
              name={
                res &&
                res.global_user_respond_from &&
                res.global_user_respond_from.name
              }
              regno={
                res &&
                res.global_user_respond_from &&
                res.global_user_respond_from.people_work_placements[0] &&
                res.global_user_respond_from.people_work_placements[0].regno
              }
              title={
                res &&
                res.global_user_respond_from &&
                res.global_user_respond_from.people_work_placements[0] &&
                res.global_user_respond_from.people_work_placements[0]
                  .company_job_profile &&
                res.global_user_respond_from.people_work_placements[0]
                  .company_job_profile.title
              }
              rating={res && res.answer}
              reason={res && res.answer_text}
            />
          )
        })}
    </div>
  )
}

const Table = () => {
  return (
    <TableStyled>
      <div className="header">
        <div className="row ">
          <Typography className="title">Title</Typography>
          <Typography className="date">Date Added</Typography>
          <Typography className="score">Score 1</Typography>
          <Typography className="score">Score 1</Typography>
          <Typography className="score">Score 1</Typography>
          <Typography className="score">Score 1</Typography>
          <Typography className="score">Score 1</Typography>
        </div>
      </div>

      <div className="body">
        <div className="row">
          <Typography className="title">Engagement Survey</Typography>
          <Typography className="date">30 Oct 2019</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
        </div>
      </div>
      <div className="body">
        <div className="row">
          <Typography className="title">Engagement Survey</Typography>
          <Typography className="date">30 Oct 2019</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
        </div>
      </div>
      <div className="body">
        <div className="row">
          <Typography className="title">Engagement Survey</Typography>
          <Typography className="date">30 Oct 2019</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
          <Typography className="score">123</Typography>
        </div>
      </div>
    </TableStyled>
  )
}

export default withRouter(function GenerateNPS(props) {
  const {id} = props.match.params
  const classes = useStyles()
  const [buttonVisible, setButtonVisible] = useState(true)

  const {data: dataQuery, error} = useQuery(GET_REPORT_NPS, {
    variables: {
      user: USER_ID,
      survey: id,
    },
  })

  if (!dataQuery || error) {
    return (
      <div className={classes.loadingProgress}>
        <CircularProgress />
        <Typography variant="body1" color="primary" style={{marginTop: 10}}>
          Loading data, please wait
        </Typography>
      </div>
    )
  }

  const user = dataQuery.user[0]

  const handlePrints = () => {
    setButtonVisible(false)
    document.title = `${user.name}_${(user.people_work_placements[0] &&
      user.people_work_placements[0].regno) ||
      NIP}_${moment().format('LL')}_Report NPS_${
      dataQuery.multirater_surveys[0].title
    }`
    setTimeout(() => {
      window.print()
      setButtonVisible(true)
      document.title = 'WLB - Performance'
    }, 1000)
  }

  return (
    <>
      <div style={{maxHeight: '100vh'}} className={classes.container}>
        <HeaderGenerate
          taskCode={dataQuery.multirater_surveys[0].title}
          title={'Net Promotor Score'}
          from={moment(dataQuery.multirater_surveys[0].startdate).format('LL')}
          to={moment(dataQuery.multirater_surveys[0].enddate).format('LL')}
        />
        <Divider className={classes.dividerHEader} />
        <Paper style={{padding: '1.5rem 1.5rem 0rem 1.5rem'}} elevation={0}>
          <Profile data={user} />
          <Body setlayout={!buttonVisible} data={dataQuery} />
          <Footer
            // hiddenprint
            handlePrints={handlePrints}
            visible={buttonVisible}
          />
        </Paper>
      </div>
      <div
        style={{maxHeight: '100vh', display: 'none'}} // page 2 block by datanya, ubah display buat munculin
        className={classes.container}
      >
        <HeaderGenerate
          taskCode={'Leadership Survey'}
          title="Net Promotor Score"
          from={'October 21, 2020'}
          to={'November 30, 2020'}
        />
        <Divider className={classes.dividerHEader} />
        <Paper
          style={{
            padding: '1.5rem 0rem 0rem 0em',
            minHeight: 680,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
          elevation={0}
        >
          <Table />
          <div style={{padding: '0px 24px'}}>
            <Footer handlePrints={handlePrints} visible={buttonVisible} />
          </div>
        </Paper>
      </div>
    </>
  )
})
