import {useQuery} from '@apollo/react-hooks'
import {Divider, MenuItem, TextField, Typography} from '@material-ui/core'
import moment from 'moment'
import React, {useState} from 'react'
import {withRouter} from 'react-router'
import EmptyState from '../../../components/empty-state/EmptyStateComponent'
import LoadingComponent from '../../../components/loading/LoadingComponent'
import {TypographyStyleds} from '../../../components/typography/TypographyStyles'
import {VERSION_COMMITTEE_TASK} from '../../../graphql/queries/classification/performanceClassification.query'
import {USER_ID} from '../../../utils/globals'
import {capitalize} from '../../../utils/helpers'
import {FlexBetween, GreyTypography, LineClamp} from '../PerformanceStyles'

const MyCompany = ({history}) => {
  const [sort, setSort] = useState('')
  const {data, loading} = useQuery(VERSION_COMMITTEE_TASK, {
    variables: {
      user: USER_ID,
      shortName:
        sort === 'nameAsc' ? 'asc' : sort === 'nameDesc' ? 'desc' : null,
      shortDate: sort === 'latest' ? 'desc' : sort === 'oldest' ? 'asc' : null,
    },
  })

  if (loading) {
    return <LoadingComponent />
  }

  return (
    <>
      <FlexBetween style={{marginBottom: 20}}>
        <TextField
          label="Urutkan berdasarkan"
          select
          variant="outlined"
          size="small"
          style={{width: 210}}
          value={sort}
          onChange={e => setSort(e.target.value)}
        >
          <MenuItem value="latest">Terbaru</MenuItem>
          <MenuItem value="oldest">Terlama</MenuItem>
          <MenuItem value="nameAsc">Nama (A-Z)</MenuItem>
          <MenuItem value="nameDesc">Nama (Z-A)</MenuItem>
        </TextField>
      </FlexBetween>
      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {data.talent_committees.length === 0 ? (
          <EmptyState
            message1={'Maaf, Tidak ada Daftar'}
            message2={
              'Tidak ada evaluasi kinerja yang terdaftar dari perusahaan Anda saat ini'
            }
            image={require('../../../assets/images/nodatalist.png')}
          />
        ) : (
          data.talent_committees.map((_res, i) => {
            const res = _res.talent_candidate_group?.talent_version
            const startDate = moment(
              _res.talent_candidate_group.committee_date_start
            ).format('LL')

            const endDate = moment(
              _res.talent_candidate_group.committee_date_end
            ).format('LL')

            const daysLeft = moment(endDate)
              .add(1, 'days')
              .diff(moment(new Date()), 'days')

            return (
              <div style={{width: '25%', marginBottom: 20}} key={i}>
                <div
                  style={{
                    padding: '15px 15px 16px 14px',
                    borderRadius: 5,
                    border: 'solid 1px #eff2f4',
                    backgroundColor: '#ffffff',
                    marginRight: 20,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    history.push({
                      pathname: `committee/version/${res.id}/${_res.talent_candidate_group?.company_job_profile?.id}/${_res.talent_candidate_group?.id}`,
                    })
                  }
                >
                  <LineClamp
                    style={{
                      fontWeight: 600,
                      color: '#055469',
                      fontSize: '0.875rem',
                      marginBottom: 8,
                    }}
                  >
                    {res.name}
                  </LineClamp>

                  <Typography variant="body2" color="secondary" paragraph>
                    {_res.talent_candidate_group?.company_job_profile?.title}
                  </Typography>

                  <Divider style={{marginBottom: 10}} />
                  <TypographyStyleds
                    color={
                      res.status === 'COMPLETED'
                        ? '#4caf50'
                        : res.status === 'INEVALUATION'
                        ? '#039be5'
                        : '#000'
                    }
                    weight="600"
                    gutterBottom
                    variant="body2"
                  >
                    {res.status === 'INEVALUATION'
                      ? 'Dalam Evaluasi'
                      : capitalize(res.status)}
                  </TypographyStyleds>
                  <GreyTypography variant="body2" gutterBottom>
                    {startDate} - {endDate}
                  </GreyTypography>
                  <Typography
                    variant="body2"
                    style={{
                      color:
                        daysLeft <= 7 && daysLeft > 3
                          ? '#ffa000'
                          : daysLeft <= 3
                          ? '#ef4d5e'
                          : '#039be5',
                    }}
                  >
                    Tersisa {daysLeft || 0} hari lagi
                  </Typography>
                </div>
              </div>
            )
          })
        )}
      </div>
    </>
  )
}

export default withRouter(MyCompany)
