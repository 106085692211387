import gql from 'graphql-tag'

export const DELETE_TASK = gql`
  mutation deleteTask($id: bigint!) {
    update_performance_tasks(
      where: {id: {_eq: $id}}
      _set: {deletedat: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`
