import React, {useState, useContext, useEffect} from 'react'
import {
  ChipCascade,
  FlexBetween,
  InformationContainer,
} from '../../PerformanceStyles'

import {GreyTypography} from '../../../../components/typography/TypographyStyles'

import {
  Typography,
  Avatar,
  Button,
  TextField,
  MenuItem,
} from '@material-ui/core'
import moment from 'moment'
import {capitalize, trimString} from '../../../../utils/helpers'
import {goalType, priority, visibility} from './constant'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'
import {STATUS_OPTIONS, TASK_STATUS} from '../constant'
import ConfirmationPopup from '../../../shared-component/ConfirmationPopup'
import {useMutation, useQuery, useApolloClient} from '@apollo/react-hooks'
import {
  DELETE_CASCADE,
  UPDATE_TASK,
  UPDATE_TASK_REVIEW,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import {USER_ID} from '../../../../utils/globals'
import {DataContext} from '../../../../contexts/ContextConfig'
import {GET_CASCADE_GOAL, GET_CASCADE_TASK} from '../../../../graphql/queries'
import ModalDelete from '../../Modal/ModalDelete'
import CascadePopup from '../../../shared-component/CascadePopup'

export default function Detail({data, type}) {
  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()
  const {setDataContext} = useContext(DataContext)
  const [editStatus, setEditStatus] = useState(false)
  const [addCascade, setAddCascade] = useState(false)
  const [tempCascade, setTempCascade] = useState([])
  const INITIAL_DELETE_CASCADE = {
    open: false,
    id: null,
  }
  const [deleteCascade, setDeleteCascade] = useState(INITIAL_DELETE_CASCADE)
  const [limit, setlimit] = useState(3)
  const [statusTask, setStatusTask] = useState({
    id: '',
    status: '',
    progress: 0,
    comment: '',
  })
  const [alertEditTask, setAlertEditTask] = useState({
    open: false,
    message: '',
    feature: '',
    children: null,
  })
  const customStyle = {
    flex: {
      display: 'flex',
    },
    flexFull: {
      display: 'flex',
      width: '100%',
    },
    PR: {
      paddingRight: '3rem',
    },
    pR: {
      paddingRight: '1.5rem',
    },
    buttonSpacer: {
      marginRight: 12,
    },
    assignedWrapper: {
      background: '#eff2f4',
      padding: '1rem',
      minHeight: '11rem',
    },
    dynamicWidth: {width: type === 'taskDetail' ? '35%' : '50%'},
    editStyle: {
      fontSize: 18,
      color: '#039be5',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    addCascade: {
      width: '25%',
      fontSize: 25,
      color: '#039be5',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    closeCascade: {
      width: '15px',
      fontSize: 15,
      color: '#014A62',
      cursor: 'pointer',
      alignSelf: 'center',
    },
    editText: {color: '#039be5', marginRight: '0.5rem'},
    featureStyle: {color: '#039be5'},
    buttonStyle: {margin: '5px 0px 0px 12px'},
    cascadeId: {color: '#039be5', marginRight: '0.5rem', fontSize: 12},
    seeMore: {
      color: '#039be5',
      marginRight: '0.5rem',
      fontSize: 12,
      marginTop: 10,
      cursor: 'pointer',
    },
  }

  const [updateStatusTask] = useMutation(UPDATE_TASK)
  const [mutationReview] = useMutation(UPDATE_TASK_REVIEW)
  const [mutationDeleteCascade] = useMutation(DELETE_CASCADE)

  const isGoal = type === 'goal'

  const handleEditStatus = (idTask, dataStatus, progress) => {
    setStatusTask({
      ...statusTask,
      id: idTask,
      status: dataStatus,
      progress: progress,
    })
    setEditStatus(true)
  }

  const handleCancel = () => {
    setEditStatus(false)
    setStatusTask({id: '', status: '', comment: ''})
  }

  const isCompleted = statusTask.status === 'COMPLETED'

  const handleMutation = () => {
    const isUnable = alertEditTask.feature.includes('Unable')
    if (!isUnable) {
      if (isCompleted) {
        mutationReview({
          variables: {
            id: statusTask.id,
            review: statusTask.comment,
          },
        })
          .then(() => {
            enqueueSnackbar('Status and review Updated', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setEditStatus(false)
            setDataContext(true)
          })
          .catch(() => {
            enqueueSnackbar(
              'Update Status and review error, please try again',
              {
                variant: 'error',
                autoHideDuration: 1000,
              }
            )
          })
      } else {
        updateStatusTask({
          variables: {
            id: statusTask.id,
            status: statusTask.status,
            progress: statusTask.progress,
          },
        })
          .then(() => {
            enqueueSnackbar('Status Updated', {
              variant: 'success',
              autoHideDuration: 1000,
            })
            setEditStatus(false)
            setDataContext(true)
          })
          .catch(() => {
            enqueueSnackbar('Update status error, please try again', {
              variant: 'error',
              autoHideDuration: 1000,
            })
          })
      }
    }
    setAlertEditTask({
      ...alertEditTask,
      open: false,
    })
  }

  const handleConfirm = () => {
    setAlertEditTask({
      open: true,
      feature: `Change Status to ${TASK_STATUS[statusTask?.status]}?`,
      message: (
        <>
          <span>Are you sure to change this task status to</span>
          <span style={customStyle.featureStyle}>
            {` ${TASK_STATUS[statusTask?.status]} `}
          </span>
          <span>?</span>
        </>
      ),
      ...(isCompleted && {
        children: (
          <>
            <Typography
              variant="body2"
              style={{color: '#A9A8A8', marginTop: '2.5rem'}}
            >
              Comment*
            </Typography>
            <TextField
              id="comment"
              style={{marginTop: 12, width: '100%'}}
              placeholder="Add Comment"
              multiline
              rows={4}
              variant="outlined"
              onChange={event =>
                setStatusTask({...statusTask, comment: event.target.value})
              }
            />
          </>
        ),
      }),
    })
  }

  const handleSeeMore = () => {
    if (limit >= totalCascade) {
      setlimit(3)
    } else {
      setlimit(totalCascade)
    }
    refetch()
  }

  const onDeleteCascade = () => {
    mutationDeleteCascade({variables: {id: deleteCascade.id}})
      .then(() => {
        enqueueSnackbar('Success delete link', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setDeleteCascade(INITIAL_DELETE_CASCADE)
        refetch()
      })
      .catch(() => {
        enqueueSnackbar('Delete link error, please try again', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const dynamicData = isGoal ? data.performance_goals : data.performance_tasks
  const queryCascade = isGoal ? GET_CASCADE_GOAL : GET_CASCADE_TASK
  const {data: dataCascade, refetch, loading} = useQuery(queryCascade, {
    variables: {
      id: dynamicData?.[0]?.id,
      offset: 0,
      limit: limit,
    },
    skip: !dynamicData,
  })

  useEffect(() => {
    refetch()
  }, [])

  const isCascade = dataCascade?.performance_cascadings?.length > 0
  const totalCascade = dataCascade?.total?.aggregate?.count

  const handleAddCascade = async () => {
    setAddCascade(true)
    const {data} = await client.query({
      query: queryCascade,
      variables: {id: dynamicData?.[0]?.id, offset: 0, limit: totalCascade},
    })

    const tempIdCascade = data?.performance_cascadings?.map(data => {
      const dataCascading = isGoal
        ? data.performance_task.id
        : data.performance_goal.id
      return dataCascading
    })

    setTempCascade(tempIdCascade)
  }

  return dynamicData.map((res, i) => {
    const formattedStartDate = moment(res.startdate).format('ll')
    const formattedEndDate = moment(res.enddate).format('ll')
    const dataKpi = isGoal ? res.kpi_list : res.performance_goal.kpi_list
    const isReporter =
      res?.ReporterUser?.id === USER_ID || res?.created_by_user?.id === USER_ID
    const typeGoal =
      dataKpi?.kpi_cascading_lists_aggregate?.aggregate?.count > 0

    const status =
      res?.total_task?.aggregate?.count ===
        res?.complete_task?.aggregate?.count &&
      res?.total_task?.aggregate?.count !== 0
        ? TASK_STATUS.COMPLETED
        : TASK_STATUS.TODO

    const creator = isGoal ? res?.global_user?.id : res?.created_by_user?.id
    const canEditCascade = USER_ID === creator

    const participantId = res?.performance_task_assignments?.map(
      assign => assign?.global_user?.id
    )
    const canEdit = participantId?.includes(USER_ID)

    const handleAlertEdit = (event, dataStatus) => {
      const value = event.target.value
      if (dataStatus !== 'DONE' && value === 'COMPLETED') {
        setAlertEditTask({
          ...alertEditTask,
          open: true,
          feature: 'Unable to Change Status to Complete',
          message: (
            <>
              <span>In order to change this task status to </span>
              <span style={customStyle.featureStyle}>Complete</span>
              <span>, you need to set the task status to </span>
              <span style={customStyle.featureStyle}>Done</span>
              <span> first.</span>
            </>
          ),
        })
      } else if (!isReporter && value === 'COMPLETED') {
        setAlertEditTask({
          ...alertEditTask,
          open: true,
          feature: 'Unable to Change Status to Complete',
          message: 'Only reporter who can change task status to complete.',
        })
      } else {
        setStatusTask({...statusTask, status: event.target.value})
      }
    }

    return (
      <div style={customStyle.flexFull} key={i}>
        <div style={customStyle.dynamicWidth}>
          <InformationContainer>
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Nama {GLOBAL_GLOSSARY.performance.Goal}
              </GreyTypography>
              <div style={{display: 'flex'}}>
                <Typography
                  variant="body1"
                  style={{color: '#039be5', marginRight: '0.5rem'}}
                >
                  [PF-{isGoal ? res.id : res.performance_goal.id}]
                </Typography>
                <Typography variant="body1">
                  {isGoal ? res.name : res.performance_goal.name}
                </Typography>
              </div>
            </InformationContainer>
            {isGoal ? (
              <>
                <InformationContainer>
                  <GreyTypography variant="caption" gutterBottom>
                    Status
                  </GreyTypography>
                  <div style={{display: 'flex'}}>
                    <Typography
                      variant="body1"
                      style={
                        status === TASK_STATUS.COMPLETED
                          ? {
                              color: '#4caf50',
                            }
                          : {
                              color: '#039be5',
                            }
                      }
                    >
                      {status}
                    </Typography>
                  </div>
                </InformationContainer>
                <InformationContainer>
                  <GreyTypography variant="caption" gutterBottom>
                    Tipe {GLOBAL_GLOSSARY.performance.Goal}
                  </GreyTypography>
                  <div style={{display: 'flex'}}>
                    <Typography variant="body1">
                      {`${
                        typeGoal
                          ? GLOBAL_GLOSSARY.performance['Company Goal']
                          : GLOBAL_GLOSSARY.performance['Personal Goal']
                      } ${dataKpi?.type ? '-' : ''} ${goalType[dataKpi?.type] ??
                        ''}`}
                    </Typography>
                  </div>
                </InformationContainer>
              </>
            ) : (
              <>
                <InformationContainer>
                  <GreyTypography variant="caption" gutterBottom>
                    Nama {GLOBAL_GLOSSARY.performance.Task}
                  </GreyTypography>
                  <div style={{display: 'flex'}}>
                    <Typography
                      variant="body1"
                      style={{color: '#039be5', marginRight: '0.5rem'}}
                    >
                      [PF-{res.id}]
                    </Typography>
                    <Typography variant="body1">{res.name}</Typography>
                  </div>
                </InformationContainer>
                <InformationContainer>
                  <GreyTypography variant="caption" gutterBottom>
                    Status
                  </GreyTypography>
                  <div style={{display: 'flex'}}>
                    {editStatus ? (
                      <>
                        <TextField
                          size="small"
                          id="select-status"
                          select
                          variant="outlined"
                          value={statusTask?.status}
                          style={{width: '50%', marginTop: '5px'}}
                          onChange={event =>
                            handleAlertEdit(event, res?.status)
                          }
                        >
                          {STATUS_OPTIONS.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <Button
                          style={customStyle.buttonStyle}
                          onClick={handleCancel}
                        >
                          Batal
                        </Button>
                        <Button
                          onClick={handleConfirm}
                          variant="contained"
                          color="secondary"
                          style={customStyle.buttonStyle}
                        >
                          Simpan
                        </Button>
                      </>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          style={customStyle.editText}
                        >
                          {TASK_STATUS[res?.status]}
                        </Typography>
                        {res?.status !== 'ARCHIVED' && canEdit && (
                          <EditIcon
                            onClick={() =>
                              handleEditStatus(
                                res?.id,
                                res?.status,
                                res?.progress
                              )
                            }
                            style={customStyle.editStyle}
                          />
                        )}
                      </>
                    )}
                  </div>
                </InformationContainer>
                <InformationContainer>
                  <GreyTypography variant="caption" gutterBottom>
                    Tipe {GLOBAL_GLOSSARY.performance.Task}
                  </GreyTypography>
                  <div style={{display: 'flex'}}>
                    <Typography variant="body1">
                      {`${
                        typeGoal
                          ? GLOBAL_GLOSSARY.performance['Company Goal']
                          : GLOBAL_GLOSSARY.performance['Personal Goal']
                      } ${res?.method_type ? '-' : ''} ${goalType[
                        res?.method_type
                      ] ?? ''}`}
                    </Typography>
                  </div>
                </InformationContainer>
              </>
            )}

            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Aspek {GLOBAL_GLOSSARY.performance.Goal} Individu
              </GreyTypography>
              <Typography variant="body1">
                {isGoal
                  ? dataKpi?.kpi_aspect?.name || '-'
                  : res.kpi_aspect?.name || dataKpi?.kpi_aspect?.name || '-'}
              </Typography>
            </InformationContainer>

            <GreyTypography variant="caption" gutterBottom>
              Perspektif
            </GreyTypography>
            <Typography variant="body1">
              {dataKpi?.kpi_perspective?.name ?? '-'}
            </Typography>
          </InformationContainer>
          {/* <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Weight
            </GreyTypography>
            <Typography variant="body1">{res.weight}%</Typography>
          </InformationContainer> */}
          {type === 'taskDetail' && (
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Visibility
              </GreyTypography>
              <Typography variant="body1">
                {res?.visibility !== ' '
                  ? visibility[res.visibility] || capitalize(res.visibility)
                  : '-'}
              </Typography>
            </InformationContainer>
          )}
          <InformationContainer>
            <FlexBetween>
              <GreyTypography
                variant="caption"
                gutterBottom
                style={{marginBottom: 0}}
              >
                {`Cascaded ${isGoal ? 'From' : 'To'}`}
              </GreyTypography>
              <AddIcon
                style={customStyle.addCascade}
                onClick={handleAddCascade}
              />
            </FlexBetween>
            {isCascade
              ? dataCascade?.performance_cascadings?.map((data, key) => {
                  const dataCascading = isGoal
                    ? data.performance_task
                    : data.performance_goal
                  return (
                    <ChipCascade style={{marginTop: 10}} key={key}>
                      <FlexBetween>
                        <div style={{display: 'flex', marginRight: 5}}>
                          <Typography
                            component="span"
                            style={customStyle.cascadeId}
                          >
                            {`[PF-${dataCascading?.id}]`}
                          </Typography>
                          <Typography component="span" style={{fontSize: 12}}>
                            {dataCascading?.name
                              ? trimString(dataCascading?.name, 45)
                              : '-'}
                          </Typography>
                        </div>
                        {canEditCascade && (
                          <CloseIcon
                            style={customStyle.closeCascade}
                            onClick={() =>
                              setDeleteCascade({
                                open: true,
                                id: data?.id,
                              })
                            }
                          />
                        )}
                      </FlexBetween>
                    </ChipCascade>
                  )
                })
              : '-'}
            <Typography style={customStyle.seeMore} onClick={handleSeeMore}>
              {loading
                ? 'Loading...'
                : totalCascade > 3
                ? limit < totalCascade
                  ? 'See More'
                  : 'See Less'
                : ''}
            </Typography>
          </InformationContainer>
        </div>
        <div style={customStyle.dynamicWidth}>
          <InformationContainer style={customStyle.flex}>
            <div style={customStyle.PR}>
              <GreyTypography variant="caption" gutterBottom>
                Tanggal Mulai
              </GreyTypography>
              <Typography variant="body1">{formattedStartDate}</Typography>
            </div>
            <div>
              <GreyTypography variant="caption" gutterBottom>
                Tanggal Berakhir
              </GreyTypography>
              <Typography variant="body1">{formattedEndDate}</Typography>
            </div>
          </InformationContainer>
          {type !== 'taskDetail' && !isGoal && (
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Visibilitas
              </GreyTypography>
              <Typography variant="body1">
                {res?.visibility !== ' '
                  ? visibility[res.visibility] || capitalize(res.visibility)
                  : '-'}
              </Typography>
            </InformationContainer>
          )}

          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Prioritas
            </GreyTypography>
            <Typography variant="body1">{priority[res.priority]}</Typography>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Metrik
            </GreyTypography>
            <Typography variant="body1">
              {isGoal
                ? dataKpi?.formula || '-'
                : res.metric || dataKpi?.formula || '-'}
            </Typography>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Sifat Penilaian
            </GreyTypography>
            <Typography variant="body1">
              {dataKpi?.kpi_scoring_nature?.name ?? '-'}
            </Typography>
          </InformationContainer>
          <InformationContainer>
            <GreyTypography variant="caption" gutterBottom>
              Satuan Target
            </GreyTypography>
            <Typography variant="body1">
              {isGoal
                ? dataKpi?.kpi_weight_unit?.name || '-'
                : res.kpi_weight_unit?.name ||
                  dataKpi?.kpi_weight_unit?.name ||
                  '-'}
            </Typography>
          </InformationContainer>
          <InformationContainer style={customStyle.flex}>
            <div style={customStyle.PR}>
              <GreyTypography variant="caption" gutterBottom>
                {isGoal
                  ? GLOBAL_GLOSSARY.performance['Target Base']
                  : `Target ${GLOBAL_GLOSSARY.performance.Task}`}
              </GreyTypography>
              <Typography variant="body1">
                {res.target} {res?.kpi_weight_unit?.name}
              </Typography>
            </div>

            {/* <div>
              <GreyTypography variant="caption" gutterBottom>
                Stretched Target
              </GreyTypography>
              <Typography variant="body1">
                {res.target_stretch}{' '}
                {res.kpi_weight_unit && res.kpi_weight_unit.name}
              </Typography>
            </div> */}
          </InformationContainer>
          {type === 'taskDetail' && (
            <InformationContainer>
              <GreyTypography variant="caption" gutterBottom>
                Kemajuan
              </GreyTypography>
              <Typography variant="body1">
                {`${res.progress} (${
                  res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : '0'
                }%)`}
              </Typography>
            </InformationContainer>
          )}
        </div>
        {type === 'taskDetail' && (
          <div style={{width: '30%'}}>
            <div style={customStyle.assignedWrapper}>
              <GreyTypography variant="caption">Assigned To</GreyTypography>
              {res?.performance_task_assignments?.map((assignee, index) => {
                return (
                  <div
                    style={{display: 'flex', padding: '1rem 0 '}}
                    key={index}
                  >
                    <Avatar
                      alt={assignee?.global_user?.name ?? '-'}
                      style={customStyle.buttonSpacer}
                      src={assignee?.global_user?.avatar}
                    />
                    <div>
                      <Typography variant="body2" color="secondary">
                        {assignee?.global_user?.name}
                      </Typography>
                      <Typography variant="caption">
                        {assignee?.global_user?.people_work_placements?.[0]
                          ?.company_job_profile?.title ?? '-'}
                      </Typography>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <CascadePopup
          open={addCascade}
          handleClose={() => setAddCascade(false)}
          isGoal={isGoal}
          id={dynamicData?.[0]?.id}
          tempCascade={tempCascade}
          refetchCascade={refetch}
        />
        <ModalDelete
          open={deleteCascade.open}
          handleClose={() => setDeleteCascade(INITIAL_DELETE_CASCADE)}
          name="this link"
          titleCapitalize={false}
          isHighlight={false}
          mutation={onDeleteCascade}
          canClose={false}
        />
        <ConfirmationPopup
          open={alertEditTask.open}
          handleClose={() => setAlertEditTask({...alertEditTask, open: false})}
          feature={alertEditTask.feature}
          message={alertEditTask.message}
          type="Confirm"
          optional={alertEditTask.children}
          mutation={handleMutation}
          disabled={isCompleted && !statusTask.comment}
        />
      </div>
    )
  })
}
