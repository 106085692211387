import gql from 'graphql-tag'

export const GET_LIST_GOAL = gql`
  query getListGoal($search: String, $limit: Int) {
    performance_goals(where: {name: {_ilike: $search}}, limit: $limit) {
      id
      name
      status
      description
      priority
      target
      target_stretch
      weight
      startdate
      enddate
      status
      visibility
      attachment
      created_by
      kpi_list {
        id
        name
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists {
          id
        }
      }

      performance_tasks {
        id
        weight
        progress
        performance_task_assignments {
          id
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`

export const GET_SEE_ALL_MYGOAL = gql`
  query listMyGoal(
    $id: uuid!
    $limit: Int
    $offset: Int
    $search: String
    $latest: order_by
  ) {
    performance_goals(
      where: {
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $id}}
            }
          }
          {created_by: {_eq: $id}}
        ]
        name: {_ilike: $search}
      }
      limit: $limit
      offset: $offset
      order_by: [{date_added: $latest}]
    ) {
      id
      name
      status
      description
      priority
      target
      target_stretch
      weight
      startdate
      enddate
      status
      visibility
      attachment
      created_by
      performance_tasks {
        id
        weight
        target
        progress
        performance_task_assignments(distinct_on: [user]) {
          global_user {
            id
            name
            avatar
          }
        }
      }
      kpi_list {
        id
        name
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists {
          id
        }
      }
    }
  }
`
export const GET_PERFORMANCE = gql`
  query($user: uuid) {
    index_score: talent_assignments(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          visibility: {_eq: "PUBLISH"}
          classification_type: {_eq: "PERFORMANCE"}
        }
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      talent_score
    }
    total_goal: performance_goals_aggregate(
      where: {
        kpi_list: {
          _or: [
            {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
            {_not: {kpi_cascading_lists: {}}}
          ]
        }
        performance_tasks: {performance_task_assignments: {user: {_eq: $user}}}
      }
    ) {
      aggregate {
        count
      }
      nodes {
        total: performance_tasks_aggregate {
          aggregate {
            count
          }
        }
        complete: performance_tasks_aggregate(
          where: {status: {_in: ["COMPLETED", "ARCHIVED", "COMPLETE"]}}
        ) {
          aggregate {
            count
          }
        }
      }
    }

    total_task: performance_tasks_aggregate(
      where: {
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
      }
    ) {
      aggregate {
        count
      }
    }
    task_todo: performance_tasks_aggregate(
      where: {
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
        status: {_in: ["TODO"]}
      }
    ) {
      aggregate {
        count
      }
    }
    task_inprogress: performance_tasks_aggregate(
      where: {
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
        status: {_in: ["INPROGRESS"]}
      }
    ) {
      aggregate {
        count
      }
    }
    task_done: performance_tasks_aggregate(
      where: {
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
        status: {_in: ["DONE"]}
      }
    ) {
      aggregate {
        count
      }
    }
    task_completed: performance_tasks_aggregate(
      where: {
        performance_goal: {
          kpi_list: {
            _or: [
              {kpi_cascading_lists: {cascading_main: {status: {_eq: "ACTIVE"}}}}
              {_not: {kpi_cascading_lists: {}}}
            ]
          }
        }
        performance_task_assignments: {user: {_eq: $user}}
        status: {_in: ["COMPLETED", "ARCHIVED"]}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_CONTAINER_GOAL = gql`
  query getLazyGoal($id: bigint!) {
    performance_goals_by_pk(id: $id) {
      id
      name
      status
      priority
      progress_percentage
      created_by
      startdate
      enddate
      performance_goal_group {
        id
        name
        color
      }
      performance_tasks {
        id
        performance_task_assignments {
          id
          global_user {
            id
            name
            avatar
          }
        }
      }
      kpi_list {
        id
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
      total_task: performance_tasks_aggregate {
        aggregate {
          count
        }
      }

      complete_task: performance_tasks_aggregate(
        where: {status: {_in: ["COMPLETED", "ARCHIVED"]}}
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_DETAIL_CONTAINER_GOAL = gql`
  query getLazyGoal($id: bigint!) {
    performance_goals_by_pk(id: $id) {
      id
      name
      status
      description
      priority
      progress
      target
      target_stretch
      visibility
      progress_percentage
      created_by
      startdate
      enddate
      attachment
      kpi_list {
        id
        name
        type
        formula
        description
        kpi_scoring_nature {
          id
          name
        }
        kpi_weight_unit {
          id
          name
        }
        kpi_aspect {
          id
          name
        }
        kpi_level {
          id
          name
        }
        kpi_version {
          id
          name
        }
        kpi_method {
          id
          name
        }
        kpi_monitoring_frequency {
          id
          name
        }
        kpi_perspective {
          id
          name
        }
        kpi_cascading_lists {
          id
        }
      }
      performance_goal_group {
        id
        name
        color
      }
      performance_tasks {
        id
        performance_task_assignments {
          id
          global_user {
            id
            name
            avatar
          }
        }
      }
    }
  }
`

export const GET_LIST_GOAL_ASSIGN_TASK = gql`
  query($company: uuid, $user: uuid, $search: String) {
    performance_goals(
      where: {
        company: {_eq: $company}
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $user}}
            }
            kpi_list: {
              _or: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {status: {_eq: "ACTIVE"}}
                  }
                }
                {_not: {kpi_cascading_lists: {}}}
              ]
            }
            name: {_ilike: $search}
          }
          {
            name: {_ilike: $search}
            kpi_list: {
              _or: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {status: {_eq: "ACTIVE"}}
                  }
                }
                {_not: {kpi_cascading_lists: {}}}
              ]
            }
            global_user: {
              people_work_placements: {
                company_job_profile: {
                  company_organization: {
                    company_job_profiles: {
                      people_work_placements: {user: {_eq: $user}}
                    }
                  }
                }
              }
            }
          }
        ]
      }
      limit: 30
    ) {
      id
      name
    }
  }
`

export const GET_LIST_ALL_MY_GOAL = gql`
  query getListGoals(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $filterStatus: [performance_goals_bool_exp]
    $label: [uuid!]
  ) {
    total: performance_goals_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $user}}
            }
          }
          {created_by: {_eq: $user}}
        ]
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
    ) {
      aggregate {
        count
      }
    }
    performance_goals_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $user}}
            }
          }
          {created_by: {_eq: $user}}
        ]
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        _and: $filterStatus
      }
      offset: $offset
      limit: $limit
    ) {
      aggregate {
        count
      }
    }
    performance_goals(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        _or: [
          {
            performance_tasks: {
              performance_task_assignments: {user: {_eq: $user}}
            }
          }
          {created_by: {_eq: $user}}
        ]
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
      kpi_list {
        id
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
          nodes {
            kpi_main {
              id
              status
            }
          }
        }
      }
    }
  }
`

export const GET_LIST_ALL_DEPARTMENT_GOAL = gql`
  query getListGoalDepartement(
    $offset: Int
    $limit: Int
    $status: [String!]
    $company: uuid!
    $search: String
    $user: uuid!
    $order: order_by
    $creator: [uuid!]
    $reporter: [uuid!]
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $type: [Int!]
    $priority: [enum_mpp_priority!]
    $progressPersenFrom: float8
    $progressPersenTo: float8
    $dateFrom: date
    $dateTo: date
    $aspect: [Int!]
    $perspective: [Int!]
    $scoringNature: [Int!]
    $weightUnit: [Int!]
    $filterStatus: [performance_goals_bool_exp]
    $label: [uuid!]
  ) {
    total: performance_goals_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        global_user: {
          people_work_placements: {
            company: {_eq: $company}
            company_job_profile: {
              company_organization: {
                company_job_profiles: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
    ) {
      aggregate {
        count
      }
    }
    performance_goals_aggregate(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        global_user: {
          people_work_placements: {
            company: {_eq: $company}
            company_job_profile: {
              company_organization: {
                company_job_profiles: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
      offset: $offset
      limit: $limit
    ) {
      aggregate {
        count
      }
    }
    performance_goals(
      where: {
        status: {_in: $status}
        company: {_eq: $company}
        kpi_list: {
          aspect: {_in: $aspect}
          prespective: {_in: $perspective}
          scoring: {_in: $scoringNature}
          type: {_in: $type}
          _or: [
            {
              kpi_cascading_lists: {
                cascading_main: {
                  _and: [
                    {status: {_eq: "ACTIVE"}}
                    {company: {_eq: $company}}
                    {_not: {id: {_is_null: $isCompany}}}
                  ]
                }
              }
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        global_user: {
          people_work_placements: {
            company: {_eq: $company}
            company_job_profile: {
              company_organization: {
                company_job_profiles: {
                  people_work_placements: {
                    status: {_eq: "ACTIVE"}
                    user: {_eq: $user}
                  }
                }
              }
            }
          }
        }
        name: {_ilike: $search}
        created_by: {_in: $creator}
        reporter: {_in: $reporter}
        priority: {_in: $priority}
        progress_percentage: {
          _gte: $progressPersenFrom
          _lte: $progressPersenTo
        }
        startdate: {_gte: $dateFrom, _lte: $dateTo}
        target_unit: {_in: $weightUnit}
        group: {_in: $label}
        _and: $filterStatus
      }
      order_by: [{id: $order, date_added: desc_nulls_last}]
      offset: $offset
      limit: $limit
    ) {
      id
    }
  }
`
