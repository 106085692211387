import gql from 'graphql-tag'

export const GET_LIST_TEAM_MEMBER = gql`
  query listMyTeamPerformance(
    $company: uuid
    $user: uuid
    $search: String
    $offset: Int
    $limit: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {
            _and: [
              {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
          {
            _and: [
              {performance_teams: {added_by: {_eq: $user}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        _or: [
          {
            _and: [
              {
                company_job_profile: {
                  job_profile_supervisor: {
                    people_work_placements: {
                      user: {_eq: $user}
                      status: {_eq: "ACTIVE"}
                    }
                  }
                }
              }
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
          {
            _and: [
              {performance_teams: {added_by: {_eq: $user}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                ]
              }
            ]
          }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
        talent_assignments(
          where: {
            talentVersionByTalentVersion: {
              status: {_eq: "COMPLETED"}
              visibility: {_eq: "PUBLISH"}
              classification_type: {_eq: "PERFORMANCE"}
            }
            badge_classification: {_is_null: false}
          }
          order_by: [{id: desc}]
          limit: 1
        ) {
          id
          talent_box {
            id
            name
            badge
          }
          talent_score
          talentVersionByTalentVersion {
            id
            name
            start_date
            end_date
          }
        }
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_LIST_EXPANDED_TEAM_GOAL = gql`
  query listMyTeamGoal(
    $company: uuid!
    $member: uuid!
    $reporter: uuid_comparison_exp
    $creator: uuid_comparison_exp
    $isCompany: Boolean!
    $isPersonal: Boolean!
    $methodType: Int_comparison_exp
    $priority: enum_mpp_priority_comparison_exp
    $label: uuid_comparison_exp
    $search: String
    $isComplete: Boolean!
    $isTodo: Boolean!
    $period: date_comparison_exp
    $aspect: Int_comparison_exp
    $perspective: Int_comparison_exp
    $scoring: Int_comparison_exp
    $weightUnit: Int_comparison_exp
    $offset: Int!
    $limit: Int!
    $order: order_by
  ) {
    total: performance_goals_aggregate(
      where: {
        company: {_eq: $company}
        priority: $priority
        group: $label
        performance_tasks: {
          performance_task_assignments: {user: {_eq: $member}}
        }
        kpi_list: {
          aspect: $aspect
          prespective: $perspective
          scoring: $scoring
          weight: $weightUnit
          type: $methodType
          _or: [
            {
              _and: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {status: {_eq: "ACTIVE"}}
                  }
                }
                {_not: {id: {_is_null: $isCompany}}}
              ]
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        reporter: $reporter
        created_by: $creator
        name: {_ilike: $search}
        _or: [
          {
            _and: [
              {
                _not: {
                  performance_tasks: {
                    status: {
                      _in: [
                        "TODO"
                        "INPROGRESS"
                        "DONE"
                        "todo"
                        "inprogress"
                        "done"
                      ]
                    }
                  }
                }
              }
              {_not: {id: {_is_null: $isComplete}}}
            ]
          }
          {
            _and: [
              {
                performance_tasks: {
                  status: {
                    _in: [
                      "TODO"
                      "INPROGRESS"
                      "DONE"
                      "todo"
                      "inprogress"
                      "done"
                    ]
                  }
                }
              }
              {_not: {id: {_is_null: $isTodo}}}
            ]
          }
        ]
        startdate: $period
      }
    ) {
      aggregate {
        count
      }
    }
    performance_goals(
      where: {
        company: {_eq: $company}
        priority: $priority
        group: $label
        performance_tasks: {
          performance_task_assignments: {user: {_eq: $member}}
        }
        kpi_list: {
          aspect: $aspect
          prespective: $perspective
          scoring: $scoring
          weight: $weightUnit
          type: $methodType
          _or: [
            {
              _and: [
                {
                  kpi_cascading_lists: {
                    cascading_main: {status: {_eq: "ACTIVE"}}
                  }
                }
                {_not: {id: {_is_null: $isCompany}}}
              ]
            }
            {
              _and: [
                {_not: {kpi_cascading_lists: {}}}
                {_not: {id: {_is_null: $isPersonal}}}
              ]
            }
          ]
        }
        reporter: $reporter
        created_by: $creator
        name: {_ilike: $search}
        _or: [
          {
            _and: [
              {
                _not: {
                  performance_tasks: {
                    status: {
                      _in: [
                        "TODO"
                        "INPROGRESS"
                        "DONE"
                        "todo"
                        "inprogress"
                        "done"
                      ]
                    }
                  }
                }
              }
              {_not: {id: {_is_null: $isComplete}}}
            ]
          }
          {
            _and: [
              {
                performance_tasks: {
                  status: {
                    _in: [
                      "TODO"
                      "INPROGRESS"
                      "DONE"
                      "todo"
                      "inprogress"
                      "done"
                    ]
                  }
                }
              }
              {_not: {id: {_is_null: $isTodo}}}
            ]
          }
        ]
        startdate: $period
      }
      order_by: [{id: $order}]
      offset: $offset
      limit: $limit
    ) {
      id
      name
      startdate
      enddate
      global_user {
        id
        name
      }
      progress_percentage
      kpi_list {
        id
        kpi_cascading_lists_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`

export const GET_LIST_SUGESTION_SQUAD = gql`
  query(
    $company: uuid
    $user: uuid
    $search: String
    $offset: Int
    $limit: Int
    $nin: [Int!]
  ) {
    people_work_placements(
      where: {
        id: {_nin: $nin}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        user: {_neq: $user}
        _or: [
          {
            _and: [
              {_not: {performance_teams: {}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
              {
                company_job_profile: {
                  _or: [
                    {
                      job_profile_supervisor: {
                        _or: [
                          {
                            _not: {
                              people_work_placements: {
                                status: {_eq: "ACTIVE"}
                                user: {_eq: $user}
                              }
                            }
                          }
                          {_not: {people_work_placements: {}}}
                        ]
                      }
                    }
                    {_not: {job_profile_supervisor: {}}}
                  ]
                }
              }
            ]
          }
          {
            _and: [
              {_not: {performance_teams: {added_by: {_eq: $user}}}}
              {
                _or: [
                  {global_user: {name: {_ilike: $search}}}
                  {regno: {_ilike: $search}}
                  {company_job_profile: {title: {_ilike: $search}}}
                ]
              }
              {
                company_job_profile: {
                  _or: [
                    {
                      job_profile_supervisor: {
                        _or: [
                          {
                            _not: {
                              people_work_placements: {
                                status: {_eq: "ACTIVE"}
                                user: {_eq: $user}
                              }
                            }
                          }
                          {_not: {people_work_placements: {}}}
                        ]
                      }
                    }
                    {_not: {job_profile_supervisor: {}}}
                  ]
                }
              }
            ]
          }
        ]
      }
      offset: $offset
      limit: $limit
    ) {
      id
      regno
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
      }
    }
  }
`

export const GET_SQUAD_NAME = gql`
  subscription getSquadMember($user: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      placement_fields
    }
  }
`
