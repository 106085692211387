import gql from 'graphql-tag'

export const UPDATE_TASKS = gql`
  mutation updateTask(
    $name: String
    $description: String
    $target: float8
    $target_stretch: float8
    $weight: float8
    $priority: enum_mpp_priority!
    $progress: float8
    $visibility: String
    $startdate: date
    $enddate: date
    $group: bigint
    $id: bigint!
    $attachment: jsonb
    $assignment: [performance_task_assignments_insert_input!]!
    $changeRecepient: Boolean
  ) {
    update_performance_tasks(
      _set: {
        name: $name
        description: $description
        target: $target
        progress: $progress
        target_stretch: $target_stretch
        weight: $weight
        priority: $priority
        visibility: $visibility
        startdate: $startdate
        enddate: $enddate
        attachment: $attachment
        group: $group
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }

    delete_performance_task_assignments(
      where: {_not: {id: {_is_null: $changeRecepient}}, task: {_eq: $id}}
    ) {
      affected_rows
    }

    insert_performance_task_assignments(
      objects: $assignment
      on_conflict: {
        constraint: performance_task_assignments_task_user_key
        update_columns: user
      }
    ) {
      affected_rows
    }
  }
`
