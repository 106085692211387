import gql from 'graphql-tag'

export const TALENT_BADGE_SIDEBAR = gql`
  query($id: uuid) {
    talent_assignments(
      where: {
        user: {_eq: $id}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          visibility: {_eq: "PUBLISH"}
          classification_type: {_eq: "PERFORMANCE"}
        }
        badge_classification: {_is_null: false}
        people_work_placement: {deletedAt: {_is_null: true}}
      }
      limit: 3
      order_by: [{id: desc}]
    ) {
      id
      talent_score
      date_modified
      talent_box {
        id
        badge
        color_hex
        name
        description
        level
      }
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
      }
      indicator_pot_competence
      indicator_pot_attendance
      indicator_pot_total_course
      indicator_pot_socmed_post
      indicator_perf_goal
      indicator_perf_ogf
      indicator_perf_multirater
      indicator_perf_nps
      people_work_placement {
        id
        placement_fields
        company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const GET_DETAIL_USER_BADGE = gql`
  query($id: Int) {
    talent_assignments(where: {id: {_eq: $id}}) {
      id
      talent_score
      date_modified
      people_work_placement {
        id
        company_job_profile {
          id
          title
        }
      }
      talent_box {
        id
        badge
        color_hex
        name
        description
        level
      }
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
        box_height
        box_width
        talent_boxes {
          id
          badge
          color_hex
          name
          description
          level
          index_x
          index_y
        }
      }
      indicator_pot_competence
      indicator_pot_attendance
      indicator_pot_total_course
      indicator_pot_socmed_post
      indicator_perf_goal
      indicator_perf_ogf
      indicator_perf_multirater
      indicator_perf_nps
    }
  }
`

export const GET_ALL_USER_BADGE = gql`
  query($user: uuid, $offset: Int, $limit: Int) {
    talent_assignments(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          visibility: {_eq: "PUBLISH"}
          classification_type: {_eq: "PERFORMANCE"}
        }
        people_work_placement: {deletedAt: {_is_null: true}}
        badge_classification: {_is_null: false}
      }
      order_by: [{id: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
      }
      talent_box {
        id
        name
        badge
        color_hex
      }
      talent_score
      people_work_placement {
        id
        company_job_profile {
          id
          title
        }
      }
    }
  }
`

export const VERSION_COMMITTEE_TASK = gql`
  query GetTalentComitteTask(
    $user: uuid
    $shortName: order_by
    $shortDate: order_by
  ) {
    talent_committees(
      where: {
        committee: {_eq: $user}
        talent_candidate_group: {
          committee_date_start: {_lte: "now()"}
          committee_date_end: {_gte: "now()"}
          talent_version: {
            status: {_eq: "INEVALUATION"}
            classification_type: {_eq: "PERFORMANCE"}
          }
        }
      }
      order_by: [
        {
          talent_candidate_group: {
            talent_version: {name: $shortName}
            committee_date_start: $shortDate
            committee_date_end: $shortDate
          }
        }
      ]
    ) {
      talent_candidate_group {
        id
        committee_date_start
        committee_date_end
        talent_version {
          id
          name
          status
          flexy_components
        }
        company_job_profile {
          id
          title
          company_profile {
            id
            legal_name
            brand_name
            logo
          }
        }
      }
    }
  }
`

export const GET_LIST_TALENT_LIST = gql`
  query(
    $job_profile: [Int!]
    $version: uuid
    $badge: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $office: [Int!]
    $aspiration: [Int!]
    $filter: Boolean
    $orderName: order_by
    $orderScore: order_by
    $searchName: String
    $orderLatest: order_by
    $offset: Int
    $limit: Int
  ) {
    talent_assignments(
      where: {
        global_user: {name: {_ilike: $searchName}}
        talent_version: {_eq: $version}
        badge_classification: {_in: $badge, _is_null: false}
        people_work_placement: {
          status: {_eq: "ACTIVE"}
          company_job_profile: {
            _and: [
              {organization: {_in: $organization}}
              {position: {_in: $position}}
              {office_address: {_in: $office}}
              {id: {_in: $job_profile}}
            ]
          }
        }
        _and: [
          {
            _or: [
              {
                people_work_placement: {
                  talent_aspirations: {aspired_job: {_in: $aspiration}}
                }
              }
              {
                _and: [
                  {_not: {people_work_placement: {talent_aspirations: {}}}}
                  {id: {_is_null: $filter}}
                ]
              }
            ]
          }
        ]
      }
      order_by: [
        {talent_score: $orderScore}
        {global_user: {name: $orderName}}
        {date_modified: $orderLatest}
      ]
      offset: $offset
      limit: $limit
    ) {
      people_work_placement {
        id
        regno
        company_date: start_date
        placement_fields
        talent_development_plans(limit: 1, order_by: [{id: desc}]) {
          talent_development_competencies {
            id
            competency_dictionary {
              id
              name
            }
          }
          id
          name
          measure_of_success
          start_date
          end_date
          recommended_action
          status
        }
        global_user {
          id
          name
          email
          avatar
        }
        current_position: company_job_profile {
          company
          id
          title
          job_icon
          job_summary
          job_description
          experience_level
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
        talent_aspirations(limit: 1, order_by: [{id: desc}]) {
          company_job_profile {
            id
            title
            job_icon
            job_description
            job_summary
            company_job_profile_r_educations {
              id
              global_degree {
                id
                name
              }
              global_faculty {
                id
                name
              }
            }
            company_job_profile_competencies {
              id
              competency_dictionary {
                id
                name
              }
              competency_category {
                id
                name
                description
              }
              competency_version {
                id
                name
              }
              level
            }
            experience_level
            company_organization {
              id
              name
            }
            company_employee_position {
              id
              name
            }
            company_employee_rank {
              id
              name
            }
            company_address {
              id
              office_name
            }
          }
        }
        talent_assignments(
          where: {talent_version: {_eq: $version}}
          order_by: [{id: desc}]
          limit: 1
        ) {
          id
          talent_score
          date_modified
          talent_box {
            id
            name
            badge
            color_hex
            level
          }
        }
      }
    }
  }
`

export const GET_TALENT_BOXES = gql`
  query(
    $id: uuid
    $job_profile: [Int!]
    $organization: [Int!]
    $position: [Int!]
    $office: [Int!]
    $aspiration: [Int!]
    $filter: Boolean
    $search: String
  ) {
    talent_versions(where: {id: {_eq: $id}}) {
      id
      name
      box_height
      box_width
      status
      indicator_perf_nps
      indicator_perf_ogf
      indicator_perf_goal
      indicator_perf_multirater
      indicator_pot_attendance
      indicator_pot_competence
      indicator_pot_socmed_post
      indicator_pot_total_course
      indicator_perf_nps_fields
      indicator_perf_ogf_fields
      indicator_perf_goal_fields
      indicator_perf_multirater_fields
      indicator_pot_attendance_fields
      indicator_pot_competence_fields
      indicator_pot_socmed_post_fields
      indicator_pot_total_course_fields
      flexy_components
      talent_boxes(order_by: [{index_y: desc}, {index_x: asc}]) {
        id
        name
        level
        badge
        color_hex
        index_x
        index_y
        description
        talent_assignments_aggregate(
          where: {
            global_user: {name: {_ilike: $search}}
            badge_classification: {_is_null: false}
            people_work_placement: {
              status: {_eq: "ACTIVE"}
              company_job_profile: {
                _and: [
                  {organization: {_in: $organization}}
                  {position: {_in: $position}}
                  {office_address: {_in: $office}}
                  {id: {_in: $job_profile}}
                ]
              }
            }
            _and: [
              {
                _or: [
                  {
                    people_work_placement: {
                      talent_aspirations: {aspired_job: {_in: $aspiration}}
                    }
                  }
                  {
                    _and: [
                      {_not: {people_work_placement: {talent_aspirations: {}}}}
                      {id: {_is_null: $filter}}
                    ]
                  }
                ]
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      min_pot
      min_perf
      max_pot
      max_perf
    }

    # job_profile: company_job_profiles(
    #   where: {talent_candidate_groups: {talent_version: {id: {_eq: $id}}}}
    #   distinct_on: id
    # ) {
    #   id
    #   title
    # }

    organization: company_job_profiles(
      where: {talent_candidate_groups: {talent_version: {id: {_eq: $id}}}}
      distinct_on: organization
    ) {
      company_organization {
        id
        name
      }
    }

    position: company_job_profiles(
      where: {talent_candidate_groups: {talent_version: {id: {_eq: $id}}}}
      distinct_on: position
    ) {
      company_employee_position {
        id
        name
      }
    }

    office: company_job_profiles(
      where: {talent_candidate_groups: {talent_version: {id: {_eq: $id}}}}
      distinct_on: office_address
    ) {
      company_address {
        id
        office_name
      }
    }

    # aspiration: talent_aspirations(
    #   where: {
    #     people_work_placement: {
    #       company_job_profile: {
    #         talent_candidate_groups: {talent_version: {id: {_eq: $id}}}
    #       }
    #     }
    #   }
    # ) {
    #   company_job_profile {
    #     id
    #     title
    #   }
    # }
  }
`

export const USER_WORK = gql`
  query($user: uuid!, $company: uuid!) {
    people_work_placements(
      where: {user: {_eq: $user}, company: {_eq: $company}}
    ) {
      id
    }
  }
`

export const TALENT_LIST_HOME = gql`
  query($user: uuid, $version: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      regno
      start_date
      end_date
      global_user {
        id
        name
        email
        avatar
        past_badge: talent_assignments(
          where: {
            badge_classification: {_is_null: false}
            talentVersionByTalentVersion: {
              status: {_eq: "COMPLETED"}
              visibility: {_eq: "PUBLISH"}
              id: {_neq: $version}
              classification_type: {_eq: "PERFORMANCE"}
            }
          }
        ) {
          id
          talent_score
          talent_box {
            id
            name
            badge
            color_hex
            level
          }
          talentVersionByTalentVersion {
            id
            name
            start_date
            end_date
          }
          people_work_placement {
            id
            company_job_profile {
              id
              title
            }
          }
        }
      }
      placement_fields
      talent_development_plans {
        talent_development_competencies {
          id
          competency_dictionary {
            id
            name
          }
        }
        id
        name
        measure_of_success
        start_date
        end_date
        recommended_action
        status
      }
      current_position: company_job_profile {
        company
        id
        title
        job_icon
        job_summary
        job_description
        experience_level
        company_organization {
          id
          name
        }
        company_employee_position {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
        }
      }
      talent_aspirations {
        id
        planned_date
        company_job_profile {
          id
          title
          job_icon
          job_description
          job_summary
          company_organization {
            id
            name
          }
          company_employee_position {
            id
            name
          }
          company_employee_rank {
            id
            name
          }
          company_address {
            id
            office_name
            address
          }
          company_job_profile_r_educations {
            id
            global_degree {
              id
              name
            }
            global_faculty {
              id
              name
            }
          }
          company_job_profile_competencies {
            id
            competency_dictionary {
              id
              name
            }
            competency_category {
              id
              name
              description
            }
            competency_version {
              id
              name
            }
            level
          }
          experience_level
        }
      }
      talent_assignments(
        where: {
          talentVersionByTalentVersion: {
            status: {_in: ["INEVALUATION", "COMPLETED"]}
            visibility: {_eq: "PUBLISH"}
            id: {_eq: $version}
            classification_type: {_eq: "PERFORMANCE"}
          }
        }
        offset: 0
        limit: 1
        order_by: [{id: desc}]
      ) {
        id
        talent_score
        indicator_pot_competence
        indicator_pot_attendance
        indicator_pot_total_course
        indicator_pot_socmed_post
        indicator_perf_goal
        indicator_perf_ogf
        indicator_perf_multirater
        indicator_perf_nps
        talentVersionByTalentVersion {
          id
          name
          start_date
          end_date
          flexy_components
          status
        }
        talent_box {
          id
          name
          badge
          color_hex
          level
        }
        people_work_placement {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_LESS_COMMITTEE_GROUP = gql`
  query popUpCommittee($group: Int!) {
    pic: talent_committees(
      where: {candidate_group: {_eq: $group}, is_pic: {_eq: true}}
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }

    committee: talent_committees(
      where: {candidate_group: {_eq: $group}, is_pic: {_eq: false}}
    ) {
      id
      global_user {
        id
        name
        avatar
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }

    total_committee: talent_committees_aggregate(
      where: {candidate_group: {_eq: $group}, is_pic: {_eq: false}}
    ) {
      aggregate {
        count
      }
    }
  }
`
