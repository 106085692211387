import React from 'react'

import {Button} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'

import GoalsComponent from './goal-components/sections/GoalsComponent'
import TasksComponent from './goal-components/sections/TasksComponent'
import {QuickFilter} from './PerformanceStyles'
import {
  QUICK_FILTER_DEPARTMENT,
  QUICK_FILTER_PERSONAL,
} from './goal-components/constant'
import {GLOBAL_GLOSSARY} from '../../utils/constant'

const useStyles = makeStyles(() => ({
  wrapQuickFilter: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 0px 30px',
  },
  buttonFilter: {
    marginRight: 20,
  },
  labelButtonFilter: {
    fontWeight: '600',
  },
}))

const QUICK_FILTER = [
  {active: QUICK_FILTER_PERSONAL, name: 'Hasil Kerja Saya'},
  {
    active: QUICK_FILTER_DEPARTMENT,
    name: GLOBAL_GLOSSARY.performance['Company Goal'],
  },
]

const Goals = props => {
  const {history, match} = props
  const {type} = match.params
  const classes = useStyles()

  const handleChangeType = (event, active) => {
    event.preventDefault()
    history.push({pathname: `/goals/${active}`})
  }

  return (
    <>
      <div className={classes.wrapQuickFilter}>
        <QuickFilter>Filter cepat:</QuickFilter>
        <div>
          {QUICK_FILTER?.map(res => (
            <Button
              key={res.active}
              color="primary"
              disableElevation
              variant={type === res.active ? 'contained' : 'text'}
              onClick={e => handleChangeType(e, res.active)}
              classes={{
                root: classes.buttonFilter,
                label: classes.labelButtonFilter,
              }}
            >
              {res.name}
            </Button>
          ))}
        </div>
      </div>

      <GoalsComponent active={type} />
      <TasksComponent active={type} />
    </>
  )
}

export default withRouter(Goals)
