import React, {useState} from 'react'
import {
  Button,
  CircularProgress,
  Divider,
  Icon,
  // Icon,
  // IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import HeaderGenerate from '../../../../components/header/HeaderGenerate'
import {USER_ID} from '../../../../utils/globals'
import {FlexCenter, IconFeedback} from '../../PerformanceStyles'
import {GET_REPORT_OGF} from '../../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import moment from 'moment'
import {withRouter} from 'react-router-dom'
// import {generatePdf} from '../../../../utils/helpers'

const useStyles = makeStyles({
  emojiBuble: {
    borderRadius: '40px',
    width: '24%',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    justifyContent: 'space-between',
    border: '1px solid #eff2f4',
  },
  commentContainer: {
    marginTop: '1rem',
    padding: '1rem 1.6rem',
    borderRadius: 5,
    border: 'solid 1px #eff2f4',
  },
  dividerComment: {margin: '1rem -1.6rem'},
  feedbacktype: {marginBottom: '3rem'},
  dividerHEader: {height: '4px!important', background: '#eff2f4'},
  dividerFooter: {
    height: '10px!important',
    background: '#014a62',
    margin: '0 -2rem -2rem',
  },
  textComment: {width: '21%', padding: '0 2%'},
  container: {width: 720, minHeight: '100vh', margin: 'auto'},
  containerRoot: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'center',
  },
  loadingProgress: {
    position: 'absolute',
    top: '50%',
    width: '100%',
    textAlign: 'center',
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
  },
  textFooter: {fontSize: '0.875rem', color: '#a9a8a8', lineHeight: '15px'},
  lineBottom: {borderBottom: '10px solid #014a62'},
})

const GenerateReport = props => {
  const {from, to} = props.match.params
  const filterDate = (tanggal,type) => {
    if (tanggal !== 'Invalid date' && tanggal !== null) {
      const hours = type  ==='from'? '00:00:01': '23:59:59'
      const day = new Date(`${tanggal} ${hours}`);
      // const newDateTo = filter.dateTo.split('-')
      // const newDay = parseInt(newDateTo[2]) + 1
      // return `${newDateTo[0]}-${newDateTo[1]}-${newDay}`
      return  moment(day, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }

  const classes = useStyles()
  const {data, error} = useQuery(GET_REPORT_OGF, {
    variables: {
      user: USER_ID,
      from :filterDate(from,'from'),
      to : filterDate(to,'to'),
    },
  })

  const [buttonVisible, setButtonVisible] = useState(true)
  const handlePrints = () => {
    setButtonVisible(false)
    document.title = `${data.user[0].global_user.name}_${
      data.user[0].regno
    }_${moment().format('LL')}_Report Summary Ongoing Feedback`
    setTimeout(() => {
      window.print()
      setButtonVisible(true)
      document.title = 'WLB - Performance'
    }, 500)
  }

  // const [avaFeedback, setAvaFeedback] = useState([])
  // const [avaManager, setAvaManager] = useState([])
  // const [avaSubordinate, setAvaSubordinate] = useState([])
  // const [mainAvatar, setMainAvatar] = useState('')
  // const [loading, setLoading] = useState(false)

  // const convertImgToBase64 = (url, type, id) => {
  //   const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
  //   var xhr = new XMLHttpRequest()
  //   xhr.open('GET', proxyUrl + url)
  //   xhr.responseType = 'blob'
  //   xhr.send()
  //   xhr.addEventListener('load', function() {
  //     var reader = new FileReader()
  //     reader.readAsDataURL(xhr.response)
  //     reader.addEventListener('loadend', function() {
  //       if (type === 'feedback') {
  //         setAvaFeedback(prev => [...prev, {id, avatar: reader.result}])
  //       } else if (type === 'manager') {
  //         setAvaManager(prev => [...prev, {id, avatar: reader.result}])
  //       } else if (type === 'subordinate') {
  //         setAvaSubordinate(prev => [...prev, {id, avatar: reader.result}])
  //       } else {
  //         setMainAvatar(reader.result)
  //       }
  //     })
  //   })
  // }

  // useEffect(() => {
  //   if (data) {
  //     const loopEachImg = (array, type) => {
  //       array.forEach(res =>
  //         convertImgToBase64(
  //           res.global_user_from.avatar,
  //           type,
  //           res.global_user_from.id
  //         )
  //       )
  //     }

  //     loopEachImg(data.manager, 'manager')
  //     loopEachImg(data.feedback, 'feedback')
  //     loopEachImg(data.subordinate, 'subordinate')
  //     convertImgToBase64(user.avatar, 'main')
  //     setLoading(true)
  //     setTimeout(() => {
  //       generatePdf(
  //         document.getElementById('toPdf'),
  //         `${data.user[0].global_user.name}_${
  //           data.user[0].regno
  //         }_${moment().format('LL')}_Report Summary Ongoing Feedback`
  //       )
  //       setLoading(false)
  //     }, [4000])

  //     setTimeout(() => {
  //       setLoading(false)
  //     }, [3900])
  //   }
  // }, [data])

  const feedbacks = (
    res,
    i
    // stateAvatar
  ) => {
    // const index = stateAvatar.findIndex(i => i.id === res.global_user_from.id)

    // const avatars =
    //   stateAvatar.length > 0 && stateAvatar[index] && stateAvatar[index].avatar
    return (
      <div className={classes.commentContainer} key={i}>
        <FlexCenter>
          <div style={{display: 'flex', alignItems: 'center', width: '40%'}}>
            <img
              id="imageid"
              src={res.global_user_from.avatar}
              alt=""
              style={{
                width: '57px',
                height: '57px',
                borderRadius: '10px',
                marginRight: '1rem',
              }}
            />
            <div>
              <Typography variant="caption" component="p">
                {res.global_user_from.name}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                style={{
                  color: '#a9a8a8',
                }}
              >
                {res.global_user_from.people_work_placements &&
                  res.global_user_from.people_work_placements.length > 0 &&
                  res.global_user_from.people_work_placements[0].regno}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                style={{
                  color: '#a9a8a8',
                }}
              >
                {res.global_user_from.people_work_placements &&
                  res.global_user_from.people_work_placements.length > 0 &&
                  res.global_user_from.people_work_placements[0]
                    .company_job_profile &&
                  res.global_user_from.people_work_placements[0]
                    .company_job_profile.title}
              </Typography>
            </div>
          </div>
          <Typography
            variant="caption"
            component="p"
            className={classes.textComment}
          >
            {res.topic}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            className={classes.textComment}
          >
            {moment(res.date_added).format('LL')}
          </Typography>
          <IconFeedback
            style={{
              color:
                res.summary === 'SATISFIED'
                  ? '#4caf50'
                  : res.summary === 'ORDINARY'
                  ? '#ffa000'
                  : '#ef4d5e',
              width: '10%',
              textAlign: 'right',
              fontSize: '1.8rem',
            }}
          >
            {res.summary === 'SATISFIED'
              ? 'sentiment_satisfied_alt'
              : res.summary === 'ORDINARY'
              ? 'sentiment_neutral'
              : 'sentiment_very_dissatisfied'}
          </IconFeedback>
        </FlexCenter>
        <Divider className={classes.dividerComment} />
        <Typography
          variant="caption"
          component="p"
          color="secondary"
          gutterBottom
        >
          Description
        </Typography>
        <Typography variant="caption" component="p">
          {res.description}
        </Typography>
      </div>
    )
  }

  if (data === undefined && error === undefined) {
    return (
      <div className={classes.loadingProgress}>
        <CircularProgress />
        <Typography variant="body1" color="primary" style={{marginTop: 10}}>
          Loading data, please wait
        </Typography>
      </div>
    )
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const user = data.user[0].global_user

  return (
    // <div className={classes.containerRoot}>
    //   <IconButton onClick={props.history.goBack}>
    //     <Icon>arrow_back</Icon>
    //   </IconButton>
    <div id="toPdf" className={classes.container}>
      <HeaderGenerate
        from={moment(from).format('LL')}
        to={moment(to).format('LL')}
      />
      <Divider className={classes.dividerHEader} />
      <Paper style={{padding: '1.5rem'}} elevation={0}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img
            src={user.avatar}
            alt=""
            style={{
              width: '57px',
              height: '57px',
              borderRadius: '10px',
              marginRight: '1rem',
            }}
          />
          <div>
            <Typography variant="body2" style={{fontWeight: 'bold'}}>
              {user.name}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#a9a8a8',
              }}
            >
              {data.user[0].regno}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#a9a8a8',
              }}
            >
              {data.user[0].company_job_profile &&
                data.user[0].company_job_profile.title}
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2rem',
          }}
        >
          <div className={classes.emojiBuble}>
            <IconFeedback style={{color: '#4caf50'}}>
              sentiment_satisfied_alt
            </IconFeedback>
            <Typography variant="h5">
              {data.satisfied.aggregate.count}
            </Typography>
          </div>
          <div className={classes.emojiBuble}>
            <IconFeedback style={{color: '#ffa000'}}>
              sentiment_neutral
            </IconFeedback>
            <Typography variant="h5">
              {data.ordinary.aggregate.count}
            </Typography>
          </div>
          <div className={classes.emojiBuble}>
            <IconFeedback style={{color: '#ef4d5e'}}>
              sentiment_very_dissatisfied
            </IconFeedback>
            <Typography variant="h5">
              {data.disappointed.aggregate.count}
            </Typography>
          </div>
        </div>
        <div className={classes.feedbacktype}>
          <Typography variant="h6" color="primary" gutterBottom>
            Manager
          </Typography>
          {data.manager.map((res, i) =>
            feedbacks(
              res,
              i
              // avaManager
            )
          )}
        </div>
        <div className={classes.feedbacktype}>
          <Typography variant="h6" color="primary" gutterBottom>
            Feedback
          </Typography>
          {data.feedback.map((res, i) =>
            feedbacks(
              res,
              i
              // avaFeedback
            )
          )}
        </div>
        <div className={classes.feedbacktype}>
          <Typography variant="h6" color="primary" gutterBottom>
            Subordinate
          </Typography>
          {data.subordinate.map((res, i) =>
            feedbacks(
              res,
              i
              // avaSubordinate
            )
          )}
        </div>
        <footer>
          <style>{`@media print {footer {position: fixed;bottom: 0;}}`}</style>
          <div className={classes.footerWrapper}>
            <Typography className={classes.textFooter}>
              Generated on {moment().format('LL')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              style={{
                padding: '4px 12px',
                display: buttonVisible ? 'flex' : 'none',
              }}
              onClick={handlePrints}
            >
              <Icon style={{marginRight: 12}}>print</Icon>Print
            </Button>
          </div>
        </footer>
      </Paper>
    </div>
    // </div>
  )
}
export default withRouter(GenerateReport)
