import React, {useState} from 'react'
import useStyles from './CommitteeTaskStyles'
import {
  Dialog,
  DialogContent,
  Typography,
  Icon,
  Grid,
  Button,
  DialogActions,
} from '@material-ui/core'
import {useMutation} from '@apollo/react-hooks'
import {BoldTypography} from '../../../../components/typography/TypographyStyles'
import {EDIT_BADGES} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'

export default function ChangeBoxes(props) {
  const classes = useStyles()
  const [choose, setChoose] = useState([])
  const [state, setState] = useState(null)
  const {enqueueSnackbar} = useSnackbar()

  const {
    data,
    onClose,
    open,
    dataCurrent,
    back,
    nameUser,
    idAssignment,
    newWidth,
    newHeight,
  } = props

  const [editBadges] = useMutation(EDIT_BADGES)

  const box = []
  for (var y = newHeight; y > 0; y--) {
    for (var x = 0; x < newWidth; x++) {
      box.push({x: x, y: y})
    }
  }
  const handleOpen = (id, res) => {
    let tmp = choose
    if (!tmp.includes(id)) {
      setChoose([id])
      setState(res)
    } else {
      tmp = tmp.filter(e => e !== id)
      setChoose(tmp)
      setState(null)
    }
  }

  const handleSubmit = () => {
    if (!state) {
      enqueueSnackbar('please choose data badges', {
        variant: 'error',
        autoHideDuration: 2500,
      })
    } else {
      const dataEdit = {
        id: idAssignment,
        talentBoxId: state.id,
      }

      editBadges({
        variables: dataEdit,
      })
        .then(() => {
          enqueueSnackbar('Perbarui Lencana Berhasil', {
            variant: 'success',
            autoHideDuration: 1500,
          })
          setTimeout(() => {
            back()
          }, 1500)
        })
        .catch(() => {
          enqueueSnackbar('Perbarui Lencana Gagal', {
            variant: 'error',
            autoHideDuration: 1500,
          })
        })
    }
  }
  // const tempData = []
  // if (data) {
  //   const groupData =
  //     data &&
  //     data.reduce((objectVal, obj) => {
  //       const value = obj.resd.index_x
  //       objectVal[value] = (objectVal[value] || []).concat(obj).reverse()
  //       return objectVal
  //     }, [])
  //   for (let i = 0; i < groupData.length; i++) {
  //     groupData[i] &&
  //       groupData[i].forEach(val => {
  //         tempData.push(val)
  //       })
  //   }
  // }
  const renderData = () => {
    const newArr = []
    // const i = newWidth
    // for (let x = i; x >= 1; x--) {
    //   for (let y = 1; y <= newHeight; y++) {
    data &&
      data.talent_versions[0].talent_boxes.map((res, ids) => {
        // if (res.index_x === x && res.index_y === y) {
        return newArr.push(
          <Grid item key={ids} style={{width: `${100 / newWidth}%`}}>
            <div
              style={{
                border: choose.includes(res.id) && `2px solid ${res.color_hex}`,
                borderWidth: '3px',
                borderLeft: '0px',
                borderRadius: 10,
                height: `${250 / newWidth}px`,
                cursor: 'pointer',
                marginRight: 10,
                marginTop: 20,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
              }}
              onClick={() => handleOpen(res.id, res)}
            >
              <div
                style={{
                  width: '130px',
                  height: '30px',
                  borderRadius: '5px',
                  backgroundColor: '#4caf50',
                  top: '-10px',
                  right: '8px',
                  position: 'absolute',
                  display:
                    res.id === dataCurrent.talent_box?.id ? 'block' : 'none',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body1"
                  style={{color: '#fff', textAlign: 'center'}}
                >
                  Lencana saat ini
                </Typography>
              </div>
              <div
                style={{
                  backgroundColor: res.color_hex,
                  height: '100%',
                  width: '10px',
                  borderRadius: '7px',
                  left: '-3px',
                  position: 'absolute',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <img
                  src={res.badge}
                  alt={res.name}
                  style={{
                    width: `${
                      newWidth === 1 || newWidth === 2 ? 4 : 9 / newWidth
                    }vw`,
                    marginLeft: '1vw',
                  }}
                />
                <div style={{textAlign: 'right', marginLeft: '2vw'}}>
                  <BoldTypography variant="body2">{res.name}</BoldTypography>
                </div>
              </div>
            </div>
          </Grid>
        )
        // }
      })
    //   }
    // }
    return newArr
  }

  return (
    <Dialog scroll="body" fullWidth maxWidth="md" onClose={onClose} open={open}>
      <div className={classes.talentDetailHeader}>
        <div>
          <Typography variant="h6" className={classes.talentDetailTitle}>
            Ubah Lencana
          </Typography>
          <Typography variant="body1" className={classes.talentSubtittle}>
            Pilih lencana paling sesuai untuk {nameUser}
          </Typography>
        </div>
        <Icon className={classes.iconClose} onClick={onClose}>
          close
        </Icon>
      </div>

      <DialogContent>
        {/* BOXES */}
        <Grid container item>
          <Grid container item xs={12}>
            {renderData()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{margin: '16px 0'}}>
        <Typography
          variant="body1"
          style={{color: '#a9a8a8', marginRight: '1rem', cursor: 'pointer'}}
          onClick={onClose}
        >
          Batalkan
        </Typography>

        <Button
          variant="contained"
          color="primary"
          style={{width: '139px', height: '50px', borderRadius: '5px'}}
          onClick={handleSubmit}
        >
          <Typography variant="body1">Konfirmasi</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
