import React from 'react'

import {
  IconButton as IconButtonMui,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import {EmptyState} from '@smartasn/wlb-utils-components'

import {GLOBAL_PAGINATION_RANGE} from '../../../../../utils/helpers'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    fontSize: 12,
    padding: 12,
  },
  rootCell: {padding: 12, fontSize: 12},
  tableHead: {height: 82},
  rootPagination: {color: '#a9a8a8'},
  icon: {color: '#a9a8a8'},
  iconDisabled: {color: '#e5e5e5'},
})

export default function TableDetail(props) {
  const classes = useStyles()
  const {
    loading,
    count,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props

  return (
    <>
      {loading ? (
        <>
          <Skeleton height={76} animation="wave" />
          <Skeleton height={76} animation="wave" />
          <Skeleton height={76} animation="wave" />
        </>
      ) : (
        <TableContent {...props} />
      )}

      <Table>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={GLOBAL_PAGINATION_RANGE}
              count={count || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Baris per halaman"
              labelDisplayedRows={({from, to, count}) =>
                `${from}-${to} dari ${count}`
              }
              classes={{
                root: classes.rootPagination,
                selectIcon: classes.rootPagination,
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}

const TableContent = props => {
  const {columns, data, loading, config, count} = props
  const {emptyStateProps} = config || {}

  const classes = useStyles()

  const isEmptyData = !loading && data && count === 0

  return (
    <>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns?.map((column, idx) => (
              <TableCell
                key={column.label}
                align={column.align || 'left'}
                classes={{root: classes.rootCell}}
                style={{
                  textAlign: 'left',
                  ...getLayoutStyle(idx, columns.length - 1),
                }}
                {...column}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isEmptyData ? (
            <TableRow>
              <TableCell colSpan={columns?.length}>
                <div style={{margin: '50px auto', maxWidth: 300}}>
                  <EmptyState {...emptyStateProps} />
                </div>
              </TableCell>
            </TableRow>
          ) : (
            data?.map((row, i) => {
              return (
                <TableRow key={row.id + i}>
                  {columns?.map((column, idx) => {
                    return (
                      <TableCell
                        key={column.label + idx}
                        scope="row"
                        classes={{root: classes.rootCell}}
                        align={column.align || 'left'}
                        style={getLayoutStyle(idx, columns.length - 1)}
                        {...column}
                      >
                        {column?.value?.(row)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </>
  )
}

function TablePaginationActions(props) {
  const {count, page, rowsPerPage, onPageChange} = props

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{flexShrink: 0}}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  )
}

const IconButton = props => {
  const classes = useStyles()
  return (
    <IconButtonMui
      {...props}
      classes={{root: classes.icon, disabled: classes.iconDisabled}}
    />
  )
}

const getLayoutStyle = (index, maxIndex) => {
  if (index === 0) {
    return {paddingLeft: 22}
  }

  if (index === maxIndex) {
    return {paddingRight: 63}
  }
}
