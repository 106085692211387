import React, {useEffect, useState, useContext, Fragment} from 'react'
import {
  Button,
  MenuItem,
  Icon,
  InputLabel,
  InputAdornment,
  TextField,
  DialogActions,
  Typography,
  IconButton,
  Radio,
  Paper,
} from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns'
import Select, {components} from 'react-select'

import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {useApolloClient, useMutation, useQuery} from '@apollo/react-hooks'
import {
  CREATE_OWN_GOAL,
  ADD_NEW_GOALS,
  ADD_NEW_GOAL_GROUP,
  DELETE_GOAL_GROUP,
} from '../../../graphql/mutations'
import {
  GET_DROPDOWN_GOAL_ASPECTS,
  GET_DROPDOWN_WEIGHT_UNIT,
  GET_DROPDOWN_PERSPECTIVE,
  GET_DROPDOWN_SCORING_NATURE,
  GET_LIST_GOAL_GROUP,
  CHECK_GROUP_GOAL,
} from '../../../graphql/queries'
import {TOKEN, UPLOAD_URL, COMPANY_ID, USER_ID} from '../../../utils/globals'
import {
  selectProps,
  handleChangeAttachment,
  getTypeFile,
  trimString,
} from '../../../utils/helpers'
import axios from 'axios'
import {useStyles} from '../goal-components/GoalStyled'
import {
  ModalContent,
  StyledDialog,
  ModalHeader,
} from '../detail-modal/ModalPerformanceStyles'
import moment from 'moment'
import ConfirmationPopup from '../../shared-component/ConfirmationPopup'
import ModalDelete from './ModalDelete'
import {
  FlexBetween,
  TextHelper,
  GenerateTooltip,
  FlexCenter,
  LineClamp,
} from '../PerformanceStyles'
import {
  TextXl,
  GreyTypography,
  PmdGrey,
  PsmGrey,
} from '../../../components/typography/TypographyStyles'
import ButtonAttachment from '../../../components/attachment/ButtonAttachment'
import ItemAttachment from '../../../components/attachment/ItemAttachment'
import TooltipAttachmentGoal from '../../shared-component/attachment/TooltipAttachmentGoal'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import {DataContext} from '../../../contexts/ContextConfig'
import {
  FILE_TYPE_UPLOAD,
  goalTypes,
  priorities,
} from '../goal-components/TabDialog/constant'
import {GLOBAL_GLOSSARY} from '../../../utils/constant'
import _ from 'lodash'
import {ChromePicker} from 'react-color'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import {useSnackbar} from 'notistack'

const customSelect = {
  control: provided => ({
    ...provided,
    width: '100%',
    outline: 'none',
    padding: 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: base => ({
    ...base,
    backgroundColor: '#055469',
  }),
  multiValueLabel: base => ({
    ...base,
    color: '#fff',
  }),
  multiValueRemove: base => ({
    ...base,
    color: '#ffff',
    '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
  }),
  menu: base => ({
    ...base,
    maxWidth: '100%',
  }),
}

const ModalOwnGoal = props => {
  const {onClose, open, dataGoal, handleClose} = props
  const classes = useStyles()
  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()
  const {setDataContext} = useContext(DataContext)
  const _values = {
    name: '',
    description: '',
    target: '',
    // target_stretch: '',
    weight: null,
    priority: '',
    // visibility: '',
    type: '',
    startdate: new Date(),
    enddate: new Date(),
    status: '',
    attachment: [],
    perspective: '',
    weightUnit: '',
    scoringNature: '',
    metrix: '',
  }
  const [values, setValues] = useState(_values)
  const [isError, setIsError] = useState(false)
  const [dialogCancel, setDialogCancel] = useState({
    cancel: false,
    discard: false,
    save: false,
  })
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [aspectDropdown, setAspectDropdown] = useState([])
  const [perspectiveDropdown, setPerspectiveDropdown] = useState([])
  const [weightUnitDropdown, setWeightUnitDropdown] = useState([])
  const [scoringNatureDropdown, setScoringNatureDropdown] = useState([])
  const [searchLabel, setSearchLabel] = useState('')
  const [searchDropdown, setSearchDropdown] = useState({
    perspective: '',
    weightUnit: '',
    scoringNature: '',
    aspect: '',
  })
  const [selectedGroup, setSelectedGroup] = useState([])
  const [colorPicker, setColorPicker] = useState(false)
  const [label, setLabel] = useState({
    open: false,
    name: '',
    color: '#055469',
  })
  const [colorOptions, setColorOptions] = useState([
    {color: '#ef4d5e'},
    {color: '#a9a8a8'},
    {color: '#ffa000'},
    {color: '#007fb2'},
    {color: '#4caf50'},
    {color: '#a35a31'},
    {color: '#874caf'},
    {color: '#f04ba0'},
  ])
  const INITIAL_ALERT_LABEL = {
    canDelete: true,
    open: false,
    idLabel: null,
  }
  const [alertLabel, setAlertLabel] = useState(INITIAL_ALERT_LABEL)

  useEffect(() => {
    if (dataGoal) {
      setValues({
        name: dataGoal.name,
        description: dataGoal.description,
        target: dataGoal.target,
        weight: null,
        priority: dataGoal.priority,
        // visibility: dataGoal.visibility,
        startdate: dataGoal.startdate,
        enddate: dataGoal.enddate,
        status: dataGoal.status,
        attachment: dataGoal.attachment,
        aspect: dataGoal.performance_goal.kpi_list.kpi_aspect?.id,
        perspective: dataGoal.performance_goal.kpi_list.kpi_perspective?.id,
        weightUnit: dataGoal.performance_goal.kpi_list.kpi_weight_unit?.id,
        scoringNature:
          dataGoal.performance_goal.kpi_list.kpi_scoring_nature?.id,
        metrix: dataGoal.metric || dataGoal.performance_goal.kpi_list.formula,
        cascading: [
          {
            task: dataGoal?.id,
          },
        ],
      })
      setAspectDropdown({
        value: dataGoal.performance_goal.kpi_list.kpi_aspect?.id,
        label: dataGoal.performance_goal.kpi_list.kpi_aspect?.name,
      })
      setPerspectiveDropdown({
        value: dataGoal.performance_goal.kpi_list.kpi_perspective?.id,
        label: dataGoal.performance_goal.kpi_list.kpi_perspective?.name,
      })
      setWeightUnitDropdown({
        value: dataGoal.performance_goal.kpi_list.kpi_weight_unit?.id,
        label: dataGoal.performance_goal.kpi_list.kpi_weight_unit?.name,
      })
      setScoringNatureDropdown({
        value: dataGoal.performance_goal.kpi_list.kpi_scoring_nature?.id,
        label: dataGoal.performance_goal.kpi_list.kpi_scoring_nature?.name,
      })
    }
  }, [dataGoal])

  const isCompanyType =
    dataGoal?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
      ?.aggregate?.count > 0

  const handleChange = name => event => {
    setValues({
      ...values,
      [name]: event.target.value,
    })
  }

  const [createOwnGoal] = useMutation(CREATE_OWN_GOAL(!!dataGoal))
  const [createCompanyGoal] = useMutation(ADD_NEW_GOALS)
  const [deleteGoalGroup] = useMutation(DELETE_GOAL_GROUP)

  const {data: aspect, loading: aspectLoad, error: aspectError} = useQuery(
    GET_DROPDOWN_GOAL_ASPECTS,
    {
      variables: {
        company: COMPANY_ID,
        search: `%${searchDropdown.aspect}%`,
        limit: 20,
      },
      fetchPolicy: 'cache-and-network',
      skip: !open,
    }
  )

  const {
    data: perspective,
    loading: perspectiveLoad,
    error: perspectiveError,
  } = useQuery(GET_DROPDOWN_PERSPECTIVE, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.perspective}%`,
    },
    fetchPolicy: 'cache-and-network',
    skip: !open,
  })

  const {
    data: weightUnit,
    loading: weightUnitLoad,
    error: weightUnitError,
  } = useQuery(GET_DROPDOWN_WEIGHT_UNIT, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.weightUnit}%`,
    },
    fetchPolicy: 'cache-and-network',
    skip: !open,
  })

  const {
    data: scoringNature,
    loading: scoringNatureLoad,
    error: scoringNatureError,
  } = useQuery(GET_DROPDOWN_SCORING_NATURE, {
    variables: {
      company: COMPANY_ID,
      search: `%${searchDropdown.scoringNature}%`,
    },
    fetchPolicy: 'cache-and-network',
    skip: !open,
  })

  const [addGoalGroup] = useMutation(ADD_NEW_GOAL_GROUP)

  const {data: dataGoalGroup, refetch: refetchGoalGroup} = useQuery(
    GET_LIST_GOAL_GROUP,
    {
      variables: {
        search: searchLabel
          ? {name: {_ilike: `%${searchLabel}%`}}
          : {
              _or: [{added_by: {_eq: USER_ID}}],
            },
      },
      fetchPolicy: 'cache-and-network',
      skip: !open,
    }
  )

  if (
    perspectiveError ||
    weightUnitError ||
    aspectError ||
    scoringNatureError
  ) {
    console.error({
      perspectiveError,
      weightUnitError,
      aspectError,
      scoringNatureError,
    })
  }

  const _aspect = aspect?.kpi_aspects.map(opt => ({
    value: opt.id,
    label: opt.name,
  }))

  const _perspective = perspective?.kpi_perspectives.map(opt => ({
    value: opt.id,
    label: opt.name,
  }))

  const _weightUnit = weightUnit?.kpi_weight_units.map(opt => ({
    value: opt.id,
    label: opt.name,
  }))

  const _scoringNature = scoringNature?.kpi_scoring_natures.map(opt => ({
    value: opt.id,
    label: opt.name,
  }))

  const labelOptionsGoal = [
    {
      idGroup: null,
      value: 'Tidak Ada',
      label: 'Tidak Ada',
      color: '#A9A8A8',
      user: null,
    },
  ]
  const tempDataOptions = dataGoalGroup?.performance_goal_groups?.map(res => {
    const option = {
      idGroup: res.id,
      value: res.name,
      label: res.name,
      color: res.color,
      user: res.added_by,
    }
    labelOptionsGoal.push(option)
  })

  const handleChangeFile = e => {
    const tempFile = Array.from(e.target.files)
    const fileCount =
      (tempFile?.length ?? 0) + (values?.attachment?.length ?? 0)

    tempFile.forEach(val => {
      if (fileCount > 10) {
        enqueueSnackbar('Jumlah lampiran tidak boleh lebih dari 10', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (!FILE_TYPE_UPLOAD.includes(getTypeFile(val.name))) {
        enqueueSnackbar('Ada jenis file yang tidak diperbolehkan', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else if (val.size >= 1024 * 1024 * 25) {
        enqueueSnackbar('Beberapa ukuran lampiran Anda lebih dari 25 MB', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      } else {
        handleChangeAttachment(
          val,
          setDisabledButton,
          setValues,
          axios,
          UPLOAD_URL,
          TOKEN
        )
      }
    })
  }

  const handleDeleteAttachButton = i => {
    const newState = [...values.attachment]
    if (i > -1) {
      newState.splice(i, 1)
    }
    setValues(prevState => ({...prevState, attachment: newState}))
  }

  const handleChangeNameFileAttBtn = (e, i) => {
    const newState = [...values.attachment]
    newState[i].name = e.target.value
    setValues(prevState => ({...prevState, attachment: newState}))
  }

  const dateChecker = () => {
    const startdateSplit = moment(values.startdate).format('MM/DD/YYYY')
    const enddateSplit = moment(values.enddate).format('MM/DD/YYYY')

    if (moment(enddateSplit).isSame(startdateSplit)) {
      return false
    } else {
      return moment(enddateSplit).isBefore(startdateSplit)
    }
  }

  const onSaveLabel = () => {
    const data = {name: label.name, color: label.color}
    addGoalGroup({variables: data})
      .then(() => {
        enqueueSnackbar('Sukses menambah label baru', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setLabel({...label, open: false, name: ''})
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Kesalahan penambahan data, harap coba lagi', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const onMutation = () => {
    if (
      !values.name ||
      values.name.length > 255 ||
      // !values.visibility ||
      !values.type ||
      !values.description ||
      // !values.weight ||
      !values.target ||
      // !values.target_stretch ||
      !values.priority ||
      !values.aspect ||
      !values.perspective ||
      !values.weightUnit ||
      !values.scoringNature ||
      !values.metrix
      // || checkScoring()
    ) {
      handleAlert('save')
      const ids = document.getElementById('top')
      ids.scrollIntoView({behavior: 'smooth'})
      setIsError(true)
      setTimeout(() => setIsError(false), 5000)
    } else if (dateChecker()) {
      handleAlert('save')
      setIsErrorDate(true)
      setTimeout(() => {
        setIsErrorDate(false)
      }, 3000)
    } else {
      const data = {
        name: values.name,
        description: values.description,
        target: values.target,
        progress: values.progress,
        weight: values.weight,
        priority: values.priority,
        // visibility: values.visibility,
        startdate: values.startdate,
        enddate: values.enddate,
        status: values.status,
        attachment: values.attachment,
        group: values.group,
        kpi: dataGoal?.performance_goal?.kpi_list?.id,
        performance_cascadings: {
          data: [
            {
              task: dataGoal?.id,
            },
          ],
        },
      }
      const mutationDynamic = isCompanyType ? createCompanyGoal : createOwnGoal
      const variableDynamic = isCompanyType ? {data} : values
      mutationDynamic({variables: variableDynamic})
        .then(() => {
          enqueueSnackbar('Berhasil Membuat Data', {
            variant: 'success',
            autoHideDuration: 1000,
          })
          setDataContext(true)
          setTimeout(() => {
            handleAlert('all')
            if (dataGoal) {
              handleClose()
              setDataContext(null)
            }
          }, 1000)
          setValues(_values)
          setAspectDropdown([])
          setPerspectiveDropdown([])
          setWeightUnitDropdown([])
          setScoringNatureDropdown([])
        })
        .catch(() => {
          enqueueSnackbar('Kesalahan perbarui data, harap coba lagi', {
            variant: 'error',
            autoHideDuration: 1000,
          })
          setTimeout(() => handleAlert('all'), 1000)
          setValues(_values)
        })
    }
  }

  const selectStyles = {
    menu: provided => ({...provided, zIndex: 9999}),
    container: provided => ({
      ...provided,
      marginTop: 16,
      marginBottom: 8,
    }),
    control: base => ({
      ...base,
      height: 55,
      minHeight: 55,
    }),
  }

  const handleAlert = status => {
    if (status === 'save') {
      dialogCancel.save
        ? setDialogCancel({...dialogCancel, save: false})
        : setDialogCancel({...dialogCancel, save: true})
    } else if (status === 'cancel') {
      dialogCancel.cancel
        ? setDialogCancel({...dialogCancel, cancel: false})
        : setDialogCancel({...dialogCancel, cancel: true})
    } else if (status === 'discard') {
      dialogCancel.discard
        ? setDialogCancel({...dialogCancel, discard: false})
        : setDialogCancel({...dialogCancel, discard: true})
    } else if (status === 'all') {
      onClose()
      setDialogCancel({
        save: false,
        cancel: false,
        discard: false,
      })
      setValues(_values)
      setAspectDropdown([])
      setPerspectiveDropdown([])
      setWeightUnitDropdown([])
      setScoringNatureDropdown([])
    }
  }

  const handleChangeGroup = dataGroups => {
    const multipleValue = {...dataGroups}

    setValues({...values, group: multipleValue.idGroup})
    setSelectedGroup(oldArray => {
      const arr = [...oldArray, multipleValue]
      arr.reverse()
      const revArr = arr
      const newValue = _.uniqBy(revArr, 'idParent')

      return newValue
    })
  }

  const handleDeleteLabel = async idLabel => {
    const query = CHECK_GROUP_GOAL

    const {data, error} = await client.query({
      query: query,
      variables: {group: idLabel},
    })

    if (error) {
      return console.error({error})
    }

    const lengthLabel = data?.performance_goals?.length
    const isExsist = lengthLabel > 0

    setValues({...values, group: null})
    setSelectedGroup([])
    setAlertLabel({
      canDelete: !isExsist,
      idLabel: idLabel,
      open: true,
    })
  }

  const groupMenu = option => {
    const canDelete = option.user === USER_ID
    return (
      <FlexBetween>
        <div style={{width: '80%', display: 'flex'}}>
          <div
            style={{
              background: option.color,
              width: 0,
              borderRadius: 5,
              padding: 10,
              marginRight: 12,
            }}
          />
          <LineClamp
            lineClamp={1}
            style={{
              alignSelf: 'center',
              fontSize: 12,
              color: '#a9a8a8',
            }}
          >
            {option.label}
          </LineClamp>
        </div>
        {canDelete && (
          <IconButton
            style={{padding: 0}}
            onClick={() => handleDeleteLabel(option.idGroup)}
          >
            <DeleteOutlineIcon style={{color: '#EF4D5E'}} />
          </IconButton>
        )}
      </FlexBetween>
    )
  }

  const onDeleteLabel = () => {
    deleteGoalGroup({variables: {id: alertLabel.idLabel}})
      .then(() => {
        enqueueSnackbar('Berhasil menghapus label', {
          variant: 'success',
          autoHideDuration: 1000,
        })
        setAlertLabel(INITIAL_ALERT_LABEL)
        refetchGoalGroup()
      })
      .catch(() => {
        enqueueSnackbar('Kesalahan hapus data, silakan coba lagi', {
          variant: 'error',
          autoHideDuration: 1000,
        })
      })
  }

  const renderValue = option => {
    return (
      <FlexCenter>
        <div
          style={{
            background: option.color || '#A9A8A8',
            width: 0,
            borderRadius: 5,
            padding: 10,
            marginRight: 12,
          }}
        />
        <PsmGrey>{trimString(option.label || 'Tidak Ada', 50)}</PsmGrey>
      </FlexCenter>
    )
  }

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={() => handleAlert('discard')}
        maxWidth="md"
        fullWidth
        scroll="body"
      >
        <ModalHeader>
          <FlexBetween>
            <TextXl>Membuat {GLOBAL_GLOSSARY.performance.Goal}</TextXl>
            <IconButton
              aria-label="close"
              onClick={() => handleAlert('discard')}
            >
              <Icon>close</Icon>
            </IconButton>
          </FlexBetween>
        </ModalHeader>
        <ModalContent>
          <div className={classes.formChild} id="top">
            <InputLabel htmlFor="text-field-goal-name">
              Nama {GLOBAL_GLOSSARY.performance.Goal_short} *
            </InputLabel>
            <TextField
              id="text-field-goal-name"
              multiline
              placeholder="Tambah Nama Hasil Kerja"
              margin="normal"
              variant="outlined"
              style={{width: '100%'}}
              value={values.name}
              // onChange={handleChange('name')}
              onChange={e => {
                if (values.name.length >= 255 || e.target.value.length >= 255) {
                  setValues({...values, name: e.target.value.slice(0, 255)})
                } else setValues({...values, name: e.target.value})
              }}
              error={
                (!values.name && isError) ||
                (values.name.length > 255 && isError)
              }
              helperText={
                !values.name && isError
                  ? 'Bagian ini diperlukan'
                  : values.name.length > 255 && isError
                  ? "Name can't be more than 255 characters"
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <GreyTypography variant="body2">
                      {values.name.length >= 255 ? 0 : 255 - values.name.length}
                    </GreyTypography>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {/* <div className={classes.formChild}>
            <InputLabel htmlFor="select-visibility">Visibility *</InputLabel>
            <TextField
              id="select-visibility"
              select
              style={{width: '30%'}}
              value={values.visibility}
              onChange={handleChange('visibility')}
              margin="normal"
              variant="outlined"
              error={!values.visibility && isError}
              helperText={
                !values.visibility && isError ? 'Bagian ini diperlukan' : ''
              }
              SelectProps={selectProps(values.visibility)}
            >
              <MenuItem value="" disabled>
                Pilih Visibilitas
              </MenuItem>
              {visibilitys.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div> */}
          <div className={classes.flex}>
            <div style={{width: '50%'}}>
              <PmdGrey style={{marginBottom: 8}}>Label</PmdGrey>
              <Select
                value={selectedGroup}
                options={labelOptionsGoal || tempDataOptions}
                onChange={value => handleChangeGroup(value)}
                styles={customSelect}
                placeholder="Label RHK / Rencana Aksi"
                getOptionLabel={groupMenu}
                onInputChange={dataSearchDropdown =>
                  setSearchLabel(dataSearchDropdown)
                }
                components={{
                  SingleValue: ({data}) => renderValue(data),
                  Menu: props => (
                    <Fragment>
                      <components.Menu {...props}>
                        {props.children}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 16,
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{
                              textTransform: 'capitalize',
                              width: '100%',
                            }}
                            onClick={() => setLabel({...label, open: true})}
                          >
                            Buat Label Baru
                          </Button>
                        </div>
                      </components.Menu>
                    </Fragment>
                  ),
                }}
              />
              <div className={classes.formChild} style={{marginTop: 25}}>
                <InputLabel htmlFor="select-type">
                  {' '}
                  Tipe {GLOBAL_GLOSSARY.performance.Goal_short}
                </InputLabel>
                <TextField
                  id="select-type"
                  select
                  style={{width: '60%'}}
                  value={values.type}
                  onChange={handleChange('type')}
                  margin="normal"
                  variant="outlined"
                  error={!values.type && isError}
                  helperText={
                    !values.type && isError ? 'Bagian ini diperlukan' : ''
                  }
                  SelectProps={selectProps(values.type)}
                >
                  <MenuItem value="" disabled>
                    Pilih Tipe {GLOBAL_GLOSSARY.performance.Goal_short}
                  </MenuItem>
                  {goalTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            </div>
            {label.open && (
              <div style={{margin: 25}}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.outlinedInput,
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            style={{
                              padding: 10,
                              background: label.color,
                              borderRadius: 5,
                            }}
                          ></div>
                        </InputAdornment>
                      ),
                    }}
                    value={label.name}
                    onChange={event =>
                      setLabel({...label, name: event.target.value})
                    }
                  />
                  <Button
                    style={{marginLeft: 10}}
                    variant="contained"
                    color="primary"
                    disabled={label.name === ''}
                    onClick={onSaveLabel}
                  >
                    Simpan
                  </Button>
                </div>
                <div style={{marginTop: 16}}>
                  <PsmGrey>Pilih Warna</PsmGrey>
                  <div className={classes.flexWrap}>
                    {colorOptions.map((col, i) => {
                      return (
                        <Radio
                          key={i}
                          onChange={event =>
                            setLabel({...label, color: event.target.value})
                          }
                          value={col.color}
                          checked={label.color === col.color}
                          icon={
                            <span
                              className={classes.icon}
                              style={{background: col.color}}
                            />
                          }
                          checkedIcon={
                            <Icon
                              className={classes.checkedIcon}
                              style={{
                                background: col.color,
                                fontSize: 20,
                              }}
                            >
                              check
                            </Icon>
                          }
                        ></Radio>
                      )
                    })}
                    <Icon
                      onClick={() => setColorPicker(true)}
                      style={{
                        border: '1px #a9a8a8 solid',
                        color: '#a9a8a8',
                        borderRadius: 5,
                        cursor: 'pointer',
                        fontSize: 19,
                        margin: 9,
                      }}
                    >
                      add
                    </Icon>
                  </div>
                  <div className={classes.flexAbsolute}>
                    {colorPicker && (
                      <Paper style={{padding: 14, marginBottom: 10}}>
                        <Typography variant="body2" gutterBottom>
                          Pilih Warna
                        </Typography>
                        <ChromePicker
                          color={label.color || false}
                          value={label.color}
                          onChangeComplete={color =>
                            setLabel({...label, color: color.hex})
                          }
                        />
                        <div
                          style={{
                            display: 'flex',
                            marginTop: 10,
                            justifyContent: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            style={{width: '80%'}}
                            onClick={() => {
                              setColorOptions(prev => [
                                ...prev,
                                {color: label.color},
                              ])
                              setColorPicker(false)
                            }}
                          >
                            Tutup
                          </Button>
                        </div>
                      </Paper>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classes.formChild}>
            <InputLabel htmlFor="select-aspect">
              Aspek {GLOBAL_GLOSSARY.performance.Goal_short}
            </InputLabel>
            <Select
              id="select-aspect"
              options={_aspect}
              value={aspectDropdown}
              placeholder={`Tambah Aspek ${GLOBAL_GLOSSARY.performance.Goal_short}`}
              isLoading={aspectLoad}
              isDisabled={isCompanyType}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  aspect: dataSearchDropdown,
                })
              }
              onChange={event => {
                setValues({
                  ...values,
                  aspect: event.value,
                })
                setAspectDropdown(event)
              }}
              styles={selectStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: '#bfbfbf', // Placeholder color
                },
              })}
            />
            <TextHelper className={classes.margin0}>
              {!values.aspect && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="select-persvective">Perspektif</InputLabel>
            <Select
              id="select-persvective"
              options={_perspective}
              value={perspectiveDropdown}
              placeholder="Tambah Perspektif"
              isLoading={perspectiveLoad}
              isDisabled={isCompanyType}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  perspective: dataSearchDropdown,
                })
              }
              onChange={event => {
                setValues({
                  ...values,
                  perspective: event.value,
                })
                setPerspectiveDropdown(event)
              }}
              styles={selectStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: '#bfbfbf', // Placeholder color
                },
              })}
            />
            <TextHelper className={classes.margin0}>
              {!values.perspective && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="text-area-description">
              Keterangan *
            </InputLabel>
            <TextField
              id="text-area-description"
              placeholder="Tambah Keterangan"
              multiline
              rows="4"
              margin="normal"
              variant="outlined"
              value={values.description}
              onChange={handleChange('description')}
              style={{width: '100%'}}
              error={!values.description && isError}
              helperText={
                !values.description && isError ? 'Bagian ini diperlukan' : ''
              }
              inputProps={{ maxLength: 1500 }}
            />
          </div>
          <div style={{marginTop: '2%', marginBottom: '1rem'}}>
            <InputLabel htmlFor="text-field-metric">Metrik *</InputLabel>
            <TextField
              id="text-field-metric"
              multiline
              margin="normal"
              variant="outlined"
              value={values.metrix}
              disabled={isCompanyType}
              placeholder="Penerapan Sistem Kartu Skor"
              InputProps={{classes: {disabled: classes.disabled}}}
              onChange={e => {
                setValues({...values, metrix: e.target.value})
              }}
              error={!values.metrix && isError}
              style={{width: '50%'}}
            />
          </div>
          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <div style={{width: '25%', paddingRight: '0.5rem'}}>
              <InputLabel htmlFor="select-weight-unit">
                Bobot Penilaian *
              </InputLabel>
              <Select
                id="select-weight-unit"
                options={_weightUnit}
                placeholder="Persentase"
                value={weightUnitDropdown}
                isLoading={weightUnitLoad}
                isDisabled={isCompanyType}
                onInputChange={dataSearchDropdown =>
                  setSearchDropdown({
                    ...searchDropdown,
                    weightUnit: dataSearchDropdown,
                  })
                }
                onChange={event => {
                  setValues({
                    ...values,
                    weightUnit: event.value,
                  })
                  setWeightUnitDropdown(event)
                }}
                styles={selectStyles}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: '#bfbfbf', // Placeholder color
                  },
                })}
              />
              <TextHelper className={classes.margin0}>
                {!values.weightUnit && isError ? 'Bagian ini diperlukan' : ''}
              </TextHelper>
            </div>
          </div>
          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <div style={{width: '25%', paddingRight: '1rem'}}>
              <InputLabel htmlFor="text-field-target-base">
                Basis Sasaran *
              </InputLabel>
              <TextField
                id="text-field-target-base"
                type="number"
                margin="normal"
                variant="outlined"
                placeholder="Tambah Basis Sasaran"
                value={values.target}
                onChange={handleChange('target')}
                fullWidth
                error={!values.target && isError}
                helperText={
                  !values.target && isError ? 'Bagian ini diperlukan' : ''
                }
              />
            </div>
          </div>
          <div className={classes.formChild} style={{width: '30%'}}>
            <InputLabel htmlFor="select-scoring-nature">
              Parameter Penilaian *
            </InputLabel>
            <Select
              id="select-scoring-nature"
              options={_scoringNature}
              placeholder="Lebih tinggi lebih baik"
              value={scoringNatureDropdown}
              isLoading={scoringNatureLoad}
              isDisabled={isCompanyType}
              onInputChange={dataSearchDropdown =>
                setSearchDropdown({
                  ...searchDropdown,
                  scoringNature: dataSearchDropdown,
                })
              }
              onChange={event => {
                setValues({
                  ...values,
                  scoringNature: event.value,
                })
                setScoringNatureDropdown(event)
              }}
              styles={selectStyles}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: '#bfbfbf', // Placeholder color
                },
              })}
            />
            <TextHelper className={classes.margin0}>
              {!values.scoringNature && isError ? 'Bagian ini diperlukan' : ''}
            </TextHelper>
          </div>
          <div
            className={classes.formChild}
            style={{display: 'flex', flexDirection: 'row'}}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div style={{flex: '0.5'}}>
                <InputLabel htmlFor="date-picker-start-date">
                  Tanggal Mulai *
                </InputLabel>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-start-date"
                  inputVariant="outlined"
                  value={values.startdate}
                  onChange={date =>
                    setValues({
                      ...values,
                      startdate: date,
                    })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
              <div>
                <InputLabel htmlFor="date-picker-end-date">
                  Tanggal Akhir *
                </InputLabel>
                <KeyboardDatePicker
                  disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  minDate={values.startdate}
                  id="date-picker-end-date"
                  inputVariant="outlined"
                  value={values.enddate}
                  onChange={date =>
                    setValues({
                      ...values,
                      enddate: date,
                    })
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  error={isErrorDate}
                />
                {isErrorDate ? (
                  <Typography className={classes.errorDate}>
                    end date cannot be before start date
                  </Typography>
                ) : null}
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className={classes.formChild}>
            <InputLabel htmlFor="text-edit-priority">Prioritas *</InputLabel>
            <TextField
              id="text-edit-priority"
              select
              style={{width: '30%'}}
              value={values.priority}
              onChange={handleChange('priority')}
              margin="normal"
              variant="outlined"
              error={!values.priority && isError}
              helperText={
                !values.priority && isError ? 'Bagian ini diperlukan' : ''
              }
              SelectProps={selectProps(values.priority)}
            >
              <MenuItem value="" disabled>
                Pilih Prioritas
              </MenuItem>
              {priorities.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className={classes.formChild} style={{width: '50%'}}>
            <InputLabel
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#a9a8a8',
              }}
            >
              Lampiran (opsional){' '}
              <GenerateTooltip
                title={<TooltipAttachmentGoal />}
                placement="right"
              >
                <InfoOutlinedIcon
                  style={{marginLeft: 7, width: 16}}
                  color="primary"
                />
              </GenerateTooltip>
            </InputLabel>
            <ButtonAttachment
              multiple
              id={'add-attachment'}
              disabled={values?.attachment?.length > 10 || disabledButton}
              onUpload={handleChangeFile}
            />
            {values?.attachment?.map((res, i) => {
              const {name, size, url, isUploading, loaded} = res
              return (
                <ItemAttachment
                  res={res}
                  key={`${url}-${i}`}
                  id={`${url}-${i}`}
                  size={size}
                  onDelete={() => handleDeleteAttachButton(i)}
                  src={url}
                  onChange={e => handleChangeNameFileAttBtn(e, i)}
                  fileName={name}
                  isUploading={isUploading}
                  loaded={loaded}
                />
              )
            })}
          </div>

          <DialogActions style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{marginRight: 12}}>
              <Button
                size="large"
                onClick={() => handleAlert('cancel')}
                style={{textTransform: 'capitalize'}}
              >
                Batal
              </Button>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleAlert('save')}
                size="large"
                style={{textTransform: 'capitalize'}}
              >
                Simpan
              </Button>
            </div>
          </DialogActions>
        </ModalContent>
      </StyledDialog>
      {dialogCancel.save ? (
        <ConfirmationPopup
          open={dialogCancel.save}
          handleClose={() => handleAlert('save')}
          handleCloseForm={() => handleAlert('all')}
          feature={`Tambah ${GLOBAL_GLOSSARY.performance.Goal_short} ${
            !dataGoal ? 'Pribadi ' : ''
          }?`}
          message={`Apakah kamu yakin untuk menambah ${
            GLOBAL_GLOSSARY.performance.Goal_short
          } ${!dataGoal ? 'Pribadi ' : ''}?`}
          type="Simpan"
          mutation={onMutation}
        />
      ) : dialogCancel.cancel ? (
        <ConfirmationPopup
          open={dialogCancel.cancel}
          handleClose={() => handleAlert('cancel')}
          handleCloseForm={() => handleAlert('all')}
          feature={`Batal menambah ${GLOBAL_GLOSSARY.performance.Goal_short} Pribadi?`}
          message={`Anda memiliki perubahan yang belum disimpan. Apakah Anda yakin akan membatalkan penambahan ${GLOBAL_GLOSSARY.performance.Goal_short} Pribadi`}
          type="Batal"
        />
      ) : (
        dialogCancel.discard && (
          <ConfirmationPopup
            open={dialogCancel.discard}
            handleClose={() => handleAlert('discard')}
            handleCloseForm={() => handleAlert('all')}
            feature="Buang perubahan?"
            message="Apakah Anda yakin akan membuang perubahan yang belum disimpan? "
            type="Buang"
          />
        )
      )}
      <ModalDelete
        open={alertLabel.open && alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        name="label ini"
        isHighlight={false}
        description="All goal / task that using this label will be unassigned to any label once you delete this label"
        mutation={onDeleteLabel}
        canClose={false}
      />
      <ConfirmationPopup
        open={alertLabel.open && !alertLabel.canDelete}
        handleClose={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        handleCloseForm={() => setAlertLabel(INITIAL_ALERT_LABEL)}
        feature="Tidak dapat menghapus Label"
        message="Tidak dapat menghapus karena label ini masih digunakan pada RHK atau Rencana Aksi"
        type="Konfirmasi"
        isCancel={false}
      />
    </div>
  )
}

export default ModalOwnGoal
