import gql from 'graphql-tag'

export const GET_LIST_MULTIRATER_SUMMARY = gql`
  query getListMultirater($id: uuid, $limit: Int, $offset: Int) {
    multirater_surveys(
      where: {
        multirater_responses: {respond_to: {_eq: $id}}
        multirater_survey_groups: {isnps: {_eq: false}}
      }
      limit: $limit
      offset: $offset
    ) {
      id
      title
      type
      startdate
      enddate
      date_created
      status
      multirater_survey_groups {
        id
        name
        avg_all_score: multirater_survey_responses_aggregate {
          aggregate {
            avg {
              answer
            }
          }
        }
        avg_spv_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {
                global_user: {
                  people_work_placement_supervisor: {user: {_eq: $id}}
                }
              }
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
        avg_peer_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {
                global_user_supervisor: {
                  people_work_placement_supervisor: {user: {_eq: $id}}
                }
              }
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
        avg_subordinate_score: multirater_survey_responses_aggregate(
          where: {
            respond_to: {_eq: $id}
            global_user_respond_from: {
              people_work_placements: {global_user_supervisor: {id: {_eq: $id}}}
            }
          }
        ) {
          aggregate {
            avg {
              answer
            }
          }
        }
      }

      count_responded: multirater_responses_aggregate(
        where: {respond_from: {_eq: $id}}
        distinct_on: respond_to
      ) {
        aggregate {
          count
        }
      }
      count_respondents: multirater_respondents_aggregate {
        aggregate {
          count
        }
      }
      multirater_responses_aggregate(where: {respond_to: {_eq: $id}}) {
        aggregate {
          avg {
            answer
          }
        }
      }
    }
  }
`
