import React, {useState, useEffect, useContext} from 'react'
import {
  Grid,
  Icon,
  Card,
  CardContent,
  CardActions,
  Typography,
  Avatar,
  CircularProgress,
  makeStyles,
  Popover,
  IconButton,
  List,
} from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {
  TaskCard,
  FlexBetween,
  StyledMenuItem,
  TitleContent,
  GoalsListBtn,
  GoalProgressIndicator,
} from './../../../PerformanceStyles'
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd'
import ModalTab from '../../../goal-components/TabDialog/ModalTab'
import Skeleton from 'react-loading-skeleton'

// graphql
import {useQuery, useMutation, useApolloClient} from '@apollo/react-hooks'
import {
  GET_LIST_CONTAINER_TASK,
  GET_DETAIL_CONTAINER_TASK,
  GET_LIST_ALL_PERSONAL_TASK,
  GET_LIST_ALL_DEPARTMENT_TASK,
} from '../../../../../graphql/queries/index'

// global js
import {USER_ID, COMPANY_ID} from '../../../../../utils/globals'
import ModalEdit from '../../../Modal/ModalEdit'
import ModalDelete from '../../../Modal/ModalDelete'
import {
  DELETE_TASK,
  UPDATE_TASK,
  UPDATE_TASK_ATTACHMENTS,
} from '../../../../../graphql/mutations'
import {snackBar} from '../../../../../components/snakbar/SnackbarComponent'
import ModalMoveTask from '../../../Modal/ModalMoveTask'
import InfiniteScroll from 'react-infinite-scroll-component'
import {DataContext} from '../../../../../contexts/ContextConfig'
import ConfirmationPopup from '../../../../shared-component/ConfirmationPopup'
import {
  COLUMNS_FAKE,
  DONE_STATUS_ASSIGNMENT,
  PRIORITY,
  PRIORITY_STYLE,
  QUICK_FILTER_PERSONAL,
  TASK_STATUS,
} from '../../constant'
import {GLOBAL_GLOSSARY} from '../../../../../utils/constant'

const customStyle1 = makeStyles(() => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 7,
  },
  pfGroup: {
    fontSize: 12,
    fontWeight: '600',
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
  },
  box: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 4,
  },
  emptyStateWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 254,
  },
  emptyState: {
    fontSize: 14,
    color: '#a9a8a8',
    textAlign: 'center',
  },
  buttonActionMore: {marginTop: -12},
  kanbanContainer: {
    display: 'grid',
    boxSizing: 'border-box',
    gridTemplateColumns: 'repeat(auto-fit, 258px)',
    gap: '0px 18px',
  },
  kanbanItem: {
    width: '100%',
    boxSizing: 'border-box',
  },
  cardContent: {
    padding: 0,
  },
  avatar: {
    fontSize: 12,
    width: 30,
    height: 30,
  },
}))

const customStyle = {
  cardItem: {
    padding: 12,
    fontSize: 13,
    width: '90%',
    height: '154px !important',
    marginBottom: 20,
    cursor: 'pointer',
  },
  textSkyBlue: {
    color: '#039be5',
  },
  textDate: {
    color: '#a9a8a8',
    marginBottom: 0,
  },
  canbanCard: {
    backgroundColor: '#f7f8f9',
    margin: '20px 15px 20px 0px',
    maxWidth: '31%',
    padding: '15px',
  },
  canbanCardTodo: {
    backgroundColor: 'rgba(3, 155, 229, 0.1)',
    padding: '15px',
    borderRadius: 5,
    height: '100%',
  },
  canbanCardInProgress: {
    backgroundColor: 'rgba(255, 160, 0, 0.1)',
    padding: '15px',
    borderRadius: 5,
    height: '100%',
  },
  canbanCardDone: {
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    padding: '15px',
    borderRadius: 5,
    height: '100%',
  },
  pos: {
    width: '100%',
    height: '13px',
    fontFamily: 'Muli',
    fontSize: '12px',
    lineHeight: '13px',
    textAlign: 'left',
    color: '#039be5',
    marginBottom: 15,
    marginTop: 12,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    verticalAlign: 'text-top',
  },
  marginCard: {marginTop: '35px'},
  btnGreen: {backgroundColor: '#4caf50', textTransform: 'none'},
  btnSkyBlue: {backgroundColor: '#039be5', textTransform: 'none'},
  status: {margin: '2px 0px 15px 0px'},
  divider: {border: '1px solid #e5e5e5', width: '100%', marginTop: 40},
  rootList: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  rootListItem: {
    padding: '3.5px 24px',
  },
}

export default function ListTask(props) {
  const {search, idGoal, sorting, filterData, filterValue} = props
  const client = useApolloClient()
  const {dataContext, setDataContext} = useContext(DataContext)
  const classes = customStyle1()
  const [columns, setColumns] = useState(COLUMNS_FAKE)
  const [open, setOpen] = useState(false)
  const [openMoveTask, setOpenMoveTask] = useState({
    open: false,
    target: '',
    data: null,
    id: null,
    editProgress: false,
  })
  const [selectedId, setSelectedId] = useState(0)
  const [type, setType] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openArchive, setOpenArchive] = useState(false)
  const [dataArchive, setDataArchive] = useState({})
  const [dataEdit, setDataEdit] = useState([])
  const [states, setStates] = React.useState({
    open: false,
    message: '',
  })
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 5,
    hasMore: true,
  })
  const [dataModal, setDataModal] = useState([])

  const handleClickOpen = id => {
    setOpen(true)
    setSelectedId(id)
    setType('task')
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleMoveToArchive = (dataArc, e) => {
    e.stopPropagation()
    setDataArchive(dataArc)
    setOpenArchive(true)
  }

  const handleElipsis = async (action, data, e) => {
    e.stopPropagation()
    setDataEdit(data)
    if (action === 'edit') {
      const {
        data: _data,
        error: _error,
        loading: _loading,
      } = await client.query({
        query: GET_DETAIL_CONTAINER_TASK,
        variables: {id: data.id},
      })

      if (_loading) {
        return <div>Loading....</div>
      } else if (_error !== undefined) {
        return <div>{JSON.stringify(_error)}</div>
      }
      setDataModal(_data.performance_tasks_by_pk)
      return setOpenEdit(true)
    } else if (action === 'delete') {
      setOpenDelete(true)
    }
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setTimeout(() => {
      setDataContext(null)
    }, 5000)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    setTimeout(() => {
      setDataContext(null)
    }, 1000)
  }

  const [deleteTask] = useMutation(DELETE_TASK)
  const [updateTask] = useMutation(UPDATE_TASK)
  const [updateTaskAttachments] = useMutation(UPDATE_TASK_ATTACHMENTS)

  const onDeleteTask = () => {
    deleteTask({variables: {id: dataEdit.id}})
      .then(() => {
        setStates({open: true, message: 'Data deleted'})
        setDataContext(true)
        refetchAllListTask()
      })
      .catch(() => {
        setStates({
          open: true,
          message: 'Update data error, please try again',
        })
      })
  }

  function handleCloseSnackbar() {
    setStates({...states, open: false})
  }

  const query =
    filterValue === QUICK_FILTER_PERSONAL
      ? GET_LIST_ALL_PERSONAL_TASK
      : GET_LIST_ALL_DEPARTMENT_TASK

  const variables = {
    offset: infiniteLoad.offset,
    limit: infiniteLoad.limit,
    company: COMPANY_ID,
    search: `%${search}%`,
    user: USER_ID,
    isCompany: true,
    isPersonal: true,
    goal: idGoal,
    order: sorting?.value,
    ...filterData?.include,
  }

  const isFilterStatus = !filterData?.include?.status?.[0]
  const filterStatus = filterData?.include?.status

  const statusDone = []
  if (filterStatus?.includes('DONE')) {
    statusDone.push('DONE')
  }
  if (filterStatus?.includes('COMPLETED')) {
    statusDone.push('COMPLETED')
  }

  const {
    data: dataTodo,
    error: errorTodo,
    refetch: refetchTodo,
  } = useQuery(query, {variables: {...variables, status: ['TODO']}})

  const {
    data: dataInprogress,
    error: errorInprogress,
    refetch: refetchInprogress,
  } = useQuery(query, {variables: {...variables, status: ['INPROGRESS']}})

  const {data: dataDone, error: errorDone, refetch: refetchDone} = useQuery(
    query,
    {
      variables: {
        ...variables,
        status: statusDone.length > 0 ? statusDone : DONE_STATUS_ASSIGNMENT,
      },
    }
  )

  const refetchAllListTask = () => {
    refetchTodo()
    refetchInprogress()
    refetchDone()
  }

  useEffect(() => {
    if (dataContext) {
      refetchAllListTask()
    }
  }, [dataContext])

  const onDragEnd = async result => {
    const {destination, source, draggableId} = result
    const id = source.index

    const {loading, data, error} = await client.query({
      query: GET_DETAIL_CONTAINER_TASK,
      variables: {id: id},
    })

    if (loading) {
      return <div>Loading....</div>
    } else if (error !== undefined) {
      return <div>{error}</div>
    }

    const destinationStatus = {
      'column-1': 'TODO',
      'column-2': 'INPROGRESS',
      'column-3': 'DONE',
    }

    const dataMoveTask = data.performance_tasks_by_pk
    const isCanUpdate =
      (source?.droppableId === 'column-1' &&
        destination?.droppableId === 'column-2') ||
      (source?.droppableId === 'column-3' &&
        destination?.droppableId === 'column-2')
    if (destination?.droppableId !== source?.droppableId) {
      setOpenMoveTask({
        ...openMoveTask,
        open: true,
        target: destinationStatus[destination?.droppableId],
        data: dataMoveTask,
        id,
        editProgress: isCanUpdate,
      })
    }

    if (!destination) {
      return
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const column = columns[source.droppableId]
    const newTaskIds = Array.from(column.taskIds)
    newTaskIds.splice(source.index, 1)
    newTaskIds.splice(destination.index, 0, draggableId)

    const newColumn = {
      ...column,
      taskIds: newTaskIds,
    }

    const newState = {
      ...columns,
      [newColumn.taskIds]: newColumn,
    }
    setColumns(newState)
  }

  if (
    (dataTodo === undefined && errorTodo === undefined) ||
    (dataInprogress === undefined && errorInprogress === undefined) ||
    (dataDone === undefined && errorDone === undefined)
  ) {
    return <CircularProgress />
  } else if (
    errorTodo !== undefined ||
    errorInprogress !== undefined ||
    errorDone !== undefined
  ) {
    return (
      <div>{JSON.stringify(errorTodo || errorInprogress || errorDone)}</div>
    )
  }

  const renderListData = idTask => {
    if (idTask?.total?.aggregate.count === 0 || idTask === null) {
      return (
        <div className={classes.emptyStateWrap}>
          <p className={classes.emptyState}>
            Tidak ada {GLOBAL_GLOSSARY.performance.Task} di sini
          </p>
        </div>
      )
    }

    return idTask?.performance_tasks?.map((res, key) => (
      <Draggable draggableId={res.id} index={res.id} key={res.id}>
        {provided => (
          <TaskCard
            style={customStyle.canbanCard}
            key={key}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <ContainerListGoal idTask={res.id} />
          </TaskCard>
        )}
      </Draggable>
    ))
  }

  const ContainerListGoal = ({idTask, key}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {data, error, refetch} = useQuery(GET_LIST_CONTAINER_TASK, {
      variables: {id: idTask},
      errorPolicy: 'ignore',
    })

    useEffect(() => {
      if (dataContext) {
        refetch()
      }
    }, [dataContext])

    if (data === undefined && error === undefined) {
      return (
        <Card
          className={customStyle.canbanCard}
          key={key}
          style={{marginBottom: '1rem'}}
          elevation={0}
        >
          <CardContent className={customStyle.cardItem}>
            <Skeleton width="100%" height={90} />
            <Skeleton circle width={50} height={50} />
            <Skeleton width="100%" height={60} />
          </CardContent>
        </Card>
      )
    }

    const res = data && data.performance_tasks_by_pk
    const typeGoal =
      res?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate
        .count > 0

    const handleClickMore = e => {
      e.stopPropagation()
      setAnchorEl(e.currentTarget)
    }

    const handleClosePopup = e => {
      e.stopPropagation()
      setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? `more-${idTask}` : undefined

    const listMore = [
      {
        isShow: true,
        label: 'Ubah',
        onClick: e => handleElipsis('edit', res, e),
      },
      {
        isShow: true,
        label: 'Hapus',
        onClick: e => handleElipsis('delete', res, e),
      },
      {
        isShow: res?.status?.toLowerCase() === 'completed',
        label: 'Pindah ke Arsip',
        onClick: e => handleMoveToArchive(res, e),
      },
    ]

    return (
      <Card
        onMouseLeave={() => handleMouseEvent(res)}
        style={customStyle.cardItem}
        elevation={0}
        onClick={() => handleClickOpen(res.id)}
      >
        <CardContent classes={{root: classes.cardContent}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={10}>
              <div className={classes.pfGroupWrap}>
                <div
                  className={classes.box}
                  style={{
                    backgroundColor: res?.performance_group?.color || '#e5e5e5',
                  }}
                />
                <Typography
                  className={classes.pfGroup}
                  gutterBottom
                  style={{
                    color: res?.performance_group ? '#014a62' : '#a9a8a8',
                    fontStyle: res?.performance_group ? 'initial' : 'italic',
                  }}
                >
                  {res?.performance_group?.name || 'Tidak Ada'}
                </Typography>
              </div>
              <Typography component="h3" className={classes.title}>
                {res.name}
              </Typography>
            </Grid>
            {res.created_by_user.id === USER_ID && (
              <Grid item xs={12} sm={12} md={2}>
                <IconButton
                  className={classes.buttonActionMore}
                  onClick={handleClickMore}
                >
                  <Icon>more_horiz</Icon>
                </IconButton>
              </Grid>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClosePopup}
              classes={{paper: classes.rootList}}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <List>
                {listMore
                  ?.filter(({isShow}) => isShow)
                  ?.map(({label, onClick}) => (
                    <StyledMenuItem
                      className={classes.rootListItem}
                      key={label}
                      onClick={onClick}
                    >
                      {label}
                    </StyledMenuItem>
                  ))}
              </List>
            </Popover>
          </Grid>
          <div style={customStyle.status}>
            <Typography
              style={customStyle.pos}
              color="textSecondary"
              component="span"
            >
              {TASK_STATUS[res?.status] || res?.status?.toLowerCase()}
            </Typography>
            {res?.status?.toLowerCase() === 'completed' && (
              <CheckCircle
                color="secondary"
                style={{fontSize: 15, marginLeft: '5px'}}
              />
            )}
          </div>
          <Grid
            style={{marginBottom: 18, gap: 6, boxSizing: 'unset'}}
            container
            item={true}
          >
            {res?.performance_task_assignments
              ?.slice(0, 4)
              .map((resImg, key) => (
                <Avatar
                  item="true"
                  className={classes.avatar}
                  md={2}
                  alt={resImg?.global_user?.name}
                  src={resImg?.global_user?.avatar}
                  key={key}
                />
              ))}
            {res?.performance_task_assignments?.length > 4 && (
              <Avatar className={classes.avatar}>
                +{res.performance_task_assignments.length - 4}
              </Avatar>
            )}
          </Grid>
        </CardContent>
        <CardActions classes={{root: classes.cardContent}}>
          <Grid container>
            <Grid item md={12}>
              <GoalProgressIndicator
                style={{
                  textAlign: 'left',
                  marginBottom: 12,
                  fontSize: 20,
                  fontWeight: '600',
                  color: DONE_STATUS_ASSIGNMENT.includes(res.status)
                    ? '#4caf50'
                    : '#039be5',
                }}
              >
                {`${
                  res.progress_percentage
                    ? res.progress_percentage.toFixed(2)
                    : 0
                }%`}
              </GoalProgressIndicator>
            </Grid>
            <div
              style={{
                paddingTop: '8px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: 6,
              }}
            >
              <GoalsListBtn
                onClick={() => handleClickOpen(res.id)}
                style={{
                  textTransform: 'none',
                  backgroundColor: PRIORITY_STYLE[res.priority] || '#FFFFFF',
                }}
              >
                {PRIORITY[res.priority] || res.priority}
              </GoalsListBtn>
              <GoalsListBtn
                onClick={() => handleClickOpen(res.id)}
                style={{
                  width: 'auto',
                  backgroundColor: typeGoal ? '#a35a31' : '#874caf',
                }}
              >
                {typeGoal
                  ? GLOBAL_GLOSSARY.performance['Company Goal']
                  : GLOBAL_GLOSSARY.performance['Personal Goal']}
              </GoalsListBtn>
            </div>
          </Grid>
        </CardActions>
      </Card>
    )
  }

  const handleMouseEvent = data => {
    setOpenMoveTask({...openMoveTask, data})
  }

  const handleSubmitMoveTask = (progress, attachment) => {
    updateTask({
      variables: {
        id: openMoveTask.id,
        status: openMoveTask.target,
        progress: progress,
      },
    })
      .then(() => {
        setStates({open: true, message: 'Task Updated'})
        setOpenMoveTask({
          ...openMoveTask,
          open: false,
          target: '',
          data: null,
          id: null,
          editProgress: false,
        })
        refetchAllListTask()
        setDataContext(true)
        setTimeout(() => {
          setDataContext(null)
        }, 1000)
      })
      .catch(() => {
        setStates({open: true, message: `Update task error, please try again`})
      })

    if (attachment?.attachment?.length > 0) {
      updateTaskAttachments({
        variables: {
          task: openMoveTask.id,
          attachment: attachment?.attachment,
          progress: progress,
        },
      })
        .then(() => {})
        .catch(err => {
          setStates({
            open: true,
            message: `Error while update attachment, ${err}`,
          })
        })
    }
  }

  const lengthTaskTodo = dataTodo.total.aggregate.count
  const lengthTaskInprogress = dataInprogress.total.aggregate.count
  const lengthTaskDone = dataDone.total.aggregate.count
  const starterInfiniteLoading =
    lengthTaskTodo > 5 || lengthTaskInprogress > 5 || lengthTaskDone > 5

  const fetchMoreData = () => {
    if (
      infiniteLoad.limit >= lengthTaskTodo ||
      infiniteLoad.limit >= lengthTaskInprogress ||
      infiniteLoad.limit >= lengthTaskDone
    ) {
      setInfiniteLoad(prev => ({
        ...prev,
        hasMore: false,
      }))
    }
    setTimeout(() => {
      setInfiniteLoad(prev => ({
        ...prev,
        limit: prev.limit + 5,
      }))
    }, 1000)
  }

  const onMoveToArchive = () => {
    updateTask({
      variables: {
        id: dataArchive.id,
        status: 'ARCHIVED',
        progress: dataArchive.progress,
      },
    })
      .then(() => {
        setStates({open: true, message: 'Task Updated'})
        setOpenArchive(false)
        refetchAllListTask()
      })
      .catch(() => {
        setStates({open: true, message: `Update task error, please try again`})
      })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <InfiniteScroll
        style={{marginBottom: !idGoal ? '100px' : '0px', overflow: 'hidden'}}
        dataLength={infiniteLoad.limit}
        next={fetchMoreData}
        hasMore={starterInfiniteLoading ? infiniteLoad.hasMore : false}
        loader={
          <div style={{textAlign: 'center'}}>
            <CircularProgress size={15} style={{marginRight: '10px'}} />
            <span>Loading</span>
          </div>
        }
      >
        <div className={classes.kanbanContainer}>
          <Droppable droppableId={columns['column-1'].id}>
            {provided => (
              <div className={classes.kanbanItem}>
                <Grid
                  style={customStyle.canbanCardTodo}
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <TitleContent style={{marginLeft: '0px'}}>
                    {TASK_STATUS.TODO}
                  </TitleContent>

                  <Grid style={{width: '100%'}}>
                    <ModalTab
                      open={open}
                      handleClose={handleClose}
                      id={selectedId}
                      type={type}
                    />
                    {renderListData(
                      isFilterStatus || filterStatus.includes('TODO')
                        ? dataTodo
                        : null
                    )}
                  </Grid>
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>

          <Droppable droppableId={columns['column-2'].id}>
            {provided => (
              <div className={classes.kanbanItem}>
                <Grid
                  style={customStyle.canbanCardInProgress}
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <FlexBetween>
                    <TitleContent style={{marginLeft: '0px'}}>
                      {TASK_STATUS.INPROGRESS}
                    </TitleContent>
                  </FlexBetween>
                  <Grid style={{width: '100%'}}>
                    {renderListData(
                      isFilterStatus || filterStatus.includes('INPROGRESS')
                        ? dataInprogress
                        : null
                    )}
                  </Grid>
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>

          <Droppable droppableId={columns['column-3'].id}>
            {provided => (
              <div className={classes.kanbanItem}>
                <Grid
                  style={customStyle.canbanCardDone}
                  innerRef={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <FlexBetween>
                    <TitleContent style={{marginLeft: '0px'}}>
                      {TASK_STATUS.DONE}
                    </TitleContent>
                  </FlexBetween>
                  <Grid style={{width: '100%'}}>
                    {renderListData(
                      isFilterStatus ||
                        filterStatus.includes('DONE') ||
                        filterStatus.includes('COMPLETED')
                        ? dataDone
                        : null
                    )}
                  </Grid>
                  {provided.placeholder}
                </Grid>
              </div>
            )}
          </Droppable>
        </div>
      </InfiniteScroll>
      <ConfirmationPopup
        open={openArchive}
        handleClose={() => setOpenArchive(false)}
        name={dataArchive && dataArchive.name}
        fMessage="Are you sure to move task "
        lMessage="  to archive?"
        feature={`Move ${dataArchive && dataArchive.name} to Archive?`}
        type="Confirm"
        mutation={onMoveToArchive}
      />
      <ModalEdit
        open={openEdit}
        onBack={handleCloseEdit}
        dataEdit={dataModal}
        type="edit-task"
      />
      <ModalDelete
        open={openDelete}
        handleClose={handleCloseDelete}
        name={dataEdit.name}
        mutation={() => onDeleteTask()}
      />
      <ModalMoveTask
        open={openMoveTask.open}
        target={openMoveTask.target}
        data={openMoveTask.data}
        isCanUpdate={openMoveTask.editProgress}
        onClose={() => {
          setOpenMoveTask({
            ...openMoveTask,
            open: false,
            target: '',
            data: null,
            id: null,
            editProgress: false,
          })
        }}
        onSubmit={(progress, attachment) =>
          handleSubmitMoveTask(progress, attachment)
        }
      />
      {snackBar(handleCloseSnackbar, states.open, states.message)}
    </DragDropContext>
  )
}
