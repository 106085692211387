import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  Checkbox,
} from '@material-ui/core'

import {makeStyles} from '@material-ui/styles'
import {ModalHeader} from '../detail-modal/ModalPerformanceStyles'
import {FlexBetween} from '../PerformanceStyles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
}))

export default function ModalDelete(props) {
  const classes = useStyles()
  const {
    open,
    handleClose,
    name,
    mutation,
    isHighlight = true,
    description = '',
    canClose = true,
    titleCapitalize = true,
  } = props
  const [checked, setChecked] = useState(false)
  const handleChange = e => setChecked(e.target.checked)
  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <ModalHeader style={{backgroundColor: '#FFFFFF'}}>
        <FlexBetween>
          <Typography
            variant="h6"
            color="error"
            className={classes.wordBreak}
            style={{
              textTransform: titleCapitalize ? 'capitalize' : 'initial',
              fontSize: 18,
            }}
          >
            Hapus {name}?
          </Typography>
          {canClose && (
            <IconButton aria-label="close" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          )}
        </FlexBetween>
      </ModalHeader>
      <DialogContent className={classes.content}>
        <Typography variant="body2" display="inline">
          Apakah Anda yakin untuk menghapus
        </Typography>
        <Typography
          variant="body2"
          color={isHighlight ? 'error' : 'initial'}
          display="inline"
          className={classes.wordBreak}
        >
          {` ${name} `}
        </Typography>
        <Typography variant="body2" display="inline">
          secara permanen?
        </Typography>
        <div className={classes.checkContainer}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            color="primary"
            className={classes.checkbox}
          />
          <Typography variant="body2">
            {`Saya mengerti bahwa menghapus ${
              isHighlight ? 'file ini' : name
            } bersifat permanen dan tidak
            dapat dibatalkan. ${description}`}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose}>Batalkan</Button>
        <Button
          className={classes.btnDel}
          disabled={!checked}
          variant="contained"
          onClick={() => {
            setChecked(false)
            handleClose()
            mutation()
          }}
        >
          Hapus
        </Button>
      </DialogActions>
    </Dialog>
  )
}
