import gql from 'graphql-tag'

export const GET_DETAIL_FEEDBACK = gql`
  query detailOGF($from: uuid, $to: uuid!) {
    ongoing_feedback_lists(
      where: {ongoing_to: {_eq: $to}, ongoing_from: {_eq: $from}}
      order_by: [{date_added: desc}]
    ) {
      id
      description
      summary
      date_added
      global_user_from {
        id
        name
        avatar
        people_work_placements {
          id
          company_employee_position {
            id
            name
          }
          company_address {
            id
            office_name
          }
        }
      }
    }

    satisfied: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $to}
        ongoing_from: {_eq: $from}
        summary: {_eq: "SATISFIED"}
      }
    ) {
      aggregate {
        count
      }
    }

    ordinary: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $to}
        ongoing_from: {_eq: $from}
        summary: {_eq: "ORDINARY"}
      }
    ) {
      aggregate {
        count
      }
    }

    disapointed: ongoing_feedback_lists_aggregate(
      where: {
        ongoing_to: {_eq: $to}
        ongoing_from: {_eq: $from}
        summary: {_eq: "DISAPPOINTED"}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`
