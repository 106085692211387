import React, {useContext, useEffect} from 'react'

import {useQuery} from '@apollo/react-hooks'
import {makeStyles, Typography} from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'

import {GET_LIST_CONTAINER_TASK} from '../../../../../graphql/queries'
import {USER_ID} from '../../../../../utils/globals'
import {
  COMPANY_GOAL_TYPE,
  PERSONAL_GOAL_TYPE,
  TASK_STATUS,
} from '../../constant'

import Loader from '../Loader'
import CardItem from '../../CardItem'
import {DataContext} from '../../../../../contexts/ContextConfig'

const useStyles = makeStyles(() => ({
  titleWrap: {
    marginBottom: 2,
  },
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 7,
  },
  pfGroup: {
    fontSize: 12,
    fontWeight: '600',
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
  },
  status: {fontSize: 12},
  box: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 4,
  },
  title: {
    maxWidth: '86%',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  subheaderWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 0px 9px',
  },
}))

export default function TaskContainer({id, handleElipsis}) {
  const {dataContext} = useContext(DataContext)

  const {data, error, loading, refetch} = useQuery(GET_LIST_CONTAINER_TASK, {
    variables: {id},
    errorPolicy: 'ignore',
  })

  useEffect(() => {
    if (dataContext) {
      refetch()
    }
  }, [dataContext])

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.error(error)
  }

  const pf_task = data?.performance_tasks_by_pk
  const isCompanyGoal =
    pf_task?.performance_goal?.kpi_list?.kpi_cascading_lists_aggregate
      ?.aggregate.count > 0

  const listAction = [
    {
      isShow: true,
      label: 'Ubah',
      onClick: () => handleElipsis('edit', pf_task),
    },
    {
      isShow: true,
      label: 'Hapus',
      onClick: () => handleElipsis('delete', pf_task),
    },
    {
      isShow: pf_task?.status === 'COMPLETED',
      label: 'Pindah ke Arsip',
      onClick: () => handleElipsis('archive', pf_task),
    },
  ]

  return (
    <CardItem
      id={id}
      title={<Title data={pf_task} />}
      subheader={<Subheader status={pf_task?.status} />}
      onClick={() => handleElipsis('detail', pf_task)}
      listAction={pf_task?.created_by_user.id === USER_ID ? listAction : []}
      type={isCompanyGoal ? COMPANY_GOAL_TYPE : PERSONAL_GOAL_TYPE}
      priority={pf_task?.priority}
      percentage={pf_task?.progress_percentage}
      avatar={pf_task?.performance_task_assignments || []}
      percentageColor="#039be5"
    />
  )
}

export const Title = ({data}) => {
  const classes = useStyles()

  return (
    <div className={classes.titleWrap}>
      <div className={classes.pfGroupWrap}>
        <div
          className={classes.box}
          style={{
            backgroundColor: data?.performance_group?.color || '#e5e5e5',
          }}
        />
        <Typography
          className={classes.pfGroup}
          gutterBottom
          style={{
            color: data?.performance_group ? '#014a62' : '#a9a8a8',
            fontStyle: data?.performance_group ? 'initial' : 'italic',
          }}
        >
          {data?.performance_group?.name || 'Tidak Ada'}
        </Typography>
      </div>
      <Typography component="h3" className={classes.title}>
        {data?.name}
      </Typography>
    </div>
  )
}

export const Subheader = ({status}) => {
  const classes = useStyles()

  return (
    <div className={classes.subheaderWrap}>
      <Typography className={classes.status} color="secondary" component="p">
        {TASK_STATUS[status] || status?.toLowerCase()}
      </Typography>
      {status === 'COMPLETED' && (
        <CheckCircle
          color="secondary"
          style={{fontSize: 15, marginLeft: '5px'}}
        />
      )}
    </div>
  )
}
