import {useMutation, useQuery} from '@apollo/react-hooks'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {
  GET_DETAIL_GENERAL_SQUAD,
  GET_EXPORT_DISABLED_BUTTON_CONTAINS_FILTER,
  GET_LIST_MEMBER_GENERAL_SQUAD,
  GET_LIST_SUGGESTION_GENERAL_SQUAD_MEMBER,
  GET_STATUS_EXPORT_BALOON_CONTAINS_FILTER,
} from '../../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../../utils/globals'
import {isDateBetween} from '../../../../utils/helpers'
import EditSquadMember from '../shared/EditSquadMember'
import HeaderSquadDetail from '../shared/HeaderSquadDetail'
import ListMember from '../shared/list-squad-member/ListMember'
import PopupDetailSquad from '../shared/PopupDetailSquad'
import AddSquadMember from '../shared/AddSquadMember'
import AddEditGeneralSquad from './AddEditGeneralSquad'
import {getSquadPeriod} from './ListGeneralSquad'
import NotificationPopup from '../../../shared-component/NotificationPopup'
import {
  ADD_GENERAL_SQUAD_MEMBER,
  DELETE_GENERAL_SQUAD_MEMBER,
} from '../../../../graphql/mutations'
import {useSnackbar} from 'notistack'
import ExportBaloon from '../../../shared-component/ExportBaloon'
import useDownloadReport from '../../../../hooks/useDownloadReport'
import moment from 'moment'
import ChoosePeriodPopup from '../../../shared-component/ChoosePeriodPopup'

import {makeStyles, Paper} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    padding: '24px 18px',
    backgroundColor: '#fff',
  },
}))

const TABLE_NAME = 'performance_goal_team'

const DEFAULT_NOTIFICATION = {
  open: false,
  title: '',
  message: '',
  onConfirm: null,
  onClose: null,
}

const ListMemeberGeneralSquad = props => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [openAddMember, setOpenAddMember] = useState(false)
  const [openEditMember, setOpenEditMember] = useState(false)
  const [openDetailInfo, setOpenDetailInfo] = useState(false)
  const [selectedAddMember, setSelectedAddMember] = useState([])
  const [openExport, setExport] = useState(false)
  const [notification, setNotification] = useState(DEFAULT_NOTIFICATION)

  const [addGeneralSquadMember] = useMutation(ADD_GENERAL_SQUAD_MEMBER)
  const [deleteGeneralSquadMember] = useMutation(DELETE_GENERAL_SQUAD_MEMBER)

  const ID_GROUP = parseInt(props.match.params.id)

  const {
    dataBaloon: getExportBaloon,
    isDisableButton,
    onDownload,
    onCloseBaloon,
  } = useDownloadReport({
    table: TABLE_NAME,
    filter: {id: ID_GROUP},
    query: {
      getStatusBaloon: GET_STATUS_EXPORT_BALOON_CONTAINS_FILTER,
      getDisabledButton: GET_EXPORT_DISABLED_BUTTON_CONTAINS_FILTER,
    },
  })

  const {
    data: dataDetail,
    refetch: refetchDetailSquad,
    loading: loadingDetail,
  } = useQuery(GET_DETAIL_GENERAL_SQUAD, {
    variables: {
      id: ID_GROUP,
      company: COMPANY_ID,
      user: USER_ID,
      limit: 1,
    },
  })

  const {data, loading, error, refetch, fetchMore} = useQuery(
    GET_LIST_MEMBER_GENERAL_SQUAD,
    {
      fetchPolicy: 'network-only',
      variables: {
        group: ID_GROUP,
        search: `%${search}%`,
        limit: 20,
        offset: 0,
      },
    }
  )

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_work_placements.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_work_placements: [
            ...prev.people_work_placements,
            ...fetchMoreResult.people_work_placements,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const handleDownload = ({startDate, endDate}) => {
    const format = 'YYYY-MM-DD'
    const successAction = () => {
      setExport(false)
      setNotification(e => ({
        ...e,
        open: true,
        title: 'Pembuatan data sedang diproses!',
        message:
          'Pembuatan data sedang diproses! Kami akan mengirimkan email kepada Anda jika sudah selesai.',
        onConfirm: () => setNotification(DEFAULT_NOTIFICATION),
      }))
      enqueueSnackbar('Pembuatan data sedang diproses!', {
        variant: 'success',
        autoHideDuration: 1000,
      })
    }
    const errorAction = () => {
      setExport(false)
      enqueueSnackbar('Generate Data failed, please try again later', {
        variant: 'error',
        autoHideDuration: 1000,
      })
    }

    onDownload({
      variables: {
        table: TABLE_NAME,
        filter: {
          id: ID_GROUP,
          ...(startDate &&
            endDate && {
              start_date: moment(startDate).format(format),
              end_date: moment(endDate).format(format),
            }),
        },
      },
      action: {
        success: () => successAction(),
        error: () => errorAction(),
      },
    })
  }

  const closeBaloonExportMutation = async () => {
    await onCloseBaloon(getExportBaloon?.company_export_data_logs?.[0]?.id)
  }

  const handleDeleteMember = (data, refetchList) => {
    deleteGeneralSquadMember({
      variables: {
        group: ID_GROUP,
        placement: data.id,
      },
    })
      .then(() => {
        refetchList()
        enqueueSnackbar('Delete Squad Member success', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Delete Squad Member error, please try again later', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const addMember = () => {
    const variables = {
      team: selectedAddMember.map(res => ({
        member_placement: res.id,
        group: ID_GROUP,
      })),
    }
    addGeneralSquadMember({
      variables,
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Menambahkan Anggota Tim Kerja Berhasil', {
          variant: 'success',
          autoHideDuration: 2000,
        })
      })
      .catch(() => {
        enqueueSnackbar('Menambahkan Anggota Tim Kerja Gagal', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      })
  }

  const handleCloseAddMember = () => {
    setOpenAddMember(false)
    setNotification({
      open: true,
      title: 'Batal Tambah Anggota Tim Kerja?',
      message:
        'Apakah Anda yakin akan membatalkan penambahan anggota ke Tim Kerja Anda?',
      onClose: () => {
        setNotification(DEFAULT_NOTIFICATION)
        setOpenAddMember(true)
      },
      onConfirm: () => {
        setSelectedAddMember([])
        setNotification(DEFAULT_NOTIFICATION)
      },
    })
  }

  const handleSaveAddMember = () => {
    setOpenAddMember(false)
    setNotification({
      open: true,
      title: 'Tambah Anggota Tim Kerja?',
      message:
        'Apakah anda yakin ingin menambah anggota tim kerja? Orang yang anda pilih akan mendapatkan notifikasi',
      onClose: () => {
        setNotification(DEFAULT_NOTIFICATION)
        setOpenAddMember(true)
      },
      onConfirm: () => {
        addMember()
        setSelectedAddMember([])
        setNotification(DEFAULT_NOTIFICATION)
      },
    })
  }

  const {name, description, start_date, end_date} =
    dataDetail?.performance_squad_groups?.[0] || {}

  const IS_ACTIVE_SQUAD = isDateBetween(new Date(), start_date, end_date)

  return (
    <Paper className={classes.root}>
      <ExportBaloon
        data={getExportBaloon}
        closeBaloonMutation={closeBaloonExportMutation}
        retry={handleDownload}
        showCloseButton={isDisableButton}
      />

      <HeaderSquadDetail
        squadName={name}
        isDisabledReport={isDisableButton}
        squadDate={getSquadPeriod(start_date, end_date, 'll')}
        onClickShowDetail={() => setOpenDetailInfo(true)}
        isActiveSquad={(!loadingDetail && IS_ACTIVE_SQUAD) || ''}
        onClickSquadInformation={() => setOpen(true)}
        onClickSquadMember={() => setOpenEditMember(true)}
        onClickGenerateReport={() => setExport(true)}
      />

      <ListMember
        setSearch={setSearch}
        search={search}
        data={data}
        loading={loading}
        error={error}
        refetch={refetch}
        fetchMoreData={fetchMoreData}
        onClickInviteMember={() => setOpenAddMember(true)}
        {...props}
      />

      <AddEditGeneralSquad
        actionType="Edit"
        open={open}
        onClose={() => setOpen(false)}
        refetch={refetchDetailSquad}
        squadId={ID_GROUP}
        squadName={name}
        squadDescription={description}
        startDate={start_date}
        endDate={end_date}
      />

      <EditSquadMember
        squadType="GENERAL"
        open={openEditMember}
        onClose={() => {
          refetch()
          setOpenEditMember(false)
        }}
        query={GET_LIST_MEMBER_GENERAL_SQUAD}
        variablesQuery={{group: ID_GROUP}}
        onDelete={handleDeleteMember}
        onInviteMember={() => {
          setOpenEditMember(false)
          setOpenAddMember(true)
        }}
      />

      <AddSquadMember
        open={openAddMember}
        onClose={handleCloseAddMember}
        onSave={handleSaveAddMember}
        selectedAddMember={selectedAddMember}
        setSelectedAddMember={setSelectedAddMember}
        query={GET_LIST_SUGGESTION_GENERAL_SQUAD_MEMBER}
        variablesQuery={{group: ID_GROUP}}
      />

      <PopupDetailSquad
        name={name}
        description={description}
        status={IS_ACTIVE_SQUAD ? 'Active' : 'Tidak Aktif'}
        period={getSquadPeriod(start_date, end_date, 'LL')}
        open={openDetailInfo}
        onClose={() => setOpenDetailInfo(false)}
      />

      <NotificationPopup
        open={notification.open}
        title={notification.title}
        message={notification.message}
        onConfirm={notification.onConfirm}
        onClose={notification.onClose}
        onCancel={notification.onClose}
      />

      <ChoosePeriodPopup
        open={openExport}
        onClose={() => setExport(false)}
        onConfirm={handleDownload}
        enableFuture
        custom
      />
    </Paper>
  )
}

export default withRouter(ListMemeberGeneralSquad)
