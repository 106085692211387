/**
 * Common words that change frequently
 * see: https://smartasn.atlassian.net/browse/SMARTASN-109?focusedCommentId=10494
 */
export const GLOBAL_GLOSSARY = {
  performance: {
    Goal: 'Rencana Hasil Kerja',
    Goal_short: 'RHK',
    Task: 'Rencana Aksi',
    Activity: 'Realisasi',
    /**
     * `Description_goal` here in the context of th e `Description` on `Goal`
     */
    Description_goal: 'Indikator Kinerja',
    Reporter: 'Pemberi Tugas',
    'Personal Goal': 'Hasil Kerja Pribadi',
    'Company Goal': 'Hasil Kerja Unit',
    'Weight Unit': 'Satuan Target',
    'Target Base': 'Target',
    Qualitative: 'Kualitatif',
    Quantitative: 'Kuantitatif',
    OGF: 'Umpan Balik Berkelanjutan',
    NPS: 'NPS',
  },
}

export const parameters_versions = {
  attendance: {EN: 'Attendance', ID: 'Kehadiran'},
  competency: {EN: 'Competency', ID: 'Kompetensi'},
  course: {EN: 'Course', ID: 'Pembelajaran'},
  socmed_post: {EN: 'Social Media Post', ID: 'Postingan Sosial Media'},
  task: {EN: 'Task', ID: GLOBAL_GLOSSARY.performance.Task},
  ogf: {EN: 'On Going Feedback', ID: GLOBAL_GLOSSARY.performance.OGF},
  multirater: {EN: 'Multirater', ID: 'Multirater'},
  nps: {EN: 'NPS', ID: GLOBAL_GLOSSARY.performance.NPS},
}
