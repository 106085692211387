import gql from 'graphql-tag'

export const ADD_NEW_SURVEY_RESPONSE = gql`
  mutation addMultiraterSurveyResponse(
    $data: [multirater_survey_responses_insert_input!]!
  ) {
    insert_multirater_survey_responses(objects: $data) {
      affected_rows
    }
  }
`

export const ADD_SURVEY_WITH_DELETE = gql`
  mutation addMultiraterWithDelete(
    $respond_from: uuid!
    $respond_to: uuid!
    $survey: Int
    $group: Int
    $question: Int
    $data: [multirater_survey_responses_insert_input!]!
  ) {
    delete_multirater_survey_responses(
      where: {
        respond_from: {_eq: $respond_from}
        respond_to: {_eq: $respond_to}
        survey: {_eq: $survey}
        group: {_eq: $group}
        question: {_eq: $question}
      }
    ) {
      affected_rows
    }

    insert_multirater_survey_responses(objects: $data) {
      returning {
        id
        respond_from
        respond_to
        survey
        group
        question
        answer
        answer_text
      }
      affected_rows
    }
  }
`
