import React from 'react'
import styled from 'styled-components'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Button,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

export const PaperPage = styled(Paper)`
  width: 100%;
  height: auto;
  padding: 14px 20px 50px 20px;
  box-sizing: border-box;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-left: 13px;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
`

export const TitlePage = styled(Typography)`
  margin-left: 12px;
  font-weight: 600;
`

export const ButtonPrimary = styled(Button)`
  padding: 12px 30px;
  font-weight: 600;
`
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 0px 10px;
  margin: 30px 0px;
`

export const SelectStyled = styled(TextField)`
  // width: 14vw;
  width: ${props => (props.searchProps ? '18vw' : '14vw')};
`

export const SearchWrapper = styled.div`
  width: auto;
  height: auto;
`

export const ButtonFilter = styled(Button)`
  width: auto;
  height: 100%;
  margin-left: 32px;
  font-weight: 600;
  padding: 0px 45px;
`

export const Wrapper1 = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const CardGoal = styled(Paper)`
  width: 21vw;
  height: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding: 16px;
  box-sizing: border-box;
  margin-right: 1.4vw;
  margin-bottom: 4.5vh;
`
export const TitleContentGoal = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  cursor: 'pointer';
  &:hover {
    text-decoration: underline;
  }
`

export const HeaderCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`

export const Status = styled(Typography)`
  font-size: 12px;
  margin-top: 10px;
  text-transform: capitalize;
  font-weight: bold;
`
export const GroupAvatar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  flex-wrap: wrap;
`
export const FooterCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
  align-items: center;
`

export const Index = styled.div`
  border-radius: 5px;
  color: #fff;
  padding: 6px 0px;
  width: 5vw;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
`

export const Persentase = styled(Typography)`
  margin: 0px;
  padding-top: 8px;
  text-align: left;
  font-family: Muli;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #707070;
`

export const GoalWrapper = styled.div`
  ${props =>
    props.loading &&
    `
      &:before {
        content: "Loading";
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}
`

export const StyledMenus = styled(({...other}) => (
  <Menu classes={{list: 'list'}} {...other} />
))`
  & .list {
    min-width: 120px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  justify-content: left;
  color: #003d51;
`

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid #E5E5E5',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    borderBottom: 'none',
  },
})(MuiAccordionSummary)

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: '1px solid #E5E5E5',
    display: 'initial',
  },
}))(MuiAccordionDetails)

export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  errorDate: {
    margin: '8px 14px 0px',
    color: '#f44336',
    fontSize: 12,
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  upload: {
    width: '150px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#055469',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#fff',
  },
  save: {
    width: '150px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#007fb2',
    fontFamily: 'Muli',
    fontSize: '18px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: '#fff',
  },
  formChild: {marginBottom: '1rem'},
  disabled: {background: '#eff2f4'},
  outlinedInput: {
    padding: '10px 14px',
  },
  radioRoot: {padding: 0},
  icon: {
    borderRadius: 5,
    padding: 10,
  },
  checkedIcon: {
    borderRadius: 5,
    color: '#fff',
  },
  list: {display: 'flex', margin: '10px 0'},
  textGrey: {color: '#a9a8a8'},
  spacer: {marginRight: 10},
  selectedEmp: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  cardEmp: {
    width: '28.5%',
    margin: 10,
    padding: 9,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #a9a8a8',
  },
  flexAbsolute: {
    display: 'flex',
    position: 'absolute',
    zIndex: 1,
  },
  flex: {
    display: 'flex',
  },
  flexWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 350,
  },
}))
