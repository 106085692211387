import React, {useState, useContext} from 'react'
import Grid from '@material-ui/core/Grid'
import {makeStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlus} from '@fortawesome/free-solid-svg-icons'
import Typography from '@material-ui/core/Typography'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_LIST_TAB_TASK,
  GET_DETAIL_TAB_TASK,
} from '../../../../graphql/queries/index'
import {ExpansionPanelDetails, CircularProgress, Icon} from '@material-ui/core'
import {SwitchValue} from '../../../../components/color-switch/ColorSwitcher'
import {StyledSmallButton} from '../../../../components/button/ButtonStyles'
import Detail from './Detail'
import {
  FlexCenter,
  StyledBorderedExpansion,
  StyledExpansionSummaryAssign,
  StyledLinear,
} from '../../PerformanceStyles'
import {GreyTypography} from '../../../../components/typography/TypographyStyles'
import ModalPersonal from '../../Modal/Modalpersonal'
import InfiniteScroll from 'react-infinite-scroll-component'
import {DataContext} from '../../../../contexts/ContextConfig'
import {priority, progress_status} from './constant'
import {GLOBAL_GLOSSARY} from '../../../../utils/constant'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
  },
  assign: {
    flexGrow: 1,
    padding: theme.spacing(3, 2),
    backgroundColor: '#eff2f4',
  },
  add_task: {
    width: '100%',
    height: '64px',
    // borderRadius: '5px',
    // backgroundColor: '#055469',
    // color: '#fff',
  },
  hight: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#ef4d5e',
    color: '#fff !important',
  },
  medium: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#ffa000',
    color: '#fff !important',
  },
  low: {
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#4caf50',
    color: '#fff !important',
  },
  assign_image: {
    width: '50px',
    height: '50px',
    borderRadius: '150px',
  },
  title: {
    fontFamily: 'Muli',
    fontSize: '15px',
    fontWeight: 'normal',
    color: '#a9a8a8',
  },
}))

export default function Task(props) {
  const classes = useStyles()
  const {setDataContext} = useContext(DataContext)
  const [addTask, setAddTask] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [infiniteLoad, setInfiniteLoad] = useState({
    offset: 0,
    limit: 3,
    hasMore: true,
  })

  const {data, error, refetch} = useQuery(GET_LIST_TAB_TASK, {
    variables: {
      id: props.idGoals,
      offset: infiniteLoad.offset,
      limit: infiniteLoad.limit,
    },
    skip: !props.idGoals,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  })

  const {data: dataDetailTask, loading: loadingDetailTask} = useQuery(
    GET_DETAIL_TAB_TASK,
    {
      variables: {id: expanded && parseInt(expanded)},
      skip: !expanded,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    }
  )

  if (data === undefined && error === undefined) {
    return <CircularProgress />
  } else if (error !== undefined) {
    return <div>{JSON.stringify(error)}</div>
  }
  const dataDetail = data.performance_tasks
  const countDataDetail = data.performance_tasks_aggregate.aggregate.count

  const handleAddTask = () => setAddTask(true)
  const handleCancelAdd = () => {
    setDataContext(null)
    setAddTask(false)
    refetch()
  }

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    event.preventDefault()
  }

  const fetchMoreData = () => {
    if (infiniteLoad.limit >= countDataDetail) {
      setInfiniteLoad({
        ...infiniteLoad,
        hasMore: false,
      })
    }
    setTimeout(() => {
      setInfiniteLoad({
        ...infiniteLoad,
        limit: infiniteLoad.limit + 3,
      })
    }, 1000)
  }

  return (
    <>
      {addTask ? (
        <ModalPersonal
          idGoals={props.idGoals}
          dataKpi={props.dataKpi}
          dataDateGoal={props.dataDateGoal}
          dataContribute={props.dataContribute}
          onBack={handleCancelAdd}
          type="add-task"
        />
      ) : (
        <div className={classes.root}>
          <Grid container spacing={3}>
            {props.withoutAdd ? null : (
              <Grid item xs={12}>
                <Button
                  className={classes.add_task}
                  variant="contained"
                  color="primary"
                  onClick={handleAddTask}
                  style={{textTransform: 'capitalize'}}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    style={{
                      flexDirection: 'column',
                      cursor: 'pointer',
                      fontSize: '20px',
                      marginRight: '10px',
                    }}
                  />
                  Tambah {GLOBAL_GLOSSARY.performance.Task}
                </Button>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              id="scrollableDiv"
              style={{height: 400, overflow: 'auto'}}
            >
              <InfiniteScroll
                style={{width: '100%'}}
                dataLength={infiniteLoad.limit}
                next={fetchMoreData}
                hasMore={countDataDetail > 2 ? infiniteLoad.hasMore : false}
                loader={
                  <div style={{textAlign: 'center'}}>
                    <CircularProgress size={15} style={{marginRight: '10px'}} />
                    <span>Loading</span>
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                {dataDetail.map((value, index) => {
                  const resWeight = value?.kpi_weight_unit?.name

                  return (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      style={{marginBottom: '24px'}}
                    >
                      <StyledBorderedExpansion
                        expanded={expanded === value.id}
                        onChange={handleExpand(value.id)}
                        elevation={0}
                        style={{marginTop: 0}}
                      >
                        <StyledExpansionSummaryAssign>
                          <div style={{width: '100%'}}>
                            <Grid container spacing={3} alignItems="center">
                              <Grid item xs={12}>
                                <StyledSmallButton
                                  style={{
                                    height: 'auto',
                                    marginBottom: 5,
                                    fontSize: 10,
                                  }}
                                  background={SwitchValue(
                                    value.priority,
                                    'priority2'
                                  )}
                                >
                                  {priority[value.priority]}
                                </StyledSmallButton>
                                <FlexCenter>
                                  <Typography variant="body2">
                                    {value.name}
                                  </Typography>
                                  {(value.status === 'ARCHIVED' ||
                                    value.status === 'COMPLETED') && (
                                    <Icon
                                      color="secondary"
                                      style={{marginLeft: 8, fontSize: '1rem'}}
                                    >
                                      check_circle_outline
                                    </Icon>
                                  )}
                                </FlexCenter>
                                <Typography display="block" variant="caption">
                                  {taskStatus[value.status]}
                                </Typography>
                                <GreyTypography
                                  display="block"
                                  variant="caption"
                                  component="label"
                                >
                                  #{value.id}
                                </GreyTypography>
                              </Grid>
                            </Grid>
                            <FlexCenter
                              style={{justifyContent: 'space-between'}}
                            >
                              <StyledLinear
                                variant="determinate"
                                color="secondary"
                                value={
                                  value.progress_percentage > 100
                                    ? 100
                                    : value.progress_percentage
                                }
                              />
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <Typography
                                  style={{
                                    marginRight: 10,
                                    fontWeight: 'bold',
                                    width: 'max-content',
                                  }}
                                >
                                  {value.progress
                                    ? value.progress.toFixed(2)
                                    : 0}{' '}
                                  {resWeight} (
                                  {value.progress_percentage
                                    ? value.progress_percentage.toFixed(2)
                                    : 0}
                                  %)
                                </Typography>
                                <Typography
                                  style={{
                                    color:
                                      value.progress_percentage < 100
                                        ? '#014a62'
                                        : '#4caf50',
                                  }}
                                >
                                  {value.progress_percentage < 100
                                    ? progress_status.Below
                                    : value.progress_percentage === 100
                                    ? progress_status.Meet
                                    : value.progress_percentage > 100
                                    ? progress_status.Exceed
                                    : '-'}
                                </Typography>
                              </div>
                            </FlexCenter>
                          </div>
                        </StyledExpansionSummaryAssign>
                        {expanded === value.id && !loadingDetailTask ? (
                          dataDetailTask &&
                          dataDetailTask.performance_tasks.map((res, i) => (
                            <ExpansionPanelDetails key={i}>
                              <Detail
                                data={{performance_tasks: [res]}}
                                type="taskDetail"
                              />
                            </ExpansionPanelDetails>
                          ))
                        ) : expanded === value.id && loadingDetailTask ? (
                          <CircularProgress />
                        ) : null}
                      </StyledBorderedExpansion>
                    </Grid>
                  )
                })}
              </InfiniteScroll>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

const taskStatus = {
  TODO: 'Belum Dikerjakan',
  INPROGRESS: 'In Progress',
  COMPLETED: 'Complete',
  DONE: 'Done',
}
