import React, {useContext, useEffect} from 'react'

import {makeStyles, Typography} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import CheckCircle from '@material-ui/icons/CheckCircle'

import {
  COMPANY_GOAL_TYPE,
  PERSONAL_GOAL_TYPE,
  TASK_STATUS,
} from '../../constant'

import {GET_LIST_CONTAINER_GOAL} from '../../../../../graphql/queries'
import {USER_ID} from '../../../../../utils/globals'
import Loader from '../Loader'
import CardItem from '../../CardItem'
import {DataContext} from '../../../../../contexts/ContextConfig'

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000',
    marginBottom: 2,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  status: {
    fontSize: 12,
    marginBottom: 12,
  },
  titleWrap: {
    marginBottom: 2,
  },
  pfGroupWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 7,
  },
  pfGroup: {
    fontSize: 12,
    fontWeight: '600',
    width: '80%',
    margin: 0,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
  },
  box: {
    width: 15,
    height: 15,
    borderRadius: 3,
    marginRight: 4,
  },
}))

export default function GoalContainer({id, handleElipsis}) {
  const classes = useStyles()
  const {dataContext} = useContext(DataContext)

  const {data, error, loading, refetch} = useQuery(GET_LIST_CONTAINER_GOAL, {
    variables: {id},
    errorPolicy: 'ignore',
  })

  useEffect(() => {
    if (dataContext) {
      refetch()
    }
  }, [dataContext])

  if (loading) {
    return <Loader />
  }

  if (error) {
    console.error(error)
  }

  const pf_goal = data?.performance_goals_by_pk
  const totalData = pf_goal?.total_task?.aggregate?.count
  const isCompanyGoal =
    pf_goal?.kpi_list?.kpi_cascading_lists_aggregate?.aggregate.count > 0
  const status =
    totalData !== 0 && totalData === pf_goal?.complete_task?.aggregate?.count
      ? 'COMPLETED'
      : 'TODO'

  const listAction = [
    {
      isShow: true,
      label: 'Ubah',
      onClick: () => handleElipsis('edit', pf_goal),
    },
    {
      isShow: true,
      label: 'Hapus',
      onClick: () => handleElipsis('delete', pf_goal),
    },
  ]

  const taskAssignments = getTaskAssignments(pf_goal?.performance_tasks)
  const uniqueids = []
  const mergeDuplicateAvatar = taskAssignments.filter(taskData => {
    const isDuplicate = uniqueids.includes(taskData.global_user.id)

    if (!isDuplicate) {
      uniqueids.push(taskData.global_user.id)

      return true
    }

    return false
  })

  return (
    <CardItem
      id={id}
      title={<Title data={pf_goal} />}
      subheader={
        <>
          <Typography
            className={classes.status}
            color="secondary"
            component="p"
          >
            {TASK_STATUS[status]}
          </Typography>
          {status === 'COMPLETED' && (
            <CheckCircle
              color="secondary"
              style={{
                fontSize: 15,
                marginLeft: '5px',
                verticalAlign: 'text-top',
              }}
            />
          )}
        </>
      }
      typeCard="goal"
      maxAvatar={5}
      onClick={() => handleElipsis('detail', pf_goal)}
      listAction={pf_goal?.created_by === USER_ID ? listAction : []}
      type={isCompanyGoal ? COMPANY_GOAL_TYPE : PERSONAL_GOAL_TYPE}
      priority={pf_goal?.priority}
      percentage={pf_goal?.progress_percentage}
      avatar={mergeDuplicateAvatar || []}
      percentageColor={
        pf_goal?.progress_percentage >= 100 ? '#4caf50' : '#039be5'
      }
    />
  )
}

export const Title = ({data}) => {
  const classes = useStyles()

  return (
    <div className={classes.titleWrap}>
      <div className={classes.pfGroupWrap}>
        <div
          className={classes.box}
          style={{
            backgroundColor: data?.performance_goal_group?.color || '#e5e5e5',
          }}
        />
        <Typography
          className={classes.pfGroup}
          gutterBottom
          style={{
            color: data?.performance_goal_group ? '#014a62' : '#a9a8a8',
            fontStyle: data?.performance_goal_group ? 'initial' : 'italic',
          }}
        >
          {data?.performance_goal_group?.name || 'Tidak Ada'}
        </Typography>
      </div>
      <Typography component="h3" className={classes.title}>
        {data?.name}
      </Typography>
    </div>
  )
}

const getTaskAssignments = performance_tasks => {
  return performance_tasks
    ?.map(({performance_task_assignments}) => performance_task_assignments)
    ?.flat()
}
