import {TextField, InputAdornment} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React from 'react'
import {withRouter} from 'react-router-dom'
import SquadItem from '../shared/SquadItem'
import {ListSquadWrapper} from '../SquadStyle'
import EmptyState from '../../../../components/empty-state/EmptyStateComponent'
import {Skeleton} from '@material-ui/lab'
import InfiniteScroll from 'react-infinite-scroll-component'
import {dateFormat, isDateBetween} from '../../../../utils/helpers'

export const getSquadPeriod = (startdate, enddate, format) => {
  return (
    dateFormat({date: startdate, format: format}) +
    ' - ' +
    dateFormat({date: enddate, format: format})
  )
}

const SkeletonLoader = () => (
  <Skeleton
    style={{
      margin: '0 13px 16px 0',
    }}
    animation="wave"
    height={149}
    width={178}
  />
)

const ListGeneralSquad = props => {
  const {
    history,
    data,
    loading,
    error,
    search,
    setSearch,
    fetchMoreData,
  } = props

  const handleClickDetail = id => {
    history.push({
      pathname: `/squad/collaboration-squad/detail/${id}`,
    })
  }

  if (error) return JSON.stringify(error, 0, 4)

  return (
    <ListSquadWrapper>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Cari"
        className="searchbar"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {!loading && data && (
        <InfiniteScroll
          dataLength={data.performance_squad_groups.length}
          next={fetchMoreData}
          hasMore={
            data.performance_squad_groups.length < data.total.aggregate.count
          }
          loader={
            <div className="list-data">
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
              <SkeletonLoader />
            </div>
          }
        >
          <div className="list-data">
            {data?.performance_squad_groups?.map(
              ({id, name, description, start_date, end_date}) => (
                <SquadItem
                  key={id}
                  title={name}
                  description={description}
                  status={
                    isDateBetween(new Date(), start_date, end_date)
                      ? 'Aktif'
                      : 'Tidak Aktif'
                  }
                  date={getSquadPeriod(start_date, end_date, 'll')}
                  onClick={() => handleClickDetail(id)}
                />
              )
            )}
          </div>
        </InfiniteScroll>
      )}
      {loading && (
        <div className="list-data">
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
          <SkeletonLoader />
        </div>
      )}
      {!loading && !data?.performance_squad_groups?.[0] && (
        <EmptyState
          message1="Maaf, Tidak ada Daftar"
          message2={
            search
              ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
              : 'Saat ini, tidak ada tim kerja lintas unit kerja'
          }
          image={require('../../../../assets/images/nodatalist.png')}
          styleMessage2={{
            maxWidth: 360,
            margin: 'auto',
          }}
        />
      )}
    </ListSquadWrapper>
  )
}

export default withRouter(ListGeneralSquad)
