import React from 'react'
import {
  CommentWrapper,
  BorderAddActivity,
} from '../../pages/performance-tabs/PerformanceStyles'
import {Icon, InputBase, Button} from '@material-ui/core'
import ItemAttachment from '../attachment/ItemAttachment'
import {
  acceptImage,
  acceptFile,
} from '../../pages/performance-tabs/goal-components/TabDialog/constant'

const PostingField = props => {
  const {
    handleKeyPress,
    handleTextField,
    handleCancelPostField,
    handleChangeFile,
    handleDeleteAttachement,
    handleChangeNameFile,
    handleSave,
    textValue = '',
    placeholder,
    attachmentValue = [],
    variant = 'add',
    disabled = false,
  } = props
  return (
    <BorderAddActivity>
      <CommentWrapper>
        <InputBase
          style={{fontSize: 14}}
          id="input-with-icon-grid"
          name="parComment"
          fullWidth
          multiline
          variant="outlined"
          onKeyPress={handleKeyPress}
          onChange={handleTextField}
          value={textValue}
          placeholder={placeholder}
        />
      </CommentWrapper>
      {attachmentValue?.map((res, i) => {
        const {name, size, url, isUploading, loaded} = res
        return (
          <ItemAttachment
            res={res}
            key={`${url}-${i}`}
            id={`${url}-${i}`}
            size={size}
            onDelete={() => handleDeleteAttachement(i)}
            src={url}
            onChange={e => handleChangeNameFile(e, i)}
            fileName={name}
            isUploading={isUploading}
            loaded={loaded}
          />
        )
      })}
      <hr
        style={{
          border: '1px solid #039be5',
          width: '100%',
        }}
      />
      <CommentWrapper
        style={{
          justifyContent: 'space-between',
          paddingBottom: 0,
        }}
      >
        <div style={{display: 'flex'}}>
          <input
            style={{display: 'none'}}
            type="file"
            id="add-attachment-photo"
            name="attachmentVal"
            onChange={handleChangeFile}
            multiple
            accept={acceptImage}
            disabled={disabled}
          />
          <label
            htmlFor="add-attachment-photo"
            style={{display: 'flex', marginRight: 10}}
          >
            <Icon
              style={{
                color: '#a9a8a8',
                ...(!disabled && {cursor: 'pointer'}),
                ...(disabled && {cursor: 'not-allowed'}),
                fontSize: 20,
              }}
            >
              photo
            </Icon>
          </label>
          <input
            style={{display: 'none'}}
            type="file"
            id="add-attachment-file"
            name="attachmentVal"
            onChange={handleChangeFile}
            multiple
            accept={acceptFile}
            disabled={disabled}
          />
          <label htmlFor="add-attachment-file" style={{display: 'flex'}}>
            <Icon
              style={{
                color: '#a9a8a8',
                ...(!disabled && {cursor: 'pointer'}),
                ...(disabled && {cursor: 'not-allowed'}),
                fontSize: 20,
              }}
            >
              attach_file
            </Icon>
          </label>
        </div>
        <div>
          <Button
            style={{
              marginRight: 10,
              fontSize: 12,
              width: '85px',
              height: '32px',
            }}
            size="small"
            onClick={handleCancelPostField}
          >
            Batal
          </Button>
          <Button
            style={{fontSize: 12, width: '85px', height: '32px'}}
            variant="contained"
            color={variant === 'add' ? 'primary' : 'secondary'}
            onClick={handleSave}
            size="small"
            disabled={!textValue || disabled}
          >
            {variant === 'add' ? 'Tambah' : 'Simpan'}
          </Button>
        </div>
      </CommentWrapper>
    </BorderAddActivity>
  )
}

export default PostingField
