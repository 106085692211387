import React from 'react'
import {
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBack from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)'},
  contentTxt: {fontSize: 12},
  btnPopup: {
    backgroundColor: '#014a62',
    fontSize: 12,
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  titleDialog: {
    fontSize: 16,
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  actions: {padding: '12px 20px', borderTop: '1px solid #e5e5e5'},
  wordBreak: {
    wordBreak: 'break-all',
    color: '#014a62',
    textTransform: 'capitalize',
  },
  btnCancel: {
    color: '#a9a8a8',
    backgroundColor: '#ffffff',
    boxShadow: 'initial',
  },
  word: {wordBreak: 'break-all'},
  nameStyle: {
    color: '#014a62',
    fontWeight: '600',
  },
  title: {color: '#014a62', fontSize: 14, fontWeight: '600'},
  customTitle: {padding: 0, background: '#fff'},
}))

export default function PopupWrapper({
  open,
  title,
  titleBackground = '#eff2f4',
  maxWidth = 'sm',
  children,
  withAction = true,
  onSave,
  onClose,
  onCancel = undefined,
  mainButtonText = 'Simpan',
  secondaryButtonText = 'Batalkan',
  contentPadding = '24px 22px',
  isDisabledMainButton = false,
  withCloseicon = false,
  withArrowBack = false,
}) {
  const classes = useStyles()

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={maxWidth}>
      <DialogTitle
        className={classes.titleDialog}
        style={{backgroundColor: titleBackground}}
      >
        {withArrowBack && (
          <div>
            <IconButton onClick={onClose} aria-label="back">
              <ArrowBack />
            </IconButton>
            {title}
          </div>
        )}
        {!withArrowBack && title}
        {withCloseicon && (
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        style={{padding: contentPadding}}
        className={classes.content}
      >
        {children}
      </DialogContent>
      {withAction && (
        <DialogActions className={classes.actions}>
          {onCancel && (
            <Button onClick={onCancel}>{secondaryButtonText}</Button>
          )}
          <Button
            className={classes.btnPopup}
            variant="contained"
            disabled={isDisabledMainButton}
            onClick={onSave}
          >
            {mainButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}
